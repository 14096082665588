// IntlHelpers.js

import { formatMessage as format } from 'lngProvider';
// eslint-disable-next-line max-len
export const formatMessage = ( { id, defaultMessage }, values ) => format( id, defaultMessage, values );

/**
 * Formatea el nombre de una ronda.
 *
 * @param {string} roundName - El nombre de la ronda a formatear.
 * @returns {string} El nombre de la ronda formateado.
 */
export const formatRoundName = ( roundName ) => {
  // Verifica si el nombre de la ronda o la función formatMessage no están definidos.
  if ( !roundName || !formatMessage ) return '';

  // Divide el nombre de la ronda en palabras.
  const nameArray = roundName.split( ' ' );

  // Si solo hay una palabra, devuelve el nombre original.
  if ( nameArray.length <= 1 ) return roundName;

  // Formatea el nombre de la ronda utilizando formatMessage.
  return formatMessage( { id: nameArray[0] }, { value: nameArray[1] } );
};

// Exporta las funciones formatMessage y formatRoundName.
export default { formatMessage, formatRoundName };
