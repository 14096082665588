/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { Fragment } from 'react';
import {
  Dialog,
  Slide,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  DialogContent,
  Container,
} from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import KotlerHeaderFullWidthModal from 'components/custom/KotlerHeaderFullWidthModal';
import SectionInfoText from 'components/custom/SectionInfoText';

const CostumerOwnerShipModal = ( { title, open, onClose, data } ) => (
  <Dialog
    fullScreen
    open={open}
    onClose={onClose}
    TransitionComponent={Slide}
    TransitionProps={{ direction: 'right' }}
  >
    <KotlerHeaderFullWidthModal {...{ title, onClose }} />

    <DialogContent className="px-2">
      <Container maxWidth="xl">
<SectionInfoText className="mx-2 text-justify" title="help.info.customer.ownership.snackbar" />
      </Container>
 
   
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CardBox header={<KotlerCardHeader title="CustomerOwnerShipByAge" minHeight="0xp" />} styleName="px-2">

            <Table className="kotler-table">
              <TableHead>
                <TableRow>
                  <TableCell className="TableHead-report"><IntlMessages id="product" /></TableCell>
                  <TableCell align="right"><IntlMessages id="total" /></TableCell>
                  <TableCell align="right">-20</TableCell>
                  <TableCell align="right">21-30</TableCell>
                  <TableCell align="right">31-40</TableCell>
                  <TableCell align="right">41-50</TableCell>
                  <TableCell align="right">51-60</TableCell>
                  <TableCell align="right">61-70</TableCell>
                  <TableCell align="right">71+</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ? (
                    <Fragment>
                      {data.productOwnership.byAge.map( ( item, index ) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                          <TableCell>{item.product.toLowerCase() === 'total active customers' || item.product.toLowerCase() === 'total population' ? <IntlMessages id={item.product.toLowerCase()} /> : item.product}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.total )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item['20-'] )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item['21-30'] )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item['31-40'] )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item['41-50'] )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item['51-60'] )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item['61-70'] )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item['71+'] )}</TableCell>
                        </TableRow>
                      ) )}
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <TableRow>
                        <TableCell colSpan={5}><IntlMessages id="ConstructionZone" /></TableCell>
                      </TableRow>
                    </Fragment>
                  )
                }
              </TableBody>
            </Table>
          </CardBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CardBox header={<KotlerCardHeader title="CustomerOwnerShipByGender" minHeight="0xp" />} styleName="px-2">

            <Table className="kotler-table">
              <TableHead>
                <TableRow>
                  <TableCell className="TableHead-report"><IntlMessages id="product" /></TableCell>
                  <TableCell align="right"><IntlMessages id="total" /></TableCell>
                  <TableCell align="right"><IntlMessages id="MALE" /></TableCell>
                  <TableCell align="right"><IntlMessages id="FEMALE" /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ? (
                    <Fragment>
                      {data.productOwnership.byGender.map( ( item, index ) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                          <TableCell>{item.product.toLowerCase() === 'total active customers' || item.product.toLowerCase() === 'total population' ? <IntlMessages id={item.product.toLowerCase()} /> : item.product}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.total )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.male )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.female )}</TableCell>
                        </TableRow>
                      ) )}
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <TableRow>
                        <TableCell colSpan={5}><IntlMessages id="ConstructionZone" /></TableCell>
                      </TableRow>
                    </Fragment>
                  )
                }
              </TableBody>
            </Table>
          </CardBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CardBox header={<KotlerCardHeader title="CustomerOwnerShipByDemografic" minHeight="0xp" />} styleName="px-2">

            <Table className="kotler-table">
              <TableHead>
                <TableRow>
                  <TableCell><IntlMessages id="product" /></TableCell>
                  <TableCell align="right"><IntlMessages id="total" /></TableCell>
                  <TableCell align="right"><IntlMessages id="Executive" /></TableCell>
                  <TableCell align="right"><IntlMessages id="Professional" /></TableCell>
                  <TableCell align="right"><IntlMessages id="Employee" /></TableCell>
                  <TableCell align="right"><IntlMessages id="Student" /></TableCell>
                  <TableCell align="right"><IntlMessages id="Home" /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ? (
                    <Fragment>
                      {data.productOwnership.bySegment.map( ( item, index ) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                          <TableCell>{item.product.toLowerCase() === 'total active customers' || item.product.toLowerCase() === 'total population' ? <IntlMessages id={item.product.toLowerCase()} /> : item.product}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.total )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.Executive )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.Professional )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.Employee )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.Student )}</TableCell>
                          <TableCell align="right">{Util.formatNumberNoDecimals( item.Home )}</TableCell>
                        </TableRow>
                      ) )}
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <TableRow>
                        <TableCell colSpan={5}><IntlMessages id="ConstructionZone" /></TableCell>
                      </TableRow>
                    </Fragment>
                  )
                }
              </TableBody>
            </Table>
          </CardBox>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
);

export default CostumerOwnerShipModal;
