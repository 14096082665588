/* eslint-disable react/no-danger */
import React from 'react';
import './LiveAdContainer.scss';
import CircularProgress from 'components/theme/CircularProgress';

export const LiveAdContainer = ( { ad, loading } ) => (
  <div className="liveads-container">
    {loading ? (
      <CircularProgress />
    ) : (
      <div
        className="liveads-wraper"
        dangerouslySetInnerHTML={{ __html: ad ? ad.body : "<span className='text-primary2'>No ads</span>" }}
      />
    )}
  </div>
);
export default LiveAdContainer;
