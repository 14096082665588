import { Grid, Hidden, Typography } from '@material-ui/core';
import IconCard from 'components/custom_v2/IconCard';
import React from 'react';
import IntlMessages from 'utils/IntlMessages';
import Company from 'assets/images/kotler/dashboardIcons/now/company.png';
import Comunication from 'assets/images/kotler/dashboardIcons/now/comunication.png';
import Market from 'assets/images/kotler/dashboardIcons/now/market.png';
import Price from 'assets/images/kotler/dashboardIcons/now/price.png';
import Products from 'assets/images/kotler/dashboardIcons/now/products.png';
import Search from 'assets/images/kotler/dashboardIcons/now/search.png';




export const NowOptions = ( { user } ) => (
  <>
    <Grid item className='d-flex flex-column align-items-center'>
      <Typography
        variant="h6"
        color="textSecondary"
        className="text-center icon-card-section-title"
      >
        <IntlMessages id="informationDashboard" />
      </Typography>

      <Grid container>
        <IconCard
        step={1}
          image={<img src={Company} alt="company" style={{objectFit:"contain"}}  />}
          title="company"
          link="/sessions/info/company"
        />

        <IconCard
        step={2}
         image={<img src={Search} alt="investigation"  style={{objectFit:"contain"}} />}
          title="investigation"
          link="/sessions/info/investigation"
        />
      </Grid>
      <Grid container xs={2} className="justify-content-center">
        <IconCard
        step={3}
          image={<img src={Market} alt="market"  style={{objectFit:"contain"}} />}
          title="market"
          link="/sessions/info"
        />
      </Grid>
    </Grid>
    <Hidden lgDown><div className='mx-5'></div>
</Hidden>
    
    <Grid item className='d-flex flex-column align-items-center'>

      <Typography
        variant="h6"
        color="textSecondary"
        className="text-center  icon-card-section-title"
      >
        <IntlMessages id="decisionDashboard"  />
      </Typography>
      <Grid container>
        <IconCard
        step={1}
          image={<img src={Products} alt="products"  style={{objectFit:"contain"}} />}
          title="products"
          link="/sessions/general/products"
        />

        <IconCard
        step={2}
          image={<img src={Price} alt="price" />}
          title="price"
          link="/sessions/general/price"
        />
      </Grid>
      <Grid container xs={2} className="justify-content-center" style={{objectFit:"contain"}} >
        <IconCard
        step={3}
          image={<img src={Comunication} alt="comunication" style={{objectFit:"contain"}} />}
          title="comunication"
          link="/sessions/general/crm"
        />
      </Grid>
    </Grid>
  </>
);
