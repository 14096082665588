import api from 'config/Api';
import { z } from 'zod';
import { AllRoundServicesSchema } from './schemas/all-rounds-schema';
import { LastRoundServiceSchema } from './schemas/last-round-schemas';

// const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

export async function getAllRoundServices( sessionId, params ) {
  const res = await api.get(
    `${endpoint2}/${sessionId}/market/services-all-round?`,
    params,
  );

  if ( !res.ok ) {
    throw new Error( ( res.data && res.data.message ) || res.data.error );
  }
  const data = z.array( AllRoundServicesSchema ).parse( res.data );
  return data;
}


export async function getLastRoundServices( sessionId, workgroupId, params ) {
  const res = await api.get(
    `${endpoint2}/${sessionId}/market/services-last-round?workgroup=${workgroupId}`,
    params,
  );

  if ( !res.ok ) {
    throw new Error( ( res.data && res.data.message ) || res.data.error );
  }
  const data = z.array( LastRoundServiceSchema ).parse( res.data );
  return data;
}

export default {
  getAllRoundServices,
  getLastRoundServices,
};
