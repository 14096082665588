import { postHttpRequest } from 'utils/ApiPetitionhandles';

/**
 * Sends a POST request to ask a question to the manual AI.
 * @param {string} sessionId - The session ID.
 * @param {Object} [data={ question: '', languange: 'en' }] - The data to be sent.
 * @param {string} [data.question=''] - The question to be asked. Default is an empty string.
 * @param {string} [data.language='en'] - The language for the question. Default is 'en'.
 * @param {Object} params - Additional parameters for the request.
 * @returns {Promise<Object>} - A promise resolving to the response data.
 */
export async function postManualAsk(
  sessionId,
  data = { question: '', languange: 'en' },
  params,
) {
  const url = `v2/assistant-messages/${sessionId}`;
  return postHttpRequest(
    url,
    { user: 'Livemax', language: 'en', ...data },
    params,
  );
}

/**
 * Exports the postHttpRequest function.
 */
export default { postHttpRequest };
