import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { find, map } from 'lodash';
import KotlerPagination from 'components/custom/KotlerPagination';
import { injectIntl } from 'react-intl';

const TableMarketProduct = ( {
  className = 'mt-3',
  intl,
  infoProductData,
  //   loading,
  //   error,
} ) => {
  const [dataToTable, setDataToTable] = useState( [] );
  useEffect( () => {
    const formatData = map( infoProductData, ( item ) => {
      const design = find( item.attributes, { label: 'Design' } );
      const size = find( item.attributes, { label: 'Size' } );
      const reliability = find( item.attributes, { label: 'Reliability' } );
      const weight = find( item.attributes, { label: 'Sustainability' } );
      const speed = find( item.attributes, { label: 'Speed' } );
      return {
        key: item.id,
        name: item.name,
        design: design && design.valueText,
        size: size && size.valueText,
        reliability: reliability && reliability.valueText,
        weight: weight && weight.valueText,
        speed: speed && speed.valueText,
        price: Util.formatCurrency( item.salePrice ),
      };
    } );

    setDataToTable( formatData );
  }, [infoProductData] );
  const align = 'left';
  return (
    <div className={className}>
      <KotlerPagination data={dataToTable} initialPageSize={5}>
        {( { pageData } ) => (
          <Table className="kotler-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <IntlMessages id="model" />
                </TableCell>
                <TableCell align={align}>
                  <i
                    className="fa mr-2 fa-pencil-ruler text-purple"
                    title={intl.formatMessage( { id: 'Design' } )}
                  />
                </TableCell>
                <TableCell align={align}>
                  <i
                    className="fa mr-2 fa-arrows-alt text-blue"
                    title={intl.formatMessage( { id: 'Size' } )}
                  />
                </TableCell>
                <TableCell align={align}>
                  <i
                    className="fa mr-2 fa-cookie text-warning"
                    title={intl.formatMessage( { id: 'Reliability' } )}
                  />
                </TableCell>
                <TableCell align={align}>
                  <i
                    className="fa mr-2 fa-cog text-sepia"
                    title={intl.formatMessage( { id: 'Sustainability' } )}
                  />
                </TableCell>
                <TableCell align={align}>
                  <i
                    className="fa mr-2 fa-tachometer-alt text-danger"
                    title={intl.formatMessage( { id: 'Speed' } )}
                  />
                </TableCell>
                <TableCell align="right">
                  <IntlMessages id="price" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(
                pageData,
                ( {
                  key,
                  name,
                  design,
                  size,
                  reliability,
                  weight,
                  price,
                  speed,
                } ) => (
                  <TableRow key={key}>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      {design && <IntlMessages id={design} />}
                    </TableCell>
                    <TableCell>{size && <IntlMessages id={size} />}</TableCell>
                    <TableCell>
                      {reliability && <IntlMessages id={reliability} />}
                    </TableCell>
                    <TableCell>
                      {weight && <IntlMessages id={weight} />}
                    </TableCell>
                    <TableCell>
                      {speed && <IntlMessages id={speed} />}
                    </TableCell>
                    <TableCell align="right">{price}</TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        )}
      </KotlerPagination>
    </div>
  );
};

const mapStateToProps = ( { products, settings }, { categoryId } ) => {
  if ( !categoryId ) {
    return {
      infoProductData: undefined,
      loading: true,
      error: null,
      locale: settings.locale,
    };
  }
  const product = products.infoProducts.byCategoryId[categoryId];
  if ( !product ) {
    return {
      infoProductData: undefined,
      loading: false,
      error: null,
      locale: settings.locale,
    };
  }
  return {
    infoProductData: product.data && product.data.data,
    loading: product.loading,
    error: product.error,
    locale: settings.locale,
    // activeSessionId: user && user.activeSession && user.activeSession.id,
  };
};

export default connect( mapStateToProps )( injectIntl( TableMarketProduct ) );
