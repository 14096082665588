import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

const KotlerTableCardGraphic = ( { titles = [], cells = [], originalPadding } ) => (
    <Table className="kotler-table mt-1" style={{ minHeight: 65 }}>
      <TableHead>
        <TableRow>
          {titles.map( item => (
            <TableCell colspan={2} className={!originalPadding ? 'px-2' : null}>
              {item}
            </TableCell>
          ) )}
        </TableRow>
      </TableHead>
      <TableBody>
        {cells.map( rows => (
          <TableRow>
            {rows.map( (cell,index) => (
              <TableCell align={index %2!==0?"right":"left"} className={!originalPadding ? 'px-2' : null}>
                {cell}
              </TableCell>
            ) )}
          </TableRow>
        ) )}
      </TableBody>
    </Table>
  );
  export default KotlerTableCardGraphic;