import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import IntlMessages from 'utils/IntlMessages';
import ConfirmCancelButtons from '../ConfirmCancelButtons';
import FeatherIcon from '../FeatherIcon';

const KotlerSectionHeader = ( {
  title,
  titleComplement,
  noTranslateTitle,
  image,
  backTo,
  crm = false,
  onSubmit,
  onCancel,
  titleSubmit,
  titleCancel,
  submitTo,
  cancelTo,
  submitBtnType,
  hoverCancel = {},
  hoverSubmit = {},
} ) => (
  <>
    <Box className="kotler-header-section-baseline" />
    <Box
      className="kotler-header-section"
      style={{ backgroundImage: `url(${image})`, zIndex: 1 }}
    >
      <Container maxWidth="lg">
        <Grid container justify="space-between">
          <Grid item xs={12} sm="auto">
            <div className="d-flex align-items-center ">
              {backTo && (
                <Button
                  to={backTo}
                  component={Link}
                  variant="contained"
                  className="p-1 mr-1"
                  style={{ minWidth: 28, width: 28, height: 28 }}
                  color="primary"
                >
                  <FeatherIcon icon="chevronLeft" color="#fff" size="24px" />
                </Button>
              )}
              <Typography
                variant="h4"
                color="textSecondary"
                className="kotler-header-section-title"
              >
                {noTranslateTitle ? title : <IntlMessages id={title} />}
                {titleComplement && (
                <>
                  {' '}
                      :
                  {' '}
                  <IntlMessages id={titleComplement} />
                </>
                )}
              </Typography>

              {crm && <Badge className="kotler-badge ml-2">CRM</Badge>}
            </div>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ConfirmCancelButtons
              {...{
                onSubmit,
                onCancel,
                submitBtnType,
                titleSubmit,
                titleCancel,
                submitTo,
                cancelTo,
                hoverCancel,
                hoverSubmit,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);
export default KotlerSectionHeader;
