import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Grid, IconButton } from '@material-ui/core';

import SettingsActions from 'store/reducers/Settings';
import LogService from 'services/Log';
import CardBox from 'components/custom_v2/CardBox';
import Table from 'components/custom/Table/AsyncTable';
import IntlMessages from 'utils/IntlMessages';
import { Row, Col } from 'reactstrap';
import { Form } from 'react-final-form';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-activity.jpeg';
import _ from 'lodash';

import {
  DatePicker,
  Select,
} from 'components/custom/FormElements';
import Util from 'utils/Util';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import FeatherIcon from 'components/custom/FeatherIcon';

class UserLicenses extends React.Component {
  state = { data: [], dataToSend: {}, entity: [] };
  dataToSend = {};
  defaultRoundId = '';
  export = false;
  firstLoad = true;

  getData = async ( params ) => {
    this.params = _.cloneDeep( params );
    const { toggleLoading, user } = this.props;
    toggleLoading( true );
    await LogService.getDataFilters( user.activeSession.id, params )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          const actions = _.map( response.data.actions, item => ( {
            id: item,
            name: item,
          } ) );
          actions.unshift( { id: '', name: '----------------' } );

          const rounds = _.map( response.data.rounds, item => ( {
            id: item.id,
            name: item.shortName,
          } ) );
          rounds.unshift( { id: '', name: '----------------' } );

          this.lastRound = rounds[rounds.length - 1];
          this.defaultRoundId = this.lastRound.id;

          const commodities = _.map( response.data.commodities, item => ( {
            id: item.id,
            name: item.name,
          } ) );
          commodities.unshift( { id: '', name: '----------------' } );

          const students = _.map( response.data.students, item => ( {
            id: item.id,
            name: item.name,
          } ) );
          students.unshift( { id: '', name: '----------------' } );

          const workgroups = _.map( response.data.workgroups, item => ( {
            id: item.id,
            name: item.name,
          } ) );
          workgroups.unshift( { id: '', name: '----------------' } );

          this.setState( {
            defaultRoundId: this.defaultRoundId,
            actions: actions || [],
            commodities: commodities || [],
            rounds: rounds || [],
            students: students || [],
            workgroups: workgroups || [],
          } );
        }
      } );

    let MergeParams = {};
    if ( !this.export ) {
      if ( this.firstLoad ) {
        this.dataToSend['filters[round]'] = this.defaultRoundId;
        this.firstLoad = false;
        this.setState( { entity: { round: this.defaultRoundId } } );
      }
      MergeParams = { ...this.dataToSend, ...params };

      await LogService.getLogs( user.activeSession.id, MergeParams )
        .then( ( response ) => {
          toggleLoading( false );
          if ( response.ok ) {
            this.setState( {
              limit: params.limit,
              offset: params.offset,
              data: response.data.data || [],
              pages: Math.ceil( response.data.total / params.limit ),
              total: response.data.total,
            } );
          }
        } );
    } else {
      this.export = false;
      if ( this.firstLoad ) {
        this.dataToSend['filters[round]'] = this.defaultRoundId;
        this.firstLoad = false;
      }
      MergeParams = { ...this.dataToSend };
      await LogService.getLogs( user.activeSession.id, MergeParams )
        .then( ( response ) => {
          toggleLoading( false );
          if ( response.ok ) {
            window.location.href = response.data;
          }
          this.dataToSend = {};
        } );
    }
  };

  onClear = () => {
    this.firstLoad = true;
    this.dataToSend = {};
    this.getData( this.params );
  }

  submitForm = ( formData ) => {
    const createATFrom = formData.fromDate ? `${formData.fromDate.format( 'YYYY-MM-DD' )}` : '';
    const createATTo = formData.toDate ? `${formData.toDate.format( 'YYYY-MM-DD' )}` : '';


    this.dataToSend = {
      'filters[createdBy]': formData.student,
      'filters[round]': formData.round,
      'filters[workgroup]': formData.workgroup,
      'filters[commodity]': formData.commodity,
      'filters[action]': formData.action,
    };

    if ( ( createATFrom !== '' ) && ( createATTo !== '' ) ) {
      this.dataToSend['filters[fromDate]'] = createATFrom;
      this.dataToSend['filters[toDate]'] = createATTo;
    }
    if ( this.export ) {
      this.dataToSend.export = true;
      this.dataToSend.limit = -1;
    }
    this.getData( this.params );
  };
  getDetail = ( row ) => {
    const ACTIONS_RULES = {
      ACTION_BUNDLE_CREATED: _.get( row, 'commodity.name', '---' ),
      BUNDLE_CREATED: _.get( row, 'commodity.name', '---' ),
      BUNDLE_PRICE_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.price', '' ) )}  `,
      BUNDLE_ACTIVATED: _.get( row, 'commodity.name', '---' ),
      BUNDLE_DEACTIVATED: _.get( row, 'commodity.name', '---' ),
      SERVICE_PRICE_CHANGED: _.get( row, 'commodity.name', '---' ),
      SERVICE_ACTIVATED: _.get( row, 'commodity.name', '---' ),
      SERVICE_DEACTIVATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_CREATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_DUPLICATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_DEACTIVATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_ACTIVATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_PRODUCTION_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.quantity', '' ) )}  `,
      PRODUCT_PRICE_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.price', '' ) )}  `,
      PRODUCT_TRADE_SALE_PRICE_CHANGED: `${_.get( row, 'commodity.name', '' )} /  ${Util.formatNumber( _.get( row, 'commodity.tradeSalePrice', '' ) )}  `,
      PRODUCT_POP_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.pop', '' ) )}  `,
      SERVICE_TRADE_SALE_PRICE_CHANGED: _.get( row, 'commodity.name', '---' ),
      SERVICE_POP_CHANGED: _.get( row, 'commodity.name', '---' ),
      BUNDLE_TRADE_SALE_PRICE_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.tradeSalePrice', '' ) )}  `,
      BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.fee', '' ) )}  `,
      BUNDLE_POP_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.pop', '' ) )}  `,
      CEM_CHANGED: _.get( row, 'data.extraData.name', 'CEM' ),
      CAMPAIGN_COMMUNICATION_CREATED: _.get( row, 'data.extraData.name', '---' ),
      CAMPAIGN_CRM_CREATED: _.get( row, 'data.extraData.name', '---' ),
      PURCHASED_REPORT: _.get( row, 'data.extraData.name', '---' ),

    };
    return ACTIONS_RULES[row.action] || '---';
  };

  render() {
    // eslint-disable-next-line max-len
    const { rounds, commodities, workgroups, students, actions, defaultRoundId, entity } = this.state;
    return (

      <>
        <HeadConfig breadcrumbsLinks={[
          {
            url: '/',
            name: 'HomeLink',
          },
          {
            name: 'InformationDashboard-activity',
            url: 'activity',
          },
          { name: 'detail' },


        ]}
        />
        <KotlerSectionHeader title="activity" image={HeaderImage} />


        <Form
          initialValues={entity}
          onSubmit={values => this.submitForm( values )}
          render={( { handleSubmit, form } ) => (
            <form onSubmit={handleSubmit}>
              <Grid container style={{ rowGap: '20px' }}>
                <Grid item xs={12}>
                  <CardBox styleName="px-2 h-100" heading="activityFilters">
                    <Row>

                      <Col>
                        <Select
                          isClearable
                          field="round"
                          label="round"
                          value={defaultRoundId}
                          options={rounds}
                          translateOptions={false}
                        />
                      </Col>

                      <Col>
                        <Select
                          isClearable
                          field="commodity"
                          label="commodity"
                          options={commodities}
                          translateOptions={false}
                        />
                      </Col>
                      <Col>
                        <Select
                          isClearable
                          field="action"
                          label="action"
                          options={actions}
                          translateOptions={false}
                        />
                      </Col>
                      <Col>
                        <div className="col-md-12 text-right">
                          <Button
                            variant="contained"
                            color="primary"
                            className="jr-btn jr-btn-lg"
                            type="submit"
                          >
                            <i className="fa fa-search" />
                            <IntlMessages id="filterBtn" />
                          </Button>

                          <Button
                            variant="contained"
                            className="jr-btn jr-btn-lg bg-blue-grey text-white"
                            type="reset"
                            onClick={() => {
                              form.reset();
                              this.onClear();
                            }}
                          >
                            <i className="fa fa-eraser" />
                            <IntlMessages id="clearBtn" />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Select
                          isClearable
                          field="workgroup"
                          label="group"
                          options={workgroups}
                          translateOptions={false}
                        />
                      </Col>
                      <Col>
                        <Select
                          isClearable
                          field="student"
                          label="student"
                          options={students}
                          translateOptions={false}
                        />
                      </Col>

                      <Col>
                        <DatePicker
                          label="createdAtFrom"
                          field="fromDate"
                          placeholder=""
                          marginContainer={false}
                        />
                      </Col>

                      <Col>
                        <DatePicker
                          label="createdAtTo"
                          field="toDate"
                          placeholder=""
                          marginContainer={false}
                        />
                      </Col>


                    </Row>

                  </CardBox>
                </Grid>
                <Grid item xs={12}>
                  <CardBox
                    header={(
                      <KotlerCardHeader
                        minHeight="0px"
                        title="activityResults"
                        styleName="px-2"
                        rightComponent={(
                          <IconButton
                            className="square-icon-button"
                            onClick={( ) => {
                              this.export = true; form.submit();
                            }
                            }
                            color="primary"
                          >
                            <FeatherIcon
                              icon="download"
                              color="primary.main"
                              size="18px"
                            />
                          </IconButton>

                        )}
                      />

                    )}

                  >
                    <Table
                      onFetchData={this.getData}
                      {...this.state}
                      columns={[
                        {
                          Header: <IntlMessages id="round" />,
                          accessor: 'round.shortName',
                          maxWidth: 150,
                          minWidth: 100,
                          width: 100,
                        },
                        {
                          Header: <IntlMessages id="user" />,
                          accessor: 'user.name',
                        },
                        {
                          Header: <IntlMessages id="createdAt" />,
                          accessor: 'createdAt',
                          Cell: props => ( props.value ? moment.utc( Number( props.value ) ).format( 'DD/MM/YYYY HH:mm' ) : null ),
                        },
                        {
                          Header: <IntlMessages id="action" />,
                          accessor: 'action',
                          maxWidth: 450,
                          minWidth: 350,
                          width: 400,
                        },
                        {
                          Header: <IntlMessages id="name" />,
                          accessor: 'id',
                          Cell: props => this.getDetail( props.original ),
                        },
                      ]}
                    />
                  </CardBox>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

const mapStateToProps = ( { user } ) => ( { user } );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( UserLicenses );
