import React, { Fragment } from 'react';


import { Grid, Hidden } from '@material-ui/core';

import TableMedio from './TableMedio';
import TableMedioDescription from './TableMedioDescription';

export default ( {
  name,
  mediaCostLabel,
  getMediaCost,
  values,
  filterType,
  tableValues,
  form,
  withBigHead,
} ) => (
  <Fragment>
    <Grid item xs={12} md={5}>

      <TableMedio {...{
        name,
        withBigHead,
        mediaCostLabel,
        form,
        getMediaCost,
        values,
        filterType,
      }}
      />
    </Grid>
    <Hidden smDown>
      <Grid item xs={12} md={7}>

        <TableMedioDescription {...tableValues} withBigHead={withBigHead} />

      </Grid>
    </Hidden>
  </Fragment>
);
