import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert'

import IntlMessages from 'utils/IntlMessages';

export default ( { translateValues, open, onSubmit, onClose, title,warning ,info,confirmBtnBsStyle ,confirmBtnText, showCancel = true, cancelBtnText, closeOnClickOutside = true } ) => {

  return (
    <SweetAlert show={open}
                warning={warning?warning:false}
                info={info?info:false}
                showCancel={showCancel}
                cancelBtnText={cancelBtnText ?
                  <IntlMessages id={cancelBtnText}/> : <IntlMessages id="cancelBtn"/>}
                closeOnClickOutside={closeOnClickOutside}
                confirmBtnText={confirmBtnText ?
                  <IntlMessages id={confirmBtnText}/> : <IntlMessages id={"resendModal.confirmBtn"}/>}
                confirmBtnBsStyle={warning?"danger":"info"}
                cancelBtnBsStyle="default"
                title={title ? <IntlMessages id={title} values={translateValues}/> :
                  <IntlMessages id={"resendModal.confirmation"} values={translateValues}/>}
                onConfirm={onSubmit}
                onCancel={onClose}
    />
  )
}
