import api from 'config/Api';

const endpoint = 'v2/changelog';
const endpointExt = 'feature';
export function getChangelogs( params ) {
  return api.get( endpoint, params );
}

export function getChangelog( changelogId ) {
  return api.get( `${endpoint}/${changelogId}` );
}
export function postChangelog( data ) {
  return api.post( endpoint, data );
}
export function updateChangelog( changelogId, data ) {
  return api.put( `${endpoint}/${changelogId}`, data );
}
export function deleteChangelog( changelogId ) {
  return api.delete( `${endpoint}/${changelogId}` );
}
export function getFeatures( changelogId, params ) {
  return api.get( `${endpoint}/${changelogId}/${endpointExt}`, params );
}
export function postFeature( changelogId, data ) {
  return api.post( `${endpoint}/${changelogId}/${endpointExt}`, data );
}
export function updateFeature( changelogId, featureId, data ) {
  return api.put(
    `${endpoint}/${changelogId}/${endpointExt}/${featureId}`,
    data,
  );
}
export function deleteFeature( changelogId, featureId ) {
  return api.delete( `${endpoint}/${changelogId}/${endpointExt}/${featureId}` );
}
