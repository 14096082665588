import api from '../config/Api';

const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

export default {

  getRoundsBySession( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/round`, params );
  },

  getRound( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/round`, params );
  },

  saveRound( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/round`, data );
  },

  closeRound( sessionId ) {
    return api.put( `${endpoint2}/${sessionId}/round/manual-closing` );
  },

  closeMultipleRound1( sessionId ) {
    return api.put( `${endpoint2}/${sessionId}/round/manual-closing?multiple=1` );
  },

  closeMultipleRound2( sessionId ) {
    return api.put( `${endpoint2}/${sessionId}/round/manual-closing?multiple=2` );
  },

  changeManualClosing( sessionId, data ) {
    return api.put( `${endpoint2}/${sessionId}/round/change/manual-closing`, data );
  },

  changeClosingTime( sessionId, data ) {
    return api.put( `${endpoint2}/${sessionId}/round/closing-time`, data );
  },
};
