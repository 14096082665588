import { shallowEqual, useSelector } from 'react-redux';
import { find } from 'lodash';
import { useSessionStatus } from './useSession';
// return status when passed session

export const useActiveSession = () => {
  const activeSession = useSelector(
    ( { user } ) => user && user.activeSession,
    shallowEqual,
  );

  const statuses = useSessionStatus( activeSession );

  return {
    ...statuses,
    activeSession,
    isNow: activeSession && activeSession.distributionType === 'now',
  };
};
// eslint-disable-next-line max-len
export const useActiveSessionId = () => useSelector(
  ( { user } ) => user && user.activeSession && user.activeSession.id,
);
export const useMyCurrentWorkgroupId = () => useSelector( ( { user } ) => {
  if ( !user || !user.activeSession || !user.licenses ) return undefined;
  if ( user.roles.includes( 'ROLE_SUPERVISOR' ) ) {
    return (
      user.activeSession.workGroups
        && user.activeSession.workGroups[0]
        && user.activeSession.workGroups[0].id
    );
  }

  const license = find( user.licenses, { session: { id: user.activeSession.id } } );
  return license && license.workGroup && license.workGroup.id;
} );
