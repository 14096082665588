import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import SessionService from 'services/Session';
import IntlMessages from 'utils/IntlMessages';
import classNames from 'classnames';

const LiveMaxVersion = ( { style = {}, user, textDark = false } ) => {
  const [WorkGroup, setWorkgroup] = useState( null );
  useEffect( () => {
    if ( user && user.activeSession && user.activeSession.id ) {
      SessionService.getSessionCurrentWorkgroupRound(
        user.activeSession.id,
      ).then( ( response ) => {
        if ( response.ok ) {
          setWorkgroup( response.data );
        }
      } );
    }
  }, [user, user.activeSession] );

  return (
    <footer
      className={classNames(
        'app-footer py-0 pl-0 pr-10 d-flex  flex-row-reverse  ',
        { 'text-dark': textDark },
      )}
      style={{
        ...style,
        flexWrap: 'wrap-reverse',
        justifyContent: 'flex-start',
      }}
    >
      <span>
        &nbsp; | &nbsp; LiveMax &copy;
        {moment().format( 'YYYY' )}
        {' '}
v3.0
      </span>
      {WorkGroup && WorkGroup.members && (
        <>
          {WorkGroup.members
            .map( ( { id, name, lastname }, i ) => (
              <span key={id}>
                {`${name} ${lastname} `}
                {i < WorkGroup.members.length - 1 && ','}
                {' '}
&nbsp;
              </span>
            ) )
            .reverse()}
          <span>
            <IntlMessages id="Team" />
            &nbsp;:&nbsp;
          </span>
        </>
      )}
    </footer>
  );
};

export default connect( ( { user } ) => ( { user } ) )( LiveMaxVersion );
