import React, { useEffect, useState } from 'react';
import { withWidth } from '@material-ui/core';
import GraphDecorator from '../decorators/GraphDecorator';
import RadarGraph from '../RadarGraph';

/**
 * Componente LineGraphFull
 *
 * Este componente se utiliza para mostrar un gráfico de líneas (LineGraph) con la capacidad de mostrarlo en un modal emergente cuando se hace clic.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.title - El título del gráfico de líneas.
 * @param {string} props.hoverTitle - El título que se muestra al pasar el cursor sobre el gráfico de líneas.
 * @param {string} props.htmlId - El ID del elemento HTML donde se renderizará el gráfico de líneas.
 * @param {Array} props.data - Un arreglo de objetos que contiene los datos que se mostrarán en el gráfico de líneas.
 * @param {string} props.height - La altura del gráfico de líneas (por ejemplo, '315px').
 * @param {boolean} props.error - Un booleano que indica si se ha producido un error al cargar los datos.
 * @param {Array} props.seriesData - Un arreglo de objetos que contiene la configuración de las series de datos para el gráfico de líneas.
 * @param {string} props.width - El ancho del componente, que se obtiene de Material-UI.
 * @param {boolean} props.loading - Un booleano que indica si los datos se están cargando.
 * @param {object} props.intl - El objeto de internacionalización proporcionado por react-intl.
 * @returns {JSX.Element} - El componente LineGraphFull.
 */
const RadarGraphFull = ( {
  title,
  hoverTitle,
  htmlId,
  data,
  height = '315px',
  error = false,
  seriesData,
  width,
  loading,
  children,
  decoratorProps = {},
} ) => {
  const [dataToGraph, setDataToGraph] = useState();
  const [seriesToGraph, setSeriesToGraph] = useState();

  useEffect( () => {
    if (
      JSON.stringify( data ) !== JSON.stringify( dataToGraph )
      || JSON.stringify( seriesData ) !== JSON.stringify( seriesToGraph )
    ) {
      setDataToGraph( data );
      setSeriesToGraph( seriesData );
    }
  }, [data, seriesData, seriesToGraph, dataToGraph] );

  return (
    <GraphDecorator
      {...decoratorProps}
      title={title}
      hoverTitle={hoverTitle}
      extraChilds={children}
      modalGraph={(
        <RadarGraph
          height={width === 'xs' || width === 'sm' ? '90vh' : '70vh'}
          data={dataToGraph}
          loading={loading}
          interactive
          error={error}
          seriesData={seriesToGraph}
          htmlId={`${htmlId}-modal`}
        />
      )}
    >
      <RadarGraph
        height={height}
        data={dataToGraph}
        error={error}
        loading={loading}
        interactive={false}
        seriesData={seriesToGraph}
        htmlId={htmlId}
      />
    </GraphDecorator>
  );
};

export default withWidth()( RadarGraphFull );
