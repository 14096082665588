import { useQuery } from 'react-query';
import { getRoundsBySession, ParamsSchema } from '../services';

export const useRoundsBySessionId = (
  sessionId,
  params = ParamsSchema.parse( { limit: -1 } ),
) => {
  const query = useQuery(
    ['v1', 'session', sessionId, 'rounds'],
    () => getRoundsBySession( sessionId, params ),
    {
      retry: 0,
      enabled: !!sessionId,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60,
      refetchInterval: 1000 * 60 * 60,
    },
  );
  return {
    query,
    isLoading: query.isLoading,
    rounds: ( query.data && query.data.data ) || [],
    total: ( query.data && query.data.total ) || 0,
    currentRound: ( query.data && query.data.currentRound ) || 0,
  };
};
