import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import moment from 'moment';

import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';

const NotificationItem = ({notification, handleClickDel, loading}) => {
  const {text, body, createdAt, id, isRead} = notification;

  return (
    <li className="media">
      <Row className="mb-2 align-items-right">
        <Col>
          <p style={{fontSize:17}}>
            <IntlMessages id={text}></IntlMessages> &nbsp;
            <span style={{fontSize:14}}  className="meta-date">
              {moment.unix( createdAt / 1000 ).format( 'DD/MM/YYYY h:mm:ss' )} 
            </span> 
            <br/>
            <span style={{fontSize:15}} className="sub-heading mb-0">
              <IntlMessages id={body}></IntlMessages>
            </span>
          </p>
        </Col>
        <Col md="auto">
          { 
            !isRead &&
            <IconButton size="small" color="primary" disabled={loading} aria-label="upload picture" component="span" onClick={()=>{ handleClickDel(id)}} >
              <CancelIcon fontSize="small" />
            </IconButton> 
          }
        </Col>
      </Row>      
    </li>
  );
};

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  notificationsUser: UserActions.notificationsUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( NotificationItem );