import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { formatDataToLineGraph } from 'helpers/GraphHelpers';

import { withWidth } from '@material-ui/core';
import LineGraphFull from 'components/StatisticalGraphs/containers/LineGraphFull';

const LineGraphNumberOfCustomers = ( {
  gId = 1,
  totalCustomerData,
  loading,
  error,
  locale,
} ) => {
  const [dataToGraph, setDataToGraph] = useState( {} );

  useEffect( () => {
    const key = 'totalNumberCustomer';
    const formatData = formatDataToLineGraph( totalCustomerData, key );
    if ( JSON.stringify( dataToGraph ) !== JSON.stringify( formatData ) ) {
      setDataToGraph( formatData );
    }
  }, [locale.locale, totalCustomerData, dataToGraph] );

  return (
    <LineGraphFull
      title="market.evolution_of_customer"
      hoverTitle="market.evolution_of_customer.hover"
      htmlId={`line_chart_number_of_customer_${gId}`}
      height="315px"
      loading={loading}
      error={error}
      titleValue="activeCustomers"
      titleCategory="Rounds"
      data={dataToGraph.data}
      seriesData={dataToGraph.seriesData}
    />
  );
};

const mapStateToProps = ( { customers, settings } ) => ( {
  totalCustomerData: customers.totalCustomers.data,
  loading: customers.totalCustomers.loading,
  error: customers.totalCustomers.error,
  locale: settings.locale,
  // activeSessionId: user && user.activeSession && user.activeSession.id,
} );

export default connect( mapStateToProps )(
  injectIntl( withWidth()( LineGraphNumberOfCustomers ) ),
);
