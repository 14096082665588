import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './changelog-card.scss';
import { useFeaturesQuery } from 'modules/changelog/hooks/useFeaturesQuery';
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FeatureBadge } from '../FeatureBadge/FeatureBadge';

export const ChangelogCard = ( { changelog = {} } ) => {
  const { featureQuery } = useFeaturesQuery( changelog && changelog.id );
  const locale = useSelector(
    ( { settings } ) => settings.locale && settings.locale.locale,
  );
  const title = useMemo(
    () => changelog
      && ( changelog[`${locale}Description`] || changelog.enDescription ),
    [locale, changelog],
  );
  const publicationDate = useMemo(
    () => changelog
      && moment.unix( changelog.publicationDate / 1000 ).format( 'DD/MM/YYYY' ),
    [changelog],
  );
  return (
    <ExpansionPanel
      defaultExpanded
      className="mb-3
       changelog-expand"
      elevation={0}
      style={{ boxShadow: 'none', border: 'none' }}
    >
      <ExpansionPanelSummary clas expandIcon={<ExpandMore />}>
        <div className="d-flex w-100 flex-column ">
          <h1 className="changelog-register-title">{title}</h1>
          <h2 className="changelog-register-time">{publicationDate}</h2>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="d-flex flex-column pb-0">
        {featureQuery.isLoading && (
          <CircularProgress className="ml-4" size={20} />
        )}
        {!featureQuery.isLoading && featureQuery.data && (
          <>
            {featureQuery.data.length
              ? featureQuery.data.map( feature => (
                <FeatureRowModal key={feature.id} feature={feature} />
              ) )
              : '-'}
          </>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
export const FeatureRowModal = ( { feature } ) => {
  const locale = useSelector(
    ( { settings } ) => settings.locale && settings.locale.locale,
  );
  const title = useMemo(
    () => feature[`${locale}Description`] || feature.enDescription,
    [locale, feature],
  );
  return (
    <div className="changelog-register text-break">
      <FeatureBadge className="mx-0 mr-1" type={feature.type} />

      <h5>{title}</h5>
    </div>
  );
};

export default ChangelogCard;
