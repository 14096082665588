import api from '../config/Api';

const endpoint = 'v1/session';
const endpoint2 = 'v2/entity';

export default {

  getSupervisorLicensesBySession( entityId, params ) {
    return api.get( `${endpoint}/${entityId}/licensesupervisor`, params );
  },

  getSupervisorLicense( entityId, supervisorLicenseId ) {
    return api.get( `${endpoint}/${entityId}/licensesupervisor/${supervisorLicenseId}` );
  },

  resendInvitation( entityId, licenseSupervisor ) {
    return api.get( `${endpoint2}/${entityId}/licensesupervisor/${licenseSupervisor}/resend` );
  },

  saveSupervisorLicense( entityId, data ) {
    return api.post( `${endpoint}/${entityId}/licensesupervisor`, data );
  },

  saveSupervisorLicenseV2( entityId, data ) {
    return api.post( `${endpoint2}/${entityId}/licensesupervisor`, data );
  },

  updateSupervisorLicense( entityId, licenseId, data ) {
    return api.put( `${endpoint}/${entityId}/licensesupervisor/${licenseId}`, data );
  },
  updateSupervisorAccessToAllSessions( entityId, licenseId, value ) {
    return api.put( `${endpoint2}/${entityId}/licensesupervisor/${licenseId}/access-to-all-session`, { access: value } );
  },
};
