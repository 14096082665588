import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import SettingsActions from 'store/reducers/Settings';
import Sidebar from 'components/custom_v2/SideNav';
import Error404 from 'components/theme/Error404';
import { Container } from '@material-ui/core';
import Info from './Info';
import UserLicenses from './UserLicenses';
import WorkGroups from './WorkGroups';
import Rounds from './Rounds';
import Logs from './Logs';

const General = ( { match, navCollapsed, toggleCollapsedNav, sessionId } ) => {
  const lists = [
    {
      id: 2,
      icon: 'view-module',
      name: 'roundmngmnt',
      color: 'warning',
      url: `${match.url}/rounds`,
    },
    {
      id: 3,
      icon: 'layers',
      name: 'workgroups',
      color: 'info',
      url: `${match.url}/user-licenses`,
    },
  ];

  return (
    <>
      <Sidebar
        menus={lists}
        open={navCollapsed}
        onToggle={toggleCollapsedNav}
      />

      <Container className="mt-3" maxWidth="lg">
        <Switch>
          <Route
            path={`${match.url}/user-licenses`}
            render={props => <UserLicenses {...props} sessionId={sessionId} />}
          />
          <Route
            path={`${match.url}/work-groups`}
            render={props => <WorkGroups {...props} sessionId={sessionId} />}
          />
          <Route
            path={`${match.url}/rounds`}
            render={props => <Rounds {...props} sessionId={sessionId} />}
          />
          <Route
            path={`${match.url}/logs`}
            render={props => <Logs {...props} sessionId={sessionId} />}
          />
          <Route
            path={match.url}
            render={props => <Info {...props} sessionId={sessionId} />}
            exact
          />
          <Route component={Error404} />
        </Switch>
      </Container>
    </>
  );
};


const mapStateToProps = ( { settings } ) => (
  { navCollapsed: settings.navCollapsed }
);

const mapDispatchToProps = {
  toggleCollapsedNav:
  SettingsActions.toggleCollapsedNav,
};

export default connect( mapStateToProps, mapDispatchToProps )( General );
