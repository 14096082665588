import React from 'react';

export const UniversityNowPaper = ( { children, style } ) => (
  <div style={style}>
    <div className=" position-relative " style={{}}>
      <img src="/images/now/paper.png" alt="papper-now" className="w-100" />
      <div
        className="position-absolute "
        style={{
          top: '36%',
          left: '10%',
          bottom: '18%',
          right: '10%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
    </div>
  </div>
);
