import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import Image from 'assets/images/kotler/sectionHeaders/register.jpeg';
import React from 'react';
import { ChangelogView } from 'modules/changelog';
import { Container } from '@material-ui/core';

const Changelog = () => (
  <div className="h-100 d-flex flex-column">
    <HeadConfig
      breadcrumbsLinks={[
        {
          url: '/',
          name: 'HomeLink',
        },
        { name: 'changelog' },
      ]}
    />
    <KotlerSectionHeader title="changelog" image={Image} />
    <Container maxWidth="lg" className="mt-2 d-flex  flex-grow-1 ">
      <ChangelogView />
    </Container>
  </div>
);

export default Changelog;
