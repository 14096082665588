import api from '../config/Api';

const endpoint = 'v1/session';
const endpointV2 = 'v2/session';

export default {

  updateProduction( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/production`, data );
  },

  updateProductionV2( sessionId, data, isForce = false ) {
    let urlFragment = 'production';
    if ( isForce ) {
      urlFragment = 'production?force=1';
    } else {
      urlFragment = 'production';
    }
    return api.put( `${endpointV2}/${sessionId}/${urlFragment}`, data );
  },

  updateProductionPriceV2( sessionId, data, isForce = false ) {
    let urlFragment = 'price';
    if ( isForce ) {
      urlFragment = 'price?force=1';
    } else {
      urlFragment = 'price';
    }
    return api.put( `${endpointV2}/${sessionId}/production/${urlFragment}`, data );
  },

  updateProductionDistributionV2( sessionId, data, isForce = false ) {
    let urlFragment = 'distribution';
    if ( isForce ) {
      urlFragment = 'distribution?force=1';
    } else {
      urlFragment = 'distribution';
    }
    return api.put( `${endpointV2}/${sessionId}/production/${urlFragment}`, data );
  },

  updateServiceDistributionV2( sessionId, data, isForce = false ) {
    let urlFragment = 'distribution';
    if ( isForce ) {
      urlFragment = 'distribution?force=1';
    } else {
      urlFragment = 'distribution';
    }
    return api.put( `${endpointV2}/${sessionId}/service/${urlFragment}`, data );
  },

  updateBundleDistributionV2( sessionId, data, isForce = false ) {
    let urlFragment = 'distribution';
    if ( isForce ) {
      urlFragment = 'distribution?force=1';
    } else {
      urlFragment = 'distribution';
    }
    return api.put( `${endpointV2}/${sessionId}/bundle/${urlFragment}`, data );
  },

  updatePriceDistribution( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/production/price-distribution`, data );
  },
};
