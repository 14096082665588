import React from 'react';
import { useIntl } from 'react-intl';

const IntlHtmlMessages = ( { id, defaultMessage, values } ) => {
  const { $t } = useIntl();
  const translatedMessage = $t( { id, defaultMessage }, values );

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: translatedMessage }} />;
};

export default IntlHtmlMessages;
