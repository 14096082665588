import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Crear un cliente de React Query
const queryClient = new QueryClient();

// Función para guardar queries en localStorage
// const saveToLocalStorage = () => {
//   const queries = queryClient
//     .getQueryCache()
//     .getAll()
//     .map( query => ( {
//       queryKey: query.queryKey,
//       state: query.state,
//     } ) );
//   localStorage.setItem( 'reactQueryCache', JSON.stringify( queries ) );
// };

// // Función para cargar queries desde localStorage
// const loadFromLocalStorage = () => {
//   const cache = localStorage.getItem( 'reactQueryCache' );
//   if ( cache ) {
//     const queries = JSON.parse( cache );
//     queries.forEach( ( { queryKey, state } ) => {
//       queryClient.setQueryData( queryKey, state.data ); // Restaurar los datos
//     } );
//   }
// };

// // Cargar la caché desde localStorage al iniciar
// //loadFromLocalStorage();

// ReactQueryContainer
// useEffect( () => {
//   // Subscribir al cache para guardar en localStorage cuando cambien o se eliminen queries
//   //  const unsubscribe =
//   // queryClient.getQueryCache().subscribe( ( event ) => {
//   //   if (
//   //     event
//   //     && ( event.type === 'queryUpdated' || event.type === 'queryRemoved' )
//   //   ) {
//   //     // Actualizar el localStorage cuando las queries cambian o se eliminan
//   //     saveToLocalStorage();
//   //   }
//   // } );

//   return () => {
//     // if ( typeof unsubscribe === 'function' )
//     // unsubscribe(); // Limpiar la subscripción al desmontar
//   };
// }, [] );

const ReactQueryContainer = ( { children } ) => (
  <QueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export { queryClient, ReactQueryContainer };
