import React from 'react';
import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';
import { Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';

const CompCashAndDept = ( { data } ) => (
  <>
    {!!data && data.length > 0 && (
      <Grid item xs={12} style={{ overflowX: 'auto' }}>
        <CardBox
          styleName="px-2 h-100"
          header={<KotlerCardHeader minHeight="0px" title="cashAndDebt" />}
        >
          <div className="jr-link" id="PLossCompdiv" />
          <Table className="kotler-table">
            <TableBody>
              <TableRow>
                <TableCell lg={2}>&nbsp;</TableCell>
                <TableCell align="right"><b>{data[0].name}</b></TableCell>
                <TableCell align="right"><b>{data[1].name}</b></TableCell>
                <TableCell align="right"><b>{data[2].name}</b></TableCell>
                <TableCell align="right"><b>{data[3].name}</b></TableCell>
                <TableCell align="right"><b>{data[4].name}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}><IntlMessages id="cashavailability" /></TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[0].cash )}</TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[1].cash )}</TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[2].cash )}</TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[3].cash )}</TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[4].cash )}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}><IntlMessages id="outstandingDebt" /></TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[0].outstandingDebt )}</TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[1].outstandingDebt )}</TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[2].outstandingDebt )}</TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[3].outstandingDebt )}</TableCell>
                <TableCell align="right">{Util.formatCurrencyNoDecimals( data[4].outstandingDebt )}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardBox>
      </Grid>
    )}
  </>

);

export default CompCashAndDept;
