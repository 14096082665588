import React from 'react';
import AiStars from '../assets/AiStars.png';

const AiIcon = ( { className = '', size = 24, style = {}, rest } ) => (
  <img
    src={AiStars}
    width={size}
    className={className}
    style={{ objectFit: 'contain', ...style }}
    height={size}
    alt="ai-tarts"
    {...rest}
  />
);

export default AiIcon;
