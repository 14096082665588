
export const FETCH_CUSTOMER_LIFETIME_VALUE_REQUEST = 'FETCH_CUSTOMER_LIFETIME_VALUE_REQUEST';
export const FETCH_CUSTOMER_LIFETIME_VALUE_SUCCESS = 'FETCH_CUSTOMER_LIFETIME_VALUE_SUCCESS';
export const FETCH_CUSTOMER_LIFETIME_VALUE_FAILURE = 'FETCH_CUSTOMER_LIFETIME_VALUE_FAILURE';
export const CLEAR_CUSTOMER_LIFETIME_VALUE = 'CLEAR_CUSTOMER_LIFETIME_VALUE';

export const fetchCustomerLifetimeValueRequest = ( sessionId, params ) => ( {
  type: FETCH_CUSTOMER_LIFETIME_VALUE_REQUEST,
  payload: { sessionId, params },
} );

export const fetchCustomerLifetimeValueSuccess = data => ( {
  type: FETCH_CUSTOMER_LIFETIME_VALUE_SUCCESS,
  payload: data,
} );

export const fetchCustomerLifetimeValueFailure = error => ( {
  type: FETCH_CUSTOMER_LIFETIME_VALUE_FAILURE,
  payload: error,
} );

export const clearCustomerLifetimeValue = () => ( { type: CLEAR_CUSTOMER_LIFETIME_VALUE } );
