import api from '../config/Api';

const endpoint = 'v1/session';
const endpointV2 = 'v2/session';

export default {
  getProducts( sessionId, params ) {
    return api.get( `${endpointV2}/${sessionId}/product`, params );
  },

  getProductsBySession( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/product`, params );
  },

  getProduct( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/product/detail`, params );
  },

  saveProduct( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/product`, data );
  },

  saveProductV2( sessionId, data ) {
    return api.post( `${endpointV2}/${sessionId}/product`, data );
  },

  duplicateProduct( sessionId, data ) {
    return api.post( `${endpointV2}/${sessionId}/product/duplicate`, data );
  },
};
