import { takeEvery, put, call } from 'redux-saga/effects';
import { getMarketSizeStimation } from 'modules/Market/MarketServices';
import {
  FETCH_MARKET_STIMATE_REQUEST,
  fetchMarketStimateFailure,
  fetchMarketStimateSuccess,
} from '../actions/MarketStimateActions';

function* fetchMarketStimateSaga( action ) {
  try {
    const { sessionId, params = { limit: -1 } } = action.payload;
    const marketStimateData = yield call(
      getMarketSizeStimation,
      sessionId,
      params,
    );
    yield put( fetchMarketStimateSuccess( marketStimateData ) );
  } catch ( error ) {
    yield put( fetchMarketStimateFailure( error ) );
  }
}

export default function* marketStimateRootSaga() {
  yield takeEvery( FETCH_MARKET_STIMATE_REQUEST, fetchMarketStimateSaga );
}
