import { DialogTitle, IconButton, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import IntlMessages from 'utils/IntlMessages';

const KotlerHeaderFullWidthModal = ({ title, onClose ,noTrasnlate}) => {
    return (
        <DialogTitle className="p-0 pb-1">
            <Paper elevation={2} square className="p-2 px-4 bg-dark" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" style={{ letterSpacing: "1px", fontWeight: 400, fontSize: 18, borderBottom: " 1px solid #2CD5C4", padding:"2px 5px" }} color="textSecondary">
                    {title && !noTrasnlate ?
                        <IntlMessages id={title} />:title}
                </Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className="square-icon-button"
                        onClick={onClose}
                        style={!title ? { position: 'absolute', top: 20, right: 20 } : {}}
                    >
                        <Close className="text-white" />
                    </IconButton>
                ) : null}
            </Paper>
        </DialogTitle>
    )
}
export default KotlerHeaderFullWidthModal;