// rootSaga.js

import { all } from 'redux-saga/effects';
import totalCustomerRootSaga from './totalCustomersSaga';
import customerEvolutionRootSaga from './customerEvolutionSaga';
import customerLifetimeValueRootSaga from './customerLifetimeValueSaga';
import customerAdquisitionCostRootSaga from './customerAdquisitionCostSaga';

function* customerRootSaga() {
  yield all( [
    // Otras sagas de la aplicación
    totalCustomerRootSaga(),
    customerEvolutionRootSaga(),
    customerLifetimeValueRootSaga(),
    customerAdquisitionCostRootSaga(),
  ] );
}

export default customerRootSaga;
