import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Divider } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';

import CardBox from 'components/theme/CardBox';
import { TextField, Select, Buttons } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import LicenseService from 'services/License';
import IntlMessages from 'utils/IntlMessages';
import { composeValidators, required, equalTo } from 'config/InputErrors';
import logo from 'assets/images/logo.png';
import UserActions from 'store/reducers/User';

class SignUp extends React.Component {
  state = { data: {} };

  componentDidMount() {
    const { match, accessToken, logoutUser } = this.props;
    if ( accessToken ) logoutUser();
    this.token = match.params.token;
    this.getEntity();
  }

  getEntity = () => {
    const { toggleLoading, history } = this.props;
    toggleLoading( true );

    LicenseService.getLicenseByToken( this.token ).then( ( response ) => {
      toggleLoading( false );

      if ( response.ok ) {
        this.setState( { data: response.data } );
      } else {
        history.push( '/login' );
      }
    } );
  };

  submitForm = ( data ) => {
    const { toggleLoading, toggleAlert, history } = this.props;
    const dataToSend = {
      workGroup: data.workGroup,
      name: data.user.name,
      lastName: data.user.lastName,
      phone: data.user.phone,
      plainPassword: data.plainPassword,
    };

    toggleLoading( true );

    LicenseService.updateLicenseByToken( this.token, dataToSend ).then(
      ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        toggleAlert( 'successfullyRegistered', 'info' );
        history.push( '/login' );
      },
    );
  };

  render() {
    const { data } = this.state;

    return (
      <div className="app-wrapper overflow-auto">
        <CardBox styleName="col-12 p-0" cardStyle="pt-0 px-0">
          <div className="bg-primary p-3 text-white text-center">
            <img src={logo} alt="MK" title="MK" style={{ width: '150px' }} />
          </div>
          <div className="row mt-3 px-3">
            <div className="col-md-12">
              <h2>
                <IntlMessages id="signup.invitation.title" />
              </h2>
            </div>
          </div>
          <div className="row mt-3 px-3">
            <div className="col-md-6">
              <h4>{_.get( data, 'entity.name', '---' )}</h4>
              <Divider />

              <p className="mt-3">
                <IntlMessages id="address" />
:
                {' '}
                {_.get( data, 'entity.address', '---' )}
              </p>
              <p>
                <IntlMessages id="contact" />
:
                {' '}
                {_.get( data, 'entity.contactName', '---' )}
              </p>
              <p className="mb-4">
                <IntlMessages id="email" />
:
                {' '}
                {_.get( data, 'entity.contactEmail', '---' )}
              </p>
              {_.get( data, 'session.startDate' ) && (
                <>
                  <h4>{_.get( data, 'session.name', '---' )}</h4>
                  <Divider />

                  <p className="mt-3">
                    <IntlMessages id="startDate" />
:
                    {' '}
                    {_.get( data, 'session.startDate' )
                      ? moment( Number( data.session.startDate ) ).format(
                        'DD/MM/YYYY',
                      )
                      : '---'}
                  </p>
                  <p>
                    <IntlMessages id="endDate" />
:
                    {' '}
                    {_.get( data, 'session.endDate' )
                      ? moment( Number( data.session.endDate ) ).format(
                        'DD/MM/YYYY',
                      )
                      : '---'}
                  </p>
                </>
              )}
            </div>
            <div className="col-md-6">
              <Form
                initialValues={data}
                onSubmit={this.submitForm}
                render={( { handleSubmit, form } ) => (
                  <form onSubmit={handleSubmit}>
                    <h4>
                      <IntlMessages id="signup.finishRegistration.title" />
                    </h4>
                    <Divider />
                    <div className="row">
                      <div className="col-md-12">
                        {!!data && data.owner !== 'sup' && (
                          <Select
                            field="workGroup"
                            label="workGroup"
                            validate={required}
                            translateOptions={false}
                            options={
                              data && data.session
                                ? data.session.workGroups
                                : []
                            }
                          />
                        )}
                        <TextField
                          field="user.name"
                          label="name"
                          validate={required}
                        />
                        <TextField field="user.lastName" label="lastName" />
                        <TextField field="user.phone" label="phone" />
                        {_.get( data, 'user.firstTime' ) ? (
                          <>
                            <TextField
                              field="plainPassword"
                              label="password"
                              type="password"
                              validate={required}
                            />
                            <TextField
                              field="passwordConfirmation"
                              label="passwordConfirmation"
                              type="password"
                              translateValues={{ label: <IntlMessages id="password" /> }}
                              validate={composeValidators(
                                required,
                                equalTo( 'plainPassword' ),
                              )}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-right">
                        <Buttons cancelTo="/login" />
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { accessToken: user.access_token } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  logoutUser: UserActions.logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( SignUp );
