import { useDispatch, useSelector } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';

export const useToggleLoading = () => {
  const isLoading = useSelector( ( { settings } ) => settings.isLoading );
  const dispatch = useDispatch();
  const setLoading = ( value = true ) => {
    dispatch( SettingsActions.toggleLoading( value ) );
  };
  return { isLoading, setLoading };
};
export const useToggleAlert = () => {
  const alert = useSelector( ( { settings } ) => settings.alert );
  const dispatch = useDispatch();
  const pushAlert = ( message, mode = 'danger', translate = false ) => {
    dispatch( SettingsActions.toggleAlert( message, mode, translate ) );
  };
  return { alert, pushAlert };
};
export const useLocale = () => {
  const locale = useSelector( ( { settings } ) => settings.locale );
  const dispatch = useDispatch();
  const changeLanguage = ( message, mode = 'danger', translate = false ) => {
    dispatch( SettingsActions.switchLanguage( message, mode, translate ) );
  };
  return { locale, changeLanguage };
};
