import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import {
  reducer as getLiveAd,
  rootSaga as getLiveAdSaga,
} from './liveAdReducer';

export {
  reducer as getLiveAdReducer,
  rootSaga as getLiveAdSaga,
  Creators as getLiveAdActions,
} from './liveAdReducer';

// reducer roots
export const liveAdsRootReducer = combineReducers( { getLiveAd } );

// sagas root
export function* liveAdsRootSaga() {
  yield all( [getLiveAdSaga()] );
}
