import React from 'react';
import CardBox from 'components/custom_v2/CardBox';

import { connect } from 'react-redux';
import _ from 'lodash';
// import CategoryService from 'services/Category';
import DashboardStudentService from 'services/DashboardStudent';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartModal from 'routes/App/components/User/Chart';
import Util from 'utils/Util';
import { Grid, Hidden, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-products.jpeg';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import SectionInfoText from 'components/custom/SectionInfoText';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import SettingsActions from '../../../../../../../../store/reducers/Settings';


class ProductCharts extends React.Component {
  state = {
    chartModal: false,
    seriesTitleBundle1: [],
    seriesTitle1: [],
    seriesTitle2: [],
    seriesTitle3: [],
  };


  componentDidMount() {
    this.loadComponent();
  }

  componentDidUpdate( prevProps ) {
    const { locale } = this.props;
    if ( prevProps.locale.locale !== locale.locale ) {
      am4core.disposeAllCharts();
      this.loadComponent();
    }
  }

  componentWillUnmount() {
    am4core.disposeAllCharts();
  }

  getDataSerie=( categories, roundsCat = [], seriesTitle, param ) => {
    _.map( categories, ( item ) => {
      _.map( item.historical, ( round ) => {
        const exist = _.findIndex( roundsCat, { id: round.round.id } );
        if ( exist === -1 ) {
          const dataToPush = { attribute: round.round.shortName, id: round.round.id };
          dataToPush[item.id] = round[param];
          roundsCat.push( dataToPush );
        } else {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][item.id] = round[param];
        }
      } );
      seriesTitle.push( item.name );
    } );
  };

  createCharSerie=( key, roundsCat, categories, param, param1, xAxesTitle, yAxesTitle ) => {
    const { intl } = this.props;
    const roundsCatTraduce = roundsCat.map( round => ( { ...round, attribute: intl.formatMessage( { id: round.attribute.split( ' ' )[0] }, { value: round.attribute.split( ' ' )[1] } ) } ) );
    const config = {
      data: roundsCatTraduce,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
        title: { text: intl.formatMessage( { id: xAxesTitle } ) || '' },
      }],
      yAxes: [{ type: 'ValueAxis', title: { text: intl.formatMessage( { id: yAxesTitle } ) || '' } }],
      series: _.map( categories, itemX => ( {
        type: 'LineSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[param],
      lastsUnitSales: item[param1],
    } ) );
    return { config, chart, table };
  };


  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  async loadComponent() {
    const { user, toggleLoading } = this.props;
    toggleLoading( true );
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    await DashboardStudentService.getHistoricalBundle( user.activeSession.id,
      { workgroup: this.license.workGroup.id, limit: -1, 'sortby[createdAt]': 'ASC' } )
      .then( ( response ) => {
        if ( response.ok ) {
          const allData = _.cloneDeep( response.data.data );
          const roundsBundle1 = [];
          const seriesTitleBundle1 = [];

          this.getDataSerie( allData, roundsBundle1, seriesTitleBundle1, 'unitSales' );
          const result1 = this.createCharSerie( 'bundleunitsale', roundsBundle1, allData, 'unitSales', 'lastsUnitSales', 'Rounds', 'SalesU' );
          this.configBundle1 = _.cloneDeep( result1.config );
          this.chartBundle1 = result1.chart;
          const tableBundle1 = result1.table;

          this.getDataSerie( allData, roundsBundle1, seriesTitleBundle1, 'revenues' );
          const result2 = this.createCharSerie( 'bundlerevenuesale', roundsBundle1, allData, 'revenues', 'lastsRevenues', 'Rounds', 'SalesS' );
          this.configBundle2 = _.cloneDeep( result2.config );
          this.chartBundle2 = result2.chart;
          const tableBundle2 = result2.table;

          this.setState( ( {
            tableBundle1,
            seriesTitleBundle1,
            tableBundle2,
          } ) );
        }
      } );

    await DashboardStudentService.getHistoricalProducts( user.activeSession.id,
      this.license.workGroup.id, { limit: -1, 'sortby[createdAt]': 'ASC' } )
      .then( ( response ) => {
        if ( response.ok ) {
          const allData = _.cloneDeep( response.data.data );
          const categories1 = _.filter( allData, { category: { label: 'CATEGORY_1' } } );
          const categories2 = _.filter( allData, { category: { label: 'CATEGORY_2' } } );
          const categories3 = _.filter( allData, { category: { label: 'CATEGORY_3' } } );
          const roundsCat1 = [];
          const roundsCat2 = [];
          const roundsCat3 = [];
          const seriesTitle1 = [];
          const seriesTitle2 = [];
          const seriesTitle3 = [];

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'unitSales' );
          const result1 = this.createCharSerie( 'laptopsalesbybrandu', roundsCat1, categories1, 'unitSales', 'lastsUnitSales', 'Rounds', 'SalesU' );
          this.config1 = _.cloneDeep( result1.config );
          this.chart = result1.chart;
          const table1 = result1.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'revenues' );
          const result11 = this.createCharSerie( 'laptopsalesbybrandrevenuew', roundsCat1, categories1, 'revenues', 'lastsRevenues', 'Rounds', 'SalesS' );
          this.config11 = _.cloneDeep( result11.config );
          this.chart11 = result11.chart;
          const table11 = result11.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'profits' );
          const result12 = this.createCharSerie( 'laptopsalesbybrandprofits', roundsCat1, categories1, 'profits', 'lastsProfits', 'Rounds', 'ProfitS' );
          this.config12 = _.cloneDeep( result12.config );
          this.chart12 = result12.chart;
          const table12 = result12.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'percentDirectSales' );
          const result13 = this.createCharSerie( 'laptopsalesbybrandsale', roundsCat1, categories1, 'percentDirectSales', 'lastPercentDirectSales', 'Rounds', 'DirectSalesPercent' );
          this.config13 = _.cloneDeep( result13.config );
          this.chart13 = result13.chart;
          const table13 = result13.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'unitSales' );
          let result2 = this.createCharSerie( 'tabletsalesbybrandu', roundsCat2, categories2, 'unitSales', 'lastsUnitSales', 'Rounds', 'SalesU' );
          this.config2 = _.cloneDeep( result2.config );
          this.chart2 = result2.chart;
          const table2 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'revenues' );
          result2 = this.createCharSerie( 'tabletsalesbybrandrevenue', roundsCat2, categories2, 'revenues', 'lastsRevenues', 'Rounds', 'SalesS' );
          this.config21 = _.cloneDeep( result2.config );
          this.chart21 = result2.chart;
          const table21 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'profits' );
          result2 = this.createCharSerie( 'tabletsalesbybrandprofits', roundsCat2, categories2, 'profits', 'lastsProfits', 'Rounds', 'ProfitS' );
          this.config22 = _.cloneDeep( result2.config );
          this.chart22 = result2.chart;
          const table22 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'percentDirectSales' );
          result2 = this.createCharSerie( 'tabletsalesbybrandsale', roundsCat2, categories2, 'percentDirectSales', 'lastPercentDirectSales', 'Rounds', 'DirectSalesPercent' );
          this.config23 = _.cloneDeep( result2.config );
          this.chart23 = result2.chart;
          const table23 = result2.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'unitSales' );
          let result3 = this.createCharSerie( 'cellsalesbybrandu', roundsCat3, categories3, 'unitSales', 'lastsUnitSales', 'Rounds', 'SalesU' );
          this.config3 = _.cloneDeep( result3.config );
          this.chart3 = result3.chart;
          const table3 = result3.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'revenues' );
          result3 = this.createCharSerie( 'cellsalesbybrandrevenue', roundsCat3, categories3, 'revenues', 'lastsRevenues', 'Rounds', 'SalesS' );
          this.config31 = _.cloneDeep( result3.config );
          this.chart31 = result3.chart;
          const table31 = result3.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'profits' );
          result3 = this.createCharSerie( 'cellsalesbybrandprofits', roundsCat3, categories3, 'profits', 'lastsProfits', 'Rounds', 'ProfitS' );
          this.config32 = _.cloneDeep( result3.config );
          this.chart32 = result3.chart;
          const table32 = result3.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'percentDirectSales' );
          result3 = this.createCharSerie( 'cellsalesbybrandsale', roundsCat3, categories3, 'percentDirectSales', 'lastPercentDirectSales', 'Rounds', 'DirectSalesPercent' );
          this.config33 = _.cloneDeep( result3.config );
          this.chart33 = result3.chart;
          const table33 = result3.table;


          this.setState( ( {
            table1,
            table2,
            table3,
            table11,
            table12,
            table13,
            table21,
            table22,
            table23,
            table33,
            table32,
            table31,
            seriesTitle1,
            seriesTitle2,
            seriesTitle3,
          } ) );
        }
      } );
    toggleLoading( false );
  }
  render() {
    const {
      chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
      table1,
      table2,
      table3,
      table11,
      table12,
      table13,
      table21,
      table22,
      table23,
      table33,
      table32,
      table31,
      seriesTitle1,
      seriesTitle2,
      seriesTitle3,
      tableBundle1,
      tableBundle2,
      seriesTitleBundle1,
    } = this.state;
    const cardHeaderProps = { minHeight: '54px', className: '', marginBottom: '0px' };
    const gridItemProps = { xs: 12, sm: 12, md: 6, lg: 4 };
    const cardBoxProps = { styleName: 'px-3 h-100 mb-0' };
    const contentGraphicProps = { style: { width: '100%', height: '220px' }, className: 'jr-link mt-0' };
    return (
      <div>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'InformationDashboard-products' },
          ]}
        />
        <KotlerSectionHeader title="products" image={HeaderImage} />
        <SectionInfoText title="info.product.HelpTextCharts" />

        <Grid container spacing={2} justify="center">
          {/* laptops */}

          <Hidden smDown>
            <Grid item xs={12} sm={4} md={4}>
              <KotlerTableTitle title="Laptops" icon="monitor" />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <KotlerTableTitle title="Tablets" icon="tablet" />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <KotlerTableTitle title="cellPhones" icon="smartphone" />
            </Grid>
          </Hidden>


          <Grid {...gridItemProps} item>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="LaptopSalesU" />}
              onClick={() => this.toggleChartModal( this.config1, 'LaptopSalesU', seriesTitle1 )}
            >
              <div {...contentGraphicProps} id="laptopsalesbybrandu" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table1, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />
              </div>
              <FooterInfoCardGraphic />
            </CardBox>

          </Grid>
          <Grid {...gridItemProps} item>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="TabletSalesU" />}
              onClick={() => this.toggleChartModal( this.config2, 'TabletSalesU', seriesTitle2 )}
            >
              <div {...contentGraphicProps} id="tabletsalesbybrandu" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table2, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />
              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>
          <Grid {...gridItemProps} item md={12}>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="CellPhoneSalesU" />}

              onClick={() => this.toggleChartModal( this.config3, 'CellPhoneSalesU', seriesTitle3 )}
            >
              <div {...contentGraphicProps} id="cellsalesbybrandu" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table3, item => ( [item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />

              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>

          <Grid {...gridItemProps} item>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="LaptopSalesRevenue" />}
              onClick={() => this.toggleChartModal( this.config11, 'LaptopSalesRevenue', seriesTitle1 )}
            >
              <div {...contentGraphicProps} id="laptopsalesbybrandrevenuew" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table11, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />

              </div>
              <FooterInfoCardGraphic />
            </CardBox>

          </Grid>
          <Grid {...gridItemProps} item>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="TabletSalesRevenue" />}
              onClick={() => this.toggleChartModal( this.config21, 'TabletSalesRevenue', seriesTitle2 )}
            >
              <div {...contentGraphicProps} id="tabletsalesbybrandrevenue" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table21, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />

              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>
          <Grid {...gridItemProps} item md={12}>
            <CardBox
              styleName="h-100"
              heading="CellPhoneSalesRevenue"
              header={<KotlerCardHeader {...cardHeaderProps} title="CellPhoneSalesRevenue" />}
              onClick={() => this.toggleChartModal( this.config31, 'CellPhoneSalesRevenue', seriesTitle3 )}
            >
              <div {...contentGraphicProps} id="cellsalesbybrandrevenue" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table31, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />
              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>

          <Grid {...gridItemProps} item>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="LaptopSalesProfits" />}
              onClick={() => this.toggleChartModal( this.config12, 'LaptopSalesProfits', seriesTitle1 )}
            >
              <div {...contentGraphicProps} id="laptopsalesbybrandprofits" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.profits" />,
                    <IntlMessages id="market.profits.previous.month" />,
                  ]}
                  cells={_.map( table12, item => ( [
                    item.name,
                    Util.formatNumberNoDecimals( item.unitSales ),
                    item.name,
                    Util.formatNumberNoDecimals( item.lastsUnitSales ),
                  ] ) )}
                />

              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>
          <Grid {...gridItemProps} item>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="TabletSalesProfits" />}
              onClick={() => this.toggleChartModal( this.config22, 'TabletSalesProfits', seriesTitle2 )}
            >
              <div {...contentGraphicProps} id="tabletsalesbybrandprofits" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.profits" />,
                    <IntlMessages id="market.profits.previous.month" />,
                  ]}
                  cells={_.map( table22, item => ( [
                    item.name,
                    Util.formatNumberNoDecimals( item.unitSales ),
                    item.name,
                    Util.formatNumberNoDecimals( item.lastsUnitSales ),
                  ] ) )}
                />

              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>
          <Grid {...gridItemProps} item md={12}>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="CellphoneSalesProfits" />}
              onClick={() => this.toggleChartModal( this.config32, 'CellphoneSalesProfits', seriesTitle3 )}
            >
              <div {...contentGraphicProps} id="cellsalesbybrandprofits" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.profits" />,
                    <IntlMessages id="market.profits.previous.month" />,
                  ]}
                  cells={_.map( table32, item => ( [
                    item.name,
                    Util.formatNumberNoDecimals( item.unitSales ),
                    item.name,
                    Util.formatNumberNoDecimals( item.lastsUnitSales ),
                  ] ) )}
                />
              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>

          <Grid {...gridItemProps} item>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="LaptopsDirectSales" />}
              onClick={() => this.toggleChartModal( this.config13, 'LaptopsDirectSales', seriesTitle1 )}
            >
              <div {...contentGraphicProps} id="laptopsalesbybrandsale" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table13, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />

              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>
          <Grid {...gridItemProps} item>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="TabletDirectSales" />}
              onClick={() => this.toggleChartModal( this.config22, 'TabletDirectSales', seriesTitle2 )}
            >
              <div {...contentGraphicProps} id="tabletsalesbybrandsale" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table23, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />
              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>
          <Grid {...gridItemProps} item md={12}>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="CellPhoneDirectSales" />}
              onClick={() => this.toggleChartModal( this.config33, 'CellPhoneDirectSales', seriesTitle3 )}
            >
              <div {...contentGraphicProps} id="cellsalesbybrandsale" />
              <div>
                <TableCardGraphic
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( table33, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />
              </div>
              <FooterInfoCardGraphic />
            </CardBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="BundlesU" />}
              onClick={() => this.toggleChartModal( this.configBundle1, 'BundlesU', seriesTitleBundle1 )}
            >
              <div {...contentGraphicProps} id="bundleunitsale" />
              <div>
                <TableCardGraphic
                  originalPadding
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( tableBundle1, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />
              </div>
            </CardBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBox
              {...cardBoxProps}
              header={<KotlerCardHeader {...cardHeaderProps} title="BundlesRevenue" />}
              onClick={() => this.toggleChartModal( this.configBundle2, 'BundlesRevenue', seriesTitleBundle1 )}
            >
              <div {...contentGraphicProps} id="bundlerevenuesale" />
              <div>
                <TableCardGraphic
                  originalPadding
                  titles={[
                    <IntlMessages id="market.last.month.sales" />,
                    <IntlMessages id="market.sales.previous.month" />,
                  ]}
                  cells={_.map( tableBundle2, item => ( [
                    item.name,
                    Util.formatNumber( item.unitSales ),
                    item.name,
                    Util.formatNumber( item.lastsUnitSales ),
                  ] ) )}
                />
              </div>
            </CardBox>
          </Grid>
        </Grid>


        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />

      </div>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};

const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };
const TableCardGraphic = ( { titles = [], cells = [], originalPadding } ) => (
  <Table className="kotler-table mt-1" style={{ minHeight: 65 }}>
    <TableHead>
      <TableRow>
        {titles.map( item => (
          <TableCell colspan={2} className={!originalPadding ? 'px-2' : null}>
            {item}
          </TableCell>
        ) )}
      </TableRow>
    </TableHead>
    <TableBody>
      {cells.map( rows => (
        <TableRow>
          {rows.map( ( cell, index ) => (
            <TableCell align={( index + 1 ) % 2 === 0 ? 'right' : 'left'} className={!originalPadding ? 'px-2' : null}>
              {cell}
            </TableCell>
          ) )}
        </TableRow>
      ) )}
    </TableBody>
  </Table>
);
const FooterInfoCardGraphic = ( { title } ) => (
  <Typography style={{
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#999FA3',
    marginTop: '16px',
  }}
  >
    {title ? <IntlMessages id={title} /> : <IntlMessages id="info.product.HelpText" />}

  </Typography>
);
export default connect( mapStateToProps, mapDispatchToProps )( injectIntl( ProductCharts ) );
