import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { ChangelogCard } from '../components/ChangelogCard/ChangelogCard';
import { useChangelogsQuery } from '../hooks/useChangelogsQuery';

export const ChangelogView = () => {
  const { logsQuery } = useChangelogsQuery();
  if ( logsQuery.isLoading
  ) return <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100 "><CircularProgress /></div>;
  return (
    <div className="d-flex flex-column w-100 ">
      {logsQuery.data && logsQuery.data.map( changelog => (
        <ChangelogCard changelog={changelog} />
      ) )}
    </div>
  );
};
export default ChangelogView;
