import Error404 from 'components/theme/Error404';
import { Route, Switch } from 'react-router-dom';

import React from 'react';
import ChangelogDashboard from './Dashboard';
import NewChangeLog from './New';
import ChangelogDetail from './details';

export default ( { match } ) => (
  <Switch>
    <Route path={`${match.url}/`} exact component={ChangelogDashboard} />
    <Route
      path={`${match.url}/:changelogId`}
      exact
      component={ChangelogDetail}
    />
    <Route path={`${match.url}/new`} exact component={NewChangeLog} />
    <Route component={Error404} />
  </Switch>
);
