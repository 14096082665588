import React from 'react';
import { connect } from 'react-redux';

import UserService from 'services/User';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';
import picture from 'assets/images/kotler/profile.png';
import AccountProfile from './AccountProfile';

class ProfileSettings extends React.Component {
  state = {
    readOnly: true,
    isOpen: false,
    uploadAvatar: null,
    data: {},
  };

  static getDerivedStateFromProps( props ) {
    return {
      data: {
        name: props.user.name,
        lastName: props.user.lastName,
        avatar: props.user.avatar,
        email: props.user.email,
      },
    };
  }

  handleEdit = () => {
    this.setState( previousState => ( { readOnly: !previousState.readOnly } ) );
  };

  handleClose = () => this.setState( { isOpen: false } );

  handleAvatarEditor = () => this.setState( { isOpen: true } );

  handleEditAvatar = ( src ) => {
    const { toggleLoading, toggleAlert, updateInternalAvatar } = this.props;

    toggleLoading( true );

    UserService.updateUserProfile( { uploadAvatar: src } ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      updateInternalAvatar( response.data.avatar );
      this.setState( { isOpen: false, uploadAvatar: src } );
      toggleAlert( 'profileUpdated', 'info' );
    } );
  };

  render() {
    const { isOpen, uploadAvatar, data, readOnly } = this.state;

    return data && data.name ? (


      <AccountProfile
        profileProps={{
          defaultPicture: picture,
          src: data.avatar || picture,
          newSrc: uploadAvatar,
          isOpen,
          editAvatar: this.handleEditAvatar,
          avatarEditor: this.handleAvatarEditor,
          onClose: this.handleClose,
        }}
        readOnly={readOnly}
        onEdit={this.handleEdit}
        user={data}
      />

    ) : null;
  }
}
const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateInternalAvatar: UserActions.updateAvatar,
};

export default connect( null, mapDispatchToProps )( ProfileSettings );
