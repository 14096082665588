import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { map } from 'lodash';
import CardBox from 'components/custom_v2/CardBox';
import { formatRoundName } from 'helpers/IntlHelpers';
import KotlerPagination from 'components/custom/KotlerPagination';

const formatDataToTable = ( data ) => {
  if ( !data || !data.length ) return [];
  return data.map( ( { empl, exec, home, prof, round, students } ) => ( {
    name: round.shortName,
    empl: Util.formatNumber( empl ),
    exec: Util.formatNumber( exec ),
    home: Util.formatNumber( home ),
    prof: Util.formatNumber( prof ),
    students: Util.formatNumber( students ),
  } ) );
};

const TablesLastRoundService = ( {
  data,
  disableDecoration = false,
  //   loading,
  //   error,
  className = '',
} ) => {
  const [dataToTable, setDataToTable] = useState( [] );
  useEffect( () => {
    const formatData = formatDataToTable( data );
    setDataToTable( formatData );
  }, [data] );
  const Soon = () => (
    <KotlerPagination data={dataToTable} initialPageSize={5}>
      {( { pageData } ) => (
        <div
          style={{
            maxHeight: 225,
            overflowY: 'auto',
          }}
          className="w-100"
        >
          <Table className="kotler-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>
                    <IntlMessages id="round" />
                  </strong>
                </TableCell>
                <TableCell align="right">
                  <IntlMessages id="crm.media.exec" />
                </TableCell>
                <TableCell align="right">
                  <IntlMessages id="crm.media.prof" />
                </TableCell>
                <TableCell align="right">
                  <IntlMessages id="crm.media.empl" />
                </TableCell>
                <TableCell align="right">
                  <IntlMessages id="crm.media.students" />
                </TableCell>
                <TableCell align="right">
                  <IntlMessages id="crm.media.home" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map( pageData, ( { name, empl, exec, home, prof, students } ) => (
                <TableRow key={name}>
                  <TableCell className="text-primary">
                    {formatRoundName( name )}
                  </TableCell>
                  <TableCell align="right">{exec}</TableCell>
                  <TableCell align="right">{prof}</TableCell>
                  <TableCell align="right">{empl}</TableCell>
                  <TableCell align="right">{students}</TableCell>
                  <TableCell align="right">{home}</TableCell>
                </TableRow>
              ) )}
              {( !dataToTable || !dataToTable.length ) && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <IntlMessages id="NoDataFound" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </KotlerPagination>
  );
  return disableDecoration ? (
    <Soon />
  ) : (
    <CardBox styleName={`px-2  mt-3 ${className}`} header={<div />}>
      <Soon />
    </CardBox>
  );
};

const mapStateToProps = (
  {
    market: { marketStimate: { data, loading, error } },
    settings,
  },
  { attribute },
) => ( attribute && data
  ? {
    data: data[attribute],
    loading,
    error,
    locale: settings.locale,
  }
  : {
    data: null,
    loading,
    error,
    locale: settings.locale,
  } );

export default connect( mapStateToProps )( TablesLastRoundService );
