import { useQuery } from 'react-query';
import moment from 'moment';
import { useMemo } from 'react';
import { getLastGeneratedAiReport } from '../services';

export const useAiReportQuery = (
  sessionId,
  { refetchInterval } = { refetchInterval: 1000 * 60 * 60 },
) => {
  const query = useQuery(
    ['v2', 'session', sessionId, 'report-ai'],
    () => getLastGeneratedAiReport( sessionId ),
    {
      retry: 0,
      enabled: !!sessionId,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval,
    },
  );

  const reportData = useMemo( () => {
    const data = query.data || {};
    const isfailed = data.creationStatus === 'failed';
    const isGenerated = data.creationStatus === 'successful';
    const isGenerating = data.creationStatus === 'pending';
    const neverGenerated = query.isError && !query.isLoading;

    const creationDate = data.createdAt
      ? new Date( Number( data.createdAt ) )
      : null;
    const creationMoment = moment( creationDate );
    return {
      ...data,
      neverGenerated,
      isGenerating,
      isGenerated,
      isfailed,
      loading: query.isLoading || isGenerating,
      canNewGenerate:
        isfailed
        || neverGenerated
        || ( isGenerated
          && creationDate
          && creationMoment.isBefore( moment().subtract( 3, 'days' ) ) )
        || ( query.isError && !query.isLoading && !query.isFetching ),

      creationDate,
      creationMoment,
    };
  }, [query] );
  return {
    query,
    loading: reportData.loading,
    reportData,
  };
};
