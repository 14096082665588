export const FETCH_ALL_ROUNDS_BUNDLES_REQUEST = 'FETCH_ALL_ROUNDS_BUNDLES_REQUEST';
export const FETCH_ALL_ROUNDS_BUNDLES_SUCCESS = 'FETCH_ALL_ROUNDS_BUNDLES_SUCCESS';
export const FETCH_ALL_ROUNDS_BUNDLES_FAILURE = 'FETCH_ALL_ROUNDS_BUNDLES_FAILURE';
export const CLEAR_ALL_ROUNDS_BUNDLES = 'CLEAR_ALL_ROUNDS_BUNDLES';

export const fetchAllRoundsBundlesRequest = ( sessionId, params ) => ( {
  type: FETCH_ALL_ROUNDS_BUNDLES_REQUEST,
  payload: { sessionId, params },
} );

export const fetchAllRoundsBundlesSuccess = data => ( {
  type: FETCH_ALL_ROUNDS_BUNDLES_SUCCESS,
  payload: data,
} );

export const fetchAllRoundsBundlesFailure = error => ( {
  type: FETCH_ALL_ROUNDS_BUNDLES_FAILURE,
  payload: error,
} );

export const clearAllRoundsBundles = () => ( { type: CLEAR_ALL_ROUNDS_BUNDLES } );
