import {
  FETCH_MARKET_STIMATE_REQUEST,
  FETCH_MARKET_STIMATE_SUCCESS,
  FETCH_MARKET_STIMATE_FAILURE,
  CLEAR_MARKET_STIMATE,
} from '../actions/MarketStimateActions';

/**
 * @typedef {object} Round
 * @property {string} id
 * @property {string} name
 * @property {number} nameD
 * @property {string} shortName
 * @property {string} largeName
 */

/**
 * @typedef {object} MarketEstimate
 * @property {Round} round
 * @property {number} exec
 * @property {number} prof
 * @property {number} empl
 * @property {number} home
 * @property {number} students
 */

/**
 * @typedef {object} MarketStimateData
 * @property {MarketEstimate[]} LAPTOPS - Lista de estimaciones de mercado latops.
 * @property {MarketEstimate[]} TABLET - Lista de estimaciones de mercado tablets .
 * @property {MarketEstimate[]} "CEll PHONES" - Lista de estimaciones de mercado telefonos.
 * @property {MarketEstimate[]} MUSIC - Lista de estimaciones de mercado del servicio de musica.
 * @property {MarketEstimate[]} NEWS - Lista de estimaciones de mercado del servicio de Noticias.
 * @property {MarketEstimate[]} "SERIES & MOVIES" - Lista de estimaciones de mercado del servicio
 * de Series y peliculas.
 */

const initialState = {
  data: /** @type {MarketStimateData} */ ( null ),
  loading: false,
  error: null,
};

const marketStimateReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_MARKET_STIMATE_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case FETCH_MARKET_STIMATE_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: null,
    };
  case FETCH_MARKET_STIMATE_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case CLEAR_MARKET_STIMATE:
    return {
      ...state,
      ...initialState,
    };
  default:
    return state;
  }
};

export default marketStimateReducer;
