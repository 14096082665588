import React, { useEffect, useState } from 'react';


import PieGraphFull from 'components/StatisticalGraphs/containers/PieGraphFull';
import { findIndex } from 'lodash';
import { useActiveSessionId, useMyCurrentWorkgroupId } from 'modules/sessions';
import { z } from 'zod';
import { useLastRoundServicesQuery } from '../hooks';
import { LastRoundServicesSchema } from '../schemas/last-round-schemas';

const formatData = ( data, key = 'unitSales' ) => {
  const keyValidated = z.enum( ['unitSales', 'price', 'totalSales'] ).parse( key );
  if ( !data ) return {};
  const formatedData = LastRoundServicesSchema.transform( ( d ) => {
    const dataFormated = [];
    d.map( ( { workgroupData } ) => workgroupData.map( ( { name: group, services } ) => {
      const exist = findIndex( dataFormated, { group } );

      const value = services.services.reduce(
        ( prev, serv ) => prev + serv[keyValidated],
        0,
      );
      if ( exist === -1 ) {
        return dataFormated.push( {
          group,
          value,
        } );
      }
      dataFormated[exist].value += value;
      return value;
    } ) );
    return dataFormated;
  } ).parse( data );

  return { data: formatedData };
};

const PieGraphLastRoundServices = ( {
  gId = 1,
  graphHeight = '315px',
  title = 'market.services_information',
  hoverTitle,
  children,
} ) => {
  const [dataToGraph, setDataToGraph] = useState( {} );
  const sessionId = useActiveSessionId();
  const workgroupId = useMyCurrentWorkgroupId();
  const { data, query } = useLastRoundServicesQuery( sessionId, workgroupId, { limit: -1 } );

  useEffect( () => {
    const formatedData = formatData( data, 'unitSales' );
    if ( JSON.stringify( dataToGraph ) !== JSON.stringify( formatedData ) ) {
      setDataToGraph( formatedData );
    }
  }, [data, dataToGraph] );

  return (
    <PieGraphFull
      {...{
        title,
        error: query.error,
        loading: query.loading,
        hoverTitle,
        children,
      }}
      height={graphHeight}
      htmlId={`pie_chart_lastround_services_${gId}`}
      data={dataToGraph.data}
    />
  );
};

export default PieGraphLastRoundServices;
