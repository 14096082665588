import { formatMessage, formatRoundName } from 'helpers/IntlHelpers';
import { filter, flatMap, map } from 'lodash';

// Función para formatear los datos de un itemC
const formatServiceRound = ( itemC ) => {
  const arr = flatMap( itemC.serviceData, itemD => [
    itemD.unitSales === 0 ? '-' : itemD.unitSales,
    itemD.totalSales === 0 ? '-' : itemD.totalSales,
  ] );
  return [itemC.name, ...arr];
};

// Función para formatear un item
const formatItem = ( item ) => {
  if ( item.services.length > 0 ) {
    const rounds = flatMap( item.services[0].serviceData, ( itemB ) => {
      const transName = formatRoundName( itemB.round.shortName );
      return [`${transName} (u)`, `${transName} ($)`];
    } );
    const titleSection = [item.workGroupName, ...rounds];

    const dataSection = map( item.services, formatServiceRound );

    return [
      {
        columns: titleSection,
        data: dataSection,
      },
      {
        columns: [''],
        data: [],
      },
    ];
  }
};

// Función principal para formatear todos los datos de las rondas a Excel
export const formatAllRoundServicesToExcel = (
  data,
  title = 'market.services_information',
) => {
  const sheetData = [
    {
      xSteps: 0,
      ySteps: 1,
      columns: [formatMessage( { id: title } )],
      data: [],
    },
  ];

  let resultData = flatMap( data, formatItem );
  resultData = filter( resultData, item => item !== undefined );
  sheetData.push( ...resultData );

  return sheetData;
};

export default { formatAllRoundServicesToExcel };
