import { takeEvery, put, call } from 'redux-saga/effects';

import {
  FETCH_CUSTOMER_LIFETIME_VALUE_REQUEST,
  fetchCustomerLifetimeValueFailure,
  fetchCustomerLifetimeValueSuccess,
} from '../actions/customerLifeTimeValueActions';
import { getCustomerLifetimeValue } from '../../customersService';

function* fetchCustomerLifetimeValueSaga( action ) {
  try {
    const { sessionId, params = { limit: -1 } } = action.payload;
    const customerLifetimeValueData = yield call( getCustomerLifetimeValue, sessionId, params );
    yield put( fetchCustomerLifetimeValueSuccess( customerLifetimeValueData ) );
  } catch ( error ) {
    yield put( fetchCustomerLifetimeValueFailure( error ) );
  }
}

export default function* customerLifetimeValueRootSaga() {
  yield takeEvery( FETCH_CUSTOMER_LIFETIME_VALUE_REQUEST, fetchCustomerLifetimeValueSaga );
}
