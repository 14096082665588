import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';

import async from 'async';
import _ from 'lodash'
import NotificationItem from './NotificationItem';
import CustomScrollbars from '../CustomScrollbars';
import IntlMessages from 'utils/IntlMessages';
import User from 'services/User';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';

const AppNotification = ({user, toggleAlert, notificationsUser}) => {
  
    
  const {notifications,activeSession,access_token,licenses} = user;
    const [loading, setLoading] = useState(false);

    const heightBox = (count) => {
      if (count <= 5 && count > 0){
        return {height: count * 150}
      } else if (count > 5){
        return {height: 600}
      }
      return {height: 120}
    };

const getAndUpdateNotifications = ()=>{
  
  const currentLicense =  _.find(licenses,(license)=>license.session.id === activeSession.id);
  User.getNotificationsUser({ access_token: access_token,sessionId:activeSession.id ,all:true,workgroupId:currentLicense.workGroup.id,roundId:activeSession.currentRound.id })
          .then((response) => {
            if(response.ok && response.data){
            const noficationsFilterRounds= response.data.data;
            if(JSON.stringify(noficationsFilterRounds) !==JSON.stringify(notifications.data))
              notificationsUser({data:noficationsFilterRounds,total:noficationsFilterRounds.length});
            }
          });
}

    useEffect(() => {
      const timeId = setTimeout(() => {
        getAndUpdateNotifications();
      },60000);
  
      return () => { window.clearTimeout(timeId); };
  
    });
    useEffect(() => {
        getAndUpdateNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.activeSession.id]);
    
    const handleClickDel = (idNotification) => {
      const read = { 'isRead': true };
      setLoading(true);
      async.series({
        first: updateNotificationUser.bind(this, idNotification, read, user),
      },(error, results ) => {
        if(error){
          return toggleAlert(error);
        }
        getAndUpdateNotifications()
        setLoading(false);
        return true;
      });
    }

    const updateNotificationUser = ( idNotification, read, user, callback) => {
      User.updateNotificationUser( idNotification, read, user )
        .then( ( response ) => {
          if(response.ok)
            return callback(null, true);
          return callback(response.errors);
        } );
    };
    
const notificationsNoRead = notifications.data.filter(not=>(!not.isAchievement&& !not.isRead) );
    return (
      <CustomScrollbars className="messages-list scrollbar" style={heightBox(notificationsNoRead.length)}>
       {
        notificationsNoRead.length  > 0 ?
         <ul className="list-unstyled" >
          {
            notificationsNoRead.map((notification, index) =>  <NotificationItem key={index} notification={notification} handleClickDel={handleClickDel} loading={loading} />)
          }
        </ul> 
        :
        <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
          <Box p={1} variant="h3">
            <br />
            <h3 style={{ color: '#8C92AC' }}>
              <div>
                <i className="fa fa-inbox fa-2x" /> &nbsp;&nbsp;
                <span style={{paddingBotton:100}}>
                  <IntlMessages id="emptyNotifications" />
                </span> 
              </div>
            </h3>
          </Box>
        </Box>
       }
      </CustomScrollbars>
    );
  
}

const mapStateToProps = ( { user, toggleAlert } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  notificationsUser: UserActions.notificationsUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( AppNotification );