import api from 'config/Api';
import { z } from 'zod';

export const CurrentRoundSchema = z.object( {
  id: z.string().optional(),
  name: z.string().optional(),
  shortName: z.string().optional(),
  manualClosing: z.boolean().optional(),
  closingTime: z.string().optional(),
} );
export const DataSchema = z.object( {
  id: z.string().optional(),
  name: z.string().optional(),
  nameD: z.string().optional(),
  shortName: z.string().optional(),
  largeName: z.string().optional(),
  status: z.string().optional(),
  typeDuration: z.string().optional(),
  startDate: z.string().optional(),
  startDateInMonth: z.string().optional(),
  endDate: z.string().optional(),
  endDateInMonth: z.string().optional(),
  cont: z.number().optional(),
  manualClosing: z.boolean().optional(),
  roundReport: z.string().optional(),
} );

export const RoundsBySessionIdSchema = z.object( {
  total: z.number().optional(),
  data: z.array( DataSchema ).optional(),
  currentRound: CurrentRoundSchema.optional(),
} );
export const StatusSchema = z.enum( ['OPEN', 'CLOSED', 'IN_PROGRESS'] );

export const ParamsSchema = z
  .object( {
    limit: z.number().optional(),
    'filter[status]': StatusSchema.optional(),
    'filter[noStatus]': StatusSchema.optional(),
  } )
  .optional();

// asignar schema a params para autoayuda

export const getRoundsBySession = async ( sessionId, params = ParamsSchema.parse() ) => {
  const paramsToSend = ParamsSchema.parse( params );
  const res = await api.get( `v1/session/${sessionId}/round`, paramsToSend );
  if ( !res.ok ) {
    throw new Error( res.data && res.data.message );
  }
  const data = RoundsBySessionIdSchema.parse( res.data );

  return data;
};
