import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { map } from 'lodash';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import CardBox from 'components/custom_v2/CardBox';
import HoverTitle from 'components/custom_v2/HoverTitle';
import './TableLastRoundBundles.css';

const TablesLastRoundService = ( {
  data,
  total,
  //   loading,
  //   error,
} ) => (
  <CardBox
    styleName="px-2 h-100 mt-3"
    header={
      <KotlerCardHeader minHeight="0px" title="market.bundles_information" />
    }
  >
    <Table className="kotler-table">
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>
              <IntlMessages id="group" />
            </strong>
          </TableCell>
          <TableCell>
            <strong>
              <IntlMessages id="bundle" />
            </strong>
          </TableCell>
          <TableCell align="right">
            <strong>
              <IntlMessages id="salesU" />
            </strong>
          </TableCell>
          <TableCell align="right">
            <strong>
              <IntlMessages id="salesP" />
            </strong>
          </TableCell>
          <TableCell align="right" style={{ width: '30px' }}>
            <strong>
              <IntlMessages id="monthlyFee" />
            </strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map( data, bundle => (
          <ExpandTableRow {...bundle} />
        ) )}
        {!!data && !!data.length && !!total && (
          <TableRow>
            <TableCell>
              <strong>
                <IntlMessages id="total" />
              </strong>
            </TableCell>
            <TableCell />
            <TableCell align="right">{total.unitSales}</TableCell>
            <TableCell align="right">{total.revenues}</TableCell>
            <TableCell />
          </TableRow>
        )}
        {( !data || !data.length ) && (
          <TableRow>
            <TableCell colSpan={5}>
              <IntlMessages id="NoDataFound" />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </CardBox>
);
const formatDataToTable = ( data ) => {
  if ( !data ) return {};

  const dataFormated = [];
  const total = { price: 0, revenues: 0, unitSales: 0 };
  // eslint-disable-next-line
  data.map(({ bundles, name: group }) => bundles.map(
    ( { name, id: key, price, revenues, unitSales, product, service } ) => {
      total.price += price;
      total.unitSales += unitSales;
      total.revenues += revenues;
      return dataFormated.push( {
        key,
        name,
        group,
        price: Util.formatCurrency( price ),
        revenues: Util.formatCurrency( revenues ),
        unitSales: Util.formatNumber( unitSales ),
        product,
        service,
      } );
    },
  ) );

  return {
    data: dataFormated,
    total: {
      price: Util.formatCurrency( total.price ),
      revenues: Util.formatCurrency( total.revenues ),
      unitSales: Util.formatNumber( total.unitSales ),
    },
  };
};
export const ExpandTableRow = ( {
  key,
  name,
  revenues,
  price,
  unitSales,
  group,
  service,
  product,
} ) => {
  const [expand, setExpand] = useState( false );

  return (
    <>
      <TableRow
        onClick={() => setExpand( !expand )}
        key={`${key}-bundle`}
        className="hr-clickeable-table-row"
      >
        <TableCell>{group}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell align="right">{unitSales}</TableCell>
        <TableCell align="right">{revenues}</TableCell>
        <TableCell align="right">{price}</TableCell>
      </TableRow>
      {expand ? (
        <TableRow key={`${key}-detail`} className="hr-expand-row">
          <TableCell colSpan={2} align="center">
            <span>
              <IntlMessages id="product" />
:
            </span>
            <span className="ml-2 text-primary2">{product.name}</span>
          </TableCell>
          <TableCell colSpan={3} align="center">
            <span>
              <IntlMessages id="service" />
:
            </span>
            <HoverTitle value={service.description}>
              <span className="ml-2 text-primary2">{service.name}</span>
            </HoverTitle>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
// eslint-disable-next-line max-len
const mapStateToProps = ( { bundles: { lastRoundBundles: { loading, error, data: bundles } } } ) => {
  if ( !bundles || error || loading ) {
    return {
      total: 0,
      data: null,
      loading,
      error,
    };
  }

  const { data, total } = formatDataToTable( bundles );
  return { data, total, loading, error };
};
export default connect( mapStateToProps )( TablesLastRoundService );
