import { takeEvery, put, call } from 'redux-saga/effects';
import { getPriceProductHistorical } from 'modules/Products/ProductServices';
import {
  FETCH_PRICE_PRODUCTS_REQUEST,
  fetchPriceProductsFailure,
  fetchPriceProductsSuccess,
} from '../actions/PriceProductsActions';

function* fetchPriceProductsSaga( action ) {
  const { sessionId, categoryId, params = { limit: -1 } } = action.payload;
  try {
    const priceProductsData = yield call(
      getPriceProductHistorical,
      sessionId,
      categoryId,
      params,
    );
    yield put(
      fetchPriceProductsSuccess( { categoryId, data: priceProductsData } ),
    );
  } catch ( error ) {
    yield put( fetchPriceProductsFailure( { categoryId, error } ) );
  }
}

export default function* priceProductsRootSaga() {
  yield takeEvery( FETCH_PRICE_PRODUCTS_REQUEST, fetchPriceProductsSaga );
}
