import React from 'react';

import { connect } from 'react-redux';


import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import _ from 'lodash';
import async from 'async';


import Util from 'utils/Util';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import LogService from 'services/Log';

import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';

import CardBox from 'components/custom_v2/CardBox';
import ChartModal from 'routes/App/components/User/Chart';

import SettingsActions from 'store/reducers/Settings';
import parallel from 'async/parallel';
import ReactExport from 'react-export-excel';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-activity.jpeg';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import CompModal from './CompModal';


const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

class Activity extends React.Component {
  state = { chartModal: false, comparisonModal: false };

  componentDidMount() {
    this.loadAllChart();
  }
  componentDidUpdate( prevProps ) {
    const { locale } = this.props;
    if ( locale !== prevProps.locale ) {
      am4core.disposeAllCharts();
      this.loadAllChart();
    }
  }
  componentWillUnmount() {
    am4core.disposeAllCharts();
  }
  customOrderBy = ( obj ) => {
    try {
      return obj.name.split( '#' )[1];
    } catch ( e ) {
      return obj.name;
    }
  }
  loadAllChart = () => {
    const { user, toggleLoading, intl } = this.props;
    const { dataToExport } = this.state;
    if ( dataToExport ) this.setState( { dataToExport: undefined } );
    toggleLoading( true );
    let result1 = null;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    parallel( [
      ( cb ) => {
        LogService.getActionsByGroupsRound( user.activeSession.id )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
      ( cb ) => {
        LogService.getActionsByGroups( user.activeSession.id )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
    ], ( error, results ) => {
      if ( !error ) {
        if ( results ) {
          const dataList12month = _.map( results[1].data, item => ( {
            attribute: item.name,
            value: item.total,
          } ) );
          const titles = _.filter( results[1].data, o => o.total !== 0 );
          const evolutionSeriesTitle = _.map( titles, item => ( item.name ) );
          result1 = this.createCharSerie(
            'chartcustomeractivity12m', dataList12month,
            [
              { id: 'value', name: intl.formatMessage( { id: 'group' } ) },
            ],
          );

          this.config12m = _.cloneDeep( result1.config );
          this.chart = result1.chart;

          /* const dataList = [
            {attribute: 'x1', group1: 2, group2: 4 },
            {attribute: 'x3', group1: 3, group2: 6 },
            {attribute: 'x2', group1: 4, group2: 8 },
          ];

          result1 = this.createLineCharSerie(
            'chartcustomeractivity', dataList,
            [
              { id: 'group1', name: 'Group 1'},
              { id: 'group2', name: 'Group 2'},
            ],
          );
          this.config = _.cloneDeep( result1.config );
          this.chart = result1.chart;
          */


          const groupsData = [];
          const groupsSeries = [];
          this.setState( { data: results[1].data, evolutionSeriesTitle } );

          _.map( _.sortBy( results[0].data, this.customOrderBy ), ( item ) => {
            groupsSeries.push( { id: item.workgroupId, name: item.name } );
            _.map( item.rounds, ( workgroupData ) => {
              const exist = _.findIndex( groupsData, { attribute: workgroupData.shortName } );
              if ( exist === -1 ) {
                const dataToPush = { attribute: workgroupData.shortName };
                dataToPush[item.workgroupId] = workgroupData.quantity;
                groupsData.push( dataToPush );
              } else {
                groupsData[exist][item.workgroupId] = workgroupData.quantity;
              }
            } );
          } );

          result1 = this.createLineCharSerie(
            'chartcustomeractivity', groupsData,
            groupsSeries,
          );
          this.config = _.cloneDeep( result1.config );
          this.chart = result1.chart;
          /* const dataList = _.map( results[0].data, item => ( {
            attribute: item.round3s.shortName111,
            group1: item.rounds.quatity,
          } ) );

          result1 = this.createLineCharSerie(
            'chartcustomeractivity', dataList,
            [
              { id: 'group1', name: intl.formatMessage( { id: 'activeCustomers' } ) },
            ],
          );
          this.config = _.cloneDeep( result1.config );
          this.chart = result1.chart;
          */
        }
      }
      this.prepareExportData();
      toggleLoading( false );
    } );
  }

  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  prepareExportData = ( ) => {
    const { data } = this.state;
    const { user, intl } = this.props;

    const groupsToCallBack = [];

    let dataToPrepare = [];

    _.map( data, ( item ) => {
      groupsToCallBack.push(
        cb => this.getActionsByStudents( user.activeSession.id, item.id, item.name, cb ),
      );
    } );

    async.parallel( groupsToCallBack, ( err, results ) => {
      _.map( results, ( item ) => {
        // let newItems = item.data;
        const newItems = _.map( item.data, o => _.extend( { group: item.group }, o ) );

        dataToPrepare = _.concat( dataToPrepare, newItems );
      } );
      const preparedData = [];
      _.map( dataToPrepare, ( item ) => {
        preparedData.push( [
          `${item.name} ${item.lastName}`,
          item.group,
          item.incomeSystem,
          item.screenToured,
          item.timeInSystem,
          item.percMemoIndDelivered,
        ] );
      } );

      const result = [{
        xSteps: 0,
        ySteps: 0,
        columns: [
          _.upperCase( intl.formatMessage( { id: 'name' } ) ),
          _.upperCase( intl.formatMessage( { id: 'group' } ) ),
          intl.formatMessage( { id: 'activity.totalLogin' } ),
          intl.formatMessage( { id: 'activity.screen_toured' } ),
          intl.formatMessage( { id: 'activity.time_in_system' } ),
          intl.formatMessage( { id: 'activity.percMemoIndDelivered' } ),
        ],
        data: preparedData,
      }];
      this.setState( { dataToExport: result } );
    } );
  };

  getActionsByStudents = ( user, group, groupName, cb ) => {
    LogService.getActionsByStudents( user, group )
      .then( response => cb(
        response.errors,
        response.data.data
          ? { data: response.data.data, group: groupName }
          : { data: [], group: groupName },
      ) );
  };

  createLineCharSerie=( key, roundsCat, datas ) => {
    const { intl } = this.props;
    const roundsCatTraduce = roundsCat.map( item => ( {
      ...item,
      attribute: intl.formatMessage( { id: item.attribute.split( ' ' )[0] }, { value: item.attribute.split( ' ' )[1] } ),
    } ) );
    const config = {
      data: roundsCatTraduce,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
        title: { text: intl.formatMessage( { id: 'Rounds' } ) },
      }],
      yAxes: [{ type: 'ValueAxis', min: 0, title: { text: intl.formatMessage( { id: 'Decisions' } ) } }],
      series: _.map( datas, itemX => ( {
        type: 'LineSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    return { config, chart };
  };
  createCharSerie=( key, roundsCat, datas ) => {
    const { intl } = this.props;
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
        renderer: { minGridDistance: 30 },
        title: { text: intl.formatMessage( { id: 'Groups' } ) },
      }],
      yAxes: [{ type: 'ValueAxis', min: 0, title: { text: intl.formatMessage( { id: 'Decisions' } ) } }],
      series: _.map( datas, itemX => ( {
        type: 'ColumnSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    return { config, chart };
  };
  toggleCompModal = () => {
    this.setState( previousState => ( { comparisonModal: !previousState.comparisonModal } ) );
  };
  render() {
    const {
      chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
      data,
      evolutionSeriesTitle,
      comparisonModal,
      workgroupId,
      dataToExport,
    } = this.state;
    const { intl, history } = this.props;
    return (
      <>
        <HeadConfig breadcrumbsLinks={[
          {
            url: '/',
            name: 'HomeLink',
          },
          { name: 'InformationDashboard-activity' },

        ]}
        />
        <KotlerSectionHeader title="activity" image={HeaderImage} />
        <Grid container justify="space-between" style={{ rowGap: '20px' }}>
          <Grid item xs={12}>
            <div className="d-flex justify-content-end">
              {dataToExport && (
                <ExcelFile
                  element={(
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      className="jr-btn jr-btn-md text-white ml-2"
                    >
                      <i className="fa fa-download" />
                      <IntlMessages id="exportDetail" />
                    </Button>
                  )}
                  filename="detailActivity"
                >
                  <ExcelSheet dataSet={dataToExport} name="detail" />
                </ExcelFile>
              )}
              {' '}
              <Button
                variant="contained"
                type="button"
                color="primary"
                className="jr-btn jr-btn-md text-white ml-2"
                onClick={() => {
                  history.push( './activitydetail' );
                }}
              >
                <i className="fa fa-list" />
                <IntlMessages id="detail" />
              </Button>

            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <CardBox
              heading="activity_bar_group_decision"
              styleName="px-2 h-100"
              onClick={() => this.toggleChartModal( this.config12m, 'activity_bar_group_decision',
                [intl.formatMessage( { id: 'activity' } )] )}
            >
              <div className="jr-link" id="chartcustomeractivity12m" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardBox
              heading="activity_evolution_group_decision"
              styleName="px-2 h-100"
              onClick={() => this.toggleChartModal( this.config, 'activity_evolution_group_decision',
                evolutionSeriesTitle )}
            >
              <div className="jr-link" id="chartcustomeractivity" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Grid>
          {!!data && data.length > 0 && (
            <Grid item xs={12}>

              <CardBox styleName="px-2 mt-4">
                <div className="jr-link" id="PLossCompdiv" />
                <KotlerTableTitle icon="activity" title="activityResults" />
                <div className="kotler-table-container">
                  <Table className="kotler-table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '15%' }}>
                        &nbsp;
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center text-primary" key={item.id}>
                            <b>
                              { item.name }
                            &nbsp;
                              <i className="fa fa-eye ml-2 mr-3" style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => { this.setState( { workgroupId: item.id, comparisonModal: true } ); }} />
                            </b>
                          </TableCell>
                        ) )}
                        <TableCell className="text-center text-primary">
                          <b><IntlMessages id="TOTAL" /></b>
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>


                      <TableRow>
                        <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                          <b><IntlMessages id="activity.product" /></b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PRODUCT_CREATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PRODUCT_CREATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PRODUCT_CREATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_CREATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PRODUCT_CREATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PRODUCT_DUPLICATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PRODUCT_DUPLICATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PRODUCT_DUPLICATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_DUPLICATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PRODUCT_DUPLICATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PRODUCT_DEACTIVATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PRODUCT_DEACTIVATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PRODUCT_DEACTIVATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_DEACTIVATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PRODUCT_DEACTIVATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PRODUCT_ACTIVATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PRODUCT_ACTIVATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PRODUCT_ACTIVATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_ACTIVATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PRODUCT_ACTIVATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PRODUCT_PRODUCTION_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PRODUCT_PRODUCTION_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PRODUCT_PRODUCTION_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_PRODUCTION_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PRODUCT_PRODUCTION_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PRODUCT_PRICE_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PRODUCT_PRICE_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PRODUCT_PRICE_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_PRICE_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PRODUCT_PRICE_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PRODUCT_TRADE_SALE_PRICE_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PRODUCT_TRADE_SALE_PRICE_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PRODUCT_TRADE_SALE_PRICE_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_TRADE_SALE_PRICE_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PRODUCT_TRADE_SALE_PRICE_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                          <b><IntlMessages id="activity.serviceAndBundle" /></b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="SERVICE_ACTIVATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'SERVICE_ACTIVATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'SERVICE_ACTIVATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_ACTIVATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'SERVICE_ACTIVATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="SERVICE_DEACTIVATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'SERVICE_DEACTIVATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'SERVICE_DEACTIVATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_DEACTIVATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'SERVICE_DEACTIVATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="SERVICE_PRICE_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'SERVICE_PRICE_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'SERVICE_PRICE_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_PRICE_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'SERVICE_PRICE_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="SERVICE_TRADE_SALE_PRICE_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'SERVICE_TRADE_SALE_PRICE_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'SERVICE_TRADE_SALE_PRICE_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_TRADE_SALE_PRICE_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'SERVICE_TRADE_SALE_PRICE_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="BUNDLE_CREATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'BUNDLE_CREATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'BUNDLE_CREATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_CREATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'BUNDLE_CREATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="BUNDLE_ACTIVATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'BUNDLE_ACTIVATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'BUNDLE_ACTIVATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_ACTIVATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'BUNDLE_ACTIVATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="BUNDLE_DEACTIVATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'BUNDLE_DEACTIVATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'BUNDLE_DEACTIVATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_DEACTIVATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'BUNDLE_DEACTIVATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="BUNDLE_PRICE_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'BUNDLE_PRICE_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'BUNDLE_PRICE_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_PRICE_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'BUNDLE_PRICE_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                          <b><IntlMessages id="activity.distribution" /></b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PRODUCT_POP_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PRODUCT_POP_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PRODUCT_POP_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_POP_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PRODUCT_POP_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="SERVICE_POP_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'SERVICE_POP_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'SERVICE_POP_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_POP_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'SERVICE_POP_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="BUNDLE_POP_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'BUNDLE_POP_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'BUNDLE_POP_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_POP_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'BUNDLE_POP_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="BUNDLE_TRADE_SALE_PRICE_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'BUNDLE_TRADE_SALE_PRICE_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'BUNDLE_TRADE_SALE_PRICE_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_TRADE_SALE_PRICE_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'BUNDLE_TRADE_SALE_PRICE_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      {
                      // <TableRow >
                      //   <TableCell >
                      //     <IntlMessages id="BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED" />
                      //   </TableCell>
                      //   {_.map( data, item => (
                      //     <TableCell className="text-center">
                      //       {Util.formatNumber(
                      //  _.findIndex( item.actions,
                      // { action: 'BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED' } ) !== -1
                      // ? item.actions[_.findIndex( item.actions,
                      // { action: 'BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED' } )].quantity : 0 ) }
                      //     </TableCell>
                      //   ) )}
                      //   <TableCell className="text-center" >
                      //     {Util.formatNumber( _.sum(
                      // _.map( data, item => ( _.findIndex( item.actions,
                      // { action: 'BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED' }
                      // ) !== -1 ? Number( item.actions[_.findIndex( item.actions,
                      // { action: 'BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED' } )].quantity )
                      // : 0 ) ) ) ) }
                      //   </TableCell>
                      // </TableRow>
                      }
                      <TableRow>
                        <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                          <b><IntlMessages id="activity.cx" /></b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="CEM_CHANGED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'CEM_CHANGED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'CEM_CHANGED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'CEM_CHANGED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'CEM_CHANGED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                          <b><IntlMessages id="activity.communications" /></b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="CAMPAIGNS_COMMUNICATION_CREATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'CAMPAIGN_COMMUNICATION_CREATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'CAMPAIGN_COMMUNICATION_CREATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'CAMPAIGN_COMMUNICATION_CREATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'CAMPAIGN_COMMUNICATION_CREATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                          <b><IntlMessages id="activity.marketing1to1" /></b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="CAMPAIGN_CRM_CREATED" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'CAMPAIGN_CRM_CREATED' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'CAMPAIGN_CRM_CREATED' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'CAMPAIGN_CRM_CREATED' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'CAMPAIGN_CRM_CREATED' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                          <b><IntlMessages id="activity.research" /></b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <IntlMessages id="PURCHASED_REPORTS" />
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center" key={item.id}>
                            {Util.formatNumber( _.findIndex( item.actions, { action: 'PURCHASED_REPORT' } ) !== -1 ? item.actions[_.findIndex( item.actions, { action: 'PURCHASED_REPORT' } )].quantity : 0 ) }
                          </TableCell>
                        ) )}
                        <TableCell className="text-center">
                          {Util.formatNumber( _.sum( _.map( data, item => ( _.findIndex( item.actions, { action: 'PURCHASED_REPORT' } ) !== -1 ? Number( item.actions[_.findIndex( item.actions, { action: 'PURCHASED_REPORT' } )].quantity ) : 0 ) ) ) ) }
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: '30px' }}>
                        {/*      <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                        <b>&nbsp;</b>
                      </TableCell> */}
                      </TableRow>
                      <TableRow>
                        <TableCell className="text-primary">
                          <b><IntlMessages id="activity.totalActivityPerGroup" /></b>
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center text-primary" key={item.id}>
                            <b>{Util.formatNumber( item.total ) }</b>
                          </TableCell>
                        ) )}
                        <TableCell className="text-center text-primary">
                          <b>
                            {Util.formatNumber( _.sum( _.map( data, item => ( item.total ) ) ) ) }
                          </b>
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ height: '30px' }}>
                        {/*     <TableCell >
                        &nbsp;
                      </TableCell> */}
                      </TableRow>

                      <TableRow>
                        <TableCell className="text-primary">
                          <b><IntlMessages id="activity.totalLogin" /></b>
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center text-primary" key={item.id}>
                            <b>{Util.formatNumber( item.incomeSystems ) }</b>
                          </TableCell>
                        ) )}
                        <TableCell className="text-center text-primary">
                          <b>
                            {Util.formatNumber(
                              _.sum( _.map( data, item => ( item.incomeSystems ) ) ),
                            )
                            }
                          </b>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className="text-primary">
                          <b><IntlMessages id="activity.screen_toured" /></b>
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center text-primary" key={item.id}>
                            <b>{Util.formatNumber( item.screenToured ) }</b>
                          </TableCell>
                        ) )}
                        <TableCell className="text-center text-primary">
                          <b>
                            {Util.formatNumber(
                              _.sum( _.map( data, item => ( item.screenToured ) ) ),
                            )
                            }
                          </b>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className="text-primary" style={{ width: '15%' }}>
                          <b><IntlMessages id="activity.time_in_system" /></b>
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center text-primary" key={item.id}>
                            <b>{Util.formatNumber( item.timeInSystem ) }</b>
                          </TableCell>
                        ) )}
                        <TableCell className="text-center text-primary">
                          <b>
                            {
                              Util.formatNumber(
                                _.sum( _.map( data, item => ( item.timeInSystem ) ) ),
                              )
                            }
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="text-primary">
                          <b><IntlMessages id="activity.percMemoIndDelivered" /></b>
                        </TableCell>
                        {_.map( data, item => (
                          <TableCell className="text-center text-primary" key={item.id}>
                            <b>{Util.formatNumber( item.percMemoIndDelivered ) }</b>
                          </TableCell>
                        ) )}
                        <TableCell className="text-center text-primary">
                          <b>
                            {Util.formatNumber(
                              _.sum( _.map( data, item => ( item.percMemoIndDelivered ) ) ),
                            )
                            }
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </CardBox>
            </Grid>
          )}
        </Grid>


        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />

        <CompModal
          open={comparisonModal}
          onClose={this.toggleCompModal}
          workgroupId={workgroupId}

        />

      </>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};
const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect( mapStateToProps, mapDispatchToProps )( injectIntl( Activity ) );
