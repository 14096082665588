export const FETCH_MARKET_STIMATE_REQUEST = 'FETCH_MARKET_STIMATE_REQUEST';
export const FETCH_MARKET_STIMATE_SUCCESS = 'FETCH_MARKET_STIMATE_SUCCESS';
export const FETCH_MARKET_STIMATE_FAILURE = 'FETCH_MARKET_STIMATE_FAILURE';
export const CLEAR_MARKET_STIMATE = 'CLEAR_MARKET_STIMATE';

export const fetchMarketStimateRequest = ( sessionId, params ) => ( {
  type: FETCH_MARKET_STIMATE_REQUEST,
  payload: { sessionId, params },
} );

export const fetchMarketStimateSuccess = data => ( {
  type: FETCH_MARKET_STIMATE_SUCCESS,
  payload: data,
} );

export const fetchMarketStimateFailure = error => ( {
  type: FETCH_MARKET_STIMATE_FAILURE,
  payload: error,
} );

export const clearMarketStimate = () => ( { type: CLEAR_MARKET_STIMATE } );
