export const FETCH_CUSTOMER_EVOLUTION_REQUEST = 'FETCH_CUSTOMER_EVOLUTION_REQUEST';
export const FETCH_CUSTOMER_EVOLUTION_SUCCESS = 'FETCH_CUSTOMER_EVOLUTION_SUCCESS';
export const FETCH_CUSTOMER_EVOLUTION_FAILURE = 'FETCH_CUSTOMER_EVOLUTION_FAILURE';

export const fetchCustomerEvolutionRequest = ( sessionId, params ) => ( {
  type: FETCH_CUSTOMER_EVOLUTION_REQUEST,
  payload: { sessionId, params },
} );

export const fetchCustomerEvolutionSuccess = data => ( {
  type: FETCH_CUSTOMER_EVOLUTION_SUCCESS,
  payload: data,
} );

export const fetchCustomerEvolutionFailure = error => ( {
  type: FETCH_CUSTOMER_EVOLUTION_FAILURE,
  payload: error,
} );
