import React from 'react'
import {
  Dialog,
  DialogContent,
  Button,
  IconButton,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { TextField } from 'components/custom/FormElements'
import { Row, Col } from 'reactstrap'
import { Form } from 'react-final-form'
import IntlMessages from 'utils/IntlMessages'
import { required } from 'config/InputErrors'
import { Close } from '@material-ui/icons'
import async from 'async'
import _ from 'lodash'
import { connect } from 'react-redux'
import UserService from 'services/User'
import SettingsActions from 'store/reducers/Settings'
import { useTheme } from '@material-ui/core/styles';

const apiValidateCode = (data, cb) => {
  UserService.validateCode(data).then((response) =>
    cb(response.errors, response.data),
  )
}

const CodeToRegisterModal = ({
  open,
  history,
  toggleLoading,
  toggleAlert,
  onClose,
}) => {
    const {palette} = useTheme();
  const validateCode = (dataForm) => {
    toggleLoading(true)
    const data = { registrationCode: dataForm.code }
    async.waterfall([apiValidateCode.bind(this, data)], (error, results) => {
      if (error) {
        toggleLoading(false)
        return toggleAlert(error)
      }
      if (_.get(results, 'id')) {
        toggleLoading(false)
        history.push(`sign-up2/${results.id}`)
      }
    })
  }
  return (
    <Dialog open={open} fullWidth maxWidth="sm" >
      <DialogTitle disableTypography style={{ paddingTop: '30px',paddingBottom:"5px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:"center" }}>
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontWeight: '600' }}
          >
            <IntlMessages id="appModule.regsiter" />
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
          
            >
              <Close style={{color:palette.secondary.dark}} />
            </IconButton>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '30px' }}>
        <Form
          onSubmit={validateCode}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} id="codeToRegisterForm" className='login-modal-form' noValidate>
              <Row>
                <Col>
                  <TextField
                    field="code"
                    labelGap="20px"
                    placeholder="placeHolderCodeField"
                 
                    label="enterCodeForRegistration"
                    validate={required}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    variant="outlined"
                    className="jr-btn btn-register-code border-danger text-danger"
                    onClick={onClose}
                  >
                    <IntlMessages id="cancelBtn" />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn btn-register-code"
                    type="submit"
                  >
                    <IntlMessages id="validateCodeConfirmBtn" />
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
const mapStateToProps = ({ user, settings }) => ({
  isLoading: settings.isLoading,
})

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
}
export default connect(mapStateToProps, mapDispatchToProps)(CodeToRegisterModal)
