import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import UserService from 'services/User';
import WorkGroupService from 'services/WorkGroup';
import SessionService from 'services/Session';
import SettingsActions from 'store/reducers/Settings';

import group from 'assets/images/kotler/group-photo-settings.png';
import HeaderImage from 'assets/images/kotler/sectionHeaders/register.jpeg';
import { Container, Grid, Typography } from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import async from 'async';
import _ from 'lodash';
import { Form } from 'react-final-form';
import { TextField } from 'components/custom/FormElements';

import UserActions from 'store/reducers/User';
import HeadConfig from 'components/custom/HeadConfig';
import { required } from 'config/InputErrors';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import PictureProfile from '../PictureProfile';

const getData = ( { user, toggleLoading, setData } ) => {
  toggleLoading( true );

  SessionService.getSessionCurrentWorkgroupRound( user.activeSession.id )
    .then( ( response ) => {
      if ( !response.ok ) return response.errors;
      if ( response.data.id ) {
        WorkGroupService.getWorkGroupInfo( user.activeSession.id, response.data.id )
          .then( ( res ) => {
            if ( !res.ok ) return res.errors;
            toggleLoading( false );
            setData( res.data );
          } );
      }
    } );
};
const GroupSettings = ( { user, toggleLoading, toggleAlert, updateLicenses } ) => {
  const [readOnly, setReadOnly] = useState( true );
  const [isOpen, setIsOpen] = useState( false );
  const [uploadAvatar, setuploadAvatar] = useState( null );
  const [data, setData] = useState( {} );
  const [license, setLicense] = useState( null );


  useEffect( () => {
    setLicense(
      _.find( user.licenses, ( { session } ) => session.id === user.activeSession.id, null ),
    );
  }, [user] );
  useEffect( () => {
    getData( { setData, toggleLoading, user } );
  }, [user, toggleLoading] );
  const handleEdit = () => {
    setReadOnly( !readOnly );
  };

  const handleClose = () => setIsOpen( false );

  const handleAvatarEditor = () => setIsOpen( true );

  const handleEditAvatar = ( src ) => {
    toggleLoading( true );

    WorkGroupService.updateWorkGroupSettings(
      user.activeSession.id,
      data.id,
      { uploadAvatar: src },
    ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      setIsOpen( false );
      setuploadAvatar( src );
      toggleAlert( 'profile-group-updated', 'info' );
    } );
  };

  const submitForm = ( group2, form ) => {
    toggleLoading( true );
    async.waterfall( [
      ( cb ) => {
        WorkGroupService.updateWorkGroupSettings(
          user.activeSession.id,
          data.id,
          { name: group2.name },
        ).then( ( response ) => {
          toggleLoading( false );
          if ( !response.ok ) {
            toggleAlert( response.errors );

            return cb( response.errors );
          }
          handleEdit();
          toggleAlert( 'profile-group-updated', 'info' );
          form.change( 'name', response.data.name );
          form.initialize( { name: response.data.name } );
          return cb( null );
        } );
      },

      ( cb ) => {
        UserService.getCurrentUser( user )
          .then( ( response ) => {
            if ( !response.errors ) {
              const data1 = { ...user, ...response.data };
              try {
                updateLicenses( data1.licenses );
                return cb( null, data1 );
              } catch ( err ) {
              // eslint-disable-next-line no-console
                console.log( err );
                return cb( err );
              }
            }
          } );
      },

    ] );
  };
  return (
    <>
      <HeadConfig breadcrumbsLinks={[
        {
          url: '/',
          name: 'HomeLink',
        },
        { name: 'profile-group-title' },

      ]}
      />
      <Form
        initialValues={{ name: data.name }}
        onSubmit={submitForm}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit} noValidate>
            <KotlerSectionHeader
              title="profile-group-title"
              image={HeaderImage}
              titleCancel={readOnly ? 'exit' : 'cancelBtn'}
              onCancel={!readOnly ? () => {
                form.reset();
                handleEdit();
              } : null}
              cancelTo={readOnly ? '/' : null}
              titleSubmit={readOnly ? 'profile-edit' : 'save'}
              onSubmit={readOnly ? handleEdit : handleSubmit}
            />
            <Container maxWidth="lg" className="flex-grow-1">
              {data && data.name && (

                <div className="h-100 d-flex align-items-center">
                  <Grid container style={{ rowGap: '20px' }} className="mt-3 flex-grow-1">

                    <Grid item xs={12} md={4}>
                      <CardBox styleName="px-3 h-100" disableHeader>
                        <Typography
                          variant="h4"
                          style={{
                            fontSize: '21px',
                            fontWeight: 700,
                            lineHeight: '32px',
                            color: '#09082C',
                          }}
                        >
                          {license && license.workGroup && license.workGroup.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '21px',
                            color: '#09082C',
                          }}
                          className="mt-2"
                        >
                          {user.email && user.email}

                        </Typography>
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '21px',
                            color: '#09082C',
                          }}
                          className="mt-2"
                        >
                          {user.name && user.lastName && ( `${user.name} ${user.lastName}` ) }

                        </Typography>
                        <PictureProfile
                          src={data.avatar || group}
                          newSrc={uploadAvatar}
                          isOpen={isOpen}
                          editAvatar={handleEditAvatar}
                          avatarEditor={handleAvatarEditor}
                          onClose={handleClose}
                        />

                      </CardBox>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <CardBox styleName="px-3 h-100" header={<KotlerCardHeader title="NewSessionStep1" minHeight="0px" />}>

                        <Grid container>

                          <Grid item xs={12} className="px-2">
                            <TextField
                              containerClass={readOnly ? 'kotler-text-field-form-control' : null}
                              className={readOnly ? 'kotler-text-field-gray' : null}
                              field="name"
                              label="name"
                              validate={required}
                              disabled={readOnly}
                            />

                          </Grid>
                        </Grid>

                      </CardBox>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Container>

          </form>
        )}
      />

    </>
  );
};
const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  updateLicenses: UserActions.updateLicenses,
};

export default connect( null, mapDispatchToProps )( GroupSettings );
