import { z } from 'zod';

export const ServiceSchema = z.object( {
  id: z.string().optional(),
  name: z.string().optional(),
  price: z.number().optional(),
  unitSales: z.number().optional(),
  totalSales: z.number().optional(),
  active: z.boolean().optional(),
} );

export const ServicesSchema = z.object( {
  services: z.array( ServiceSchema ),
  totalServicesSold: z.number(),
} );

export const WorkgroupDatumSchema = z.object( {
  id: z.string(),
  name: z.string(),
  services: ServicesSchema,
} );

export const LastRoundServiceSchema = z.object( {
  id: z.string(),
  name: z.string(),
  label: z.string(),
  workgroupData: z.array( WorkgroupDatumSchema ),
} );
export const LastRoundServicesSchema = z.array( LastRoundServiceSchema );
