import api from '../config/Api';

const endpoint = 'v1/session';
const endpointV2 = 'v2/session';

export default {
  getBundles( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/bundle`, params );
  },

  getBundlesV2( sessionId, params ) {
    return api.get( `${endpointV2}/${sessionId}/bundle`, params );
  },

  getBundle( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/bundle/detail`, params );
  },

  getBundleCost( serviceId, productId, params ) {
    return api.get(
      `v2/bundle-unit-cost/service/${serviceId}/product/${productId}`,
      params,
    );
  },

  saveBundle( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/bundle`, data );
  },

  saveBundleV2( sessionId, data ) {
    return api.post( `${endpointV2}/${sessionId}/bundle`, data );
  },

  updateBundle( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/bundle`, data );
  },

  updateBundlesV2( sessionId, data, isForce = false ) {
    let urlFragment = 'price-status';
    if ( isForce ) {
      urlFragment = 'price-status?force=1';
    } else {
      urlFragment = 'price-status';
    }
    return api.put( `${endpointV2}/${sessionId}/bundle/${urlFragment}`, data );
  },

  updateBundleRoundPrice( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/bundle-round/price`, data );
  },
};
