import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Col } from 'reactstrap';
import { injectIntl } from 'react-intl';

import CardBox from 'components/custom_v2/CardBox';
import IntlMessages from 'utils/IntlMessages';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';

const Detail = ( { intl, data, sumary } ) => {
  const promotePurchasing = (
    <div style={{ display: 'flex' }}>
      {data.promotePurchasing ? (
        <FeatherIcon
          icon="check"
          color="#2CD5C4"
          size="30px"
          style={{
            backgroundColor: '#D5F7F3',
            borderRadius: '2px',
            padding: '4px',
          }}
        />
      ) : (
        <FeatherIcon
          size="30px"
          icon="x"
          color="#F56954"
          style={{
            backgroundColor: '#FFC7BE',
            borderRadius: '2px',
            padding: '4px',
          }}
        />
      )}
    </div>
  );

  const getPropertyRender = ( label, key, value ) => (
    <TableRow>
      <TableCell className="border-none">
        <IntlMessages id={label} />
      </TableCell>

      <TableCell className="border-none">
        {value || ( _.get( data, key ) ? _.get( data, key ) : '-' )}
      </TableCell>
    </TableRow>
  );

  const getTranslatePropertyRender = ( label, key, value ) => (
    <TableRow>
      <TableCell className="border-none">
        <IntlMessages id={label} />
      </TableCell>

      <TableCell className="border-none">
        {value ? (
          <IntlMessages id={value} />
        ) : _.get( data, key ) ? (
          <IntlMessages id={_.get( data, key )} />
        ) : (
          '-'
        )}
        {' '}
      </TableCell>
    </TableRow>
  );

  const getTranslateArrayRender = ( label, value ) => (
    <TableRow>
      <TableCell>
        <IntlMessages id={label} />
      </TableCell>

      <TableCell className="border-none">
        {_.map( value, ( item, index ) => (
          <span key={item.id}>
            <IntlMessages id={item.id} />
            {index < value.length - 1 ? ', ' : ''}
          </span>
        ) )}
      </TableCell>
    </TableRow>
  );

  return sumary ? (
    <CardBox header={<div />}>
      <Grid container className="mx-3">
        <Grid item>
          <Table
            className={classnames(
              'px-2 kotler-table kotler-table-info-details',
            )}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} className="pt-0">
                  <IntlMessages id="campaignSummary" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getPropertyRender( 'name', 'name' )}
              {getTranslatePropertyRender( 'mainObjective', 'contentType' )}
              {data.contentType === 'PRODUCT' && (
                <>
                  {getPropertyRender( 'product', 'product' )}
                  {getPropertyRender(
                    'attributes',
                    null,
                    _.map( data.productFeature, item => intl.formatMessage( { id: item.id } ) ).join( ', ' ),
                  )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}

              {data.contentType === 'SERVICE' && (
                <>
                  {getPropertyRender( 'service', 'service' )}
                  {getPropertyRender(
                    'attributes',
                    null,
                    _.map( data.serviceFeature, item => intl.formatMessage( { id: item.id } ) ).join( ', ' ),
                  )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}

              {data.contentType === 'BUNDLE' && (
                <>
                  {getPropertyRender( 'bundle', 'bundle' )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}
              {getTranslatePropertyRender(
                'contactMethod',
                'directContactMethod',
              )}
              {getTranslatePropertyRender( 'messagePerMonth', 'messagePerMonth' )}
              {getPropertyRender(
                'execution',
                null,
                data.execution ? (
                  <span>
                    <IntlMessages id={data.execution} />
                    {data.months ? ` - ${data.months}` : ''}
                  </span>
                ) : (
                  '-'
                ),
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </CardBox>
  ) : (
  /*     <CardBox header={(
      <div
        className="p-2 bg-primary cursor-pointer d-flex justify-content-between"
        onClick={toggleOpened}
      >
        <h4 className="card-heading m-0 text-white">
          <IntlMessages id="overview" />
        </h4>
        <i className={classnames( 'fa text-white', {
          'fa-chevron-up': !opened,
          'fa-chevron-down': opened,
        } )}
        />
      </div>
    )}
    > */

    <Grid
      container
      style={{ rowGap: '20px', paddingBottom: '20px', margin: '0px 5px' }}
    >
      <Grid item xs={12} sm={6} lg={3}>
        <CardBox styleName="h-100" header={<div />}>
          <Table
            className={classnames(
              'px-2 kotler-table kotler-table-info-details',
            )}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} className="pt-0">
                  <IntlMessages id="campaign" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getPropertyRender( 'name', 'name' )}
              {getTranslatePropertyRender( 'mainObjective', 'contentType' )}
              {data.contentType === 'PRODUCT' && (
                <>
                  {getPropertyRender( 'product', 'product' )}
                  {getPropertyRender(
                    'attributes',
                    null,
                    _.map( data.productFeature, item => intl.formatMessage( { id: item.id } ) ).join( ', ' ),
                  )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}

              {data.contentType === 'SERVICE' && (
                <>
                  {getPropertyRender( 'service', 'service' )}
                  {getPropertyRender(
                    'attributes',
                    null,
                    _.map( data.serviceFeature, item => intl.formatMessage( { id: item.id } ) ).join( ', ' ),
                  )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}

              {data.contentType === 'BUNDLE' && (
                <>
                  {getPropertyRender( 'bundle', 'bundle' )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}
              {getTranslatePropertyRender(
                'contactMethod',
                'directContactMethod',
              )}
              {getTranslatePropertyRender( 'messagePerMonth', 'messagePerMonth' )}
              {getPropertyRender(
                'execution',
                null,
                data.execution ? (
                  <span>
                    <IntlMessages id={data.execution} />
                    {data.months ? ` - ${data.months}` : ''}
                  </span>
                ) : (
                  '-'
                ),
              )}
            </TableBody>
          </Table>
        </CardBox>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <CardBox styleName="h-100" header={<div />}>
          <Table
            className={classnames(
              'px-2 kotler-table kotler-table-info-details',
            )}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} className="pt-0">
                  <IntlMessages id="who" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.iotSegmentation && data.iotSegmentation.iotFeature && (
                <>
                  <TableRow>
                    <TableCell colSpan={2} className="subtitle">
                      <IntlMessages id="iotSegmentation" />
                    </TableCell>
                  </TableRow>
                  {getPropertyRender( 'product', 'iotSegmentation.product' )}
                  {getTranslatePropertyRender(
                    'feature',
                    'iotSegmentation.iotFeature',
                  )}
                </>
              )}
              {data.demographicSegmentation
              && ( ( data.demographicSegmentation.segmentation
                && data.demographicSegmentation.segmentation.length )
                || data.demographicSegmentation.gender
                || data.demographicSegmentation.ageFrom
                || data.demographicSegmentation.ageTo ) ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} className="subtitle">
                        <IntlMessages id="demographicSegmentation" />
                      </TableCell>
                    </TableRow>


                    {getTranslatePropertyRender(
                      'gender',
                      null,
                      data.demographicSegmentation.gender,
                    )}
                    {getPropertyRender(
                      'age',
                      null,
                      data.demographicSegmentation.ageFrom
                      || data.demographicSegmentation.ageTo
                        ? `${data.demographicSegmentation.ageFrom || ''} ${
                          data.demographicSegmentation.ageTo
                          && data.demographicSegmentation.ageFrom
                            ? intl.formatMessage( { id: 'to' } ).toLowerCase()
                            : ''
                        } ${data.demographicSegmentation.ageTo || ''}`
                        : false,
                    )}
                    {getTranslateArrayRender(
                      'segmentation',
                      data.demographicSegmentation.segmentation,
                    )}
                  </>
                ) : null}
              {data.behavioralSegmentation
                && !!data.behavioralSegmentation.length
                && _.map( [...data.behavioralSegmentation], item => (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} className="subtitle">
                        <span>
                          <IntlMessages id="behavioralSegmentation" />
                          {' '}
                          {item.behavioralType
                            ? item.behavioralType.split( '_' )[1]
                            : ''}
                        </span>
                      </TableCell>
                    </TableRow>
                    {getPropertyRender( 'behavioral', null, item.behavioral )}

                    {getPropertyRender(
                      'Months',
                      null,
                      item.fromMonth || item.toMonth
                        ? `${item.fromMonth || ''} ${
                          item.toMonth && item.fromMonth
                            ? intl.formatMessage( { id: 'to' } ).toLowerCase()
                            : ''
                        } ${item.toMonth || ''}`
                        : false,
                    )}
                    {item.behavioralType === 'TYPE_1'
                    || item.behavioralType === 'TYPE_2'
                      ? getPropertyRender(
                        'commodity',
                        null,
                        _.get( item, 'commodity.name' ),
                      )
                      : null}
                    {item.afterRound && (
                      <Col>
                        {getPropertyRender(
                          'afterRound',
                          null,
                          item.afterRound.name,
                        )}
                      </Col>
                    )}
                    {item.beforeRound && (
                      <Col>
                        {getPropertyRender(
                          'beforeRound',
                          null,
                          item.beforeRound.name,
                        )}
                      </Col>
                    )}
                    {item.exactly && (
                      <Col>
                        <strong>
                          <IntlMessages id="exactly" />
                        </strong>
                        {': '}
                        {item.exactly}
                        {' '}
                        <IntlMessages id="monthsAgo" />
                      </Col>
                    )}
                  </>
                ) )}
            </TableBody>
          </Table>
        </CardBox>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <CardBox styleName="h-100" header={<div />}>
          <Table
            className={classnames(
              'px-2 kotler-table kotler-table-info-details',
            )}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} className="pt-0">
                  <IntlMessages id="what" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getTranslatePropertyRender( 'mainObjective', 'contentType' )}
              {data.contentType === 'PRODUCT' && (
                <>
                  {getPropertyRender( 'product', 'product' )}
                  {getPropertyRender(
                    'attributes',
                    null,
                    _.map( data.productFeature, item => intl.formatMessage( { id: item.id } ) ).join( ', ' ),
                  )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}

              {data.contentType === 'SERVICE' && (
                <>
                  {getPropertyRender( 'service', 'service' )}
                  {getPropertyRender(
                    'attributes',
                    null,
                    _.map( data.serviceFeature, item => intl.formatMessage( { id: item.id } ) ).join( ', ' ),
                  )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}

              {data.contentType === 'BUNDLE' && (
                <>
                  {getPropertyRender( 'bundle', 'bundle' )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </CardBox>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <CardBox styleName="h-100" header={<div />}>
          <Table
            className={classnames(
              'px-2 kotler-table kotler-table-info-details',
            )}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} className="pt-0">
                  <IntlMessages id="how" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getTranslatePropertyRender(
                'contactMethod',
                'directContactMethod',
              )}
              {getTranslatePropertyRender( 'messagePerMonth', 'messagePerMonth' )}
              {getPropertyRender(
                'execution',
                null,
                data.execution ? (
                  <span>
                    <IntlMessages id={data.execution} />
                    {data.months ? ` - ${data.months}` : ''}
                  </span>
                ) : (
                  '-'
                ),
              )}
            </TableBody>
          </Table>
        </CardBox>
      </Grid>
    </Grid>
  );
};
export default injectIntl( Detail );
