import { takeEvery, put, call } from 'redux-saga/effects';

import { getCustomerEvolution } from '../../customersService';
import {
  FETCH_CUSTOMER_EVOLUTION_REQUEST,
  fetchCustomerEvolutionFailure,
  fetchCustomerEvolutionSuccess,
} from '../actions/customerEvolutionActions';

function* fetchCustomerEvolutionSaga( action ) {
  try {
    const { sessionId, params = { limit: -1 } } = action.payload;
    const customerEvolutionData = yield call(
      getCustomerEvolution,
      sessionId,
      params,
    );
    yield put( fetchCustomerEvolutionSuccess( customerEvolutionData ) );
  } catch ( error ) {
    yield put( fetchCustomerEvolutionFailure( error ) );
  }
}

export default function* customerEvolutionRootSaga() {
  yield takeEvery( FETCH_CUSTOMER_EVOLUTION_REQUEST, fetchCustomerEvolutionSaga );
}
