import React from 'react';
import Button from '@material-ui/core/Button';

import IntlMessages from 'utils/IntlMessages';

export default ({ category, onClick }) => (
  <Button
    variant="contained"
    className="kotler-button-sm p-0"
    color="primary"
    style={{height:32}}
    onClick={onClick}
  >  {category.label === 'CATEGORY_1'
    && (

      <IntlMessages id="newLaptop" />

    )}
    {category.label === 'CATEGORY_2'
      && (

        <IntlMessages id="newTablet" />

      )}
    {category.label === 'CATEGORY_3'
      && (

        <IntlMessages id="newCellPhone" />

      )}
  </Button>
)
