import React, { useEffect } from 'react';
import HeaderImage from 'assets/images/kotler/sectionHeaders/campaing.jpeg';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';

import HeadConfig from 'components/custom/HeadConfig';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import DashboardBackground from 'assets/images/kotler/dashboard-background-simple.jpeg';
import SettingsActions from 'store/reducers/Settings';
import { Form } from 'react-final-form';
import {
  LiveAdCodeInput,
  LiveAdContainer,
  getLiveAdActions,
  updateLiveAd,
} from 'modules/LiveAds';
import IntlMessages from 'utils/IntlMessages';
import { Switch } from 'components/custom/FormElements';
import Error404 from 'components/theme/Error404';

const AdsPage = ( { sessionId } ) => {
  const dispatch = useDispatch();
  const { ad, loading, error } = useSelector( ( { ads } ) => ads.getLiveAd );
  useEffect( () => {
    if ( sessionId ) {
      dispatch( getLiveAdActions.clearAd() );
      dispatch( getLiveAdActions.fetchAdRequest( sessionId ) );
    }
  }, [sessionId, dispatch] );

  useEffect( () => {
    dispatch( SettingsActions.toggleLoading( loading ) );
  }, [loading, dispatch] );
  if ( error && error === 'The object could not be updated' ) return <Error404 />;
  const onSubmit = async ( { body, active } ) => {
    try {
      dispatch( SettingsActions.toggleLoading( true ) );
      const data = await updateLiveAd( sessionId, ad.id, {
        body,
        active,
      } );
      dispatch( getLiveAdActions.set( data ) );
      dispatch( SettingsActions.toggleLoading( false ) );
      dispatch( SettingsActions.toggleAlert( 'savesuccessful', 'info', true ) );
    } catch ( e ) {
      dispatch( SettingsActions.toggleAlert( e.message ) );
    }
  };
  return (
    <Form
      initialValues={ad}
      onSubmit={onSubmit}
      render={( { handleSubmit, form, values } ) => (
        <div
          className="flex-grow-1  d-flex flex-column justify-content-center "
          style={{
            backgroundImage: `url(${DashboardBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <HeadConfig
            breadcrumbsLinks={[
              {
                url: '/',
                name: 'HomeLink',
              },
              { name: 'DecisionDashboard-liveads' },
            ]}
          />
          <KotlerSectionHeader
            title="liveAd.title"
            image={HeaderImage}
            onCancel={() => form.reset()}
            onSubmit={handleSubmit}
            titleCancel="cancelBtn"
            titleSubmit="save"
          />

          <form onSubmit={handleSubmit}>
            <Container
              maxWidth="lg"
              className="mt-5 d-flex flex-column flex-grow-1 pb-4"
              style={{ overflowY: 'auto' }}
            >
              <div className="d-flex px-4 justify-content-between">
                <h1 className="text-primary2">
                  <IntlMessages id="preview" />
                </h1>
                <Switch label="active" field="active" />
              </div>
              <div
                className="d-flex w-100 justify-content-between  "
                style={{ gap: 20 }}
              >
                <LiveAdContainer ad={values} />
                <LiveAdCodeInput ad={ad} />
              </div>
            </Container>
          </form>
        </div>
      )}
    />
  );
};

export default AdsPage;
