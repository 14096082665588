import { getHttpRequest } from 'utils/ApiPetitionhandles';

// const endpoint = 'v1/session';
const endpoint2 = 'v2/session';
// get  market products by category with sales prices and historical
export async function getMarketProductsHistorical(
  sessionId,
  workgroupId,
  categoryId,
  params,
) {
  const url = `${endpoint2}/${sessionId}/market/products/?workgroup=${workgroupId}&category=${categoryId}`;
  return getHttpRequest( url, params );
}
// get info products by category with attributes params confs
export async function getInfoProducts(
  sessionId,
  workgroupId,
  categoryId,
  params,
) {
  const url = `${endpoint2}/${sessionId}/category/${categoryId}/product?workgroup=${workgroupId}`;
  return getHttpRequest( url, params );
}

// get workgroups array with category info and historical that contains price inside  product array
export async function getPriceProductHistorical( sessionId, categoryId, params ) {
  const url = `${endpoint2}/${sessionId}/price-product/all-workgroup?category=${categoryId}`;
  return getHttpRequest( url, params );
}

// get info categories or products with  workgroupdata
export async function getLastRoundProductAllCategories(
  sessionId,
  workgroupId,
  params,
) {
  const url = `${endpoint2}/${sessionId}/market/products-last-round?workgroup=${workgroupId}`;
  return getHttpRequest( url, params );
}
export default {
  getMarketProductsHistorical,
  getInfoProducts,
  getLastRoundProductAllCategories,
  getPriceProductHistorical,
};
