import { filter, get } from 'lodash';

export const filterReportsByPermissions = (
  reports,
  reportKeyName,
  researchPermissions,
) => {
  if ( researchPermissions && reports ) {
    return filter( reports, ( report ) => {
      const can = get(
        researchPermissions,
        get( report, reportKeyName, '' ).toLowerCase(),
      ) !== false;
      return can;
    } );
  }

  return reports;
};
