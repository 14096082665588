import { formatMessage, formatRoundName } from 'helpers/IntlHelpers';
import { flatMap, map } from 'lodash';

export const formatHistoricalToRow = ( historical, valueKey, groupName ) => {
  if ( !historical || !historical.length ) return [groupName];
  return [
    groupName,
    ...map( historical, round => ( round[valueKey] === 0 ? '-' : round[valueKey] ) ),
  ];
};

export const formatRoundsLabels = historical => flatMap( historical, ( { round } ) => [
  {
    value: formatRoundName( round.shortName ),
    style: { alignment: { horizontal: 'right' }, font: { bold: true } },
  },
] );

export const formatTotalCustomersToExcell = (
  totalCustomers,
  title = 'NumberOfCustomers',
) => {
  const sheetData = [
    {
      xSteps: 0,
      ySteps: 1,
      columns: [formatMessage( { id: title } )],
      data: [],
    },
  ];

  if ( !totalCustomers || !totalCustomers.length ) return sheetData;
  const titles = [
    { value: formatMessage( { id: 'group' } ), style: { font: { bold: true } } },
    ...formatRoundsLabels( totalCustomers[0].historical ),
  ];

  const dataHistorical = map(
    totalCustomers,
    ( { historical, name: groupName } ) => formatHistoricalToRow( historical, 'totalNumberCustomer', groupName ),
  );

  sheetData.push( { columns: [], data: [titles, ...dataHistorical] } );

  return sheetData;
};
