import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Divider,
  Grid,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HeaderImage from 'assets/images/kotler/sectionHeaders/products.jpeg';
import { TextField } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import IndividualSemesterMemoService from 'services/IndividualSemesterMemo';
import InitialStrategicPlanService from 'services/InitialStrategicPlan';
import StrategyService from 'services/Strategy';
import FinalStrategicReport from 'services/FinalStrategicReport';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import FeatherIcon from 'components/custom/FeatherIcon';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import SectionInfoText from 'components/custom/SectionInfoText';
import CompModal from './CompModal';
import DetailReport from './DetailReport';

class Strategy extends React.Component {
  state = {
    CompModalB: false,
    strategiesReport: [],
    confirmSaveModal: false,
    confirmDeactivateModal: false,
    showGeneralStrategy: false,
    showSemesterMemo: false,
    showFinalStrategicReport: false,
    showEmptyReport: false,
    dataInitialStrategicPlan: {},
    dataIndividualSemester: {},
    dataFinalStrategicReport: {},
    titleFormSection: 'initialStrategicPlan',
    readInitialStrategicPlan: false,
    readIndividualSemesterMemo: false,
    readFinalStrategicReport: false,
    weekNumber: 0,
    understanding: '',
    productStrategy: '',
    customerStrategy: '',
    understandingB: '',
    productHits: '',
    customerHits: '',
    productErrors: '',
    customerErrors: '',
    learningPersonalActivity: '',
    generalStrategy: '',
    products: '',
    segments: '',
  }
  mkmode = {}

  // eslint-disable-next-line react/sort-comp
  toggleGeneralStrategy = ( typeForm ) => {
    this.setState( {
      showGeneralStrategy: true,
      showSemesterMemo: false,
      showFinalStrategicReport: false,
      titleFormSection: typeForm,
    } );
  }

  toggleSemesterMemo = ( typeForm ) => {
    this.setState( {
      showSemesterMemo: true,
      showGeneralStrategy: false,
      showFinalStrategicReport: false,
      titleFormSection: typeForm,
    } );
  }

  toggleFinalStrategicReport = ( typeForm ) => {
    this.setState( {
      showFinalStrategicReport: true,
      showGeneralStrategy: false,
      showSemesterMemo: false,
      titleFormSection: typeForm,
    } );
  }

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.getData();
  }

  setDefaultData = () => {
    const { strategiesReport } = this.state;

    strategiesReport.map( ( item ) => {
      if ( item.action !== 'block' ) {
        if ( item.type === 'initialStrategicPlan' ) {
          this.toggleGeneralStrategy( item.type );
          this.setState( {
            dataInitialStrategicPlan: item.data,
            generalStrategy: item.data.generalStrategy || '',
            products: item.data.products || '',
            segments: item.data.segments || '',
          } );
        } else if (
          item.type === 'individualSemesterMemo'
          && item.status === 'READY'
        ) {
          this.toggleSemesterMemo( item.type );
          this.setState( {
            dataIndividualSemester: item.data,
            understanding: item.data.understanding || '',
            productStrategy: item.data.productStrategy || '',
            customerStrategy: item.data.customerStrategy || '',
          } );
        } else if ( item.type === 'finalStrategicReport' ) {
          this.toggleFinalStrategicReport( item.type );
          this.setState( {
            dataFinalStrategicReport: item.data,
            understandingB: item.data.understandingB || '',
            productHits: item.data.productHits || '',
            customerHits: item.data.customerHits || '',
            productErrors: item.data.productErrors || '',
            customerErrors: item.data.customerErrors || '',
            learningPersonalActivity: item.data.learningPersonalActivity || '',
          } );
        }
        return true;
      }
      return false;
    } );
  }

  getData = async () => {
    const { toggleLoading, toggleAlert } = this.props;

    const sessionId = this.activeSession.id;
    const workGroupId = this.license.workGroup.id;

    const promise = StrategyService.getStrategies( sessionId, workGroupId );

    await promise.then( ( response ) => {
      if ( !response.ok ) return toggleAlert( response.errors );
      let strategiesReport = response.data.strategies;
      if ( !this.mkmode.decision.strategy.initialStrategicPlan ) {
        strategiesReport = _.filter(
          strategiesReport,
          o => o.type.indexOf( 'initialStrategicPlan' ) === -1,
        );
      }
      if ( !this.mkmode.decision.strategy.finalStrategicReport ) {
        strategiesReport = _.filter(
          strategiesReport,
          o => o.type.indexOf( 'finalStrategicReport' ) === -1,
        );
      }
      this.setState( { strategiesReport } );
      this.setDefaultData();
    } );

    toggleLoading( false );
  }

  handleClick = ( week, type ) => {
    this.setState( { weekNumber: week, titleType: type } );
  }

  toggleIndividualMemo = () => {
    this.setState( prevState => ( { CompModalB: !prevState.CompModalB } ) );
  }

  toggleConfirmDeactivateModal = () => {
    const { confirmDeactivateModal } = this.state;
    this.setState( { confirmDeactivateModal: !confirmDeactivateModal } );
  }

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  }

  submitInitialStrategicPlan = async ( frmInitialStrategicPlan ) => {
    const { toggleLoading, toggleAlert } = this.props;
    const { dataInitialStrategicPlan } = this.state;
    toggleLoading( true );

    const frmData = frmInitialStrategicPlan;
    const sessionId = this.activeSession.id;

    // eslint-disable-next-line no-param-reassign
    frmData.workGroup = this.license.workGroup.id;
    // eslint-disable-next-line max-len
    const promise = dataInitialStrategicPlan.id === undefined
      ? InitialStrategicPlanService.saveInitialStrategicPlan(
        sessionId,
        frmInitialStrategicPlan,
      )
      : InitialStrategicPlanService.updateInitialStrategicPlan(
        sessionId,
        dataInitialStrategicPlan.id,
        frmInitialStrategicPlan,
      );
    await promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      this.setState( { dataInitialStrategicPlan } );
      toggleAlert( 'dataSaved', 'info' );
    } );

    await this.getData();
  }

  submitSemesterMemoForm = async ( formSemesterMemoData ) => {
    const { toggleLoading, toggleAlert } = this.props;
    const { dataIndividualSemester } = this.state;
    toggleLoading( true );
    const frmData = formSemesterMemoData;
    const sessionId = this.activeSession.id;

    frmData.workGroup = this.license.workGroup.id;

    // eslint-disable-next-line max-len
    const promise = dataIndividualSemester.id === undefined
      ? IndividualSemesterMemoService.saveIndividualSemesterMemo(
        sessionId,
        formSemesterMemoData,
      )
      : IndividualSemesterMemoService.updateIndividualSemesterMemo(
        sessionId,
        dataIndividualSemester.id,
        formSemesterMemoData,
      );
    await promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      this.setState( { dataIndividualSemester } );
      toggleAlert( 'dataSaved', 'info' );
    } );

    await this.getData();
  }

  submitFinalStrategicReport = async ( frmFinalStrategicReport ) => {
    const { toggleLoading, toggleAlert } = this.props;
    const { dataFinalStrategicReport } = this.state;
    toggleLoading( true );

    const frmData = frmFinalStrategicReport;
    const sessionId = this.activeSession.id;

    // eslint-disable-next-line no-param-reassign
    frmData.workGroup = this.license.workGroup.id;
    // eslint-disable-next-line max-len
    const promise = dataFinalStrategicReport.id === undefined
      ? FinalStrategicReport.saveFinalStrategicReport( sessionId, frmData )
      : FinalStrategicReport.updateFinalStrategicReport(
        sessionId,
        dataFinalStrategicReport.id,
        frmData,
      );
    await promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      this.setState( { dataFinalStrategicReport } );
      toggleAlert( 'dataSaved', 'info' );
    } );

    await this.getData();
  }

  handleChangeUnderstanding = ( e ) => {
    this.setState( { understanding: e } );
  }

  handleChangeProductStrategy = ( e ) => {
    this.setState( { productStrategy: e } );
  }

  handleChangeCustomerStrategy = ( e ) => {
    this.setState( { customerStrategy: e } );
  }

  handleChangeUnderstandingB = ( e ) => {
    this.setState( { understandingB: e } );
  }

  handleChangeProductHits = ( e ) => {
    this.setState( { productHits: e } );
  }

  handleChangeCustomerHits = ( e ) => {
    this.setState( { customerHits: e } );
  }

  handleChangeProductErrors = ( e ) => {
    this.setState( { productErrors: e } );
  }

  handleChangeCustomerErrors = ( e ) => {
    this.setState( { customerErrors: e } );
  }

  handleChangeLearningPersonalActivity = ( e ) => {
    this.setState( { learningPersonalActivity: e } );
  }

  handleChangeGeneralStrategy = ( e ) => {
    this.setState( { generalStrategy: e } );
  }

  handleChangeProducts = ( e ) => {
    this.setState( { products: e } );
  }

  handleChangeSegments = ( e ) => {
    this.setState( { segments: e } );
  }

  render() {
    const { toggleAlert, user, toggleLoading, locale } = this.props;
    const charactersHint = locale.locale === 'en' ? 'remaining characters' : 'caracteres restantes';

    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    const {
      dataIndividualSemester,
      showFinalStrategicReport,
      showEmptyReport,
      strategiesReport,
      readIndividualSemesterMemo,
      readFinalStrategicReport,
      dataInitialStrategicPlan,
      showSemesterMemo,
      titleFormSection,
      readInitialStrategicPlan,
      dataFinalStrategicReport,
      showGeneralStrategy,
      CompModalB,
      weekNumber,
      titleType,
      understanding,
      productStrategy,
      customerStrategy,
      understandingB,
      productHits,
      customerHits,
      productErrors,
      customerErrors,
      learningPersonalActivity,
      generalStrategy,
      products,
      segments,
    } = this.state;

    const styleFormSelector = {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#169FC4',
    };

    const styleCursorSelector = { cursor: 'pointer' };

    return (
      <div>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'DecisionDashboard-strategies' },
          ]}
        />
        <KotlerSectionHeader title="strategies" image={HeaderImage} />
        <SectionInfoText title="help.strategy.snackbar" />
        <ExpansionPanel className="kotler-expansion-panel ">
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>
              <IntlMessages id="overview" />
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Table className="kotler-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IntlMessages id="plan" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="status" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="deliveryDate" />
                  </TableCell>
                  <TableCell align="center">
                    <IntlMessages id="seeGroup" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map( strategiesReport, ( item, index ) => (
                  <TableRow key={index}>
                    <TableCell>
                      <span
                        onClick={() => {
                          if (
                            item.type === 'initialStrategicPlan'
                            && ( item.action === 'create'
                              || item.action === 'update'
                              || item.action === 'read' )
                          ) {
                            this.setState( {
                              dataInitialStrategicPlan: item.data,
                              showEmptyReport: false,
                              readInitialStrategicPlan: false,
                              readIndividualSemesterMemo: false,
                              readFinalStrategicReport: false,
                            } );
                            this.toggleGeneralStrategy( item.type );
                            if ( item.action === 'read' ) {
                              this.setState( {
                                readInitialStrategicPlan: true,
                                readIndividualSemesterMemo: false,
                                readFinalStrategicReport: false,
                              } );
                            }
                          } else if (
                            item.type === 'individualSemesterMemo'
                            && ( item.action === 'create'
                              || item.action === 'update'
                              || item.action === 'read' )
                          ) {
                            this.setState( {
                              dataIndividualSemester: item.data,
                              showEmptyReport: false,
                              readIndividualSemesterMemo: false,
                              readInitialStrategicPlan: false,
                              readFinalStrategicReport: false,
                            } );
                            this.toggleSemesterMemo( item.type );
                            if ( item.action === 'read' ) {
                              this.setState( {
                                readInitialStrategicPlan: false,
                                readIndividualSemesterMemo: true,
                                readFinalStrategicReport: false,
                              } );
                            }
                          } else if (
                            item.type === 'finalStrategicReport'
                            && ( item.action === 'create'
                              || item.action === 'update'
                              || item.action === 'read' )
                          ) {
                            this.setState( {
                              dataFinalStrategicReport: item.data,
                              showEmptyReport: false,
                              readInitialStrategicPlan: false,
                              readIndividualSemesterMemo: false,
                              readFinalStrategicReport: false,
                            } );
                            this.toggleFinalStrategicReport( item.type );
                            if ( item.action === 'read' ) {
                              this.setState( {
                                readInitialStrategicPlan: false,
                                readIndividualSemesterMemo: false,
                                readFinalStrategicReport: true,
                              } );
                            }
                          } else if ( item.action === 'block' ) {
                            this.setState( {
                              showEmptyReport: true,
                              showSemesterMemo: false,
                              showGeneralStrategy: false,
                              showFinalStrategicReport: false,
                            } );
                          }
                        }}
                        style={
                          item.action === 'create'
                          || item.action === 'update'
                          || item.action === 'read'
                            ? styleFormSelector
                            : styleCursorSelector
                        }
                      >
                        <IntlMessages id={item.type} />
                        &nbsp;
                        {item.type === 'individualSemesterMemo'
                          ? item.name.substr( item.name.length - 1 )
                          : null}
                      </span>
                    </TableCell>
                    <TableCell>
                      <IntlMessages id={item.status.toLowerCase()} />
                    </TableCell>
                    <TableCell>
                      {item.untilDate
                        ? moment
                          .utc( Number( item.untilDate ) )
                          .format( 'DD/MM/YYYY HH:mm' )
                        : '-'}
                    </TableCell>
                    <TableCell align="center">
                      {item.type === 'individualSemesterMemo'
                      && ( item.status === 'UNDELIVERED'
                        || item.status === 'DELIVERED' ) ? (
                          <IconButton
                            onClick={() => {
                              this.handleClick( item.noWeek, item.type );
                              this.toggleIndividualMemo();
                            }}
                            className="square-icon-button-primary2"
                          >
                            <FeatherIcon icon="eye" color="primary2.light" size="18px" />

                          </IconButton>
                        ) : null}
                    </TableCell>
                  </TableRow>
                ) )}
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Divider className="mt-4 mb-3" />
        <Grid container>
          <Grid item xs={12}>
            <div
              classNam="px-2"
            >
              {showEmptyReport ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  m={1}
                  p={1}
                  bgcolor="background.paper"
                >
                  <Box p={1} variant="h3">
                    <br />
                    <br />
                    <h1 style={{ color: '#8C92AC' }}>
                      <i className="fa fa-inbox fa-2x" />
                      &nbsp;&nbsp;
                      <IntlMessages id="emptyMemo" />
                    </h1>
                  </Box>
                </Box>
              ) : null}
              {readInitialStrategicPlan && !showEmptyReport ? (
                <DetailReport
                  type="initialStrategicPlan"
                  data={Object.values( dataInitialStrategicPlan )}
                />
              ) : showGeneralStrategy ? (
                <div id="generalStrategy">
                  <Form
                    initialValues={dataInitialStrategicPlan}
                    onSubmit={this.submitInitialStrategicPlan}
                    render={( { handleSubmit } ) => (
                      <form onSubmit={handleSubmit} noValidate>
                        {!readInitialStrategicPlan ? (
                          <Grid container className="mb-2 " justify="space-between" alignItems="center" wrap="noWrap">
                            <Grid item xs={6}><KotlerCardHeader title={titleFormSection} minHeight="0px" /></Grid>
                            <Grid item>

                              <Button
                                variant="contained"
                                color="primary"
                                className="kotler-button-md"
                                type="submit"
                              >
                                <IntlMessages id="save" />
                              </Button>
                            </Grid>
                          </Grid>
                        ) : null}
                        <Row>
                          <Col md={12}>
                            <TextField
                              multiline
                              field="generalStrategy"
                              label="generalStrategy"
                              inputProps={{ maxLength: 500 }}
                              value={generalStrategy}
                              onChange={this.handleChangeGeneralStrategy}
                              helperText={`${
                                500 - generalStrategy.length
                              } ${charactersHint}`}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <TextField
                              multiline
                              field="products"
                              label="titProductsStrategies"
                              inputProps={{ maxLength: 500 }}
                              value={products}
                              onChange={this.handleChangeProducts}
                              helperText={`${
                                500 - products.length
                              } ${charactersHint}`}
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              multiline
                              rows="2"
                              field="segments"
                              label="titCustomerStrategies"
                              inputProps={{ maxLength: 500 }}
                              value={segments}
                              onChange={this.handleChangeSegments}
                              helperText={`${
                                500 - segments.length
                              } ${charactersHint}`}
                            />
                          </Col>
                        </Row>
                      </form>
                    )}
                  />
                </div>
              ) : null}
              {readIndividualSemesterMemo && !showEmptyReport ? (
                <DetailReport
                  type="individualSemesterMemo"
                  data={Object.values( dataIndividualSemester )}
                />
              ) : showSemesterMemo ? (
                <div id="semesterMemo">
                  <Form
                    initialValues={dataIndividualSemester}
                    onSubmit={this.submitSemesterMemoForm}
                    render={( { handleSubmit } ) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <Grid container className="mb-2 " justify="space-between" alignItems="center" wrap="nowrap">
                          <Grid item xs={6}><KotlerCardHeader title={titleFormSection} minHeight="0px" /></Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              className="kotler-button-sm"
                              type="submit"
                            >
                              <IntlMessages id="save" />
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              marginContainer={false}
                              multiline
                              field="understanding"
                              label="understanding"
                              inputProps={{ maxLength: 500 }}
                              value={understanding}
                              onChange={this.handleChangeUnderstanding}
                              helperText={`${
                                500 - understanding.length
                              } ${charactersHint}`}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              multiline
                              marginContainer={false}
                              field="productStrategy"
                              label="productStrategy"
                              inputProps={{ maxLength: 500 }}
                              value={productStrategy}
                              onChange={this.handleChangeProductStrategy}
                              helperText={`${
                                500 - productStrategy.length
                              } ${charactersHint}`}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              marginContainer={false}
                              field="customerStrategy"
                              label="customerStrategy"
                              inputProps={{ maxLength: 500 }}
                              value={customerStrategy}
                              onChange={this.handleChangeCustomerStrategy}
                              helperText={`${
                                500 - customerStrategy.length
                              } ${charactersHint}`}
                            />
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                </div>
              ) : null}
              {readFinalStrategicReport && !showEmptyReport ? (
                <DetailReport
                  type="finalStrategicReport"
                  data={Object.values( dataFinalStrategicReport )}
                />
              ) : showFinalStrategicReport ? (
                <div id="finalStrategicReport">
                  <Form
                    initialValues={dataFinalStrategicReport}
                    onSubmit={this.submitFinalStrategicReport}
                    render={( { handleSubmit } ) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <Row>
                          <Col>
                            <div className="d-flex align-items-rigth">
                              <Button
                                variant="contained"
                                color="primary"
                                className="jr-btn jr-btn-lg align-items-center"
                                type="submit"
                              >
                                <i className="fas fa-save" />
                                <IntlMessages id="save" />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <TextField
                              multiline
                              rows="3"
                              field="understanding"
                              label="understanding"
                              inputProps={{ maxLength: 500 }}
                              value={understandingB}
                              onChange={this.handleChangeUnderstandingB}
                              helperText={`${
                                500 - understandingB.length
                              } ${charactersHint}`}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <h4>
                              <IntlMessages id="titProductsStrategies" />
                            </h4>
                            <TextField
                              multiline
                              rows="2"
                              field="productHits"
                              label="productHits"
                              inputProps={{ maxLength: 500 }}
                              value={productHits}
                              onChange={this.handleChangeProductHits}
                              helperText={`${
                                500 - productHits.length
                              } ${charactersHint}`}
                            />
                          </Col>
                          <Col md={6}>
                            <h4>
                              <IntlMessages id="titCustomerStrategies" />
                            </h4>
                            <TextField
                              multiline
                              rows="2"
                              field="customerHits"
                              label="customerHits"
                              inputProps={{ maxLength: 500 }}
                              value={customerHits}
                              onChange={this.handleChangeCustomerHits}
                              helperText={`${
                                500 - customerHits.length
                              } ${charactersHint}`}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <TextField
                              multiline
                              rows="2"
                              field="productErrors"
                              label="productErrors"
                              inputProps={{ maxLength: 500 }}
                              value={productErrors}
                              onChange={this.handleChangeProductErrors}
                              helperText={`${
                                500 - productErrors.length
                              } ${charactersHint}`}
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              multiline
                              rows="2"
                              field="customerErrors"
                              label="customerErrors"
                              inputProps={{ maxLength: 500 }}
                              value={customerErrors}
                              onChange={this.handleChangeCustomerErrors}
                              helperText={`${
                                500 - customerErrors.length
                              } ${charactersHint}`}
                            />
                          </Col>
                          <Col md={12}>
                            <TextField
                              multiline
                              rows="2"
                              field="learningPersonalActivity"
                              label="learningPersonalActivity"
                              inputProps={{ maxLength: 500 }}
                              value={learningPersonalActivity}
                              onChange={
                                this.handleChangeLearningPersonalActivity
                              }
                              helperText={`${
                                500 - learningPersonalActivity.length
                              } ${charactersHint}`}
                            />
                          </Col>
                        </Row>
                      </form>
                    )}
                  />
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
        {weekNumber > 0 && (
          <CompModal
            open={CompModalB}
            title={titleType}
            onClose={this.toggleIndividualMemo}
            sessionId={this.activeSession.id}
            workGroupId={this.license.workGroup.id}
            workGroupName={this.license.workGroup.name}
            weekNumber={weekNumber}
            toggleLoading={toggleLoading}
            toggleAlert={toggleAlert}
          />
        )}

      </div>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Strategy );
