import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';

import HeaderImage from 'assets/images/kotler/sectionHeaders/campaing.jpeg';
import { Switch } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import CommunicationService from 'services/Communication';
import HoverTitle from 'components/custom_v2/HoverTitle';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import SectionInfoText from 'components/custom/SectionInfoText';
import FeatherIcon from 'components/custom/FeatherIcon';
import KotlerPagination from 'components/custom/KotlerPagination';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import { STATUS } from 'modules/sessions';

class Dashboard extends React.Component {
  state = {
    data: [],
    showActiveCampaigns: 1,
    confirmDeactivateModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  orderByField = ( fieldName ) => {
    const { data, orientationOrderBy } = this.state;
    const NewOrientationOrderBy = orientationOrderBy === 'asc' ? 'desc' : 'asc';
    const orderedData = _.orderBy(
      data,
      fieldName === 'name' ? [arr => arr.name.toLowerCase()] : [fieldName],
      [orientationOrderBy],
    );
    this.setState( {
      data: orderedData,
      orientationOrderBy: NewOrientationOrderBy,
    } );
  };

  getData = () => {
    const { user, toggleLoading, toggleAlert } = this.props;
    const { showActiveCampaigns } = this.state;

    toggleLoading( true );

    CommunicationService.getCampaigns( user.activeSession.id, {
      workgroup: this.license.workGroup.id,
      'sortby[name]': 'ASC',
      'filters[active]': showActiveCampaigns ? 1 : 0,
      limit: -1,
    } ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      this.setState( { data: response.data.data } );
    } );
  };
  toggleConfirmDeactivateModal = () => {
    const { confirmDeactivateModal } = this.state;
    this.setState( { confirmDeactivateModal: !confirmDeactivateModal } );
  };

  changeStatus = () => {
    const { user, toggleLoading, toggleAlert } = this.props;

    toggleLoading( true );
    CommunicationService.changeStatusCampaign(
      user.activeSession.id,
      this.campaignToDeactivate,
      { workgroup: this.license.workGroup.id, active: !this.campaignActive },
    ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      toggleAlert( 'dataSaved', 'info' );
      this.getData();
    } );
    this.toggleConfirmDeactivateModal();
  };
  render() {
    const { showActiveCampaigns, data, confirmDeactivateModal } = this.state;
    const { match, user } = this.props;

    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'DecisionDashboard-comunications' },
          ]}
        />

        <KotlerSectionHeader title="crm" image={HeaderImage} />
        <Grid
          container
          className="justify-content-around justify-content-lg-between"
        >
          <Grid
            item
            xs={12}
            md={
              user
              && user.activeSession
              && user.activeSession.status === STATUS.READY
                ? 8
                : 9
            }
          >
            <SectionInfoText title="help.crm.snackbar" />
          </Grid>
          <Grid item xs="auto" md={2}>
            <div className=" h-100 d-flex align-items-center justify-content-end">
              <Switch
                label="ShowActiveCampaign"
                checked={showActiveCampaigns}
                // eslint-disable-next-line max-len
                onChange={checked => this.setState( { showActiveCampaigns: checked }, this.getData )
                }
                marginContainer={false}
                hover={{
                  id: showActiveCampaigns
                    ? 'allowsYouToSeeInactiveCampaigns'
                    : 'allowsYouToSeeActiveCampaigns',
                }}
              />
            </div>
          </Grid>

          {user
            && user.activeSession
            && user.activeSession.status === STATUS.READY && (
            <Grid item xs="auto" className="d-flex align-items-center">
              <Link
                to={`${match.url}/new/campaign`}
                className="text-decoration-none"
              >
                <Button
                  color="primary"
                  variant="contained"
                  className="jr-btn jr-btn-lg"
                >
                  <IntlMessages id="newCampaign" />
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex justify-content-end">
            <span className="text-primary2 font-bold">
              <IntlMessages
                id="campaings.conunter"
                values={{ total: ( data && data.length ) || 0, max: 100 }}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="kotler-table-container">
            <KotlerPagination data={data}>
              {( { pageData } ) => (
                <Table size="small" className="kotler-table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '24%' }}>
                        <span
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={this.orderByField.bind( this, 'name' )}
                        >
                          <IntlMessages id="campaign" />
                        </span>
                      </TableCell>
                      <TableCell style={{ width: '24%' }}>
                        <span
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={this.orderByField.bind(
                            this,
                            'startDate.startDate',
                          )}
                        >
                          <IntlMessages id="startDate" />
                        </span>
                      </TableCell>
                      <TableCell style={{ width: '24%' }}>
                        <span
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={this.orderByField.bind(
                            this,
                            'endDate.endDate',
                          )}
                        >
                          <IntlMessages id="endDate" />
                        </span>
                      </TableCell>

                      <TableCell style={{ width: '24%' }}>
                        <HoverTitle id="allowsYouToDeactivateActiveCampaign">
                          <span>
                            <IntlMessages id="status" />
                          </span>
                        </HoverTitle>
                      </TableCell>
                      <TableCell style={{ width: '4%' }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map( pageData, campaign => (
                      <TableRow key={campaign.id}>
                        <TableCell
                          style={{
                            wordWrap: 'break-word',
                            maxWidth: '20vw',
                            textTransform: 'uppercase',
                          }}
                        >
                          {campaign.name}
                        </TableCell>
                        <TableCell>
                          {campaign.startDate ? (
                            <>
                              <IntlMessages
                                id={campaign.startDate.largeName
                                  .trim()
                                  .split( ' ' )[0]
                                  .toLowerCase()}
                              />
                              {` ${
                                campaign.startDate.largeName
                                  .trim()
                                  .split( ' ' )[1]
                              }`}
                            </>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell>
                          {campaign.endDate ? (
                            <>
                              <IntlMessages
                                id={campaign.endDate.largeName
                                  .trim()
                                  .split( ' ' )[0]
                                  .toLowerCase()}
                              />
                              {` ${
                                campaign.endDate.largeName.trim().split( ' ' )[1]
                              }`}
                            </>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="d-flex align-items-center py-1">
                            <Switch
                              checked={campaign.active}
                              marginContainer={false}
                              disabled={!campaign.active}
                              onChange={() => {
                                this.campaignToDeactivate = campaign.id;
                                this.campaignActive = campaign.active;
                                this.toggleConfirmDeactivateModal();
                              }}
                              hover={{
                                id: campaign.active
                                  ? 'allowsYouToDeactivateActiveCampaign'
                                  : 'allowsYouToDeactivateActiveDeactivateCampaign',
                              }}
                            />
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 500 }}
                            >
                              {campaign.active ? (
                                <IntlMessages id="lblActive" />
                              ) : (
                                <IntlMessages id="lblInactive" />
                              )}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="d-flex ">
                            <Link
                              to={`/sessions/general/crm/campaign/${campaign.id}`}
                            >
                              <HoverTitle id="showsDetailsResultsCampaign">
                                <div>
                                  <FeatherIcon
                                    icon="eye"
                                    size="22px"
                                    color="text.primary"
                                  />
                                </div>
                              </HoverTitle>
                            </Link>
                            {user
                              && user.activeSession
                              && user.activeSession.status === STATUS.READY && (
                              <Link
                                to={`/sessions/general/crm/campaign/${campaign.id}/clone`}
                              >
                                <HoverTitle id="copiesExistingCampaignIntoNewOne">
                                  <div>
                                    <FeatherIcon
                                      icon="copy"
                                      size="22px"
                                      color="text.primary"
                                      className="ml-3"
                                    />
                                  </div>
                                </HoverTitle>
                              </Link>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ) )}
                  </TableBody>
                </Table>
              )}
            </KotlerPagination>
          </div>
        </Grid>

        <ConfirmationDialog
          open={confirmDeactivateModal}
          title="confirmDeactivateCampaign"
          confirmBtnText="ok"
          showCancel
          closeBtnText="btnNo"
          closeOnClickOutside={false}
          onSubmit={this.changeStatus}
          onClose={() => {
            this.toggleConfirmDeactivateModal();
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Dashboard );
