import ClientRetention from '../assets/client_retention.png';
import FirstPlaceSession from '../assets/first_place_session.png';
import HightDecision from '../assets/high_decisions.png';
import HighRegisteredAndUnregistered from '../assets/high_registered_and_unregistered.png';
import HighDirectMarketingCapaign from '../assets/hight_direct_marketing_capaign.png';

const ACHIEVEMENTS = {
  highest_client_retention: { Image: ClientRetention },
  highest_stock_value: { Image: FirstPlaceSession },
  highest_decisions_taken: { Image: HightDecision },
  highest_client_active_registered_and_unregistered: { Image: HighRegisteredAndUnregistered },
  highest_direct_marketing_campaigns: { Image: HighDirectMarketingCapaign },
};

export default ACHIEVEMENTS;
