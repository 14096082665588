const { useMemo } = require( 'react' );
const { useSelector } = require( 'react-redux' );

const TUTORIAL_ENTRIES = {
  welcome: 'welcome',
  livemax_world: 'entry1',
  your_business: 'entry2',
  the_competition: 'entry3',
  how_is_livemax_used: 'entry4',
  tips_to_get_started: 'entry5',
  checklist_for_first_decisions: 'entry6',
  simulation_start: 'entry7',
  fixed_costs: 'entry8',
  variable_costs: 'entry9',
  'p&l_dictionary': 'entry10',
  new_product_development: 'new_product_dev',
};

// eslint-disable-next-line import/prefer-default-export
export const useTutorialProps = ( id ) => {
  const entry = TUTORIAL_ENTRIES[id || ''];
  const isStudio = useSelector(
    ( { user } ) => user
      && user.activeSession
      && user.activeSession.distributionType === 'studio',
  );
  const title = useMemo(
    () => `tutorial.index.${entry}${
      isStudio && id !== 'welcome' && id !== 'new_product_development'
        ? '.studio'
        : ''
    }`,
    [isStudio, id, entry],
  );
  const content = useMemo(
    () => `tutorial.content.${entry}${
      isStudio && id !== 'new_product_development' ? '.studio' : ''
    }`,
    [isStudio, id, entry],
  );

  const link = useMemo( () => `/tutorial/${id}`, [id] );

  return { entry, title, content, link };
};
