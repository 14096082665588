import { create } from 'apisauce';
import _ from 'lodash';
import { formatMessage } from 'lngProvider';
import { store } from '../MainApp';

// Creación de una instancia de 'apisauce' para interactuar con la API
const api = create( {
  baseURL: process.env.REACT_APP_API_URL, // URL base de la API
  timeout: 60000, // Tiempo de espera máximo para las solicitudes en milisegundos (60 segundos aqui)
} );

// Transformación de solicitudes antes de enviarlas a la API
api.addRequestTransform( ( request ) => {
  request.url = `${process.env.REACT_APP_API_URL}${request.url}`;

  // Obtener datos de usuario y token de acceso del almacenamiento local
  const data = JSON.parse( localStorage.getItem( 'mk21' ) );
  const accessToken = _.get( data, 'user.access_token' );

  // Agregar token de autorización a la solicitud si está presente
  if ( accessToken && request.url.indexOf( 'spyToken' ) === -1 ) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  // Agregar información de sesión y configuración de idioma a las cabeceras de la solicitud
  if ( _.get( data, 'user.activeSession.id' ) ) {
    request.headers['x-session'] = data.user.activeSession.id;
    request.headers['x-locale'] = data.settings.locale.locale || 'en';
  }
} );

// Transformación de respuestas después de recibir una respuesta de la API
api.addResponseTransform( ( response ) => {
  const { data, ok } = response;
  // Obtener el objeto 'intl' para la internacionalización

  if ( !ok ) {
    if ( data === 'SESSION_CLOSED' ) {
      store.dispatch( { type: 'OPEN_NO_SESSION_ALERT' } );
    }

    let errors = '';

    // Manejar diferentes estructuras de errores posibles en la respuesta
    if ( !data ) return 'API ERROR';
    const config = JSON.parse( _.get( response, 'config.data', '{}' ) );
    if ( data.error === 'invalid_grant' && ( !config || !config.grant_type ) ) {
      window.location.replace( '/login' );
    }

    if ( data ) {
      // Manejar errores en formato específico
      if ( data.error_description ) errors = data.error_description;
      else if ( data.errors ) {
        if ( _.isObject( data.errors ) && !_.isArray( data.errors ) ) {
          errors = [];
          _.map( _.keys( data.errors ), ( key ) => {
            const error = data.errors[key];
            if ( _.isString( error ) ) {
              errors.push( error );
            } else if ( _.isObject( error ) && !_.isArray( error ) ) {
              _.map( _.keys( error ), ( errorKey ) => {
                const error2 = error[errorKey];
                if ( _.isObject( error2 ) && !_.isArray( error2 ) ) {
                  _.map( _.keys( error2 ), ( error2Key ) => {
                    const error3 = error2[error2Key];
                    if ( _.isString( error3 ) ) {
                      errors.push( error3 );
                    } else if ( _.isArray( error3 ) ) {
                      errors.push(
                        `${errorKey}  ${error2Key} ${error3.join( '\n' )}`,
                      );
                    }
                  } );
                } else if ( _.isArray( error2 ) ) {
                  errors.push( `${key}  ${errorKey} ${error2.join( '\n' )}` );
                }
              } );
            } else if ( _.isArray( error ) ) {
              _.map( error, ( itemError ) => {
                const error2 = itemError;
                if ( _.isString( error ) ) {
                  errors.push( error );
                } else if ( _.isObject( error2 ) && !_.isArray( error2 ) ) {
                  _.map( _.keys( error2 ), ( error2Key ) => {
                    const error3 = error2[error2Key];
                    if ( _.isString( error3 ) ) {
                      errors.push( error3 );
                    } else if ( _.isArray( error3 ) ) {
                      errors.push( ` ${error2Key} ${error3.join( '\n' )}` );
                    }
                  } );
                } else if ( _.isArray( error2 ) ) {
                  errors.push( `${key} ${error2.join( '\n' )}` );
                } else {
                  errors.push( itemError );
                }
              } );
            }
          } );
        } else {
          errors = data.errors; // eslint-disable-line
        }
      } else if ( data.error ) {
        if ( _.isObject( data.error ) && !_.isArray( data.error ) ) {
          errors = [];
          _.map( data.error, ( error ) => {
            if ( _.isString( error ) ) errors.push( error );
            else if ( _.isArray( errors ) ) errors.push( error.join( '\n' ) );
          } );
          errors = errors.join( '\n' );
        } else {
          errors = data.error;
        }
      } else if ( data.message ) errors = data.message;
      else errors = data;
    } else {
      errors = 'API ERROR';
    }
    response.errors = `ERR-${data.code ? data.code : 1000}: ${formatMessage(
      errors,
    )}`;
  }
} );

// Exportación de la instancia API configurada para su uso en otras partes de la aplicación
export default api;
