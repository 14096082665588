import {
  FETCH_PRICE_PRODUCTS_REQUEST,
  FETCH_PRICE_PRODUCTS_SUCCESS,
  FETCH_PRICE_PRODUCTS_FAILURE,
  CLEAR_PRICE_PRODUCTS,
} from '../actions/PriceProductsActions';

const initialState = { byCategoryId: {} };

const priceProductsReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_PRICE_PRODUCTS_REQUEST:
    return {
      ...state,
      byCategoryId: {
        ...state.byCategoryId,
        [action.payload.categoryId]: {
          ...( state.byCategoryId[action.payload.categoryId] || {} ), // Validar si el objeto existe
          loading: true,
          error: null,
        },
      },
    };
  case FETCH_PRICE_PRODUCTS_SUCCESS:
    return {
      ...state,
      byCategoryId: {
        ...state.byCategoryId,
        [action.payload.categoryId]: {
          ...( state.byCategoryId[action.payload.categoryId] || {} ), // Validar si el objeto existe
          data: action.payload.data,
          loading: false,
          error: null,
        },
      },
    };
  case FETCH_PRICE_PRODUCTS_FAILURE:
    return {
      ...state,
      byCategoryId: {
        ...state.byCategoryId,
        [action.payload.categoryId]: {
          ...( state.byCategoryId[action.payload.categoryId] || {} ), // Validar si el objeto existe
          loading: false,
          error: action.payload.error,
        },
      },
    };
  case CLEAR_PRICE_PRODUCTS:
    return {
      ...state,
      byCategoryId: {},
    };
  default:
    return state;
  }
};

export default priceProductsReducer;
