import { takeEvery, put, call } from 'redux-saga/effects';
import { getLastRoundBundles } from 'modules/Bundles/BundlesService';
import {
  FETCH_LAST_ROUND_BUNDLES_REQUEST,
  fetchLastRoundBundlesFailure,
  fetchLastRoundBundlesSuccess,
} from '../actions/LastRoundBundlesActions';

function* fetchLastRoundBundlesSaga( action ) {
  const { sessionId, workgroupId, params = { limit: -1 } } = action.payload;
  try {
    const lastRoundBundlesData = yield call(
      getLastRoundBundles,
      sessionId,
      workgroupId,
      params,
    );
    yield put( fetchLastRoundBundlesSuccess( lastRoundBundlesData ) );
  } catch ( error ) {
    yield put( fetchLastRoundBundlesFailure( error ) );
  }
}

export default function* lastRoundBundlesRootSaga() {
  yield takeEvery( FETCH_LAST_ROUND_BUNDLES_REQUEST, fetchLastRoundBundlesSaga );
}
