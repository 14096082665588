import { useQuery } from 'react-query';

import { showError } from 'utils/alerts';
import { getChangelogs } from '../services/ChangelogService';

const get = async ( ) => {
  const res = await getChangelogs( );
  if ( res.ok ) {
    return res.data;
  }
  if ( !res.ok ) {
    if ( res.errors ) {
      throw new Error( res.errors );
    }
    throw new Error( 'Error' );
  }
};


const createQueryKey = () => ['v2', 'changelogs'];


export const useChangelogsQuery = ( ) => {
  const logsQuery = useQuery(
    createQueryKey( ),
    () => get( ),
    {
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 12,
      retryOnMount: true,
      onError( error ) {
        showError( error && error.message );
      },
    },
  );

  return { logsQuery };
};
