import {
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  withWidth,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import IntlMessages from 'utils/IntlMessages';

/**
 * Componente ContainerGraphModal
 *
 * Este componente se utiliza para crear un contenedor modal que puede contener contenido personalizado, como gráficos u otros elementos.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {ReactNode} props.children - El contenido que se mostrará dentro del modal.
 * @param {string} props.title - El título del modal.
 * @param {boolean} props.open - Un booleano que indica si el modal está abierto o cerrado.
 * @param {function} props.onClose - Una función que se ejecutará al cerrar el modal.
 * @param {string} props.width - El ancho del modal, que se obtiene de Material-UI.
 * @param {string} props.maxWidth - El ancho máximo del modal (por defecto es 'md').
 * @param {boolean} props.fullScreen - Un booleano que indica si el modal debe mostrarse en pantalla completa en dispositivos pequeños.
 * @returns {JSX.Element} - El componente ContainerGraphModal.
 */
const ContainerGraphModal = React.memo(
  ( { children, title, open, onClose, width, maxWidth = 'md', fullScreen } ) => (
    <Dialog
      maxWidth={maxWidth}
      fullScreen={width === 'xs' || width === 'sm' || fullScreen}
      fullWidth
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
    >
      <div className="px-2 py-3" style={{ minHeight: '300px' }}>
        <DialogTitle className="p-0 ml-1">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, fontSize: 21 }}
              color="textPrimary"
            >
              {title && <IntlMessages id={title} />}
            </Typography>
            {onClose ? (
              <IconButton
                aria-label="close"
                className="square-icon-button"
                onClick={onClose}
                style={
                  !title ? { position: 'absolute', top: 20, right: 20 } : {}
                }
              >
                <Close className="text-dark" />
              </IconButton>
            ) : null}
          </div>
        </DialogTitle>

        {children}
      </div>
    </Dialog>
  ),
);

export default withWidth()( ContainerGraphModal );
