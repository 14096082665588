import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import _ from 'lodash';

// import { HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import Header from 'components/custom_v2/Header/index';
import Error404 from 'components/theme/Error404';

import { useActiveSession } from 'modules/sessions';
import { UniversityNowPaper } from 'components/common/UniversityNowPaper';
import { CurrentUniversityAvatar } from 'components/common/CurrentUniversityAvatar';
import PaperImage from 'assets/images/kotler/hoja-con-ganchos.png';
import { Hidden } from '@material-ui/core';
import Admin from './routes/Admin';
import User from './routes/V2/User';
import Professor from './routes/V2/Professor';
import ProfileSettings from './routes/ProfileSettings';
import SessionInfoBar from './components/User/SessionInfoBar';
import Changelog from './routes/Changelog';

const App = ( { match, /* navigationStyle */ user, isVideo, location } ) => {
  // set default height and overflow for iOS mobile Safari 10+ support.
  const { isNow } = useActiveSession();
  if ( isIOS && isMobile ) {
    document.body.classList.add( 'ios-mobile-view-height' );
  } else if ( document.body.classList.contains( 'ios-mobile-view-height' ) ) {
    document.body.classList.remove( 'ios-mobile-view-height' );
  }

  function hasPermissions( role, license ) {
    // eslint-disable-next-line max-len
    return (
      user.roles
      && user.roles.indexOf( role ) !== -1
      && user.licenses
      && _.find( user.licenses, { owner: license } )
    );
  }
  const BgImage = useMemo( () => {
    if ( isNow ) return '/images/now/dashboard-background.jpeg';
    return '/images/kotler/dashboard-background.jpeg';
  }, [isNow] );
  const BgImageItems = useMemo( () => {
    if ( isNow ) return '/images/now/dashboard-items.png';
    return '/images/kotler/dashboard-items.png';
  }, [isNow] );

  return (
    <div className="app-container">
      <div className="app-main-container">
        <Header user={user} />

        <main className="app-main-content-wrapper d-block  ">
          <div
            style={{
              height: '100%',
              backgroundColor: location.pathname.includes(
                'sessions/general/products/',
              )
                ? '#FBFBFF'
                : '',
              backgroundImage:
                location.pathname === '/' ? `url(${BgImage})` : '',
              backgroundSize: 'cover',

              backgroundRepeat: 'no-repeat',
              overflowX: 'hidden',
            }}
          >
            <div
              className=" animated slideInUpTiny animation-duration-3  h-100   pt-0 "
              style={
                isVideo && !hasPermissions( 'ROLE_SUPERVISOR', 'sup' )
                  ? { backgroundColor: 'white' }
                  : {
                    backgroundColor: location.pathname.includes(
                      'sessions/general/products/',
                    )
                      ? '#FBFBFF'
                      : 'transparent',

                    position: 'relative',
                  }
              }
            >
              {location.pathname === '/'
                && !hasPermissions( 'ROLE_ADMIN', 'adm' ) && (
                <Hidden xsDown>
                  <img
                    src={BgImageItems}
                    className="background-elements"
                    alt="livemax-things"
                  />
                </Hidden>
              )}
              {location.pathname === '/'
                && user
                && user.activeSession
                && user.activeSession.entity
                && user.activeSession.entity.avatarPath && (
                <Hidden xsDown>
                  <>
                    {!isNow && (
                      <div
                        className="papper-university-avatar  "
                        style={{ backgroundImage: `url(${PaperImage})` }}
                      >
                        <div className="container-university-avatar ">
                          <img
                            className="university-avatar"
                            src={user.activeSession.entity.avatarPath}
                            alt={user.activeSession.entity.name}
                          />
                        </div>
                      </div>
                    )}
                    {isNow && (
                      <UniversityNowPaper
                        style={{
                          position: 'fixed',
                          bottom: 0,
                          minWidth: '370px',
                          width: '30vw',
                        }}
                      >
                        <CurrentUniversityAvatar width="100%" />
                      </UniversityNowPaper>
                    )}
                  </>
                </Hidden>
              )}
              {!location.pathname.includes( 'tutorial' ) && <SessionInfoBar />}

              <Switch>
                <Route
                  path={`${match.url}profile-settings`}
                  render={props => <ProfileSettings {...props} user={user} />}
                />
                <Route path={`${match.url}changelog`} component={Changelog} />
                {hasPermissions( 'ROLE_ADMIN', 'adm' ) ? (
                  <Route path={match.url} component={Admin} />
                ) : null}
                {hasPermissions( 'ROLE_CLIENT', 'user' ) ? (
                  <Route
                    path={match.url}
                    render={props => <User {...props} user={user} />}
                  />
                ) : null}
                {hasPermissions( 'ROLE_SUPERVISOR', 'sup' ) ? (
                  <Route path={match.url} component={Professor} />
                ) : null}
                <Route component={Error404} />
              </Switch>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = ( { settings, user } ) => {
  const { navigationStyle, isVideo } = settings;
  return {
    navigationStyle,
    user,
    isVideo,
  };
};
export default connect( mapStateToProps )( App );
