import React from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';
import { connect } from 'react-redux';

import TablePagination from '../Pagination';

class AsyncTable extends React.Component {
  getData = ( params ) => {
    const apiParams = {
      limit: params.pageSize,
      offset: params.page * params.pageSize + 1,
    };
    if ( params.sorted.length ) {
      apiParams[`sortby[${params.sorted[0].id}]`] = params.sorted[0].desc
        ? 'DESC'
        : 'ASC';
    } else apiParams['sortby[createdAt]'] = 'DESC';
    this.props.onFetchData( apiParams );
  };

  render() {
    const {
      className,
      showPageSizeOptions,
      showPaginationBottom = false,
      globalPageSize = 20,
      tableRef,
      ...rest
    } = this.props;

    return (
      <ReactTable
        ref={tableRef}
        className={`kotler-async-table  ${className}`}
        defaultPageSize={globalPageSize}
        manual
        minRows={rest.data.length}
        rowsText=""
        pageSizeOptions={[5, 10, 20, 50, 100]}
        resizable={false}
        showPaginationBottom={showPaginationBottom}
        showPaginationTop={false}
        getTheadFilterThProps={() => ( { style: { position: 'inherit', overflow: 'inherit' } } )}
        showPageSizeOptions={
          _.isUndefined( showPageSizeOptions ) ? true : showPageSizeOptions
        }
        PaginationComponent={props => <TablePagination {...props} />}
        {...rest}
        onFetchData={this.getData}
        getTrProps={() => ( { className: 'pointer' } )}
      />
    );
  }
}
const mapStateToProps = ( { settings } ) => {
  const { globalPageSize } = settings;
  return { globalPageSize };
};
export default connect( mapStateToProps )( AsyncTable );
