import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import Dropzone from 'react-dropzone';
import IntlMessages from 'utils/IntlMessages';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button } from '@material-ui/core';

const acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

class ProfileAvatarEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      croppedImg: null,
    };
    this.cropImage = this.cropImage.bind(this);
    this.canvasRef = React.createRef(null);
  }

  handleOnDrop = (file, rejectedFiles) => {
    const { toggleAlert } = this.props;

    if (rejectedFiles && rejectedFiles.length > 0) {
      toggleAlert(<IntlMessages id="dragNdrop-error" />);
    }

    if (file && file.length > 0) {
      const newFile = file[0];
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          this.setState({
            src: reader.result,
          });
        },
        false
      );
      reader.readAsDataURL(newFile);
    }
  };

  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    let croppedCanvas = this.cropper.getCroppedCanvas();
    let roundedCanvas = this.getRoundedCanvas(croppedCanvas);

    this.setState({ croppedImg: roundedCanvas.toDataURL() });
  }

  getRoundedCanvas(sourceCanvas) {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
   /*  context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    ); */
    context.fill();
    return canvas;
  }

  render() {
    const { src, croppedImg } = this.state;
    const { onSave } = this.props;
    return (
      <div className="row h-100">
        {!src ? (
          <div className="col-md-12">
            <Dropzone
            className="p-5 overflow-visible d-flex align-items-center text-center" style={{minHeight:300}}
              accept={acceptedFileTypes}
              multiple={false}
              onDrop={this.handleOnDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="h-100 w-100">
                  <div
                    {...getRootProps()}
                    className="profile-dropzone-file-btn h-100 w-100"
                  >
                    <div className="row h-100 d-flex justify-content-center">
                      <div className="my-auto">
                        <input {...getInputProps()} />
                        <h3>
                          <IntlMessages id="dragNdrop-label" />
                        </h3>
                        <p>
                          <i>
                            <IntlMessages id="dragNdrop-labelText" />
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        ) : (
          <React.Fragment>
            <div className="col-lg-6 col-md-9">
              <Cropper
                style={{ maxHeight: 400 }}
                aspectRatio={1}
                
                src={this.state.src}
                ref={cropper => {
                  this.cropper = cropper;
                }}
                viewMode={3}
              />
              <div className="row d-flex justify-content-center">
                <Button
                  id="crop"
                  variant="contained"
                  color="primary"
                  className="jr-btn jr-btn-lg mt-2"
                  onClick={this.cropImage}
                  type="button"
                >
                  <i className="fa fa-crop" />
                  <IntlMessages id="profile-crop" />
                </Button>

                <Button
                  id="crop"
                  variant="contained"
                  color="primary"
                  className="jr-btn jr-btn-lg mt-2"
                  onClick={() => onSave(croppedImg)}
                  disabled={croppedImg === null}
                  type="button"
                >
                  <i className="fa fa-save" />
                  <IntlMessages id="profile-save" />
                </Button>
              </div>
            </div>

            <div className="col-md-3">
              <canvas ref={this.canvasRef} className="canvas" />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(null, mapDispatchToProps)(ProfileAvatarEditor);
