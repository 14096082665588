import { createReducer, createActions } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getChangelogs } from '../services/ChangelogService';
// Action types and action creators
export const { Types, Creators: changelogActions } = createActions( {
  set: ['changelogs', 'loading', 'error'],
  fetchChangelogsRequest: ['sessionId'],
  fetchChangelogsSuccess: ['data'],
  setChangelog: ['changelog'],
  fetchChangelogsFailure: ['error'],
  clearChangelogs: [],
} );

// Reducer initial state
const INITIAL_STATE = {
  changelogSelected: null,
  changelogs: [],
  features: {},
  loading: false,
  error: null,
};

// Reducers
export const changelogsReducer = createReducer( INITIAL_STATE, {
  [Types.FETCH_CHANGELOGS_REQUEST]: state => ( {
    ...state,
    loading: true,
    error: null,
  } ),
  [Types.SET_CHANGELOG]: ( state, { changelog } ) => ( {
    ...state,
    changelogSelected: changelog,
  } ),
  [Types.SET]: ( state, newState ) => ( { ...state, ...newState } ),
  [Types.FETCH_CHANGELOGS_SUCCESS]: ( state, { data } ) => ( {
    ...state,
    loading: false,
    changelogs: data,
  } ),
  [Types.FETCH_CHANGELOGS_FAILURE]: ( state, { error } ) => ( {
    ...state,
    loading: false,
    error,
  } ),
  [Types.CLEAR_CHANGELOGS]: () => INITIAL_STATE,
} );

// Sagas
function* fetchChangelogsSaga( { sessionId } ) {
  const response = yield call( getChangelogs, sessionId );
  if ( response.ok ) yield put( changelogActions.fetchChangelogsSuccess( response.data ) );
  else {
    yield put(
      changelogActions.fetchChangelogsFailure(
        response.error || 'Has been error',
      ),
    );
  }
}

export function* changelogsRootSaga() {
  yield takeLatest( Types.FETCH_CHANGELOGS_REQUEST, fetchChangelogsSaga );
}
