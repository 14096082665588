import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText } from '@material-ui/core';
import _, { isArray } from 'lodash';
import classNames from 'classnames';
import Select from 'react-select';

import IntlMessages from 'utils/IntlMessages';
import { formatMessage } from 'helpers/IntlHelpers';

const SelectField = ( {
  field,
  idKey,
  labelKey,
  onChange,
  disabled,
  adornRequired = false,
  containerClass,
  emptyValue = false,
  fullWidth = true,
  marginContainer = true,
  label,
  options,
  translateOptions = true,
  translateValues,
  validate,
  ...rest
} ) => {
  options = emptyValue && !rest.isMulti ? [{ id: null }, ...options] : options;
  if ( options && options[0] && options[0].id === null ) {
    options[0][labelKey || 'name'] = '--';
  }
  return field ? (
    <Field name={field} validate={validate}>
      {( { input, meta } ) => {
        let predefinedValue;
        if ( rest.isMulti ) {
          predefinedValue = isArray( input.value ) ? input.value : [];
        } else {
          predefinedValue = input.value || '';
        }

        return (
          <FormControl
            className={classNames(
              'kotler-select-form-control',
              containerClass,
              { 'mb-4': marginContainer },
            )}
            style={
              fullWidth
                ? {
                  flex: 1,
                  display: 'flex',
                }
                : {}
            }
            error={meta.touched && !!meta.error}
          >
            {label && (
              <label>
                <IntlMessages id={label} />
                {adornRequired && <span className="text-danger">*</span>}
              </label>
            )}
            <Select
              {...rest}
              isDisabled={disabled}
              /* classNamePrefix=" kotler-select" */
              className={classNames( ' kotler-select', { 'is-invalid': meta.touched && meta.error } )}
              classNamePrefix="kotler-select"
              placeholder={
                rest.placeholder ? <IntlMessages id={rest.placeholder} /> : ''
              }
              value={
                rest.isMulti
                  ? predefinedValue.map( item => ( {
                    value: item,
                    label: translateOptions
                      ? formatMessage( {
                        id: _.get(
                          options.find( o => o[idKey || 'id'] === item ),
                          [labelKey || 'name'],
                        ),
                      } )
                      : _.get( options.find( o => o[idKey || 'id'] === item ), [
                        labelKey || 'name',
                      ] ),
                  } ) )
                  : predefinedValue && {
                    value: predefinedValue,
                    label: translateOptions
                      ? formatMessage( {
                        id: _.get(
                          options.find(
                            o => o[idKey || 'id'] === predefinedValue,
                          ),
                          [labelKey || 'name'],
                        ),
                      } )
                      : _.get(
                        options.find(
                          o => o[idKey || 'id'] === predefinedValue,
                        ),
                        [labelKey || 'name'],
                      ),
                  }
              }
              onChange={( val ) => {
                if ( val !== input.value ) {
                  input.onChange(
                    val
                      ? rest.isMulti
                        ? _.map( val, 'value' )
                        : val.value
                      : null,
                  );
                  if ( onChange ) {
                    onChange(
                      val
                        ? rest.isMulti
                          ? _.map( val, 'value' )
                          : val.value
                        : null,
                      input.value,
                    );
                  }
                }
              }}
              options={_.map( options, option => ( {
                value: _.get( option, idKey || 'id' ),
                label: translateOptions
                  ? option[labelKey || 'name']
                    ? formatMessage( { id: option[labelKey || 'name'] } )
                    : option[labelKey || 'name']
                  : _.get( option, labelKey || 'name' ),
              } ) )}
            />
            {meta.touched && meta.error && (
              <FormHelperText>
                <IntlMessages id={meta.error} values={translateValues} />
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  ) : (
    <FormControl
      className={classNames( 'kotler-select-form-control', containerClass, { 'mb-4': marginContainer } )}
      style={
        fullWidth
          ? {
            flex: 1,
            display: 'flex',
          }
          : {}
      }
    >
      {label && (
        <label>
          <IntlMessages id={label} />
          {adornRequired && <span className="text-danger">*</span>}
        </label>
      )}

      <Select
        {...rest}
        isDisabled={disabled}
        className="pb-0 kotler-select"
        classNamePrefix="kotler-select"
        placeholder={
          rest.placeholder ? <IntlMessages id={rest.placeholder} /> : ''
        }
        value={
          rest.value
            ? rest.isMulti
              ? rest.value.map( item => ( {
                value: item,
                label: _.get( options.find( o => o[idKey || 'id'] === item ), [
                  labelKey || 'name',
                ] ),
              } ) )
              : {
                value: rest.value,
                label: translateOptions
                  ? formatMessage( {
                    id: _.get(
                      options.find( o => o[idKey || 'id'] === rest.value ),
                      [labelKey || 'name'],
                    ),
                  } )
                  : _.get(
                    options.find( o => o[idKey || 'id'] === rest.value ),
                    [labelKey || 'name'],
                  ),
              }
            : rest.isMulti
              ? []
              : ''
        }
        onChange={( val ) => {
          if ( val !== rest.value ) {
            if ( onChange ) {
              if ( val ) {
                onChange(
                  rest.isMulti
                    ? _.map( val, 'value' )
                    : val.value
                      ? val.value
                      : null,
                );
              } else {
                onChange( null );
              }
            }
          }
        }}
        options={_.map( options, option => ( {
          value: _.get( option, idKey || 'id' ),
          label: translateOptions
            ? _.get( option, labelKey || 'name' )
              ? formatMessage( { id: _.get( option, labelKey || 'name' ) } )
              : _.get( option, labelKey || 'name' )
            : _.get( option, labelKey || 'name' ),
        } ) )}
      />
    </FormControl>
  );
};

export default SelectField;
