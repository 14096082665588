import React from 'react';

import ContainerHeader from 'components/theme/ContainerHeader';
import EditForm from '../../../components/Admin/NewProfessor/EditForm';

const NewProfessor = ( { match } ) => (
  <div className="app-wrapper">
    <ContainerHeader match={match} title="createProfessorBtn" />
    <EditForm />
  </div>
);

export default NewProfessor;
