import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LineGraphFull from 'components/StatisticalGraphs/containers/LineGraphFull';
import { useLifeTimeStore } from '../../hooks/lifeTimeValueStore';

export const CustomerLifetimeValueLineGraph = ( {
  gId = 1,
  graphHeight = '250px',
  filterByWorkgroupId,
  loading,
} ) => {
  const dispatch = useDispatch();
  const activeSessionId = useSelector(
    ( { user } ) => user && user.activeSession && user.activeSession.id,
  );
  const {
    error,
    loading: loadingData,
    lineGraphLifeTime,
    fetchData,
    clearData,
  } = useLifeTimeStore( {
    lineGraphLifeTime: true,
    filterByWorkgroupId,
  } );

  useEffect( () => {
    if ( !activeSessionId ) clearData();
    if ( activeSessionId ) fetchData( activeSessionId );
  }, [activeSessionId, dispatch, fetchData, clearData] );

  return (
    <LineGraphFull
      title="analytics.title.lifetimeValue"
      htmlId={`line_chart_life_time_value_${gId}`}
      height={graphHeight}
      loading={loading || loadingData}
      error={error}
      titleValue="activeCustomers"
      titleCategory="Rounds"
      data={lineGraphLifeTime.data}
      seriesData={lineGraphLifeTime.seriesData}
    />
  );
};

export default CustomerLifetimeValueLineGraph;
