import { useQuery } from 'react-query';
import { queryClient } from 'config/react-query';
import { getAllRoundServices } from '../services';

export const useAllRoundServicesQuery = ( sessionId, { disabled } = {} ) => {
  const query = useQuery(
    ['market', 'services-all-round', 'session', sessionId],
    () => getAllRoundServices( sessionId, { limit: -1 } ),
    {
      retry: 1,
      enabled: !!sessionId && !disabled,
      refetchOnReconnect: true,
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: true,
    },
  );

  return {
    query,
    loading: query.isLoading,
    allRoundServices: query.data || [],
    allRoundsServicesQuery: query,
    data: query.data || [],
  };
};

export const prefetchAllRoundServices = ( sessionId ) => {
  queryClient.prefetchQuery(
    ['market', 'services-all-round', 'session', sessionId],
    () => getAllRoundServices( sessionId, { limit: -1 } ),
    { staleTime: 1000 * 60 * 60 },
  );
};
