import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import CircularProgress from 'components/theme/CircularProgress';
import FeatherIcon from 'components/custom/FeatherIcon';
import IntlMessages from 'utils/IntlMessages';

/**
 * Componente PieGraph
 *
 * Este componente se utiliza para crear gráficos de torta (o pie charts) interactivos utilizando la biblioteca amCharts en una aplicación web de React.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.data - Un arreglo de objetos que contiene los datos que se mostrarán en el gráfico de torta.
 * @param {boolean} props.loading - Un booleano que indica si los datos se están cargando.
 * @param {boolean} props.error - Un booleano que indica si se ha producido un error al cargar los datos.
 * @param {string} props.categoryField - El nombre del campo en los datos que se utilizará para categorizar las partes de la torta.
 * @param {string} props.valueField - El nombre del campo en los datos que se utilizará para determinar el valor de cada parte de la torta.
 * @param {string} props.height - La altura del gráfico (por ejemplo, '300px').
 * @param {string} props.htmlId - El ID del elemento HTML en el que se renderizará el gráfico.
 * @param {boolean} props.interactive - Un booleano que indica si se habilitarán las herramientas interactivas del gráfico.
 * @returns {JSX.Element} - El componente PieGraph.
 */
function PieGraph( {
  data,
  loading = false,
  error = false,
  categoryField = 'group',
  valueField = 'value',
  height = '300px',
  htmlId = 'chartdiv',
  interactive = false,
} ) {
  const [noData, setNoData] = useState( false );

  useEffect( () => {
    if ( !data || !data.length ) return;

    const chart = am4core.create( htmlId, am4charts.PieChart );

    // Configurar serie de torta
    const pieSeries = chart.series.push( new am4charts.PieSeries() );
    pieSeries.dataFields.value = valueField;
    pieSeries.dataFields.category = categoryField;
    pieSeries.slices.template.filters.push( new am4core.DropShadowFilter() );
    // Habilitar el zoom y el cursor
    if ( interactive ) {
      enableTools( chart );
    }

    chart.data = data;

    return () => {
      if ( chart && chart.dispose ) chart.dispose();
    };
  }, [data, categoryField, valueField, htmlId, interactive] );

  useEffect( () => {
    const thereIsntData = !!( !loading && !error && ( !data || !data.length ) );
    if ( thereIsntData === noData ) return;
    if ( !thereIsntData ) setNoData( thereIsntData );
    else {
      const timeout = setTimeout( () => {
        setNoData( thereIsntData );
      }, 1000 );
      return () => clearTimeout( timeout );
    }
  }, [data, error, loading, noData] );

  const noGraphic = loading || error || noData;

  return (
    <div style={{ width: '100%', height, position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          opacity: noGraphic ? 0 : 1,
          transition: 'opacity 1s ease-in-out',
        }}
        id={htmlId}
      />

      <div
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          pointerEvents: noGraphic ? 'auto' : 'none',
          opacity: noGraphic ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <>
            <FeatherIcon icon="alertCircle" color="warning.main" size="50px" />
            <span className="text-warning mt-2">
              <IntlMessages id="graphError" />
            </span>
          </>
        ) : (
          <div />
        )}
        {noData && (
          <>
            <FeatherIcon icon="info" color="text.primary" size="50px" />
            <span className="text-dark mt-2">
              <IntlMessages id="graphNoData" />
            </span>
          </>
        )}
      </div>
    </div>
  );
}

/**
 * Habilita herramientas interactivas en el gráfico.
 *
 * @param {am4core.Chart} chart - El objeto de gráfico de amCharts.
 */
const enableTools = ( chart ) => {
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = 'zoomXY';
  chart.legend = new am4charts.Legend();
  const exportMenu = new am4core.ExportMenu();
  exportMenu.verticalAlign = 'bottom';
  chart.exporting.menu = exportMenu;
};

export default PieGraph;
