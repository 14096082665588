import api from '../config/Api';

const endpoint = 'v1/session';
const endpointV2 = 'v2/session';

export default {

  getAvailableReports( id, params ) {
    return api.get( `${endpoint}/${id}/market-report/available-report`, params );
  },

  getPurchasedReports( id, params ) {
    return api.get( `${endpoint}/${id}/market-report/purchased-report`, params );
  },

  getPurchasedReportsProfessor( id, params ) {
    return api.get( `${endpointV2}/${id}/market-report/purchased-report`, params );
  },

  sendPurchaseReports( id, data, isForce = false ) {
    let urlFragment = 'purchased-report';
    if ( isForce ) {
      urlFragment = 'purchased-report?force=1';
    } else {
      urlFragment = 'purchased-report';
    }
    return api.post( `${endpoint}/${id}/market-report/${urlFragment}`, data );
  },

  getProductLastRoundReports( id, params ) {
    return api.get( `${endpointV2}/${id}/market/products-last-round`, params );
  },
};
