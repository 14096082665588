import api from '../config/Api';

const endpoint = 'v2/session';

export default {

  getLogs( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/log-student`, params );
  },

  getDataFilters( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/log-student/data-filter`, params );
  },

  getActionsByGroupsRound( sessionId ) {
    return api.get( `${endpoint}/${sessionId}/log-student/groups-rounds` );
  },

  getActionsByGroups( sessionId ) {
    return api.get( `${endpoint}/${sessionId}/log-student/groups` );
  },

  getActionsByStudents( sessionId, workgroupId ) {
    return api.get( `${endpoint}/${sessionId}/log-student/${workgroupId}/students` );
  },

};
