import React, { useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SessionService from 'services/Session';
import UserActions from 'store/reducers/User';
import { showLoading, showSuccess } from 'utils/alerts';
import UserService from 'services/User';

const getSessionById = async ( sessionId ) => {
  const res = await SessionService.getSession( sessionId );
  if ( res.ok ) {
    return res.data;
  }
};

export const SessionCreatingGestion = () => {
  const sessionCreatingIds = useSelector(
    state => ( state.user && state.user.sessionsCreating ) || [],
  );
  const [sessionsShowed, setSessionsShowed] = useState( [] );
  // const activeSessionId = useSelector(
  //   ( { user } ) => user && user.activeSession && user.activeSession.id,
  // );
  const distpacth = useDispatch();
  // const addSessionCreating = ( sessionId ) => {
  //   distpacth( UserActions.addSessionCreating( sessionId ) );
  // };
  const removeSessionCreating = ( sessionId ) => {
    distpacth( UserActions.removeSessionCreating( sessionId ) );
  };
  const results = useQueries(
    sessionCreatingIds.map( sessionId => ( {
      queryKey: ['v1', 'session', sessionId],
      queryFn: () => getSessionById( sessionId ),
      refetchInterval: 1000 * 30,
    } ) ),
  );
  useEffect( () => {
    results.forEach( ( { data } ) => {
      if ( data && data.id ) {
        if ( data.creationStatus !== 'READY' ) {
          if ( !sessionsShowed.some( id => id === data.id ) ) {
            showLoading( 'session_creating_message', {
              translate: true,
              id: `session_creating_${data.id}`,
              messageValues: { session: data.name },
            } );
            setSessionsShowed( last => [...last, data.id] );
          }
        }
        if ( data.creationStatus === 'READY' ) {
          UserService.getCurrentUser().then( ( response ) => {
            if ( !response.errors ) {
              const { licenses } = response.data || {};
              try {
                if ( licenses ) distpacth( UserActions.updateLicenses( licenses ) );
              } catch ( err ) {
                // eslint-disable-next-line no-console
                console.log( err );
              }
            }
          } );
          removeSessionCreating( data.id );

          toast.dismiss( `session_creating_${data.id}` );
          showSuccess( 'sesion_creating_success_message', {
            translate: true,
            id: `session_creating_success_${data.id}`,
            messageValues: { session: data.name, code: data.registrationCode },
          } );
        }
      }
    } );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results] );
  return <></>;
  // return (
  //   <
  //     style={{
  //       zIndex: 100000,
  //       position: 'absolute',
  //       top: 0,
  //       left: 0,
  //       backgroundColor: 'rgba(0,0,0,0.5)',
  //     }}
  //   >
  //     SessionCreatingGestion
  //     <button onClick={() => addSessionCreating( activeSessionId )}>
  //       agregar session actual
  //     </button>
  //   </>
  // );
};
