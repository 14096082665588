import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import CardBox from 'components/custom_v2/CardBox';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import ResearchServices from '../services/ResearchServices';

const ResearchTableValues = ( { align = 'center', toggleAlert } ) => {
  const [preferences, setPreferences] = useState( {} );

  useEffect( () => {
    const fetchData = async () => {
      ResearchServices.getCXReportValues().then( ( response ) => {
        if ( !response.ok ) return toggleAlert( response.errors );
        setPreferences( response.data );
      } );
    };
    fetchData()
      // eslint-disable-next-line no-console
      .catch( console.error );
  }, [toggleAlert] );

  const {
    // cx,
    servicesProcess,
    salesProcess,
    complMgmt,
    siteUsability,
  } = preferences;
  return (
    <CardBox
      header={(
        <KotlerCardHeader
          title="Expected CX levels by segment"
          minHeight="0xp"
        />
      )}
      styleName="px-2"
    >
      <Table className="kotler-table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align={align}>
              <IntlMessages id="Executive" />
            </TableCell>
            <TableCell align={align}>
              <IntlMessages id="Professional" />
            </TableCell>
            <TableCell align={align}>
              <IntlMessages id="Employee" />
            </TableCell>
            <TableCell align={align}>
              <IntlMessages id="Student" />
            </TableCell>
            <TableCell align={align}>
              <IntlMessages id="Home" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <PreferenceTableRows
            title="cx"
            align={align}
            alignFirst="left"
            preference={cx}
          /> */}
          <PreferenceTableRows
            title="serviceProcess"
            align={align}
            alignFirst="left"
            preference={servicesProcess}
          />
          <PreferenceTableRows
            title="salesProcess"
            align={align}
            alignFirst="left"
            preference={salesProcess}
          />
          <PreferenceTableRows
            title="Complaint Management"
            align={align}
            alignFirst="left"
            preference={complMgmt}
          />
          <PreferenceTableRows
            title="Site Usability"
            align={align}
            alignFirst="left"
            preference={siteUsability}
          />
        </TableBody>
      </Table>
    </CardBox>
  );
};

const PreferenceTableRows = ( {
  title,
  align,
  alignFirst = 'left',
  preference = {},
} ) => {
  const { executive, professional, employee, student, home } = preference;

  return (
    <TableRow>
      <TableCell align={alignFirst}>
        <IntlMessages id={title} />
      </TableCell>
      <TableCell align={align}>{executive}</TableCell>
      <TableCell align={align}>{professional}</TableCell>
      <TableCell align={align}>{employee}</TableCell>
      <TableCell align={align}>{student}</TableCell>
      <TableCell align={align}>{home}</TableCell>
    </TableRow>
  );
};
const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};
export default connect(
  () => ( {} ),
  mapDispatchToProps,
)( ResearchTableValues );
