import { formatMessage, formatRoundName } from 'helpers/IntlHelpers';
import { flatMap, isObject, map } from 'lodash';

// Función para formatear los datos de un paquete (product)
const formatProductData = ( historical ) => {
  // Usamos flatMap para crear un array de valores de venta unitaria y total,
  // con '-' en lugar de 0 si el valor es 0
  const arr = historical.flatMap( itemD => [
    itemD.unitSales === 0 ? '-' : itemD.unitSales,
    itemD.revenues === 0 ? '-' : itemD.revenues,
  ] );
  // Devolvemos un array que contiene el nombre del paquete y los datos formateados
  return arr;
};

// Función para formatear las etiquetas de las rondas
const formatRoundsLabels = historical => flatMap( historical, ( { round } ) => [
  {
    // Formateamos el nombre corto de la ronda
    value: formatRoundName( round.shortName ),
    style: { alignment: { horizontal: 'right' }, font: { bold: true } },
  },
] );

// Función para formatear las etiquetas de las rondas con títulos
export const formatRoundLabelsTitle = ( roundlabels = [], title = '' ) => [
  {
    // Agregamos el título
    value: title,
    style: { font: { bold: true } },
  },
  ...flatMap( roundlabels, ( cell ) => {
    if ( isObject( cell ) ) {
      // Si es un objeto, agregamos '(u)' y '($)' a los valores
      return [
        { ...cell, value: `${cell.value} (u)` },
        { ...cell, value: `${cell.value} ($)` },
      ];
    }
    // Si es una cadena, agregamos '(u)' y '($)' a la cadena
    return [`${cell} (u)`, `${cell} ($)`];
  } ),
];
// Función para formatear los valores de las rondas
const formatRoundsValues = data => map( data, ( { historical, name } ) => {
  const dataRows = formatProductData( historical );

  // Devolvemos un array que contiene las etiquetas de la ronda, los datos formateados
  // y una fila vacía
  return [name, ...dataRows];
} );
// Función para formatear todos los paquetes de rondas en formato Excel
export const formatMarketProductsToExcel = ( data, title = 'market' ) => {
  const sheetData = {
    xSteps: 0,
    ySteps: 1,
    columns: [formatMessage( { id: title } )],
    data: [],
  };

  // Verificamos si hay datos, si no, devolvemos el array vacío
  if ( !data || !data.length ) return [sheetData];

  const roundsLabel = formatRoundsLabels( data[0].historical );
  const labelRow = formatRoundLabelsTitle(
    roundsLabel,
    formatMessage( { id: 'group' } ),
  );
  // Formateamos los valores de las rondas
  const dataToExcel = formatRoundsValues( data );
  // Agregamos los datos formateados al objeto de la hoja de Excel y lo devolvemos
  sheetData.data = [labelRow, ...dataToExcel];
  return [sheetData];
};
