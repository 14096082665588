import React from 'react';
import {
  Dialog,
  Slide,
  IconButton,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import * as am4core from '@amcharts/amcharts4/core';
import _ from 'lodash';
import { Close } from '@material-ui/icons';
import IntlMessages from 'utils/IntlMessages';
import withWidth from '@material-ui/core/withWidth';

class ChartModal extends React.Component {
  componentDidUpdate( prevProps ) {
    const { config, seriesTitle, open } = this.props;

    if ( !prevProps.open && open ) {
      if ( seriesTitle ) {
        setTimeout( () => {
          this.chartModal = am4core.createFromConfig( {
            ..._.cloneDeep( config ),
            series: _.map( config.series, ( serie, index ) => ( {
              ...serie,
              tooltipText: `${seriesTitle[index]} - {categoryX}: {valueY.value}`,
            } ) ),
            cursor: {},
            legend: { type: 'Legend' },
            scrollbarX: {
              type: 'XYChartScrollbar',
              background: {
                fill: am4core.color( '#04A89E' ),
                fillOpacity: 0.5,
              },
              minHeight: 10,
            },
          }, 'chartdivModal' );
        }, 100 );
      } else {
        setTimeout( () => {
          this.chartModal = am4core.createFromConfig( {
            ..._.cloneDeep( config ),
            series: _.map( config.series, serie => ( { ...serie } ) ),
            cursor: {},
            legend: { type: 'Legend' },
            scrollbarX: {
              type: 'XYChartScrollbar',
              background: {
                fill: am4core.color( '#04A89E' ),
                fillOpacity: 0.5,
              },
              minHeight: 10,
            },
          }, 'chartdivModal' );
        }, 100 );
      }
    } else if ( prevProps.open && !open ) {
      this.chartModal.dispose();
    }
  }

  render() {
    const { title, open, onClose, maxWidth = 'md', width, fullScreen } = this.props;
    let title2 = title;
    if ( !title ) title2 = 'nodefinido';
    return (
      <Dialog
        maxWidth={maxWidth}
        fullScreen={width === 'xs' || width === 'sm' || fullScreen}
        fullWidth
        open={open}
        onClose={onClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
      >
        <div className="px-2 py-3">
          <DialogTitle className="p-0 ml-1">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5" style={{ fontWeight: 600, fontSize: 21 }} color="textPrimary">
                {title2 && <IntlMessages id={title2} />}
              </Typography>
              {onClose ? (
                <IconButton
                  aria-label="close"
                  className="square-icon-button"
                  onClick={onClose}
                  style={!title2 ? { position: 'absolute', top: 20, right: 20 } : {}}
                >
                  <Close className="text-dark" />
                </IconButton>
              ) : null}
            </div>
          </DialogTitle>


          <div id="chartdivModal" style={{ width: '100%', height: width === 'xs' || width === 'sm' ? '90vh' : '70vh' }} />

        </div>
      </Dialog>
    );
  }
}

export default withWidth()( ChartModal );
