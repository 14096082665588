import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formatDataToPieGraphPerGroup } from 'helpers/GraphHelpers';
import { withWidth } from '@material-ui/core';
import PieGraphFull from 'components/StatisticalGraphs/containers/PieGraphFull';

const PieGraphNumberOfCustomers = ( {
  gId = 1,
  totalCustomerData,
  loading,
  error,
  locale,
} ) => {
  const [dataToGraph, setDataToGraph] = useState( {} );
  const localeRef = useRef( null );
  useEffect( () => {
    const key = 'totalCustomerLastRound';
    const formatData = formatDataToPieGraphPerGroup( totalCustomerData, key );
    if (
      JSON.stringify( dataToGraph ) !== JSON.stringify( formatData )
      || locale.locale !== localeRef.current
    ) {
      setDataToGraph( formatData );
    }
    localeRef.current = locale.locale;
  }, [locale.locale, totalCustomerData, dataToGraph] );

  return (
    <PieGraphFull
      title="market.total_number_of_customer"
      htmlId={`pie_chart_number_of_customer_${gId}`}
      categoryField="group"
      valueField="value"
      height="315px"
      loading={loading}
      error={error}
      data={dataToGraph.data}
    />
  );
};

const mapStateToProps = ( { customers, settings } ) => ( {
  totalCustomerData: customers.totalCustomers.data,
  loading: customers.totalCustomers.loading,
  error: customers.totalCustomers.error,
  locale: settings.locale,
  // activeSessionId: user && user.activeSession && user.activeSession.id,
} );

export default connect( mapStateToProps )(
  injectIntl( withWidth()( PieGraphNumberOfCustomers ) ),
);
