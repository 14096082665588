import React, { useState, useEffect } from 'react';


const KotlerHour = () =>  {
    const [currentDate, setCurrentDate ]= useState(new Date());
    useEffect(() => {
        const timeId = setTimeout(() => {
            updateHour();
        }, 10000);

        return () => { window.clearTimeout(timeId); };

    }, [currentDate]);
    const updateHour = () =>
    {   
        setCurrentDate( new Date() ); 
    }
    return (<>{currentDate.getHours()}:{currentDate.getMinutes().toString().padStart(2,'0')}</>)
}

export default KotlerHour