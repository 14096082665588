import api from '../config/Api';

const endpoint = 'v2/session';

export default {

  getInitialStrategicPlan( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/initialstrategicplan`, params );
  },

  saveInitialStrategicPlan( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/initialstrategicplan`, data );
  },

  updateInitialStrategicPlan( sessionId, initialStrategicPlanId, frmData ) {
    const data = frmData;
    delete data.id;
    delete data.createdAt;
    return api.put( `${endpoint}/${sessionId}/initialstrategicplan/${initialStrategicPlanId}`, data );
  },
};
