import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Error404 from 'components/theme/Error404';
import SettingsActions from 'store/reducers/Settings';
import SessionService from 'services/Session';
import UserActions from 'store/reducers/User';
import LiveMaxVersion from 'components/layouts/LiveMaxVersion';
import { GoToAiBtn } from 'modules/ManualAi';
import { useActiveSessionQuery } from 'modules/sessions';
import Dashboard from './Dashboard';
import Information from './Information';
import General from './General';
import Message from './Message';

const Session = ( {
  match,
  toggleLoading,
  toggleAlert,
  changeActiveSession,
  user,
} ) => {
  const [limitedAccess, setLimitedAccess] = useState( true );
  const { isLoading } = useActiveSessionQuery();

  useEffect( () => {
    toggleLoading( true );

    SessionService.getSession( user.activeSession.id ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      changeActiveSession( response.data );

      if ( !user.activeSession ) {
        setLimitedAccess( true );
        return toggleAlert( 'sessionNotConfigured', 'danger', true );
      }

      if ( !user.activeSession.currentRound ) {
        setLimitedAccess( true );
        return toggleAlert( 'sessionNoRounds', 'danger', true );
      }

      const license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
      if ( !license || !license.workGroup ) {
        setLimitedAccess( true );
        return toggleAlert( 'sessionNotConfigured', 'danger', true );
      }

      if ( response.data.status === 'READY_NO_ROUND' ) {
        setLimitedAccess( true );
        return toggleAlert( 'sessionNoRounds', 'danger', true );
      }

      if ( !response.data.status ) {
        setLimitedAccess( true );
        return toggleAlert( 'sessionNotConfigured', 'danger', true );
      }

      setLimitedAccess( false );
    } );
  }, [user.activeSession.id]); // eslint-disable-line

  if ( isLoading ) return <div />;
  return (
    <div className="h-100 d-flex flex-column">
      <div className="d-flex flex-column flex-grow-1  position-relative pt-2">
        <Switch>
          <Route exact path={`${match.url}/dashboard`} component={Dashboard} />
          <Route path={`${match.url}/info`} component={Information} />
          {!limitedAccess && (
            <Route
              path={`${match.url}/general`}
              render={props => <General {...props} />}
            />
          )}
          <Route
            exact
            path={`${match.url}/messages`}
            render={props => <Message {...props} />}
          />
          <Route
            exact
            path={`${match.url}/:rest`}
            render={Error404}
          />
          <Route
            exact
            path={`${match.url}/`}
          >
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
      <GoToAiBtn />
      <LiveMaxVersion textDark />
    </div>
  );
};

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  changeActiveSession: UserActions.changeActiveSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( withRouter( Session ) );
