import { Card, CardHeader } from '@material-ui/core';
// import Avatar from "@material-ui/core/es/Avatar/Avatar";
import { InfoOutlined } from '@material-ui/icons';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import IntlMessages from 'utils/IntlMessages';
import './styles.css';
import { useSelector } from 'react-redux';

export default ( props ) => {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,

    centerPadding: '0',
    ...props,
  };
  const slideRef = useRef( null );

  return (
    <Slider
      className="principal-carrousel"
      ref={slideRef}
      {...settings}
      arrows={false}
    >
      {[...props.data].map( ( item, index ) => (
        <ItemCarrousel
          item={item}
          key={index}
          onNext={slideRef.current ? slideRef.current.slickNext : () => {}}
        />
      ) )}
    </Slider>
  );
};

const ItemCarrousel = ( { item, onNext } ) => {
  const isNow = useSelector(
    ( { user } ) => user
      && user.activeSession
      && user.activeSession.distributionType === 'now',
  );
  return (
    <div className="px-1  " style={{ overflowY: 'auto' }}>
      <Card
        elevation={3}
        style={{
          alignItems: 'baseline',
          background: 'rgba(255, 255, 255, 0.87)',
          display: 'flex',
          ...( isNow
            ? {
              borderRadius: '11px',
              background: 'rgba(16, 69, 64, 0.50)',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
              backdropFilter: 'blur(1.75px)',
            }
            : {} ),
        }}
        onClick={() => onNext()}
        className={` card-phone-notification  container-fluid p-2 ${
          isNow ? '' : 'border'
        }`}
      >
        <CardHeader
          className={isNow ? 'p-0 px-xl-3 py-xl-2  ' : 'p-0'}
          style={{ margin: 0 }}
          subheaderTypographyProps={{
            color: isNow ? 'textSecondary' : 'textPrimary',
            padding: 0,
            margin: 0,

            style: isNow ? { color: 'white', letterSpacing: '0.2px' } : {},
          }}
          /* avatar={
          <Avatar className="bg-primary">
            <Accessibility />
          </Avatar>
        } */
          /*       action={
          <IconButton aria-label="settings">
            hola
          </IconButton>
        } */
          title={(
            <span
              style={{
                cursor: 'pointer',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 600,
                textAlign: 'left',
                fontSize: 12,
                marginTop: 4,
                marginBottom: '6px',
                lineHeight: '18px',
                display: 'flex',
                wordBreak: 'break-word',
                ...( isNow
                  ? { color: 'white', fontWeight: 500, marginBottom: '10px' }
                  : {} ),
              }}
            >
              <div />
              <div
                style={{
                  marginTop: 3,
                  marginLeft: '3px',
                }}
              >
                <InfoOutlined />
                {' '}
                {item.translate ? <IntlMessages id={item.title} /> : item.title}
                {' '}
              </div>
            </span>
          )}
          subheader={(
            <p
              style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 400,
                margin: 0,

                fontSize: 10,
                textAlign: 'justify',
                ...( isNow
                  ? {
                    color: 'white',
               
                    fontWeight: 300,
                  }
                  : { lineHeight: '15px' } ),
              }}
            >
              {item.translate ? (
                <IntlMessages id={item.content} />
              ) : (
                item.content
              )}
            </p>
          )}
        />
      </Card>
    </div>
  );
};
