import React from "react";
import FeatherIcon from "components/custom/FeatherIcon";

export default ({ category, avatar }) => {
  if (avatar)
    return (
      <div className="kotler-table-title-icon">
        <FeatherIcon icon={avatar} />
      </div>
    );

  let icon = "monitor";
  switch (category.label) {
    case "CATEGORY_2":
      icon = "tablet";
      break;
    case "CATEGORY_3":
      icon = "smartphone";
      break;
      default: break;
  }

  return (
    <div className="kotler-table-title-icon">
      <FeatherIcon icon={icon} />
    </div>
  );
};
