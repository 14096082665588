/**
 * Functions to interact with live ads via HTTP requests.
 * @module LiveAdService
 */

import { getHttpRequest, putHttpRequest } from 'utils/ApiPetitionhandles';

/**
 * Fetches a live ad for a given session.
 * @async
 * @param {string} sessionId - The ID of the session.
 * @param {Object} [params] - Additional parameters for the request.
 * @returns {Promise<Object>} - A promise that resolves with the fetched live ad.
 */
export async function getLiveAd( sessionId, params ) {
  const url = `v2/session/${sessionId}/session-ads`;
  return getHttpRequest( url, params );
}

/**
 * Updates a live ad for a given session.
 * @async
 * @param {string} sessionId - The ID of the session.
 * @param {string} sessionAdId - The ID of the session ad to update.
 * @param {Object} [data] - The data to update the session ad with.
 * @param {string} [data.name] - The name of the session ad.
 * @param {string} [data.body] - The body/content of the session ad.
 * @param {boolean} [data.active] - Whether the session ad is active or not.
 * @param {Object} [params] - Additional parameters for the request.
 * @returns {Promise<Object>} - A promise that resolves with the updated live ad.
 */
export async function updateLiveAd( sessionId, sessionAdId, data, params ) {
  const url = `v2/session/${sessionId}/session-ads/${sessionAdId}`;
  return putHttpRequest( url, data, params );
}

export default { getLiveAd, updateLiveAd };
