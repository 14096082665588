import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Grid, Hidden, IconButton } from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import logo from 'assets/images/logo-livemax-kotler.png';
import BackgroundImage from 'assets/images/kotler/login-background.jpeg';
import IntlHtmlMessages from 'utils/IntlHtmlMessages';

import FeatherIcon from 'components/custom/FeatherIcon';
import HoverTitle from 'components/custom_v2/HoverTitle';
import { LanguageButton } from 'components/custom/LanguageButton';

const PrivacyPolicies = () => (
  <div className="app-login-container d-flex flex-column position-relative   ">
    <div
      className="bg-image app-login-container"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        filter: 'blur(4px)',
      }}
    />
    <Hidden smDown>
      <div
        style={{
          background:
            'linear-gradient(270.87deg, #FFFFFF 33.81%, rgba(255, 255, 255, 0) 96.88%)',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: '50%',
          filter: 'blur(20px)',
        }}
      />
    </Hidden>

    <div
      style={{
        background: 'rgba(255,255,255,.9)',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    />

    <HoverTitle id="login" placement="left">
      <IconButton
        component={Link}
        to="/login"
        className="icon-btn  square-icon-buttom"
        style={{ position: 'absolute', zIndex: 100, top: 20, right: 75 }}
      >
        <FeatherIcon icon="user" size="18px" color="primary.main" />
      </IconButton>
    </HoverTitle>
    <LanguageButton />
    <Container
      maxWidth="xl"
      style={{ zIndex: 2 }}
      className="d-flex flex-grow-1 overflow-auto "
    >
      <Grid
        container
        className="d-flex  justify-content-center justify-content-lg-end w-100 "
      >
        <Grid
          item
          xs={12}
          lg={6}
          xl={5}
          className="d-flex  mt-5 justify-content-center "
        >
          <Grid container justify="center" alignItems="center">
            <Grid item xs={11} lg={9} xl={10}>
              <div className="d-flex flex-column" style={{ rowGap: '30px' }}>
                <Link to="/" title="LiveMax" replace>
                  <img
                    alt="LiveMaX-Kotler-logo"
                    src={logo}
                    className="logo-login"
                  />
                </Link>
                <Row>
                  <Col>
                    <IntlHtmlMessages id="privacy.content" />
                  </Col>
                </Row>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </div>
);

export default PrivacyPolicies;
