import React from 'react';

import KotlerTabs from 'components/custom/KotlerTabs';
import CompBalance from './CompBalance';


export default function TabCompBalance( { data } ) {
  return (
    <KotlerTabs
      disableUnderLine
      tabClassName="kotler-tab-pills mt-3"
      noTranslateTitles
      titles={data.map( WorkGroup => ( WorkGroup.balance
        ? { title: WorkGroup.workgroup.name }
        : null ) )}
      pages={data.map( WorkGroup => ( WorkGroup.balance ? (
        <CompBalance {...WorkGroup.balance} />
      ) : null ) )}
    />
  );
}
