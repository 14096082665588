import React from 'react'
import *  as FIcon from 'react-feather';
import _ from 'lodash'
import { useTheme } from '@material-ui/core/styles';

const FeatherIcon =({icon,style={},color = "secondary.light",size=12, ...rest})=>{
    const {palette} = useTheme();
const colorFind = _.get(palette,color,color)
if(!icon) return null;
const Icon = FIcon[_.upperFirst(icon)];
    return Icon? <Icon style={{transition:"transform ease-in-out .2s",...style}}   {...rest} color={colorFind} size={size}/>:null;
}

export default FeatherIcon;