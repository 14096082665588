import api from 'config/Api';

// const endpoint = 'v1';
const endpoint2 = 'v2';

export default {
  getCXReportValues() {
    return api.get( `${endpoint2}/session/report/cx-research` );
  },
};
