// rootSaga.js

import { all } from 'redux-saga/effects';
import marketProductsRootSaga from './MarketProductsSaga';
import infoProductsRootSaga from './InfoProductsSaga';
import lastroundProductsRootSaga from './LastRoundProductsSaga';
import priceProductsRootSaga from './PriceProductsSaga';

function* productsRootSaga() {
  yield all( [
    // Otras sagas de la aplicación
    marketProductsRootSaga(),
    infoProductsRootSaga(),
    lastroundProductsRootSaga(),
    priceProductsRootSaga(),
  ] );
}

export default productsRootSaga;
