import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import IntlMessages from 'utils/IntlMessages';

function TimePickerField( { adornRequired,label,field, suffix, marginContainer = true, onChange, validate, translateValues, disabled=false } ) {
  return (
    <Field name={field} validate={validate}>
      {( { meta, input } ) => {
        return (
          <FormControl error={meta.touched && !!meta.error}
                       className={classNames( 'w-100 kotler-text-field-form-control', { 'mb-4': marginContainer } )}>
                         {label ? (
            <label /* className={labelClass} */>
              <IntlMessages id={label} />{adornRequired && <span className="text-danger">*</span>}
            </label>
          ) : (
            ''
          )}
            <TimePicker
              showSecond={false}
              className={classNames( {
                'Mui-error': meta.touched && !!meta.error
              },"kotler-time-picker" )}
              onChange={onChange ? onChange : input.onChange }
              value={input.value || undefined}
              format="HH:mm"
              
              inputReadOnly
              disabled={disabled}
              clearIcon={
                <a role="button" className="rc-time-picker-clear text-muted" title="clear"> { /*eslint-disable-line*/ }
                  <i className="fa fa-times"/>
                </a>
              }
            />
            {meta.touched && meta.error
            && (
              <FormHelperText>
                <IntlMessages id={meta.error} values={translateValues}/>
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
}

export default TimePickerField;
