import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { map } from 'lodash';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import CardBox from 'components/custom_v2/CardBox';
import { z } from 'zod';
import { useActiveSessionId, useMyCurrentWorkgroupId } from 'modules/sessions';
import { LastRoundServiceSchema } from '../schemas/last-round-schemas';
import { useLastRoundServicesQuery } from '../hooks';

export const DataFormated = z
  .array( LastRoundServiceSchema )
  .optional()
  .nullable()
  .transform( ( data ) => {
    if ( !data ) return {};

    const dataFormated = [];
    const groups = [];
    // eslint-disable-next-line
    data.map(({ workgroupData, name, id: key }, index) => {
      const prices = workgroupData.map( ( { name: group, services } ) => {
        if ( index === 0 ) groups.push( group );

        return services.services.reduce(
          ( prev, { price, active } ) => ( active ? Util.formatCurrency( price ) : prev ),
          '-',
        );
      } );

      return dataFormated.push( {
        key,
        name,
        prices,
      } );
    } );

    return {
      groups,
      data: dataFormated,
    };
  } );

const TablesLastRoundService = () => {
  const [dataToTable, setDataToTable] = useState( [] );
  const sessionId = useActiveSessionId();
  const myWorkgroupId = useMyCurrentWorkgroupId();
  const { lastRoundServices } = useLastRoundServicesQuery(
    sessionId,
    myWorkgroupId,
  );
  const [groups, setGroups] = useState( [] );
  useEffect( () => {
    const formatData = DataFormated.parse( lastRoundServices );
    setDataToTable( formatData.data );
    setGroups( formatData.groups );
  }, [lastRoundServices] );

  return (
    <CardBox
      styleName="px-2 h-100 mt-3"
      header={
        <KotlerCardHeader minHeight="0px" title="market.services_information" />
      }
    >
      <Table className="kotler-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>
                <IntlMessages id="service" />
              </strong>
            </TableCell>
            {dataToTable
              && map( groups, name => (
                <TableCell key={name} align="center">
                  <strong>{name}</strong>
                </TableCell>
              ) )}
          </TableRow>
        </TableHead>
        <TableBody>
          {map( dataToTable, ( { key, name, prices } ) => (
            <TableRow key={key}>
              <TableCell>{name}</TableCell>
              {prices
                && prices.map( ( price, index ) => (
                  <TableCell align="center" key={`price${index + price}`}>
                    {price}
                  </TableCell>
                ) )}
            </TableRow>
          ) )}
          {( !dataToTable || !dataToTable.length ) && (
            <TableRow>
              <TableCell colSpan={1}>
                <IntlMessages id="NoDataFound" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CardBox>
  );
};

export default TablesLastRoundService;
