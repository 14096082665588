import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDataToLineGraph } from 'helpers/GraphHelpers';
import { fetchCustomerLifetimeValueRequest } from '../redux/actions';

/**
 * Custom hook to manage lifetime store data.
 * @param {Object} options - Options object.
 * @param {boolean} options.lineGraphAdquisition - indicator to generate line graph acquisition.
 * @param {boolean} options.lineGraphLifeTime - indicator to generate line graph LTV.
 * @param {string | null} options.filterByWorkgroupId - indicator to filter all data by workgroup.
 * @returns {{
 *   loading: boolean,
 *   error: string | null,
 *   fetchData: (sessionId:string)=>void,
 *   clearData: ()=>void,
 *  lineGraphAdquisition:  ReturnType<typeof formatDataToLineGraph> | undefined,
 *  lineGraphLifeTime:  ReturnType<typeof formatDataToLineGraph> | undefined,
 *   data: Array<{
 *     id: string,
 *     name: string,
 *     historical: Array<{
 *       round: {
 *         id: string,
 *         name: string,
 *         shortName: string,
 *         largeName: string
 *       },
 *       lifetimeValue: number,
 *       acquisitionCost: number
 *     }>
 *   }>
 * }} Object containing loading state, error state, line graph acquisition data, and raw data.
 */
export const useLifeTimeStore = ( {
  lineGraphAdquisition = false,
  lineGraphLifeTime = false,
  filterByWorkgroupId = null,
} = {} ) => {
  const dispatch = useDispatch();
  const { locale } = useSelector( ( { settings } ) => settings.locale );
  // Retrieve data from Redux store
  const { loading, error, data } = useSelector(
    ( { customers: { customerLifetimeValue } } ) => {
      if ( !filterByWorkgroupId || !customerLifetimeValue.data ) return customerLifetimeValue;
      const dataFiltered = customerLifetimeValue.data.filter(
        ( { id } ) => id === filterByWorkgroupId,
      );

      return {
        ...customerLifetimeValue,
        data: dataFiltered,
      };
    },
  );

  // Generate line graph acquisition data if requested
  const lineGraphAcquisitionData = useMemo( () => {
    if ( !lineGraphAdquisition ) return undefined;
    return formatDataToLineGraph( data, 'acquisitionCost' );
  }, [data, lineGraphAdquisition, locale]); // eslint-disable-line
  // Generate line graph LTV  data if requested
  const lineGraphLifeTimeData = useMemo( () => {
    if ( !lineGraphLifeTime ) return undefined;
    return formatDataToLineGraph( data, 'lifetimeValue' );
  }, [data, lineGraphLifeTime, locale]); // eslint-disable-line

  const fetchData = useCallback(
    ( sessionId ) => {
      dispatch( fetchCustomerLifetimeValueRequest( sessionId ) );
    },
    [dispatch],
  );
  const clearData = useCallback( () => {
    dispatch( { type: 'CLEAR_CUSTOMER_LIFETIME_VALUE' } );
  }, [dispatch] );

  return {
    loading,
    error,
    fetchData,
    clearData,
    lineGraphAdquisition: lineGraphAcquisitionData,
    lineGraphLifeTime: lineGraphLifeTimeData,
    data,
  };
};
export default { useLifeTimeStore };
