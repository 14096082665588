import {
  FETCH_ALL_ROUNDS_BUNDLES_REQUEST,
  FETCH_ALL_ROUNDS_BUNDLES_SUCCESS,
  FETCH_ALL_ROUNDS_BUNDLES_FAILURE,
  CLEAR_ALL_ROUNDS_BUNDLES,
} from '../actions/AllRoundsBundlesActions';

const initialState = { data: null, loading: false, error: null };

const allRoundsBundlesReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_ALL_ROUNDS_BUNDLES_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case FETCH_ALL_ROUNDS_BUNDLES_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: null,
    };
  case FETCH_ALL_ROUNDS_BUNDLES_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case CLEAR_ALL_ROUNDS_BUNDLES:
    return {
      ...state,
      ...initialState,
    };
  default:
    return state;
  }
};

export default allRoundsBundlesReducer;
