import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import LineGraphFull from 'components/StatisticalGraphs/containers/LineGraphFull';
import { formatRoundName } from 'helpers/IntlHelpers';
import { findIndex, map } from 'lodash';

const formatPriceProductsToLineGraph = ( data ) => {
  if ( !data || !data.length ) return {};

  const formatedRounds = [];
  const seriesData = [];
  map( data, ( workgroup ) => {
    map( workgroup.historical, ( round ) => {
      const existRoundIndex = findIndex( formatedRounds, { id: round.round.id } );
      let currentRound = existRoundIndex !== -1 ? formatedRounds[existRoundIndex] : null;

      if ( !currentRound ) {
        currentRound = {
          attribute: formatRoundName( round.round.shortName ),
          id: round.round.id,
        };
        formatedRounds.push( currentRound );
      }
      map( round.products, ( product ) => {
        currentRound[product.name] = product.price;
        const existProductIndex = findIndex( seriesData, { name: product.name } );
        if ( existProductIndex === -1 ) {
          seriesData.push( { valueField: product.name, name: product.name } );
        }
      } );
    } );
  } );
  return { data: formatedRounds, seriesData };
};

const LineGraphPriceProduct = ( {
  gId = 1,
  graphHeight = '315px',
  title = 'analytics.LaptopPriceByProduct',
  hoverTitle,
  titleValue = 'PriceS',
  titleCategory = 'Rounds',
  dataKey = 'unitSales',
  priceProductData,
  loading,
  error,
  locale,
} ) => {
  const [dataToGraph, setDataToGraph] = useState( {} );

  useEffect( () => {
    const formatData = formatPriceProductsToLineGraph(
      priceProductData,
      dataKey,
    );
    if ( JSON.stringify( dataToGraph ) !== JSON.stringify( formatData ) ) {
      setDataToGraph( formatData );
    }
  }, [locale.locale, priceProductData, dataKey, dataToGraph] );

  return (
    <LineGraphFull
      {...{
        title,
        loading,
        error,
        hoverTitle,
        titleCategory,
        titleValue,
      }}
      height={graphHeight}
      htmlId={`line_chart_market_product_${gId}`}
      data={dataToGraph.data}
      seriesData={dataToGraph.seriesData}
    />
  );
};

const mapStateToProps = ( { products, settings }, { categoryId } ) => {
  if ( !categoryId ) {
    return {
      priceProductData: undefined,
      loading: true,
      error: null,
      locale: settings.locale,
    };
  }
  const product = products.priceProducts.byCategoryId[categoryId];
  if ( !product ) {
    return {
      priceProductData: undefined,
      loading: false,
      error: null,
      locale: settings.locale,
    };
  }
  return {
    priceProductData: product.data && product.data.data,
    loading: product.loading,
    error: product.error,
    locale: settings.locale,
    // activeSessionId: user && user.activeSession && user.activeSession.id,
  };
};

export default connect( mapStateToProps )( injectIntl( LineGraphPriceProduct ) );
