import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import _ from 'lodash';
import React from 'react';
import { RadioButtons } from 'components/custom/FormElements';
import { FieldArray } from 'react-final-form-arrays';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import IconTV from 'assets/images/kotler/icons/icon-tv.png';
import IconRADIO from 'assets/images/kotler/icons/icon-radio.png';
import IconOUTDOOR from 'assets/images/kotler/icons/icon-outdoor.png';
import IconPERIODIC from 'assets/images/kotler/icons/icon-periodic.png';

const generateTitle = ( filterType ) => {
  if ( !filterType || !_.isString( filterType ) ) return null;
  let formatText = '';
  let Icon = null;
  switch ( filterType ) {
  case 'TV':
    formatText = 'TV';
    Icon = IconTV;
    break;
  case 'RADIO':
    formatText = 'RADIO';
    Icon = IconRADIO;
    break;
  case 'INTERNET':
    formatText = 'PRINT';
    Icon = IconPERIODIC;
    break;
  case 'OUTDOOR':
    formatText = 'OUTDOOR';
    Icon = IconOUTDOOR;
    break;
  default:
    break;
  }
  return (
    <>
      <img className="pr-2" src={Icon} alt={formatText} />
      <IntlMessages id={formatText} />
    </>
  );
};

export default ( {
  name,
  mediaCostLabel,
  getMediaCost,
  values,
  filterType,
  form,
  withBigHead,
} ) => (
  <CardBox
    styleName=" h-100 pl-2 ml-2 pb-0 mb-0"
    header={(
      <KotlerCardHeader
        minHeight="0px"
        marginBottom="0px"
        onDelete={() => {
          _.map( values.mediaAttributes, ( item, index ) => {
            if ( item.mediaType === filterType ) {
              form.change( `mediaAttributes[${index}].selected`, null );
              form.change( `mediaAttributes[${index}].intensity`, null );
              // eslint-disable-next-line  no-param-reassign
              mediaCostLabel[index] = 0;
            }
          } );
        }
        }
      />
    )}
  >
    <div className="overflow-auto">
      <Table className="kotler-table">
        <TableHead>
          {withBigHead && (
            <TableRow style={{ height: '18px' }}>
              <TableCell colSpan={4} className="border-0" />

            </TableRow>
          )}
          <TableRow>
            <TableCell style={{ minWidth: '25%' }}>
              {generateTitle( filterType )}
              {/*   <IntlMessages id="estimatedMonthlyCost" /> */}
            </TableCell>
            <TableCell style={{ minWidth: '240px' }} align="center">
              <IntlMessages id="intensity" />
              {/*  <div className="mt-1">
              <span className="mr-5"><IntlMessages id="low" /></span>
              <span style={{ align: 'right', paddingLeft: '4rem' }}>
              <IntlMessages id="high" /><
              /span>
            </div> */}
            </TableCell>
            <TableCell style={{ width: '20%' }}>
              <IntlMessages id="estimatedMonthlyCost" />
            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name={name}>
            {
              ( { fields } ) => fields.map( ( mediaField, index ) => {
                const media = fields.value[index];
                const defaultValue = values.mediaAttributes[index].intensity;
                // eslint-disable-next-line no-param-reassign
                mediaCostLabel[index] = getMediaCost( defaultValue, media );
                if ( !media || ( filterType && filterType !== media.mediaType ) ) return null;

                return (
                  <TableRow key={media.id}>
                    <TableCell className=" py-0">
                      <IntlMessages id={media.mediaChannel.split( ' ' )[0]} />
                      {' '}
                      {media.mediaChannel.split( ' ' )[1]}
                    </TableCell>
                    <TableCell align="center">
                      <RadioButtons
                        field={`${mediaField}.intensity`}
                        options={[
                          { id: 1 /*  className: 'kotler-radio-slow'  */ },
                          { id: 2 /* className: 'kotler-radio-slow' */ },
                          { id: 3 /* className: 'kotler-radio-medium' */ },
                          { id: 4 /* className: 'kotler-radio-fast' */ },
                          { id: 5 /* className: 'kotler-radio-fast' */ },
                        ]}
                        radioClassName="justify-content-center d-flex "
                        onChange={( value ) => {
                          // eslint-disable-next-line max-len
                          // eslint-disable-next-line no-param-reassign
                          mediaCostLabel[index] = getMediaCost( value, media );
                        }}
                      />
                    </TableCell>
                    <TableCell className="td-crm">
                      {
                        // eslint-disable-next-line max-len
                        Util.formatCurrency( mediaCostLabel[index] ? mediaCostLabel[index] : 0 )
                      }
                    </TableCell>
                  </TableRow>
                );
              } )}

          </FieldArray>
        </TableBody>
      </Table>
    </div>
  </CardBox>
);
