import { useEffect } from 'react'
import { connect } from 'react-redux'
import SettingsActions from 'store/reducers/Settings'

const HeadConfig = ({ breadcrumbsLinks, currentBreadCrumbsLinks,setBreadcrumbsLinks }) => {
  useEffect(() => {
    if (
      breadcrumbsLinks &&
      JSON.stringify(currentBreadCrumbsLinks) !==
        JSON.stringify(breadcrumbsLinks)
    )
      setBreadcrumbsLinks(breadcrumbsLinks)
      
  }, [breadcrumbsLinks,currentBreadCrumbsLinks,setBreadcrumbsLinks])
  return null
}

const mapStateToProps = ({ settings }) => ({
  currentBreadCrumbsLinks: settings.breadcrumbsLinks,
})
export default connect(mapStateToProps, {
  setBreadcrumbsLinks: SettingsActions.setBreadcrumbsLinks,
})(HeadConfig)
