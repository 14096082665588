import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import SettingsActions from "store/reducers/Settings";
import Dropzone from "react-dropzone";
import IntlMessages from "utils/IntlMessages";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button } from "@material-ui/core";

const acceptedFileTypes = ["image/jpg", "image/jpeg", "image/png"];

const KotlerImageCrooper = ({
  value,
  toggleLoading,

  toggleAlert,
  onChange = (image64) => {},
}) => {
  const [src, setSrc] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null);
  const canvasRef = useRef();
  const crooper = useRef();
  /* const cropImage = useState() */

  const handleOnDrop = (file, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      toggleAlert(<IntlMessages id="dragNdrop-error" />);
    }

    if (file && file.length > 0) {
      const newFile = file[0];
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
        },
        false
      );
      reader.readAsDataURL(newFile);
    }
  };

  const cropImage = () => {
    if (typeof crooper.current.getCroppedCanvas() === "undefined") {
      return;
    }

    let croppedCanvas = crooper.current.getCroppedCanvas();
    let roundedCanvas = getRoundedCanvas(croppedCanvas);
    setCroppedImg(roundedCanvas.toDataURL());
  };

  const getRoundedCanvas = (sourceCanvas) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = "destination-in";
    context.beginPath();
    /*  context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    ); */
    context.fill();
    return canvas;
  };

  return (
    <div className="row h-100 w-100 ">
      {!src ? (
        <div className="col-md-12">
          <Dropzone
            className="p-5 overflow-visible d-flex align-items-center text-center"
            style={{ minHeight: 300 }}
            accept={acceptedFileTypes}
            multiple={false}
            onDrop={handleOnDrop}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="h-100 w-100">
                <div
                  {...getRootProps()}
                  className="profile-dropzone-file-btn h-100 w-100"
                >
                  <div className="row h-100 d-flex justify-content-center">
                    <div className="my-auto">
                      <input {...getInputProps()} />
                      <h3>
                        <IntlMessages id="dragNdrop-label" />
                      </h3>
                      <p>
                        <i>
                          <IntlMessages id="dragNdrop-labelText" />
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      ) : (
        <React.Fragment>
         <div className="col-lg-6 col-md-9">
            <Cropper
              aspectRatio={1 / 1}
               
              src={src}
              ref={crooper}
              viewMode={1}
            />
            <div className="row d-flex justify-content-center">
            <Button
          className="jr-btn jr-btn-lg mt-2"
          variant="contained"
          color="secondary"
          onClick={()=>{setSrc(null)}}
        >
          <IntlMessages id="changePhoto" />
        </Button>
              <Button
                id="crop"
                variant="contained"
                color="primary"
                className="jr-btn jr-btn-lg mt-2"
                onClick={cropImage}
                type="button"
              >
                <i className="fa fa-crop" />
                <IntlMessages id="profile-crop" />
              </Button>

              <Button
                id="crop"
                variant="contained"
                color="primary"
                className="jr-btn jr-btn-lg mt-2"
                onClick={() => onChange(croppedImg)}
                disabled={croppedImg === null}
                type="button"
              >
                <i className="fa fa-save" />
                <IntlMessages id="profile-save" />
              </Button>
            </div>
          </div>

          <div className="col-md-3">
            <canvas ref={canvasRef} className="canvas" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps
)(KotlerImageCrooper);
