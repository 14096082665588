import React, { useRef, useState } from 'react';
import { Typography, withWidth } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import IntlMessages from 'utils/IntlMessages';
import { formatMessage } from 'helpers/IntlHelpers';

import AiIcon from './AiIcon';
import './InputAi.scss';
import { useManualAiStore } from '../hooks/useManualAIStore';
import { ResponseAi } from '..';

/**
 * InputAi Component
 * This component provides an input field for users to interact with the AI assistant.
 */
const InputAi = ( { onClickLink } ) => {
  const {
    loading,
    lastQuestion,
    fetchAsk,
    show,
    setShow,
    message,
  } = useManualAiStore();
  const [height, setHeight] = useState( 30 );
  const textareaRef = useRef( null );

  // Adjusts height of the textarea based on content
  const onKeyUp = () => {
    if ( !textareaRef.current ) return;
    setHeight( textareaRef.current.scrollHeight );
  };

  // Submits user's question to the AI assistant
  const onSubmit = ( { question } ) => {
    if ( !question ) return;
    fetchAsk( question );
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        zIndex: 20000,

        top: 0,
      }}
    >
      <div className="d-flex align-items-center" style={{ zIndex: 1500 }}>
        <AiIcon size={35} />
        <Typography variant="body2">
          <IntlMessages id="manualAiLabel" />
        </Typography>
      </div>

      <Form
        initialValues={{ question: lastQuestion }}
        onSubmit={onSubmit}
        render={( { handleSubmit, values } ) => (
          <form onSubmit={handleSubmit}>
            <div className="manual-ai-input-container  shadow shadow-lg position-relative">
              <div className={`container-response ${show ? 'show' : ''}`}>
                <div className="drawer-container-response  ">
                  <ResponseAi onClickLink={onClickLink} />
                </div>
              </div>
              <Field name="question">
                {( { input } ) => (
                  <textarea
                    {...input}
                    disabled={loading}
                    ref={textareaRef}
                    style={{ height: `${height}px` }}
                    onKeyUp={onKeyUp}
                    onFocus={() => {
                      if ( message ) setShow( true );
                    }}
                    maxLength={250}
                    className="manual-ai-input"
                    placeholder={formatMessage( { id: 'manualAiPlaceholder' } )}
                    onKeyDown={( event ) => {
                      if ( event.key === 'Enter' && !event.shiftKey ) {
                        event.preventDefault();
                        textareaRef.current.blur();
                        handleSubmit();
                      }
                    }}
                  />
                )}
              </Field>
              <div className="d-flex justify-content-center align-items-center ms-1">
                <button
                  disabled={loading || !values.question}
                  type="submit"
                  className="manual-ai-btn-send"
                >
                  <AiIcon size={24} />
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default withWidth()( InputAi );
