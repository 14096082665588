import { Box, Button } from "@material-ui/core";
import HoverTitle from "components/custom_v2/HoverTitle";
import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "utils/IntlMessages";

const ConfirmCancelButtons = ({containerClass="",submitBtnType,hoverCancel,titleCancel,cancelTo,onSubmit,hoverSubmit,titleSubmit,submitTo,onCancel})=>{
    return (
        <Box className={`kotler-header-section-container-buttons ${containerClass}`}>
        {titleCancel && (
          <HoverTitle {...hoverCancel} >
          <Button
            variant="contained"
            elevation={0}
            className="px-4 py-1 kt-btn-cancel"
            component={cancelTo ? Link : "button"}
            to={cancelTo}
            onClick={onCancel}
          >
            <IntlMessages id={titleCancel} />
          </Button>
          </HoverTitle>
        )}
        {titleSubmit && (
          <HoverTitle {...hoverSubmit} >
          <Button
            variant="contained"
            type={submitBtnType}
            elevation={0}
            className="px-4 py-1 kt-btn-submit"
            component={submitTo ? Link : "button"}
            to={submitTo}
            onClick={onSubmit}
          >
            <IntlMessages id={titleSubmit} />
          </Button>
          </HoverTitle>
        )}
      </Box>
    )
}
export default ConfirmCancelButtons;