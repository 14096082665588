// rootSaga.js

import { all } from 'redux-saga/effects';
import lastRoundBundlesRootSaga from './LastRoundBundlesSaga';
import allRoundsBundlesRootSaga from './AllRoundsBundlesSaga';

function* bundlesRootSaga() {
  yield all( [
    // Otras sagas de la aplicación
    lastRoundBundlesRootSaga(),
    allRoundsBundlesRootSaga(),
  ] );
}

export default bundlesRootSaga;
