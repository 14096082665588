import { useQuery } from 'react-query';
import { queryClient } from 'config/react-query';
import { getLastRoundServices } from '../services';

export const useLastRoundServicesQuery = ( sessionId, workgroupId ) => {
  const query = useQuery(
    [
      'market',
      'services-last-round',
      'session',
      sessionId,
      'workgroup',
      workgroupId,
    ],
    () => getLastRoundServices( sessionId, workgroupId, { limi: -1 } ),
    {
      enabled: !!sessionId && !!workgroupId,
      refetchOnReconnect: true,
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: true,
    },
  );

  return {
    query,
    loading: query.isLoading,
    data: query.data,
    lastRoundServices: query.data || [],
  };
};

export const prefetchLastRoundServices = ( sessionId, workgroupId ) => {
  queryClient.prefetchQuery(
    [
      'market',
      'services-last-round',
      'session',
      sessionId,
      'workgroup',
      workgroupId,
    ],
    () => getLastRoundServices( sessionId, workgroupId, { limit: -1 } ),
    { staleTime: 1000 * 60 * 60 },
  );
};
