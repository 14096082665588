import { getHttpRequest } from 'utils/ApiPetitionhandles';

// const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

// get bundles in array of workgroups  and each one has a historical of operations
export async function getAllRoundBundles( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/market/bundles-all-round`;
  return getHttpRequest( url, params );
}
// get bundles of last round of all workgroups inside data
export async function getLastRoundBundles( sessionId, workgroupId, params ) {
  const url = `${endpoint2}/${sessionId}/market/bundles-last-round?workgroup=${workgroupId}`;
  return getHttpRequest( url, params );
}
export default {
  getAllRoundBundles,
  getLastRoundBundles,
};
