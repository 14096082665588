import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import IntlMessages from "utils/IntlMessages";
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';
import { TextField } from "components/custom/FormElements";
import { Form } from "react-final-form";
import { composeValidators, email, required } from "config/InputErrors";
import { Close } from '@material-ui/icons'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';

const ForgotPassword = ({ accessToken, logoutUser, toggleLoading, toggleAlert, history }) => {
    const { palette } = useTheme();

    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);

    useEffect(() => {

        if (accessToken && open) logoutUser();
    }, [accessToken, logoutUser,open]);
    const submitForm = (data, form) => {
        toggleLoading(true);
        UserService.recoverPassword({ email: data.username })
            .then((response) => {
                toggleLoading(false);
                if (!response.ok) return toggleAlert(response.errors);
                form.reset();
                setOpen(false);
                toggleAlert('forgotPasswordEmail', 'info');
            });
    };

    return (
        <>
            <div className="d-flex justify-content-between  " style={{ marginBottom: 30, fontSize: '12px', fontWeight: 500, lineHeight: '24px' }}>
                <Button variant="text" color="primary" className="hr-button" onClick={() => setOpen(true)} >

                    <IntlMessages id="forgotPasswordBtn" />
                </Button>
            </div>
            <Dialog open={open} fullWidth maxWidth="sm" >
                <DialogTitle disableTypography style={{ paddingTop: '30px', paddingBottom: "5px" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                        <Typography
                            variant="h5"
                            color="textPrimary"
                            style={{ fontWeight: '600' }}
                        >
                            <IntlMessages id="recoverYourPassword" />
                        </Typography>
                        {onClose ? (
                            <IconButton
                                aria-label="close"
                                onClick={onClose}

                            >
                                <Close style={{ color: palette.secondary.dark }} />
                            </IconButton>
                        ) : null}
                    </div>
                </DialogTitle>
                <DialogContent style={{ marginBottom: '30px' }}>
                    <Form
                        onSubmit={submitForm}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} id="forgot-password-form" className='login-modal-form' noValidate>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField field="username" labelGap="20px" placeholder="placeHolderEmailField" label="email" validate={composeValidators(required, email)} />
                                    </Grid>
                                    <Grid item >
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            className="hr-button"
                                            onClick={onClose}
                                        >
                                            <IntlMessages id="cancelBtn" />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="hr-button mx-2"
                                            onClick={handleSubmit}
                                        >
                                            <IntlMessages id="recoverPassword" />
                                        </Button>
                                    </Grid>
                                </Grid>


                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}
const mapStateToProps = ({ user, settings }) => ({
    accessToken: user.access_token,
    isLoading: settings.isLoading,
});

const mapDispatchToProps = ({
    loginUser: UserActions.loginUser,
    logoutUser: UserActions.logoutUser,
    toggleLoading: SettingsActions.toggleLoading,
    toggleAlert: SettingsActions.toggleAlert,
    changeActiveSession: UserActions.changeActiveSession,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
