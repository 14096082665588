import React, { memo, useState } from 'react';

import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import _ from 'lodash';

import { connect, useSelector } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';

const localizer = BigCalendar.momentLocalizer( moment );
const WorkingDaysCalendarV2 = memo( ( { data, onConfirm } ) => {
  const [confirmCreateModal, setConfirmCreateModal] = useState( false );
  const [deleteModal, setDeleteModal] = useState( false );
  const [event, setEvent] = useState( {} );
  const locale = useSelector(
    ( { settings } ) => settings.locale && settings.locale.locale,
  );

  function toggleConfirmCreateModal() {
    setConfirmCreateModal( state => !state );
  }

  function toggleDeleteModal() {
    setDeleteModal( state => !state );
  }

  function submitEvents() {
    if ( event.slots !== undefined ) {
      const dates = _.map( event.slots, item => ( {
        title: 'X',
        allDay: true,
        start: new Date( item ),
        end: new Date( item ),
      } ) );
      onConfirm( { event: 'add', data: dates } );
    } else {
      const dates = {
        title: 'X',
        allDay: true,
        start: new Date( event ),
        end: new Date( event ),
      };
      onConfirm( { event: 'add', data: dates } );
    }

    toggleConfirmCreateModal();
  }

  function deleteEvents() {
    toggleDeleteModal();
    onConfirm( { event: 'remove', data: event } );
    // moment( Number(  ) ).format( 'YYYY-MM-DD' )
  }

  return (
    <div>
      <BigCalendar
        selectable
        events={data}
        culture={locale}
        localizer={localizer}
        defaultView="month"
        startAccessor="start"
        className="kotler-big-calendar"
        endAccessor="end"
        views={['month']}
        onSelectEvent={( ev ) => {
          setEvent( ev );
          toggleDeleteModal();
        }}
        onSelectSlot={( ev ) => {
          setEvent( ev );
          toggleConfirmCreateModal();
        }}
        onDrillDown={( ev ) => {
          setEvent( ev );
          toggleConfirmCreateModal();
        }}
      />

      <ConfirmationDialog
        open={confirmCreateModal}
        title="confirmCreateWorkingDayMsg"
        confirmBtnText="ok"
        onSubmit={submitEvents}
        onClose={toggleConfirmCreateModal}
      />

      <ConfirmationDialog
        open={deleteModal}
        title="confirmDeleteWorkingDayMsg"
        confirmBtnText="ok"
        onSubmit={deleteEvents}
        onClose={toggleDeleteModal}
      />
    </div>
  );
} );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( WorkingDaysCalendarV2 );
