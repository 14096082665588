import api from 'config/Api';
import { z } from 'zod';

export const createAiReportSchema = z.object( {
  sessionId: z.string().uuid(),
  fromRoundId: z.string(),
  toRoundId: z.string(),
} );

export const generateAiReport = async ( data = createAiReportSchema.parse() ) => {
  const { sessionId, fromRoundId, toRoundId } = createAiReportSchema.parse(
    data,
  );
  const res = await api.get(
    `v2/session/${sessionId}/report-ai/all-work-groups/fromRound/${fromRoundId}/toRound/${toRoundId}`,
  );
  await new Promise( resolve => setTimeout( resolve, 4000 ) );
  if ( res.ok ) {
    return res.data;
  }
  if ( res.error ) {
    throw new Error( res );
  }
};

const CreationStatusSchema = z.enum( ['pending', 'successful', 'failed'] );
export const ReportAiSchema = z.object( {
  id: z.number().optional(),
  sessionId: z.string().optional(),
  createdAt: z.string().optional(),
  url: z.string().optional(),
  creationStatus: CreationStatusSchema.optional(),
} );

const sessionIdSchema = z.string().uuid();
export const getLastGeneratedAiReport = async ( sessionId ) => {
  const id = sessionIdSchema.parse( sessionId );
  const res = await api.get( `v2/session/${id}/report-ai` );

  if ( !res.ok ) {
    throw new Error( ( res.data && res.data.message ) || res.data.error );
  }
  const data = ReportAiSchema.parse( res.data );
  return data;
};
