import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import SessionService from 'services/Session';
import CardBox from 'components/custom_v2/CardBox';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';

const LiveMaxVersion = ( { user } ) => {
  const [WorkGroup, setWorkgroup] = useState( null );
  useEffect( () => {
    if ( user && user.activeSession && user.activeSession.id ) {
      SessionService.getSessionCurrentWorkgroupRound(
        user.activeSession.id,
      ).then( ( response ) => {
        if ( response.ok ) {
          setWorkgroup( response.data );
        }
      } );
    }
  }, [user, user.activeSession] );

  return (
    <>
      {WorkGroup && WorkGroup.members && (
        <CardBox styleName="px-2 mt-3" header={<KotlerCardHeader minHeight="0px" title="ManagementTeam" className="p-0" />}>

          <Table className="kotler-table">
            <TableHead />
            <TableBody>
              {WorkGroup.members.map( ( { name, lastname, id } ) => (
                <TableRow key={id}>
                  {id === user.id ? (
                    <TableCell className="subtitle">
                      {`${name} ${lastname}`}

                    </TableCell>
                  ) : (
                    <TableCell>
                      {`${name} ${lastname}`}

                    </TableCell>
                  )}


                </TableRow>
              ) )}


            </TableBody>
          </Table>


        </CardBox>
      )}
    </>
  );
};

export default connect( ( { user } ) => ( { user } ) )( LiveMaxVersion );
