import React from 'react';
import _ from 'lodash';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import HoverTitle from '../HoverTitle';

const CardBox = ( {
  heading,
  disableHeader,
  children,
  styleName,
  childrenStyle,
  header,
  headerBg,
  onClick,
  icon,
  footer,
  text,
  hover,
  headerHover,
  aspectRatio,
} ) => (
  <div
    className={`kotler-card jr-card ${styleName} `}
    style={{ aspectRatio, cursor: onClick ? 'pointer' : undefined }}
    onClick={onClick}
  >
    {!disableHeader
      && ( header || (
        <HoverTitle {...headerHover}>
          <KotlerCardHeader
            minHeight="0px"
            title={heading}
            noTranslate={!heading || !_.isString( heading )}
            rightComponent={(
              <div>
                {' '}
                <i
                  style={{ float: 'right' }}
                  className={`fa fa-${icon} fa-1x `}
                />
              </div>
            )}
          />
        </HoverTitle>
      ) )}

    {!hover && (
      <div className={`jr-card-body ${childrenStyle}`}>{children}</div>
    )}

    {hover && (
      <HoverTitle {...hover}>
        <div className={`jr-card-body${childrenStyle}`}>{children}</div>
      </HoverTitle>
    )}

    {!!footer && <div className="jr-card-footer ">{footer}</div>}
  </div>
);

export default CardBox;

CardBox.defaultProps = {
  cardStyle: 'px-4 py-3 mb-0',
  headingStyle: 'mb-3',
  childrenStyle: 'p-0',
  styleName: 'col-12 p-0', // mt-3
};

/*
className="bg-primary text-white"
 */
