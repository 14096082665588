import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import FeatherIcon from '../FeatherIcon';


export default injectIntl(( { intl,label, onClick, linkTo, className } ) =>
  linkTo ? (
      <Link to={linkTo} className="text-decoration-none">
        <Tooltip title={intl.formatMessage({id:"spy"})} >
        <IconButton
        className=" square-icon-button mx-1"
      >
        <FeatherIcon icon="key" size="20px" color="primary2.main" />
      </IconButton>
      </Tooltip>
      </Link>
    )
    : (
      <Tooltip title={intl.formatMessage({id:"spy"})} >
      <IconButton
      className=" square-icon-button mx-1"
      onClick={onClick}
    >
       <i  className="fa fa-chalkboard-teacher  text-primary2" />
    </IconButton>
   </Tooltip>
    ))
