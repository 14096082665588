import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { map } from 'lodash';

const TableMarketProduct = ( {
  className = 'mt-3',
  marketProductData,
  //   loading,
  //   error,
} ) => {
  const [dataToTable, setDataToTable] = useState( [] );
  const [totalMonthSales, setTotalMonthSales] = useState( 0 );
  const [totalLastMonthSales, setTotalLastMonthSales] = useState( 0 );
  useEffect( () => {
    let totalMS = 0;
    let totalLMS = 0;
    const formatData = map(
      marketProductData && marketProductData.data,
      ( item ) => {
        totalLMS += item.lastsUnitSales;
        totalMS += item.unitSales;
        return {
          key: item.id,
          group: item.name,
          lastsUnitSales: Util.formatNumber( item.lastsUnitSales ),
          unitSales: Util.formatNumber( item.unitSales ),
        };
      },
    );

    setDataToTable( formatData );
    setTotalMonthSales( Util.formatNumber( totalMS ) );
    setTotalLastMonthSales( Util.formatNumber( totalLMS ) );
  }, [marketProductData] );
  return (
    <Table className={`kotler-table ${className}`}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            <IntlMessages id="market.last.month.sales" />
          </TableCell>
          <TableCell colSpan={2}>
            <IntlMessages id="market.sales.previous.month" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map( dataToTable, ( { group, key, lastsUnitSales, unitSales } ) => (
          <TableRow key={key}>
            <TableCell>{group}</TableCell>
            <TableCell align="right">{unitSales}</TableCell>
            <TableCell>{group}</TableCell>
            <TableCell align="right">{lastsUnitSales}</TableCell>
          </TableRow>
        ) )}

        <TableRow>
          <TableCell>
            <IntlMessages id="total" />
          </TableCell>
          <TableCell align="right">{totalMonthSales}</TableCell>
          <TableCell />
          <TableCell align="right">{totalLastMonthSales}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const mapStateToProps = ( { products }, { categoryId } ) => {
  if ( !categoryId ) {
    return {
      marketProductData: undefined,
      loading: true,
      error: null,
    };
  }
  const product = products.marketProducts.byCategoryId[categoryId];
  if ( !product ) {
    return {
      marketProductData: undefined,
      loading: false,
      error: null,
    };
  }
  return {
    marketProductData: product.data,
    loading: product.loading,
    error: product.error,
    // activeSessionId: user && user.activeSession && user.activeSession.id,
  };
};

export default connect( mapStateToProps )( TableMarketProduct );
