import React from 'react'
import { Field } from 'react-final-form'
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import classNames from 'classnames'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import NumberFormat from 'react-number-format'
import IntlMessages from 'utils/IntlMessages'


const TextFieldInput = ({
  field,
  containerClass,
  fullWidth = true,
  onChange,
  allowZero = false,
  translateValues,
  validate,
  intl,
  marginContainer = true,
  startAdornment,
  labelGap=null,
 labelClass,
 adornRequired=false,
  ...rest
}) =>
  field ? (
    <Field name={field} validate={validate}>
      {({ meta, input }) => (
        <FormControl 
          error={meta.touched && !!meta.error}
          className={classNames('form-control-app-sm', {
            'mb-4': marginContainer
          },containerClass || "kotler-text-field-form-control")}
          style={{gap:labelGap}}
          fullWidth={fullWidth}
        >
          {rest.label ? (
            <label className={labelClass}>
              <IntlMessages id={rest.label} />{adornRequired && <span className="text-danger">*</span>}
            </label>
          ) : (
            ''
          )}
          {(() => {
            if (rest.type === 'number') {
              rest.type = 'string'
              rest.setmasknumber = 'true'
            }
          })()}
          {rest.setmasknumber === 'true' ? (
            <NumberFormat
              variant="outlined"
              customInput={TextField}
              thousandSeparator={true}
              isNumericString={true}
              {...input}
              {...rest}
              className={rest.className || "kotler-text-field-primary2"}
              label={null}
              error={meta.submitFailed && meta.error}
              placeholder={
                rest.placeholder
                  ? intl.formatMessage({ id: rest.placeholder })
                  : null
              }
              defaultValue={allowZero ? input.value : input.value || ''}
              onValueChange={(values) => (rest.value = values.value)}
              onChange={(e) => {
                input.onChange(rest.value || '')
                if (onChange) onChange(e.target.value, e)
              }}
            />
          ) : (
            <TextField
              variant="outlined"
              {...input}
              {...rest}
              className={rest.className || "kotler-text-field-primary2"}
              label={null}
              error={meta.submitFailed && meta.error}
              placeholder={
                rest.placeholder
                  ? intl.formatMessage({ id: rest.placeholder })
                  : null
              }
              value={allowZero ? input.value : input.value || ''}
              onChange={(e) => {
                if (rest.type === 'number') {
                  input.onChange(
                    !_.isEmpty(e.target.value) ? +e.target.value : '',
                  )
                  if (onChange)
                    onChange(
                      !_.isEmpty(e.target.value) ? +e.target.value : '',
                      e,
                    )
                } else {
                  input.onChange(e.target.value)
                  if (onChange) onChange(e.target.value, e)
                }
              }}
              InputProps={{
                startAdornment: startAdornment ? (
                  <InputAdornment position="start" style={{backgroundColor:"white"}}>
                    {startAdornment}
                  </InputAdornment>
                ) : null,
              }}
            />
          )}
          {meta.submitFailed && meta.error && (
            <FormHelperText>
              <IntlMessages id={meta.error} values={translateValues} />
            </FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  ) : (
    <FormControl
      className={classNames({ 'mb-4': marginContainer },containerClass || "kotler-text-field-form-control")}
      fullWidth={fullWidth}
      style={{gap:labelGap}}
    >
      <TextField
        variant="outlined"
        {...rest}
        className={rest.className || "kotler-text-field-primary2"}
        placeholder={
          rest.placeholder ? intl.formatMessage({ id: rest.placeholder }) : null
        }
        value={rest.value || ''}
        onChange={(e) => {
          if (onChange) onChange(e.target.value, e)
        }}
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start" style={{backgroundColor:"white"}}>
              {startAdornment}
            </InputAdornment>
          ) : null,
        }}
      />
    </FormControl>
  )

export default injectIntl(TextFieldInput)
