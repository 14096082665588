import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';


import { TextField } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import session from 'services/Session';
import userapi from 'services/User';
import IntlMessages from 'utils/IntlMessages';
import { composeValidators, required, email } from 'config/InputErrors';
import UserActions from 'store/reducers/User';
import async from 'async';
import AuthService from 'services/Auth';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import SectionImage from 'assets/images/kotler/sectionHeaders/register.jpeg';
import CardBox from 'components/custom_v2/CardBox';

class SignUpDemo extends React.Component {
  state = {
    data: {},
    dataOri: {},
  };
  isEnabled = false;

  workGroupID= '';
  userEmail='';
  userEmailPrev='';
  componentDidMount() {
    const { match } = this.props;
    // if ( accessToken ) logoutUser();
    this.token = match.params.token;
    this.getEntity();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }
  handleKeyPress = ( event ) => {
    const { dataOri } = this.state;
    const fieldName = event.target.name;
    if ( ( event.key === 'Enter' ) && ( fieldName ) ) {
      this.isEnabled = true;
      const fieldValue = event.target.value;
      this.userEmailPrev = this.userEmail;
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        if ( fieldName.search( 'user.email' ) >= 0 ) {
          const { toggleLoading, toggleAlert } = this.props;
          toggleLoading( true );
          let user = {
            name: '',
            lastName: '',
            phone: '',
            email: this.userEmail,
          };
          userapi.userByEmail( { email: fieldValue } )
            .then( ( response ) => {
              toggleLoading( false );
              if ( !response.ok ) return toggleAlert( response.errors );
              try {
                user = {
                  name: response.data.user.name,
                  lastName: response.data.user.lastName,
                  phone: response.data.user.phone,
                  email: this.userEmail,
                };
              } catch ( e ) {
                user = {
                  name: '',
                  lastName: '',
                  phone: '',
                  email: this.userEmail,
                };
              }
              this.setState( { data: { user, ...dataOri, workGroup: this.workGroupID } } );
            } );
        }
      }
    }
  }
  getEntity = () => {
    const { toggleLoading, history } = this.props;
    toggleLoading( true );

    session.getSessionRegistration( this.token )
      .then( ( response ) => {
        toggleLoading( false );
        if ( response.ok ) {
          this.setState( { dataOri: response.data } );
          this.setState( { data: response.data } );
        } else {
          history.push( '/login' );
        }
      } );
  };

  submitForm = ( data2 ) => {
    const { toggleLoading, toggleAlert } = this.props;
    const { data } = this.state;

    const workGroup = data.workGroups[Math.floor( Math.random() * data.workGroups.length )];

    const dataToSend = {
      workGroup: workGroup.id,
      name: data2.user.name,
      lastName: data2.user.lastName,
      phone: '00000000000',
      plainPassword: '1234567',
      email: data2.user.email,
      session: this.token,
    };


    toggleLoading( true );

    userapi.registerByCode( dataToSend )
      .then( async ( response ) => {
        if ( !response.ok ) { toggleLoading( false ); return toggleAlert( response.errors ); }
        await this.loginSendData( { username: data2.user.email, password: '1234567' } );

        toggleAlert( 'successfullyRegistered', 'info' );
      } );
  };
  handleChangeWG = ( workGroupID_ ) => {
    this.workGroupID = workGroupID_;
  }

  handleBlurEmail = ( event ) => {
    const { dataOri } = this.state;
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const isChanged = fieldValue !== this.userEmailPrev;
    this.userEmailPrev = this.userEmail;
    const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( fieldValue );
    if ( ( isChanged ) && ( fieldName ) && ( isEmail ) ) {
      this.isEnabled = true;
      // const fieldValue = event.target.value;
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        if ( fieldName.search( 'user.email' ) >= 0 ) {
          const { toggleLoading, toggleAlert } = this.props;
          toggleLoading( true );
          let user = {
            id: null,
            name: '',
            lastName: '',
            phone: '',
            email: this.userEmail,
          };
          userapi.userByEmail( { email: fieldValue } )
            .then( ( response ) => {
              toggleLoading( false );
              if ( !response.ok ) return toggleAlert( response.errors );
              try {
                user = {
                  id: response.data.user.id,
                  name: response.data.user.name,
                  lastName: response.data.user.lastName,
                  phone: response.data.user.phone,
                  email: this.userEmail,
                };
              } catch ( e ) {
                user = {
                  id: null,
                  name: '',
                  lastName: '',
                  phone: '',
                  email: this.userEmail,
                };
              }
              this.setState( { data: { user, ...dataOri, workGroup: this.workGroupID } } );
            } );
        }
      }
    }
  }
  handleChangeEmail = ( userEmail_ ) => {
    const { dataOri } = this.state;
    this.userEmailPrev = this.userEmail;
    this.userEmail = userEmail_;
    const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( userEmail_ );
    if ( !isEmail ) {
      this.isEnabled = false;
      const user = {
        id: null,
        name: '',
        lastName: '',
        phone: '',
        email: this.userEmail,
      };
      this.setState( { data: { user, ...dataOri, workGroup: this.workGroupID } } );
    }
  }
  handleChange = async ( dataEmail ) => {
    const { dataOri } = this.state;
    const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( dataEmail );
    let user = {
      name: '',
      lastName: '',
      phone: '',
    };
    if ( isEmail ) {
      const { toggleLoading, toggleAlert } = this.props;
      toggleLoading( true );

      await userapi.userByEmail( { email: dataEmail } )
        .then( ( response ) => {
          toggleLoading( false );
          if ( !response.ok ) return toggleAlert( response.errors );
          try {
            user = {
              name: response.data.user.name,
              lastName: response.data.user.lastName,
              phone: response.data.user.phone,
            };
          } catch ( e ) {
            user = {
              name: '',
              lastName: '',
              phone: '',
            };
          }
          this.setState( { data: { user, ...dataOri } } );
        } );
    } else {
      this.setState( { data: { user, ...dataOri } } );
    }
    this.isEnabled = isEmail;
  }

  loginUser = ( data, cb ) => {
    AuthService.loginUser( data )
      .then( response => cb( response.errors, response.data ) );
  };

  getUser = ( data, cb ) => {
    userapi.getCurrentUser( data )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        return cb( null, { ...data, ...response.data } );
      } );
  };

  getNotificationsUser = ( data, cb ) => {
    userapi.getNotificationsUser( data )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        return cb( null, { ...data, notifications: response.data } );
      } );
  };

  loginSendData( data ) {
    const {
      loginUser,
      changeActiveSession,
      toggleLoading,
      toggleAlert,
      history,
      toggleVideo,
      toggleTip,
    } = this.props;

    toggleLoading( true );
    toggleVideo( true );
    toggleTip( true );

    async.waterfall( [
      this.loginUser.bind( this, data ),
      this.getUser,
      this.getNotificationsUser,
    ], ( error, results ) => {
      if ( error ) {
        if ( error === 'invalid_grant' ) {
          return toggleAlert( 'wrongUserPass', 'danger', true );
        }
        return toggleAlert( error );
      }
      if ( _.get( results, 'licenses[0].session' ) ) {
        changeActiveSession( results.licenses[0].session );
        loginUser( results );
        history.push( '/' );
      } else {
        loginUser( results );
        history.push( '/sessions/add' );
      }
      toggleLoading( false );
    } );
  }
  render() {
    const { data } = this.state;
    return (

      <Container maxWidth="lg" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Form
          initialValues={{ ...data, plainPassword: '1234567' }}
          onSubmit={this.submitForm}
          render={( { handleSubmit, form } ) => (
            <form onSubmit={handleSubmit}>
              <KotlerSectionHeader title="appModule.regsiter" image={SectionImage} cancelTo="/login" titleCancel="cancelBtn" titleSubmit="save" onSubmit={handleSubmit} />

              <Grid container alignItems="center" className="mt-5 flex-grow-1">
                <Grid item xs={12} sm={6} md={3}>
                  <CardBox
                    styleName="px-3 h-100 pb-0"
                    header={(
                      <div>
                        <Typography variant="body1" style={{ fontSize: '18px', fontWeight: 600, lineHeight: '26px' }} className="text-dark">
                          <IntlMessages id="signup.invitation.title" />
                        </Typography>
                      </div>
                    )}
                  >
                    <Table className="kotler-table-info-details">
                      <TableHead />
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 600, lineHeight: '26px' }} color="primary">
                              {_.get( data, 'entity.name', '---' )}
                              {` > ${_.get( data, 'name', '---' )}`}
                            </Typography>
                          </TableCell>


                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <IntlMessages id="address" />
                          </TableCell>
                          <TableCell>
                            {_.get( data, 'entity.address', '---' )}
                          </TableCell>

                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <IntlMessages id="contact" />
                          </TableCell>
                          <TableCell>
                            {_.get( data, 'entity.contactName', '---' )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <IntlMessages id="email" />
                          </TableCell>
                          <TableCell>
                            {_.get( data, 'entity.contactEmail', '---' )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <IntlMessages id="startDate" />
                          </TableCell>
                          <TableCell>
                            {_.get( data, 'startDate' )
                              ? moment( Number( data.startDate ) )
                                .format( 'DD/MM/YYYY' ) : '---'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <IntlMessages id="endDate" />
                          </TableCell>
                          <TableCell>
                            {_.get( data, 'startDate' )
                              ? moment( Number( data.endDate ) )
                                .format( 'DD/MM/YYYY' ) : '---'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                  </CardBox>
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                  <CardBox
                    styleName="pl-3  h-100 pb-0"
                    header={(
                      <div>
                        <Typography variant="body1" style={{ fontSize: '18px', fontWeight: 600, lineHeight: '26px' }} className="text-dark">
                          <IntlMessages id="signup.finishRegistration.title" />
                        </Typography>
                      </div>
                    )}
                  >

                    <Grid container spacing={2} className="mt-0">

                      <Grid item xs={12} md={6}>
                        <TextField
                          field="user.email"
                          label="email"
                          marginContainer={false}
                          validate={composeValidators( required, email )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} />
                      <Grid item xs={12} md={6}>
                        <TextField
                          field="user.name"
                          label="name"
                          marginContainer={false}
                          validate={required}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          field="user.lastName"
                          marginContainer={false}
                          label="lastName"
                          validate={required}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          field="plainPassword"
                          marginContainer={false}
                          value="1234567"
                          label="password"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={6} />
                      <Grid item xs={12} md={6} />
                      <Grid item xs={12} md={6} />
                    </Grid>

                  </CardBox>
                </Grid>
              </Grid>


            </form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => ( {
  accessToken: user.access_token,
  isLoading: settings.isLoading,
} );

const mapDispatchToProps = {
  loginUser: UserActions.loginUser,
  toggleVideo: SettingsActions.toggleVideo,
  toggleTip: SettingsActions.toggleTip,
  notificationsUser: UserActions.notificationsUser,
  changeActiveSession: UserActions.changeActiveSession,
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  logoutUser: UserActions.logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( SignUpDemo );
