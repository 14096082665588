import { combineReducers } from 'redux';

import lastRoundBundlesReducer from './LastRoundBundlesReducer';
import allRoundsBundlesReducer from './AllRoundsBundlesReducer';

const bundlesRootReducer = combineReducers( {
  lastRoundBundles: lastRoundBundlesReducer,
  allRoundsBundles: allRoundsBundlesReducer,
  // Agrega aquí más reducers específicos del módulo si los tienes
} );

export default bundlesRootReducer;
