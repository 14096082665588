import { takeEvery, put, call } from 'redux-saga/effects';

import { getAllRoundBundles } from 'modules/Bundles/BundlesService';
import {
  FETCH_ALL_ROUNDS_BUNDLES_REQUEST,
  fetchAllRoundsBundlesFailure,
  fetchAllRoundsBundlesSuccess,
} from '../actions/AllRoundsBundlesActions';

function* fetchAllRoundsBundlesSaga( action ) {
  const { sessionId, params = { limit: -1 } } = action.payload;

  try {
    const allRoundsBundlesData = yield call(
      getAllRoundBundles,
      sessionId,
      params,
    );
    yield put( fetchAllRoundsBundlesSuccess( allRoundsBundlesData ) );
  } catch ( error ) {
    yield put( fetchAllRoundsBundlesFailure( error ) );
  }
}

export default function* allRoundsBundlesRootSaga() {
  yield takeEvery( FETCH_ALL_ROUNDS_BUNDLES_REQUEST, fetchAllRoundsBundlesSaga );
}
