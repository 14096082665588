import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Container } from '@material-ui/core';
import _ from 'lodash';

import { TextField } from 'components/custom/FormElements';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import FilterForm from 'components/custom/FilterForm';
import NewEntityBtn from 'components/custom/NewEntityBtn';
import SettingsActions from 'store/reducers/Settings';
import EntityService from 'services/Entity';
import SearchParams from 'utils/SearchParams';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import AsyncTable from 'components/custom/Table/AsyncTable';

class Dashboard extends React.Component {
  state = {
    data: [],
    deleteModal: false,
  };

  search = {};

  getData = ( params ) => {
    const { toggleLoading } = this.props;
    toggleLoading( true );

    this.params = _.cloneDeep( params );

    if ( this.search.endDate ) {
      this.search.endDate = moment( this.search.endDate ).format( 'YYYY-MM-DD' );
    }
    if ( this.search.initDate ) {
      this.search.initDate = moment( this.search.initDate ).format( 'YYYY-MM-DD' );
    }
    const formattedParams = SearchParams.getSearchParams( this.search, params );
    EntityService.getEntities( formattedParams ).then( ( response ) => {
      toggleLoading( false );

      if ( response.ok ) {
        this.setState( {
          data: response.data.data || [],
          pages: Math.ceil( response.data.total / params.limit ),
          total: response.data.total,
        } );
      }
    } );
  };

  submitForm = ( data ) => {
    this.search = data;
    this.getData( this.params );
  };

  deleteEntity = () => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );

    EntityService.deleteEntity( this.entityToDelete.id ).then( ( response ) => {
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleAlert( response.errors );
      }
      this.toggleDeleteModal( null, () => this.getData( this.params ) );
    } );
  };

  toggleDeleteModal = ( entity, cb ) => {
    this.entityToDelete = entity;
    this.setState(
      previousState => ( { deleteModal: !previousState.deleteModal } ),
      cb,
    );
  };

  render() {
    const { deleteModal, ...rest } = this.state;

    return (
      <>
        <KotlerSectionHeader
          title="universities"
          image="/images/sectionHeaders/info-company.jpeg"
        />
        <Container
          maxWidth="xl"
          className="d-flex flex-grow-1  flex-column  mt-4"
        >
          <FilterForm
            newBtn={
              <NewEntityBtn label="newUniversity" linkTo="/universities/new" />
            }
            onSubmit={this.submitForm}
            onClear={() => {
              this.search = {};
              this.getData( this.params );
            }}
          >
            <div className="col-lg-3">
              <TextField field="name" label="name" />
            </div>
          </FilterForm>

          <AsyncTable
            showPaginationTop
            onFetchData={this.getData}
            {...rest}
            columns={[
              {
                Header: <IntlMessages id="name" />,
                accessor: 'name',
                Cell: props => `${props.value || ''} ${props.original.lastName || ''}`,
              },
              { Header: <IntlMessages id="email" />, accessor: 'email' },
              {
                Header: <IntlMessages id="minLicensesAvailable" />,
                accessor: 'minLicensesAvailable',
                Cell: ( { value } ) => Util.formatNumber( value ),
              },
              {
                Header: <IntlMessages id="availableLicenses" />,
                accessor: 'availableLicenses',
                Cell: ( { value } ) => Util.formatNumber( value ),
              },
              {
                Header: <IntlMessages id="usedLicenses" />,
                accessor: 'usedLicenses',
                Cell: ( { value } ) => Util.formatNumber( value ),
              },
              {
                Header: <IntlMessages id="active" />,
                accessor: 'active',
                maxWidth: 120,
                Cell: props => ( props.value ? (
                  <i className="fa fa-check" />
                ) : (
                  <i className="fa fa-times" />
                ) ),
              },
              {
                Header: '',
                accessor: 'id',
                maxWidth: 70,
                Cell: props => (
                  <Link
                    to={`/universities/detail/${props.value}/general`}
                    className="mr-2 text-decoration-none"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className="jr-btn"
                    >
                      <i className="fa fa-door-closed" />
                    </Button>
                  </Link>
                ),
              },
            ]}
          />
        </Container>

        <ConfirmationModal
          open={deleteModal}
          translateValues={{
            name: this.entityToDelete
              ? `${this.entityToDelete.name || ''} ${this.entityToDelete
                .lastName || ''}`
              : '',
          }}
          onSubmit={this.deleteEntity}
          onClose={this.toggleDeleteModal}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( Dashboard );
