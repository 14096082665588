import { createReducer, createActions } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getLiveAd } from '../services/LiveAdsService';

// Action types and action creators
export const { Types, Creators } = createActions( {
  set: ['ad', 'loading', 'error'],
  fetchAdRequest: ['sessionId'],
  fetchAdSuccess: ['data'],
  fetchAdFailure: ['error'],
  clearAd: [],
} );

// Reducer initial state
const INITIAL_STATE = {
  ad: null,
  loading: false,
  error: null,
};

// Reducers
export const reducer = createReducer( INITIAL_STATE, {
  [Types.FETCH_AD_REQUEST]: state => ( { ...state, loading: true, error: null } ),
  [Types.SET]: ( state, newState ) => ( { ...state, ...newState } ),
  [Types.FETCH_AD_SUCCESS]: ( state, { data } ) => ( {
    ...state,
    loading: false,
    ad: data,
  } ),
  [Types.FETCH_AD_FAILURE]: ( state, { error } ) => ( {
    ...state,
    loading: false,
    error,
  } ),
  [Types.CLEAR_AD]: () => INITIAL_STATE,
} );

// Sagas
function* fetchAdSaga( { sessionId } ) {
  try {
    const data = yield call( getLiveAd, sessionId );
    yield put( Creators.fetchAdSuccess( data ) );
  } catch ( error ) {
    yield put( Creators.fetchAdFailure( error.message ) );
  }
}

export function* rootSaga() {
  yield takeLatest( Types.FETCH_AD_REQUEST, fetchAdSaga );
}
