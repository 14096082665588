import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import SessionService from 'services/Session';
import { useCallback, useEffect } from 'react';
import UserActions from 'store/reducers/User';
import { SessionSchema } from '../schemas/session-schemas';
import { useActiveSessionId } from './useActiveSession';

/**
 *
 * Obtiene una sesión por ID.
 *
 * @param {number} sessionId - El ID de la sesión a obtener.
 * @returns {Promise<LivemaxSession>} - Una promesa que se resuelve con la sesión.
 */
const getSessionById = async ( sessionId ) => {
  const res = await SessionService.getSession2( sessionId );
  if ( res.ok ) {
    const { data } = SessionSchema.safeParse( res.data );
    return data;
  }
  throw res.errors;
};

export const useActiveSessionQuery = ( queryProps = {} ) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line max-len
  const activeSessionId = useSelector(
    ( { user } ) => user && user.activeSession && user.activeSession.id,
  );

  const sessionQuery = useQuery(
    ['v1', 'session', activeSessionId],
    () => getSessionById( activeSessionId ),
    {
      enabled: !!activeSessionId,
      refetchInterval: 1000 * 60 * 1,
      staleTime: 1000,
      cacheTime: 1000 * 60 * 60 * 24 * 2,
      //  initialData: activeSessionRedux,
      refetchOnMount: true,
      ...queryProps,
    },
  );

  useEffect( () => {
    const session = sessionQuery.data;
    if (
      session
      && session.id === activeSessionId
      && JSON.stringify( session ) !== JSON.stringify( sessionQuery.data )
    ) {
      dispatch( UserActions.changeActiveSession( session ) );
    }
  }, [sessionQuery.data, activeSessionId, dispatch] );

  return {
    ...sessionQuery,
    activeSessionId,
  };
};

export const useRefetchActiveSession = () => {
  const queryClient = useQueryClient();

  const activeSessionId = useActiveSessionId();

  const refetchActiveSession = useCallback(
    () => queryClient.refetchQueries( ['v1', 'session', activeSessionId] ),
    [queryClient, activeSessionId],
  );
  return refetchActiveSession;
};
