import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/AsyncTable';
import NewEntityBtn from 'components/custom/NewEntityBtn';
import FilterForm from 'components/custom/FilterForm';
import { Switch, TextField } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import UserLicenseService from 'services/UserLicense';
import SupervisorService from 'services/Supervisor';
import IntlMessages from 'utils/IntlMessages';
import SearchParams from 'utils/SearchParams';
import ConfirmationResendModal from 'components/custom/ConfirmationResendModal';
import { Button } from '@material-ui/core';
import PurchaseLicenseEditForm from '../../../../../../components/Admin/University/PurchaseLicenseEditForm';
import LicenseEditForm from '../../../../../../components/LicenseEditForm';

class ProfLicenses extends React.Component {
  entityToEdit={}
  state = {
    data: [],
    editModal: false,
    editModalLicense: false,
    resendModal: false,
  };

  getData = ( params ) => {
    const { toggleLoading, entityId } = this.props;
    toggleLoading( true );

    this.params = _.cloneDeep( params );
    const formattedParams = SearchParams.getSearchParams( this.search, params );

    UserLicenseService.getProfLicensesByEntity( entityId, formattedParams )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( {
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / params.limit ),
            total: response.data.total,
          } );
        }
      } );
  };

  submitForm = ( data ) => {
    this.search = data;
    this.getData( this.params );
  };

  submitFormLicense = ( formData, form ) => {
    const { toggleLoading, toggleAlert, sessionId, entityId } = this.props;
    const dataToSend = {};

    toggleLoading( true );
    _.map( _.keys( form.getState().modified ), ( key ) => {
      dataToSend[key] = _.get( formData[key], 'id' ) ? formData[key].id : formData[key];
    } );

    const promise = this.entityToEdit && this.entityToEdit.id
      ? SupervisorService.updateSupervisorLicense( sessionId, this.entityToEdit.id, dataToSend )
      : SupervisorService.saveSupervisorLicenseV2( entityId, dataToSend );
    promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );

      toggleAlert( 'dataSaved', 'info' );
      this.toggleEditModalNewLicense();
      this.getData( this.params );
    } );
  };

  toggleEditModal = ( cb ) => {
    this.setState( previousState => ( { editModal: !previousState.editModal } ), cb );
  };
  toggleEditModalNewLicense = ( cb ) => {
    this.setState( previousState => ( { editModalLicense: !previousState.editModalLicense } ), cb );
  };

  resendInvitation = () => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );
    SupervisorService.resendInvitation( this.entityToResend.entity.id, this.entityToResend.id )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }
        toggleAlert( 'emailSent', 'info' );
        this.toggleResendModal( null, () => this.getData( this.params ) );
      } );
  };
  toggleResendModal = ( entity, cb ) => {
    this.entityToResend = entity;
    this.setState( previousState => ( { resendModal: !previousState.resendModal } ), cb );
  };
  updateAccessAllSessions = ( value, original ) => {
    const { entityId, toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );
    SupervisorService.updateSupervisorAccessToAllSessions( entityId, original.id, !value )
      .then(
        ( response ) => {
          toggleLoading( false );
          if ( response.ok )toggleAlert( 'dataSaved', 'info' );
          this.getData( this.params );
        },
      );
  }
  render() {
    const { editModalLicense, resendModal, editModal, ...rest } = this.state;
    const { entityId } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-12 text-right">
            <NewEntityBtn
              label="newProfessorLicense"
              onClick={this.toggleEditModalNewLicense}
            />
          </div>
        </div>
        <CardBox cardStyle="p-0">
          <FilterForm
            onSubmit={this.submitForm}
            onClear={() => {
              this.search = {};
              this.getData( this.params );
            }}
          >
            <div className="col-lg-3">
              <TextField
                field="user"
                label="user"
              />
            </div>
          </FilterForm>
          <Table
            onFetchData={this.getData}
            {...rest}
            columns={[
              {
                maxWidth: 150,
                Header: <IntlMessages id="id" />,
                accessor: 'id',
                Cell: props => ( props.value ? `${props.value.slice( 0, 8 ).toUpperCase()}` : null ),
              },
              {
                Header: <IntlMessages id="name" />,
                accessor: 'user.name',
                id: 'name',
                Cell: ( { value, original } ) => `${value} ${_.get( original, 'user.lastName', '' )}`,

              },
              {
                Header: <IntlMessages id="user" />,
                accessor: 'user.email',
                id: 'user',
              },
              {
                Header: <IntlMessages id="userEnabled" />,
                accessor: 'user.enabled',
                Cell: props => ( props.value ? <i className="fa fa-check" />
                  : <i className="fa fa-times" /> ),
              },
              {
                Header: <IntlMessages id="enabled" />,
                accessor: 'enabled',
                maxWidth: 120,
                Cell: props => ( props.value ? <i className="fa fa-check" />
                  : <i className="fa fa-times" /> ),
              },
              {
                Header: <IntlMessages id="allSession.list.label" />,
                accessor: 'allSession',
                maxWidth: 120,
                Cell: ( { value, original } ) => (
                  <Switch
                    checked={!!value}
                    onChange={() => this.updateAccessAllSessions( value, original )}
                  />
                ),
              },
              {
                Header: <IntlMessages className="text-center" id="resendInvitation" />,
                accessor: 'id',
                Cell: props => (
                  <Button
                    className="jr-btn bg-blue-grey text-white ml-4"
                    variant="contained"
                    onClick={() => this.toggleResendModal( props.original )}
                  >
                    <i className="fa fa-envelope" />
                  </Button>
                ),
              },
            ]}
          />
        </CardBox>

        <PurchaseLicenseEditForm
          open={editModal}
          onClose={() => this.toggleEditModal()}
          onConfirm={() => {
            this.toggleEditModal();
            this.getData( this.params );
          }}
          entityId={entityId}
        />
        <LicenseEditForm
          data={this.entityToEdit}
          open={editModalLicense}
          newLabel="newProfessorLicense"
          editLabel="editProfessorLicense"
          onClose={() => this.toggleEditModalNewLicense()}
          onSubmit={this.submitFormLicense}
        />
        <ConfirmationResendModal
          open={resendModal}
          warning
          translateValues={{ name: this.entityToResend && this.entityToResend.user ? `${this.entityToResend.user.name || ''} ${this.entityToResend.user.lastName || ''}` : '' }}
          onSubmit={this.resendInvitation}
          onClose={this.toggleResendModal}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( ProfLicenses );
