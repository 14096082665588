import React, { useState } from 'react';
import { Field } from 'react-final-form';
import {
  FormControl,
  FormHelperText,
  Slider,
  Box,
} from '@material-ui/core';
import classNames from 'classnames';
import IntlMessages from 'utils/IntlMessages';
import HoverTitle from 'components/custom_v2/HoverTitle';

export default ({
  containerClass, field, label,marks=[],
  onChange, min, max, validate, disabled = false, leftContent, rightContent, radioClassName, hover,initialValue= (min || 0)
}) => {
  const [value, setValue] = useState(null);
  return (
    <Field name={field} validate={validate}>
      {({ meta, input }) => {


        return (
          <FormControl error={meta.submitFailed && !!meta.error}
            className={classNames('w-100', containerClass)}>


            {label && (
              <label>
                <IntlMessages id={label} />
              </label>
            )}
            <Box className='w-100 d-flex align-items-end  '>
              {leftContent}
              <HoverTitle {...hover}  >
                <Box
                  className='flex-grow-1 d-flex justify-content-center flex-column text-left mx-3'>
                  <Slider
                valueLabelDisplay="on"
                  marks={marks}
                    className='kotler-slider'
                    disabled={disabled}
                    min={min}
                    max={max}
                    style={{ minWidth: "50px" }}
                    value={(value || Number(input.value) || initialValue)}
                    onChange={(e, val) => {
                      setValue(val);
                    }}
                    onChangeCommitted={(e, val) => {
                      input.onChange(value);
                      if (onChange) onChange(val, input.value);
                    }}
                  />
                 
                </Box>
              </HoverTitle>

              {rightContent}
            </Box>
            {meta.submitFailed && meta.error &&
              <FormHelperText>{<IntlMessages id={meta.error} />}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  )
};
