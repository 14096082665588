import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button } from '@material-ui/core';
import _ from 'lodash';

import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/AsyncTable';
import NewEntityBtn from 'components/custom/NewEntityBtn';
import { TextField } from 'components/custom/FormElements';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import FilterForm from 'components/custom/FilterForm';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';
import SearchParams from 'utils/SearchParams';
import IntlMessages from 'utils/IntlMessages';

class Dashboard extends React.Component {
  state = {
    data: [],
    deleteModal: false,
  };

  search = {};

  getData = ( params ) => {
    const { toggleLoading } = this.props;
    toggleLoading( true );

    this.params = _.cloneDeep( params );

    if ( this.search.endDate ) {
      this.search.endDate = moment( this.search.endDate )
        .format( 'YYYY-MM-DD' );
    }
    if ( this.search.initDate ) {
      this.search.initDate = moment( this.search.initDate )
        .format( 'YYYY-MM-DD' );
    }
    const formattedParams = SearchParams.getSearchParams( this.search, params );
    UserService.getUsers( formattedParams )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( {
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / params.limit ),
            total: response.data.total,
          } );
        }
      } );
  };

  submitForm = ( data ) => {
    this.search = data;
    this.getData( this.params );
  };

  deleteEntity = () => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );

    UserService.deleteUser( this.entityToDelete.id ).then( ( response ) => {
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleAlert( response.errors );
      }
      this.toggleDeleteModal( null, () => this.getData( this.params ) );
    } );
  };

  toggleDeleteModal = ( entity, cb ) => {
    this.entityToDelete = entity;
    this.setState( previousState => ( { deleteModal: !previousState.deleteModal } ), cb );
  };

  render() {
    const { deleteModal, ...rest } = this.state;

    return (
      <div className="app-wrapper">
        <CardBox cardStyle="p-0">
          <FilterForm
            newBtn={(
              <NewEntityBtn
                label="newUser"
                linkTo="/users/new"
              />
            )}
            onSubmit={this.submitForm}
            onClear={() => {
              this.search = {};
              this.getData( this.params );
            }}
          >
            <div className="col-lg-3">
              <TextField
                field="name"
                label="name"
              />
            </div>
          </FilterForm>

          <Table
            onFetchData={this.getData}
            {...rest}
            columns={[
              {
                Header: <IntlMessages id="name" />,
                accessor: 'name',
                Cell: props => `${props.value || ''} ${props.original.lastName || ''}`,
              },
              {
                Header: <IntlMessages id="email" />,
                accessor: 'email',
              },
              {
                Header: <IntlMessages id="enabled" />,
                accessor: 'enabled',
                maxWidth: 120,
                Cell: props => ( props.value ? <i className="fa fa-check" /> : <i className="fa fa-times" /> ),
              },
              {
                Header: '',
                accessor: 'id',
                maxWidth: 200,
                Cell: props => (
                  <div>
                    <NewEntityBtn
                      isEdit
                      className="mr-2"
                      linkTo={`/users/edit/${props.value}`}
                    />
                    <Link to={`/users/change-password/${props.value}`} className="mr-2 text-decoration-none">
                      <Button
                        className="jr-btn bg-blue-grey text-white"
                        variant="contained"
                      >
                        <i className="fa fa-key" />
                      </Button>
                    </Link>
                    <Link to={`/users/detail/${props.value}`} className="mr-2 text-decoration-none">
                      <Button
                        className="jr-btn bg-blue-grey text-white"
                        variant="contained"
                      >
                        <i className="fa fa-eye" />
                      </Button>
                    </Link>
                    {/*  <Button
                      variant="contained"
                      className="jr-btn bg-danger text-white"
                      onClick={() => this.toggleDeleteModal( props.original )}
                    >
                      <i className="fa fa-trash" />
                    </Button> */}
                  </div>
                ),
              },
            ]}
          />
        </CardBox>

        <ConfirmationModal
          open={deleteModal}
          translateValues={{ name: this.entityToDelete ? `${this.entityToDelete.name || ''} ${this.entityToDelete.lastName || ''}` : '' }}
          onSubmit={this.deleteEntity}
          onClose={this.toggleDeleteModal}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( Dashboard );
