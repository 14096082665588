import { useState } from 'react';
import { useToggleAlert, useToggleLoading } from './use-config';

export const useFetch = (
  service,
  { onSuccess = async () => {}, onError = () => {}, initialData = null } = {},
) => {
  const [data, setData] = useState( initialData );
  const [error, setError] = useState( null );
  const [isLoading, setIsLoading] = useState( false );
  const { pushAlert } = useToggleAlert();
  const { setLoading } = useToggleLoading();

  const toggleError = message => pushAlert( message || 'errorOcurred', 'danger', !message );

  const fetchData = async ( values ) => {
    if ( isLoading ) return;
    setLoading( true );
    setIsLoading( true );
    const res = await service( values );
    setLoading( false );
    setIsLoading( false );
    if ( !res.ok ) {
      toggleError( res.errors );
      setError( res.errors );
      if ( onError ) onError( res );
      return;
    }
    if ( onSuccess ) await onSuccess( res );
    setData( res.data );
    setError( null );
  };
  return { fetchData, data, error, isLoading };
};

export default { useFetch };
