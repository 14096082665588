import HoverTitle from 'components/custom_v2/HoverTitle';
import moment from 'moment-timezone';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const SessionTime = ( {
  value,
  sessionTimeZone,
  format = 'DD/MM/YYYY HH:mm',
} ) => {
  if ( !value ) return '----';
  const sessionValue = Number( value );

  const localTime = moment( sessionValue ).format( format );

  const time = moment( sessionValue )
    .tz( sessionTimeZone )
    .format( format );

  if ( sessionTimeZone && localTime !== time ) {
    return (
      <HoverTitle
        id="sessiontimezone"
        placement="right"
        values={{
          sessionTimeZone: sessionTimeZone
            .split( '/' )
            .filter( ( val, i ) => i !== 0 )
            .join( ' ' ),
          time,
        }}
      >
        <span>{localTime}</span>
      </HoverTitle>
    );
  }

  return <span>{localTime}</span>;
};
