import React from 'react';
import { Form } from 'react-final-form';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import { DatePicker, TextField } from 'components/custom/FormElements';
import FeatherIcon from 'components/custom/FeatherIcon';
import { required } from 'config/InputErrors';

export const ChangelogRowForm = ( {
  onCancel,
  onSubmit = () => {},
  initialData,
} ) => (
  <Form
    initialValues={initialData}
    onSubmit={( values ) => {
      onSubmit( {
        publicationDate: values.publicationDate,
        enDescription: values.enDescription,
        esDescription: values.esDescription,
      } );
    }}
    render={( { handleSubmit } ) => (
      <TableRow>
        <TableCell className="align-top">
          <DatePicker
            marginContainer={false}
            field="publicationDate"
            validate={required}
          />
        </TableCell>
        <TableCell className="text-primary align-top ">
          <TextField
            marginContainer={false}
            field="enDescription"
            validate={required}
          />
        </TableCell>
        <TableCell className="align-top ">
          <TextField
            marginContainer={false}
            field="esDescription"
            validate={required}
          />
        </TableCell>

        <TableCell
          colSpan={2}
          className="text-primary align-top"
          style={{ width: '150px' }}
          field="esDescription"
        >
          <div className="d-flex justify-content-end align-items-center ">
            <IconButton
              onClick={onCancel}
              className="square-icon-button-danger"
            >
              <FeatherIcon icon="x" color="error.main" size="18px" />
            </IconButton>
            <div className="mx-1" />
            <IconButton
              onClick={handleSubmit}
              className="square-icon-button-primary2 "
            >
              <FeatherIcon icon="check" color="primary2.light" size="18px" />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    )}
  />
);
export default ChangelogRowForm;
