import React from 'react';
import _ from 'lodash';
import {
  Dialog,
  Slide,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  DialogContent,
} from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import IntlMessages from 'utils/IntlMessages';
import IndividualSemesterMemoService from 'services/IndividualSemesterMemo';
import KotlerHeaderFullWidthModal from 'components/custom/KotlerHeaderFullWidthModal';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';

class CompModal extends React.Component {
  state = {
    modalData: [],
    show: true,
    weekNumberMemo: 0,
  }

  getData = async ( ) => {
    const { sessionId, workGroupId, toggleAlert, toggleLoading, weekNumber } = this.props;
    this.setState( { weekNumberMemo: weekNumber } );
    toggleLoading( true );
    const params = { week: weekNumber };
    if ( weekNumber > 0 ) {
      const promise = IndividualSemesterMemoService.getIndividualSemesterMemo(
        sessionId,
        workGroupId,
        params,
      );
      toggleLoading( true );
      await promise.then( ( response ) => {
        if ( !response.ok ) return toggleAlert( response.errors );
        toggleLoading( false );
        this.setState( { modalData: response.data } );
      } );
    }
  }

  onOpen = () => {
    const { show } = this.state;
    this.setState( { show: !show } );
  }

  render() {
    const { title, onClose, open, workGroupName } = this.props;
    const { modalData, weekNumberMemo } = this.state;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        onEntered={this.getData}
      >
        <KotlerHeaderFullWidthModal
          noTranslate
          title={(
            <span>
              <IntlMessages id={title} />
              &nbsp;
              {weekNumberMemo}
            </span>
          )}
          onClose={onClose}
        />
        <DialogContent className="p-3">
          <CardBox
            styleName="px-2"
            header={<KotlerCardHeader title={workGroupName.toLowerCase().replace( ' ', '' )} />}
          >
            <div style={{ overflowX: 'auto' }}>
              <Table className="kotler-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-center" style={( _.size( modalData ) <= 4 ) ? { width: '10%' } : null}>
                    &nbsp;
                    </TableCell>
                    {_.map( modalData, ( item, index ) => (
                      <TableCell key={index} className="text-center" style={( _.size( modalData ) <= 4 ) ? { width: '20%' } : null}>

                        {item.student.name ? item.student.name : '-' }
                        &nbsp;
                        {item.student.lastName ? item.student.lastName : '-' }

                      </TableCell>
                    ) )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell><div style={{ minWidth: 150, maxWidth: 150 }}><b><IntlMessages id="understanding" /></b></div></TableCell>
                    {_.map( modalData, ( item, index ) => (
                      <TableCell key={index}>
                        {_.size( modalData ) <= 4 && (
                          <div style={{ width: '100%' }}>
                            { ( 'data' in item ) ? item.data.understanding : '-' }
                          </div>
                        )}
                        {_.size( modalData ) > 4 && (
                          <div style={{ minWidth: 400, maxWidth: 400 }}>
                            { ( 'data' in item ) ? item.data.understanding : '-' }
                          </div>
                        )}
                      </TableCell>
                    ) )}
                  </TableRow>
                  <TableRow>
                    <TableCell><div style={{ minWidth: 150, maxWidth: 150 }}><b><IntlMessages id="productStrategy" /></b></div></TableCell>
                    {_.map( modalData, ( item, index ) => (
                      <TableCell key={index}>
                        {_.size( modalData ) <= 4 && (

                          <div style={{ width: '100%' }}>
                            { ( 'data' in item ) ? item.data.productStrategy : '-' }
                          </div>
                        )}
                        {_.size( modalData ) > 4 && (
                          <div style={{ minWidth: 400, maxWidth: 400 }}>
                            { ( 'data' in item ) ? item.data.productStrategy : '-' }
                          </div>
                        )}
                      </TableCell>
                    ) )}
                  </TableRow>
                  <TableRow>
                    <TableCell><div style={{ minWidth: 150, maxWidth: 150 }}><b><IntlMessages id="customerStrategy" /></b></div></TableCell>
                    {_.map( modalData, ( item, index ) => (
                      <TableCell key={index}>
                        {_.size( modalData ) <= 4 && (

                          <div style={{ width: '100%' }}>
                            { ( 'data' in item ) ? item.data.customerStrategy : '-' }
                          </div>
                        )}
                        {_.size( modalData ) > 4 && (
                          <div style={{ minWidth: 400, maxWidth: 400 }}>
                            { ( 'data' in item ) ? item.data.customerStrategy : '-' }
                          </div>
                        )}
                      </TableCell>
                    ) )}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </CardBox>
        </DialogContent>
      </Dialog>
    );
  }
}
export default CompModal;
