import React, { useCallback, useState } from 'react';
import { DialogContent, Dialog, DialogTitle, IconButton } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import IntlHtmlMessages from 'utils/IntlHtmlMessages';
import HoverTitle from 'components/custom_v2/HoverTitle';
import FeatherIcon from 'components/custom/FeatherIcon';

const CostDetailModal = ( { title, content, children } ) => {
  const [modalOpened, setModalOpened] = useState( false );

  const toggleModal = useCallback( () => {
    setModalOpened( !modalOpened );
  }, [modalOpened] );

  return (
    <>
      <i className="list-inline-item" onClick={toggleModal}>
        <HoverTitle id="appBar.infobtn.hover" placement="left">
          <IconButton
            className="info-icon-button square-icon-buttom"
          >
            <FeatherIcon icon="info" color="secondary.dark" size="16px" />
          </IconButton>
        </HoverTitle>
      </i>
      {modalOpened
      && (
        <Dialog
          maxWidth="md"
          open={modalOpened}
          onClose={toggleModal}
          fullWidth
        >
          <DialogTitle className="border-bottom py-2">
            <IntlHtmlMessages id={title} />
            <IconButton
              aria-label="close"
              className="closeButton float-right"
              onClick={toggleModal}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
            <IntlHtmlMessages id="help.fast.guide.link" />
          </DialogTitle>
          <DialogContent>
            <Row>
              <Col>
                <div>
                  <p style={{ textAlign: 'center' }}>
                    <IntlHtmlMessages id={content} />
                  </p>
                </div>

              </Col>
            </Row>
            {children}
          </DialogContent>

        </Dialog>
      )}
    </>
  );
};

export default CostDetailModal;
