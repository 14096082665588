import {
  FETCH_CUSTOMER_ADQUISITION_COST_REQUEST,
  FETCH_CUSTOMER_ADQUISITION_COST_SUCCESS,
  FETCH_CUSTOMER_ADQUISITION_COST_FAILURE,
  CLEAR_CUSTOMER_ADQUISITION_COST,
} from '../actions/customerAdquisitionCostActions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const customerAdquisitionCostReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_CUSTOMER_ADQUISITION_COST_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case FETCH_CUSTOMER_ADQUISITION_COST_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: null,
    };
  case FETCH_CUSTOMER_ADQUISITION_COST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case CLEAR_CUSTOMER_ADQUISITION_COST:
    return {
      ...state,
      ...initialState,
    };
  default:
    return state;
  }
};

export default customerAdquisitionCostReducer;
