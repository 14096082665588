// rootSaga.js

import { all } from 'redux-saga/effects';
import marketStimateRootSaga from './MarketStimateSaga';

function* marketRootSaga() {
  yield all( [marketStimateRootSaga()] );
}

export default marketRootSaga;
