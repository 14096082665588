import React from 'react';
import LineGraphFull from 'components/StatisticalGraphs/containers/LineGraphFull';
import { useLifeTimeStore } from 'modules/Customers/hooks/lifeTimeValueStore';

export const CustomerAdcquisitionLineGraph = ( {
  gId = 1,
  graphHeight = '250px',
  filterByWorkgroupId,
} ) => {
  const { loading, error, lineGraphAdquisition } = useLifeTimeStore( {
    lineGraphAdquisition: true,
    filterByWorkgroupId,
  } );

  return (
    <LineGraphFull
      title="analytics.title.customerAcquisitionCostBAR"
      htmlId={`line_chart_cost_acquisition_${gId}`}
      height={graphHeight}
      loading={loading}
      error={error}
      titleValue="activeCustomers"
      titleCategory="Rounds"
      data={lineGraphAdquisition.data}
      seriesData={lineGraphAdquisition.seriesData}
    />
  );
};
export default CustomerAdcquisitionLineGraph;
