import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import _ from 'lodash';
import arrayMutators from 'final-form-arrays';
import { Row, Col } from 'reactstrap';
import {
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import async from 'async';

import CardBox from 'components/custom_v2/CardBox';
import {
  TextField,
  RadioButtons,
  Checkboxes,
  Checkbox,
  Select,
  // Checkbox,
} from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import {
  composeValidators,
  greaterThan,
  required,
  lessOrEqualThanValue,
} from 'config/InputErrors';
import RoundService from 'services/Round';
import CommodityService from 'services/Commodity';
import AttributeService from 'services/Attribute';
import AttributeServiceService from 'services/AttributeService';
import CommunicationService from 'services/Communication';
import CategoryService from 'services/Category';
import SessionInfoService from 'services/SessionInfo';
import BehavioralSegmentation from 'routes/App/components/User/OneToOne/BehavioralSegmentationV2';
import CampaignDetail from 'routes/App/components/User/OneToOne/Detail';
import Util from 'utils/Util';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import HoverTitle from 'components/custom_v2/HoverTitle';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/one-to-one-campaing.jpeg';
import { Link, Prompt } from 'react-router-dom';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import IconEmail from 'assets/images/kotler/icons/icon-email.png';
import IconTelemarketing from 'assets/images/kotler/icons/icon-telemarketing.png';
import IconEmailBox from 'assets/images/kotler/icons/icon-email-card.png';
import IotReportModal from './iotReportModal';

let contentTypes = [
  {
    id: 'LOYALTY',
    value: 'BUILD_LOYALTY',
  },
  {
    id: 'REFERRALS',
    value: 'GENERATE_REFERRALS',
  },
  {
    id: 'PRODUCT',
    value: 'productPromotion',
  },
  {
    id: 'SERVICE',
    value: 'servicePromotion',
  },
  {
    id: 'BUNDLE',
    value: 'bundlePromotion',
  },
];

const demographicSegmentationOptions = [
  {
    id: 'EXECUTIVES',
    value: 'Executives',
  },
  {
    id: 'PROFESSIONAL',
    value: 'Professionals',
  },
  {
    id: 'EMPLOYEES',
    value: 'Employees',
  },
  {
    id: 'STUDENTS',
    value: 'Students',
  },
  {
    id: 'HOME',
    value: 'Home',
  },
];

let behavioralSegmentations1 = [
  {
    id: 1,
    type: 'radio',
    options: [
      {
        id: 'PURCHASED',
        name: 'PURCHASED',
      },
      {
        id: 'NOT_PURCHASED',
        name: 'NOT_PURCHASED',
      },
    ],
    selectCommodity: true,
    behavioralType: 'TYPE_1',
  },
  {
    id: 2,
    type: 'radio',
    options: [
      {
        id: 'PURCHASED',
        name: 'PURCHASED',
      },
      {
        id: 'NOT_PURCHASED',
        name: 'NOT_PURCHASED',
      },
    ],
    selectCommodity: true,
    behavioralType: 'TYPE_2',
  },
];

const behavioralSegmentations2 = [
  {
    id: 3,
    type: 'checkbox',
    options: [
      {
        id: 'COMPLAINT',
        name: 'COMPLAINT',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_3',
  },
  {
    id: 4,
    type: 'checkbox',
    options: [
      {
        id: 'REMAINED_INACTIVE',
        name: 'REMAINED_INACTIVE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_4',
  },
  {
    id: 5,
    type: 'checkbox',
    options: [
      {
        id: 'VISITED_WEBSITE',
        name: 'VISITED_WEBSITE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_5',
  },
  {
    id: 6,
    type: 'checkbox',
    options: [
      {
        id: 'SUFFERED_FAILURE',
        name: 'SUFFERED_FAILURE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_6',
  },
];

const Header = ( { title } ) => (
  <div>
    <Typography
      variant="h6"
      style={{ fontWeight: 600, fontSize: 18, lineHeight: '27px' }}
      color="textPrimary"
      className="m-0 text-dark"
    >
      <IntlMessages id={title} />
    </Typography>
  </div>
);

class EditCampaign extends React.Component {
  state = {
    rounds: [],
    products: [],
    services: [],
    bundles: [],
    commodities: [],
    productAttributes: [],
    serviceAttributes: [],
    categories: [],
    activeStep: 0,
    costIndex: { value: 1 },
    detailData: {},
    confirmExitModal: false,
    iotModal: false,
  };
  canExit = false;
  goTo = '/';
  dirtyStep = [];
  dirtyFinal = false;
  steps = [
    {
      id: 1,
      label: 'name',
    },
    {
      id: 2,
      label: 'who',
    },
    /* {
      id: 3,
      label: 'campaignList',
    },
    */
    {
      id: 4,
      label: 'what',
    },
    {
      id: 5,
      label: 'how',
    },
    {
      id: 6,
      label: 'finalConfirmation',
    },
  ];
  entityId = null;
  mkmode = {};

  componentDidMount() {
    const { toggleAlert, user, match } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;

    if (
      !this.mkmode.decision.communications.informationStep.defineTarget
        .servicePromotion
    ) {
      this.steps = _.filter( this.steps, o => o.label.indexOf( 'how' ) === -1 );
    }
    if ( !this.mkmode.decision.oneToOne.who.behavior2 ) {
      behavioralSegmentations1 = _.filter(
        behavioralSegmentations1,
        o => o.behavioralType.indexOf( 'TYPE_2' ) === -1,
      );
    }
    if ( !this.mkmode.decision.oneToOne.what.servicePromotion ) {
      contentTypes = _.filter(
        contentTypes,
        o => o.value.indexOf( 'servicePromotion' ) === -1,
      );
    }
    if ( !this.mkmode.decision.oneToOne.what.bundlePromotion ) {
      contentTypes = _.filter(
        contentTypes,
        o => o.value.indexOf( 'bundlePromotion' ) === -1,
      );
    }

    this.entityId = match.params.id;
    this.getData( this.entityId );
  }
  setDirty( dirty, idStep ) {
    if ( this.dirtyStep[idStep] !== dirty ) {
      this.dirtyStep[idStep] = dirty;
      // eslint-disable-next-line max-len
      this.dirtyFinal = this.dirtyStep.reduce( ( previus, value ) => ( previus === true ? true : value ) );
    }
  }
  genderFromApiToForm = ( gender, exist ) => {
    if ( gender === 'MALE' || gender === 'FEMALE' ) return [gender];
    if ( !gender && exist ) return ['MALE', 'FEMALE'];
    return [];
  };
  toggleConfirmExitModal = () => {
    const { confirmExitModal } = this.state;
    this.setState( { confirmExitModal: !confirmExitModal } );
  };

  toggleIotModal = () => {
    const { iotModal } = this.state;
    this.setState( { iotModal: !iotModal } );
  };

  setConfirmExitModal = () => {
    const { history } = this.props;
    this.canExit = true;
    if ( this.goTo !== '' ) {
      history.push( this.goTo );
    }
  };

  getData = () => {
    const { toggleLoading, toggleAlert, user } = this.props;

    toggleLoading( true );
    async.parallel(
      {
        entity: this.getEntity,
        costIndex: this.getCostIndex,
        rounds: this.getRounds,
        commodities: this.getCommodities,
        productAttributes: this.getProductAttributes,
        serviceAttributes: this.getServiceAttributes,
        aditionalData: this.getAditionalData,
        categories: this.getCategories,
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) {
          return toggleAlert( error );
        }
        let entity = {};
        if ( this.entityId ) {
          entity = {
            ...results.entity,
            behavioralSegmentations1: _.cloneDeep( behavioralSegmentations1 ),
            behavioralSegmentations2: _.cloneDeep( behavioralSegmentations2 ),
          };
          if ( entity.contentType === 'PRODUCT' ) {
            entity.product = entity.product
              && _.find( results.commodities, { id: entity.product.id } )
              ? entity.product.id
              : null;
            entity.productFeature = [];
            if ( results.entity.productFeature ) {
              entity.productFeature.push( { id: results.entity.productFeature.id } );
            }
            if ( entity.productFeature2 ) {
              entity.productFeature.push( { id: results.entity.productFeature2.id } );
            }
          }
          if ( entity.contentType === 'SERVICE' ) {
            entity.service = entity.service
              && _.find( results.commodities, { id: entity.service.id } )
              ? entity.service.id
              : null;
            entity.serviceFeature = [];
            if ( results.entity.serviceFeature ) {
              entity.serviceFeature.push( { id: results.entity.serviceFeature.id } );
            }
            if ( entity.serviceFeature2 ) {
              entity.serviceFeature2.push( { id: results.entity.serviceFeature2.id } );
            }
          }
          if ( entity.contentType === 'BUNDLE' ) {
            entity.bundle = entity.bundle ? entity.bundle.id : null;
          }

          if ( entity.demographicSegmentation ) {
            const {
              ageFrom,
              ageTo,
              segmentation,
              gender,
            } = entity.demographicSegmentation;
            entity.demographicSegmentation.gender = this.genderFromApiToForm(
              gender,
              !!( ageFrom || ageTo || ( segmentation && segmentation.length ) ),
            );
            if ( entity.demographicSegmentation.segmentation ) {
              entity.demographicSegmentation.segmentation = _.map(
                entity.demographicSegmentation.segmentation,
                item => ( { id: item } ),
              );
            }
          }
          if ( entity.behavioralSegmentation ) {
            entity.behavioralSegmentations1 = _.map(
              entity.behavioralSegmentations1,
              ( item ) => {
                const newItem = { ...item };
                // eslint-disable-next-line max-len
                const segmentation = _.find( entity.behavioralSegmentation, { behavioralType: item.behavioralType } );
                if ( segmentation ) {
                  newItem.behavioral = segmentation.behavioral;
                  if (
                    segmentation.commodity
                    && _.find( results.commodities, { id: segmentation.commodity.id } )
                  ) {
                    newItem.commodity = segmentation.commodity.id;
                  }
                  newItem.fromMonth = segmentation.fromMonth;
                  newItem.toMonth = segmentation.toMonth;
                }
                return newItem;
              },
            );

            entity.behavioralSegmentations2 = _.map(
              entity.behavioralSegmentations2,
              ( item ) => {
                const newItem = { ...item };
                // eslint-disable-next-line max-len
                const segmentation = _.find( entity.behavioralSegmentation, { behavioralType: item.behavioralType } );
                if ( segmentation ) {
                  newItem.behavioral = [{ id: segmentation.behavioral }];
                  newItem.fromMonth = segmentation.fromMonth;
                  newItem.toMonth = segmentation.toMonth;
                }
                return newItem;
              },
            );
          }
          if ( entity.iotSegmentation ) {
            if (
              entity.iotSegmentation.product
              && _.find( results.commodities, { id: entity.iotSegmentation.product.id } )
            ) {
              entity.iotSegmentation.product = entity.iotSegmentation.product.id;
            } else entity.iotSegmentation.product = null;
          }
        }
        let defaultRent = '';
        switch ( entity.rentExternalDatabase ) {
        case 'LAPTOP':
          defaultRent = _.find( results.categories, { name: 'Laptops' } );
          entity.rentExternalDatabase = defaultRent.id;
          break;
        case 'CELULAR':
          defaultRent = _.find( results.categories, { name: 'Celular' } );
          entity.rentExternalDatabase = defaultRent.id;
          break;
        case 'TABLET':
          defaultRent = _.find( results.categories, { name: 'Tablet' } );
          entity.rentExternalDatabase = defaultRent.id;
          break;
        default:
          break;
        }
        this.setState(
          {
            ...results,
            entity,
            products:
              user.activeSession.distributionType === 'studio'
                ? _.filter( results.commodities, {
                  owner: 'PRODUCT',
                  category: 'Cellular',
                  active: true,
                } )
                : _.filter( results.commodities, {
                  owner: 'PRODUCT',
                  active: true,
                } ),
            services: _.filter( results.commodities, {
              owner: 'SERVICE',
              active: true,
            } ),
            bundles: _.filter( results.commodities, {
              owner: 'BUNDLE',
              active: true,
            } ),
            rounds: _.map( results.rounds, round => ( {
              id: round.id,
              name: round.largeName,
            } ) ),
          },
          () => this.setState( { detailData: this.entityId ? this.loadDataDetail( entity ) : {} } ),
        );
      },
    );
  };

  getEntity = ( cb ) => {
    if ( !this.entityId ) return cb();
    CommunicationService.getOneToOneCampaign(
      this.activeSession.id,
      this.entityId,
      { workgroup: this.license.workGroup.id },
    ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      return cb( null, response.data );
    } );
  };

  getCostIndex = ( cb ) => {
    // eslint-disable-next-line max-len
    CommunicationService.getOneToOneCostIndex( this.activeSession.id, { workgroup: this.license.workGroup.id } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      return cb( null, response.data );
    } );
  };

  getRounds = ( cb ) => {
    RoundService.getRoundsBySession( this.activeSession.id, {
      limit: -1,
      'filters[noRoundZero]': 'R000',
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      return cb( null, response.data.data );
    } );
  };

  getCommodities = ( cb ) => {
    CommodityService.getCommodities( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      limit: -1,
      all: 1,
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      return cb(
        null,
        response.data.data.filter(
          value => !(
            value.status
              && ( ( value.status === 'READY' && !value.active )
                || value.status === 'RD' )
          ),
        ),
      );
    } );
  };

  getProductAttributes = ( cb ) => {
    AttributeService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      cb( null, response.data.data );
    } );
  };

  getServiceAttributes = ( cb ) => {
    AttributeServiceService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      cb( null, response.data.data );
    } );
  };

  getAditionalData = ( cb ) => {
    SessionInfoService.getAditionalData( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      type: 'iot_report',
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      cb( null, response.data.data );
    } );
  };

  getCategories = ( cb ) => {
    CategoryService.getCategories( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } ).then( ( response ) => {
      if ( !response.ok ) return cb( response.errors );
      cb( null, response.data.data );
    } );
  };

  submitForm = ( data ) => {
    const { activeStep, categories } = this.state;
    const { toggleLoading, toggleAlert, history, intl } = this.props;

    if ( this.steps[activeStep].id < 6 ) return;

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      name: data.name
        .normalize( 'NFD' )
        .replace( /[\u0300-\u036f]/g, '' )
        .replace( /[^a-zA-Z0-9 ]/g, '' ),
      contentType: data.contentType,
      promotePurchasing: !!data.promotePurchasing,
      directContactMethod: data.directContactMethod,
      execution: data.execution,
      months: data.months,
      messagePerMonth: data.messagePerMonth,
      rentExternalDatabase: null,
    };

    const restExternalOptionChecked = _.find( categories, { id: data.rentExternalDatabase } );

    if ( restExternalOptionChecked !== undefined ) {
      let rentExternalValue = restExternalOptionChecked.name.toUpperCase();
      if ( rentExternalValue === 'LAPTOPS' ) rentExternalValue = 'LAPTOP';

      dataToSend.rentExternalDatabase = rentExternalValue;
    }
    if ( data.contentType === 'PRODUCT' ) {
      dataToSend.product = data.product;
      if ( data.productFeature ) {
        if ( data.productFeature.length === 1 ) {
          dataToSend.productFeature = data.productFeature[0].id;
        }
        if ( data.productFeature.length === 2 ) {
          dataToSend.productFeature = data.productFeature[0].id;
          dataToSend.productFeature2 = data.productFeature[1].id;
        }
      }
    }
    if ( data.contentType === 'SERVICE' ) {
      dataToSend.service = data.service;
      if ( data.serviceFeature ) {
        if ( data.serviceFeature.length === 1 ) {
          dataToSend.serviceFeature = data.serviceFeature[0].id;
        }
        if ( data.serviceFeature.length === 2 ) {
          dataToSend.serviceFeature = data.serviceFeature[0].id;
          dataToSend.serviceFeature2 = data.serviceFeature[1].id;
        }
      }
    }
    if ( data.contentType === 'BUNDLE' ) {
      dataToSend.bundle = data.bundle;
    }

    if ( data.demographicSegmentation ) {
      // eslint-disable-next-line max-len
      const gender = data.demographicSegmentation.gender
        && data.demographicSegmentation.gender.length === 1
        ? data.demographicSegmentation.gender[0]
        : null;
      dataToSend.demographicSegmentation = {
        gender,
        ageFrom: data.demographicSegmentation.ageFrom,
        ageTo: data.demographicSegmentation.ageTo,
        segmentation: _.map( data.demographicSegmentation.segmentation, 'id' ),
      };
    }

    if ( data.behavioralSegmentations1 || data.behavioralSegmentations2 ) {
      dataToSend.behavioralSegmentation = [];
    }
    if ( data.behavioralSegmentations1 ) {
      dataToSend.behavioralSegmentation.push(
        ..._.map( data.behavioralSegmentations1, ( item ) => {
          if ( !item.behavioral ) return;
          const newData = {
            behavioral: item.behavioral,
            behavioralType: item.behavioralType,
            commodity: item.commodity,
            fromMonth: item.fromMonth,
            toMonth: item.toMonth,
          };

          return newData;
        } ),
      );
    }
    if ( data.behavioralSegmentations2 ) {
      dataToSend.behavioralSegmentation.push(
        ..._.map( data.behavioralSegmentations2, ( item ) => {
          if ( !item.behavioral || !item.behavioral.length ) return;
          const newData = {
            behavioral: item.behavioral[0].id,
            behavioralType: item.behavioralType,
            fromMonth: item.fromMonth,
            toMonth: item.toMonth,
          };

          return newData;
        } ),
      );
    }

    dataToSend.behavioralSegmentation = _.compact(
      dataToSend.behavioralSegmentation,
    );

    if ( data.iotSegmentation ) {
      dataToSend.iotSegmentation = {
        product: data.iotSegmentation.product,
        iotFeature: data.iotSegmentation.iotFeature,
      };
    }

    if ( data.uploadFile && data.uploadFile.fileDecoded ) {
      dataToSend.uploadFile = data.uploadFile.fileDecoded;
    }

    toggleLoading( true );
    // eslint-disable-next-line react/destructuring-assignment
    CommunicationService.saveOneToOneCampaign(
      this.activeSession.id,
      dataToSend,
    ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        const messageErr = response.errors
          === 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET'
          ? intl.formatMessage( {
            id:
                  'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
          } )
          : response.errors;
        return toggleAlert( messageErr );
      }
      this.canExit = true;
      toggleAlert( 'dataSaved', 'info' );
      history.push( '/sessions/general/one-to-one' );
    } );
  };

  loadDataDetail = ( dataDetail ) => {
    const {
      products,
      services,
      bundles,
      commodities,
      rounds,
      productAttributes,
      serviceAttributes,
    } = this.state;
    const data = _.cloneDeep( dataDetail );
    if ( data.product ) {
      const product = _.find( products, { id: data.product } );
      if ( product ) data.product = product.name;
    }
    if ( data.service ) {
      const service = _.find( services, { id: data.service } );
      if ( service ) data.service = service.name;
    }
    if ( data.bundle ) {
      const bundle = _.find( bundles, { id: data.bundle } );
      if ( bundle ) data.bundle = bundle.name;
    }
    data.productFeature = _.map( data.productFeature, ( item ) => {
      const productAttribute = _.find( productAttributes, { id: item.id } );
      if ( productAttribute ) return { id: productAttribute.name };
      return item.id;
    } );
    data.serviceFeature = _.map( data.serviceFeature, ( item ) => {
      const serviceAttribute = _.find( serviceAttributes, { id: item.id } );
      if ( serviceAttribute ) return { id: serviceAttribute.name };
      return item.id;
    } );

    if ( _.get( data, 'iotSegmentation.product' ) ) {
      const product = _.find( products, { id: data.iotSegmentation.product } );
      if ( product ) data.iotSegmentation.product = product.name;
    }

    if ( data.behavioralSegmentations1 ) {
      data.behavioralSegmentations1 = _.map(
        data.behavioralSegmentations1,
        ( item ) => {
          if ( !item.behavioral ) return;
          const newData = {
            behavioral: item.behavioral,
            behavioralType: item.behavioralType,
            commodity: _.find( commodities, { id: item.commodity } ),
          };
          if ( item.date === 'afterRound' || item.date === 'beforeRound' ) {
            if ( item.date === 'afterRound' ) newData.afterRound = _.find( rounds, { id: item.round } );
            if ( item.date === 'beforeRound' ) newData.beforeRound = _.find( rounds, { id: item.round } );
          } else if ( item.date === 'exactly' ) newData.exactly = item.months;
          return newData;
        },
      );
    }
    data.behavioralSegmentations1 = _.compact( data.behavioralSegmentations1 );
    if ( data.behavioralSegmentations2 ) {
      data.behavioralSegmentations2 = _.map(
        data.behavioralSegmentations2,
        ( item ) => {
          if ( !item.behavioral || !item.behavioral.length ) return;
          const newData = {
            behavioral: item.behavioral[0].id,
            behavioralType: item.behavioralType,
          };
          if ( item.date === 'afterRound' || item.date === 'beforeRound' ) {
            if ( item.date === 'afterRound' ) newData.afterRound = _.find( rounds, { id: item.round } );
            if ( item.date === 'beforeRound' ) newData.beforeRound = _.find( rounds, { id: item.round } );
          } else if ( item.date === 'exactly' ) newData.exactly = item.months;
          return newData;
        },
      );
    }
    data.behavioralSegmentations2 = _.compact( data.behavioralSegmentations2 );
    return data;
  };

  handleNext = ( data ) => {
    const { activeStep } = this.state;

    this.setState( {
      activeStep: activeStep + 1,
      detailData: this.loadDataDetail( data ),
    } );
  };

  handleBack = ( data ) => {
    const { activeStep } = this.state;
    this.setState( {
      activeStep: activeStep - 1,
      detailData: this.loadDataDetail( data ),
    } );
  };

  getStepButtons = ( form ) => {
    const { activeStep } = this.state;
    const lastStep = activeStep >= this.steps.length - 1;

    return (
      <div className="text-center w-100 d-flex align-items-center justify-content-end h-100">
        <Button
          variant="text"
          className="kotler-button kotler-button-danger "
          component={Link}
          to="/sessions/general/one-to-one"
        >
          <IntlMessages id="cancelCreation" />
        </Button>

        <Button
          variant="outlined"
          disabled={activeStep <= 0}
          className="kotler-button "
          color="primary"
          type="submit"
          onClick={() => {
            if ( form.getState().valid ) this.handleBack( form.getState().values );
          }}
        >
          <IntlMessages id="back" />
        </Button>
        <Button
          variant={lastStep ? 'contained' : 'contained'}
          color="primary"
          className="kotler-button kotler-button-primary mx-2"
          onClick={() => {
            if ( !form.getState().valid || lastStep ) {
              form.submit();
            } else if (
              form.getState().valid
              || ( activeStep === 0 && !form.getState().errors.name )
              || ( activeStep === 1
                && !form.getState().errors.behavioralSegmentations1
                && !form.getState().errors.demographicSegmentation
                && !form.getState().errors.behavioralSegmentations2
                && !form.getState().errors.iotSegmentation )
            ) {
              this.handleNext( form.getState().values );
            }
          }}
        >
          {lastStep ? <IntlMessages id="save" /> : <IntlMessages id="next" />}
        </Button>
      </div>
    );
  };

  getCostbyDirectC = ( obj, objCost ) => {
    switch ( obj.directContactMethod ) {
    case 'EMAIL':
      return objCost.email;
    case 'TELEMARKETING':
      return objCost.telemarketing;
    case 'DIRECT_MAIL':
      return objCost.directEmail;
    default:
      return objCost.rentExternalDatabase;
    }
  };
  render() {
    const {
      entity,
      rounds,
      products,
      services,
      bundles,
      activeStep,
      detailData,
      confirmExitModal,
      iotModal,
      productAttributes,
      commodities,
      costIndex,
      aditionalData, // categories,
    } = this.state;
    const { user, intl } = this.props;
    let howAttributes = {};
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;

    if ( user.activeSession.isStudio ) {
      howAttributes = {
        execution: this.mkmode.decision.oneToOne.how.execution,
        directContactMethod: this.mkmode.decision.oneToOne.how
          .directContactMethod,
        messagePerMonth: this.mkmode.decision.oneToOne.how.msgPerMonth,
      };
    }

    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            {
              url: '/sessions/general/one-to-one',
              name: 'DecisionDashboard-1to1Campaigns',
            },
            { name: 'newCampaign' },
          ]}
        />
        <KotlerSectionHeader
          backTo="/sessions/general/one-to-one"
          title="1to1Campaigns"
          image={HeaderImage}
        />
        <Prompt
          when={!this.canExit}
          message={( location ) => {
            const { canExit, dirtyFinal } = this;
            if ( dirtyFinal && !canExit ) {
              this.goTo = location.pathname;
              this.toggleConfirmExitModal();
              return false;
            }
            return true;
          }}
        />

        <Form
          initialValues={{
            behavioralSegmentations1,
            behavioralSegmentations2,
            ...entity,
            ...howAttributes,
          }}
          validate={( values ) => {
            const errors = {};
            const behavioralSegmentations1Errors = [];
            const behavioralSegmentations2Errors = [];
            if (
              values.behavioralSegmentations1
              && values.behavioralSegmentations1.length
            ) {
              _.map( values.behavioralSegmentations1, ( item, index ) => {
                if ( !item.behavioral || !item.behavioral.length ) return;
                if (
                  !item.commodity
                  || !item.behavioral
                  || !item.fromMonth
                  || !item.toMonth
                  || parseInt( item.toMonth, 10 ) < parseInt( item.fromMonth, 10 )
                  || parseInt( item.fromMonth, 10 ) <= 0
                ) {
                  behavioralSegmentations1Errors[index] = {};
                }
                if ( !item.commodity ) {
                  behavioralSegmentations1Errors[index].commodity = 'fieldError.required';
                }

                if ( item.commodity && !item.fromMonth ) {
                  behavioralSegmentations1Errors[index].fromMonth = 'fieldError.required';
                }
                if ( item.commodity && !item.toMonth ) {
                  behavioralSegmentations1Errors[index].toMonth = 'fieldError.required';
                }
                if ( parseInt( item.toMonth, 10 ) < parseInt( item.fromMonth, 10 ) ) {
                  behavioralSegmentations1Errors[index].toMonth = 'fieldError.greaterOrEqualThanFromMonth';
                }
                if ( parseInt( item.fromMonth, 10 ) <= 0 ) {
                  behavioralSegmentations1Errors[index].fromMonth = 'fieldError.greaterThanZero';
                }
                // if ( item.date === 'exactly' && !item.months ) {
                //  behavioralSegmentations1Errors[index].months = 'fieldError.required';
                // }
              } );
              if ( behavioralSegmentations1Errors.length ) {
                errors.behavioralSegmentations1 = behavioralSegmentations1Errors;
              }
            }
            if (
              values.behavioralSegmentations2
              && values.behavioralSegmentations2.length
            ) {
              _.map( values.behavioralSegmentations2, ( item, index ) => {
                if ( !item.behavioral || !item.behavioral.length ) return;
                if (
                  !item.commodity
                  || !item.fromMonth
                  || !item.toMonth
                  || parseInt( item.toMonth, 10 ) > parseInt( item.fromMonth, 10 )
                  || parseInt( item.fromMonth, 10 ) <= 0
                ) {
                  behavioralSegmentations2Errors[index] = {};
                }

                if ( item.behavioral && !item.fromMonth ) {
                  behavioralSegmentations2Errors[index].fromMonth = 'fieldError.required';
                }
                if ( item.behavioral && !item.toMonth ) {
                  behavioralSegmentations2Errors[index].toMonth = 'fieldError.required';
                }
                if (
                  item.behavioral
                  && item.behavioral.length
                  && item.fromMonth
                  && item.toMonth
                  && parseInt( item.toMonth, 10 ) < parseInt( item.fromMonth, 10 )
                ) {
                  behavioralSegmentations2Errors[index].toMonth = 'fieldError.greaterOrEqualThanFromMonth';
                }
                if (
                  item.behavioral
                  && item.behavioral.length
                  && item.fromMonth
                  && item.toMonth
                  && parseInt( item.fromMonth, 10 ) <= 0
                ) {
                  behavioralSegmentations2Errors[index].fromMonth = 'fieldError.greaterThanZero';
                }
              } );
              if ( behavioralSegmentations2Errors.length ) {
                errors.behavioralSegmentations2 = behavioralSegmentations2Errors;
              }
            }
            if (
              values.iotSegmentation
              && values.iotSegmentation.product
              && !values.iotSegmentation.iotFeature
            ) {
              errors.iotSegmentation = { iotFeature: 'fieldError.required' };
            }
            if (
              values.execution
              && values.execution === 'DISTRIBUTE_IN_MONTH'
              && !values.months
            ) {
              errors.months = 'fieldError.required';
            }
            if (
              values.execution
              && values.execution === 'DISTRIBUTE_IN_MONTH'
              && values.months
            ) {
              if (
                parseInt( values.months, 10 ) > 6
                || parseInt( values.months, 10 ) < 0
              ) {
                errors.months = 'fieldError.OneToOneMonthDistributionValidation';
              }
            }
            // eslint-disable-next-line max-len
            if (
              values.demographicSegmentation
              && values.demographicSegmentation.ageFrom
            ) {
              if ( parseInt( values.demographicSegmentation.ageFrom, 10 ) <= 0 ) {
                const demographicSegmentation = {};
                demographicSegmentation.ageFrom = 'fieldError.greaterThanZero';
                errors.demographicSegmentation = demographicSegmentation;
              }
            }
            if ( values.productFeature && values.productFeature.length ) {
              if ( values.productFeature.length > 2 ) {
                errors.productFeature = 'fieldError.length';
              }
            }
            return errors;
          }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, form, values } ) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                className="kotler-stepper-container  flex-column-reverse  flex-md-row my-4"
              >
                <Grid item xs={12} md={7}>
                  <Stepper
                    activeStep={activeStep}
                    className="kotler-stepper bg-transparent"
                  >
                    {this.steps.map( ( step, index ) => (
                      <Step key={step.id}>
                        <StepLabel
                          className={` ${index === activeStep ? 'active' : ''}`}
                          StepIconProps={{ completed: false }}
                        >
                          <IntlMessages id={step.label} />
                        </StepLabel>
                      </Step>
                    ) )}
                  </Stepper>
                </Grid>
                <Grid item xs={12} md={5}>
                  {this.getStepButtons( form )}
                </Grid>
              </Grid>
              {this.setDirty( form.getState().dirty, this.steps[activeStep].id )}

              {this.steps[activeStep].id === 1 && (
                <>
                  {/*    <CardBox header={<Header title="campaignInfo" />}> */}
                  <Grid container>
                    <Grid item xs={12} sm={9} md={6}>
                      <TextField
                        adornRequired
                        marginContainer={false}
                        field="name"
                        label="campaignName"
                        placeholder="campaignName"
                        validate={required}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={9} m={6}>
                      <p className="kotler-text-field-footer pt-1 text-upper">
                        <IntlMessages id="campaignInfo" />
.
                        {' '}
                        <IntlMessages
                          id="maxCharacters"
                          values={{ characters: '255' }}
                        />
                      </p>
                    </Grid>
                  </Grid>
                </>
              )}
              {this.steps[activeStep].id === 2 && (
                <>
                  <Row>
                    <Col>
                      {/*  <CardBox
                        styleName=""
                        header={<Header title="campaignWhoReceiveMessage" />}
                      >
                        <p className="mb-2" style={{ fontStyle: 'italic' }}>
                          <IntlMessages id="campaignWhoHelpText" />
                        </p> */}
                      <Grid
                        container
                        className="mx-2 mb-3 mx-sm-0"
                        style={{ rowGap: '20px' }}
                      >
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CardBox
                            styleName="h-100 mb-0 kotler-card-gray mr-sm-4 mr-md-3 mr-lg-4"
                            header={(
                              <KotlerCardHeader
                                title="demographicSegmentation"
                                onDelete={() => {
                                  form.change(
                                    'demographicSegmentation.gender',
                                    null,
                                  );
                                  form.change(
                                    'demographicSegmentation.ageTo',
                                    null,
                                  );
                                  form.change(
                                    'demographicSegmentation.ageFrom',
                                    null,
                                  );
                                  form.change(
                                    'demographicSegmentation.segmentation',
                                    null,
                                  );
                                }}
                              />
                            )}
                          >
                            <Select
                              label="gender"
                              isMulti
                              marginContainer={false}
                              field="demographicSegmentation.gender"
                              labelContainerClass="row"
                              translate
                              displayBlock
                              options={[
                                {
                                  id: 'MALE',
                                  name: 'male',
                                  value: 'male',
                                },
                                {
                                  id: 'FEMALE',
                                  name: 'female',
                                  value: 'female',
                                },
                              ]}
                            />
                            <Grid
                              container
                              wrap="nowrap"
                              className="my-3"
                              spacing={1}
                            >
                              <Grid item>
                                <TextField
                                  marginContainer={false}
                                  label="age"
                                  field="demographicSegmentation.ageFrom"
                                  type="number"
                                />
                              </Grid>
                              <Grid
                                item
                                className="text-dark"
                                style={{ fontWeight: 500, marginTop: '33px' }}
                              >
                                <IntlMessages id="ageto" />
                              </Grid>
                              <Grid item style={{ marginTop: '23px' }}>
                                <TextField
                                  marginContainer={false}
                                  field="demographicSegmentation.ageTo"
                                  type="number"
                                  validate={composeValidators(
                                    greaterThan(
                                      'demographicSegmentation.ageFrom',
                                    ),
                                  )}
                                  translateValues={{ label: 'ageFrom' }}
                                />
                              </Grid>
                            </Grid>

                            <Checkboxes
                              label="segment"
                              marginContainer={false}
                              displayBlock
                              field="demographicSegmentation.segmentation"
                              labelClassName="col-md-6 mr-0"
                              labelContainerClass="row"
                              options={demographicSegmentationOptions}
                            />
                            {/*
                              <Divider className="my-3" />

                              <p style={{ fontStyle: 'italic' }}>
                              <IntlMessages id="campaignListHelpText" />
                              </p>
                              <div className="text-center mt-2">
                                  <Dropzone
                                  accept=".txt"
                                  field="uploadFile"
                                  btn={(
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className="jr-btn jr-btn-lg text-uppercase mr-0"
                                      type="button"
                                    >
                                      <IntlMessages id="upload" />
                                    </Button>
                                  )}
                                  />
                              </div>
                              */}
                          </CardBox>
                        </Grid>

                        <FieldArray name="behavioralSegmentations1">
                          {( { fields } ) => fields.map( ( behavioralSegmentationField, index ) => {
                            const behavioralSegmentation = fields.value[index];
                            if ( !behavioralSegmentation ) return null;

                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                key={behavioralSegmentation.id}
                              >
                                <BehavioralSegmentation
                                  behavioralSegmentation={
                                    behavioralSegmentation
                                  }
                                  behavioralSegmentationField={
                                    behavioralSegmentationField
                                  }
                                  commodities={
                                    user.activeSession.distributionType
                                      === 'studio'
                                      ? _.filter( commodities, { category: 'Cellular' } )
                                      : commodities
                                  }
                                  change={form.change}
                                  rounds={rounds}
                                  index={index + 1}
                                />
                              </Grid>
                            );
                          } )
                          }
                        </FieldArray>
                        {this.mkmode.decision.oneToOne.who.behavior2 && (
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CardBox
                              styleName="h-100 kotler-card-gray mr-sm-4 mr-md-3 mr-lg-4"
                              header={(
                                <KotlerCardHeader
                                  title="iotSegmentation"
                                  onDelete={() => {
                                    form.change(
                                      'iotSegmentation.product',
                                      null,
                                    );
                                    form.change(
                                      'iotSegmentation.iotFeature',
                                      null,
                                    );
                                  }}
                                />
                              )}
                            >
                              <div className="  pb-3">
                                <Select
                                  field="iotSegmentation.product"
                                  translateOptions={false}
                                  label="model"
                                  marginContainer={false}
                                  valueKey="name"
                                  options={_.filter( products, { category: 'Laptops' } )}
                                  displayBlock
                                />
                              </div>
                              <Checkboxes
                                noMulti
                                field="iotSegmentation.iotFeature"
                                valueKey="name"
                                options={[
                                  {
                                    id: 'INTENSE_USAGE',
                                    name: 'INTENSE_USAGE',
                                  },
                                  {
                                    id: 'BATTERY_ISSUE',
                                    name: 'BATTERY_ISSUE',
                                  },
                                  {
                                    id: 'PERFORMANCE_ISSUE',
                                    name: 'PERFORMANCE_ISSUE',
                                  },
                                ]}
                                displayBlock
                              />
                              <div className="text-right">
                                {/*  <Button
                                    variant="contained"
                                    color="primary"
                                    className="jr-btn jr-btn-lg"
                                    type="button"
                                    onClick={() => {
                                      this.toggleIotModal( );
                                    }}
                                  >
                                    <i className="fa fa-bars" />
                                    <IntlMessages id="Btn.iotReport" />
                                  </Button> */}
                              </div>
                            </CardBox>
                          </Grid>
                        )}
                        {this.mkmode.decision.oneToOne.who.behavior2 && (
                          <FieldArray name="behavioralSegmentations2">
                            {( { fields } ) => fields.map(
                              ( behavioralSegmentationField, index ) => {
                                const behavioralSegmentation = fields.value[index];
                                if ( !behavioralSegmentation ) return null;

                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    key={behavioralSegmentation.id}
                                  >
                                    <BehavioralSegmentation
                                      key={behavioralSegmentation.id}
                                      behavioralSegmentation={
                                        behavioralSegmentation
                                      }
                                      behavioralSegmentationField={
                                        behavioralSegmentationField
                                      }
                                      change={form.change}
                                      rounds={rounds}
                                      index={index + 3}
                                    />
                                  </Grid>
                                );
                              },
                            )
                            }
                          </FieldArray>
                        )}
                      </Grid>

                      {/*
                      </CardBox> */}
                    </Col>
                  </Row>
                  {this.getStepButtons( form )}
                </>
              )}
              {this.steps[activeStep].id === 3 && (
                <>
                  {/*
                  <Row>
                    <Col md={4} className="m-md-auto">
                      <CardBox
                        header={<Header title="campaignList" />}
                        styleName=""
                      >
                        <p><IntlMessages id="campaignListHelpText" /></p>
                        <div className="text-center mt-2">
                          <Dropzone
                            accept=".txt"
                            field="uploadFile"
                            btn={(
                              <Button
                                variant="contained"
                                color="primary"
                                className="jr-btn jr-btn-lg text-uppercase mr-0"
                                type="button"
                              >
                                <IntlMessages id="upload" />
                              </Button>
                            )}
                          />
                        </div>
                      </CardBox>
                    </Col>
                  </Row>
                  <Row className="p-3">

                    <Col md={2}>
                      <div className="text-right">
                        <b>
                          <IntlMessages id="campaignList" />
                          :
                        </b>
                      </div>
                    </Col>
                    <Col md={1}>

                      <CardBox
                        header={(
                          <div
                            className="p-2 bg-primary"
                          >
                            <h4 className="card-heading m-0 text-white">
                              <IntlMessages id="rentExternalDatabase" />
                              <i
                                style={{ float: 'right' }}
                                className="fa fa-eraser fa-1x "
                                onClick={( ) => {
                                  form.change( 'rentExternalDatabase', null );
                                }
                                }
                              />
                            </h4>
                          </div>
                        )}
                      >
                        <RadioButtons
                          field="rentExternalDatabase"
                          label="targetPeopleProduct"
                          valueKey="name"
                          options={categories}
                          displayBlock
                        />
                      </CardBox>

                      <div className="text-left">
                        {/* <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg text-uppercase mb-5 mr-0"
                          type="button"
                        >
                          <IntlMessages id="DOWNLOADDATABASE" />
                        </Button>
                        <Dropzone
                          accept=".txt"
                          field="uploadFile"
                          btn={(
                            <Button
                              variant="contained"
                              color="primary"
                              className="jr-btn jr-btn-lg text-uppercase mr-0"
                              type="button"
                            >
                              <IntlMessages id="upload" />
                            </Button>
                          )}
                        />
                      </div>

                    </Col>
                    <Col md={4}>
                      <div className="text-left">
                        <IntlMessages id="campaignListHelpText" />
                      </div>
                    </Col>
                  </Row>
                  */}
                </>
              )}

              {this.steps[activeStep].id === 4 && (
                <>
                  <Grid container style={{ rowGap: '24px' }} className="mt-3">
                    {/*   <div className="text-dark py-2 border-none">
                        <Grid container justify="space-between">
                          <Grid item >


                          </Grid>
                          <Grid item>
                            <IconButton
                              className="square-icon-button"
                              onClick={() => {
                                form.change( 'contentType', null );
                              }}
                            >
                              <img
                                style={{ float: 'right' }}
                                src={EraseIcon}
                                alt="erase"
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </div> */}
                    <Grid item xs={12} sm={9} md={12}>
                      <div>
                        <Checkboxes
                          adornRequired
                          noMulti
                          label="campaignMainObjective"
                          field="contentType"
                          marginContainer={false}
                          validate={required}
                          options={contentTypes}
                          onChange={() => {
                            form.change( 'product', null );
                            form.change( 'productFeature', null );
                            form.change( 'service', null );
                            form.change( 'serviceFeature', null );
                            form.change( 'bundle', null );
                            form.change( 'promotePurchasing', null );
                          }}
                          hovers={[
                            {
                              id:
                                'theseCampaignsToIncreaseLoyaltyCustomersWhoReceiveThemTowardsBrand',
                            },
                            {
                              id:
                                'theseCampaignsMotivateThoseWhoReceiveThemToReferNewCustomersToCompany',
                            },
                            { id: 'campaignsAimedPromotingSpecificProduct' },
                            { id: 'campaignsAimedPromotingSpecificService' },
                            { id: 'campaignsAimedPromotingSpecificBundle' },
                          ]}
                        />
                      </div>
                    </Grid>

                    {values.contentType === 'PRODUCT' && (
                      <>
                        <Grid item xs={12}>
                          <Header title="productPromotionCampaign" />
                        </Grid>

                        {this.mkmode.decision.oneToOne.what.prodAttr && (
                          <Grid item xs={12}>
                            <HoverTitle id="possibleAttributesProductToPromotedCommunication">
                              <div>
                                <Checkboxes
                                  adornRequired
                                  label="defineAttributePromote"
                                  marginContainer={false}
                                  field="productFeature"
                                  /*  labelClassName="col-md-12 mr-0" */
                                  /*  labelContainerClass="row" */
                                  valueKey="name"
                                  options={productAttributes}
                                />
                              </div>
                            </HoverTitle>
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <div>
                            <RadioButtons
                              adornRequired
                              label="selectProductPromote"
                              field="product"
                              translate={false}
                              marginContainer={false}
                              valueKey="name"
                              options={products}
                              displayBlock
                              validate={required}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <HoverTitle id="generatesCallToActionCommunication">
                            <div>
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </div>
                          </HoverTitle>
                        </Grid>
                        <Grid item xs={12}>
                          {this.getStepButtons( form )}
                        </Grid>
                      </>
                    )}

                    {values.contentType === 'SERVICE' && (
                      <>
                        <Grid item xs={12}>
                          <Header title="servicePromotionCampaign" />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButtons
                            label="selectServicePromote"
                            adornRequired
                            field="service"
                            translate={false}
                            marginContainer={false}
                            valueKey="name"
                            options={services.map( item => item )}
                            displayBlock
                            validate={required}
                          />
                        </Grid>
                        {' '}
                        <Grid item xs={12}>
                          <HoverTitle id="generatesCallToActionCommunication">
                            <div>
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </div>
                          </HoverTitle>
                        </Grid>
                        <Grid item xs={12}>
                          {this.getStepButtons( form )}
                        </Grid>
                      </>
                    )}

                    {values.contentType === 'BUNDLE' && (
                      <>
                        <Grid item xs={12}>
                          <Header title="bundlePromotionCampaign" />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButtons
                            label="selectBundlePromote"
                            field="bundle"
                            translate={false}
                            marginContainer={false}
                            valueKey="name"
                            options={bundles}
                            displayBlock
                            validate={required}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <HoverTitle id="generatesCallToActionCommunication">
                            <div>
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </div>
                          </HoverTitle>
                        </Grid>
                        <Grid item xs={12}>
                          {this.getStepButtons( form )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}

              {this.steps[activeStep].id === 5 && (
                <>
                  <Grid container className="justify-content-md-between">
                    <Grid item xs={12} sm={6} md={5}>
                      <CardBox
                        header={(
                          <KotlerCardHeader
                            minHeight="0px"
                            className="p-0"
                            title="selectDirectContactMethod"
                            onDelete={() => {
                              form.change( 'directContactMethod', null );
                            }}
                          />
                        )}
                        styleName="border-0"
                      >
                        <div className="mx2">
                          <Checkboxes
                            adornRequired
                            noMulti
                            label="contact"
                            field="directContactMethod"
                            marginContainer={false}
                            valueKey="name"
                            displayBlock
                            validate={required}
                            disabled={
                              this.mkmode.decision.oneToOne.how.fieldDisabled
                            }
                            translate={false}
                            options={[
                              {
                                id: 'EMAIL',
                                name: (
                                  <span>
                                    <img
                                      className="pr-1"
                                      alt="email"
                                      src={IconEmail}
                                    />
                                    <IntlMessages id="EMAIL" />
                                  </span>
                                ),
                              },
                              {
                                id: 'DIRECT_MAIL',
                                name: (
                                  <span>
                                    <img
                                      className="pr-1"
                                      alt="DIRECT MAIL"
                                      src={IconEmailBox}
                                    />
                                    <IntlMessages id="DIRECT_MAIL" />
                                  </span>
                                ),
                              },
                              {
                                id: 'TELEMARKETING',
                                name: (
                                  <span>
                                    <img
                                      className="pr-1"
                                      alt="TELEMARKETING"
                                      src={IconTelemarketing}
                                    />
                                    <IntlMessages id="TELEMARKETING" />
                                  </span>
                                ),
                              },
                            ]}
                          />
                        </div>
                      </CardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5}>
                      <CardBox
                        header={(
                          <KotlerCardHeader
                            minHeight="0px"
                            className="p-0"
                            title="campaignDateExecuted"
                            onDelete={() => {
                              form.change( 'execution', null );
                              form.change( 'months', null );
                            }}
                          />
                        )}
                        styleName="border-0 px-2"
                      >
                        <Grid container>
                          <Grid item md="auto">
                            <div className="mx2 mb-4">
                              <Checkboxes
                                adornRequired
                                noMulti
                                label="campaignDateExecuted"
                                field="execution"
                                marginContainer={false}
                                valueKey="name"
                                validate={required}
                                disabled={
                                  this.mkmode.decision.oneToOne.how
                                    .fieldDisabled
                                }
                                options={[
                                  {
                                    id: 'THIS_MONTH',
                                    name: 'THIS_MONTH',
                                  },
                                  {
                                    id: 'DISTRIBUTE_IN_MONTH',
                                    name: 'DISTRIBUTE_IN_MONTH',
                                  },
                                  {
                                    id: 'PERMANENT_RUNNING',
                                    name: 'PERMANENT_RUNNING',
                                  },
                                ]}
                                onChange={( value ) => {
                                  if (
                                    ( !value
                                      || value !== 'DISTRIBUTE_IN_MONTH' )
                                    && values.months
                                  ) {
                                    form.change( 'months', null );
                                  }
                                }}
                                hovers={[
                                  {
                                    id:
                                      'allcontactsOnSelectedDatabaseDoneCurrentMonth',
                                  },
                                  {
                                    id:
                                      'contactsOnSelectedDatabaseDistributedOver3months',
                                  },
                                  {
                                    id:
                                      'everyMonthCommunicationSentAutomaticallyToThoseCustomers',
                                  },
                                ]}
                                displayBlock
                              />
                            </div>
                          </Grid>
                          {values.execution === 'DISTRIBUTE_IN_MONTH' && (
                            <Grid item xs={12} md={8}>
                              <Select
                                marginContainer={false}
                                translateOptions={false}
                                field="months"
                                options={[
                                  /*    {
                                  id: '0',
                                  value: '0',
                                  name: `0 ${intl.formatMessage( { id: 'months' } )}`,
                                }, */
                                  {
                                    id: '1',
                                    value: '1',
                                    name: `1 ${intl.formatMessage( { id: 'months' } )}`,
                                  },
                                  {
                                    id: '2',
                                    value: '2',
                                    name: `2 ${intl.formatMessage( { id: 'months' } )}`,
                                  },
                                  {
                                    id: '3',
                                    value: '3',
                                    name: `3 ${intl.formatMessage( { id: 'months' } )}`,
                                  },
                                  {
                                    id: '4',
                                    value: '4',
                                    name: `4 ${intl.formatMessage( { id: 'months' } )}`,
                                  },
                                  {
                                    id: '5',
                                    value: '5',
                                    name: `5 ${intl.formatMessage( { id: 'months' } )}`,
                                  },
                                  {
                                    id: '6',
                                    value: '6',
                                    name: `6 ${intl.formatMessage( { id: 'months' } )}`,
                                  },
                                ]}
                                disabled={
                                  values.execution !== 'DISTRIBUTE_IN_MONTH'
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </CardBox>
                    </Grid>

                    <Grid item xs={12} sm={12} md={5}>
                      <CardBox
                        header={(
                          <KotlerCardHeader
                            minHeight="0px"
                            className="p-0"
                            title="messagesToSend"
                            onDelete={() => {
                              form.change( 'messagePerMonth', null );
                            }}
                          />
                        )}
                        styleName="border-0"
                      >
                        {/*  <p className="mb-2" style={{ fontStyle: 'italic' }}>
                          <IntlMessages id="messagesToSendHelpText" />
                        </p> */}
                        <Grid container>
                          <Grid item xs={12}>
                            <HoverTitle
                              id="itSetsMaximumNumberContactsMadePerMonthCampaign"
                              placement="left"
                            >
                              <TextField
                                adornRequired
                                label="maximumMessagesMonth"
                                field="messagePerMonth"
                                type="number"
                                marginContainer={false}
                                disabled={
                                  this.mkmode.decision.oneToOne.how
                                    .fieldDisabled
                                }
                                // eslint-disable-next-line no-sequences
                                validate={composeValidators(
                                  required,
                                  lessOrEqualThanValue( 10000000 ),
                                )}
                                translateValues={{ value: 0, value2: 10000000 }}
                              />
                            </HoverTitle>
                          </Grid>
                          <Grid item xs={12}>
                            <p className="kotler-text-field-footer pt-1 text-upper">
                              <IntlMessages id="maximumEstimatedCostMonth" />
                              {': '}
                              {form.getState().values.messagePerMonth
                                ? Util.formatCurrency(
                                  Number(
                                    form.getState().values.messagePerMonth,
                                  )
                                      * this.getCostbyDirectC(
                                        form.getState().values,
                                        costIndex,
                                      ),
                                )
                                : '$0'}
                            </p>
                          </Grid>
                        </Grid>
                      </CardBox>
                    </Grid>
                    <Grid item xs={12}>
                      {this.getStepButtons( form )}
                    </Grid>
                  </Grid>
                </>
              )}

              {this.steps[activeStep].id === 6 && (
                <Grid
                  container
                  spacing={1}
                  className="justify-content-center justify-content-sm-start mt-3"
                >
                  <Grid item>
                    <CampaignDetail sumary data={detailData} />
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12} sm={8} md={6} lg={5}>
                    <CardBox styleName="px-4" disableHeader>
                      <span
                        className="text-dark "
                        style={{ fontSize: '18px', fontWeight: 600 }}
                      >
                        <IntlMessages id="totalMonthlyCost" />
:
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {form.getState().values.messagePerMonth
                          ? Util.formatCurrency(
                            Number( form.getState().values.messagePerMonth )
                                * this.getCostbyDirectC(
                                  form.getState().values,
                                  costIndex,
                                ),
                          )
                          : '$0'}
                      </span>
                    </CardBox>
                  </Grid>
                </Grid>
                /*    <Row>
                  <Col md={4} className="m-md-auto">
                    <CardBox
                      header={<Header title="finalConfirmation" />}
                      styleName=""
                    >
                      <p>
                        <IntlMessages id="approveCampaign" />
                      </p>
                      <div className="text-center mt-2">
                        <Button
                          variant="contained"
                          className="jr-btn jr-btn-lg bg-blue-grey text-white"
                          type="submit"
                          onClick={() => {
                            if (form.getState().valid)
                              this.handleBack(form.getState().values)
                          }}
                        >
                          <i className="fa fa-angle-double-left" />
                          <IntlMessages id="back" />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg"
                          type="submit"
                        >
                          <i className="fa fa-save" />
                          <IntlMessages id="saveBtn" />
                        </Button>
                      </div>
                    </CardBox>
                  </Col>
                </Row> */
              )}
            </form>
          )}
        />
        <ConfirmationDialog
          open={confirmExitModal}
          title="confirmExitWithOutSavingButton"
          subTitle="subtitleExitWithoutSaving"
          confirmBtnText="yesModalBtn"
          closeBtnText="cancelBtn"
          onSubmit={() => {
            this.toggleConfirmExitModal();
            this.setConfirmExitModal();
          }}
          onClose={this.toggleConfirmExitModal}
        />
        <IotReportModal
          open={iotModal}
          onClose={this.toggleIotModal}
          title="iotReport"
          data={aditionalData}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( EditCampaign ) );
