import {  useActiveSessionQuery } from 'modules/sessions';
import React, { useMemo } from 'react';
import { capitalizeFirstLetter } from 'utils/Util';

const TextEnviroment = ( { isAdmin } ) => {
  const { isEnterprise, isNow, isStudio } = useActiveSessionQuery();

  const distributionName = useMemo( () => {
    if ( isAdmin ) return 'Admin';
    if ( isNow ) return 'Now';
    if ( isStudio ) return 'Studio';
    if ( isEnterprise ) return 'Advanced';
    return '';
  }, [isEnterprise, isNow, isStudio, isAdmin] );

  return <>{distributionName && capitalizeFirstLetter( distributionName )}</>;
};

export default TextEnviroment;
