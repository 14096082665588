import { takeEvery, put, call } from 'redux-saga/effects';
import { getMarketProductsHistorical } from 'modules/Products/ProductServices';
import {
  FETCH_MARKET_PRODUCTS_REQUEST,
  fetchMarketProductsFailure,
  fetchMarketProductsSuccess,
} from '../actions/marketProductsActions';

function* fetchMarketProductsSaga( action ) {
  const {
    sessionId,
    workgroupId,
    categoryId,
    params = { limit: -1 },
  } = action.payload;
  try {
    const marketProductsData = yield call(
      getMarketProductsHistorical,
      sessionId,
      workgroupId,
      categoryId,
      params,
    );
    yield put(
      fetchMarketProductsSuccess( { categoryId, data: marketProductsData } ),
    );
  } catch ( error ) {
    yield put( fetchMarketProductsFailure( { categoryId, error } ) );
  }
}

export default function* marketProductsRootSaga() {
  yield takeEvery( FETCH_MARKET_PRODUCTS_REQUEST, fetchMarketProductsSaga );
}
