import { Grid, Typography } from "@material-ui/core";
import IconCard from "components/custom_v2/IconCard";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IntlMessages from "utils/IntlMessages";
import NavigationOptions from "constants/NavigationOptions";

const ProfessorDashboardOptions = ({ user, activeStrategy, mkmode }) => {
  const [options, setOptions] = useState(null);
  useEffect(() => {
    if (user && user.activeSession) {
        setOptions(
          NavigationOptions({
            user:user,
            professor:true
          })
        );
  
      
    }
  }, [user, user.activeSession, activeStrategy, mkmode]);

  return options ? (
    <>
  
  <div>
              <Typography variant="h6" color="textSecondary" className="text-center icon-card-section-title">
                <IntlMessages id="informationDashboard" />
              </Typography>
            <ul className="list-icon-cards list-information">
            {options.information && options.information.map((option) => (
              <li  key={option.title}>
                <IconCard {...option} />
              </li>
            ))}
            </ul>
            </div>
       
    
<div className="container-activity-decision">
          <Grid container className="className='d-flex justify-content-center ">
          <Grid item xs={12} >
              <Typography variant="h6" color="textSecondary" className="text-center icon-card-section-title">
                <IntlMessages id="activityDashboard" />
              </Typography>
            </Grid>
            {options.activity && options.activity.map((option) => (
              <Grid  key={option.title} >
                <IconCard {...option} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary" className="text-center icon-card-section-title mt-sm-5" >
                <IntlMessages id="decisionDashboard" />
              </Typography>
            </Grid>
            {options.desicion && options.desicion.map((option) => (
              <Grid item  key={option.title}>
                <IconCard {...option} />
              </Grid>
            ))}
          </Grid>
      </div>
  
   
    </>
  ) : null;
};
export default connect(({ user }) => ({
  user,
}))(ProfessorDashboardOptions);
