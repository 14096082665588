import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = { open: false, showedList: [] };

export const { Types, Creators: AchievementActions } = createActions( {
  setOpen: ['open'],
  setShowed: ['sessionId'],
  reset: [],
} );

const setOpen = ( state, { open } ) => ( {
  ...state,
  open,
} );
const setShowed = ( state, { sessionId } ) => {
  if ( state.showedList.includes( sessionId ) ) return state;
  const showedList = [...state.showedList, sessionId];
  return {
    ...state,
    showedList,
  };
};
const reset = () => ( { ...INITIAL_STATE } );
const HANDLERS = {
  [Types.SET_OPEN]: setOpen,
  [Types.SET_SHOWED]: setShowed,
  [Types.RESET]: reset,
};

export const reducer = createReducer( INITIAL_STATE, HANDLERS );
