/* eslint-disable max-len */
import React, { Fragment } from 'react';
import {
  Dialog,
  Slide,
  IconButton,
  Typography,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  DialogContent,
} from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';
import { Close } from '@material-ui/icons';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';

const MarketBudgetExecutionModal = ( { open, onClose, data, title, marketingBudget, groupBudget } ) => (
  <Dialog
    open={open}
    onClose={onClose}
    TransitionComponent={Slide}
    fullWidth
    maxWidth="md"
    TransitionProps={{ direction: 'down' }}
  >

    <DialogTitle className=" px-2  ml-1">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" style={{ fontWeight: 600, fontSize: 21 }} color="textPrimary">
          {title && <IntlMessages id={title} />}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className="square-icon-button"
            onClick={onClose}
            style={!title ? { position: 'absolute', top: 20, right: 20 } : {}}
          >
            <Close className="text-dark" />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
    {/*  <AppBar style={{ position: 'sticky' }}>
      <Toolbar>
        <IconButton onClick={onClose} aria-label="Close" className="text-white">
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          style={{ flex: 1 }}
        >

        </Typography>
      </Toolbar>
    </AppBar> */}
    <DialogContent>

      <CardBox styleName="px-3" header={<KotlerCardHeader minHeight="0px" title="marketingBudgetExplication" />}>
        <Grid container className="pl-2">
          <Grid item xs={12}>
            <h5><IntlMessages id="marketingBudgetExplication1" /></h5>
            <h5><IntlMessages id="marketingBudgetExplication2" /></h5>
            <h5><IntlMessages id="marketingBudgetExplication3" /></h5>
            <h5><IntlMessages id="marketingBudgetExplication4" /></h5>

          </Grid>
          <Grid item xs={12}>
            <h5><IntlMessages id="marketingBudgetExplication5" /></h5>
            <h5><IntlMessages id="marketingBudgetExplication6" /></h5>
            <h5><IntlMessages id="marketingBudgetExplication7" /></h5>

          </Grid>
        </Grid>
      </CardBox>
      <CardBox styleName="px-3" header={<div />}>
        <Table className="kotler-table">
          <TableHead>
            <TableRow>
              <TableCell><IntlMessages id="concept" /></TableCell>
              <TableCell align="right"><IntlMessages id="investedCurrentMonth" /></TableCell>
              <TableCell align="right"><IntlMessages id="investedActualYear" /></TableCell>
              <TableCell align="right"><IntlMessages id="commitedThisYear" /></TableCell>
              <TableCell align="right"><IntlMessages id="total" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data !== undefined
                    && (
                      <Fragment>
                        <TableRow>
                          <TableCell><IntlMessages id="cem" /></TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.cxCurrent )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.cxInvested )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.cxCommitted )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.cxInvested + data.cxCommitted )}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><IntlMessages id="pontPurchase" /></TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.popCurrent )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.popInvested )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.popCommitted )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.popInvested + data.popCommitted )}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><IntlMessages id="massMediaCampaigns" /></TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.massMediaCampCurrent )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.massMediaCampInvested )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.massMediaCampCommitted )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.massMediaCampInvested + data.massMediaCampCommitted )}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><IntlMessages id="directMarketingCampaigns" /></TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.directMarketingCampCurrent )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.directMarketingCampInvested )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.directMarketingCampCommitted )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.directMarketingCampInvested + data.directMarketingCampCommitted )}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><IntlMessages id="marketingBudge.research" /></TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.researchCurrent )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.researchInvested )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.researchCommitted )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.researchInvested + data.researchCommitted )}</TableCell>
                        </TableRow>
                        <TableRow className="subtitle">
                          <TableCell><IntlMessages id="balance.totals" /></TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.cxCurrent + data.popCurrent + data.massMediaCampCurrent + data.directMarketingCampCurrent + data.researchCurrent )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.cxInvested + data.popInvested + data.massMediaCampInvested + data.directMarketingCampInvested + data.researchInvested )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.cxCommitted + data.popCommitted + data.massMediaCampCommitted + data.directMarketingCampCommitted + data.researchCommitted )}</TableCell>
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( data.cxInvested + data.cxCommitted + data.popInvested + data.popCommitted + data.massMediaCampInvested + data.massMediaCampCommitted + data.directMarketingCampInvested + data.directMarketingCampCommitted + data.researchInvested + data.researchCommitted )}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><IntlMessages id="annualBudget" /></TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell align="right">{Util.formatCurrencyNoDecimals( marketingBudget )}</TableCell>
                        </TableRow>
                        <TableRow className="subtitle">
                          <TableCell className="subtitle"><IntlMessages id="remainingBalance" /></TableCell>
                          <TableCell align="right" className="subtitle" />
                          <TableCell align="right" className="subtitle">{Util.formatCurrencyNoDecimals( groupBudget )}</TableCell>
                          <TableCell align="right" className="subtitle" />
                          <TableCell align="right" className="subtitle">{Util.formatCurrencyNoDecimals( marketingBudget - ( data.cxInvested + data.cxCommitted + data.popInvested + data.popCommitted + data.massMediaCampInvested + data.massMediaCampCommitted + data.directMarketingCampInvested + data.directMarketingCampCommitted + data.researchInvested + data.researchCommitted ) )}</TableCell>
                        </TableRow>
                      </Fragment>
                    ) }
          </TableBody>
        </Table>
      </CardBox>
    </DialogContent>
  </Dialog>
);

export default MarketBudgetExecutionModal;
