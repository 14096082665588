import {
  FETCH_CUSTOMER_EVOLUTION_REQUEST,
  FETCH_CUSTOMER_EVOLUTION_SUCCESS,
  FETCH_CUSTOMER_EVOLUTION_FAILURE,
} from '../actions/customerEvolutionActions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const customerEvolutionReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_CUSTOMER_EVOLUTION_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case FETCH_CUSTOMER_EVOLUTION_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: null,
    };
  case FETCH_CUSTOMER_EVOLUTION_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  default:
    return state;
  }
};

export default customerEvolutionReducer;
