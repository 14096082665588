import React, { Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import { formatRoundName } from 'helpers/IntlHelpers';
import getNewsMock from '../mocks/getNews.mock';

const LiveNewsList = ( {
  newsList = getNewsMock.data,
  locale,
  onClickNotice = ( value ) => {
    // eslint-disable-next-line no-console
    console.log( value );
  },
  indexSelected = 0,
} ) => (
  <List
    className="kotler-live-news-list"
    subheader={<li style={{ color: 'red' }} />}
  >
    {newsList.map( ( notice, index ) => (
      <Fragment key={notice.id}>
        <Divider key={`${notice.id}-top-divider`} />
        <ListItem
          button
          key={notice.id}
          selected={index === indexSelected}
          className="d-flex flex-column"
          onClick={() => {
            onClickNotice( index );
          }}
        >
          <h5 className="title">
            Art.
            {` ${newsList.length - index} > ${formatRoundName(
              notice.currentRound.shorName,
            )} `}
          </h5>
          <ListItemText
            primary={`${notice.title[locale.locale] || notice.title.en}`}
          />
        </ListItem>
        <Divider key={`${notice.id}-bottom-divider`} />
      </Fragment>
    ) )}
  </List>
);

const mapStateToProps = ( { settings } ) => ( { locale: settings.locale } );

export default connect( mapStateToProps )( LiveNewsList );
