import React from 'react';
import { Field } from 'react-final-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel
} from '@material-ui/core';
import _ from 'lodash';
import classNames from 'classnames';
import IntlMessages from 'utils/IntlMessages';
import { Check } from '@material-ui/icons';
import HoverTitle from 'components/custom_v2/HoverTitle';

export default ({
  containerClass, displayBlock, field, idKey, label, onBlur, labelClassName, labelContainerClass, noMulti, noTranslateFormLabel = false, adornRequired = false, hovers,
  onChange, options, valueKey, marginContainer = true, validate, labelPlacement, translate = true, translateValues, ...props
}) => {
  const fieldId = (fieldName, index) => `${fieldName}_${index}`;
  const idKey2 = idKey || 'id';
  const valueKey2 = valueKey || 'value';

  return !noMulti ? (
    <Field name={field} validate={validate}>
      {({ meta, input }) => (
        <FormControl error={meta.touched && !!meta.error} className={classNames("kotler-check-box-form-control", containerClass, {
          'mb-4': marginContainer
        })}>
          {label && <InputLabel className="kotler-check-boxes-principal-label">{noTranslateFormLabel ? label : <IntlMessages id={label} />}{adornRequired && <span className="text-danger">*</span>}</InputLabel>}

          <div className={classNames({ 'mt-2': label }, labelContainerClass)}>
            {_.map(options, (option, index) => (
              <HoverTitle {...(hovers && hovers[index] ? hovers[index] : {})} key={index}>
                <FormControlLabel
                  key={index}
                  labelPlacement={labelPlacement}
                  className={classNames({ 'd-flex flex-row': displayBlock }, labelClassName)}
                  id={fieldId(field, index)}
                  label={option[valueKey2] && translate ? <IntlMessages id={option[valueKey2]} /> : option[valueKey2]}
                  control={(
                    <Checkbox
                      className='kotler-check-box'
                      checkedIcon={<Check style={{ color: "#fff" }} />}
                      color="primary"
                      disabled={!!option.disabled }
                      {...props}
                      checked={!!_.find(input.value, { id: option[idKey2] }) || false}
                      value={option[idKey2].toString()}
                      onChange={(e) => {
                        const values = _.cloneDeep(input.value) || [];

                        if (e.target.checked) {
                          values.push(option);
                        } else {
                          const unCheckedIndex = _.findIndex(
                            input.value, item => item[idKey2].toString() === e.target.value,
                          );
                          values.splice(unCheckedIndex, 1);
                        }
                        input.onChange(values);
                        if (onChange) onChange(values);
                      }}
                    />

                  )}
                />
                </HoverTitle>
            ))}
          </div>
          {meta.touched && meta.error
            && (
              <FormHelperText>
                <IntlMessages id={meta.error} values={translateValues} />
              </FormHelperText>
            )}
        </FormControl>
      )}
    </Field>
  ) :
    (
      <Field name={field} validate={validate}>
        {({ meta, input }) => (
          <FormControl error={meta.touched && !!meta.error} className={classNames("kotler-check-box-form-control", containerClass, {
            'mb-4': marginContainer
          })}>
            {label && <InputLabel disableAnimation className="kotler-check-boxes-principal-label">{noTranslateFormLabel ? label : <IntlMessages id={label} />}{adornRequired && <span className="text-danger">*</span>}</InputLabel>}

            <div className={classNames({ 'mt-2': label }, labelContainerClass)}>
              {_.map(options, (option, index) => (
                <HoverTitle  {...(hovers && hovers[index] ? hovers[index] : {})} key={index}>
                  <FormControlLabel
                    key={index}
                    labelPlacement={labelPlacement}
                    className={classNames({ 'd-flex flex-row': displayBlock }, labelClassName)}
                    id={fieldId(field, index)}
                    label={option[valueKey2] && translate ? <IntlMessages id={option[valueKey2]} /> : option[valueKey2]}
                    control={(

                      <Checkbox
                        className='kotler-check-box'
                        checkedIcon={<Check style={{ color: "#fff" }} />}
                        color="primary"
                        disabled={option.disabled ? true : false}
                        {...props}
                        checked={input.value === option[idKey2]}
                        value={option[idKey2].toString()}
                        onChange={(e) => {
                          const newValue = input.value === option[idKey2] ? null : option[idKey2];
                          input.onChange(newValue);
                          if (onChange) onChange(newValue);
                        }}
                      />

                    )}
                  /></HoverTitle>
              ))}
            </div>
            {meta.touched && meta.error
              && (
                <FormHelperText>
                  <IntlMessages id={meta.error} values={translateValues} />
                </FormHelperText>
              )}
          </FormControl>
        )}
      </Field>

    )

};
