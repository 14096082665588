import React from 'react';
import LanguageItem from './LanguageItem';
import languageData from './data';
import SettingsActions from 'store/reducers/Settings'
import { connect } from 'react-redux';

const LanguageSwitcher = ({ switchLanguage, handleRequestClose,locale }) => (
      languageData.map((language, index) => (
        <LanguageItem
        active={locale.locale === language.locale}
          key={index}
          language={language}
          handleRequestClose={handleRequestClose}
          switchLanguage={switchLanguage}
        />
      ))
);

export default connect(({settings})=>({locale:settings.locale}), {
  switchLanguage: SettingsActions.switchLanguage
})(LanguageSwitcher);
