import { getHttpRequest } from 'utils/ApiPetitionhandles';

// const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

export async function getCustomerInformation( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/customer/information`;
  return getHttpRequest( url, params );
}

export async function getCustomerEvolution( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/customer/evolution`;
  return getHttpRequest( url, params );
}
export async function getCustomerRetention( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/customer/retention/rate`;
  return getHttpRequest( url, params );
}
export async function getCustomerAvgRevenue( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/customer/average/revenue`;
  return getHttpRequest( url, params );
}
export async function getTotalCustomer( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/customer/total/number`;
  return getHttpRequest( url, params );
}
export async function getCustomerLifetimeValue( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/customer-lifetime-value/all-workgroup`;
  return getHttpRequest( url, params );
}
export async function getCustomerAdquisitionCost( sessionId, params ) {
  const url = `${endpoint2}/${sessionId}/customer-acquisition-cost/all-workgroup`;
  return getHttpRequest( url, params );
}
