import * as z from 'zod';
import { SessionCreationStatusSchema, SessionStatusSchema } from './session-statuses-schemas';

export const StatusSchema = z.enum( [
  'CLOSED',
  'PENDING',
  'IN_PROGRESS',
  'CANCELLED',
] );

export const TypeDurationSchema = z.enum( ['days'] );

export const CurrentRoundSchema = z.object( {
  id: z.string(),
  name: z.string(),
  nameD: z.string(),
  shortName: z.string(),
  largeName: z.string(),
  status: StatusSchema,
  endDate: z.string(),
  startDateInMonth: z.string(),
  endDateInMonth: z.string(),
  number: z.number(),
  inCrisis: z.boolean(),
} );

export const SupervisorSchema = z.object( {
  id: z.string(),
  name: z.string(),
  lastName: z.string(),
} );

export const DefineTargetSchema = z.object( {
  servicePromotion: z.boolean(),
  bundlePromotion: z.boolean(),
  productRegistration: z.boolean(),
} );

export const InternetStepSchema = z.object( {
  retargeting: z.boolean(),
  ia: z.boolean(),
} );

export const PromotionStepSchema = z.object( { prodAttr: z.boolean() } );

export const PurpleHowSchema = z.object( {
  fieldDisabled: z.boolean(),
  directContactMethod: z.string(),
  msgPerMonth: z.string(),
  execution: z.string(),
} );

export const WhatSchema = z.object( {
  servicePromotion: z.boolean(),
  bundlePromotion: z.boolean(),
  prodAttr: z.boolean(),
} );

export const WhoSchema = z.object( {
  behavior2: z.boolean(),
  iot: z.boolean(),
} );

export const PriceSchema = z.object( {
  celular: z.boolean(),
  laptop: z.boolean(),
  tablet: z.boolean(),
} );

export const StrategySchema = z.object( {
  initialStrategicPlan: z.boolean(),
  finalStrategicReport: z.boolean(),
} );

export const ProfitAndLossSchema = z.object( {
  revenuesFromServ: z.boolean(),
  revenuesFromBundlesSales: z.boolean(),
  costOfServicesSold: z.boolean(),
  costOfBundlesSold: z.boolean(),
  comparison: z.boolean(),
  results: z.boolean().optional(),
} );

export const CustomerSchema = z.object( {
  registeredCustomerBase: z.boolean(),
  evolutionCustomerBase: z.boolean(),
} );

export const MarketSchema = z.object( {
  laptop: z.boolean(),
  tablet: z.boolean(),
  bundle: z.boolean(),
} );

export const ResearchSchema = z.object( {
  mk001: z.boolean(),
  mk002: z.boolean(),
  mk003: PriceSchema,
  mk004: z.boolean(),
  mk006: z.boolean().optional(),
} );

export const EntitySchema = z.object( {
  avatarPath: z.string(),
  usedLicenses: z.number(),
  unusedLicenses: z.number(),
  id: z.string(),
  name: z.string(),
  sessions: z.array( z.any() ),
  country: z.string(),
  email: z.string(),
  address: z.string().optional(),
  availableLicenses: z.number(),
  minLicensesAvailable: z.number(),
  avatar: z.string(),
  purchaseLicenses: z.array( z.any() ),
  active: z.boolean(),
  contactName: z.string().optional(),
  contactEmail: z.string().optional(),
  status: z.string().optional(),
} );

export const FluffyCompanySchema = z.object( {
  profitAndLoss: ProfitAndLossSchema,
  balance: z.boolean(),
} );

export const RoundSchema = z.object( {
  duration: z.number(),
  id: z.string(),
  name: z.string(),
  status: StatusSchema,
  typeDuration: TypeDurationSchema,
  startDate: z.string(),
  endDate: z.string(),
  workGroupSales: z.array( z.any() ),
  productions: z.array( z.any() ),
  reports: z.array( z.any() ),
  studyMarkets: z.array( z.any() ),
  variableData: z.array( z.any() ),
  serviceRounds: z.array( z.any() ),
  processed: z.boolean(),
  processAt: z.string().optional(),
  manualClosing: z.boolean(),
  inCrisis: z.boolean(),
} );

export const FluffyHowSchema = z.object( {
  fieldDisabled: z.boolean(),
  directContactMethod: z.string(),
  msgPerMonth: z.number(),
  execution: z.string(),
} );

export const TipSchema = z.object( {
  id: z.string(),
  title: z.string(),
  titleEs: z.string(),
  content: z.string(),
  contentEs: z.string(),
  first: z.boolean(),
  last: z.boolean(),
} );

export const VersionSchema = z.object( {
  id: z.string(),
  version: z.string(),
  active: z.boolean(),
} );

export const StockSchema = z.object( {
  stock: z.number(),
  value: z.number(),
} );

export const WorkingDaySchema = z.object( {
  id: z.number(),
  date: z.string(),
} );

export const InformationStepSchema = z.object( { defineTarget: DefineTargetSchema } );

export const PurpleOneToOneSchema = z.object( {
  who: WhoSchema,
  what: WhatSchema,
  how: PurpleHowSchema,
} );

export const PurpleCompanySchema = z.object( { profitAndLoss: ProfitAndLossSchema } );

export const NowFieldsInformationSchema = z.object( {
  company: FluffyCompanySchema,
  products: z.boolean(),
  market: z.boolean(),
  research: ResearchSchema,
  customer: CustomerSchema,
} );

export const FluffyOneToOneSchema = z.object( {
  who: WhoSchema,
  what: WhatSchema,
  how: FluffyHowSchema,
} );

export const WorkGroupSchema = z.object( {
  stock: StockSchema,
  currentRoundTotalIPlusDCost: z.number(),
  balance: z.number(),
  currentRoundProductionCost: z.number(),
  id: z.string(),
  name: z.string(),
  cash: z.number(),
  licenses: z.array( z.any() ),
  roundSales: z.array( z.any() ),
  commodities: z.array( z.any() ),
  studyMarketWorkGroups: z.array( z.any() ),
  variableData: z.array( z.any() ),
  points: z.number(),
  newStockValue: z.number(),
  percentagePoints: z.number(),
  position: z.number(),
  internal: z.boolean(),
  marketingBudget: z.number(),
  active: z.boolean(),
} );

export const CommunicationsSchema = z.object( {
  informationStep: InformationStepSchema,
  promotionStep: PromotionStepSchema,
  mediaStep: z.boolean(),
  internetStep: InternetStepSchema,
} );

export const EnterpriseFieldsInformationSchema = z.object( {
  company: PurpleCompanySchema,
  products: z.boolean(),
  market: MarketSchema,
  research: ResearchSchema,
  customer: CustomerSchema,
} );

export const NowFieldsDecisionSchema = z.object( {
  products: PriceSchema,
  price: PriceSchema,
  services: z.boolean(),
  distribution: z.boolean(),
  cx: z.boolean(),
  communications: CommunicationsSchema,
  oneToOne: z.boolean(),
  strategy: z.boolean(),
} );

export const StudioFieldsDecisionSchema = z.object( {
  products: PriceSchema,
  price: PriceSchema,
  services: z.boolean(),
  distribution: z.boolean(),
  cx: z.boolean(),
  communications: CommunicationsSchema,
  oneToOne: FluffyOneToOneSchema,
  strategy: StrategySchema,
} );

export const EnterpriseFieldsDecisionSchema = z.object( {
  products: PriceSchema,
  price: PriceSchema,
  services: z.boolean(),
  distribution: z.boolean(),
  cx: z.boolean(),
  communications: CommunicationsSchema,
  oneToOne: PurpleOneToOneSchema,
  strategy: StrategySchema,
} );

export const NowFieldsClassSchema = z.object( {
  information: NowFieldsInformationSchema,
  decision: NowFieldsDecisionSchema,
} );

export const StudioFieldsClassSchema = z.object( {
  information: EnterpriseFieldsInformationSchema,
  decision: StudioFieldsDecisionSchema,
} );

export const EnterpriseFieldsClassSchema = z.object( {
  information: EnterpriseFieldsInformationSchema,
  decision: EnterpriseFieldsDecisionSchema,
} );


export const SessionSchema = z.object( {
  currentSupervisor: SupervisorSchema,
  supervisor: SupervisorSchema,
  licensesWithUserActive: z.number(),
  licensesAvailable: z.number(),
  usedLicenses: z.number(),
  licensesInWorkGroups: z.number(),
  status: SessionStatusSchema,
  id: z.string(),
  name: z.string(),
  numberGroup: z.number(),
  maxUserGroup: z.number(),
  entity: EntitySchema,
  startDate: z.string(),
  endDate: z.string(),
  licensesTotal: z.number(),
  initialMoneyPerGroup: z.number(),
  currency: z.string(),
  stockFinalValue: z.number(),
  fixCostPerGroup: z.number(),
  taxes: z.number(),
  maxProductPerGroup: z.number(),
  studyMarkets: z.array( z.any() ),
  workGroups: z.array( WorkGroupSchema ),
  rounds: z.array( RoundSchema ),
  thirdPartyProducts: z.array( z.any() ),
  version: VersionSchema,
  loanTax: z.number(),
  timeZone: z.string(),
  currentRound: CurrentRoundSchema,
  zulipData: z.array( z.any() ),
  registrationCode: z.string(),
  workingDays: z.array( WorkingDaySchema ),
  creationStatus: SessionCreationStatusSchema,
  marketingBudget: z.number(),
  withCertificate: z.boolean(),
  withStrategies: z.boolean(),
  roundsClosePerDay: z.number(),
  simulationSpeed: z.string(),
  marketMaturity: z.string(),
  roundCardOff: z.number(),
  distributionType: z.string(),
  enterpriseFields: z.union( [z.array( z.any() ), EnterpriseFieldsClassSchema] ),
  studioFields: z.union( [z.array( z.any() ), StudioFieldsClassSchema] ),
  nowFields: z.union( [z.array( z.any() ), NowFieldsClassSchema] ),
  tip: TipSchema,
  tipDate: z.string(),
  withTestRound: z.boolean(),
  active: z.boolean(),
  returnToken: z.string().optional(),
} );
