import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import IntlMessage from 'utils/IntlMessages'
import { Typography } from '@material-ui/core'

const BreadcrumbComp = ({ links }) => {
  return (
    <Breadcrumb className="align-items-center d-flex" listClassName="bg-transparent p-0 m-0 mr-lg-2 " tag="nav">
      {links.map((link, index) => (
        <BreadcrumbItem
          active={links.length === index + 1}
          tag={links.length === index + 1 ? 'span' : Link}
          key={index}
          className="app-logo  "
          style={{ textDecoration: 'none' }}
          to={link.url}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: link.url ?600:400 }}
            children={<IntlMessage id={link.name} />}
          />{' '}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}
export default BreadcrumbComp
