import { Typography } from '@material-ui/core';
import React from 'react';
import IntlHtmlMessages from 'utils/IntlHtmlMessages';

const SectionInfoText =({title,className=""})=>{

    return(
        <Typography  variant="body1" color="textPrimary" className={`text-muted mt-3 mb-2  mb-lg-4 mt-lg-4 ${className}`} style={{ lineHeight: '22px',fontStyle:"normal" }}>
        <IntlHtmlMessages id={title}  />
      </Typography>
    )
}
export default SectionInfoText;