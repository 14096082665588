import React, { useEffect, useState } from 'react';
import UtilService from 'utils/Util';
import Ticker from 'react-ticker';
import { useActiveSessionId, useSessionByIdQuery } from 'modules/sessions';

const formatWorkgroupToWState = ( workgroup ) => {
  if ( !workgroup ) {
    return { stock: '', arrowIcon: '', color: '' };
  }

  const stock = `${workgroup.name} ${UtilService.formatCurrency(
    workgroup.newStockValue,
  )} ${workgroup.percentagePoints}%`;

  if ( workgroup.percentagePoints < 0 ) {
    return {
      stock,
      arrowIcon: 'fa-arrow-down',
      color: 'text-danger',
    };
  }
  if ( workgroup.percentagePoints > 0 ) {
    return {
      stock,
      arrowIcon: 'fa-arrow-up',
      color: 'text-primary',
    };
  }
  return {
    stock,
    arrowIcon: 'fa-arrow-right',
    color: 'text-light',
  };
};

const WorkgroupStockTicker = ( { style } ) => {
  const [tickerInfo, setTickerInfo] = useState( [] );
  const activeSessionId = useActiveSessionId();

  const { workgroups } = useSessionByIdQuery( activeSessionId );

  useEffect( () => {
    if ( workgroups && workgroups.length > 0 ) {
      const updatedTickerInfo = workgroups
        .slice( 0, 5 )
        .map( formatWorkgroupToWState );
      setTickerInfo( updatedTickerInfo );
    }
  }, [workgroups] );

  return (
    <div
      style={style}
      className="cbg-primary-dark text-center text-white py-1 "
    >
      <Ticker offset="run-in" speed={5}>
        {() => (
          <div>
            {tickerInfo.map( item => (
              <React.Fragment key={item.stock}>
                <span className="mx-1">{`${item.stock}`}</span>
                <i className={`fa ${item.arrowIcon} ${item.color} ms-2`} />
              </React.Fragment>
            ) )}

            <span className="mx-1">{'<><><>'}</span>
          </div>
        )}
      </Ticker>
    </div>
  );
};

export default WorkgroupStockTicker;
