import api from '../config/Api';

const endpoint = 'v1/session';

export default {

  getWorkgroups( id, params ) {
    return api.get( `${endpoint}/${id}/session-info/workgroup`, params );
  },

  getWorkgroupDetail( id, params ) {
    return api.get( `${endpoint}/${id}/session-info/workgroup/detail`, params );
  },

  changeUserWorkgroup( id, params ) {
    return api.get( `${endpoint}/${id}/session-info/workgroup/change`, params );
  },

  getAditionalData( id, params ) {
    return api.get( `v2/session/${id}/workgroup-additional-data`, params );
  },
};
