import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import { Link } from 'react-router-dom';
import { useFetch } from 'hooks';
import {
  deleteChangelog,
  updateChangelog,
} from 'modules/changelog/services/ChangelogService';
import { ChangelogRowForm } from './ChangelogRowForm';

export const ChangelogRow = ( {
  changelog,
  onFetchData = async () => {},
  disabled,
} ) => {
  const [isEditing, setIsEditing] = useState( false );
  const [openDelete, setOpenDelete] = useState( false );
  const isDisabled = isEditing || openDelete || disabled;
  const { fetchData: onDeleteEntity } = useFetch(
    async () => deleteChangelog( changelog.id ),
    {
      initialData: [],
      onSuccess: () => {
        onFetchData( changelog );
        setOpenDelete( false );
      },
    },
  );
  const { fetchData: onEdit } = useFetch(
    async values => updateChangelog( changelog.id, values ),
    {
      initialData: [],
      onSuccess: async () => {
        await onFetchData( changelog );
        setIsEditing( false );
      },
    },
  );
  const initialData = useMemo(
    () => ( {
      ...changelog,
      publicationDate: moment( Number( changelog.publicationDate ) ),
    } ),
    [changelog],
  );
  return isEditing ? (
    <ChangelogRowForm
      initialData={initialData}
      onCancel={() => setIsEditing( false )}
      onSubmit={onEdit}
    />
  ) : (
    <>
      <TableRow>
        <TableCell className="text-primary">
          {changelog.publicationDate
            ? moment( Number( changelog.publicationDate ) ).format( 'DD/MM/YYYY' )
            : ''}
        </TableCell>
        <TableCell className="text-primary">
          {changelog.enDescription}
        </TableCell>
        <TableCell className="text-primary">
          {changelog.esDescription}
        </TableCell>
        <TableCell
          colSpan={2}
          className="text-primary"
          style={{ width: '70px' }}
        >
          <div className="d-flex justify-content-end">
            <IconButton
              component={Link}
              to={`/changelogs/${changelog.id}`}
              className="square-icon-button-primary2 "
              disabled={isDisabled}
            >
              <FeatherIcon icon="eye" color="primary2.light" size="18px" />
            </IconButton>
            <div className="mx-1" />
            <IconButton
              className="square-icon-button-primary "
              disabled={isDisabled}
              onClick={() => {
                setIsEditing( true );
              }}
            >
              <FeatherIcon icon="edit" color="primary.main" size="18px" />
            </IconButton>
            <div className="mx-1" />
            <IconButton
              disabled={isDisabled}
              onClick={() => {
                setOpenDelete( true );
              }}
              className="square-icon-button-danger"
            >
              <FeatherIcon icon="trash" color="error.main" size="18px" />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <ConfirmationModal
        open={openDelete}
        translateValues={{
          name:
            ( changelog
              && moment( Number( changelog.publicationDate ) ).format( 'DD/MM/YYYY' ) )
            || '',
        }}
        onSubmit={onDeleteEntity}
        onClose={() => setOpenDelete( false )}
      />
    </>
  );
};

export default ChangelogRow;
