import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { map } from 'lodash';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import CardBox from 'components/custom_v2/CardBox';
import { z } from 'zod';
import { useActiveSessionId, useMyCurrentWorkgroupId } from 'modules/sessions';
import { LastRoundServiceSchema } from '../schemas/last-round-schemas';
import { useLastRoundServicesQuery } from '../hooks';

export const DataFormatted = z
  .array( LastRoundServiceSchema )
  .optional()
  .nullable()
  .transform( ( data ) => {
    if ( !data ) return {};

    const total = { price: 0, totalSales: 0, unitSales: 0 };
    // eslint-disable-next-line max-len
    const dataFormatted = data.flatMap( ( { workgroupData } ) => workgroupData.flatMap( ( { name: group, services } ) => services.services
      .filter( ( { active } ) => active ) // Filtrar servicios activos
      .map( ( { name, id: key, price, totalSales, unitSales } ) => {
        total.price += price;
        total.unitSales += unitSales;
        total.totalSales += totalSales;

        return {
          key,
          name,
          group,
          price: Util.formatCurrency( price ),
          totalSales: Util.formatCurrency( totalSales ),
          unitSales: Util.formatNumber( unitSales ),
        };
      } ) ) );

    return {
      data: dataFormatted,
      total: {
        price: Util.formatCurrency( total.price ),
        totalSales: Util.formatCurrency( total.totalSales ),
        unitSales: Util.formatNumber( total.unitSales ),
      },
    };
  } );

const TablesLastRoundService = () => {
  const sessionId = useActiveSessionId();
  const workgroupId = useMyCurrentWorkgroupId();
  const { data } = useLastRoundServicesQuery( sessionId, workgroupId, { limit: -1 } );
  const [dataToTable, setDataToTable] = useState( [] );
  const [total, setTotal] = useState( [] );
  useEffect( () => {
    const formatData = DataFormatted.parse( data );
    setDataToTable( formatData.data );
    setTotal( formatData.total );
  }, [data] );

  return (
    <CardBox
      styleName="px-2 h-100 mt-3"
      header={
        <KotlerCardHeader minHeight="0px" title="market.services_information" />
      }
    >
      <Table className="kotler-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>
                <IntlMessages id="group" />
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                <IntlMessages id="service" />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id="salesU" />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id="salesP" />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id="price" />
              </strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(
            dataToTable,
            ( { key, name, price, totalSales, unitSales, group } ) => (
              <TableRow key={key}>
                <TableCell>{group}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell align="right">{unitSales}</TableCell>
                <TableCell align="right">{totalSales}</TableCell>
                <TableCell align="right">{price}</TableCell>
              </TableRow>
            ),
          )}
          {!!dataToTable && !!dataToTable.length && !!total && (
            <TableRow>
              <TableCell>
                <strong>
                  <IntlMessages id="total" />
                </strong>
              </TableCell>
              <TableCell />
              <TableCell align="right">{total.unitSales}</TableCell>
              <TableCell align="right">{total.totalSales}</TableCell>
              <TableCell />
            </TableRow>
          )}
          {( !dataToTable || !dataToTable.length ) && (
            <TableRow>
              <TableCell colSpan={5}>
                <IntlMessages id="NoDataFound" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CardBox>
  );
};

export default TablesLastRoundService;
