import api from '../config/Api';

const endpoint = 'v1/user';
const endpoint2 = 'v2/user';

export default {
  getCurrentUser( data ) {
    if ( data && data.access_token ) return api.get( `${endpoint}/me?app_movil=true`, null, { headers: { Authorization: `Bearer ${data.access_token}` }, timeout: 120000 } );
    return api.get( `${endpoint}/me?app_movil=true`, null );
  },

  getCurrentUserSpy( data ) {
    if ( data && data.access_token ) return api.get( `${endpoint}/me?app_movil=true&spyToken=1`, null, { headers: { Authorization: `Bearer ${data.access_token}` } } );
    return api.get( `${endpoint}/me?app_movil=true` );
  },

  getNotificationsUser( {
    access_token: accessToken,
    licenses,
    all,
    sessionId,
    workgroupId,
    roundId,
  } ) {
    let query = 'v2/notification?limit=100';
    if ( sessionId )query = query.concat( `&filters[session]=${sessionId}` );
    else if ( licenses && licenses[0].session )query = query.concat( `&filters[session]=${licenses[0].session.id}` );
    if ( workgroupId )query = query.concat( `&filters[workgroup]=${workgroupId}` );
    if ( roundId )query = query.concat( `&filters[round]=${roundId}&filters[enc]=true` );
    if ( !all )query = query.concat( '&filters[isRead]=0' );

    return api.get( query, null, { headers: { Authorization: `Bearer ${accessToken}` } } );
  },

  updateNotificationUser( notificationId, read, user ) {
    return api.put( `v2/notification/${notificationId}`, read, { headers: { Authorization: `Bearer ${user.access_token}` } } );
  },

  getUsers( params ) {
    return api.get( endpoint, params );
  },

  getSupervisorUsers( params ) {
    return api.get( 'v1/user-supervisor', params );
  },

  getUser( id ) {
    return api.get( `${endpoint}/${id}` );
  },

  updateUser( id, data ) {
    return api.put( `${endpoint}/${id}`, data );
  },

  saveUser( data ) {
    return api.post( endpoint, data );
  },

  validateCode( data ) {
    return api.post( `${endpoint2}/validate/registration-code`, data );
  },

  deleteUser( id ) {
    return api.delete( `${endpoint}/${id}` );
  },

  changePassword( data ) {
    return api.post( `${endpoint}/change-password`, data );
  },

  recoverPassword( data ) {
    return api.post( `${endpoint}/recover-password`, data );
  },

  recoverPasswordConfirmation( token, data ) {
    return api.post( `${endpoint}/recover-password/${token}`, data );
  },

  updateUserProfile( data ) {
    return api.put( `${endpoint2}/setting`, data );
  },

  setReturnToken( sessionId, tokenParam ) {
    return api.put( `v2/session/${sessionId}/return-token`, tokenParam );
  },

  setReturnTokenCloseSpy( sessionId, tokenParam, returnToken ) {
    return api.put( `v2/session/${sessionId}/return-token?spyToken`, tokenParam, { headers: { Authorization: `Bearer ${returnToken}` } } );
  },

  registerByCode( data ) {
    return api.post( `${endpoint2}/register-by-code`, data );
  },

  userByEmail( data ) {
    return api.post( `${endpoint2}/user-by-email`, data );
  },

  updateScreenToured() {
    return api.put( `${endpoint2}/screens-toured` );
  },

  updateHeartBeat() {
    return api.put( `${endpoint2}/time-system` );
  },
};
