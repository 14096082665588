import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import _, { get } from 'lodash';
import async from 'async';
import CardBox from 'components/custom_v2/CardBox';

import IntlMessages from 'utils/IntlMessages';
import Table from 'components/custom_v2/Table/Custom/';
import SettingsActions from 'store/reducers/Settings';
import MarketReportService from 'services/MarketReport';
import ConfirmationModal from 'components/custom/ConfirmationModal';
// import Util from 'utils/Util';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-research.jpeg';
import SectionInfoText from 'components/custom/SectionInfoText';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import { filterReportsByPermissions } from 'modules/Research';

class MarketReport extends React.Component {
  state = {
    purchasedReports: [],
    confirmPurchaseModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    // if ( !this.license || !this.license.workGroup ) {
    //  return toggleAlert( 'ERROR' );
    // }
    this.activeSession = user.activeSession;
    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    async.parallel(
      {
        // availableReports: this.getAvailableReports,
        purchasedReports: this.getPurchasedReports,
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) return toggleAlert( error );
        this.setState( results );
      },
    );
  };

  getAvailableReports = ( cb ) => {
    const params = {};
    MarketReportService.getAvailableReports( this.activeSession.id, params ).then(
      ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        const reports = filterReportsByPermissions(
          response.data.data,
          'availableReport.report',
          this.mkmode.information.research,
        );

        cb( null, reports );
      },
    );
  };

  getPurchasedReports = ( cb ) => {
    const { user } = this.props;
    const params = {
      'sortby[boughtRound.largeName]': 'ASC',
      'sortby[availableReport.report]': 'ASC',
      'sortby[workgroup.name]': 'ASC',
    };
    MarketReportService.getPurchasedReportsProfessor(
      this.activeSession.id,
      params,
    ).then( ( response ) => {
      if ( !response.ok ) return cb( true );

      const reports = filterReportsByPermissions(
        response.data.data,
        'availableReport.report',
        get( user.activeSession, 'fields.information.research' ),
      );
      cb( null, reports );
    } );
  };

  moveToPurchased = ( report ) => {
    const { purchasedReports } = this.state;
    const newReports = [...purchasedReports];
    const purchased = {
      boughtRound: {
        name: _.get( this.activeSession, 'currentRound.name' ),
        startDateInMonth: _.get(
          this.activeSession,
          'currentRound.startDateInMonth',
        ),
      },
      availableReport: report,
      status: 'PENDING',
    };
    newReports.push( purchased );
    this.setState( { purchasedReports: newReports } );
  };

  moveToAvailable = ( report ) => {
    const { purchasedReports } = this.state;
    const newReports = [...purchasedReports];

    // eslint-disable-next-line max-len
    const index = _.findIndex( purchasedReports, { availableReport: { id: report.availableReport.id } } );
    if ( index !== -1 ) {
      newReports.splice( index, 1 );
      this.setState( { purchasedReports: newReports } );
    }
  };

  toggleConfirmPurchaseModal = () => {
    const { toggleAlert } = this.props;
    const { purchasedReports, confirmPurchaseModal } = this.state;

    const reports = _.chain( purchasedReports )
      .filter( { status: 'PENDING' } )
      .map( 'availableReport.id' )
      .value();
    if ( !reports.length ) return toggleAlert( 'selectAvailableReport', 'danger', true );

    this.setState( { confirmPurchaseModal: !confirmPurchaseModal } );
  };

  submitPurchase = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { purchasedReports } = this.state;

    const reports = _.chain( purchasedReports )
      .filter( { status: 'PENDING' } )
      .map( 'availableReport.id' )
      .value();
    if ( !reports.length ) return toggleAlert( 'selectAvailableReport', 'danger', true );

    toggleLoading( true );
    const data = {
      workgroup: this.license.workGroup.id,
      availableReports: reports,
    };
    MarketReportService.sendPurchaseReports( this.activeSession.id, data ).then(
      ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.toggleConfirmPurchaseModal();
        this.getData();
      },
    );
  };

  getStatus = ( status ) => {
    switch ( status ) {
    case 'PURCHASED':
      return <i className="fa fa-money-bill font-size-20 text-info" />;
    case 'READY':
      return <i className="fa fa-check font-size-20 text-primary" />;
    case 'PENDING':
      return <i className="fa fa-clock font-size-20 text-danger" />;
    default:
      return '';
    }
  };

  render() {
    const { purchasedReports, confirmPurchaseModal } = this.state;

    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'InformationDashboard-research' },
          ]}
        />
        <KotlerSectionHeader title="research" image={HeaderImage} />
        <SectionInfoText title="help.info.research.snackbar" />

        <Grid container>
          <Grid item xs={12}>
            <CardBox
              styleName="px-2  mb-4 h-100"
              header={<KotlerCardHeader title="reports" minHeight="0px" />}
            >
              <Table
                data={purchasedReports}
                /* pageSize={purchasedReports.length} */
                showPaginationTop={false}
                columns={[
                  {
                    Header: <IntlMessages id="month" />,
                    accessor: 'boughtRound.name',
                    Cell: ( { value, original } ) => `${value} ${_.get(
                      original,
                      'boughtRound.startDateInMonth',
                      '',
                    )}`,
                  },
                  {
                    Header: <IntlMessages id="reports" />,
                    accessor: 'availableReport.report',
                  },
                  {
                    minWidth: 300,
                    Header: <IntlMessages id="name" />,
                    accessor: 'availableReport.name',
                    Cell: ( { original } ) => ( original.status === 'READY' ? (
                      <a
                        rel="noopener noreferrer"
                        className="text-blue"
                        target={
                          original.availableReport.report === 'MK003'
                            ? ''
                            : '_blank'
                        }
                        href={
                          original.availableReport.report === 'MK003'
                            ? `positioning/${original.id}/${original.workgroup.id}`
                            : original.link
                        }
                      >
                        <IntlMessages id={original.availableReport.report} />
                      </a>
                    ) : (
                      <IntlMessages id={original.availableReport.report} />
                    ) ),
                  },
                  {
                    maxWidth: 70,
                    Header: <IntlMessages id="status" />,
                    accessor: 'status',
                    Cell: ( { value } ) => this.getStatus( value ),
                  },
                  {
                    sortable: false,
                    accessor: 'id',
                    Cell: props => ( props.original.status === 'PENDING' ? (
                      <Button
                        variant="contained"
                        className="jr-btn bg-danger text-white"
                        onClick={() => this.moveToAvailable( props.original )}
                      >
                        <i className="fa fa-times" />
                      </Button>
                    ) : null ),
                  },
                  {
                    Header: <IntlMessages id="group" />,
                    accessor: 'workgroup.name',
                  },
                ]}
              />
            </CardBox>
          </Grid>
        </Grid>

        <ConfirmationModal
          open={confirmPurchaseModal}
          title="confirmReportPurchase"
          confirmBtnText="yesModalBtn"
          onSubmit={this.submitPurchase}
          onClose={this.toggleConfirmPurchaseModal}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( MarketReport );
