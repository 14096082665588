import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import _ from 'lodash';

import CardBox from 'components/theme/CardBox';
import {
  TextField,
  Buttons,
  Checkbox,
  Select,
} from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import { composeValidators, email, required } from 'config/InputErrors';
import KotlerChargerImage from 'components/custom/KotlerImageCharger';
import UniversityProfile from 'assets/images/kotler/university-profile.jpeg';
import UniversityServices from 'modules/University/services/UniversityServices';
import COUNTRY_LIST from 'constants/Countries/CountriesList';

class EditForm extends React.Component {
  state = { data: {}, currentImage: '' };

  entityId = null;

  componentDidMount() {
    const { entityId } = this.props;
    this.entityId = entityId;

    if ( this.entityId ) {
      this.getEntity();
    } else {
      this.setState( { currentImage: UniversityProfile } );
    }
  }

  getEntity = async () => {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    const response = await UniversityServices.getUniversity( this.entityId );
    toggleLoading( false );

    if ( response.ok ) {
      const { avatarPath } = response.data;
      this.setState( {
        data: response.data,
        currentImage: avatarPath || UniversityProfile,
      } );
    }
  };

  submitForm = ( data, form ) => {
    const { toggleLoading, toggleAlert, history } = this.props;
    const dataToSend = {};

    toggleLoading( true );
    _.map( _.keys( form.getState().modified ), ( key ) => {
      if ( key !== 'usedLicenses' && key !== 'availableLicenses' ) dataToSend[key] = data[key];
    } );

    if ( !this.entityId ) {
      dataToSend.status = 'created';
    }
    const promise = this.entityId
      ? UniversityServices.updateEntity( this.entityId, dataToSend )
      : UniversityServices.saveEntity( dataToSend );
    promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );

      toggleAlert( 'dataSaved', 'info' );
      if ( !this.entityId ) {
        history.push( `/universities/detail/${response.data.id}/general` );
      }
    } );
  };

  onChangeImage = async ( image64 ) => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );
    const res = await UniversityServices.saveImage( this.entityId, image64 );
    if ( res.ok ) {
      toggleAlert( 'profileUpdated', 'info' );
      this.setState( { currentImage: image64 } );
    } else {
      toggleAlert( res.errors );
    }
    toggleLoading( false );
  };
  onDeleteImage = async () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );
    const res = await UniversityServices.deleteImage( this.entityId );
    if ( res.ok ) {
      await this.getEntity();
      toggleAlert( 'profileUpdated', 'info' );
    } else {
      toggleAlert( res.errors );
    }
    toggleLoading( false );
  };

  render() {
    const { data, currentImage } = this.state;
    const { entityId } = this.props;

    return (
      <Form
        initialValues={data}
        onSubmit={this.submitForm}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <CardBox heading="university">
              <div className="row">
                <div className="d-flex justify-content-center col-md-12 col-lg-4">
                  <div>
                    <KotlerChargerImage
                      disabled={!entityId}
                      value={currentImage}
                      onChange={this.onChangeImage}
                      onDelete={this.onDeleteImage}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <TextField field="name" label="name" validate={required} />
                  <Select
                    field="country"
                    label="country"
                    options={COUNTRY_LIST}
                    translateOptions={false}
                    validate={required}
                  />
                  <TextField
                    field="email"
                    label="email"
                    validate={composeValidators( required, email )}
                  />
                  {!!data.id && <Checkbox field="active" label="active" />}
                </div>
                <div className="col-md-6 col-lg-4">
                  <TextField field="address" label="address" />
                  <TextField
                    field="observations"
                    label="observations"
                    multiline
                    rows={1}
                    rowsMax={4}
                  />
                  {!!data.id && (
                    <Select
                      field="status"
                      label="status"
                      options={[
                        {
                          id: 'created',
                          name: 'created',
                        },
                        {
                          id: 'closed',
                          name: 'closed',
                        },
                      ]}
                      validate={required}
                    />
                  )}
                </div>
              </div>
            </CardBox>

            <CardBox heading="contact">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    field="contactName"
                    label="name"
                    validate={required}
                  />
                  <TextField
                    field="contactEmail"
                    label="email"
                    validate={composeValidators( required, email )}
                  />
                </div>
                <div className="col-md-6">
                  <TextField field="contactDescription" label="description" />
                  <TextField
                    field="contactObservations"
                    label="observations"
                    multiline
                    rows={1}
                    rowsMax={4}
                  />
                </div>
              </div>
            </CardBox>

            <CardBox heading="licenses">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    type="number"
                    field="minLicensesAvailable"
                    label="minLicensesAvailable"
                    validate={required}
                  />
                </div>
                {!!data.id && (
                  <div className="col-md-6">
                    <TextField
                      type="number"
                      field="availableLicenses"
                      label="availableLicenses"
                      disabled
                    />
                  </div>
                )}
                {!!data.id && (
                  <div className="col-md-6">
                    <TextField
                      type="number"
                      field="usedLicenses"
                      label="usedLicenses"
                      disabled
                    />
                  </div>
                )}
              </div>
            </CardBox>

            <div className="row mt-3">
              <div className="col-md-12 text-right">
                <Buttons cancelTo="/universities" />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( withRouter( EditForm ) );
