import { Hidden, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import React from 'react';
import IntlMessages from 'utils/IntlMessages';
import IconTV from 'assets/images/kotler/icons/icon-tv.png';
import IconRADIO from 'assets/images/kotler/icons/icon-radio.png';
import IconOUTDOOR from 'assets/images/kotler/icons/icon-outdoor.png';
import IconPERIODIC from 'assets/images/kotler/icons/icon-periodic.png';
import IconINTERNET from 'assets/images/kotler/icons/icon-internet.png';
import _ from 'lodash';

const generateTitle = ( filterType ) => {
  if ( !filterType || !_.isString( filterType ) ) return null;
  let formatText = 'RADIO';
  let Icon = null;
  switch ( filterType ) {
  case 'TV':
    formatText = 'TV';
    Icon = IconTV;
    break;
  case 'RADIO':
    formatText = 'RADIO';
    Icon = IconRADIO;
    break;
  case 'PRINT':
    formatText = 'PRINT';
    Icon = IconPERIODIC;
    break;
  case 'OUTDOOR':
    formatText = 'OUTDOOR';
    Icon = IconOUTDOOR;
    break;
  case 'INTERNET':
    formatText = 'INTERNET';
    Icon = IconINTERNET;
    break;
  default:
    break;
  }
  return (
    <>
      <img className="pr-2" src={Icon} alt={formatText} />
      <IntlMessages id={formatText} />
    </>
  );
};
export default ( { title, data, total, withBigHead, noHead, noCellOcult } ) => (
  <CardBox styleName=" h-100 pl-2 ml-2 pb-0 mb-0" header={!noHead ? <div style={{ height: '32px', width: '100%' }} /> : <div />}>

    <div className="overflow-auto">
      <Table className="kotler-table">

        <TableHead>
          {withBigHead && (
            <TableRow>
              <TableCell colSpan={3} className="border-0 py-0" />
              <TableCell colSpan={5} align="center" className="border-0 py-0">
                <IntlMessages id="media.audience.within" />
                {/*    <br />
                                        <IntlMessages id="media.audience.percentage" /> */}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <Hidden only={!noCellOcult ? 'md' : null}>
              <TableCell style={{ width: '150px' }}>
                {generateTitle( title )}
              </TableCell>
            </Hidden>
            <TableCell className="px-2">
              <IntlMessages id="media.audience.main.target" />
            </TableCell>
            <TableCell style={{ width: '100px' }}>
              <IntlMessages id="media.audience.reach" />
              {' '}
              <IntlMessages id="media.audience.million" />
            </TableCell>

            <TableCell><IntlMessages id="crm.media.exec" /></TableCell>
            <TableCell><IntlMessages id="crm.media.prof" /></TableCell>
            <TableCell><IntlMessages id="crm.media.empl" /></TableCell>
            <TableCell><IntlMessages id="crm.media.students" /></TableCell>
            <TableCell><IntlMessages id="crm.media.home" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length && data.map( item => (
            <TableRow key={item.id} style={{ height: '55px' }}>
              <Hidden only={!noCellOcult ? 'md' : null}>
                <TableCell>
                  {item.title1}
                </TableCell>
              </Hidden>
              <TableCell className="px-2 py-0">
                {item.title2}
              </TableCell>

              <TableCell>
                {item.mReach}
              </TableCell>
              <TableCell>
                {item.mExec}
              </TableCell>
              <TableCell>
                {item.mProf}
              </TableCell>
              <TableCell>
                {item.mEmpl}
              </TableCell>
              <TableCell>
                {item.mStudents}
              </TableCell>
              <TableCell>
                {item.mHome}
              </TableCell>

            </TableRow>
          ) )}
          {total && (
            <TableRow>
              <TableCell>
                {total.title1}
              </TableCell>
              <Hidden only="md">
                <TableCell>
                  {total.title2}
                </TableCell>
              </Hidden>
              <TableCell>
                {total.mReach}
              </TableCell>
              <TableCell>
                {total.mExec}
              </TableCell>
              <TableCell>
                {total.mProf}
              </TableCell>
              <TableCell>
                {total.mEmpl}
              </TableCell>
              <TableCell>
                {total.mStudents}
              </TableCell>
              <TableCell>
                {total.mHome}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  </CardBox>
);
