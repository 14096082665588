import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import async from 'async';

import SettingsActions from 'store/reducers/Settings';
import RoundService from 'services/Round';
import CommodityService from 'services/Commodity';
import AttributeService from 'services/Attribute';
import AttributeServiceService from 'services/AttributeService';
import CommunicationService from 'services/Communication';

import CampaignDetail from 'routes/App/components/User/OneToOne/Detail';
import Table from 'components/custom/Table/AsyncTable';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import HeaderImage from 'assets/images/kotler/sectionHeaders/one-to-one-campaing.jpeg';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import HeadConfig from 'components/custom/HeadConfig';


const behavioralSegmentations1 = [
  {
    id: 1,
    type: 'radio',
    options: [
      {
        id: 'PURCHASED',
        name: 'PURCHASED',
      },
      {
        id: 'NOT_PURCHASED',
        name: 'NOT_PURCHASED',
      },
    ],
    selectCommodity: true,
    behavioralType: 'TYPE_1',
  },
  {
    id: 2,
    type: 'radio',
    options: [
      {
        id: 'PURCHASED',
        name: 'PURCHASED',
      },
      {
        id: 'NOT_PURCHASED',
        name: 'NOT_PURCHASED',
      },
    ],
    selectCommodity: true,
    behavioralType: 'TYPE_2',
  },
];

const behavioralSegmentations2 = [
  {
    id: 3,
    type: 'checkbox',
    options: [
      {
        id: 'COMPLAINT',
        name: 'COMPLAINT',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_3',
  },
  {
    id: 4,
    type: 'checkbox',
    options: [
      {
        id: 'REMAINED_INACTIVE',
        name: 'REMAINED_INACTIVE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_4',
  },
  {
    id: 5,
    type: 'checkbox',
    options: [
      {
        id: 'VISITED_WEBSITE',
        name: 'VISITED_WEBSITE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_5',
  },
  {
    id: 6,
    type: 'checkbox',
    options: [
      {
        id: 'SUFFERED_FAILURE',
        name: 'SUFFERED_FAILURE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_6',
  },
];

class CampaignDetailComp extends React.Component {
  state = {
    rounds: [],
    data: [],
    products: [],
    services: [],
    bundles: [],
    commodities: [],
    productAttributes: [],
    serviceAttributes: [],
    detailData: {},
  };
  entityId = null;

  async componentDidMount() {
    const { toggleAlert, user, match } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.license = await _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.entityId = match.params.id;
    await this.getData( this.entityId );
    await this.getDataTable( this.params );
  }

  getData = () => {
    const { toggleLoading, toggleAlert } = this.props;

    toggleLoading( true );
    async.parallel( {
      entity: this.getEntity,
      rounds: this.getRounds,
      commodities: this.getCommodities,
      productAttributes: this.getProductAttributes,
      serviceAttributes: this.getServiceAttributes,
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );
      let entity = {};

      if ( this.entityId ) {
        entity = {
          ...results.entity,
          behavioralSegmentations1: _.cloneDeep( behavioralSegmentations1 ),
          behavioralSegmentations2: _.cloneDeep( behavioralSegmentations2 ),
        };
        if ( entity.contentType === 'PRODUCT' ) {
          entity.product = entity.product
          && _.find( results.commodities, { id: entity.product.id } )
            ? entity.product.id : entity.product.name;
          entity.productFeature = [];
          if ( results.entity.productFeature ) {
            entity.productFeature.push( { id: results.entity.productFeature.id } );
          }
          if ( entity.productFeature2 ) {
            entity.productFeature.push( { id: results.entity.productFeature2.id } );
          }
        }
        if ( entity.contentType === 'SERVICE' ) {
          entity.service = entity.service
          && _.find( results.commodities, { id: entity.service.id } ) ? entity.service.id : null;
          entity.serviceFeature = [];
          if ( results.entity.serviceFeature ) {
            entity.serviceFeature.push( { id: results.entity.serviceFeature.id } );
          }
          if ( entity.serviceFeature2 ) {
            entity.serviceFeature2.push( { id: results.entity.serviceFeature2.id } );
          }
        }
        if ( entity.contentType === 'BUNDLE' ) {
          entity.bundle = entity.bundle ? entity.bundle.id : null;
        }

        if ( entity.demographicSegmentation && entity.demographicSegmentation.segmentation ) {
          entity.demographicSegmentation.segmentation = _.map(
            entity.demographicSegmentation.segmentation, item => ( { id: item } ),
          );
        }
        if ( entity.behavioralSegmentation ) {
          entity.behavioralSegmentations1 = _.map( entity.behavioralSegmentations1, ( item ) => {
            const newItem = { ...item };
            const segmentation = _.find(
              entity.behavioralSegmentation, { behavioralType: item.behavioralType },
            );
            if ( segmentation ) {
              newItem.behavioral = segmentation.behavioral;
              if ( segmentation.commodity
                && _.find( results.commodities, { id: segmentation.commodity.id } ) ) {
                newItem.commodity = segmentation.commodity.id;
              }
              if ( segmentation.afterRound ) {
                newItem.date = 'afterRound';
                if ( _.find( results.rounds, { id: segmentation.afterRound.id } ) ) {
                  newItem.round = segmentation.afterRound.id;
                }
              }
              if ( segmentation.beforeRound ) {
                newItem.date = 'beforeRound';
                if ( _.find( results.rounds, { id: segmentation.beforeRound.id } ) ) {
                  newItem.round = segmentation.beforeRound.id;
                }
              }
              if ( segmentation.exactly ) {
                newItem.date = 'exactly';
                newItem.months = segmentation.exactly;
              }
            }
            return newItem;
          } );

          entity.behavioralSegmentations2 = _.map( entity.behavioralSegmentations2, ( item ) => {
            const newItem = { ...item };
            const segmentation = _.find(
              entity.behavioralSegmentation, { behavioralType: item.behavioralType },
            );
            if ( segmentation ) {
              newItem.behavioral = [{ id: segmentation.behavioral }];
              if ( segmentation.afterRound ) {
                newItem.date = 'afterRound';
                if ( _.find( results.rounds, { id: segmentation.afterRound.id } ) ) {
                  newItem.round = segmentation.afterRound.id;
                }
              }
              if ( segmentation.beforeRound ) {
                newItem.date = 'beforeRound';
                if ( _.find( results.rounds, { id: segmentation.beforeRound.id } ) ) {
                  newItem.round = segmentation.beforeRound.id;
                }
              }
              if ( segmentation.exactly ) {
                newItem.date = 'exactly';
                newItem.months = segmentation.exactly;
              }
            }
            return newItem;
          } );
        }
        if ( entity.iotSegmentation ) {
          if ( entity.iotSegmentation.product
            && _.find( results.commodities, { id: entity.iotSegmentation.product.id } ) ) {
            entity.iotSegmentation.product = entity.iotSegmentation.product.id;
          } else entity.iotSegmentation.product = null;
        }
      }

      this.setState( {
        ...results,
        products: _.filter( results.commodities, { owner: 'PRODUCT' } ),
        services: _.filter( results.commodities, { owner: 'SERVICE' } ),
        bundles: _.filter( results.commodities, { owner: 'BUNDLE' } ),
        rounds: _.map( results.rounds, round => ( {
          id: round.id,
          name: round.largeName,
        } ) ),
      }, () => this.setState( {
        detailData: this.entityId
          ? this.loadDataDetail( entity ) : {},
      } ) );
    } );
  };

  getEntity = ( cb ) => {
    if ( !this.entityId ) return cb();
    CommunicationService.getOneToOneCampaign( this.activeSession.id, this.entityId,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data );
      } );
  };

  getDataTable = ( params ) => {
    const { toggleLoading, toggleAlert, user, match } = this.props;
    this.entityId = match.params.id;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    toggleLoading( true );

    this.params = { ...params, workgroup: this.license.workGroup.id };
    CommunicationService.getOneToOneCampaignRoundList(
      user.activeSession.id,
      this.entityId,
      this.params,
    )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          const limit = params !== undefined ? params.limit : 10;
          this.setState( {
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / limit ),
            total: response.data.total,
          } );
        }
      } );
  }

  loadDataDetail = ( dataDetail ) => {
    const {
      products, services, bundles, commodities, rounds,
      productAttributes, serviceAttributes,
    } = this.state;
    const data = _.cloneDeep( dataDetail );
    if ( data.product ) {
      const product = _.find( products, { id: data.product } );
      if ( product ) data.product = product.name;
    }
    if ( data.service ) {
      const service = _.find( services, { id: data.service } );
      if ( service ) data.service = service.name;
    }
    if ( data.bundle ) {
      const bundle = _.find( bundles, { id: data.bundle } );
      if ( bundle ) data.bundle = bundle.name;
    }
    data.productFeature = _.map( data.productFeature, ( item ) => {
      const productAttribute = _.find( productAttributes, { id: item.id } );
      if ( productAttribute ) return { id: productAttribute.name };
      return item.id;
    } );
    data.serviceFeature = _.map( data.serviceFeature, ( item ) => {
      const serviceAttribute = _.find( serviceAttributes, { id: item.id } );
      if ( serviceAttribute ) return { id: serviceAttribute.name };
      return item.id;
    } );

    if ( _.get( data, 'iotSegmentation.product' ) ) {
      const product = _.find( products, { id: data.iotSegmentation.product } );
      if ( product ) data.iotSegmentation.product = product.name;
    }

    if ( data.behavioralSegmentations1 ) {
      data.behavioralSegmentations1 = _.map( data.behavioralSegmentations1, ( item ) => {
        if ( !item.behavioral ) return;
        const newData = {
          behavioral: item.behavioral,
          behavioralType: item.behavioralType,
          commodity: _.find( commodities, { id: item.commodity } ),
        };
        if ( item.date === 'afterRound' || item.date === 'beforeRound' ) {
          if ( item.date === 'afterRound' ) newData.afterRound = _.find( rounds, { id: item.round } );
          if ( item.date === 'beforeRound' ) newData.beforeRound = _.find( rounds, { id: item.round } );
        } else if ( item.date === 'exactly' ) newData.exactly = item.months;
        return newData;
      } );
    }
    data.behavioralSegmentations1 = _.compact( data.behavioralSegmentations1 );
    if ( data.behavioralSegmentations2 ) {
      data.behavioralSegmentations2 = _.map( data.behavioralSegmentations2, ( item ) => {
        if ( !item.behavioral || !item.behavioral.length ) return;
        const newData = {
          behavioral: item.behavioral[0].id,
          behavioralType: item.behavioralType,
        };
        if ( item.date === 'afterRound' || item.date === 'beforeRound' ) {
          if ( item.date === 'afterRound' ) newData.afterRound = _.find( rounds, { id: item.round } );
          if ( item.date === 'beforeRound' ) newData.beforeRound = _.find( rounds, { id: item.round } );
        } else if ( item.date === 'exactly' ) newData.exactly = item.months;
        return newData;
      } );
    }
    data.behavioralSegmentations2 = _.compact( data.behavioralSegmentations2 );
    return data;
  };

  getEntity = ( cb ) => {
    if ( !this.entityId ) return cb();
    CommunicationService.getOneToOneCampaign( this.activeSession.id, this.entityId,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data );
      } );
  };

  getRounds = ( cb ) => {
    RoundService.getRoundsBySession( this.activeSession.id, {
      limit: -1,
      'filters[noStatus]': 'CLOSED',
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data.data );
      } );
  };

  getCommodities = ( cb ) => {
    CommodityService.getCommodities( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data.data );
      } );
  };

  getProductAttributes = ( cb ) => {
    AttributeService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getServiceAttributes = ( cb ) => {
    AttributeServiceService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  render() {
    const { detailData, ...rest } = this.state;
    const { entity = {} } = rest;
    const haveRoi = entity.contentType !== 'LOYALTY' && entity.contentType !== 'REFERRALS';
    return (
      <>

        <KotlerSectionHeader title="1to1Campaigns" image={HeaderImage} />
        <HeadConfig breadcrumbsLinks={[
          {
            url: '/',
            name: 'HomeLink',
          },
          {
            url: '/sessions/general/one-to-one',
            name: 'DecisionDashboard-1to1Campaigns',
          },
          { name: 'campaignDetail' },
        ]}
        />

        <KotlerTableTitle backTo="/sessions/general/one-to-one" className="ml-1 mt-5 mb-4" fontSize="1.12rem" icon="eye" title="overview" />
        <CampaignDetail data={detailData} defaultOpened />
        <KotlerTableTitle className="ml-1 mt-5 mb-4" fontSize="1.12rem" icon="rotateCcw" title="rounds" />

        <Table
          onFetchData={this.getDataTable}
          {...rest}
          columns={[
            {
              Header: (
                <div className="text-center" style={{ width: '100%' }}>
                  <IntlMessages id="round" />
                  {' '}
                </div> ),
              accessor: 'round.largeName',
            },
            {
              Header: (
                <div className="text-center" style={{ width: '100%' }}>
                  <IntlMessages id="campaignCost" />
                  {' '}
                  {'\u00A0'.repeat( 50 )}
                </div> ),
              accessor: 'campaignCost',
              Cell: props => ( <div>{Util.formatNumberNoDecimals( props.value ) }</div> ),

            },
            {
              Header: (
                <div className="text-center" style={{ width: '100%' }}>
                  <IntlMessages id="peopleReached" />
                  {' '}
                  {'\u00A0'.repeat( 50 )}
                </div> ),
              accessor: 'peopleReached',
              Cell: props => ( <div>{Util.formatNumberNoDecimals( props.value ) }</div> ),

            },
            {
              Header: (
                <div className="text-center" style={{ width: '100%' }}>
                  <IntlMessages id="estimatedSalesGenerated" />
                  {' '}
                  {'\u00A0'.repeat( 35 )}
                </div> ),
              accessor: 'estimatedSalesGenerated',
              Cell: props => ( <div>{Util.formatNumberNoDecimals( props.value ) }</div> ),

            },
            {
              Header: (
                <div className="text-center" style={{ width: '100%' }}>
                  <IntlMessages id="directSalesPercent" />
                  {' '}
                  {'\u00A0'.repeat( 50 )}
                </div> ),
              accessor: 'directSalesPercent',
              Cell: props => ( <div>{Util.formatNumber( props.value ) }</div> ),

            },
            {
              Header: (
                <div className="text-center" style={{ width: '100%' }}>
                  <IntlMessages id="revenues" />
                  {' '}
                  {'\u00A0'.repeat( 50 )}
                </div> ),
              accessor: 'revenues',
              Cell: props => ( <div>{Util.formatNumberNoDecimals( props.value ) }</div> ),

            },
            {
              Header: (
                <div className="text-center" style={{ width: '100%' }}>
                  <IntlMessages id="estimatedNetRevenues" />
                  {' '}
                  {'\u00A0'.repeat( 30 )}
                </div> ),
              accessor: 'estimatedNetRevenues',
              Cell: props => ( <div>{Util.formatNumberNoDecimals( props.value ) }</div> ),

            },
            {
              Header: (
                <div>
                  <IntlMessages id="roi" />
                  {' '}
                  {'\u00A0'.repeat( 75 )}
                </div> ),
              accessor: 'roi',
              Cell: props => ( <div>{ haveRoi ? Util.formatNumberNoDecimals( props.value ) : '-' }</div> ),
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( CampaignDetailComp );
