import React from 'react';
import { connect } from 'react-redux';
import notiPhone from 'assets/images/kotler/notiPhone.png';
import withWidth from '@material-ui/core/withWidth';
import IntlMessages from 'utils/IntlMessages';
import KotlerHour from './kotlerHour';

/* const kotlerDate = (locale) => {
  const months = {
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    es: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  }
  const currentDate = new Date()
  return `${months[locale][currentDate.getMonth()]
    }, ${currentDate.getDate().toString().padStart(2, '0')}`
} */
const kotlerNotification = ( {
  children,
  locale,
  disableCell,
  width,
  activeSession,
} ) => {
  const isNow = activeSession && activeSession.distributionType === 'now';
  return (
    <div className="d-flex justify-content-center   px-2 px-lg-0  animate__animated animate__backInRight">
      <div
        style={{
          position: 'relative',
          transform:
            width !== 'xs' && width !== 'sm' ? 'rotate(15deg)' : 'rotate(0)',
          marginTop: '27px',
        }}
      >
        <img
          src={isNow ? '/images/now/iphone.png' : notiPhone}
          alt="kotlerPhone"
          style={{
            width: '100%',
            opacity: width !== 'xs' && width !== 'sm' ? 1 : 0,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '11%',
            left: '15%',
            right: '15%',
            marginLeft: '-1.5%',
            bottom: '10%',
            display: 'flex',
            justifyContent: width !== 'xs' ? 'center' : 'flex-start',
            flexDirection: 'column',
          }}
        >
          <div
            id="kotlerTime"
            style={{ verticalAlign: 'middle', marginBottom: '3%' }}
          >
            {isNow && (
              <div
                id="kotlerDate"
                style={{
                  textAlign: 'center',
                  fontSize: isNow ? 18 : undefined,
                }}
              >
                {activeSession
                && activeSession.currentRound
                && activeSession.currentRound.largeName ? (
                  <>
                    <IntlMessages
                      id={
                        activeSession.currentRound.largeName
                          .toLowerCase()
                          .split( ' ' )[0]
                      }
                    />
                    {` ${activeSession.currentRound.largeName.split( ' ' )[1]}`}
                    {' '}
                  </>
                  ) : (
                    ''
                  ) /* kotlerDate(locale.locale) */}
              </div>
            )}
            <div
              id="kotlerHour"
              style={{
                fontFamily: 'Oxygen',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: isNow ? 54 : 36,
                textAlign: 'center',
                lineHeight: isNow ? '48px' : undefined,
                color: '#333F48',
              }}
            >
              <KotlerHour />
            </div>
            {!isNow && (
              <div id="kotlerDate" style={{ textAlign: 'center' }}>
                {activeSession
                && activeSession.currentRound
                && activeSession.currentRound.largeName ? (
                  <>
                    <IntlMessages
                      id={
                        activeSession.currentRound.largeName
                          .toLowerCase()
                          .split( ' ' )[0]
                      }
                    />
                    {` ${activeSession.currentRound.largeName.split( ' ' )[1]}`}
                    {' '}
                  </>
                  ) : (
                    ''
                  ) /* kotlerDate(locale.locale) */}
              </div>
            )}
          </div>

          <div
            id="kotlerCard"
            style={{
              verticalAlign: 'middle',
              borderRadius: 4,
              position: 'relative',
              display: 'flex',
              flex: 1,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ( {
  settings: { locale },
  user: { activeSession },
} ) => ( { locale, activeSession } );

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( withWidth()( kotlerNotification ) );
