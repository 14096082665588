import { takeEvery, put, call } from 'redux-saga/effects';

import {
  FETCH_CUSTOMER_ADQUISITION_COST_REQUEST,
  fetchCustomerAdquisitionCostFailure,
  fetchCustomerAdquisitionCostSuccess,
} from '../actions/customerAdquisitionCostActions';
import { getCustomerAdquisitionCost } from '../../customersService';

function* fetchCustomerAdquisitionCostSaga( action ) {
  try {
    const { sessionId, params = { limit: -1 } } = action.payload;
    const customerAdquisitionCostData = yield call(
      getCustomerAdquisitionCost,
      sessionId,
      params,
    );
    yield put( fetchCustomerAdquisitionCostSuccess( customerAdquisitionCostData ) );
  } catch ( error ) {
    yield put( fetchCustomerAdquisitionCostFailure( error ) );
  }
}

export default function* customerAdquisitionCostRootSaga() {
  yield takeEvery(
    FETCH_CUSTOMER_ADQUISITION_COST_REQUEST,
    fetchCustomerAdquisitionCostSaga,
  );
}
