import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import IntlMessages from 'utils/IntlMessages';
import { Button, Step, StepLabel, Stepper } from '@material-ui/core';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import CardBox from 'components/theme/CardBox';
import SettingsActions from 'store/reducers/Settings';
import EntityService from 'services/Entity';
import SupervisorService from 'services/Supervisor';

import { composeValidators, email, required } from 'config/InputErrors';
import {
  TextField,
  Checkbox,
  AsyncSelect,
  Select,
} from 'components/custom/FormElements';
import UserService from 'services/User';
import COUNTRY_LIST from 'constants/Countries/CountriesList';

class EditForm extends React.Component {
  state = {
    data: {},
    activeStep: 0,
    steps: [
      {
        id: 1,
        label: 'University',
      },
      {
        id: 2,
        label: 'Professor',
      },
    ],
  };

  entityId = null;

  componentDidMount() {
    const { entityId } = this.props;
    this.entityId = entityId;

    if ( this.entityId ) {
      this.getEntity();
    }
  }

  loadUserOptions = ( value, callback ) => {
    UserService.getSupervisorUsers( {
      offset: 0,
      limit: 10,
      'filters[criteria]': value,
    } ).then( ( response ) => {
      if ( response.ok ) {
        callback( response.data.data );
      } else {
        callback();
      }
    } );
  };

  loadUniversityOptions = ( value, callback ) => {
    EntityService.getEntities( {
      offset: 0,
      limit: 10,
      'filters[criteria]': value,
    } ).then( ( response ) => {
      if ( response.ok ) {
        callback( response.data.data );
      } else {
        callback();
      }
    } );
  };

  getEntity = () => {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    EntityService.getEntity( this.entityId ).then( ( response ) => {
      toggleLoading( false );

      if ( response.ok ) {
        this.setState( { data: response.data } );
      }
    } );
  };
  handleNext = ( data ) => {
    const { activeStep } = this.state;

    this.setState( {
      activeStep: activeStep + 1,
      // eslint-disable-next-line react/no-unused-state
      data: this.loadDataDetail( data ),
    } );
  };

  handleBack = ( data ) => {
    const { activeStep } = this.state;
    this.setState( {
      activeStep: activeStep - 1,
      // eslint-disable-next-line react/no-unused-state
      data: this.loadDataDetail( data ),
    } );
  };
  loadDataDetail = ( dataDetail ) => {
    const data = _.cloneDeep( dataDetail );
    return data;
  };
  getStepButtons = ( form ) => {
    const { activeStep } = this.state;
    return (
      <Row>
        <Col md={4} className="m-md-auto">
          <div className="text-center">
            {activeStep > 0 && (
              <Button
                variant="contained"
                className="jr-btn jr-btn-lg bg-blue-grey text-white"
                type="submit"
                onClick={() => {
                  if ( form.getState().valid ) this.handleBack( form.getState().values );
                }}
              >
                <i className="fa fa-angle-double-left" />
                <IntlMessages id="back" />
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              className="jr-btn jr-btn-lg"
              type="submit"
              onClick={() => {
                if ( form.getState().valid ) this.handleNext( form.getState().values );
              }}
            >
              <IntlMessages id="next" />
              <i className="fa fa-angle-double-right" />
            </Button>
          </div>
        </Col>
      </Row>
    );
  };
  // eslint-disable-next-line no-unused-vars
  submitForm = async ( data, form ) => {
    const { toggleLoading, toggleAlert, history } = this.props;
    const dataToSend = {};
    const dataUniToSend = {};
    let entityId = data.entity;
    if ( entityId ) {
      this.entityId = entityId;
    }
    toggleLoading( true );
    if ( !entityId && data.nameUniversity && !this.entityId ) {
      dataUniToSend.name = data.nameUniversity;
      dataUniToSend.country = data.country;
      dataUniToSend.address = data.address;
      dataUniToSend.availableLicenses = data.availableLicenses;
      dataUniToSend.email = data.emailUniversity;
      const promise = EntityService.saveUniversity( dataUniToSend );
      await promise.then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        // toggleAlert( 'dataSaved', 'info' );
        entityId = response.data.id;
        this.entityId = entityId;
      } );
    }

    // _.map( _.keys( form.getState().modified ), ( key ) => {
    //  dataToSend[key] = _.get( formData[key], 'id' ) ? formData[key].id : formData[key];
    // } );
    dataToSend.user = data.user;
    dataToSend.userEmail = data.userEmail;
    dataToSend.allSession = data.allSession;

    const promise2 = SupervisorService.saveSupervisorLicenseV2(
      this.entityId,
      dataToSend,
    );
    await promise2.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      toggleAlert( 'dataSaved', 'info' );
      history.push(
        `/universities/detail/${this.entityId}/general/supervisor-licenses`,
      );
    } );
  };

  render() {
    const { data, activeStep, steps } = this.state;
    return (
      <Form
        initialValues={data}
        onSubmit={this.submitForm}
        validate={( values ) => {
          // console.log( 'VAUES  ', values );
          const errors = {};
          if ( !values.entity && !values.nameUniversity ) {
            errors.entity = 'Required';
          }
          if (
            !values.entity
            && values.nameUniversity
            && !values.emailUniversity
          ) {
            errors.emailUniversity = 'Required';
          }
          if ( !values.entity && values.nameUniversity && !values.country ) {
            errors.country = 'Required';
          }
          if (
            !values.entity
            && values.nameUniversity
            && !values.availableLicenses
          ) {
            errors.availableLicenses = 'Required';
          }
          return errors;
        }}
        // eslint-disable-next-line no-unused-vars
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <Stepper
              activeStep={activeStep}
              className="horizontal-stepper-linear bg-transparent"
            >
              {steps.map( ( step, index ) => (
                <Step key={step.id}>
                  <StepLabel
                    className={`horizontal-stepper ${
                      index === activeStep ? 'active' : ''
                    }`}
                  >
                    <IntlMessages id={step.label} />
                  </StepLabel>
                </Step>
              ) )}
            </Stepper>
            {steps[activeStep].id === 1 && (
              <>
                <CardBox heading="selectUniversity">
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <i>
                          <IntlMessages id="newProfessor.University.step.msg" />
                        </i>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <AsyncSelect
                        field="entity"
                        label="university"
                        labelKey="name"
                        loadOptions={this.loadUniversityOptions}
                      />
                    </div>
                  </div>
                </CardBox>
                <CardBox heading="newUniversity">
                  <div className="row">
                    <div className="col-md-6">
                      <TextField field="nameUniversity" label="name" />
                      <Select
                        field="country"
                        label="country"
                        options={COUNTRY_LIST}
                        translateOptions={false}
                      />

                      {!!data.id && <Checkbox field="active" label="active" />}
                      <TextField
                        type="number"
                        field="availableLicenses"
                        label="availableLicenses"
                      />
                    </div>

                    <div className="col-md-6">
                      <TextField field="address" label="address" />
                      <TextField
                        field="emailUniversity"
                        label="email"
                        validate={composeValidators( email )}
                      />
                      {!!data.id && (
                        <Select
                          field="status"
                          label="status"
                          options={[
                            {
                              id: 'created',
                              name: 'created',
                            },
                            {
                              id: 'closed',
                              name: 'closed',
                            },
                          ]}
                          validate={required}
                        />
                      )}
                    </div>
                  </div>
                  {this.getStepButtons( form )}
                </CardBox>
              </>
            )}
            {steps[activeStep].id === 2 && (
              <>
                <CardBox heading="Professor">
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <p>
                          <i>
                            <IntlMessages id="editLicenseMsg" />
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <AsyncSelect
                          field="user"
                          label="user"
                          labelKey="email"
                          loadOptions={this.loadUserOptions}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          field="userEmail"
                          validate={email}
                          label="email"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Checkbox
                          field="allSession"
                          label="allSession.form.label"
                        />
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col md={4} className="m-md-auto">
                      <div className="text-center mt-2">
                        <Button
                          variant="contained"
                          className="jr-btn jr-btn-lg bg-blue-grey text-white"
                          type="submit"
                          onClick={() => {
                            if ( form.getState().valid ) {
                              this.handleBack( form.getState().values );
                            }
                          }}
                        >
                          <i className="fa fa-angle-double-left" />
                          <IntlMessages id="back" />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg"
                          type="submit"
                        >
                          <i className="fa fa-save" />
                          <IntlMessages id="saveBtn" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBox>
              </>
            )}
          </form>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( withRouter( EditForm ) );
