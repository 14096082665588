import { Button, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import FeatherIcon from '../FeatherIcon';

const KotlerPagination = ( {
  setGlobalPageSize,
  disableGlobal = false,
  children,
  data = [],
  initialPageSize,
  globalPageSize = 20,
  pageSizes = [5, 10, 20, 50, 100],
} ) => {
  const [pageState, setPageState] = useState( {
    last: Math.ceil( data.length / initialPageSize ),
    actually: 1,
    pageData: [],
  } );

  useEffect(
    ( ant ) => {
      if ( data ) {
        const last = Math.ceil( data.length / globalPageSize );
        const pageData = data.slice(
          ( pageState.actually - 1 ) * globalPageSize,
          pageState.actually * globalPageSize,
        );
        const newState = {
          ...pageState,
          pageData,
          last,
        };
        if ( JSON.stringify( pageState ) !== JSON.stringify( newState ) ) setPageState( newState );
      }
    },
    [data, pageState, globalPageSize],
  );

  // change page size
  const changePageSize = ( value ) => {
    setGlobalPageSize( value );
    /*     setPageState({
      ...pageState,
      pageSize: value,
    }) */
  };
  const onNext = () => {
    setPageState( {
      ...pageState,
      actually: pageState.actually + 1,
    } );
  };
  const onBack = () => setPageState( {
    ...pageState,
    actually: pageState.actually - 1,
  } );
  return (
    <div className="kotler-pagination-container">
      {children( { ...pageState, fullData: data } )}
      <Grid
        container
        justify="space-between"
        className="kotler-pagination-down-bar"
        style={{ marginTop: '10px' }}
      >
        <Grid item>
          <Select
            className="kotler-page-size-select"
            color="primary"
            IconComponent={KeyboardArrowDown}
            MenuProps={{ className: 'kotler-pagination-select' }}
            value={globalPageSize}
            onChange={e => changePageSize( e.target.value )}
          >
            {pageSizes.map( item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ) )}
          </Select>
        </Grid>
        <Grid item>
          <div className="container-actions-page">
            <Button
              onClick={onBack}
              className="action-button back-button"
              variant="outlined"
              disabled={pageState.actually <= 1}
            >
              <FeatherIcon
                icon="chevronLeft"
                color="primary.main"
                size="16px"
              />
            </Button>
            <Typography className="kotler-page-info">
              {`${pageState.actually} `}
              <IntlMessages id="of" />
              {` ${pageState.last}`}
            </Typography>
            <Button
              className="action-button  next-button"
              variant="outlined"
              onClick={onNext}
              disabled={pageState.last <= pageState.actually}
            >
              <FeatherIcon
                icon="chevronRight"
                color="primary.main"
                size="16px"
              />
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = ( { settings } ) => {
  const { globalPageSize } = settings;
  return { globalPageSize };
};

export default connect(
  mapStateToProps,
  { setGlobalPageSize: SettingsActions.setGlobalPageSize },
)( KotlerPagination );
