import React from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';

import { connect } from 'react-redux';
import ReactTablePagination from '../Pagination';

class CustomTable extends React.Component {
  render() {
    const { showPageSizeOptions, className,globalPageSize=20, ...rest } = this.props;

    return (
      <ReactTable
      className={`kotler-async-table  ${className}`}
        defaultPageSize={globalPageSize}
        
        minRows={0}
        pageSizeOptions={[5,10,15,20, 50,100]}
        resizable={false}
        showPaginationBottom={true}
        showPaginationTop={false}
        showPageSizeOptions={_.isUndefined( showPageSizeOptions ) ? true : showPageSizeOptions}
        PaginationComponent={props => <ReactTablePagination {...props} />}
        getTrProps={() => {
          return {
            className: 'pointer'
          };
        }}
        {...rest}
      />
    );
  }
}
const mapStateToProps = ({settings})=>{
  const {globalPageSize} = settings;
  return {globalPageSize};
}
export default connect(mapStateToProps)(CustomTable);

