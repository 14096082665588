import { getNameList } from 'country-list';
import { map, orderBy, some, upperFirst } from 'lodash';

const overwrite = orderBy(
  [
    {
      id: 'ar',
      name: 'Argentina',
    },
    {
      id: 'co',
      name: 'Colombia',
    },
    {
      id: 'es',
      name: 'España',
    },
    {
      id: 'uy',
      name: 'Uruguay',
    },
    {
      id: 'cl',
      name: 'Chile',
    },
    {
      id: 'pe',
      name: 'Perú',
    },
    {
      id: 'bo',
      name: 'Bolivia',
    },
    {
      id: 'ec',
      name: 'Ecuador',
    },
    {
      id: 've',
      name: 'Venezuela',
    },
    {
      id: 'pa',
      name: 'Paraguay',
    },
    { id: 'do', name: 'República Dominicana' },
  ],
  'name',
);
const FormatedCountry = orderBy(
  map( getNameList(), ( value, key ) => ( {
    id: value.toLowerCase(),
    name: upperFirst( key ),
  } ) ).filter( ( { id } ) => !some( overwrite, { id } ) ),
  'name',
);

const COUNTRY_LIST = [...overwrite, ...FormatedCountry];
export default COUNTRY_LIST;
