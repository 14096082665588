import { combineReducers } from 'redux';
import totalCustomerReducer from './totalCustomersReducer';
import customerEvolutionReducer from './customerEvolutionReducer';
import customerLifetimeValueReducer from './customerLifetimeValueReducer.js';
import customerAdquisitionCostReducer from './customerAdquisitionCostReducer';

const customerRootReducer = combineReducers( {
  totalCustomers: totalCustomerReducer,
  customerEvolution: customerEvolutionReducer,
  customerLifetimeValue: customerLifetimeValueReducer,
  adquisitionCost: customerAdquisitionCostReducer,
  // Agrega aquí más reducers específicos del módulo si los tienes
} );

export default customerRootReducer;
