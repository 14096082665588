import React from 'react';
import { Badge } from 'reactstrap';
import IntlMessages from 'utils/IntlMessages';
import './feature-badge.scss';

export const FeatureBadge = ( { type = '', className = '' } ) => (
  <Badge className={`feature-badge ${type} ${className}`} pill>
    {type ? <IntlMessages id={type} /> : type}
  </Badge>
);

export default FeatureBadge;
