import api from '../config/Api';

const endpoint = 'v1/session';
const endpointV2 = 'v2/session';

export default {

  getServices( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/service`, params );
  },

  getServicesV2( sessionId, params ) {
    return api.get( `${endpointV2}/${sessionId}/service`, params );
  },

  getService( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/service/detail`, params );
  },

  getAttributeMaxValue( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/service/attributes-max-value`, params );
  },

  getAttributeCost( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/service/attribute-cost`, params );
  },

  updateServiceRound( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/service-round`, data );
  },

  saveService( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/service`, data );
  },

  updateServiceRoundPrice( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/service-round/price`, data );
  },

  updateServicesV2( sessionId, data, isForce ) {
    let urlFragment = 'price-status';
    if ( isForce ) {
      urlFragment = 'price-status?force=1';
    } else {
      urlFragment = 'price-status';
    }
    return api.put( `${endpointV2}/${sessionId}/service/${urlFragment}`, data );
  },
};
