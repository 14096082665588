import { IconButton } from '@material-ui/core';
import ContainerGraphModal from 'components/ContainerGraphModal';
import FeatherIcon from 'components/custom/FeatherIcon';
import { useActiveSession } from 'modules/sessions';
import React, { useState } from 'react';
import IntlMessages from 'utils/IntlMessages';

const PLInfoModal = () => {
  const [open, setOpen] = useState( false );
  const { activeSession } = useActiveSession();
  const isEnterprise = activeSession && activeSession.distributionType === 'enterprise';
  return (
    <>
      <IconButton
        onClick={() => setOpen( !open )}
        className="ml-1 square-icon-buttom p-0"
      >
        <FeatherIcon icon="info" color="secondary.dark" size="16px" />
      </IconButton>
      <ContainerGraphModal
        title="ProfitLossStatement"
        open={open}
        onClose={() => setOpen( false )}
      >
        <div className="p-3  mt-3">
          <p className="">
            <b className="text-primary">
              <IntlMessages id="RevenuesFromGoodSold" />
            </b>
            {' '}
            :
            {' '}
            <IntlMessages id="plInfoProducts" />
          </p>
          {isEnterprise && (
            <>
              <br />

              <p>
                <b className="text-primary">
                  <IntlMessages id="RevenuesFromServ" />
                </b>
                {' '}
                :
                {' '}
                <IntlMessages id="plInfoServices" />
              </p>
            </>
          )}

          {isEnterprise && (
            <>
              <br />

              <p>
                {' '}
                <b className="text-primary">
                  <IntlMessages id="RevenuesFromBundlesSales" />
                </b>
                {' '}
                :
                {' '}
                <IntlMessages id="plInfoBundles" />
              </p>
            </>
          )}
        </div>
      </ContainerGraphModal>
    </>
  );
};

export default PLInfoModal;
