import React, { Component } from 'react';


import IntlMessages from 'utils/IntlMessages';
import { Button, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';
import { KeyboardArrowDown } from '@material-ui/icons';
import {connect} from "react-redux";
import SettingsActions from 'store/reducers/Settings';

class ReactTablePagination extends Component {
  constructor( props ) {
    super();

    this.getSafePage = this.getSafePage.bind( this );
    this.changePage = this.changePage.bind( this );
    this.applyPage = this.applyPage.bind( this );

    this.state = { page: props.page };
  }

  componentWillReceiveProps( nextProps ) {

  this.props.setGlobalPageSize(nextProps.pageSize) 
    this.setState( { page: nextProps.page } );
  }

  getSafePage( page ) {
    if ( isNaN( page ) ) {
      page = this.props.page;
    }
    return Math.min( Math.max( page, 0 ), this.props.pages - 1 );
  }

  changePage( page ) {
    page = this.getSafePage( page );
    this.setState( { page } );
    if ( this.props.page !== page ) {
      this.props.onPageChange( page );
    }
  }

  applyPage( e ) {
    e && e.preventDefault();
    const { page } = this.state;
    this.changePage( page === '' ? this.props.page : page );
  }

  render() {
    let {
      // Computed
      pages,
      // Props
      page,
      //showPageSizeOptions,
      pageSizeOptions,
     // showPageJump,
      canPrevious,
      canNext,
     // className,
      onPageSizeChange,
      pageSize,
     // extraElement,
    } = this.props;

    

    return (
      <div className="kotler-pagination-container">
  
      <Grid
        container
        justify="space-between"
        className="kotler-pagination-down-bar"
        style={{ marginTop: '10px' }}
      >
        <Grid item>
          <Select
            className="kotler-page-size-select"
            color="primary"
            IconComponent={KeyboardArrowDown}
            MenuProps={{ className: 'kotler-pagination-select' }}
            value={pageSize}
            onChange={e=>onPageSizeChange(e.target.value)}
          >
            {pageSizeOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <div className="container-actions-page">
            <Button
              onClick={( e ) => {
                if ( !canPrevious ) return;
                this.changePage( page - 1 );
              }}
              className="action-button back-button"
              variant="outlined"
              disabled={!canPrevious}
              /* disabled={ pageState.actually<=1 } */
            >
              <FeatherIcon
                icon="chevronLeft"
                color="primary.main"
                size="16px"
              />
            </Button>
            <Typography className="kotler-page-info">
              {`${page+1} `}
              <IntlMessages id="of" />
              {` ${pages || 1}`}
            </Typography>
            <Button
              onClick={( e ) => {
                if ( !canNext ) return;
                this.changePage( page + 1 );
              }}
              className="action-button  next-button"
              variant="outlined"
           
              disabled={page+1 >=pages }
            >
              <FeatherIcon
                icon="chevronRight"
                color="primary.main"
                size="16px"
              />
            </Button>
          </div>
        </Grid>
      </Grid>
      </div>
    
    );
  }
}

export default connect(null,{setGlobalPageSize:SettingsActions.setGlobalPageSize})(ReactTablePagination)