import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Container, Grid, Hidden, Typography } from '@material-ui/core';

import {
  composeValidators,
  equalTo,
  passwordWeakValidation,
  required,
} from 'config/InputErrors';
import TextField from 'components/custom/FormElements/TextField';
import UserActions from 'store/reducers/User';
import BackgroundImage from 'assets/images/kotler/login-background.jpeg';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';
import logo from 'assets/images/logo-livemax-kotler.png';
import LiveMaxVersion from 'components/layouts/LiveMaxVersion';
import { Col, Row } from 'reactstrap';
import FeatherIcon from 'components/custom/FeatherIcon';
import { LanguageButton } from 'components/custom/LanguageButton';
import IntlMessages from 'utils/IntlMessages';

class RecoverPassword extends React.Component {
  componentDidMount() {
    const { accessToken, logoutUser } = this.props;
    if ( accessToken ) logoutUser();
  }

  submitForm = ( data ) => {
    const { toggleLoading, toggleAlert, history, match } = this.props;
    toggleLoading( true );
    // eslint-disable-next-line max-len
    UserService.recoverPasswordConfirmation( match.params.token, { password: data.password } ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );

      history.push( '/login' );
      toggleAlert( 'passwordResetted', 'info' );
    } );
  };

  render() {
    return (
      <div
        className="app-login-container d-flex flex-column  animated slideInUpTiny animation-duration-3 bg-image "
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <Hidden smDown>
          <div
            style={{
              background:
                'linear-gradient(270.87deg, #FFFFFF 33.81%, rgba(255, 255, 255, 0) 96.88%)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: '50%',
            }}
          />
        </Hidden>
        <Hidden mdUp>
          <div
            style={{
              background: 'rgba(255,255,255,.4)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            }}
          />
        </Hidden>
        <div className=" bg-primary">
          <LanguageButton />
        </div>
        <Container
          maxWidth="xl"
          style={{ zIndex: 2 }}
          className="d-flex flex-grow-1  "
        >
          <Grid
            container
            className="d-flex flex-grow-1  justify-content-end w-100"
          >
            <Grid
              item
              xs={12}
              md={8}
              lg={6}
              xl={4}
              className="d-flex flex-grow-1 justify-content-center "
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={11} sm={5} md={6} lg={6}>
                  <div
                    className="d-flex flex-column"
                    style={{ rowGap: '30px' }}
                  >
                    <div className="d-flex justify-content-center ">
                      <Link to="/" title="LiveMax" replace>
                        <img
                          alt="LiveMaX-Kotler-logo"
                          src={logo}
                          className="logo-login"
                        />
                      </Link>
                    </div>

                    <Row>
                      <Col>
                        <div className="d-flex justify-content-between ">
                          <Typography
                            variant="subtitle1"
                            className="intro-login-text w-100 text-center"
                          >
                            <IntlMessages id="enterNewPassword" />
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                    <Form
                      onSubmit={this.submitForm}
                      render={( { handleSubmit } ) => (
                        <form onSubmit={handleSubmit} noValidate>
                          <Row>
                            <Col>
                              <TextField
                                containerClass="kotler-text-field-white-form-control mb-3"
                                className="kotler-text-field-white"
                                startAdornment={<FeatherIcon icon="lock" />}
                                field="password"
                                type="password"
                                label="password"
                                autoComplete="username"
                                marginContainer={false}
                                validate={composeValidators(
                                  required,
                                  passwordWeakValidation,
                                )}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextField
                                containerClass="kotler-text-field-white-form-control mb-3"
                                className="kotler-text-field-white"
                                marginContainer={false}
                                startAdornment={<FeatherIcon icon="lock" />}
                                field="confirmPassword"
                                type="password"
                                label="confirmPassword"
                                validate={composeValidators(
                                  required,
                                  equalTo( 'password' ),
                                  passwordWeakValidation,
                                )}
                                translateValues={{ label: <IntlMessages id="password" /> }}
                                autoComplete="current-password"
                              />
                            </Col>
                          </Row>

                          <Grid
                            container
                            className="flex-column-reverse flex-sm-row "
                          >
                            <Grid item xs={12} className=" ">
                              <Button
                                fullWidth
                                style={{ minWidth: 112 }}
                                variant="contained"
                                color="primary"
                                className="kotler-button-lg px-0"
                                type="submit"
                              >
                                <IntlMessages id="save" />
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <LiveMaxVersion style={{ position: 'absolute', bottom: 0, right: 0 }} />
      </div>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => ( {
  accessToken: user.access_token,
  isLoading: settings.isLoading,
} );

const mapDispatchToProps = {
  loginUser: UserActions.loginUser,
  logoutUser: UserActions.logoutUser,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  changeActiveSession: UserActions.changeActiveSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( RecoverPassword );
