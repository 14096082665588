import React, { useEffect, useMemo } from 'react';

import { useFetch, useLocale } from 'hooks';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import Image from 'assets/images/kotler/sectionHeaders/register.jpeg';
import { FeatureTable, getChangelog } from 'modules/changelog';

const ChangelogDetail = ( { match } ) => {
  // params
  const { changelogId } = match.params;
  const { locale } = useLocale();
  // eslint-disable-next-line max-len
  const { data: changelog, fetchData: fetchChangelog } = useFetch( params => getChangelog( changelogId, params ) );

  useEffect( () => {
    fetchChangelog();
  }, []); //eslint-disable-line

  const title = useMemo( () => {
    if ( !changelog ) return 'changelog';
    if ( locale && locale.locale === 'es' ) return changelog.esDescription;

    return changelog.enDescription;
  }, [changelog, locale] );
  return (
    <>
      <KotlerSectionHeader
        title={title}
        image={Image}
        noTranslateTitle={!!changelog}
        backTo="/changelogs"
      />

      <FeatureTable changelogId={changelogId} />
    </>
  );
};

export default ChangelogDetail;
