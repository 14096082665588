import { getHttpRequest } from 'utils/ApiPetitionhandles';

// const endpoint = 'v1/session';
// const endpoint2 = 'v2/session';

export async function getMarketSizeStimation( sessionId, params ) {
  const url = `v2/marketing-size-estimation/${sessionId}`;
  return getHttpRequest( url, params );
}

export default { getMarketSizeStimation };
