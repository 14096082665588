export const FETCH_PRICE_PRODUCTS_REQUEST = 'FETCH_PRICE_PRODUCTS_REQUEST';
export const FETCH_PRICE_PRODUCTS_SUCCESS = 'FETCH_PRICE_PRODUCTS_SUCCESS';
export const FETCH_PRICE_PRODUCTS_FAILURE = 'FETCH_PRICE_PRODUCTS_FAILURE';
export const CLEAR_PRICE_PRODUCTS = 'CLEAR_PRICE_PRODUCTS';

export const fetchPriceProductsRequest = ( sessionId, categoryId, params ) => ( {
  type: FETCH_PRICE_PRODUCTS_REQUEST,
  payload: { sessionId, categoryId, params },
} );

export const fetchPriceProductsSuccess = ( { categoryId, data } ) => ( {
  type: FETCH_PRICE_PRODUCTS_SUCCESS,
  payload: { categoryId, data },
} );

export const fetchPriceProductsFailure = ( { categoryId, error } ) => ( {
  type: FETCH_PRICE_PRODUCTS_FAILURE,
  payload: { categoryId, error },
} );

export const clearPriceProducts = () => ( { type: CLEAR_PRICE_PRODUCTS } );
