import { useEffect, useState } from 'react';

export const useTyping = (
  message,
  { onFinishTyping, onInitTyping } = {},
  letterTimeout = 10, // Aumenté el valor predeterminado para que sea más fácil de seguir
) => {
  const [text, setText] = useState( '' );
  const [isTyping, setIsTyping] = useState( false );

  useEffect( () => {
    if ( message && typeof message === 'string' ) {
      if ( onInitTyping ) onInitTyping();
      setText( '' );
      setIsTyping( true );
      let currentIndex = 0;
      const interval = setInterval( () => {
        setText( ( prevText ) => {
          const newText = prevText + message.charAt( currentIndex );
          currentIndex += 1;
          if ( currentIndex === message.length ) {
            clearInterval( interval );
            setIsTyping( false );
          }
          return newText;
        } );
      }, letterTimeout );

      return () => clearInterval( interval );
    }
  }, [message, onInitTyping, letterTimeout] );

  useEffect( () => {
    if ( onFinishTyping && message && message === text ) onFinishTyping( text );
  }, [text, message, onFinishTyping] );

  const clearText = () => setText( '' );

  return [text, clearText, isTyping];
};
