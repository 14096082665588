import api from '../config/Api';

const endpoint = 'v2/session';

export default {
  getStrategies( sessionId, workGroupId, params ) {
    return api.get( `${endpoint}/${sessionId}/workgroup/${workGroupId}/strategies`, params );
  },

  getAllStrategies( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/workgroup/strategies/all`, params );
  },
};
