import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { formatMessage } from 'lngProvider';
import { showLoading } from 'utils/alerts';
import { useActiveSessionQuery } from './useActiveSessionQuery';
import { transformedSessionSchemas } from '../schemas/transformed-session-schema';

const ID = { ROUND_CLOSING: 'is_closing_currrent_round' };


const closeAllAlerts = () => {
  Object.values( ID ).forEach( id => toast.dismiss( id ) );
};

const manageAlert = ( activeSession ) => {
  const { data: session, error } = transformedSessionSchemas.safeParse(
    activeSession,
  );
  // eslint-disable-next-line no-console
  if ( error ) console.error( error );

  const { isClosingCurrentRound } = session;
  if ( isClosingCurrentRound ) {
    showLoading( formatMessage( ID.ROUND_CLOSING ), { id: ID.ROUND_CLOSING } );
  } else toast.dismiss( ID.ROUND_CLOSING );
};

export const useAlertSessionManager = () => {
  const { data, isPlaceholderData } = useActiveSessionQuery();
  useEffect( () => {
    if ( data && data.id && !isPlaceholderData ) {
      manageAlert( data );
    } else closeAllAlerts();
  }, [data, isPlaceholderData] );
};
