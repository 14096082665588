import { takeEvery, put, call } from 'redux-saga/effects';

import {
  FETCH_TOTAL_CUSTOMER_REQUEST,
  fetchTotalCustomerFailure,
  fetchTotalCustomerSuccess,
} from '../actions/totalCustomersActions';
import { getTotalCustomer } from '../../customersService';

function* fetchTotalCustomerSaga( action ) {
  try {
    const { sessionId, params = { limit: -1 } } = action.payload;
    const totalCustomerData = yield call( getTotalCustomer, sessionId, params );
    yield put( fetchTotalCustomerSuccess( totalCustomerData ) );
  } catch ( error ) {
    yield put( fetchTotalCustomerFailure( error ) );
  }
}

export default function* totalCustomerRootSaga() {
  yield takeEvery( FETCH_TOTAL_CUSTOMER_REQUEST, fetchTotalCustomerSaga );
}
