import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
} from '@material-ui/core';
import ProfileAvatarEditor from 'components/custom_v2/ProfileAvatarEditor';
import IntlMessages from 'utils/IntlMessages';
import KotlerHeaderFullWidthModal from 'components/custom/KotlerHeaderFullWidthModal';


const ProfilePicture = ( { newSrc, isOpen, src, editAvatar, avatarEditor, onClose } ) => (
  <>

    <div className="profile-picture mt-2 rounded-0">
      <div className="img__overlay img__overlay--green" onClick={avatarEditor}>
        <i className="zmdi zmdi-hc-2x zmdi-camera" />
      </div>
      <img src={newSrc || src} alt="" style={{ borderRadius: '4px' }} className=" mx-auto d-block " />
    </div>
    <div style={{ display: 'flex', gap: '10px', padding: '10px 0px' }}>
      <Button className="kotler-btn py-0" variant="contained" color="primary" onClick={avatarEditor}>
        <IntlMessages id="changePhoto" />
      </Button>
      <Button
        className="kotler-btn  py-0"
        /*     onClick={() => {

          editAvatar( <img src={defaultPicture} /> );
        }} */
        disabled
        variant="outlined"
        color="secondary"
      >
        <IntlMessages id="deletePhoto" />
      </Button>
    </div>

    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <KotlerHeaderFullWidthModal title="changePhoto" onClose={onClose} />


      <DialogContent className="p-5 overflow-visible">
        <ProfileAvatarEditor onSave={editAvatar} />
      </DialogContent>
    </Dialog>
  </>
);

export default ProfilePicture;
