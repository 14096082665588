import React, { useState, useEffect } from 'react';
import { Badge } from 'reactstrap';
import { AppBar, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
// import moment from 'moment';
import SessionService from 'services/Session';
import DashboardStudentService from 'services/DashboardStudent';
import _ from 'lodash';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import UserActions from 'store/reducers/User';
import { parallel } from 'async';
import { STATUS } from 'modules/sessions';
import MarketBudgetExecutionModal from './marketBudgetExecutionModal';
import HoverTitle from '../../../../../components/custom_v2/HoverTitle';

const getMarketingBudget = ( { workgroup, data } ) => ( cb ) => {
  SessionService.getMarketingBudgetExecution(
    workgroup.id,
    data.currentRound.id,
  ).then( ( response ) => {
    if ( response.ok ) return cb( null, response.data );
    return cb( response.errors );
  } );
};
const getCompanyBalance = ( { user, workgroup } ) => ( cb ) => {
  DashboardStudentService.getHistoricalChart(
    user.activeSession.id,
    workgroup.id,
    { limit: -1 },
  ).then( ( response ) => {
    if ( response.ok ) return cb( null, response.data );
    return cb( response.errors );
  } );
};
let peticion = false;
const SessionInfoBar = React.memo(
  ( {
    addGroupLowMarketingBudgetNotification,
    isSupervisor,
    locale,
    history,
    user,
    router,
    position = 'absolute',
  } ) => {
    const data = user.activeSession;

    // eslint-disable-next-line max-len
    let currentlicense = {};
    try {
      // eslint-disable-next-line max-len
      currentlicense = _.find(
        user.licenses,
        license => license.session.id === user.activeSession.id,
      );
    } catch ( e ) {
      //
    }

    // eslint-disable-next-line max-len
    if (
      !currentlicense
      || !currentlicense.workGroup
      || !data
      || !data.currentRound
      || data.currentRound.length <= 0
    ) return null;
    const workgroup = currentlicense.workGroup;
    const [
      budgetMarketingExecutionModal,
      setBudgetMarketingExecutionModal,
    ] = useState( false );
    const [
      budgetMarketingExecutionData,
      setBudgetMarketingExecutionData,
    ] = useState( {} );
    const [patrimony, setPatrimony] = useState( null );

    useEffect( () => {
      // eslint-disable-next-line max-len
      if (
        workgroup.id
        && data
        && data.currentRound
        && data.currentRound.id
        && peticion === false
        && user
      ) {
        peticion = true;
        parallel(
          {
            marketingBudget: getMarketingBudget( { workgroup, user, data } ),
            companyBalance: getCompanyBalance( { user, workgroup } ),
          },
          ( error, { marketingBudget, companyBalance } ) => {
            peticion = false;
            if ( marketingBudget ) setBudgetMarketingExecutionData( marketingBudget );
            if ( companyBalance ) setPatrimony( companyBalance.balance.patrimony );
          },
        );
      }
    }, [workgroup, router.location.pathname, data, user] );

    useEffect( () => {
      const timeId = setTimeout( () => {
        // eslint-disable-next-line max-len
        if (
          workgroup.id
          && data
          && data.currentRound
          && data.currentRound.id
          && peticion === false
          && user
        ) {
          peticion = true;
          parallel(
            {
              marketingBudget: getMarketingBudget( { workgroup, user, data } ),
              companyBalance: getCompanyBalance( { user, workgroup } ),
            },
            ( error, { companyBalance, marketingBudget } ) => {
              peticion = false;

              if ( marketingBudget ) setBudgetMarketingExecutionData( marketingBudget );
              if ( companyBalance ) setPatrimony( companyBalance.balance.patrimony );
            },
          );
        }
      }, 40000 );

      return () => {
        window.clearTimeout( timeId );
      };
    } );
    useEffect( () => {
      if (
        workgroup
        && patrimony
        && patrimony <= 200000000
        && !_.find( user.groupLowMarketingBudgetNotification, { id: workgroup.id } )
      ) {
        addGroupLowMarketingBudgetNotification( workgroup.id );
        setTimeout( () => {
          toast.warning(
            <div>
              <div style={{ textAlign: 'center' }}>
                <h1 style={{ color: '#04A89E' }}>
                  <IntlMessages id="testRoundTipTitle" />
                </h1>
              </div>
              <div style={{ marginTop: '30px' }}>
                <p>
                  <IntlMessages id="lowMaketingMessagesNotification" />
                </p>
              </div>
            </div>,
            {
              position: 'top-left',
              autoClose: 50000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              onClose: () => () => {},
            },
          );
        }, 3000 );
      }
    }, [
      workgroup,
      addGroupLowMarketingBudgetNotification,
      user.groupLowMarketingBudgetNotification,
      patrimony,
    ] );

    const translateRoundName = ( roundName, lo ) => {
      let transName = roundName;
      if ( lo === 'es' ) {
        transName = transName.replace( 'January', 'Enero' );
        transName = transName.replace( 'February', 'Febrero' );
        transName = transName.replace( 'March', 'Marzo' );
        transName = transName.replace( 'April', 'Abril' );
        transName = transName.replace( 'May', 'Mayo' );
        transName = transName.replace( 'June', 'Junio' );
        transName = transName.replace( 'July', 'Julio' );
        transName = transName.replace( 'August', 'Agosto' );
        transName = transName.replace( 'September', 'Septiembre' );
        transName = transName.replace( 'October', 'Octubre' );
        transName = transName.replace( 'November', 'Noviembre' );
        transName = transName.replace( 'December', 'Diciembre' );
      }
      return transName;
    };
    const availableMKBudget = ( d, budgetData ) => ( d.marketingBudget || 0 )
      - ( budgetData.cxInvested
        + budgetData.cxCommitted
        + budgetData.popInvested
        + budgetData.popCommitted
        + budgetData.massMediaCampInvested
        + budgetData.massMediaCampCommitted
        + budgetData.directMarketingCampInvested
        + budgetData.directMarketingCampCommitted
        + budgetData.researchInvested
        + budgetData.researchCommitted );

    const toogleModal = () => {
      setBudgetMarketingExecutionModal( !budgetMarketingExecutionModal );
    };

    return (
      <div
        className="app-header-horizontal "
        style={{
          zIndex: '2',
          overflowX: 'hidden',
          position,
          left: 0,
          top: 0,
          right: 0,
        }}
      >
        <AppBar
          position="relative"
          style={{ width: '100vw' }}
          className="student-subheader px-4 d-flex "
        >
          {data.currentRound ? (
            <Grid container justify="flex-end" alignItems="center">
              <Grid
                item
                sx={12}
                md="auto"
                className="d-flex align-items-center mt-2"
              >
                <HoverTitle id="infoBar.info.hover">
                  <Typography
                    variant="body2"
                    color={
                      history.location.pathname !== '/'
                        ? 'textSecondary'
                        : 'textSecondary'
                    }
                    className="ml-2   font-weight-bold text-uppercase"
                  >
                    Prof:
                    {' '}
                    {data.supervisor && data.supervisor.name
                      ? data.supervisor.name
                      : data.currentSupervisor
                        ? data.currentSupervisor.name
                        : ' ... '}
                    {', '}
                    <IntlMessages id="simulationDate" />
                    {': '}
                    {data.currentRound
                      ? translateRoundName(
                        data.currentRound.largeName,
                        locale.locale,
                      )
                      : '-'}
                    {workgroup.name ? ' - ' : ' '}
                    {workgroup.name ? <IntlMessages id="group" /> : ' '}
                    {workgroup.name ? ': ' : ' '}
                    {workgroup.name ? ` ${workgroup.name}` : ' '}
                  </Typography>
                </HoverTitle>
              </Grid>
              {!isSupervisor && (
                <Grid item>
                  <div className="d-flex align-items-center justify-content-end flex-wrap">
                    <Badge
                      onClick={toogleModal}
                      className={
                        availableMKBudget( data, budgetMarketingExecutionData )
                        < 0
                          ? ' mt-2 m-0 ml-2 text-uppercase  text-white text-muted pointer subheader-badge-danger'
                          : 'ml-2 m-0 mt-2 text-uppercase  text-white text-muted pointer subheader-badge'
                      }
                    >
                      <IntlMessages id="availableMarketingBudget" />
                      {': '}
                      {Util.formatNumber(
                        availableMKBudget( data, budgetMarketingExecutionData ),
                      )}
                    </Badge>

                    <Badge
                      className=" m-0 ml-2 mt-2 text-uppercase text-white subheader-badge text-muted"
                      style={{ cursor: 'default' }}
                    >
                      <IntlMessages id="points" />
                      {': '}

                      {Util.formatNumber(
                        // eslint-disable-next-line max-len
                        _.find(
                          user.activeSession.workGroups,
                          { id: currentlicense.workGroup.id },
                          null,
                        )
                          ? _.find(
                            user.activeSession.workGroups,
                            { id: currentlicense.workGroup.id },
                            null,
                          ).newStockValue
                          : 0,
                      )}
                    </Badge>
                    <Badge
                      className="m-0 ml-2 mt-2 text-uppercase text-white subheader-badge text-muted"
                      style={{ cursor: 'default' }}
                    >
                      <IntlMessages id="round" />
                      {' '}
                      {data.currentRound.number
                      && user
                      && user.activeSession
                      && user.activeSession.status !== STATUS.READY_NOT_STARTED
                        ? data.currentRound.number
                        : '-'}
                      {' '}
                      <IntlMessages id="of" />
                      {' '}
                      {data.rounds ? data.rounds.length - 4 : ' '}
                    </Badge>
                  </div>
                </Grid>
              )}
            </Grid>
          ) : (
            <span />
          )}
        </AppBar>
        <MarketBudgetExecutionModal
          open={budgetMarketingExecutionModal}
          onClose={toogleModal}
          title="marketingBudget"
          data={budgetMarketingExecutionData}
          groupBudget={workgroup.marketingBudget}
          marketingBudget={data.marketingBudget}
        />
      </div>
    );
  },
);
const mapStateToProps = ( { settings, router, user } ) => {
  const { locale } = settings;
  return { locale, router, user };
};

export default connect(
  mapStateToProps,
  {
    addGroupLowMarketingBudgetNotification:
      UserActions.addGroupLowMarketingBudgetNotification,
  },
)( withRouter( SessionInfoBar ) );
