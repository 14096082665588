import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import Util from "utils/Util";
import { IconButton } from "@material-ui/core";
import _ from 'lodash';

import ReactExport from "react-export-excel";
import FeatherIcon from "components/custom/FeatherIcon";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const font = { sz: "12", bold: false, shadow: false };
const fill = { patternType: "solid", fgColor: { rgb: "FFFFFF" } };
const fill2 = { patternType: "solid", fgColor: { rgb: "E5E5E5" } };
const alignment = {
  horizontal: "left",
  vertical: "center",
};
const styleTitleMain = {
  font: { ...font, bold: true },
  fill: { ...fill, fgColor: { rgb: "09A99E" } },
  alignment: { ...alignment, horizontal: "right" },
};
const styleTitle = {
  font: { ...font, bold: true },
  fill,
  alignment,
};
const styleTitleData = {
  font,
  fill,
  alignment,
};
const styleData = {
  font,
  fill,
  alignment: { ...alignment, horizontal: "right" },
};
const styleTotalTitle = {
  font,
  fill: { ...fill2 },
  alignment,
};
const styleTotalData = {
  font,
  fill: { ...fill2 },
  alignment: { ...alignment, horizontal: "right" },
};
const styleTotalBoldTitle = {
  font: { ...font, bold: true },
  fill: { ...fill2 },
  alignment,
};
const styleTotalBoldData = {
  font: { ...font, bold: true },
  fill: { ...fill2 },
  alignment: { ...alignment, horizontal: "right" },
};
const  titNameGenerate  = ({...rest})=> ([
  {
    "value": "",
    "style": {
      "font": {
        "sz": "12",
        "bold": true,
        "shadow": false
      },
      "fill": {
        "patternType": "solid",
        "fgColor": {
          "rgb": "09A99E"
        }
      },
      "alignment": {
        "horizontal": "left",
        "vertical": "center"
      }
    },
    ...rest
  }]);

const fN = (value) => (value && value !== 0 ? Util.formatNumber(value) : "0");

const fNPorc = (value, total) => {
  if (!value || !total) return value === 0 || total === 0 ? "0%" : "";

  return Util.formatNumberFixedDec((value / total) * 100) + "%";
};

const formatData = (data, intl) => {
  const fM = (id) => (id ? intl.formatMessage({ id }) : "");
  if (!data) return [{ columns: [""], data: [""] }];
  
  const excelData = [];
  

  const objectOrder = {
    workgroup: [null],
    renueves: ["Revenues"],
    revenuesFromGoodsSold: ["RevenuesFromGoodSold"],
    revenuesFromServicesSold: ["RevenuesFromServ"],
    revenuesFromBundlesSold: ["RevenuesFromBundlesSales"],
    totalRevenuesFromSales: ["TotalRevenuesFromSales"],
    directExpenses: ["DirectExpenses"],
    costOfGoodsSold: ["CostOfGoodsSold"],
    costOfServicesSold: ["CostOfServicesSold"],
    costOfBundlesSold: ["CostOfBundlesSold"],
    totalCostsFormSales: ["TotalCostFromSales"],
    grossProfit: ["Grossprofit"],
    indirectExpenses: ["IndirectExpenses"],
    generalAdministrative: ["GeneralAdministrative"],
    marketingCost: ["MarketingCosts"],
    directSalesCost: ["DirectSalesCosts"],
    stockHandlingCosts: ["StockHandlingCosts"],
    rd: ["RandD"],
    interestsFromLoans: ["InterestsFromLoans"],
    totalIndirectExpenses: ["TotalIndirectsExpenses"],
    earningBeforeTaxes: ["EarningsBeforeTax"],
    incomeTaxes: ["Incometaxes"],
    netEarnings: ["Netearnings"],
  };

  data.map((item) => {
    for (const key in item) {
      if (objectOrder[key]) {
        if (key !== "workgroup") objectOrder[key].push(fN(item[key]));
        else objectOrder[key].push(item[key].name);
        if (
          key === "grossProfit" ||
          key === "earningBeforeTaxes" ||
          key === "netEarnings"
        )
          objectOrder[key].push(fNPorc(item[key], item.totalRevenuesFromSales));
        else objectOrder[key].push("");
      }
    }
    return 0;
  });

  for (const key in objectOrder) {
    if (Object.hasOwnProperty.call(objectOrder, key)) {
      const element = objectOrder[key];

      const row = element.map((item, index) => {
        let style = styleData;

        if (key.includes("total"))
          style = index === 0 ? styleTotalTitle : styleTotalData;
        else if (
          key === "renueves" ||
          key === "directExpenses" ||
          key === "indirectExpenses"
        )
          style = styleTitle;
        else if (key === "workgroup") style = styleTitleMain;
        else if (
          key === "grossProfit" ||
          key === "earningBeforeTaxes" ||
          key === "netEarnings"
        )
          style = index === 0 ? styleTotalBoldTitle : styleTotalBoldData;
        else style = index === 0 ? styleTitleData : styleData;

        if (index === 0) return { value: fM(item), style };
        return { value: item, style };
      });

      excelData.push(row);
    }
  }

  return excelData;
};

// Helper function to find round object
const findRound = (dataArray, id) => { 
  return _.find( dataArray, (item) => (item.round.id === id));
};

const transformData = (data, intl) => {
  // Step # 1  finding workgroup and reestructuring
  let a = _.map(data, (item) => {
    let result = _.map(item.historical, (itemb) => {
      itemb['workgroup'] = {"id": item.workgroup.id, "name": item.workgroup.name}
      return itemb;
    })
    return result;
  });

  // Step # 2 flatting arrays and grouping
  let roundsFlat  = _.flattenDepth(a);
  let rounds = _.groupBy(roundsFlat, (item) => {
    return item.round.id;
  });
  
  // Step # 3 find round object
  var finalArr = _.map(rounds, (item, key) => {
    var dataResult = { "round": {...findRound(roundsFlat,key).round} } 
    dataResult.round.data = [...item];
    
    return dataResult
  });

  //Step # 4 spaces, content and title cells
  var datos = finalArr;
  let dataAuxArr = [];
  const spaceData = [ { "value" : " " } ];
  _.map(datos, (item) => {
    dataAuxArr.push( titNameGenerate({value:item.round.shortName}) );
    dataAuxArr.push(...formatData(item.round.data, intl));
    dataAuxArr.push(spaceData);
  });
  return dataAuxArr;
};

const ExcellPL = ({ data, name, intl }) => {
  const [disableExportButton, setDisableExportButton] = useState(true);
  const [dataExcell, setDataExcell] = useState([]);
  useEffect(() => {
    if (!data || data.length <= 0) setDisableExportButton(true);
   
    else {
      const formattedData = transformData( data, intl );
      const excelData = [
        {
          columns: [""],
          data: formattedData,
        },
      ];
      setDataExcell(excelData);
      setDisableExportButton(false);
    }
  }, [data, intl]);

  return !disableExportButton ? (
    <ExcelFile
      element={
        <div>
          <IconButton color="primary" className="square-icon-button">
            <FeatherIcon icon="download" color="primary.main" size="18px" />
          </IconButton>
        </div>
      }
      filename={name}
    >
      <ExcelSheet dataSet={dataExcell} name="MontlyProfit" />
    </ExcelFile>
  ) : (
    <IconButton color="primary" className="square-icon-button" disabled>
      <FeatherIcon icon="download" size="18px" />
    </IconButton>
  );
};

export default injectIntl(ExcellPL);
