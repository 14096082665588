import api from '../config/Api';

const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

export default {

  getGeneralData( id ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/general-data` );
  },

  getRankingRounds( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/ranking-round`, params );
  },

  getRankingChart( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/ranking-graph`, params );
  },

  getProducts( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/products`, params );
  },

  getServices( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/services`, params );
  },

  getBundles( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/bundles`, params );
  },

  getUnitsSalesMargin( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/units-sales-margin`, params );
  },

  getStockValueAllWorkgroup( id, params ) {
    return api.get( `${endpoint2}/${id}/stock-value/all-workgroup`, params );
  },

  getServiceBundleSalesAllWorkgroup( id, params ) {
    return api.get( `${endpoint2}/${id}/service-bundle-sales/all-workgroup`, params );
  },

  getEarningBeforeIncomingTaxAllWorkgroup( id, params ) {
    return api.get( `${endpoint2}/${id}/ebit/all-workgroup`, params );
  },
  getCategories( params ) {
    // params = { 'filters[session]' = XXXX }
    return api.get( 'v1/category', params );
  },
  getMarketProductsAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/market-products/all-workgroup`, params );
  },

  getPriceSalesProductAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/price-sales-product/all-workgroup`, params );
  },

  getPriceProductAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/price-product/all-workgroup`, params );
  },

  getActivityEffectivenessAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/activity-effectiveness/all-workgroup`, params );
  },

  getCustomerActivityAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/customer-activity/all-workgroup`, params );
  },

  getCustomerAcquisitionCostAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/customer-acquisition-cost/all-workgroup`, params );
  },

  getCustomerRetentionSatisfactionAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/customer-retention-satisfaction/all-workgroup`, params );
  },

  getShareOfVoiceAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/share-of-voice/all-workgroup`, params );
  },

  getDirectMarketingInvestmentWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/direct-marketing-investment/all-workgroup`, params );
  },

  getShareOfVoiceMarketAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/share-of-voice-market/all-workgroup`, params );
  },

  getLifetimeValueAllWorkGroup( id, params ) {
    // params = { category = XXXX }
    return api.get( `${endpoint2}/${id}/customer-lifetime-value/all-workgroup`, params );
  },

};
