import { formatMessage } from 'helpers/IntlHelpers';
import { flatMap } from 'lodash';

const formatLabels = labels => flatMap( labels, value => [
  {
    value: formatMessage( { id: value } ),
    style: {
      alignment: {
        horizontal:
            value === 'PriceS' || value === 'SalesU' || value === 'SalesS'
              ? 'right'
              : 'left',
      },
      font: { bold: true },
    },
  },
] );

const formatValues = ( data ) => {
  const formattedData = [];

  flatMap( data, ( item ) => {
    item.bundles.forEach( ( itemB ) => {
      formattedData.push( [
        item.name,
        itemB.name,
        itemB.price,
        itemB.unitSales,
        itemB.revenues,
      ] );
    } );
  } );

  return formattedData;
};

export const formatlastBundlesByCategoryToExcel = (
  data,
  title = 'Detail bundles by group',
) => {
  const sheetData = {
    xSteps: 0,
    ySteps: 1,
    columns: [formatMessage( { id: title } )],
    data: [],
  };

  if ( !data || !data.length ) return [sheetData];
  const labelRow = ['group', 'name', 'monthlyFee', 'SalesU', 'SalesS'];

  const dataToExcel = formatValues( data );
  sheetData.data = [formatLabels( labelRow ), ...dataToExcel];
  return [sheetData];
};

export default { formatlastBundlesByCategoryToExcel };
