import React, { useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import './LiveAdCodeInput.scss';
import { Field } from 'react-final-form';

export const LiveAdCodeInput = ( { isLoading } ) => {
  const [height, setHeight] = useState( 410 );
  const textareaRef = useRef( null );

  const onKeyUp = ( e ) => {
    if ( e.key === 'Tab' ) {
      e.preventDefault(); // Evita el comportamiento predeterminado del navegador
    }
    if ( !textareaRef.current || textareaRef.current.scrollHeight === height - 2 ) return;

    setHeight( textareaRef.current.scrollHeight + 10 );
  };
  return (
    <Box className="flex-grow-1 bg-dark   rounded box-code-container">
      <Box className="text-area-container">
        <span className="pre-html pre-html-top">{'<body>'}</span>
        <Field name="body">
          {( { input } ) => (
            <textarea
              {...input}
              disabled={isLoading}
              ref={textareaRef}
              style={{ height: `${height}px` }}
              onKeyUp={onKeyUp}
              // maxLength={250}
              className="text-area-code"
              placeholder="//Place your code here!"
            />
          )}
        </Field>
        <span className="pre-html pre-html-bottom ">{'<body>'}</span>
      </Box>
    </Box>
  );
};

export default LiveAdCodeInput;
