import React from 'react';

import { Route, Switch } from 'react-router';
import { Container } from '@material-ui/core';
import Error404 from 'components/theme/Error404';
import Chart from './Chart';
import Company from './Company';
import Products from './Products';
import Customer from './Customer';
import Market from './Market';
import PublicMarket from './PublicMarket';
import Research from './Research';
import Positioning from './Research/Positioning';
import Activity from './Activities';

const General = ( { match } ) => (
  <div>
    <Container className="mt-3" maxWidth="lg">
      <Switch>
        <Route exact path={`${match.url}/chart`} component={Chart} />
        <Route exact path={`${match.url}/company`} component={Company} />
        <Route exact path={`${match.url}/products`} component={Products} />
        <Route exact path={`${match.url}/customer`} component={Customer} />
        <Route exact path={`${match.url}/market`} component={Market} />
        <Route
          exact
          path={`${match.url}/publicmarket`}
          component={PublicMarket}
        />
        <Route
          exact
          path={`${match.url}/positioning/:id`}
          component={Positioning}
        />
        <Route exact path={`${match.url}/research`} component={Research} />
        <Route exact path={`${match.url}/activity`} component={Activity} />
        <Route exact path={`${match.url}`} render={Error404} />
        <Route path={`${match.url}/*`} render={Error404} />
      </Switch>
    </Container>
  </div>
);

export default General;
