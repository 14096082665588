import HoverTitle from 'components/custom_v2/HoverTitle';
import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { IconButton } from '@material-ui/core';
import LanguageSwitcher from 'components/theme/LanguageSwitcher';
import FeatherIcon from '../FeatherIcon';

export const LanguageButton = ( { className = '' } ) => {
  const [openLanguage, setOpenLanguage] = useState( false );
  return (
    <Dropdown
      className={`quick-menu user ${className}`}
      isOpen={openLanguage}
      toggle={() => setOpenLanguage( !openLanguage )}
    >
      <HoverTitle id="language" placement="left">
        <DropdownToggle
          style={{ position: 'absolute', zIndex: 100, top: 20, right: 40 }}
          tag="span"
          data-toggle="dropdown"
        >
          <IconButton className="icon-btn  square-icon-buttom">
            <FeatherIcon icon="globe" size="18px" color="primary.main" />
          </IconButton>
        </DropdownToggle>
      </HoverTitle>

      <DropdownMenu
        className="kotler-drop-down-menu"
        style={{ top: 20, marginRight: 20, padding: 0 }}
      >
        <LanguageSwitcher />
      </DropdownMenu>
    </Dropdown>
  );
};
