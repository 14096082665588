const nowFields = {
  information: {
    company: {
      profitAndLoss: {
        revenuesFromServ: false,
        revenuesFromBundlesSales: false,
        costOfServicesSold: false,
        costOfBundlesSold: false,
        comparison: false,
        results: false,
      },
      balance: false,
    },
    products: false,
    market: false,
    research: {
      mk001: false,
      mk002: false,
      mk003: {
        celular: true,
        laptop: false,
        tablet: false,
      },
      mk004: false,
      mk006: false,
    },
    customer: {
      registeredCustomerBase: false,
      evolutionCustomerBase: false,
    },
  },
  decision: {
    products: {
      laptop: false,
      tablet: false,
      celular: true,
    },
    price: {
      laptop: false,
      tablet: false,
      celular: true,
    },
    services: false,
    distribution: false,
    cx: false,
    communications: {
      informationStep: {
        defineTarget: {
          servicePromotion: false,
          bundlePromotion: false,
          productRegistration: false,
        },
      },
      promotionStep: { prodAttr: false },
      mediaStep: false,
      internetStep: {
        retargeting: false,
        ia: false,
      },
    },
    oneToOne: false,
    strategy: false,
  },
};

export const getSessionFields = ( activeSession ) => {
  if ( activeSession ) {
    if ( activeSession.distributionType === 'studio' ) {
      return {
        type: 'studio',
        isEnterprise: false,
        isNow: false,
        isStudio: true,
        fields: activeSession.studioFields,
      };
    }
    if ( activeSession.distributionType === 'enterprise' ) {
      return {
        type: 'enterprise',
        isEnterprise: true,
        isNow: false,
        isStudio: false,
        fields: activeSession.enterpriseFields,
      };
    }
    if ( activeSession.distributionType === 'now' ) {
      return {
        type: 'now',
        isEnterprise: false,
        isNow: true,
        isStudio: false,
        fields: activeSession.nowFields,
      };
    }
  }
  return {
    type: 'none',
    isEnterprise: false,
    isNow: false,
    isStudio: false,
    noSessionType: true,
    fields: nowFields,
  };
};
