import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Container,
  Typography,
  useTheme,
} from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';
import lvlogo from 'assets/images/logo-livemax-kotler-light.png';
import blackLogo from 'assets/images/logos/white.png';
import SwipeableViews from 'react-swipeable-views';
// import Logo from '../assets/livemax-news-logo.jpeg';
import { connect } from 'react-redux';
import IntlMessages from 'utils/IntlMessages';
import moment from 'moment';
import TabletImage from '../assets/tablet.png';
import LiveNewsList from './LiveNewsList';
import LiveNewsService from '../services/LiveNewsService';

const LiveNewsTablet = ( { activeSession = {}, locale } ) => {
  const [newsList, setNewsList] = useState( [] );
  const [showList, setShowList] = useState( true );
  const [onDisplay, setOnDisplay] = useState( false );
  const theme = useTheme();
  const [value, setValue] = React.useState( 0 );
  const handleChangeIndex = ( index ) => {
    setValue( index );
  };

  useEffect( () => {
    if ( activeSession && activeSession.id ) {
      const fetchData = async () => {
        const response = await LiveNewsService.getLiveNews( activeSession.id );
        if ( response.ok ) {
          setOnDisplay( true );
          setValue( 0 );
          setNewsList( response.data.data );
        }
      };

      // call the function
      fetchData()
        // eslint-disable-next-line no-console
        .catch( console.error );
    }
  }, [activeSession] );
  return ( newsList && newsList.length )
    || ( activeSession && activeSession.status !== 'SESSION_EXPIRED' ) ? (
      <div className="kotler-live-news-tablet">
        <div
          className="tablet-case"
          style={{ backgroundImage: `url(${TabletImage})` }}
        />
        <div
          className="btn-physical"
          onMouseDown={( e ) => {
            e.preventDefault();
            return setOnDisplay( !onDisplay );
          }}
        />
        <div
          className={`display ${
            onDisplay && newsList.length ? '' : 'display-off'
          }`}
          onClick={() => {
            if ( !onDisplay ) setOnDisplay( true );
          }}
        >
          <div className="display-off-screen d-flex justify-content-center align-items-center flex-column ">
            {!newsList.length && (
              <div className="d-flex justify-content-center align-items-center flex-column animate__animated animate__tada animate__slower animate__delay-1s animate__infinite">
                <img src={blackLogo} alt="logo" width="50%" />
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className="text-center"
                >
                  <IntlMessages id="Incoming Live News" />
                ...
                </Typography>
              </div>
            )}
          </div>
          <div className="selected-news d-flex  flex-1  ">
            <div className="live-news-article  h-100 ">
              <AppBar
                position="relative"
                color="inherit"
                className="notice-app-bar"
              >
                <Container className="d-flex justify-content-center py-3  ">
                  <Button
                    style={{ display: newsList.length ? 'flex' : 'none' }}
                    className="live-news-button-show px-4"
                    onClick={() => setShowList( !showList )}
                  >
                    <FeatherIcon
                      icon="alignLeft"
                      size={24}
                      color="primary2.main"
                    />
                  </Button>
                  <img src={lvlogo} alt="live-news-logos " height="18px" />
                </Container>
              </AppBar>

              <div className="notice-container">
                <SwipeableViews
                  className="h-100"
                  allowFullScreen
                  containerStyle={{ height: '100%' }}
                  slideClassName="h-100"
                  enableMouseEvents
                  style={{ display: newsList && newsList.length ? 'block' : 'none' }}
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  animateTransitions
                  onChangeIndex={handleChangeIndex}
                  ignorarNativeScroll
                >
                  {newsList.map( ( notice, index ) => (
                    <TabPanel
                      key={notice.id}
                      value={value}
                      notice={notice}
                      index={index}
                      dir={theme.direction}
                    />
                  ) )}
                </SwipeableViews>
                <span className="footer-chatgpt">
                  <IntlMessages id="Powered by ChatGPT" />
                  {' '}
                  {moment()
                    .locale( locale.locale )
                    .format( 'DD  MMMM YYYY' )}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`news-list-container ${
              showList && newsList.length ? 'show' : ''
            }`}
          >
            <LiveNewsList
              show={showList}
              newsList={newsList}
              indexSelected={value}
              onClickNotice={handleChangeIndex}
            />
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
};

const TabPanel = connect( ( { settings } ) => ( { locale: settings.locale } ) )(
  ( props ) => {
    const { index, notice, locale, ...others } = props;

    // round name array to get the first word and the second ej ["december", "'01"]
    const roundName = notice.currentRound.largeName
      .toLowerCase()
      .trim()
      .split( ' ' );

    // img selected
    const imgUrl = notice.images && notice.images.length
      ? notice.images[0]
      : 'https://cdn.elobservador.com.uy/082023/1690909498407/pexels-karolina-grabowska-4386442-jpg..webp?&extw=jpg&cw=1170';

    const theme = useTheme();
    return (
      <div
        className="h-100 overflow-auto "
        {...others}
        role="tabpanel"
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
      >
        <AppBar
          position="relative"
          color="inherit"
          elevation={0}
          className="skills-bar"
        >
          <Container className="d-flex justify-content-between align-items-center ">
            {/* <img
                    src={lvlogo}
                    alt="livemax"
                    height={16}
                    className="mx-3"
                  /> */}
            {/* <Typography variant="h6">{notice.session.name}</Typography> */}
            <Typography variant="h6" color="primary">
              <IntlMessages id={roundName[0]} />
              {` ${roundName[1]}`}
            </Typography>
          </Container>
        </AppBar>
        <Container>
          <Typography
            // onMouseMove={( event ) => {
            //   event.stopPropagation();
            // }}
            variant="h4"
            style={{ color: theme.palette.primary2.main }}
            className="w-auto mb-3 mt-2"
          >
            {notice.title[locale.locale] || notice.title.en}
          </Typography>
          <img className="w-100 notice-img" src={imgUrl} alt="Notice" />
          <Typography
            variant="body1"
            style={{ whiteSpace: 'pre-wrap' }}
            className=" w-auto text-justify"
          >
            {/* eslint-disable  */}
            <span
              dangerouslySetInnerHTML={{
                __html: notice.article[locale.locale] || notice.article.en
              }}
            />
          </Typography>
        </Container>
      </div>
    );
  }
);
const mapStateToProps = ({ user, settings }) => ({
  activeSession: user && user.activeSession,
  locale: settings.locale
});
export default connect(mapStateToProps)(LiveNewsTablet);
