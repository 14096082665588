import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { find, map } from 'lodash';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';

const formatDataToTable = ( workgroups ) => {
  if ( !workgroups ) return [];
  return map( workgroups, ( workgroup ) => {
    const totals = { revenues: 0, unitSales: 0 };
    return {
      key: workgroup.id,
      name: workgroup.name,
      products: map( workgroup.products, ( product ) => {
        totals.revenues += product.revenues;
        totals.unitSales += product.unitSales;
        return {
          key: product.id,
          name: product.name,
          price: Util.formatCurrency( product.price ),
          revenues: Util.formatCurrency( product.revenues ),
          unitSales: Util.formatNumber( product.unitSales ),
        };
      } ),
      totals: {
        unitSales: Util.formatCurrency( totals.unitSales ),
        revenues: Util.formatCurrency( totals.revenues ),
      },
    };
  } );
};

const TablesLastRoundProduct = ( {
  lastRoundProductWorkgroupData,
  //   loading,
  //   error,
} ) => {
  const [dataToTable, setDataToTable] = useState( [] );
  useEffect( () => {
    const formatData = formatDataToTable( lastRoundProductWorkgroupData );
    setDataToTable( formatData );
  }, [lastRoundProductWorkgroupData] );

  return (
    <>
      {map( dataToTable, workgroup => (
        <div key={workgroup.key}>
          <KotlerCardHeader
            noTranslate
            minHeight="0px"
            className="mt-5 mb-2"
            title={workgroup.name}
          />

          <div>
            <Table className="kotler-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IntlMessages id="model" />
                  </TableCell>
                  <TableCell align="right">
                    <IntlMessages id="share_u" />
                  </TableCell>
                  <TableCell align="right">
                    <IntlMessages id="share_u2" />
                  </TableCell>
                  <TableCell align="right">
                    <IntlMessages id="price" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {map(
                  workgroup.products,
                  ( { key, name, price, revenues, unitSales } ) => (
                    <TableRow key={key}>
                      <TableCell>{name}</TableCell>
                      <TableCell align="right">{unitSales}</TableCell>
                      <TableCell align="right">{revenues}</TableCell>
                      <TableCell align="right">{price}</TableCell>
                    </TableRow>
                  ),
                )}
                {!!workgroup.products.length && (
                  <TableRow>
                    <TableCell>
                      <IntlMessages id="total" />
                    </TableCell>
                    <TableCell align="right">
                      {workgroup.totals.unitSales}
                    </TableCell>
                    <TableCell align="right">
                      {workgroup.totals.revenues}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}
                {!workgroup.products.length && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <IntlMessages id="NoDataFound" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      ) )}
    </>
  );
};

const mapStateToProps = ( { products }, { categoryId } ) => {
  if ( !categoryId ) {
    return {
      lastRoundProductWorkgroupData: undefined,
      loading: true,
      error: null,
    };
  }

  const product = find( products.lastRoundProducts.data, { id: categoryId } );

  return {
    lastRoundProductWorkgroupData: product && product.workgroupData,
    loading: products.lastRoundProducts.loading,
    error: products.lastRoundProducts.loading.error,
  };
};

export default connect( mapStateToProps )( TablesLastRoundProduct );
