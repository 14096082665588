import api from '../config/Api';

const baseEndpoint = 'v1/session';
const baseEndpointV2 = 'v2/session';

export default {
  getCems( id, params ) {
    return api.get( `${baseEndpoint}/${id}/cem`, params );
  },
  getCemImage(
    sessionId,
    workGroupId,
    {
      contentType,
      service,
      // serviceFeature,
      // serviceFeature2,
      product,
      // productFeature,
      // productFeature2,
      bundle,
      // bundleFeature,
      // bundleFeature2,
    },
  ) {
    return api.post( `v2/image-campaign/${sessionId}`, {
      workgroup: workGroupId,
      contentType,
      service,
      // serviceFeature,
      // serviceFeature2,
      product,
      // productFeature,
      // productFeature2,
      bundle,
      // bundleFeature,
      // bundleFeature2,
    } );
  },

  getCemsV2( id, params ) {
    return api.get( `${baseEndpointV2}/${id}/cem`, params );
  },

  getCem( id, params ) {
    return api.get( `${baseEndpoint}/${id}/cem/detail`, params );
  },

  getAttributeMaxValue( id, params ) {
    return api.get( `${baseEndpoint}/${id}/cem/attributes-max-value`, params );
  },

  getAttributeMaxValueV2( id, params ) {
    return api.get( `${baseEndpointV2}/${id}/cem/attributes-max-value`, params );
  },

  getAttributeCost( id, params ) {
    return api.get( `${baseEndpoint}/${id}/cem/attribute-cost`, params );
  },

  getAttributeCostV2( id, params ) {
    return api.get( `${baseEndpointV2}/${id}/cem/attribute-cost`, params );
  },

  updateCem( id, data ) {
    return api.put( `${baseEndpoint}/${id}/cem`, data );
  },

  updateCemV2( id, data, isForce = false ) {
    let urlFragment = 'cem';
    if ( isForce ) {
      urlFragment = 'cem?force=1';
    } else {
      urlFragment = 'cem';
    }
    return api.put( `${baseEndpointV2}/${id}/${urlFragment}`, data );
  },
};
