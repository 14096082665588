import { queryClient } from 'config/react-query';
import { useQuery, useQueryClient } from 'react-query';
import SessionService from 'services/Session';

const getSessionById = async ( sessionId ) => {
  const res = await SessionService.getSession( sessionId );
  if ( res.ok ) {
    return res.data;
  }
};
export const useSessionByIdQuery = ( sessionId ) => {
  const sessionQuery = useQuery(
    ['v1', 'session', sessionId],
    () => getSessionById( sessionId ),
    {
      staleTime: 1000 * 60 * 20,
      cacheTime: 1000 * 60 * 60 * 12,
      refetchInterval: 1000 * 60 * 10,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  );

  return {
    sessionQuery,
    workgroups: sessionQuery.data && sessionQuery.data.workGroups,
  };
};
// export const UseRoundsQuery = props => props.children( useRoundsQuery( props.sessionId ) );

export const useSetSessionDataQuery = () => {
  const reactQueryClient = useQueryClient();

  const setSessionQueryData = ( sessionId, data ) => {
    reactQueryClient.setQueryData( ['v1', 'session', sessionId], data );
  };

  const getSessionQueryData = sessionId => reactQueryClient.getQueryData( ['v1', 'session', sessionId] );
  // set data when not exists
  const setDefaultSessionQueryData = ( sessionId, data ) => {
    const current = getSessionQueryData( sessionId );
    if ( !current ) setSessionQueryData( sessionId, data );
  };
  return {
    setSessionQueryData,
    getSessionQueryData,
    setDefaultSessionQueryData,
  };
};
export const setSessionQueryData = ( sessionId, data ) => {
  queryClient.setQueryData( ['v1', 'session', sessionId], data );
};

export const getSessionQueryData = sessionId => queryClient.getQueryData( ['v1', 'session', sessionId] );

export const setDefaultSessionQueryData = ( sessionId, data ) => {
  const current = getSessionQueryData( sessionId );
  if ( !current ) {
    queryClient.setQueryDefaults( ['v1', 'session', sessionId], { placeholderData: data } );
  }
};
