import { LiveAdContainer, getLiveAdActions } from 'modules/LiveAds';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const AdPoisitionerContainer = () => {
  const ad = useSelector( ( { ads } ) => ads.getLiveAd.ad );
  const distpatch = useDispatch();
  const sessionId = useSelector(
    ( { user } ) => user && user.activeSession && user.activeSession.id,
  );
  useEffect( () => {
    if ( sessionId ) {
      distpatch( getLiveAdActions.fetchAdRequest( sessionId ) );
    }
  }, [sessionId, distpatch] );
  return (
    <div
      style={{ padding: '20% 0' }}
      className="d-flex h-100 w-100 flex-column justify-content-center  align-items-end "
    >
      {!!ad && ad.active && <LiveAdContainer ad={ad} />}
    </div>
  );
};

export default AdPoisitionerContainer;
