/* eslint-disable react/jsx-indent */
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import async from 'async';
import {
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import { required } from 'config/InputErrors';
import CemService from 'services/Cem';
import HoverTitle from 'components/custom_v2/HoverTitle';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import HeaderImage from 'assets/images/kotler/sectionHeaders/cx.jpeg';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import SectionInfoText from 'components/custom/SectionInfoText';
import Slider from 'components/custom/FormElements/Slider';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import HeadConfig from 'components/custom/HeadConfig';
import { Prompt } from 'react-router-dom';
import { STATUS } from 'modules/sessions';

class Service extends React.Component {
  state = {
    data: {},
    confirmSaveModal: false,
    confirmExitModal: false,
  };
  dirtyForm = false;
  canExit = false;
  goTo = '/';

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }

  handleKeyPress = ( event ) => {
    const { user } = this.props;
    const { confirmExitModal } = this.state;
    if ( event.key === 'Enter' ) {
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        if (
          user
          && user.activeSession
          && user.activeSession.status !== STATUS.READY
        ) return;
        if ( !confirmExitModal ) {
          this.setState( { confirmSaveModal: true } );
        }
      }
    }
  };

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel(
      {
        data: ( cb ) => {
          // eslint-disable-next-line max-len
          CemService.getCemsV2( this.activeSession.id, { workgroup: this.license.workGroup.id } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            const { data } = response;
            data.attributesParam = _.map( data.attributesParam, attribute => ( {
              ...attribute,
              value: attribute.value.toString(),
            } ) );
            cb( null, data );
          } );
        },
        maxValues: ( cb ) => {
          // eslint-disable-next-line max-len
          CemService.getAttributeMaxValueV2( this.activeSession.id, { workgroup: this.license.workGroup.id } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) {
          return toggleAlert( error );
        }
        const { data } = results;
        _.map( results.maxValues, ( item ) => {
          // eslint-disable-next-line max-len
          const attributeIndex = _.findIndex( results.data.attributesParam, { attribute: { id: item.id } } );
          if ( attributeIndex !== -1 ) {
            data.attributesParam[attributeIndex].values = _.map(
              new Array( 10 ),
              ( value, index ) => ( {
                id: index + 1,
                value: index + 1,
                disabled: index >= item.maxValue,
              } ),
            );
          }
        } );
        this.setState( { data } );
      },
    );
  };

  getAttributeCost = ( id, value, previousValue, index ) => {
    const { toggleLoading } = this.props;
    if ( value === previousValue ) return;

    toggleLoading( true );
    CemService.getAttributeCostV2( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      attributeCem: id,
      value,
    } ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        this.form.change( `attributes[${index}].value`, previousValue );
        this.setState( {
          attributeError: response.errors,
          attributeIdError: id,
        } );
      } else {
        this.form.change( `attributes[${index}].cost`, response.data.cost );
        this.setState( {
          attributeError: null,
          attributeIdError: null,
        } );
        // this.setState( { confirmSaveModal: true } );
      }
    } );
  };

  submitForm = ( formData ) => {
    const { toggleLoading, toggleAlert, intl } = this.props;
    const { data } = this.state;

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      cemRound: data.id,
      attributeData: _.map( formData.attributes, item => ( {
        attributeCem: item.attribute.id,
        value: Number( item.value ),
      } ) ),
    };

    toggleLoading( true );
    CemService.updateCemV2( this.activeSession.id, dataToSend ).then( ( response ) => {
      if ( !response.ok ) {
        toggleLoading( false );
        const messageErr = response.errors
          === 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET'
          ? intl.formatMessage( {
            id:
                  'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
          } )
          : response.errors;
        return toggleAlert( messageErr );
      }

      toggleAlert( 'dataSaved', 'info' );
      this.getData();
    } );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  setConfirmExitModal = () => {
    const { history } = this.props;
    this.canExit = true;
    if ( this.goTo && this.goTo !== '' ) history.push( this.goTo );
  };

  toggleConfirmExitModal = () => {
    const { confirmExitModal } = this.state;
    this.setState( { confirmExitModal: !confirmExitModal } );
  };
  selectTableIcon = ( name ) => {
    if ( name === 'Service Process' ) return 'services';
    if ( name === 'Sales Process' ) return 'bag';
    if ( name === 'Complaint Management' ) return 'messageCircle';
    if ( name === 'Site Usability' ) return 'loader';
    return 'services';
  };

  render() {
    // eslint-disable-next-line max-len
    const {
      data,
      confirmSaveModal,
      attributeIdError,
      attributeError,
      confirmExitModal,
    } = this.state;
    const { user } = this.props;
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'DecisionDashboard-cx' },
          ]}
        />
        <KotlerSectionHeader
          title="cx"
          image={HeaderImage}
          titleSubmit="save"
          titleCancel="cancelBtn"
          cancelTo="/"
          onSubmit={() => this.toggleConfirmSaveModal()}
        />
        <SectionInfoText title="help.cem.snackbar" />
        <Form
          initialValues={{ attributes: data.attributesParam }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, dirty, form } ) => {
            this.form = form;
            return (
              <form onSubmit={handleSubmit}>
                <Prompt
                  when={!this.canExit}
                  message={( location ) => {
                    const { canExit } = this;
                    if ( dirty && !canExit ) {
                      this.goTo = location.pathname;
                      this.toggleConfirmExitModal();
                      return false;
                    }
                    return true;
                  }}
                />

                <Grid container>
                  <Grid item xs={12}>
                    <Table size="small" className="kotler-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <IntlMessages id="model" />
                          </TableCell>
                          <TableCell style={{ width: '40%' }}>
                            <IntlMessages id="quality" />
                          </TableCell>
                          <TableCell align="right" style={{ width: '25%' }}>
                            <HoverTitle id="MonthlyCostCompanyToProvideSelectedLevelCustomerExperience">
                              <span>
                                <IntlMessages id="monthlyCost" />
                                {' ($)'}
                              </span>
                            </HoverTitle>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray name="attributes">
                          {( { fields } ) => fields.map( ( attributeField, index ) => {
                            const attribute = fields.value[index];
                            if ( !attribute ) return null;
                            return [
                              <TableRow key={attribute.id}>
                                <TableCell>
                                  <KotlerTableTitle
                                    iconClass="mx-0"
                                    icon={this.selectTableIcon(
                                      attribute.attribute.name,
                                    )}
                                    title={attribute.attribute.name}
                                  />
                                </TableCell>
                                <TableCell
                                  size="medium"
                                  className="text-center"
                                >
                                  <Slider
                                    /*       marks={
                                          [

                                            {
                                              value: 1,
                                              label: '1',
                                            },
                                            {
                                              value: 2,
                                              label: '2',
                                            },
                                            {
                                              value: 3,
                                              label: '3',
                                            },
                                            {
                                              value: 4,
                                              label: '4',
                                            },
                                            {
                                              value: 5,
                                              label: '5',
                                            },
                                            {
                                              value: 6,
                                              label: '6',
                                            },
                                            {
                                              value: 7,
                                              label: '7',
                                            },
                                            {
                                              value: 8,
                                              label: '8',
                                            },
                                            {
                                              value: 9,
                                              label: '9',
                                            },
                                            {
                                              value: 10,
                                              label: '10',
                                            },
                                          ]
                                        } */

                                    leftContent={(
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: 500,
                                          color: '#09082C',
                                        }}
                                      >
                                        <IntlMessages id="low" />
                                      </Typography>
                                    )}
                                    rightContent={(
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: 500,
                                          color: '#09082C',
                                        }}
                                      >
                                        <IntlMessages id="high" />
                                      </Typography>
                                    )}
                                    validate={required}
                                    containerClass="w-100"
                                    field={`${attributeField}.value`}
                                    disabled={
                                      user
                                        && user.activeSession
                                        && user.activeSession.status
                                          !== STATUS.READY
                                    }
                                    onChange={( value, previousValue ) => {
                                      this.getAttributeCost(
                                        attribute.attribute.id,
                                        value,
                                        previousValue,
                                        index,
                                      );
                                    }}
                                    min={1}
                                    max={10}
                                  />
                                  {attributeIdError
                                      === attribute.attribute.id && (
                                    <FormHelperText className="text-danger">
                                      {attributeError}
                                    </FormHelperText>
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  <HoverTitle id="MonthlyCostCompanyToProvideSelectedLevelCustomerExperience">
                                    <span>
                                      {Util.formatCurrency( attribute.cost )}
                                    </span>
                                  </HoverTitle>
                                </TableCell>
                              </TableRow>,
                            ];
                          } )
                          }
                        </FieldArray>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
                <ConfirmationDialog
                  open={confirmSaveModal}
                  title="confirmSave"
                  showIcon
                  type="save"
                  confirmBtnText="save"
                  closeBtnText="cancelBtn"
                  onSubmit={() => {
                    this.toggleConfirmSaveModal();
                    handleSubmit();
                  }}
                  onClose={() => {
                    this.toggleConfirmSaveModal();
                    form.reset();
                  }}
                />
                <ConfirmationDialog
                  open={confirmExitModal}
                  title="confirmExitWithOutSaving"
                  subTitle="subtitleExitWithoutSaving"
                  confirmBtnText="yesModalBtn"
                  closeBtnText="cancelBtn"
                  onSubmit={() => {
                    this.toggleConfirmExitModal();
                    this.setConfirmExitModal();
                  }}
                  onClose={this.toggleConfirmExitModal}
                />
              </form>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( Service ) );
