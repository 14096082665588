import * as z from 'zod';

export const RoleSchema = z.enum( ['ROLE_CLIENT', 'ROLE_USER', 'ROLE_ADMIN'] );
export const RoundSchema = z.object( {
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
} );

export const WorkGroupMemberSchema = z.object( {
  id: z.string(),
  name: z.string(),
  email: z.string(),
  roles: z.array( RoleSchema ),
} );

export const ServiceDatumSchema = z.object( {
  round: RoundSchema,
  id: z.number(),
  margin: z.number(),
  target: z.number(),
  percentMargin: z.number(),
  pop: z.number(),
  totalCost: z.number(),
  totalSales: z.number(),
  tradeSalePrice: z.number(),
  unitCost: z.number(),
  unitSales: z.number(),
  unitTradeSales: z.number().optional(),
  active: z.boolean(),
} );

export const ServiceSchema = z.object( {
  id: z.string(),
  name: z.string(),
  serviceData: z.array( ServiceDatumSchema ),
} );

export const AllRoundServicesSchema = z.object( {
  workGroupId: z.string(),
  workGroupName: z.string(),
  workGroupNumber: z.number(),
  workGroupMembers: z.array( WorkGroupMemberSchema ),
  bundles: z.array( z.any() ),
  services: z.array( ServiceSchema ),
} );
