import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

const TextOnlyTooltip = withStyles( {
  tooltip: {
    color: '#fff',
    backgroundColor: '#003594',
  },
} )( Tooltip );
const HoverTitle = ( {
  id,
  children,
  intl,
  value,
  propsTooltip,
  className,
  placement,
  key,
  values,
} ) => ( id ? (
  <TextOnlyTooltip
    key={key}
    placement={placement}
    PopperProps={{ className }}
    {...propsTooltip}
    title={
      value
        ? intl.formatMessage( { id }, { value } )
        : intl.formatMessage( { id }, values )
    }
  >
    {children}
  </TextOnlyTooltip>
) : (
  children
) );
export default injectIntl( HoverTitle );
