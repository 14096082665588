export const FETCH_LAST_ROUND_BUNDLES_REQUEST = 'FETCH_LAST_ROUND_BUNDLES_REQUEST';
export const FETCH_LAST_ROUND_BUNDLES_SUCCESS = 'FETCH_LAST_ROUND_BUNDLES_SUCCESS';
export const FETCH_LAST_ROUND_BUNDLES_FAILURE = 'FETCH_LAST_ROUND_BUNDLES_FAILURE';
export const CLEAR_LAST_ROUND_BUNDLES = 'CLEAR_LAST_ROUND_BUNDLES';

export const fetchLastRoundBundlesRequest = (
  sessionId,
  workgroupId,
  params,
) => ( {
  type: FETCH_LAST_ROUND_BUNDLES_REQUEST,
  payload: { sessionId, workgroupId, params },
} );

export const fetchLastRoundBundlesSuccess = data => ( {
  type: FETCH_LAST_ROUND_BUNDLES_SUCCESS,
  payload: data,
} );

export const fetchLastRoundBundlesFailure = error => ( {
  type: FETCH_LAST_ROUND_BUNDLES_FAILURE,
  payload: error,
} );

export const clearLastRoundBundles = () => ( { type: CLEAR_LAST_ROUND_BUNDLES } );
