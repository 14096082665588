import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import IntlMessages from 'utils/IntlMessages';
import { DialogContent, DialogTitle, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import FeatherIcon from 'components/custom/FeatherIcon';

export default ({ translateValues,disableMarginBotton=false, open, onSubmit, onClose, title,titleValues={},titleFontSize,
    confirmBtnText = false, showCancel = true, closeOnClickOutside = true,type="warning"
    , showIcon = false, focusCancel = true, closeBtnText = 'closeBtn', subTitle, children }) => {
    const { palette } = useTheme();
    const iconStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginButtom:"10px"
    }



    return (
        <Dialog open={open} onClose={onClose} disableBackdropClick={!closeOnClickOutside} fullWidth className='p-1 m-1' PaperProps={{ className: "px-sm-4 py-4 mx-0 w-100", style: { borderRadius: "2px", maxWidth: "500px" } }} >
            <DialogTitle className='p-0 ml-1'>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                     <Typography variant='h5' style={{ fontWeight: 600,fontSize:titleFontSize }} color='textPrimary' >
                     {title &&<IntlMessages id={title} values={titleValues} />}
                    </Typography>
                    {onClose ? (
                        <IconButton
                            aria-label="close"
                            className="square-icon-button"
                            onClick={onClose}
style={!title?{position:"absolute",top:20,right:20}:{}}
                        >
                            <Close style={{ color: palette.secondary.dark }} />
                        </IconButton>
                    ) : null}
                </div>
            </DialogTitle>
            
            <DialogContentText className={`pl-1 ${children || disableMarginBotton ?"mb-0":'my-4'}`}>
                {showIcon &&
                    <span style={iconStyles}>
                        {type === "warning" &&
                            <FeatherIcon className="mb-2" icon="alertTriangle" size="50px" color="secondary.main"  />
                        }
                    {/*     {type === "success" &&
                            <img src={success ? checkIcon : ''} height={120} width={120} alt="Success" />
                        } */}
                         {type === "save" &&
                            <FeatherIcon icon="uploadCloud" className="mb-2" size="50px" color="primary.main"  />
                        }
                    </span>
                }

                {subTitle && (
                    <Typography variant="body1" component="span" style={{ fontWeight: 500 }} color='textPrimary' >
                        <IntlMessages id={subTitle} />
                    </Typography>
                )}
            </DialogContentText>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-start' }}>
                <Grid container spacing={1}>
                    {showCancel &&
                        <Grid  item xs={12} sm="auto" >
                            <Button fullWidth variant="outlined" color="secondary" style={{ fontSize: "0.86rem", fontWeight: 500, maxHeight: "32px", textTransform: "none", borderRadius: "2px" }} className='px-4 py-1 ' onClick={onClose} autoFocus={focusCancel ? true : false}>
                                <IntlMessages id={closeBtnText} />
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} sm="auto">
                        <Button fullWidth variant="contained" color="primary" style={{ fontSize: "0.86rem", fontWeight: 500, textTransform: "none", borderRadius: "2px" }} className='px-4 py-1 ' onClick={onSubmit} >
                            {confirmBtnText ? <IntlMessages id={confirmBtnText} /> : <IntlMessages id={confirmBtnText} />}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}