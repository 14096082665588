import { createMuiTheme } from '@material-ui/core';
import pink from '@material-ui/core/colors/red';

export default createMuiTheme( {
  palette: {
    primary: {
      light: '#407EC9',
      main: '#003594',
      dark: '#8094DD',
      contrastText: '#fff',
    },
    text: { primary: '#333F48', secondary: '#fff' },
    secondary: {
      light: '#5F728E',
      main: pink.A200,
      dark: '#333F48',
      contrastText: '#fff',
    },
    primary2: {
      main: '#009681',
      light: '#2CD5C4',
    },
    warning: { main: '#F0AF0C' },
    error: { main: '#F56954' },
  },
  status: { danger: 'orange' },
  typography: {
    fontFamily: '"Poppins",  sans-serif',
    fontSize: 12,
    button: {
      fontWeight: 400,
      textAlign: 'center',
      style: { minHeight: '32px' },
    },
  },

  props: {
    MuiTextField: { inputProps: { style: { borderColor: 'error' } } },
    MuiDialog: { PaperProps: { style: { borderRadius: '2px' } } },
  },
} );
