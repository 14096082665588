import React from 'react';

import { connect } from 'react-redux';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-activity.jpeg';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';


import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import LogService from 'services/Log';

import { injectIntl } from 'react-intl';

import CardBox from 'components/custom_v2/CardBox';
import ChartModal from 'routes/App/components/User/Chart';

import SettingsActions from 'store/reducers/Settings';
import parallel from 'async/parallel';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import ActivityTable from './activityTable';

class Activity extends React.Component {
  state = { chartModal: false };

  componentDidMount() {
    this.loadAllChart();
  }
  componentDidUpdate( prevProps ) {
    const { locale } = this.props;
    if ( locale !== prevProps.locale ) {
      am4core.disposeAllCharts();
      this.loadAllChart();
    }
  }
  componentWillUnmount() {
    am4core.disposeAllCharts();
  }
  customOrderBy = ( obj ) => {
    try {
      return obj.name.split( '#' )[1];
    } catch ( e ) {
      return obj.name;
    }
  }
  loadAllChart = () => {
    const { user, toggleLoading, intl } = this.props;
    toggleLoading( true );
    let result1 = null;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    parallel( [
      ( cb ) => {
        LogService.getActionsByGroupsRound( user.activeSession.id )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
      ( cb ) => {
        LogService.getActionsByGroups( user.activeSession.id )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
    ], ( error, results ) => {
      if ( !error ) {
        if ( results ) {
          const dataList12month = _.map( results[1].data, item => ( {
            attribute: item.name,
            value: item.total,
          } ) );
          const titles = _.filter( results[1].data, o => o.total !== 0 );
          const evolutionSeriesTitle = _.map( titles, item => ( item.name ) );
          result1 = this.createCharSerie(
            'chartcustomeractivity12m', dataList12month,
            [
              { id: 'value', name: intl.formatMessage( { id: 'group' } ) },
            ],
          );

          this.config12m = _.cloneDeep( result1.config );
          this.chart = result1.chart;


          const groupsData = [];
          const groupsSeries = [];
          this.setState( { data: results[1].data, evolutionSeriesTitle } );

          _.map( _.sortBy( results[0].data, this.customOrderBy ), ( item ) => {
            groupsSeries.push( { id: item.workgroupId, name: item.name } );
            _.map( item.rounds, ( workgroupData ) => {
              const exist = _.findIndex( groupsData, { attribute: workgroupData.shortName } );
              if ( exist === -1 ) {
                const dataToPush = { attribute: workgroupData.shortName };
                dataToPush[item.workgroupId] = workgroupData.quantity;
                groupsData.push( dataToPush );
              } else {
                groupsData[exist][item.workgroupId] = workgroupData.quantity;
              }
            } );
          } );

          result1 = this.createLineCharSerie(
            'chartcustomeractivity', groupsData,
            groupsSeries,
          );
          this.config = _.cloneDeep( result1.config );
          this.chart = result1.chart;
        }
      }
      toggleLoading( false );
    } );
  }

  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  getActionsByStudents = ( user, group, groupName, cb ) => {
    LogService.getActionsByStudents( user, group )
      .then( response => cb(
        response.errors,
        response.data.data
          ? { data: response.data.data, group: groupName }
          : { data: [], group: groupName },
      ) );
  };

  createLineCharSerie=( key, roundsCat, datas ) => {
    const { intl } = this.props;
    const roundsCatTraduce = roundsCat.map( item => ( {
      ...item,
      attribute: intl.formatMessage( { id: item.attribute.split( ' ' )[0] }, { value: item.attribute.split( ' ' )[1] } ),
    } ) );
    const config = {
      data: roundsCatTraduce,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
        title: { text: intl.formatMessage( { id: 'Rounds' } ) },
      }],
      yAxes: [{ type: 'ValueAxis', min: 0, title: { text: intl.formatMessage( { id: 'Decisions' } ) } }],
      series: _.map( datas, itemX => ( {
        type: 'LineSeries',
        ...this.selectColorPerGroup( itemX.name ),
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    return { config, chart };
  };
  selectColorPerGroup = ( group ) => {
    const numberWithNumeral = !group ? null : group.match( /#[0-9]/ );
    const SerieObjectToFusion = {};
    if ( numberWithNumeral ) {
      switch ( numberWithNumeral[0].replace( '#', '' ) ) {
      case '1':
        SerieObjectToFusion.stroke = am4core.color( '#003594' );
        SerieObjectToFusion.fill = am4core.color( '#003594' );
        break;
      case '2':
        SerieObjectToFusion.stroke = am4core.color( '#F0AF0C' );
        SerieObjectToFusion.fill = am4core.color( '#F0AF0C' );
        break;
      case '3':
        SerieObjectToFusion.stroke = am4core.color( '#2CD5C4' );
        SerieObjectToFusion.fill = am4core.color( '#2CD5C4' );
        break;
      case '4':
        SerieObjectToFusion.stroke = am4core.color( '#F56954' );
        SerieObjectToFusion.fill = am4core.color( '#F56954' );
        break;
      case '5':
        SerieObjectToFusion.stroke = am4core.color( '#407EC9' );
        SerieObjectToFusion.fill = am4core.color( '#407EC9' );
        break;
      default:
        break;
      }
    }
    return SerieObjectToFusion;

    /* let color = null;
const groupNumber =  */
  }
  createCharSerie=( key, roundsCat, datas ) => {
    const { intl } = this.props;
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
        renderer: { minGridDistance: 30 },
        title: { text: intl.formatMessage( { id: 'Groups' } ) },
      }],
      yAxes: [{ type: 'ValueAxis', min: 0, title: { text: intl.formatMessage( { id: 'Decisions' } ) } }],
      series: _.map( datas, itemX => ( {
        type: 'ColumnSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    return { config, chart };
  };
  render() {
    const {
      chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
      data,
      evolutionSeriesTitle,
    } = this.state;
    const { intl } = this.props;
    return (
      <>
        <HeadConfig breadcrumbsLinks={[
          {
            url: '/',
            name: 'HomeLink',
          },
          { name: 'InformationDashboard-activity' },

        ]}
        />
        <KotlerSectionHeader title="activity" image={HeaderImage} />


        <Row>
          <Col lg={6}>
            <CardBox
              header={<KotlerCardHeader title="activity_bar_group_decision" />}
              styleName="px-2"
              onClick={() => this.toggleChartModal( this.config12m, 'activity_bar_group_decision',
                [intl.formatMessage( { id: 'activity' } )] )}
            >
              <div className="jr-link" id="chartcustomeractivity12m" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
          <Col lg={6}>
            <CardBox
              styleName="px-2"
              header={<KotlerCardHeader title="activity_evolution_group_decision" />}

              onClick={() => this.toggleChartModal( this.config, 'activity_evolution_group_decision',
                evolutionSeriesTitle )}
            >
              <div className="jr-link" id="chartcustomeractivity" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
        </Row>
        {!!data && data.length > 0 && (

          <ActivityTable />

        )}
        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};
const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect( mapStateToProps, mapDispatchToProps )( injectIntl( Activity ) );
