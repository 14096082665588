import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Badge,
  Typography,
  Hidden,
} from '@material-ui/core';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import _ from 'lodash';
import { BELOW_THE_HEADER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
// import LanguageSwitcher from 'components/theme/LanguageSwitcher/index'
import UserInfoPopup from 'components/theme/UserInfo/UserInfoPopup';
import UserSpy from 'components/theme/UserSpy/index';
import AppNotification from 'components/theme/AppNotification/index';
// import TextEnviroment from 'components/custom_v2/TextEnviroment';
import UserService from 'services/User';
import SessionSelect from 'components/layouts/SessionSelect';
import TutorialButton from 'components/layouts/TutorialButton';
import FeatherIcon from 'components/custom/FeatherIcon';
import ProfileDropDown from 'components/layouts/ProfileDropDown';
import NavigationMenuDropdown from 'components/layouts/NavigationMenuDropdown';
import LanguageSwitcher from 'components/theme/LanguageSwitcher';
import BreadcrumbComp from '../Breadcrumb';
import HoverTitle from '../HoverTitle';
import TextEnviroment from '../TextEnviroment';

class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState( { appNotification: !this.state.appNotification } );
  };

  onMailNotificationSelect = () => {
    this.setState( { mailNotification: !this.state.mailNotification } );
  };

  onLangSwitcherSelect = ( event ) => {
    this.setState( {
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget,
    } );
  };

  onSearchBoxSelect = () => {
    this.setState( { searchBox: !this.state.searchBox } );
  };

  onAppsSelect = () => {
    this.setState( { apps: !this.state.apps } );
  };

  onUserInfoSelect = () => {
    this.setState( { userInfo: !this.state.userInfo } );
  };

  onSpyUserInfoSelect = () => {
    this.setState( { userSpyInfo: !this.state.userSpyInfo } );
  };

  onSpyUserInfoSelect = () => {
    this.setState( { userSpyInfo: !this.state.userSpyInfo } );
  };

  handleRequestClose = () => {
    this.setState( {
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false,
    } );
  };

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedNav();
  };

  constructor( props ) {
    super();
    this.appBarRef = React.createRef();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
    };
  }
  componentDidUpdate( prevProps ) {
    const locationChanged = this.props.location !== prevProps.location;
    if ( locationChanged ) {
      this.updateScreenToured();
    }
  }
  componentDidMount() {
    this.updateScreenToured();

    // this.heartBeat( true );
    this.heartBeatInterval = setInterval( () => {
      this.heartBeat();
    }, 60000 );
  }

  componentWillUnmount() {
    clearInterval( this.heartBeatInterval );
  }

  updateScreenToured() {
    UserService.updateScreenToured();
  }

  heartBeat() {
    UserService.updateHeartBeat();
  }

  updateSearchText( evt ) {
    this.setState( { searchText: evt.target.value } );
  }

  hasPermissions( role, license ) {
    const { user } = this.props;
    return (
      user.roles
      && user.roles.indexOf( role ) !== -1
      && user.licenses
      && _.find( user.licenses, { owner: license } )
    );
  }

  AdminApps = () => (
    <ul className="jr-list jr-list-half">
      <li className="jr-list-item">
        <Link
          className="jr-list-link"
          to="/users"
          onClick={this.onAppsSelect.bind( this )}
        >
          <i className=" fa fa-users" />
          <span className="jr-list-text">
            <IntlMessages id="users" />
          </span>
        </Link>
      </li>

      <li className="jr-list-item">
        <Link
          className="jr-list-link"
          to="/params"
          onClick={this.onAppsSelect.bind( this )}
        >
          <i className=" fa fa-clipboard-list" />
          <span className="jr-list-text">
            <IntlMessages id="params" />
          </span>
        </Link>
      </li>

      <li className="jr-list-item">
        <Link
          className="jr-list-link"
          to="/universities"
          onClick={this.onAppsSelect.bind( this )}
        >
          <i className=" fa fa-university" />
          <span className="jr-list-text">
            <IntlMessages id="university" />
          </span>
        </Link>
      </li>
    </ul>
  );

  render() {
    const {
      //      locale,
      navigationStyle,
      horizontalNavPosition,
      history,
      user,
      breadcrumbsLinks,
    } = this.props;
    const { activeSession } = user;
    return (
      <>
        <AppBar
          position="sticky"
          ref={this.appBarRef}
          className={`app-main-header  
        ${
          activeSession
          && activeSession.currentRound
          && activeSession.withTestRound
            ? 'app-main-header-test '
            : ''
        }
         ${
           navigationStyle === HORIZONTAL_NAVIGATION
           && horizontalNavPosition === BELOW_THE_HEADER
             ? 'app-main-header-top'
             : ''
         }`}
        >
          <Toolbar
            className="app-toolbar justify-content-between d-flex px-4 "
            variant="dense"
            disableGutters
          >
            <div className="d-flex align-items-center">
              <Hidden smDown>
                <Link
                  className="app-logo mr-lg-2"
                  to="/"
                  style={{ position: 'relative' }}
                >
                  <img
                    src={require( 'assets/images/logo-livemax-kotler-light.png' )}
                    alt="LiveMax"
                    title="LiveMax"
                  />
                  <span
                    style={{
                      position: 'absolute',
                      color: '#ddd',
                      bottom: '-12px',
                      right: '-10px',
                      textAnchor: 'end',
                      fontSize: '10xp',
                    }}
                  >
                    <TextEnviroment
                      isAdmin={this.hasPermissions( 'ROLE_ADMIN', 'adm' )}
                      activeSession={activeSession}
                    />
                  </span>
                </Link>
              </Hidden>
              <Hidden mdUp>
                <Link
                  className="app-logo mr-lg-2"
                  to="/"
                  style={{ position: 'relative' }}
                >
                  <img
                    style={{ height: '30px' }}
                    src={require( 'assets/images/logos/logo-xs.png' )}
                    alt="LiveMax"
                    title="LiveMax"
                  />
                  <span
                    style={{
                      color: '#ddd',
                      marginRight: '10px',
                      textAnchor: 'end',
                      fontSize: '10xp',
                    }}
                  >
                    <TextEnviroment
                      isAdmin={this.hasPermissions( 'ROLE_ADMIN', 'adm' )}
                      activeSession={activeSession}
                    />
                  </span>
                </Link>
              </Hidden>
              <Hidden smDown>
                <div className="divider" />
              </Hidden>
              <Hidden smDown>
                {breadcrumbsLinks ? (
                  <BreadcrumbComp links={breadcrumbsLinks || []} />
                ) : (
                  <Link className="app-logo mr-lg-2" to="/">
                    {' '}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontWeight: 600 }}
                      children="Home"
                    />
                    {' '}
                  </Link>
                )}
              </Hidden>

              {/*  <TextEnviroment activeSession={activeSession} /> */}

              {history.location.pathname !== '/dashboard'
                && this.hasPermissions( 'ROLE_ADMIN', 'adm' ) && (
                <ul className="header-notifications list-inline ml-sm-3">
                  <li className="list-inline-item">
                    <Dropdown
                      className="quick-menu app-notification"
                      isOpen={this.state.apps}
                      toggle={this.onAppsSelect.bind( this )}
                    >
                      <DropdownToggle
                        className="d-inline-block"
                        tag="span"
                        data-toggle="dropdown"
                      >
                        <span className="app-notification-menu">
                          <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                          <span>Menu</span>
                        </span>
                      </DropdownToggle>

                      {this.hasPermissions( 'ROLE_ADMIN', 'adm' ) ? (
                        <DropdownMenu>{this.AdminApps()}</DropdownMenu>
                      ) : null}
                    </Dropdown>
                  </li>
                </ul>
              )}
            </div>

            <div className="d-flex align-items-center">
              {activeSession
                && activeSession.currentRound
                && activeSession.withTestRound && (
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bolder' }}
                  color="inherit"
                  className=" mr-md-1 mr-lg-2"
                >
                  <IntlMessages id="testRoundInfoLabel" />
                  {' '}
                </Typography>
              )}
              <ul className="header-notifications list-inline">
                {( ( this.hasPermissions( 'ROLE_SUPERVISOR', 'sup' )
                  && history.location.pathname.indexOf( '/sessions' ) !== -1 )
                  || ( this.hasPermissions( 'ROLE_CLIENT', 'user' )
                    && history.location.pathname === '/' )
                  || ( this.hasPermissions( 'ROLE_SUPERVISOR', 'sup' )
                    && history.location.pathname === '/' ) ) && (
                  <li className="list-inline-item p-1 px-2 ">
                      <SessionSelect />
                    </li>
                )}

                {activeSession && activeSession.returnToken && user.spyMode && (
                  <li className="list-inline-item user-nav">
                    <Dropdown
                      className="quick-menu user"
                      isOpen={this.state.userSpyInfo}
                      toggle={this.onSpyUserInfoSelect.bind( this )}
                    >
                      <DropdownToggle
                        className="d-inline-block"
                        tag="span"
                        data-toggle="dropdown"
                      >
                        <IconButton className="icon-btn">
                          <i className="fa fa-chalkboard-teacher fa-1x" />
                        </IconButton>
                      </DropdownToggle>

                      <DropdownMenu right>
                        <UserSpy toggle={this.onSpyUserInfoSelect.bind( this )} />
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                )}
                {activeSession
                  && activeSession.zulipData
                  && activeSession.zulipData.apiUrl && (
                  <li className="list-inline-item">
                    <IconButton
                      style={{ textDecoration: 'None' }}
                      className="icon-btn"
                      onClick={() => window.open(
                        activeSession.zulipData.apiUrl.replace( 'api/v1', '' ),
                      )
                      }
                    >
                      <svg
                        role="img"
                        aria-label="Zulip"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="68.96 55.62 400.12 450.43"
                        height="20"
                        style={{ color: 'white' }}
                      >
                        <path
                          fill="hsl(0, 0%, 100%)"
                          d="M473.09 122.97c0 22.69-10.19 42.85-25.72 55.08L296.61 312.69c-2.8 2.4-6.44-1.47-4.42-4.7l55.3-110.72c1.55-3.1-.46-6.91-3.64-6.91H129.36c-33.22 0-60.4-30.32-60.4-67.37 0-37.06 27.18-67.37 60.4-67.37h283.33c33.22-.02 60.4 30.3 60.4 67.35zM129.36 506.05h283.33c33.22 0 60.4-30.32 60.4-67.37 0-37.06-27.18-67.37-60.4-67.37H198.2c-3.18 0-5.19-3.81-3.64-6.91l55.3-110.72c2.02-3.23-1.62-7.1-4.42-4.7L94.68 383.6c-15.53 12.22-25.72 32.39-25.72 55.08 0"
                        />
                      </svg>
                    </IconButton>
                  </li>
                )}

                {this.hasPermissions( 'ROLE_CLIENT', 'user' ) ? (
                  <li className="list-inline-item user-nav px-0">
                    <Dropdown
                      className="quick-menu user"
                      isOpen={this.state.appNotification}
                      toggle={this.onAppNotificationSelect.bind( this )}
                    >
                      <HoverTitle id="System notifications" placement="left">
                        <DropdownToggle
                          className="d-inline-block"
                          tag="span"
                          data-toggle="dropdown"
                        >
                          <IconButton className="icon-btn  square-icon-buttom">
                            <Badge
                              /*      badgeContent={
                                     user.notifications.data.filter(
                                       (not) => !not.isRead,
                                     ).length
                                   } */
                              invisible={
                                user.notifications.data.filter(
                                  not => !not.isAchievement && !not.isRead,
                                ).length <= 0
                              }
                              variant="dot"
                              overlap="circle"
                              className="notification-badge"
                              color="error"
                            >
                              <FeatherIcon
                                icon="bell"
                                color="text.secondary"
                                size="16px"
                              />
                            </Badge>
                          </IconButton>
                        </DropdownToggle>
                      </HoverTitle>

                      <DropdownMenu
                        className="kotler-drop-down-menu"
                        style={{ width: 400, top: 1 }}
                      >
                        <AppNotification
                          toggle={this.onAppNotificationSelect.bind( this )}
                        />
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                ) : null}

                <li className="list-inline-item user-nav px-0">
                  <Dropdown
                    className="quick-menu user"
                    isOpen={this.state.langSwitcher}
                    toggle={this.onLangSwitcherSelect.bind( this )}
                  >
                    <HoverTitle id="language" placement="left">
                      <DropdownToggle
                        className="d-inline-block"
                        tag="span"
                        data-toggle="dropdown"
                      >
                        <IconButton className="icon-btn  square-icon-buttom">
                          <FeatherIcon icon="globe" size="18px" color="#fff" />
                        </IconButton>
                      </DropdownToggle>
                    </HoverTitle>

                    <DropdownMenu
                      className="kotler-drop-down-menu"
                      style={{ top: 1, padding: 0 }}
                    >
                      <LanguageSwitcher />
                      {/*    <AppNotification
                        toggle={this.onLangSwitcherSelect.bind(this)}
                      /> */}
                    </DropdownMenu>
                  </Dropdown>
                </li>
                {/*   {(this.hasPermissions('ROLE_CLIENT', 'user') ||
                  this.hasPermissions('ROLE_SUPERVISOR', 'sup')) && ( */}
                <li className="list-inline-item  p-0">
                  <NavigationMenuDropdown />
                </li>
                {/*     )} */}

                <li className="list-inline-item p-0">
                  <div className="divider" />
                </li>
                <li className="list-inline-item user-nav px-0">
                  <ProfileDropDown />
                </li>

                {navigationStyle === HORIZONTAL_NAVIGATION && (
                  <li className="list-inline-item user-nav">
                    <Dropdown
                      className="quick-menu"
                      isOpen={this.state.userInfo}
                      toggle={this.onUserInfoSelect.bind( this )}
                    >
                      <DropdownToggle
                        className="d-inline-block"
                        tag="span"
                        data-toggle="dropdown"
                      >
                        <IconButton className="icon-btn size-30">
                          <Avatar
                            alt="..."
                            src="https://via.placeholder.com/150x150"
                            className="size-30"
                          />
                        </IconButton>
                      </DropdownToggle>

                      <DropdownMenu right>
                        <UserInfoPopup />
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                )}
                {this.hasPermissions( 'ROLE_CLIENT', 'user' ) ? (
                  <li className="list-inline-item pl-0">
                    <TutorialButton history={history} />
                  </li>
                ) : null}
              </ul>
            </div>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

const mapStateToProps = ( { settings, user } ) => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
    breadcrumbsLinks,
  } = settings;
  return {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
    breadcrumbsLinks,
    user,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      toggleCollapsedNav: SettingsActions.toggleCollapsedNav,
      switchLanguage: SettingsActions.switchLanguage,
    },
  )( Header ),
);
