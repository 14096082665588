import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Grid } from '@material-ui/core';
import moment from 'moment';

import SettingsActions from 'store/reducers/Settings';
import UserLicenseService from 'services/UserLicense';
import HeaderImage from 'assets/images/kotler/sectionHeaders/group-admin.jpeg';
// import WorkGroupService from 'services/WorkGroup';
import SessionService from 'services/Session';

import NewEntityBtn from 'components/custom/NewEntityBtn';
import SpyBtn from 'components/custom/SpyBtn';
import { Switch } from 'components/custom/FormElements';

// import RefuseLinceseBtn from 'components/custom/RefuseLicenseBtn';

import IntlMessages from 'utils/IntlMessages';
import SweetAlert from 'react-bootstrap-sweetalert';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import CardBox from 'components/custom_v2/CardBox';
import AsyncTable from 'components/custom/Table/AsyncTable';
import LicenseEditFormV2 from '../../../../../../components/LicenseEditFormV2';
import SpyLoginForm from '../../../../../../components/SpyLoginForm';

import AssignUserLicenses from '../../../../../../components/Professor/Session/AssignUserLicenses';

class UserLicenses extends React.Component {
  state = {
    data: [],
    dataSession: [],
    editModal: false,
    spyModal: false,
    assignModal: false,
    refuseModal: false,
    certificateModal: false,
    activeCertif: 0,
  };

  getData = ( params ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    this.params = params;
    UserLicenseService.getUserLicensesBySession( sessionId, params )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( {
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / params.limit ),
            total: response.data.total,
          } );
        }
      } );
    /* WorkGroupService.getWorkGroupsBySession( sessionId, params )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          const workgroups = _.map( response.data.data, item => ( {
            id: item.id,
            name: item.name,
          } ) );
          this.setState( { workgroups: workgroups || [] } );
        }
      } );
    */
    SessionService.getSession( sessionId )
      .then( ( response ) => {
        toggleLoading( false );
        if ( response.ok ) {
          const workgroups = _.map( response.data.workGroups, item => ( {
            id: item.id,
            name: item.name,
          } ) );
          this.setState( { workgroups: workgroups || [] } );
        }
        this.setState(
          {
            dataSession: response.data,
            activeCertif: response.data.withCertificate,
          },
        );
      } );
  };

  getEntity = ( id ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    UserLicenseService.getUserLicense( sessionId, id )
      .then( ( response ) => {
        toggleLoading( false );
        if ( response.ok ) {
          const { data } = response;
          if ( data.workGroup ) {
            data.workgroup = data.workGroup.id;
          }

          this.entityToEdit = data;
          this.toggleEditModal();
        }
      } );
  };

  preSpyLoad = ( id ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    UserLicenseService.getUserLicense( sessionId, id )
      .then( ( response ) => {
        toggleLoading( false );
        if ( response.ok ) {
          const { data } = response;
          if ( data.workGroup ) {
            data.workgroup = data.workGroup.id;
          }

          this.entityToEdit = data;
          this.toggleSpyModal();
        }
      } );
  };
  /*
  GetEntityRefuseLicense = ( id ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    UserLicenseService.getUserLicense( sessionId, id )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.entityToEdit = response.data;
          this.toggleRefuseModal();
        }
      } );
  };
  */


  refuseLicense = ( ) => {
    const { toggleLoading, sessionId, toggleAlert } = this.props;
    UserLicenseService.refuseSession( sessionId, this.entityToEdit.id )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        if ( response.ok ) {
          toggleAlert( 'dataSaved', 'info' );
          this.getData( this.params );
          this.toggleRefuseModal();
        }
      } );
  };
  setCertificate = ( ) => {
    const { toggleLoading, sessionId, toggleAlert } = this.props;
    this.toggleCertificateModal( this.activeCertif );
    toggleLoading( true );
    UserLicenseService.setCertificate( sessionId, { withCertificate: this.activeCertif } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        if ( response.ok ) {
          toggleAlert( 'dataSaved', 'info' );

          this.setState( previousState => ( { activeCertif: !previousState.activeCertif } ) );
        }
      } );
  };
  submitForm = ( formData, form ) => {
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    const dataToSend = {};
    toggleLoading( true );
    _.map( _.keys( form.getState().modified ), ( key ) => {
      dataToSend[key] = _.get( formData[key], 'id' ) ? formData[key].id : formData[key];
    } );
    const promise = this.entityToEdit && this.entityToEdit.id
      ? UserLicenseService.updateUserLicense( sessionId, this.entityToEdit.id, dataToSend )
      : UserLicenseService.saveUserLicense( sessionId, dataToSend );
    promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );

      toggleAlert( 'dataSaved', 'info' );
      this.toggleEditModal();
      this.getData( this.params );
    } );
  };
  handleCertifChange = ( value_ ) => {
    this.setState( { activeCertif: value_ } );
    this.activeCertif = value_;
  };
  toggleEditModal = ( entity, cb ) => {
    this.setState( previousState => ( { editModal: !previousState.editModal } ), cb );
  };

  toggleSpyModal = ( entity, cb ) => {
    this.setState( previousState => ( { spyModal: !previousState.spyModal } ), cb );
  };

  toggleAssignModal = ( entity, cb ) => {
    this.setState( previousState => ( { assignModal: !previousState.assignModal } ), cb );
  };

  toggleRefuseModal = ( entity, cb ) => {
    this.setState( previousState => ( { refuseModal: !previousState.refuseModal } ), cb );
  };

  toggleCertificateModal = ( val ) => {
    this.activeCertif = val;
    this.setState( previousState => ( { certificateModal: !previousState.certificateModal } ) );
  };

  render() {
    const {
      dataSession,
      editModal,
      spyModal,
      activeCertif,
      assignModal,
      refuseModal,
      workgroups,
      certificateModal,
      ...rest
    } = this.state;
    const { sessionId } = this.props;
    let totalAvailable = dataSession.licensesTotal - dataSession.licensesWithUserActive;
    totalAvailable = Number.isNaN( totalAvailable ) ? 0 : totalAvailable;

    return (
      <div>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'DecisionDashboard-workgroups' },
          ]}
        />
        <KotlerSectionHeader title="workgroups" image={HeaderImage} />
        <div className="row">
          <div className="col-12 text-right">
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-lg"
              onClick={this.toggleAssignModal}
            >
              <i className="zmdi zmdi-plus" />
              <IntlMessages id="assignUserLicenses" />
            </Button>
          </div>
        </div>
        <Grid container style={{ rowGap: '20px' }}>
          <Grid item xs={12}>
            <CardBox styleName="px-2 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <b><IntlMessages id="licensesTotal" /></b>
                </div>
                <div className="col-md-2">
                  <b>{dataSession.licensesTotal}</b>
                </div>
                <div className="col-md-4" />

                <div className="col-md-2" style={{ alignSelf: 'center', justifyContent: 'center' }}>
                  <b><IntlMessages id="registrationCode" /></b>
                </div>
                <div className="col-md-2 text-primary" style={{ fontSize: '26px' }}>
                  <b>{dataSession.registrationCode}</b>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <b><IntlMessages id="availableLicenses" /></b>
                </div>
                <div className="col-md-2">
                  <b>{totalAvailable}</b>
                </div>
                <div className="col-md-4" />

                <div className="col-md-4">
                  <Switch
                    label="withCertificateLabel"
                    checked={activeCertif ? 1 : 0}
                    onChange={this.toggleCertificateModal}
                    marginContainer={false}
                  />


                </div>
              </div>
            </CardBox>
          </Grid>
          <Grid item xs={12}>
            <CardBox styleName="px-2">
              <AsyncTable
                onFetchData={this.getData}
                showPaginationBottom
                {...rest}
                columns={[
                  {
                    Header: <IntlMessages id="workGroup" />,
                    accessor: 'workgroup.name',
                  },
                  {
                    maxWidth: 150,
                    Header: <IntlMessages id="id" />,
                    accessor: 'id',
                    Cell: props => ( props.value ? `${props.value.slice( 0, 8 ).toUpperCase()}` : null ),
                  },
                  {
                    Header: <IntlMessages id="user" />,
                    accessor: 'user.email',
                    id: 'user',
                  },
                  {
                    Header: <IntlMessages id="name" />,
                    accessor: 'user.name',
                    Cell: props => ( props.original.user ? `${props.value || ''} ${props.original.user.lastName || ''}` : null ),
                  },
                  {
                    Header: <IntlMessages id="userEnabled" />,
                    accessor: 'user.enabled',
                    Cell: props => ( props.value ? <i className="fa fa-check" />
                      : <i className="fa fa-times" /> ),
                  },
                  {
                    Header: <IntlMessages id="lastLogin" />,
                    accessor: 'user.lastLogin',
                    Cell: props => ( props.value ? moment( Number( props.value ) ).format( 'DD/MM/YYYY HH:mm' ) : null ),
                  },
                  {
                    Header: <IntlMessages id="enabled" />,
                    accessor: 'enabled',
                    maxWidth: 120,
                    Cell: props => ( props.value ? <i className="fa fa-check" />
                      : <i className="fa fa-times" /> ),
                  },
                  {
                    Header: '',
                    accessor: 'id',
                    maxWidth: 100,
                    Cell: props => (
                      <div className="d-flex">
                        <NewEntityBtn
                          isEdit
                          onClick={() => this.getEntity( props.value )}
                        />
                        <SpyBtn
                          onClick={() => this.preSpyLoad( props.value )}
                        />
                      </div>

                    ),
                  },

                ]}
              />
            </CardBox>
          </Grid>
        </Grid>


        <LicenseEditFormV2
          data={this.entityToEdit}
          open={editModal}
          newLabel="newUserLicense"
          editLabel="editUserLicense"
          onClose={() => this.toggleEditModal()}
          onSubmit={this.submitForm}
          workgroups={workgroups}
        />

        <SpyLoginForm
          data={this.entityToEdit}
          open={spyModal}
          sessionId={sessionId}
          onClose={() => this.toggleSpyModal()}
          onSubmit={this.submitForm}
          workgroups={workgroups}
        />

        <AssignUserLicenses
          open={assignModal}
          sessionId={sessionId}
          onClose={() => this.toggleAssignModal()}
          onConfirm={() => {
            this.toggleAssignModal();
            this.getData( this.params );
          }}
        />

        <SweetAlert
          show={refuseModal}
          warning
          showCancel
          confirmBtnText={<IntlMessages id="yes" />}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={<IntlMessages id="refuseLicenceModalQuestion" />}
          onConfirm={( ) => {
            this.refuseLicense();
          }}
          onCancel={() => this.toggleRefuseModal()}
        />

        <SweetAlert
          show={certificateModal}
          warning
          showCancel
          confirmBtnText={<IntlMessages id="yes" />}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={<IntlMessages id="activateCertificateQuestion" />}
          onConfirm={( ) => {
            this.setCertificate();
          }}
          onCancel={() => this.toggleCertificateModal()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( UserLicenses );
