/* eslint-disable max-len */
import React, { Fragment } from 'react';
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Table } from 'reactstrap';
import _ from 'lodash';
import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';

const AiReportModal = ( { open, onClose, data, title } ) => (
  <Dialog
    open={open}
    onClose={onClose}
    TransitionComponent={Slide}
    fullWidth
    maxWidth="md"
    TransitionProps={{ direction: 'down' }}
  >
    <AppBar style={{ position: 'sticky' }}>
      <Toolbar>
        <IconButton onClick={onClose} aria-label="Close" className="text-white">
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          style={{ flex: 1 }}
        >
          <IntlMessages id={title} />
        </Typography>
      </Toolbar>
    </AppBar>
    <p />
    <Col lg={12}>
      <CardBox heading="" headerBg="bg-product-laptop">
        <Table striped size="sm">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }} className="thead-report"><IntlMessages id="about" /></th>
              <th style={{ textAlign: 'center' }} className="thead-report"><IntlMessages id="positiveSentiment" /></th>
              <th style={{ textAlign: 'center' }} className="thead-report"><IntlMessages id="negativeSentiment" /></th>
            </tr>
          </thead>
          <tbody>
            {data !== undefined
                    && (
                      <Fragment>
                        {_.map( data, ( dataRow, index ) => (
                          <tr key={index}>
                            <td style={{ textAlign: 'center' }}>
                              {' '}
                              {dataRow && dataRow.about && ( <IntlMessages id={`about${dataRow && dataRow.about}Brand`} /> )}
                            </td>
                            <td style={{ textAlign: 'center' }}>{Util.formatNumber( dataRow && dataRow.positiveSentiment )}</td>
                            <td style={{ textAlign: 'center' }}>{Util.formatNumber( dataRow && dataRow.negativeSentiment )}</td>
                          </tr>
                        ) ) }
                      </Fragment>
                    )
            }
          </tbody>
        </Table>
      </CardBox>
    </Col>
  </Dialog>
);

export default AiReportModal;
