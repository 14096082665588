export const FETCH_LAST_ROUND_PRODUCTS_REQUEST = 'FETCH_LAST_ROUND_PRODUCTS_REQUEST';
export const FETCH_LAST_ROUND_PRODUCTS_SUCCESS = 'FETCH_LAST_ROUND_PRODUCTS_SUCCESS';
export const FETCH_LAST_ROUND_PRODUCTS_FAILURE = 'FETCH_LAST_ROUND_PRODUCTS_FAILURE';
export const CLEAR_LAST_ROUND_PRODUCTS = 'CLEAR_LAST_ROUND_PRODUCTS';

export const fetchLastRoundProductsRequest = (
  sessionId,
  workgroupId,
  params,
) => ( {
  type: FETCH_LAST_ROUND_PRODUCTS_REQUEST,
  payload: { sessionId, workgroupId, params },
} );

export const fetchLastRoundProductsSuccess = data => ( {
  type: FETCH_LAST_ROUND_PRODUCTS_SUCCESS,
  payload: data,
} );

export const fetchLastRoundProductsFailure = error => ( {
  type: FETCH_LAST_ROUND_PRODUCTS_FAILURE,
  payload: error,
} );

export const clearLastRoundProducts = () => ( { type: CLEAR_LAST_ROUND_PRODUCTS } );
