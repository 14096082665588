export const FETCH_TOTAL_CUSTOMER_REQUEST = 'FETCH_TOTAL_CUSTOMER_REQUEST';
export const FETCH_TOTAL_CUSTOMER_SUCCESS = 'FETCH_TOTAL_CUSTOMER_SUCCESS';
export const FETCH_TOTAL_CUSTOMER_FAILURE = 'FETCH_TOTAL_CUSTOMER_FAILURE';
export const CLEAR_TOTAL_CUSTOMER = 'CLEAR_TOTAL_CUSTOMER';

export const fetchTotalCustomerRequest = ( sessionId, params ) => ( {
  type: FETCH_TOTAL_CUSTOMER_REQUEST,
  payload: { sessionId, params },
} );

export const fetchTotalCustomerSuccess = data => ( {
  type: FETCH_TOTAL_CUSTOMER_SUCCESS,
  payload: data,
} );

export const fetchTotalCustomerFailure = error => ( {
  type: FETCH_TOTAL_CUSTOMER_FAILURE,
  payload: error,
} );

export const clearTotalCustomer = () => ( { type: CLEAR_TOTAL_CUSTOMER } );
