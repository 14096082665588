import { createActions, createReducer } from 'reduxsauce';
import { call, put, takeLatest } from 'redux-saga/effects';
import { postManualAsk } from '../manualAiServices';

// initial state
const INITIAL_STATE = {
  message: '',
  question: '',
  loading: false,
  error: null,
  show: false,
  links: [],
};

// actions functions

const fetchAskRequest = ( state = INITIAL_STATE, { question } ) => ( {
  ...state,
  show: true,
  question,
  loading: true,
  error: null,
} );

const fetchAskSuccess = ( state = INITIAL_STATE, { message } ) => ( {
  ...state,
  loading: false,
  show: true,
  showMore: true,
  message,
} );

const fetchAskFailure = ( state = INITIAL_STATE, action ) => ( {
  ...state,
  loading: false,
  show: false,
  error: action.error,
} );
const setShow = ( state = INITIAL_STATE, action ) => ( {
  ...state,
  show: action.show,
} );

const clearAsk = () => ( { ...INITIAL_STATE } );

// actions creator
const { Types, Creators } = createActions( {
  fetchAskRequest: ['sessionId', 'question', 'language'],
  fetchAskSuccess: ['message'],
  fetchAskFailure: ['error'],
  setShow: ['show'],
  clearAsk: null,
} );

// reducer
const reducer = createReducer( INITIAL_STATE, {
  [Types.FETCH_ASK_REQUEST]: fetchAskRequest,
  [Types.FETCH_ASK_SUCCESS]: fetchAskSuccess,
  [Types.FETCH_ASK_FAILURE]: fetchAskFailure,
  [Types.SET_SHOW]: setShow,
  [Types.CLEAR_ASK]: clearAsk,
} );

// sagas

function* fetchAskSaga( { sessionId, question, language = 'en' } ) {
  try {
    const response = yield call( postManualAsk, sessionId, {
      question,
      language,
    } );

    yield put( Creators.fetchAskSuccess( response.data && response.data.message ) );
  } catch ( error ) {
    yield put( Creators.fetchAskFailure( error.message ) );
  }
}

function* RootSaga() {
  yield takeLatest( Types.FETCH_ASK_REQUEST, fetchAskSaga );
}

export const ManualAiActions = Creators;
export const ManualAiReducer = reducer;
export const ManualAiSaga = RootSaga;
