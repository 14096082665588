import React from 'react';
import { Field } from 'react-final-form';
import {
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup
} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';
import HoverTitle from 'components/custom_v2/HoverTitle';

export default ( {
                   containerClass, displayBlock, field, idKey, label, onBlur, translate = true, labelPlacement="end",adornRequired=false,
                   onChange, options, valueKey, validate, disabled = false, extraContent, radioClassName,hovers
                 } ) => (
  <Field name={field} validate={validate}>
    {( { meta, input } ) => {
      const idKey2 = idKey || 'id';
      const valueKey2 = valueKey || 'value';

      return (
        <FormControl error={meta.touched && !!meta.error}
                     className={classNames( 'kotler-radio-buttons-form-control w-100', containerClass )}>
          {extraContent}
          {label && (
            <label className='kotler-radio-buttons-principal-label'>
              <IntlMessages id={label}/>{adornRequired && <span className="text-danger">*</span>}
            </label>
          )}

          <RadioGroup
            className={classNames( { 'd-flex flex-row p-0 ': !displayBlock }, radioClassName )}
            field={field}
            value={input.value}
            onChange={( e, val ) => {
              input.onChange( val );
              if (onChange) onChange( val, input.value );
            }}
          >
            {_.map( options, ( option, index ) => (
              hovers && hovers[index]?(
              <HoverTitle {...hovers[index]}  key={index}>
              <FormControlLabel
                key={index}
                value={option[idKey2].toString()}
                labelPlacement={labelPlacement}
                control={<Radio color='primary'  disabled={option.disabled || disabled}
                className={classNames("kotler-radio-button",option.className)}/>}
                label={option[valueKey2] ? <span className="label-font-sm">{translate ?
                  <IntlMessages id={option[valueKey2]}/> : option[valueKey2]}</span> : null}
              />
              </HoverTitle>
              ):(
                <FormControlLabel
                key={index}
                value={option[idKey2].toString()}
                labelPlacement={labelPlacement}
                control={<Radio color='primary'  disabled={option.disabled || disabled}
                                className={classNames("kotler-radio-button",option.className)}/>}
                label={option[valueKey2] ? <span className="label-font-sm">{translate ?
                  <IntlMessages id={option[valueKey2]}/> : option[valueKey2]}</span> : null}
              />
              )
            ) )}
          </RadioGroup>
          {meta.touched && meta.error &&
          <FormHelperText>{<IntlMessages id={meta.error}/>}</FormHelperText>}
        </FormControl>
      );
    }}
  </Field>
);
