import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Button } from "@material-ui/core";
import IntlMessages from "utils/IntlMessages";
import KotlerHeaderFullWidthModal from "components/custom/KotlerHeaderFullWidthModal";
import KotlerImageCrooper from "../KotlerImageCrooper";

const KotlerChargerImage = ({
disabled=false,
  value="",
  onChange = async () => {},
  onCancel = () => {},
  onDelete
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentImage,setCurrentImage] = useState(value)
  const onChangeCrop = async (image64) => {
    await onChange(image64);
    setCurrentImage(image64)
    setOpenModal(false);
  };
  useEffect(()=>{if(value !== currentImage)setCurrentImage(value)},[value,currentImage])
  const onCancelModal = async () => {
    await onCancel();
    setOpenModal(false);
  };
  //views jsx
  return (
    <>
    <div className="d-flex align-items-center flex-column">
      <div className=" profile-picture mt-2 rounded-0 mx-0 px-0">
       {!disabled &&  <div
          className="img__overlay img__overlay--green"
          onClick={() => setOpenModal(true)}
        >
          <i className="zmdi zmdi-hc-2x zmdi-camera" />
        </div>}
        <img
        
          src={currentImage}
          alt=""
          style={{ borderRadius: "4px",opacity:disabled?0.5:1 }}
          className=" mx-auto d-block "
        />
      </div>
      <div style={{ display: "flex", gap: "10px", padding: "10px 0px" }}>
        <Button
        disabled={disabled}
          className="kotler-btn py-0"
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          <IntlMessages id="changePhoto" />
        </Button>
        <Button
          className="kotler-btn  py-0"
         onClick={onDelete}
          disabled={disabled || !onDelete}
          variant="outlined"
          color="secondary"
        >
          <IntlMessages id="deletePhoto" />
        </Button>
      </div>
      </div>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        fullScreen
      >
        <KotlerHeaderFullWidthModal
          title="changePhoto"
          onClose={onCancelModal}
        />

        <DialogContent className="p-5 overflow-visible">
         <KotlerImageCrooper onChange={onChangeCrop} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default KotlerChargerImage;
