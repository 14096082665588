import React from 'react'
import sizeIcon from '../../../assets/images/kotler/icons/size.png'
import design from '../../../assets/images/kotler/icons/design.png'
import sustainability from '../../../assets/images/kotler/icons/sustainability.png'
import FeatherIcon from 'components/custom/FeatherIcon'
const color = "#8094DD";
const size = 20;
export default function PropertyIcon({ propertyName }) {
    return (
        <>
            {propertyName === 'Size' && <img className={`kotler-table-title-icon-img bg-transparent mr-2`} alt="service-icon" src={sizeIcon}  />}
            {propertyName === 'Design' && <img className={`kotler-table-title-icon-img bg-transparent mr-2`} alt="service-icon" src={design} />}
            {propertyName === 'Speed' && <FeatherIcon className="mr-2" icon="fastForward" color={color} size={size} />}
            {propertyName === 'Sustainability' && <img className={`kotler-table-title-icon-img bg-transparent mr-2`} alt="service-icon" src={sustainability} />}
            {propertyName === 'Reliability' && <FeatherIcon  className="mr-2" icon="thumbsUp" color={color} size={size} />}
        </>
  )
}
