import { Fab } from '@material-ui/core';
import React from 'react';
import './GoToAiBtn.scss';

import { Link } from 'react-router-dom';
import HoverTitle from 'components/custom_v2/HoverTitle';
import AiIcon from './AiIcon';

export const GoToAiBtn = () => (
  <div
    style={{
      position: 'sticky',
      margin: '2vw',
      bottom: '2vw',
      display: 'flex',
      justifyContent: 'flex-end',
      pointerEvents: 'none',
    }}
  >
    <HoverTitle
      id="Livemax Ai"
      placement="left"
      className="go-to-ai-btn-hover "
    >
      <Fab
        color="default"
        className=" go-to-ai-btn "
        size="small"
        component={Link}
        style={{ pointerEvents: 'auto' }}
        to="/tutorial/welcome"
      >
        <AiIcon />
      </Fab>
    </HoverTitle>
  </div>
);
