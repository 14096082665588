import {
  FETCH_CUSTOMER_LIFETIME_VALUE_REQUEST,
  FETCH_CUSTOMER_LIFETIME_VALUE_SUCCESS,
  FETCH_CUSTOMER_LIFETIME_VALUE_FAILURE,
  CLEAR_CUSTOMER_LIFETIME_VALUE,
} from '../actions/customerLifeTimeValueActions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const customerLifetimeValueReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_CUSTOMER_LIFETIME_VALUE_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case FETCH_CUSTOMER_LIFETIME_VALUE_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: null,
    };
  case FETCH_CUSTOMER_LIFETIME_VALUE_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case CLEAR_CUSTOMER_LIFETIME_VALUE:
    return {
      ...state,
      ...initialState,
    };
  default:
    return state;
  }
};

export default customerLifetimeValueReducer;
