export const FETCH_INFO_PRODUCTS_REQUEST = 'FETCH_INFO_PRODUCTS_REQUEST';
export const FETCH_INFO_PRODUCTS_SUCCESS = 'FETCH_INFO_PRODUCTS_SUCCESS';
export const FETCH_INFO_PRODUCTS_FAILURE = 'FETCH_INFO_PRODUCTS_FAILURE';
export const CLEAR_INFO_PRODUCTS = 'CLEAR_INFO_PRODUCTS';

export const fetchInfoProductsRequest = (
  sessionId,
  workgroupId,
  categoryId,
  params,
) => ( {
  type: FETCH_INFO_PRODUCTS_REQUEST,
  payload: { sessionId, workgroupId, categoryId, params },
} );

export const fetchInfoProductsSuccess = ( { categoryId, data } ) => ( {
  type: FETCH_INFO_PRODUCTS_SUCCESS,
  payload: { categoryId, data },
} );

export const fetchInfoProductsFailure = ( { categoryId, error } ) => ( {
  type: FETCH_INFO_PRODUCTS_FAILURE,
  payload: { categoryId, error },
} );

export const clearInfoProducts = () => ( { type: CLEAR_INFO_PRODUCTS } );
