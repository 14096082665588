import { IconButton } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import ContainerGraphModal from 'components/ContainerGraphModal';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import BigCalendar from 'react-big-calendar';
import { useSelector } from 'react-redux';
import IntlMessages from 'utils/IntlMessages';

const localizer = BigCalendar.momentLocalizer( moment );
const ShowWorkingDays = ( { workingDays, startDate, endDate } ) => {
  const [open, SetOpen] = useState( false );
  const locale = useSelector(
    ( { settings } ) => settings.locale && settings.locale.locale,
  );

  const events = useMemo(
    () => workingDays.map( ( { date } ) => ( {
      title: <IntlMessages id="holiday" />,
      allDay: true,
      start: new Date( Number( date ) ),
      end: new Date( Number( date ) ),
    } ) ),
    [workingDays],
  );
  const minDay = useMemo( () => startDate && new Date( Number( startDate ) ), [
    startDate,
  ] );

  const maxDay = useMemo( () => endDate && new Date( Number( endDate ) ), [endDate] );

  const handleNavigate = ( date ) => {
    // Verifica si la fecha está dentro del rango permitido
    if ( date < minDay || date > maxDay ) {
      return false; // Evita la navegación fuera del rango permitido
    }
  };
  return (
    <>
      <IconButton
        className="square-icon-button-primary2"
        onClick={() => SetOpen( true )}
      >
        <CalendarToday />
      </IconButton>
      <ContainerGraphModal
        open={open}
        title="holidays"
        onClose={() => {
          SetOpen( false );
        }}
      >
        <BigCalendar
          events={events}
          culture={locale}
          localizer={localizer}
          defaultView="agenda"
          startAccessor="start"
          onNavigate={handleNavigate}
          className="kotler-big-calendar"
          endAccessor="end"
          messages={{
            next: <IntlMessages id="calendar.next" />,
            previous: <IntlMessages id="calendar.prev" />,
            today: <IntlMessages id="today" />,
            month: <IntlMessages id="month" />,
            week: <IntlMessages id="week" />,
            day: <IntlMessages id="day" />,
            allDay: <IntlMessages id="allDay" />,
            event: <IntlMessages id="event" />,
            date: <IntlMessages id="date" />,
            time: <IntlMessages id="time" />,
            agenda: <IntlMessages id="event" />,
          }}
        />
      </ContainerGraphModal>
    </>
  );
};

export default ShowWorkingDays;
