import { isRoundClosing } from 'modules/Rounds';
import { find } from 'lodash';
import { SessionSchema } from './session-schemas';

export const transformedSessionSchemas = SessionSchema.transform( ( session ) => {
  const { currentRound, rounds } = session;
  const roundByCurrent = find( rounds, { id: currentRound.id } );

  const isClosingCurrentRound = !!(
    roundByCurrent && isRoundClosing( roundByCurrent )
  );

  return { ...session, isClosingCurrentRound };
} );
