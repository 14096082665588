import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Util from 'utils/Util';
import SettingsActions from 'store/reducers/Settings';
import LogService from 'services/Log';
import CardBox from 'components/custom_v2/CardBox';
import Table from 'components/custom/Table/AsyncTable';
import IntlMessages from 'utils/IntlMessages';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';

class ActivityTable extends React.Component {
  state = { data: [], dataToSend: {} };
  dataToSend = {};

  getData = async ( params ) => {
    this.params = _.cloneDeep( params );
    const { toggleAlert, toggleLoading, user } = this.props;
    toggleLoading( true );

    const license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    if ( !license || !license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.dataToSend['filters[workgroup]'] = license.workGroup.id;
    const MergeParams = { ...this.dataToSend, ...params };

    await LogService.getLogs( user.activeSession.id, MergeParams )
      .then( ( response ) => {
        toggleLoading( false );
        if ( response.ok ) {
          this.setState( {
            limit: params.limit,
            offset: params.offset,
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / params.limit ),
            total: response.data.total,
          } );
        }
      } );
  };

  getDetail = ( row ) => {
    const ACTIONS_RULES = {
      ACTION_BUNDLE_CREATED: _.get( row, 'commodity.name', '---' ),
      BUNDLE_CREATED: _.get( row, 'commodity.name', '---' ),
      BUNDLE_PRICE_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.price', '' ) )}  `,
      BUNDLE_ACTIVATED: _.get( row, 'commodity.name', '---' ),
      BUNDLE_DEACTIVATED: _.get( row, 'commodity.name', '---' ),
      SERVICE_PRICE_CHANGED: _.get( row, 'commodity.name', '---' ),
      SERVICE_ACTIVATED: _.get( row, 'commodity.name', '---' ),
      SERVICE_DEACTIVATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_CREATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_DUPLICATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_DEACTIVATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_ACTIVATED: _.get( row, 'commodity.name', '---' ),
      PRODUCT_PRODUCTION_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.quantity', '' ) )}  `,
      PRODUCT_PRICE_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.price', '' ) )}  `,
      PRODUCT_TRADE_SALE_PRICE_CHANGED: `${_.get( row, 'commodity.name', '' )} /  ${Util.formatNumber( _.get( row, 'commodity.tradeSalePrice', '' ) )}  `,
      PRODUCT_POP_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.pop', '' ) )}  `,
      SERVICE_TRADE_SALE_PRICE_CHANGED: _.get( row, 'commodity.name', '---' ),
      SERVICE_POP_CHANGED: _.get( row, 'commodity.name', '---' ),
      BUNDLE_TRADE_SALE_PRICE_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.tradeSalePrice', '' ) )}  `,
      BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.fee', '' ) )}  `,
      BUNDLE_POP_CHANGED: `${_.get( row, 'commodity.name', '' )} / ${Util.formatNumber( _.get( row, 'commodity.pop', '' ) )}  `,
      CEM_CHANGED: _.get( row, 'data.extraData.name', 'CEM' ),
      CAMPAIGN_COMMUNICATION_CREATED: _.get( row, 'data.extraData.name', '---' ),
      CAMPAIGN_CRM_CREATED: _.get( row, 'data.extraData.name', '---' ),
      PURCHASED_REPORT: _.get( row, 'data.extraData.name', '---' ),

    };
    return ACTIONS_RULES[row.action] || '---';
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <CardBox
              disableHeader
              styleName="px-2 mt-4"
            >
              <KotlerTableTitle icon="activity" title="activityResults" />
              <div className="mt-2">

                <Table
                  onFetchData={this.getData}
                  {...this.state}
                  showPaginationBottom
                  columns={[
                    {
                      Header: <IntlMessages id="round" />,
                      accessor: 'round.shortName',
                      maxWidth: 150,
                      minWidth: 100,
                      width: 100,
                    },
                    {
                      Header: <IntlMessages id="user" />,
                      accessor: 'user.name',

                    },
                    {
                      Header: <IntlMessages id="createdAt" />,
                      accessor: 'createdAt',
                      Cell: props => ( props.value ? moment.utc( Number( props.value ) ).format( 'DD/MM/YYYY HH:mm' ) : null ),
                    },
                    {
                      Header: <IntlMessages id="action" />,
                      accessor: 'action',
                      maxWidth: 400,
                      minWidth: 300,
                      width: 350,
                    },
                    {
                      Header: <IntlMessages id="name" />,
                      accessor: 'id',
                      Cell: props => this.getDetail( props.original ),
                    },
                  ]}
                />
              </div>
            </CardBox>
          </Col>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

const mapStateToProps = ( { user } ) => ( { user } );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( ActivityTable );
