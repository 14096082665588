import { createIntl, createIntlCache } from 'react-intl';
import en from './locales/en_US.json';
import ar from './locales/ar_SA.json';
import es from './locales/es_ES.json';
import pt from './locales/pt_PT.json';

export const messages = {
  en,
  ar,
  es,
  pt,
};

const cache = createIntlCache();

let intl;

export const initIntl = ( locale = 'en' ) => {
  intl = createIntl(
    {
      locale,
      messages: messages[locale] || messages.en, // Fallback a 'en'
    },
    cache,
  );
};

export const formatMessage = ( id, defaultMessage = '', values ) => {
  if ( !intl ) {
    throw new Error( 'Intl not initialized. Call initIntl first.' );
  }
  return intl.formatMessage( { id, defaultMessage }, values );
};
