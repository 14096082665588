import CardBox from 'components/custom_v2/CardBox';
import React, { useEffect, useState } from 'react';
import IntlMessages from 'utils/IntlMessages';
import moment from 'moment';
import FeatherIcon from 'components/custom/FeatherIcon';
import { useFetch } from 'hooks';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import { getChangelogs, postChangelog } from '../../services/ChangelogService';

import NewChangelogRow from './ChangelogRowForm';
import { ChangelogRow } from './ChangelogRow';

export const ChangelogTable = () => {
  const [isCreating, setIsCreating] = useState( false );

  const { data, fetchData } = useFetch( getChangelogs, { initialData: [] } );

  const { fetchData: onSaveNew } = useFetch(
    async ( values ) => {
      const dataToSend = {
        ...values,
        publicationDate: moment( values.publicationDate ).format( 'YYYY-MM-DD' ),
      };
      return postChangelog( dataToSend );
    },
    {
      onSuccess: () => {
        setIsCreating( false );
        fetchData();
      },
    },
  );
  useEffect( () => {
    fetchData();
  }, []); //eslint-disable-line

  return (
    <div className="app-wrapper">
      <CardBox styleName="px-2 mt-4 pb-0">
        <div className="jr-link" />
        <div className="position-absolute " style={{ top: 0, right: 0 }}>
          {isCreating ? (
            <IconButton
              onClick={() => {
                setIsCreating( false );
              }}
              className="square-icon-button-danger"
            >
              <FeatherIcon icon="x" color="error.main" size="18px" />
            </IconButton>
          ) : (
            <IconButton
              className="square-icon-button-primary2 "
              onClick={() => setIsCreating( true )}
            >
              <FeatherIcon icon="plus" color="primary2.light" size="18px" />
            </IconButton>
          )}
        </div>
        <KotlerTableTitle icon="gitBranch" title="changelog" />
        <div className="kotler-table-container overflow-visible d-flex flex-column">
          <Table className="kotler-table mb-0">
            <TableHead>
              <TableRow>
                <TableCell>
                  <IntlMessages id="publicationDate" />
                </TableCell>
                <TableCell>
                  <IntlMessages id="enDescription" />
                </TableCell>
                <TableCell>
                  <IntlMessages id="esDescription" />
                </TableCell>
                <TableCell colSpan={2} />
              </TableRow>
            </TableHead>
            <TableBody>
              {isCreating && (
                <NewChangelogRow
                  onSubmit={onSaveNew}
                  onCancel={() => setIsCreating( false )}
                />
              )}
              {data.map( changelog => (
                <ChangelogRow
                  changelog={changelog}
                  key={changelog.id}
                  onFetchData={fetchData}
                />
              ) )}
            </TableBody>
          </Table>
        </div>
      </CardBox>
    </div>
  );
};

export default ChangelogTable;
