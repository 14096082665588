/* eslint-disable no-param-reassign */
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

export { default as animated } from '@amcharts/amcharts4/themes/animated';

export const COLORS = {
  '#1': am4core.color( '#003594' ),
  '#2': am4core.color( '#F0AF0C' ),
  '#3': am4core.color( '#2CD5C4' ),
  '#4': am4core.color( '#F56954' ),
  '#5': am4core.color( '#407EC9' ),
  '#6': am4core.color( '#000000' ),
};
export const colorLineSerieFormat = ( serie ) => {
  if ( !serie ) return;

  let property = null;

  if ( serie.name ) {
    const match = serie.name.match( /#\d+$/ );
    const finded = match ? match[0] : null;
    if ( finded ) property = finded;
  }
  if ( serie.dataFields && serie.dataFields.valueY ) {
    const match = serie.dataFields.valueY.match( /#\d+$/ );
    const finded = match ? match[0] : null;
    if ( finded ) property = finded;
  }

  if ( !property ) return;

  const color = COLORS[property];
  if ( color ) {
    serie.fill = color;
    serie.stroke = color;
  }
};
export const colorPieSeriesFormat = ( pieSeries ) => {
  if ( !pieSeries || !pieSeries.slices ) return;

  let property = null;

  pieSeries.slices.template.adapter.add( 'fill', ( fill, target ) => {
    // Obtener la categoría actual
    const { category, value } = target.dataItem;

    if ( value && typeof value === 'string' ) {
      const match = value.match( /#\d+$/ );
      const finded = match ? match[0] : null;
      if ( finded ) property = finded;
    }
    if ( category && typeof category === 'string' ) {
      const match = category.match( /#\d+$/ );
      const finded = match ? match[0] : null;
      if ( finded ) property = finded;
    }
    if ( !property ) return fill;
    const color = COLORS[property];
    if ( color ) return color;
    return fill;
  } );
};

// eslint-disable-next-line camelcase
export function am4themesKotlerTheme( target ) {
  if ( target instanceof am4core.InterfaceColorSet ) {
    const colorOpacity = am4core.color( '#2CD5C4' ).lighten( -0.2 );
    target.setFor( 'secondaryButton', am4core.color( '#CFDFF1' ) );
    target.setFor( 'secondaryButtonHover', colorOpacity );
    target.setFor( 'secondaryButtonDown', colorOpacity );

    //   // target.setFor(
    //   //   'secondaryButtonActive',
    //   //   am4core.color( '#003594' ).lighten( -0.2 ),
    //   // );
    //   target.setFor( 'secondaryButtonText', am4core.color( '#FFFFFF' ) );
    //   target.setFor( 'fill', am4core.color( '#2CD5C4' ) );
    target.setFor( 'text', am4core.color( '#333F48' ) );
    target.setFor( 'fill', am4core.color( '#2CD5C4' ) );
    if ( target instanceof am4core.Button ) {
      target.setFor( 'secondaryButton', am4core.color( '#2CD5C4' ) );
      target.setFor( 'secondaryButtonHover', colorOpacity );
      target.setFor( 'secondaryButtonDown', colorOpacity );
    }
  }

  if ( target instanceof am4core.ColorSet ) {
    // eslint-disable-next-line no-param-reassign
    target.list = [
      am4core.color( '#003594' ),
      am4core.color( '#F0AF0C' ),
      am4core.color( '#2CD5C4' ),
      am4core.color( '#F56954' ),
      am4core.color( '#407EC9' ),
      am4core.color( '#000000' ),
      am4core.color( '#fe445f' ),
      am4core.color( '#7e2ab9' ),
      am4core.color( '#1998f6' ),
      am4core.color( '#2CD5C4' ).lighten( 0.3 ),
    ];
  }
  if ( target instanceof am4charts.LineSeries ) {
    target.events.on( 'ready', () => {
      colorLineSerieFormat( target );
    } );
  }
  if ( target instanceof am4charts.PieSeries ) {
    colorPieSeriesFormat( target );
  }
}
