import api from 'config/Api';

// const v1 = 'v1/session';
const v2 = 'v2';

export default {
  getLiveNews( sessionId ) {
    return api.get( `${v2}/news-articles/${sessionId}` );
  },
};
