import { formatMessage } from 'helpers/IntlHelpers';
import { find, flatMap } from 'lodash';

const formatLabels = labels => flatMap( labels, value => [
  {
    value: formatMessage( { id: value } ),
    style: {
      alignment: { horizontal: value === 'PriceS' ? 'right' : 'left' },
      font: { bold: true },
    },
  },
] );

const formatAttributeValues = ( data ) => {
  const formattedData = [];

  data.forEach( ( item ) => {
    item.workgroupData.forEach( ( itemB ) => {
      itemB.products.forEach( ( itemC ) => {
        const attributes = [
          'Design',
          'Size',
          'Reliability',
          'Sustainability',
          'Speed',
        ];
        const attributeValues = attributes.map( ( attribute ) => {
          const foundAttribute = find( itemC.attributes, { label: attribute } );
          return foundAttribute ? foundAttribute.valueText : null;
        } );

        formattedData.push( [
          itemB.name,
          itemC.name,
          itemC.price,
          ...attributeValues,
        ] );
      } );
    } );
  } );

  return formattedData;
};

export const formatlastProductsByCategoryToExcel = (
  data,
  title = 'Detail products by group',
) => {
  const sheetData = {
    xSteps: 0,
    ySteps: 1,
    columns: [formatMessage( { id: title } )],
    data: [],
  };

  if ( !data || !data.length ) return [sheetData];
  const labelRow = [
    'group',
    'model',
    'PriceS',
    'design',
    'size',
    'reliability',
    'Sustainability',
    'speed',
  ];
  const dataToExcel = formatAttributeValues( data );
  sheetData.data = [formatLabels( labelRow ), ...dataToExcel];
  return [sheetData];
};

export default { formatlastProductsByCategoryToExcel };
