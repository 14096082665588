import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import CircularProgress from 'components/theme/CircularProgress';
import FeatherIcon from 'components/custom/FeatherIcon';
import IntlMessages from 'utils/IntlMessages';

/**
 * Componente LineGraph
 *
 * Este componente se utiliza para crear gráficos de líneas interactivos utilizando la biblioteca amCharts en una aplicación web de React.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.data - Un arreglo de objetos que contiene los datos que se mostrarán en el gráfico de líneas.
 * @param {boolean} props.loading - Un booleano que indica si los datos se están cargando.
 * @param {boolean} props.error - Un booleano que indica si se ha producido un error al cargar los datos.
 * @param {Array} props.seriesData - Un arreglo de objetos que contiene información sobre las series que se mostrarán en el gráfico.
 * @param {string} props.categoryField - El nombre del campo en los datos que se utilizará en el eje x.
 * @param {string} props.titleCategory - El título del eje x.
 * @param {string} props.titleValue - El título del eje y.
 * @param {string} props.height - La altura del gráfico (por ejemplo, '300px').
 * @param {string} props.htmlId - El ID del elemento HTML en el que se renderizará el gráfico.
 * @param {boolean} props.interactive - Un booleano que indica si se habilitarán las herramientas interactivas del gráfico.
 * @param {number} props.strokeWidth - El ancho de las líneas en el gráfico.
 * @param {number} props.delay - Un número que representa el retraso antes de mostrar el mensaje "No hay datos".
 * @returns {JSX.Element} - El componente LineGraph.
 */
function BarGraph( {
  data,
  loading = false,
  error = false,
  seriesData = [],
  categoryField = 'attribute',
  titleCategory = 'rounds',
  titleValue = 'value',
  height = '300px',
  htmlId = 'chartdiv',
  interactive = false,
  strokeWidth = 2,
} ) {
  const [noData, setNoData] = useState( false );

  useEffect( () => {
    if ( !data || !data.length || !seriesData ) return; // No hay datos
    const chart = am4core.create( htmlId, am4charts.XYChart );
    // Configurar eje x
    const categoryAxis = chart.xAxes.push( new am4charts.CategoryAxis() );
    categoryAxis.dataFields.category = categoryField;
    categoryAxis.title.text = titleCategory;

    // Configurar eje y
    const valueAxis = chart.yAxes.push( new am4charts.ValueAxis() );
    valueAxis.title.text = titleValue;

    // Configurar serie de línea
    seriesData.forEach( ( seriesItem ) => {
      const series = chart.series.push( new am4charts.ColumnSeries() );
      series.dataFields.valueY = seriesItem.valueField;
      series.dataFields.categoryX = categoryField;
      series.name = seriesItem.name;
      series.clustered = false;
      if ( interactive ) {
        series.tooltipText = '{name}  -  {valueY.value}';
      }
      series.strokeWidth = strokeWidth;
      colorFormat( series );
    } );

    // Habilitar el zoom y el cursor
    if ( interactive ) {
      enableTools( chart );
    }

    chart.data = data;

    return () => {
      if ( chart && chart.dispose ) chart.dispose();
    };
  }, [
    data,
    categoryField,
    htmlId,
    strokeWidth,
    seriesData,
    titleCategory,
    titleValue,
    interactive,
  ] );

  useEffect( () => {
    const thereIsntData = !!( !loading && !error && ( !data || !data.length ) );
    if ( thereIsntData === noData ) return;
    if ( !thereIsntData ) setNoData( thereIsntData );
    else {
      const timeout = setTimeout( () => {
        setNoData( thereIsntData );
      }, 2000 );
      return () => clearTimeout( timeout );
    }
  }, [data, error, loading, noData] );

  const noGraphic = loading || error || noData;

  return (
    <div style={{ width: '100%', height, position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          opacity: noGraphic ? 0 : 1,
          transition: 'opacity 1s ease-in-out',
        }}
        id={htmlId}
      />

      <div
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          pointerEvents: noGraphic ? 'auto' : 'none',
          opacity: noGraphic ? 1 : 0,
          transition: 'opacity 1s ease-in-out',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <>
            <FeatherIcon icon="alertCircle" color="warning.main" size="50px" />
            <span className="text-warning mt-2">
              <IntlMessages id="graphError" />
            </span>
          </>
        ) : (
          <div />
        )}
        {noData && (
          <>
            <FeatherIcon icon="info" color="text.primary" size="50px" />
            <span className="text-dark mt-2">
              <IntlMessages id="graphNoData" />
            </span>
          </>
        )}
      </div>
    </div>
  );
}

/**
 * Habilita herramientas interactivas en el gráfico.
 *
 * @param {am4core.Chart} chart - El objeto de gráfico de amCharts.
 * @param {string} color - El color para el fondo de las herramientas interactivas.
 */
const enableTools = ( chart, color = '#003594' ) => {
  chart.cursor = new am4charts.XYCursor();
  const scrollX = new am4charts.XYChartScrollbar();
  scrollX.minHeight = 10;
  scrollX.minWidth = 10;
  chart.scrollbarX = scrollX;
  chart.legend = new am4charts.Legend();
  const exportMenu = new am4core.ExportMenu();
  exportMenu.verticalAlign = 'bottom';
  chart.exporting.menu = exportMenu;
};
/**
 * @param {am4charts.LineSeries} serie
 */
const colorFormat = ( serie ) => {
  if ( !serie || !serie.dataFields || !serie.dataFields.valueY ) return;
  const match = serie.dataFields.valueY.match( /#\d+$/ );
  if ( !match ) return;
  switch ( match[0] ) {
  case '#1':
    return ( serie.stroke = am4core.color( '#003594' ) );
  case '#2':
    return ( serie.stroke = am4core.color( '#F0AF0C' ) );
  case '#3':
    return ( serie.stroke = am4core.color( '#2CD5C4' ) );
  case '#4':
    return ( serie.stroke = am4core.color( '#F56954' ) );
  case '#5':
    return ( serie.stroke = am4core.color( '#407EC9' ) );
  default:
  }
};

export default BarGraph;
