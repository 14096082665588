import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import Dashboard from './Dashboard';

const Strategy = ( { match } ) => (
  <Switch>
    <Route
      path={match.url}
      component={Dashboard}
    />
    <Route component={Error404} />
  </Switch>
);

export default Strategy;
