import { takeEvery, put, call } from 'redux-saga/effects';
import { getInfoProducts } from 'modules/Products/ProductServices';
import {
  FETCH_INFO_PRODUCTS_REQUEST,
  fetchInfoProductsFailure,
  fetchInfoProductsSuccess,
} from '../actions/infoProductsActions';

function* fetchInfoProductsSaga( action ) {
  const {
    sessionId,
    workgroupId,
    categoryId,
    params = { limit: -1 },
  } = action.payload;
  try {
    const infoProductsData = yield call(
      getInfoProducts,
      sessionId,
      workgroupId,
      categoryId,
      params,
    );
    yield put(
      fetchInfoProductsSuccess( { categoryId, data: infoProductsData } ),
    );
  } catch ( error ) {
    yield put( fetchInfoProductsFailure( { categoryId, error } ) );
  }
}

export default function* infoProductsRootSaga() {
  yield takeEvery( FETCH_INFO_PRODUCTS_REQUEST, fetchInfoProductsSaga );
}
