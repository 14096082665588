import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import IntlMessages from 'utils/IntlMessages';
import HoverTitle from 'components/custom_v2/HoverTitle';

export default ( { onClickCancel, cancelTo, cancelBtn = true, disabledSubmit = false ,hoverSave} ) => (
  [
    cancelBtn ?
    cancelTo
      ? (
      <Button key="cancel" variant="contained"
              className="jr-btn jr-btn-lg bg-kotler-orange text-white" component={Link} to={cancelTo}
              onClick={onClickCancel}
      >
        <IntlMessages id="cancelBtn"/>
      </Button>
      )
      : (
        <Button key="cancel" variant="contained"
                className="jr-btn jr-btn-lg bg-kotler-orange text-white" onClick={onClickCancel}>
          <IntlMessages id="cancelBtn"/>
        </Button>
      ) :
    null, 
     ( <HoverTitle  {...hoverSave} key="save">
      <Button key="save" variant="contained" color="primary" className="jr-btn jr-btn-lg"
            disabled={disabledSubmit}
            type="submit">
      <IntlMessages id="ApproveRDproject"/>
    </Button>
    </HoverTitle>
    ),


  ]
);
