import { combineReducers } from 'redux';
import marketProductsReducer from './marketProductsReducer';
import infoProductsReducer from './infoProductsReducer';
import lastRoundProductsReducer from './lastRoundProductsReducer';
import priceProductsReducer from './priceProductsReducer';

const productsRootReducer = combineReducers( {
  marketProducts: marketProductsReducer,
  infoProducts: infoProductsReducer,
  lastRoundProducts: lastRoundProductsReducer,
  priceProducts: priceProductsReducer,
  // Agrega aquí más reducers específicos del módulo si los tienes
} );

export default productsRootReducer;
