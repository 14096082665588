import { z } from 'zod';

export const SessionCreationStatusSchema = z.enum( [
  'SESSION_CREATED',
  'INITIAL_DATA_CREATED',
  'INITIAL_BALANCE_CREATED',
  'ROUNDS_CALCULATED',
  'ROUNDS_CREATED',
  'READY',
] );

export const SessionStatusSchema = z.enum( [
  'READY_NOT_STARTED',
  'READY_NO_ROUND',
  'READY',
  'SESSION_EXPIRED',
] );
