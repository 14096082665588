import React from 'react';

import _ from 'lodash';
import CardBox from 'components/custom_v2/CardBox';
import {
  TextField,
  Checkboxes,
  Select,
} from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import { Grid } from '@material-ui/core';

import KotlerCardHeader from 'components/custom/KotlerCardHeader';

const BehavioralSegmentation = ( {
  behavioralSegmentation,
  behavioralSegmentationField,
  commodities,
  index,
  change,
  intl,
} ) => (
  <CardBox
    styleName="h-100 pb-0 kotler-card-gray mr-sm-4 mr-md-3 mr-lg-4"
    header={(
      <KotlerCardHeader
        title="behavioralSegmentation"
        titleComplement={index}
        onDelete={() => {
          change( `${behavioralSegmentationField}.behavioral`, null );
          change( `${behavioralSegmentationField}.fromMonth`, null );
          change( `${behavioralSegmentationField}.toMonth`, null );
          change( `${behavioralSegmentationField}.commodity`, null );
        }
        }
      />
    )}
  >

    {behavioralSegmentation.type === 'radio' && (
      <div className=" mb-2 pt-3 p-0">
        <Checkboxes
          noMulti
          field={`${behavioralSegmentationField}.behavioral`}
          options={behavioralSegmentation.options}
          valueKey="name"
          marginContainer={false}
        />

      </div>
    )}

    {behavioralSegmentation.type === 'checkbox' && (
      <div className=" mb-1 pt-1 p-0">
        <Checkboxes
          field={`${behavioralSegmentationField}.behavioral`}
          options={behavioralSegmentation.options}
          valueKey="name"
          translate
          marginContainer={false}
          onChange={( value ) => {
            if ( !value || !value.length ) {
              change( `${behavioralSegmentationField}.fromMonth`, null );
              change( `${behavioralSegmentationField}.toMonth`, null );
            }
          }}
        />
      </div>
    )}

    {behavioralSegmentation.selectCommodity && (
      <div className="mb-3">
        <Select
          label="model"
          field={`${behavioralSegmentationField}.commodity`}
          translateOptions={false}
          marginContainer={false}
          disabled={!behavioralSegmentation.behavioral}
          options={_.map( commodities, item => ( {
            ...item,
            name:
              item.active === false
                ? `${item.name} ( ${intl.formatMessage( { id: 'lblInactive' } )} )`
                : item.name,
          } ) )}
        />
        {/*  <div className="p-0 text-dark">
          <IntlMessages id="model" />
        </div>
        <div className="pl-3 py-2 mb-3 bg-gray-1 h-75">
          <RadioButtons
            disabled={!behavioralSegmentation.behavioral}
            field={`${behavioralSegmentationField}.commodity`}
            translate={false}
            marginContainer={false}
            valueKey="name"
            options={_.map(commodities, (item) => ({
              ...item,
              name:
                item.active === false
                  ? `${item.name} ( ${intl.formatMessage({
                      id: 'lblInactive',
                    })} )`
                  : item.name,
            }))}
            displayBlock
            clearAll={clearAll}
          />
        </div> */}
      </div>
    )}


    <Grid container wrap="nowrap" spacing={1}>
      <Grid item>

        <TextField
          label="Between"
          field={`${behavioralSegmentationField}.fromMonth`}
          disabled={
            !behavioralSegmentation.behavioral
                || !behavioralSegmentation.behavioral.length
          }
          type="number"
          marginContainer={false}
        />

      </Grid>
      <Grid item className="text-dark" style={{ fontWeight: 500, marginTop: '33px' }}>
        <IntlMessages id="and" />
      </Grid>
      <Grid item style={{ marginTop: '23px' }}>

        <TextField
          field={`${behavioralSegmentationField}.toMonth`}
          disabled={
            !behavioralSegmentation.behavioral
                || !behavioralSegmentation.behavioral.length
          }
          type="number"
          marginContainer={false}
        />

      </Grid>

    </Grid>
    <Grid container justify="flex-end">
      <Grid item className="text-dark " style={{ fontSize: '12px', fontWeight: 500 }}>
        <IntlMessages id="monthsAgo" />
      </Grid>
    </Grid>
  </CardBox>
);

export default injectIntl( BehavioralSegmentation );
