import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import customerRootReducer from 'modules/Customers/redux/reducers/rootReducer';
import productsRootReducer from 'modules/Products/redux/reducers/rootReducer';
import bundlesRootReducer from 'modules/Bundles/redux/reducers/rootReducer';
import marketRootReducer from 'modules/Market/redux/reducers/rootReducer';
import { liveAdsRootReducer } from 'modules/LiveAds';
import { reducer as achievement } from 'modules/Notifications/Redux/achievement';
import { changelogsReducer } from 'modules/changelog';
import { ManualAiReducer } from 'modules/ManualAi';
import { reducer as Settings } from './Settings';
import { reducer as User } from './User';

export default history => combineReducers( {
  router: connectRouter( history ),
  settings: Settings,
  user: User,
  customers: customerRootReducer,
  products: productsRootReducer,
  bundles: bundlesRootReducer,
  market: marketRootReducer,
  ads: liveAdsRootReducer,
  changelogs: changelogsReducer,
  achievement,
  manualAi: ManualAiReducer,
} );
