import api from 'config/Api';

export async function getHttpRequest( url, params = {} ) {
  try {
    const response = await api.get(
      url,
      params, // Parámetros de consulta para solicitudes GET
    );

    // Si la respuesta tiene una propiedad "errors", significa que hubo un error
    if ( !response.ok && response.data && response.data.errors ) {
      throw new Error( response.data.errors );
    }
    // Si la respuesta tiene una propiedad "errors", significa que hubo un error
    if ( !response.ok && response.data && response.data.message ) {
      throw new Error( `${response.status} ${response.data.message}` );
    }

    // Si la respuesta tiene un status diferente de 200, consideramos que es un error
    if ( response.status !== 200 ) {
      throw new Error( `Ha ocurrido un error en ${url}` );
    }

    return response.data;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.error( 'Error al realizar la solicitud:', error );
    throw error;
  }
}
export async function putHttpRequest( url, data, params = {} ) {
  try {
    const response = await api.put(
      url,
      data,
      params, // Parámetros de consulta para solicitudes GET
    );

    // Si la respuesta tiene una propiedad "errors", significa que hubo un error
    if ( !response.ok && response.data && response.data.errors ) {
      throw new Error( response.data.errors );
    }
    // Si la respuesta tiene una propiedad "errors", significa que hubo un error
    if ( !response.ok && response.data && response.data.message ) {
      throw new Error( `${response.status} ${response.data.message}` );
    }

    // Si la respuesta tiene un status diferente de 200, consideramos que es un error
    if ( response.status !== 200 ) {
      throw new Error( `Ha ocurrido un error en ${url}` );
    }

    return response.data;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.error( 'Error al realizar la solicitud:', error );
    throw error;
  }
}
export async function postHttpRequest( url, data, params = {} ) {
  try {
    const response = await api.post(
      url,
      data,
      params, // Parámetros de consulta para solicitudes GET
    );

    // Si la respuesta tiene una propiedad "errors", significa que hubo un error
    if ( !response.ok && response.data && response.data.errors ) {
      throw new Error( response.data.errors );
    }
    // Si la respuesta tiene una propiedad "errors", significa que hubo un error
    if ( !response.ok && response.data && response.data.message ) {
      throw new Error( `${response.status} ${response.data.message}` );
    }

    // Si la respuesta tiene un status diferente de 200, consideramos que es un error
    if ( response.status !== 200 ) {
      throw new Error( `Ha ocurrido un error en ${url}` );
    }

    return response.data;
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.error( 'Error al realizar la solicitud:', error );
    throw error;
  }
}

export default { getHttpRequest };
