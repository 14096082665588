import { useQuery } from 'react-query';

import { showError } from 'utils/alerts';
import { getFeatures } from '../services/ChangelogService';

const get = async ( changelogId ) => {
  const res = await getFeatures( changelogId );
  if ( res.ok ) {
    return res.data;
  }
  if ( !res.ok ) {
    if ( res.errors ) {
      throw new Error( res.errors );
    }
    throw new Error( 'Error' );
  }
};


const createQueryKey = changelogId => ['v2', changelogId, 'features'];


export const useFeaturesQuery = ( changelogId, params = {} ) => {
  const featureQuery = useQuery(
    createQueryKey( changelogId ),
    () => get( changelogId, params ),
    {
      enabled: !!changelogId,
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 12,
      retryOnMount: true,
      onError( error ) {
        showError( error && error.message );
      },
    },
  );

  return { featureQuery };
};
