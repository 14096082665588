import {
  FETCH_TOTAL_CUSTOMER_REQUEST,
  FETCH_TOTAL_CUSTOMER_SUCCESS,
  FETCH_TOTAL_CUSTOMER_FAILURE,
  CLEAR_TOTAL_CUSTOMER,
} from '../actions/totalCustomersActions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const totalCustomerReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_TOTAL_CUSTOMER_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case FETCH_TOTAL_CUSTOMER_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: null,
    };
  case FETCH_TOTAL_CUSTOMER_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case CLEAR_TOTAL_CUSTOMER:
    return {
      ...state,
      ...initialState,
    };
  default:
    return state;
  }
};

export default totalCustomerReducer;
