import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { formatDataToLineGraph } from 'helpers/GraphHelpers';

import LineGraphFull from 'components/StatisticalGraphs/containers/LineGraphFull';

const LineGraphMarketProduct = ( {
  gId = 1,
  graphHeight = '315px',
  title = 'LAPTOPSalesByBrandU',
  hoverTitle,
  titleValue = 'SalesU',
  titleCategory = 'Rounds',
  dataKey = 'unitSales',
  marketProductData,
  loading,
  error,
  locale,
} ) => {
  const [dataToGraph, setDataToGraph] = useState( {} );

  useEffect( () => {
    const formatData = formatDataToLineGraph(
      marketProductData && marketProductData.data,
      dataKey,
    );
    if ( JSON.stringify( dataToGraph ) !== JSON.stringify( formatData ) ) {
      setDataToGraph( formatData );
    }
  }, [locale.locale, marketProductData, dataKey, dataToGraph] );

  return (
    <LineGraphFull
      {...{
        title,
        loading,
        error,
        hoverTitle,
        titleCategory,
        titleValue,
      }}
      height={graphHeight}
      htmlId={`line_chart_market_product_${gId}`}
      data={dataToGraph.data}
      seriesData={dataToGraph.seriesData}
    />
  );
};

const mapStateToProps = ( { products, settings }, { categoryId } ) => {
  if ( !categoryId ) {
    return {
      marketProductData: undefined,
      loading: true,
      error: null,
      locale: settings.locale,
    };
  }
  const product = products.marketProducts.byCategoryId[categoryId];
  if ( !product ) {
    return {
      marketProductData: undefined,
      loading: false,
      error: null,
      locale: settings.locale,
    };
  }
  return {
    marketProductData: product.data,
    loading: product.loading,
    error: product.error,
    locale: settings.locale,
    // activeSessionId: user && user.activeSession && user.activeSession.id,
  };
};

export default connect( mapStateToProps )( injectIntl( LineGraphMarketProduct ) );
