import React, { useMemo } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImportContacts } from '@material-ui/icons';
import { useTutorialProps } from '../hooks/use-tutorial-props';

const TutorialListItem = ( {
  Icon = <ImportContacts />,
  id = '',
  onClick,
  className = '',
} ) => {
  const { link, title } = useTutorialProps( id );
  const pathname = useSelector( ( { router } ) => router.location.pathname );
  const isCurrent = useMemo( () => pathname === link, [pathname, link] );
  return (
    <ListItem
      className={` my-1   ${className}`}
      button
      onClick={onClick}
      to={link}
      replace
      component={Link}
      disabled={isCurrent}
      selected={isCurrent}
    >
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText primary={<IntlMessages id={title} />} />
    </ListItem>
  );
};

export default TutorialListItem;
