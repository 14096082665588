import { useMemo } from 'react';

export const useSessionStatus = ( session ) => {
  const status = session && session.status;

  const isReady = useMemo( () => status === 'READY', [status] );
  const isExpired = useMemo( () => status === 'SESSION_EXPIRED', [status] );
  const isReadyNoStarted = useMemo( () => status === 'READY_NOT_STARTED', [
    status,
  ] );
  const isReadyNoRound = useMemo( () => status === 'READY_NO_ROUND', [status] );
  const sessionNotConfigured = useMemo( () => !!( session && !session.status ), [
    session,
  ] );
  const isHide = useMemo( () => !!( !session || !session.status ), [session] );

  return {
    // disabled global value
    isDisabled: !isReady,
    isHide,
    // states of session
    isReady,
    isExpired,
    isReadyNoStarted,
    isReadyNoRound,
    sessionNotConfigured,
  };
};
