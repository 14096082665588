import React from 'react';
import IntlHtmlMessages from 'utils/IntlHtmlMessages';
import Error404 from 'components/theme/Error404';
import { useTutorialProps } from '../hooks/use-tutorial-props';

const TutorialInfo = ( { match: { params } } ) => {
  const { entry, title, content } = useTutorialProps( params.id );

  if ( !entry ) return <Error404 />;

  return (
    <>
      <h1 style={{ fontWeight: '500', fontSize: 28 }}>
        <IntlHtmlMessages id={title} />
      </h1>
      <IntlHtmlMessages id={content} />
    </>
  );
};

export default TutorialInfo;
