import React from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';

const Error404 = () => (
  <div
    className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3 flex flex-grow-1 flex-column   "
    style={{ height: '100%', justifyContent: 'center', display: 'flex' }}
  >
    <div className="page-error-content">
      <div className="  mb-5  mb-sm-0 animated zoomInDown  d-flex flex-column flex-sm-row  align-items-center justify-content-center w-100 ">
        <img
          src="/logo.png"
          alt="livemax-logo"
          className="w-75 d-none d-sm-block"
        />
        <h1 className=" error-code text-lg   pl-sm-2">Oops!</h1>
      </div>
      <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
        <IntlMessages id="extraPages.404Msg" />
      </h2>
      <p className="text-center zoomIn animation-delay-20 animated">
        <Link className="btn btn-primary" to="/">
          <IntlMessages id="extraPages.goHome" />
        </Link>
      </p>
    </div>
  </div>
);

export default Error404;
