import React from 'react';
import { NowOptions } from './NowOptions';
import { SystemOptions } from './SystemOptions';
import { useActiveSessionQuery } from 'modules/sessions';

const StudentDashboardOptions = (  ) => {
  const { isLoading, isNow } = useActiveSessionQuery();
  if ( isLoading ) return <div />;
  if ( isNow ) return <NowOptions />;
  return <SystemOptions />;
};
export default StudentDashboardOptions;
