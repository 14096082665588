import { getHttpRequest, putHttpRequest } from 'utils/ApiPetitionhandles';

export async function updateShareAchievement(
  workgroupId,
  data = { uploadImage: '' },
  params,
) {
  const url = `v2/achievement-workgroup/${workgroupId}`;
  return putHttpRequest( url, data, params );
}
export async function gethareAchievement( workgroupId, params ) {
  const url = `v2/achievement-workgroup/${workgroupId}`;
  return getHttpRequest( url, params );
}
