import { Button, CircularProgress, Container, Grid } from '@material-ui/core';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import CardBox from 'components/custom_v2/CardBox';
import {
  useAiReportQuery,
  useGenerateAIReportMutation,
} from 'modules/reports/hooks';

import HeaderImage from 'assets/images/kotler/sectionHeaders/info-research.jpeg';
import React, { useEffect, useState } from 'react';

import { useRoundsBySessionId } from 'modules/Rounds/hooks/roundsBySessionId';

import { Form } from 'react-final-form';
import { Select } from 'components/custom/FormElements';
import {
  composeValidators,
  greaterThanString,
  required,
} from 'config/InputErrors';
import { useIntl } from 'react-intl';
import { getRoundNameToTranslate } from 'modules/Rounds/helpers/getRoundNameToTranslate';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import SectionInfoText from 'components/custom/SectionInfoText';

const INTERVAL = 1000 * 20;
const ReportAIPage = ( { sessionId } ) => {
  const { $t } = useIntl();

  const [refetchInterval, setRefetchInterval] = useState( false );
  const [confirmPurchaseModal, setConfirmPurchaseModal] = useState( false );
  // queries
  const { rounds } = useRoundsBySessionId( sessionId, { 'filter[status]': 'CLOSED' } );

  const { query: aiReportQuery, reportData } = useAiReportQuery( sessionId, { refetchInterval } );

  // mutations
  const { mutate, ...reportMutation } = useGenerateAIReportMutation();

  useEffect( () => {
    if ( reportData.creationStatus ) setRefetchInterval( reportData.isGenerating && INTERVAL );
  }, [reportData.isGenerating, reportData.creationStatus] );

  const isloading = reportData.loading || reportData.isGenerating || reportMutation.isLoading;

  return (
    <>
      <HeadConfig
        breadcrumbsLinks={[
          {
            url: '/',
            name: 'HomeLink',
          },
          { name: 'AI Report' },
        ]}
      />
      <KotlerSectionHeader title="AI Report" image={HeaderImage} />

      <Container className="mt-4">
        <SectionInfoText title="reportAiDescription" />
        <Grid container>
          <Grid item xs={12}>
            <CardBox
              styleName="px-2 pb-0  mb-4 "
              header={
                <KotlerCardHeader title="generate a report" minHeight="0px" />
              }
            >
              <Form
                initialValues={
                  rounds
                  && rounds.length && {
                    fromRoundId:
                      rounds[rounds.length < 3 ? 0 : rounds.length - 3].id,
                    toRoundId: rounds[rounds.length - 1].id,
                  }
                }
                onSubmit={( { fromRoundId, toRoundId } ) => {
                  setConfirmPurchaseModal( false );
                  mutate(
                    { fromRoundId, toRoundId, sessionId },
                    {
                      onSuccess: () => {
                        aiReportQuery.refetch();
                        setRefetchInterval( INTERVAL );
                      },
                    },
                  );
                }}
                render={( { handleSubmit, form, valid } ) => (
                  <form onSubmit={handleSubmit}>
                    <div className="d-flex flex-column flex-md-row ">
                      <Select
                        field="fromRoundId"
                        label="startDate"
                        validate={required}
                        disabled
                        translateOptions={false}
                        options={rounds.map( ( round ) => {
                          const rn = getRoundNameToTranslate( round.shortName );

                          const name = round.shortName
                            ? $t( { id: rn.name }, { value: rn.number } )
                            : '';
                          return {
                            id: round.id,
                            name,
                          };
                        } )}
                      />
                      <div className="mx-4" />
                      <Select
                        disabled
                        field="toRoundId"
                        label="endDate"
                        validate={composeValidators(
                          required,
                          greaterThanString( 'fromRoundId' ),
                        )}
                        translateValues={{ label: $t( { id: 'startDate' } ) }}
                        translateOptions={false}
                        options={rounds.map( ( round ) => {
                          const rn = getRoundNameToTranslate( round.shortName );

                          const name = round.shortName
                            ? $t( { id: rn.name }, { value: rn.number } )
                            : '';
                          return {
                            id: round.id,
                            name,
                          };
                        } )}
                      />
                    </div>

                    <Button
                      disabled={
                        isloading
                        || rounds.length < 3
                        || !reportData.canNewGenerate
                      }
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => {
                        if ( !valid ) return form.submit();
                        setConfirmPurchaseModal( true );
                      }}
                    >
                      {$t( { id: 'generate report' } )}
                    </Button>
                    <ConfirmationDialog
                      open={confirmPurchaseModal}
                      title="confirmReportPurchase"
                      subTitle="reportAiMessage"
                      confirmBtnText="ok"
                      closeBtnText="cancelBtn"
                      onSubmit={() => handleSubmit()}
                      onClose={() => setConfirmPurchaseModal( false )}
                    />
                  </form>
                )}
              />
            </CardBox>
          </Grid>
          <Grid item xs={12}>
            <CardBox
              styleName="px-2 mb-4 pb-0  mt-4"
              header={(
                <KotlerCardHeader
                  title="last report ai generated"
                  minHeight="0px"
                />
              )}
            >
              {isloading && <CircularProgress />}
              {reportData.isGenerating && (
                <>
                  <br />
                  <span className="text-dark">
                    {$t( { id: 'reportGenerationPending' } )}
                  </span>
                </>
              )}
              {!isloading && reportData.neverGenerated && (
                <span className="text-dark">
                  {$t( { id: 'never have generated a report' } )}
                </span>
              )}

              {reportData.isfailed && (
                <span className="text-danger">
                  {$t( { id: 'error.reportGeneration' } )}
                </span>
              )}

              {reportData.url && (
                <iframe
                  src={reportData.url}
                  title="AI Report"
                  type="application/pdf"
                  width="100%"
                  style={{ aspectRatio: '3/4', maxHeight: '80vh' }}
                />
              )}
            </CardBox>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default ReportAIPage;
