import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import './ResponseAi.scss';
import { useSelector } from 'react-redux';
import { ErrorOutline } from '@material-ui/icons';
import IntlMessages from 'utils/IntlMessages';
import FeatherIcon from 'components/custom/FeatherIcon';
import { useTyping } from 'hooks';
import AiIcon from './AiIcon';
import AiSource from './AiSource';

/**
 * ResponseAi Component
 * This component displays the response from an AI assistant.
 */
const ResponseAi = () => {
  const { message, error, loading } = useSelector( ( { manualAi } ) => manualAi );
  const [showLinks, setShowLinks] = useState( false );
  const [text, clearText] = useTyping(
    message,
    {
      onFinishTyping: () => {
        setShowLinks( true );
      },
    },
    10,
  );

  // Effect to simulate text typing

  useEffect( () => {
    if ( loading ) {
      clearText();
      setShowLinks( false );
    }
  }, [loading, clearText] );

  return (
    <div className="d-flex " style={{ width: '100%' }}>
      {( loading || message || error ) && (
        <div className="manual-ai-response-container shadow shadow-lg ">
          {!loading && !error && text && (
            <Typography variant="body" className="text-to-write ">
              <AiIcon />
              {' '}
              {text}
            </Typography>
          )}
          {loading && (
            <div className="d-flex justify-content-center">
              <FeatherIcon
                className="animate__animated animate__rotateOut animate__infinite"
                size={24}
                icon="loader"
                color="white"
              />
            </div>
          )}
          {error && (
            <div className="d-flex align-items-center">
              <ErrorOutline color="error" className="mr-1" />

              <Typography
                variant="body"
                style={{ fontSize: 12 }}
                color="error"
                className="text-to-write"
              >
                <IntlMessages id="manualAiError" />
              </Typography>
            </div>
          )}
          {false && (
            <div
              style={{
                height: showLinks ? undefined : 0,
                overflow: 'hidden',
                gap: 5,
                paddingTop: showLinks ? 10 : 0,
                opacity: showLinks ? 1 : 0,
                transition: showLinks ? 'opacity 3s  ease-out' : undefined,
              }}
              className="d-flex flex-wrap  align-items-center "
            >
              <span style={{ color: '#AFE2D9', fontSize: 16 }}> Fuentes:</span>

              <AiSource
                id={1}
                title="Livemax World"
                link="/tutorial/livemax_world"
              />
              <AiSource
                id={2}
                title="Your business"
                link="/tutorial/your_business"
              />
              <AiSource id={3} title="Welcome" link="/tutorial/welcome" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ResponseAi;
