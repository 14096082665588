import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import IntlMessages from 'utils/IntlMessages';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Home from '@material-ui/icons/Home';

import ExpandLess from '@material-ui/icons/RemoveCircleOutlineOutlined';
import ExpandMore from '@material-ui/icons/AddCircleOutlineOutlined';
import { Route, Switch, Redirect } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
// eslint-disable-next-line no-unused-vars
import grap from 'assets/images/help/help_graph_1.png';
// eslint-disable-next-line no-unused-vars
import grap2 from 'assets/images/help/help_graph_2.png';
import HeadConfig from 'components/custom/HeadConfig';
import { ManualAi, useManualAiStore } from 'modules/ManualAi';

import { Backdrop, Container } from '@material-ui/core';
import TutorialInfo from './components/TutorialInfo';
import TutorialListItem from './components/TutorialListItem';
import './tutorial.scss';

const useStyles2 = makeStyles( theme => ( {
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: { paddingLeft: theme.spacing( 4 ) },
} ) );

const useStyles = makeStyles( theme => ( {
  root: { width: '100%' },
  button: {
    marginTop: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 ),
  },
  actionsContainer: { marginBottom: theme.spacing( 2 ) },
  resetContainer: { padding: theme.spacing( 3 ) },
} ) );
const RedirectToSpecificId = () => {
  // Aquí especifica el id al que deseas redirigir
  const specificId = 'welcome';

  return <Redirect to={`/tutorial/${specificId}`} />;
};

const Tutorial = ( { match } ) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { show: showManualResponse, setShow, clearAsk } = useManualAiStore();
  useEffect( () => {
    clearAsk();
  }, []); // eslint-disable-line
  const [open, setOpen] = React.useState( true );
  const [open2, setOpen2] = React.useState( true );

  const handleClick = () => {
    setOpen( !open );
  };
  const handleClick2 = () => {
    setOpen2( !open2 );
  };
  const changeLink = () => {
    setShow( false );
  };

  return (
    <div
      className="position-relative  overflow-auto"
      style={{ height: '100%' }}
    >
      <Backdrop
        style={{
          zIndex: 1,
          backgroundColor: 'rgba(0,0,0,.1)',
          backdropFilter: 'blur(10px)',
        }}
        open={showManualResponse}
        onClick={() => setShow( false )}
      >
        <div />
      </Backdrop>
      <Container maxWidth="xl" className="h-100">
        <div className="  px-md-3 pb-1 pt-3   ">
          <HeadConfig
            breadcrumbsLinks={[
              {
                url: '/',
                name: 'HomeLink',
              },
              { name: 'Tutorial' },
            ]}
          />

          <div className={classes.root}>
            <div className="row">
              <div className="col-md-4 col-lg-3 ">
                <ManualAi onClickLink={changeLink} />

                <List
                  style={{ position: 'sticky', top: '0' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={(
                    <ListSubheader
                      component="div"
                      disableSticky
                      className=" text-primary"
                      id="nested-list-subheader"
                    >
                      <IntlMessages id="tutorial.index.title" />
                    </ListSubheader>
                  )}
                  className={classes2.root}
                >
                  <TutorialListItem
                    onClick={changeLink}
                    Icon={<Home />}
                    id="welcome"
                  />

                  <TutorialListItem onClick={changeLink} id="livemax_world" />
                  <TutorialListItem onClick={changeLink} id="your_business" />
                  <TutorialListItem onClick={changeLink} id="the_competition" />
                  <TutorialListItem
                    onClick={changeLink}
                    id="how_is_livemax_used"
                  />

                  <ListItem button onClick={handleClick2}>
                    <ListItemIcon>
                      {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                    <ListItemText
                      primary={<IntlMessages id="tutorial.index.key.info2" />}
                    />
                  </ListItem>
                  <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <TutorialListItem
                        onClick={changeLink}
                        className="pl-4"
                        id="tips_to_get_started"
                      />
                      <TutorialListItem
                        onClick={changeLink}
                        className="pl-4"
                        id="checklist_for_first_decisions"
                      />
                    </List>
                  </Collapse>
                  <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                    <ListItemText
                      primary={<IntlMessages id="tutorial.index.key.info" />}
                    />
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <TutorialListItem
                        onClick={changeLink}
                        className="pl-4"
                        id="simulation_start"
                      />
                      <TutorialListItem
                        onClick={changeLink}
                        className="pl-4"
                        id="fixed_costs"
                      />
                      <TutorialListItem
                        onClick={changeLink}
                        className="pl-4"
                        id="variable_costs"
                      />
                      <TutorialListItem
                        onClick={changeLink}
                        className="pl-4"
                        id="p&l_dictionary"
                      />
                      <TutorialListItem
                        onClick={changeLink}
                        className="pl-4"
                        id="new_product_development"
                      />
                    </List>
                  </Collapse>
                </List>
              </div>
              <div className="col-md-8 col-lg-9 pb-0   border-left  border-grey">
                <div
                  className=" tutorial-content-container position-sticky"
                  style={{ top: 0 }}
                >
                  <Switch>
                    <Route
                      path={`${match.url}/`}
                      exact
                      component={RedirectToSpecificId}
                    />
                    <Route path={`${match.url}/:id`} component={TutorialInfo} />
                    <Route component={Error404} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ( { user } ) => ( { user } );
// const mapStateToProps = ( { user } ) => ( { user } );

export default connect(
  mapStateToProps,
  null,
)( Tutorial );
