import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useRefetchActiveSession } from 'modules/sessions/hooks/useActiveSessionQuery';
import { useAlertSessionManager } from 'modules/sessions/hooks/useAlertSessionManager';
import Dashboard from './Dashboard';
import Session from './Session';
import Tutorial from './Tutorial';
import GroupSettings from '../../GroupSettings';

const App = ( { user } ) => {
  const refetchActiveSession = useRefetchActiveSession();
  useAlertSessionManager();
  // eslint-disable-next-line max-len
  const pathname = useSelector(
    ( { router } ) => router && router.location && router.location.pathname,
  );

  useEffect( () => {
    refetchActiveSession();
  }, [pathname, refetchActiveSession] );

  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/tutorial" component={Tutorial} />
      <Route path="/sessions" component={Session} />
      <Route
        exact
        path="/group-settings"
        render={props => <GroupSettings {...props} user={user} />}
      />
      <Route path="/">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default App;
