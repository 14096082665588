import React from 'react';
import { connect } from 'react-redux';

import SettingsActions from 'store/reducers/Settings';
import { Route } from 'react-router';
import { Container } from '@material-ui/core';
import Activity from './Activity';
import ActivityDetail from './ActivityDetail';

const General = ( { match } ) => (
  <>
    <Container className="mt-3" maxWidth="lg">
      <Route path={`${match.url}/activity`} component={Activity} />
      <Route path={`${match.url}/activitydetail`} component={ActivityDetail} />
    </Container>
  </>
);

const mapStateToProps = ( { settings } ) => ( { navCollapsed: settings.navCollapsed } );

const mapDispatchToProps = { toggleCollapsedNav: SettingsActions.toggleCollapsedNav };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( General );
