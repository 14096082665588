import { Dialog, Hidden, IconButton, Zoom, Button } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Loader, X } from 'react-feather';
import HoverTitle from 'components/custom_v2/HoverTitle';
import { connect, useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'components/custom/FeatherIcon';
import html2canvas from 'html2canvas';
import SettingsActions from 'store/reducers/Settings';
import { injectIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import IntlMessages from 'utils/IntlMessages';
import ACHIEVEMENTS from '../constants/achievements';
import { AchievementActions } from '../Redux/achievement';
import { updateShareAchievement } from '../NotificationService';
// import { updateShareAchievement } from '../NotificationService';

const PrevButton = ( { onClick, currentSlide } ) => (
  <Hidden smDown>
    <IconButton
      onClick={( e ) => {
        e.stopPropagation();
        onClick( e );
      }}
      style={{
        display: currentSlide === 0 ? 'none' : 'block',
        position: 'absolute',
        left: '5%',
        top: ' 50%',
        transform: ' transform: translate(-50%, 0);',
        backgroundColor: '#fff',
        borderRadius: '50%',
        zIndex: 3000,
      }}
    >
      <ChevronLeft fontSize="50px" />
    </IconButton>
  </Hidden>
);
const NextButton = ( { currentSlide, slideCount, onClick, className } ) => (
  <Hidden smDown>
    <IconButton
      onClick={onClick}
      style={{
        display:
          currentSlide === slideCount || className.includes( 'disabled' )
            ? 'none'
            : 'block',
        position: 'absolute',
        right: '5%',
        top: ' 50%',
        transform: ' transform: translate(-50%, 0);',
        backgroundColor: '#fff',
        borderRadius: '50%',
        zIndex: 3000,
      }}
    >
      <ChevronRight fontSize="50px" />
    </IconButton>
  </Hidden>
);
export default () => {
  const slideRef = useRef( null );

  const open = useSelector( ( { achievement } ) => achievement.open );
  const { isShowed, sessionId } = useSelector( ( { achievement, user } ) => {
    const activeSessionId = user && user.activeSession && user.activeSession.id;
    if ( !activeSessionId ) return { sessionId: activeSessionId, isShowed: false };
    return {
      sessionId: activeSessionId,
      isShowed: !!achievement.showedList.includes( activeSessionId ),
    };
  } );

  const dispatch = useDispatch();

  const setOpen = value => dispatch( AchievementActions.setOpen( value ) );
  const onClose = () => {
    dispatch( AchievementActions.setOpen( false ) );
    dispatch( AchievementActions.setShowed( sessionId ) );
  };

  const achievements = useSelector(
    ( { user } ) => {
      const notifications = user && user.notifications && user.notifications.data;
      if ( !notifications ) return [];
      return notifications.filter( ( { isAchievement } ) => isAchievement );
    },
    ( prev, next ) => JSON.stringify( prev ) === JSON.stringify( next ),
  );

  useEffect( () => {
    if ( achievements.length && !isShowed ) setOpen( true );
  }, [sessionId]); // eslint-disable-line
  return (
    <>
      <HoverTitle id="achievements" placement="left">
        <IconButton
          className="p-1 border-primary p-2"
          style={{ zIndex: 300, borderRadius: 2, backgroundColor: '#2CD5C4' }}
          onClick={() => setOpen( true )}
        >
          <FeatherIcon icon="award" size="24px" color="text.primary" />
        </IconButton>
      </HoverTitle>
      <Dialog
        open={open && achievements.length}
        onClose={onClose}
        fullScreen
        TransitionComponent={Zoom}
        TransitionProps={{ direction: 'down' }}
        transitionDuration={300}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0,0,0,.3)',
            backdropFilter: 'blur(4px)',
            WebkitBackdropFilter: 'blur(4px)',
          },
        }}
        className="slick-throphies"
      >
        <div className="w-100 h-100 d-flex align-items-center  position-relative overflow-hidden">
          <IconButton
            onClick={onClose}
            style={{
              position: 'absolute',
              top: '15px',
              right: '15px',
              padding: '5px',
              zIndex: '3000',
              backgroundColor: 'rgba(0,0,0,.5)',
            }}
          >
            <X color="#fff" />
          </IconButton>
          <Slider
            prevArrow={<PrevButton />}
            nextArrow={<NextButton />}
            ref={slideRef}
            focusOnSelect
            infinite={false}
            autoplay
            arrows
            autoplaySpeed={7000}
            speed={1000}
            slidesToScroll={1}
            slidesToShow={1}
            className=" w-100  overflow-x-hidden p-0 m-0"
          >
            {achievements.map( item => (
              <ItemCarrousel
                item={item}
                key={item.id}
                slider={slideRef.current}
                onNext={
                  slideRef.current ? slideRef.current.slickNext : () => {}
                }
              />
            ) )}
          </Slider>
        </div>
      </Dialog>
    </>
  );
};

const ItemCarrouselBase = ( { item: { data }, user, intl, toggleAlert } ) => {
  const [image, setImage] = useState( null );
  const [isSharing, setIsSharing] = useState( false );
  const onShare = async () => {
    if ( data && data.achievementWorkgroupId && image ) {
      try {
        setIsSharing( true );
        const response = await updateShareAchievement(
          data.achievementWorkgroupId,
          { uploadImage: image },
        );
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${response.image}`,
          'Compartir en LinkedIn',
        );
        setIsSharing( false );
      } catch ( error ) {
        toggleAlert( 'errorToShare', 'danger', true );
        setIsSharing( false );
      }
    }
  };
  useEffect( () => {
    if ( user.name && data.objective ) {
      const achievementsImage = ACHIEVEMENTS[data.objective]
        && `<img style="width:100%;height:100%;object-fit:fit-contain;" src="${ACHIEVEMENTS[data.objective].Image}" alt="achievement" />`;

      const userGreeting = user && user.name
        ? `<h3 class="position-absolute text-white text-capitalize" style="top: 28%; left: 6%; right: 50%; font-size: 38px; font-weight: 700; letter-spacing: 1.5px; line-height: 44px;">${intl.formatMessage(
          { id: 'congratulations.user' },
          { user: user.name },
        )}</h3>`
        : '';

      const textContent = data.objective
        ? `<p class="position-absolute d-flex align-items-center" style="top:65%; bottom: 0px; right: 40%; left: 6%; font-size: 28px; color: #01ffe0; text-align: justify;">${intl.formatMessage(
          { id: data.objective },
        )}</p>`
        : '';

      const htmlContent = `
      ${achievementsImage}
      ${userGreeting}
      ${textContent}
    `;

      const tempElement = document.createElement( 'div' );
      tempElement.style = 'width: 800px; height: 800px;position:relative;';
      tempElement.innerHTML = htmlContent;
      document.body.appendChild( tempElement );

      html2canvas( tempElement ).then( ( canvas ) => {
        const base64Image = canvas.toDataURL( 'image/png' );
        setImage( base64Image );
        document.body.removeChild( tempElement );
      } );
    }
  }, [user, data, intl] );
  return (
    <div className="w-100 d-flex justify-content-center align-items-center p-4">
      <div
        className="position-relative  d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: '#001683',
          width: '100%',
          maxWidth: '80vh',
          aspectRatio: '1/1',
          borderRadius: '10px',
          boxShadow: '0px 3px 10px 2px #000',
        }}
      >
        {image ? (
          <img style={{ borderRadius: 10 }} src={image} alt="achievement" />
        ) : (
          <Spinner animation="border" variant="light" role="status" />
        )}

        {!!image && (
          <Button
            className="position-absolute text-primary bg-white px-2 py-1 text-blue
          align-items-end border-dark border"
            color="default"
            size="lg"
            variant="filled"
            style={{
              borderRadius: 5,
              top: 10,
              left: 10,
            }}
            disabled={!image || isSharing}
            onClick={onShare}
          >
            {isSharing ? (
              <Loader
                size={20}
                className="animate__animated animate__rotateIn animate__infinite mx-1"
              />
            ) : (
              <i className="zmdi zmdi-linkedin-box font-size-20 mr-1" />
            )}
            <span className=" font-weight-bold text-body text-blue  ">
              <IntlMessages id="share" />
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

const ItemCarrousel = connect(
  ( { user } ) => ( { user } ),
  {
    toggleAlert: SettingsActions.toggleAlert,
    toggleLoading: SettingsActions.toggleLoading,
  },
)( injectIntl( ItemCarrouselBase ) );
