import api from '../config/Api';

const endpoint = 'v1';
const endpoint2 = 'v2';

export default {

  getReport( sessionId ) {
    return api.get( `${endpoint}/session/${sessionId}/report/parametrization` );
  },

  getMarketPositioningReport( sessionId, params ) {
    return api.get( `${endpoint2}/session/${sessionId}/market-report/preference-positioning`, params );
  },

  getAdminReport( id ) {
    return api.get( `${endpoint}/report/${id}/parametrization` );
  },
};
