import api from '../config/Api';

const endpoint = 'v2/session';

export default {

  getFinalStrategicReport( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/finalstrategicreport`, params );
  },

  saveFinalStrategicReport( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/finalstrategicreport`, data );
  },

  updateFinalStrategicReport( sessionId, finalStrategicReportId, frmData ) {
    const data = frmData;
    delete data.id;
    delete data.createdAt;
    return api.put( `${endpoint}/${sessionId}/finalstrategicreport/${finalStrategicReportId}`, data );
  },
};
