export const FETCH_CUSTOMER_ADQUISITION_COST_REQUEST = 'FETCH_CUSTOMER_ADQUISITION_COST_REQUEST';
export const FETCH_CUSTOMER_ADQUISITION_COST_SUCCESS = 'FETCH_CUSTOMER_ADQUISITION_COST_SUCCESS';
export const FETCH_CUSTOMER_ADQUISITION_COST_FAILURE = 'FETCH_CUSTOMER_ADQUISITION_COST_FAILURE';
export const CLEAR_CUSTOMER_ADQUISITION_COST = 'CLEAR_CUSTOMER_ADQUISITION_COST';

export const fetchCustomerAdquisitionCostRequest = ( sessionId, params ) => ( {
  type: FETCH_CUSTOMER_ADQUISITION_COST_REQUEST,
  payload: { sessionId, params },
} );

export const fetchCustomerAdquisitionCostSuccess = data => ( {
  type: FETCH_CUSTOMER_ADQUISITION_COST_SUCCESS,
  payload: data,
} );

export const fetchCustomerAdquisitionCostFailure = error => ( {
  type: FETCH_CUSTOMER_ADQUISITION_COST_FAILURE,
  payload: error,
} );

export const clearCustomerAdquisitionCost = () => ( { type: CLEAR_CUSTOMER_ADQUISITION_COST } );
