import React from 'react';
import Activity from 'assets/images/kotler/dashboardIcons/activity.png';
import Analytics from 'assets/images/kotler/dashboardIcons/analytic.png';
import Company from 'assets/images/kotler/dashboardIcons/company.png';
import Customers from 'assets/images/kotler/dashboardIcons/customers.png';
import OneToOneCampaing from 'assets/images/kotler/dashboardIcons/one-to-one-campaing.png';
import Comunication from 'assets/images/kotler/dashboardIcons/comunication.png';
import CX from 'assets/images/kotler/dashboardIcons/cx.png';
import Distribution from 'assets/images/kotler/dashboardIcons/distribution.png';
import Market from 'assets/images/kotler/dashboardIcons/market.png';
import Price from 'assets/images/kotler/dashboardIcons/price.png';
import Products from 'assets/images/kotler/dashboardIcons/products.png';
import Products2 from 'assets/images/kotler/dashboardIcons/products2.png';
import Search from 'assets/images/kotler/dashboardIcons/search.png';
import Services from 'assets/images/kotler/dashboardIcons/services.png';
import Settings from 'assets/images/kotler/dashboardIcons/settings.png';
import PersonConfig from 'assets/images/kotler/dashboardIcons/person-config.png';
import Changelog from 'assets/images/kotler/dashboardIcons/changelog.webp';

const NavigationOptions = ( {
  user,
  admin = false,
  // activeStrategy = false,
  studio = false,
  now = false,
  professor = false,
} ) => {
  if ( admin ) {
    return {
      desicion: [
        {
          icon: 'users',
          title: 'users',
          link: '/users',
          /* onClick:() => changeActiveSession( user.activeSession ) */
        },
        {
          icon: 'clipboard-list',
          title: 'params',
          link: '/params',
          /* onClick:() => changeActiveSession( user.activeSession ) */
        },
        {
          icon: 'university',
          title: 'university',
          link: '/universities',
          /* onClick:() => changeActiveSession( user.activeSession ) */
        },
      ],
    };
  }
  if ( professor ) {
    return {
      information: [
        {
          image: <img src={Company} alt="company" />,
          title: 'company',
          link: `/sessions/${user.activeSession.id}/info/company`,
        },
        {
          image: <img src={Products} alt="products" />,
          title: 'products',
          link: `/sessions/${user.activeSession.id}/info/products`,
        },

        {
          image: <img src={Market} alt="market" />,
          title: 'market',
          link: `/sessions/${user.activeSession.id}/info/market`,
        },
        {
          image: <img src={Search} alt="search" />,
          title: 'research',
          link: `/sessions/${user.activeSession.id}/info/research`,
        },
        {
          image: <img src={Analytics} alt="analytic" />,
          title: 'info.analytics',
          link: `/sessions/${user.activeSession.id}/info/analytics`,
        },
        {
          image: <img src={Customers} alt="customers" />,
          title: 'customers',
          subtitle: 'CRM',
          link: `/sessions/${user.activeSession.id}/info/customer`,
        },
      ],
      activity: [
        {
          image: <img src={Activity} alt="search" />,
          title: 'activity',
          link: `/sessions/${user.activeSession.id}/activities/activity`,
          /*       onClick: () => changeActiveSession( user.activeSession ), */
        },
        {
          image: <img src={Changelog} alt="changelog" />,
          title: 'changelog',
          link: '/changelog',
          /*   onClick: () => changeActiveSession( user.activeSession ), */
        },
      ],
      desicion: [
        {
          image: <img src={Settings} alt="round-admin" />,
          title: 'roundmngmnt',
          link: `/sessions/${user.activeSession.id}/general/rounds`,
          /* onClick:() => changeActiveSession( user.activeSession ) */
        },
        {
          image: <img src={PersonConfig} alt="groups" />,
          title: 'workgroups',
          link: `/sessions/${user.activeSession.id}/general/user-licenses`,
          /*  onClick:() => changeActiveSession( user.activeSession ) */
        },
      ],
    };
  }

  if ( studio ) {
    return {
      information: [
        {
          image: <img src={Company} alt="company" />,
          title: 'company',
          link: '/sessions/info/company',
        },
        {
          image: <img src={Search} alt="search" />,
          title: 'research',
          link: '/sessions/info/research',
        },
        {
          image: <img src={Market} alt="market" />,
          title: 'market',
          link: '/sessions/info/market',
        },
        {
          image: <img src={Customers} alt="customers" />,
          title: 'customers',
          subtitle: 'CRM',
          link: '/sessions/info/customer',
        },
      ],
      desicion: [
        {
          image: <img src={Products2} alt="products" />,
          title: 'products',
          link: '/sessions/general/products',
        },
        {
          image: <img src={Price} alt="price" />,
          title: 'prices',
          link: '/sessions/general/price',
        },
        {
          image: <img src={Distribution} alt="distribution" />,
          title: 'distribution',
          link: '/sessions/general/distribution',
        },
        {
          image: <img src={OneToOneCampaing} alt="1 a 1 campaing" />,
          title: '1to1Campaigns',
          subtitle: 'CRM',
          link: '/sessions/general/one-to-one',
        },
        {
          image: <img src={Comunication} alt="comunication" />,
          title: 'communications',
          link: '/sessions/general/crm',
        },
        // ...( activeStrategy
        //   ? [
        //     {
        //       image: <img src={Strategies} alt="strategies" />,
        //       title: 'strategies',
        //       link: '/sessions/general/strategy',
        //     },
        //   ]
        //   : [] ),
      ],
    };
  }
  if ( now ) {
    return {
      information: [
        {
          image: <img src={Company} alt="company" />,
          title: 'company',
          link: '/sessions/info/company',
        },
        {
          image: <img src={Search} alt="search" />,
          title: 'research',
          link: '/sessions/info/research',
        },
        {
          image: <img src={Market} alt="market" />,
          title: 'market',
          link: '/sessions/info/market',
        },
      ],
      desicion: [
        {
          image: <img src={Products2} alt="products" />,
          title: 'products',
          link: '/sessions/general/products',
        },
        {
          image: <img src={Price} alt="price" />,
          title: 'prices',
          link: '/sessions/general/price',
        },
        {
          image: <img src={Comunication} alt="comunication" />,
          title: 'communications',
          link: '/sessions/general/crm',
        },
        // ...( activeStrategy
        //   ? [
        //     {
        //       image: <img src={Strategies} alt="strategies" />,
        //       title: 'strategies',
        //       link: '/sessions/general/strategy',
        //     },
        //   ]
        //   : [] ),
      ],
    };
  }
  if ( now ) {
    return {
      information: [
        {
          image: <img src={Company} alt="company" />,
          title: 'company',
          link: '/sessions/info/company',
        },
        {
          image: <img src={Search} alt="search" />,
          title: 'research',
          link: '/sessions/info/research',
        },
        {
          image: <img src={Market} alt="market" />,
          title: 'market',
          link: '/sessions/info/market',
        },
      ],
      desicion: [
        {
          image: <img src={Products2} alt="products" />,
          title: 'products',
          link: '/sessions/general/products',
        },
        {
          image: <img src={Price} alt="price" />,
          title: 'prices',
          link: '/sessions/general/price',
        },
        {
          image: <img src={Comunication} alt="comunication" />,
          title: 'communications',
          link: '/sessions/general/crm',
        },
        // ...( activeStrategy
        //   ? [
        //     {
        //       image: <img src={Strategies} alt="strategies" />,
        //       title: 'strategies',
        //       link: '/sessions/general/strategy',
        //     },
        //   ]
        //   : [] ),
      ],
    };
  }

  return {
    information: [
      {
        image: <img src={Company} alt="company" />,
        title: 'company',
        link: '/sessions/info/company',
      },
      {
        image: <img src={Products} alt="products" />,
        title: 'products',
        link: '/sessions/info/products',
      },

      {
        image: <img src={Market} alt="market" />,
        title: 'market',
        link: '/sessions/info/market',
      },

      {
        image: <img src={Search} alt="search" />,
        title: 'research',
        link: '/sessions/info/research',
      },
      {
        image: <img src={Activity} alt="search" />,
        title: 'activity',
        link: '/sessions/info/activity',
      },
      {
        image: <img src={Customers} alt="customers" />,
        title: 'customers',
        subtitle: 'CRM',
        link: '/sessions/info/customer',
      },
    ],
    desicion: [
      {
        image: <img src={Products2} alt="products" />,
        title: 'products',
        link: '/sessions/general/products',
      },
      {
        image: <img src={Services} alt="services" />,
        title: 'services',
        link: '/sessions/general/services',
      },
      {
        image: <img src={Price} alt="price" />,
        title: 'prices',
        link: '/sessions/general/price',
      },
      {
        image: <img src={Distribution} alt="distribution" />,
        title: 'distribution',
        link: '/sessions/general/distribution',
      },
      {
        image: <img src={CX} alt="cx" />,
        title: 'cem',
        link: '/sessions/general/cem',
      },
      {
        image: <img src={Comunication} alt="comunication" />,
        title: 'communications',
        link: '/sessions/general/crm',
      },
      {
        image: <img src={OneToOneCampaing} alt="1 a 1 campaing" />,
        title: '1to1Campaigns',
        subtitle: 'CRM',
        link: '/sessions/general/one-to-one',
      },
      // ...( activeStrategy
      //   ? [
      //     {
      //       image: <img src={Strategies} alt="strategies" />,
      //       title: 'strategies',
      //       link: '/sessions/general/strategy',
      //     },
      //   ]
      //   : [] ),
    ],
  };
};
export default NavigationOptions;
