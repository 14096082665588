import React, { useEffect, useState } from 'react';
import ContainerGraphModal from 'components/ContainerGraphModal';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import CardBox from 'components/custom_v2/CardBox';

/**
 * Componente GraphDecorator
 * 
 * Este componente se utiliza para decorar elementos gráficos, como gráficos de datos, con la capacidad de mostrar un modal emergente al hacer clic en el elemento gráfico.
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {ReactNode} props.children - Los elementos que se decorarán y se mostrarán en el elemento gráfico.
 * @param {ReactNode} props.modalGraph - El componente de gráfico que se mostrará en el modal emergente.
 * @param {string} props.title - El título del elemento gráfico.
 * @param {string} props.hoverTitle - El título que se muestra al pasar el cursor sobre el elemento gráfico.
 * @returns {JSX.Element} - El componente GraphDecorator.
 */
const GraphDecorator = ({ children, modalGraph,extraChilds, title, hoverTitle,...rest }) => {
  const [openModal, setOpenModal] = useState(false);
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    if (openModal) {
      const timeout = setTimeout(() => {
        setCanRender(openModal);
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [openModal]);

  return (
    <>
      <CardBox 
        header={<KotlerCardHeader minHeight='0px' title={title} />}
        styleName="px-2 pb-0 h-100 d-flex flex-column"
        childrenStyle="d-flex flex-column flex-grow-1"
        hover={hoverTitle && { id: hoverTitle }}
         {...rest}
      >
        <div className='w-100' style={{cursor:(modalGraph && "pointer")}} onClick={() => setOpenModal(true)} >
           {children}
        </div>
        {extraChilds}
      </CardBox>

      {modalGraph && (
        <ContainerGraphModal
          open={openModal}
          title={title}
          onClose={() => {
            setOpenModal(false);
          }}
        >
          {canRender && modalGraph}
        </ContainerGraphModal>
      )}
    </>
  );
};

export default GraphDecorator;