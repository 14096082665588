import React from 'react';
import FeatherIcon from "../FeatherIcon"
import PhoneIcon from "assets/images/kotler/icons/phone.png"
import PcIcon from "assets/images/kotler/icons/pc.png"
import TabletIcon from "assets/images/kotler/icons/tablet.png"
import ServiceIcon from "assets/images/kotler/icons/services.png"
import BundleIcon from "assets/images/kotler/icons/bundles.png"
import { isString } from 'lodash';
const images = {
    Cellular: PhoneIcon,
    Tablet: TabletIcon,
    Laptop: PcIcon,
    Service:ServiceIcon,
    Bundle:BundleIcon

}
export default ({ icon = "SmartPhone", img, size, color = "primary2.main",minHeight }) => {

    return (
        <div className='kotler-product-icon-container ' style={{minHeight}}>
            <div className="kotler-product-icon ">
                {img ? (
                    <img alt="product" src={isString(img) ? images[img] : img} />
                ) : <FeatherIcon icon={icon} size={size} color={color} />}

            </div>
        </div>

    )
}