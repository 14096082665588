import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import LineGraphFull from 'components/StatisticalGraphs/containers/LineGraphFull';
import { formatMessage, formatRoundName } from 'helpers/IntlHelpers';

const formatDataToLineGraph = ( data ) => {
  if ( !data || !data.length ) return {};

  const roundsFormated = data.map(
    ( { empl, exec, home, prof, round, students } ) => ( {
      attribute: formatRoundName( round.shortName ),
      empl,
      exec,
      home,
      prof,
      students,
    } ),
  );
  const keys = ['exec', 'prof', 'empl', 'home', 'students'];
  const seriesData = keys.map( key => ( {
    valueField: key,
    name: formatMessage( { id: `crm.media.${key}` } ),
  } ) );

  return { data: roundsFormated, seriesData };
};
const LineGraphMarketStimate = ( {
  gId = 1,
  graphHeight = '315px',
  title = '',
  hoverTitle,
  titleValue = 'SalesU',
  titleCategory = 'Rounds',
  dataKey = 'unitSales',
  marketStimate,
  loading,
  error,
  locale,
  attribute,
  children,
} ) => {
  const [dataToGraph, setDataToGraph] = useState( {} );
  useEffect( () => {
    const formatData = formatDataToLineGraph( marketStimate );
    if ( JSON.stringify( dataToGraph ) !== JSON.stringify( formatData ) ) {
      setDataToGraph( formatData );
    }
  }, [locale.locale, marketStimate, dataKey, dataToGraph] );

  return (
    <LineGraphFull
      {...{
        title,
        loading,
        error,
        hoverTitle,
        titleCategory,
        children,
        titleValue,
      }}
      height={graphHeight}
      htmlId={`line_chart_market_product_${gId}${attribute}`}
      data={dataToGraph.data}
      seriesData={dataToGraph.seriesData}
    />
  );
};

const mapStateToProps = (
  {
    market: { marketStimate: { data, loading, error } },
    settings,
  },
  { attribute },
) => ( attribute && data
  ? {
    marketStimate: data[attribute],
    loading,
    error,
    locale: settings.locale,
    // activeSessionId: user && user.activeSession && user.activeSession.id,
  }
  : {
    marketStimate: null,
    loading,
    error,
    locale: settings.locale,
    // activeSessionId: user && user.activeSession && user.activeSession.id,
  } );

export default connect( mapStateToProps )( injectIntl( LineGraphMarketStimate ) );
