import React from "react"
import { useActiveSessionQuery } from 'modules/sessions';

export const CurrentUniversityAvatar = ( { style, width = '55%' } ) => {
  const { activeSession } = useActiveSessionQuery();
  const { name, avatarPath } = ( activeSession && activeSession.entity ) || {};
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width,
        ...style,
      }}
    >
      {' '}
    {avatarPath &&  <img
        style={{
          aspectRatio: '1/1',
          borderRadius: '5px',
          objectFit: 'contain',
        }}
        src={avatarPath}
        alt={name}
      />}
    </div>
  );
};
