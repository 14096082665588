import React, { useState } from 'react';
/* import _ from 'lodash'; */
import Util from 'utils/Util';
import { Paper, Popper, Typography } from '@material-ui/core';
import { Fade } from 'reactstrap';
import { injectIntl } from 'react-intl';
import IntlMessages from 'utils/IntlMessages';

const formatText = value => Util.formatNumber( value );
const valuesEnterprise = {
  container: { height: 380, viewBox: '0 0 950 380' },
  union: { d: 'M375 240.5V290H575V240.5' },
};

export default function CustomerDiagram( {
  isEnterprise = false,
  customerReport, /* , month  */
} ) {
  const [anchorEl, setAnchorEl] = React.useState( null );
  const [tip, setTip] = useState( 'diplay' );
  const svg = document.getElementById( 'svgCustomers' );

  const handlePopoverOpen = tipTitle => ( event ) => {
    if ( tip !== tipTitle ) setTip( tipTitle );
    setAnchorEl( event.target );
  };
  const handlePopoverClose = () => {
    setAnchorEl( null );
  };

  const open = Boolean( anchorEl );
  // eslint-disable-next-line max-len
  const valuesSvg = /* mkmode.information.customer.registeredCustomerBase */ valuesEnterprise; // : valuesStudio;

  return (
    <>
      <svg
        width="950"
        {...valuesSvg.container}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* mkmode.information.customer.registeredCustomerBase */ true && (
          <path d={valuesSvg.union.d} stroke="#009681" />
        )}

        <SvgItem
          x="326"
          y="0.5"
          underLine
          title="NumberOfCustomers"
          value={customerReport.numberOfCustomers}
        />
        <SvgItem
          x="326"
          y="35.5"
          onMouseEnter={handlePopoverOpen( 'tip.activeCustomers' )}
          onMouseLeave={handlePopoverClose}
          title="ActiveCustomers"
          value={customerReport.activeCustomers}
        />
        <SvgItem
          x="326"
          y="70.5"
          onMouseEnter={handlePopoverOpen( 'tip.inactiveCustomers' )}
          onMouseLeave={handlePopoverClose}
          title="InactiveCustomers"
          value={customerReport.inactiveCustomers}
        />
        <SvgItem
          x="326"
          y="120.5"
          onMouseEnter={handlePopoverOpen( 'tip.Lost customers (12 month)' )}
          onMouseLeave={handlePopoverClose}
          underLine
          title="Lost customers (12 month)"
          value={customerReport.lastLostCustomers}
        />
        <SvgItem
          x="326"
          y="155.5"
          onMouseEnter={handlePopoverOpen( 'tip.RetentionRate (annual)' )}
          onMouseLeave={handlePopoverClose}
          title="RetentionRate (annual)"
          value={customerReport.retentionRate}
        />
        <SvgItem
          x="326"
          y="240.5"
          onMouseEnter={handlePopoverOpen(
            'tip.estimatedCustomerDuration (years)',
          )}
          onMouseLeave={handlePopoverClose}
          underLine
          title="estimatedCustomerDuration (years)"
          bold
          value={customerReport.estimatedCustomerDurationYear}
        />

        <SvgItem
          right
          x="526"
          y="0.5"
          underLine
          className="mt-3"
          title="totalAcquiredCustomers"
          value={customerReport.totalAcquiredCustomers || '0'}
        />
        <SvgItem
          right
          x="526"
          y="35.5"
          onMouseEnter={handlePopoverOpen( 'tip.NewCustomer (last 12 month)' )}
          onMouseLeave={handlePopoverClose}
          title="NewCustomer (last 12 month)"
          value={customerReport.newCustomers}
        />
        <SvgItem
          right
          x="526"
          y="70"
          onMouseEnter={handlePopoverOpen( 'tip.ReactivateCustomers' )}
          onMouseLeave={handlePopoverClose}
          title="ReactivateCustomers"
          value={customerReport.reactivatedCustomers}
        />
        {/* mkmode.information.customer.registeredCustomerBase */ true && (
          <SvgItem
            x="526"
            y="120.5"
            onMouseEnter={handlePopoverOpen( 'tip.ProductsPerCustomer' )}
            onMouseLeave={handlePopoverClose}
            underLine
            right
            title="ProductsPerCustomer"
            value={customerReport.productsPerCustomer}
          />
        )}
        {/* mkmode.information.customer.registeredCustomerBase */ true && (
          <SvgItem
            x="526"
            y="155.5"
            onMouseEnter={handlePopoverOpen( 'tip.Devicespercustomer' )}
            onMouseLeave={handlePopoverClose}
            right
            title="Devicespercustomer"
            value={customerReport.devicesPerCustomer}
          />
        )}
        {isEnterprise && (
          <SvgItem
            x="526"
            y="190.5"
            onMouseEnter={handlePopoverOpen( 'tip.ServicesPerCustomer' )}
            onMouseLeave={handlePopoverClose}
            right
            title="ServicesPerCustomer"
            value={customerReport.servicesPerCustomer}
          />
        )}
        {/* mkmode.information.customer.registeredCustomerBase */ true && (
          <SvgItem
            x="526"
            y="240.5"
            onMouseEnter={handlePopoverOpen(
              'tip.RevenuesPerCustomer (last 12 month)',
            )}
            onMouseLeave={handlePopoverClose}
            underLine
            bold
            right
            title="RevenuesPerCustomer (last 12 month)"
            value={customerReport.revenuesPerCustomer}
          />
        )}
        {/* mkmode.information.customer.registeredCustomerBase */ true && (
          <>
            <path d="M475 290V315" stroke="#009681" />
            <circle cx="475" cy="315" r="3" fill="#009681" />
            <SvgItem
              center
              x="426"
              y="330.5"
              onMouseEnter={handlePopoverOpen( 'tip.lifeTimeValueOfCustomers' )}
              onMouseLeave={handlePopoverClose}
              title="lifeTimeValueOfCustomers"
              bold
              value={
                customerReport.estimatedCustomerLifetime
                /*    Number( customerReport.estimatedCustomerDurationYear )
                 * Number( customerReport.revenuesPerCustomer ) */
              }
            />
          </>
        )}
      </svg>

      {/*   <IntlHtmlMessages
        id={svgId}
        values={{
          month,
          numberOfCustomers: formatText( customerReport.numberOfCustomers ),
          activeCustomers: formatText( customerReport.activeCustomers ),
          inactiveCustomers: formatText( customerReport.inactiveCustomers ),

          acquiredCustomers: formatText( customerReport.totalAcquiredCustomers || '0' ),
          newCustomers: formatText( customerReport.newCustomers ),
          reactivatedCustomers: formatText( customerReport.reactivatedCustomers ),

          lostCustomers: formatText( customerReport.lostCustomers ),
          retentionRate: formatText( customerReport.retentionRate ),
          estimatedCustomerDuration: formatText( customerReport.estimatedCustomerDurationYear ),

          lastNewCustomers: formatText( customerReport.lastNewCustomers ),


          lastLostCustomers: formatText( customerReport.lastLostCustomers ),

          productsPerCustomer: formatText( customerReport.productsPerCustomer ),
          devicesPerCustomer: formatText( customerReport.devicesPerCustomer ),
          servicesPerCustomer: formatText( customerReport.servicesPerCustomer ),
          revenuesPerCustomer: formatText( customerReport.revenuesPerCustomer ),
          estimatedCustomerLifetime: formatText( customerReport.estimatedCustomerLifetime ),

        }}
      /> */}
      <Popper
        id="mouse-over-tooltip"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        {( { TransitionProps } ) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              elevation={2}
              style={{
                maxWidth: '50vw',
                backgroundColor: '#003594',
                color: '#fff',
                padding: '3px 10px',
              }}
            >
              <Typography variant="caption" style={{ lineHeight: '100%' }}>
                <IntlMessages id={tip} />
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}
const SvgItem = injectIntl(
  ( {
    onMouseEnter,
    onMouseLeave,
    underLine = false,
    right = false,
    center = false,
    x,
    y,
    bold = false,
    title = 'hola afdafsfsgsdgsddgsgsgdfgdgdgdgdgd',
    value = 0,
    intl,
  } ) => (
    <>
      {!right && !center && (
        <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <text
            textAnchor="end"
            x={Number( x ) - 5}
            y={Number( y ) + 16}
            style={{ textDecoration: underLine ? 'underline' : null }}
            fill="#121212"
          >
            {title && intl.formatMessage( { id: title } )}
          </text>
          <rect x={x} y={y} width="98" height="23" rx="1.5" fill="#B3DFD9" />
          <text
            x={Number( x ) + 93}
            y={Number( y ) + 16}
            textAnchor="end"
            style={{ fontWeight: bold ? 'bold' : 'normal' }}
            fill="#121212"
          >
            {formatText( value )}
          </text>
          <rect x={x} y={y} width="98" height="23" rx="1.5" stroke="#009681" />
        </g>
      )}
      {right && !center && (
        <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <text
            textAnchor="start"
            x={Number( x ) + 103}
            y={Number( y ) + 16}
            style={{ textDecoration: underLine ? 'underline' : null }}
            fill="#121212"
          >
            {title && intl.formatMessage( { id: title } )}
          </text>
          <rect x={x} y={y} width="98" height="23" rx="1.5" fill="#B3DFD9" />
          <text
            x={Number( x ) + 93}
            y={Number( y ) + 16}
            textAnchor="end"
            style={{ fontWeight: bold ? 'bold' : 'normal' }}
            fill="#121212"
          >
            {formatText( value )}
          </text>
          <rect x={x} y={y} width="98" height="23" rx="1.5" stroke="#009681" />
        </g>
      )}
      {center && (
        <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <text
            textAnchor="middle"
            x={Number( x ) + 44}
            y={Number( y ) + 40}
            fill="#121212"
          >
            {title && intl.formatMessage( { id: title } )}
          </text>
          <rect x={x} y={y} width="98" height="23" rx="1.5" fill="#B3DFD9" />
          <text
            x={Number( x ) + 93}
            textAnchor="end"
            y={Number( y ) + 16}
            style={{ fontWeight: bold ? 'bold' : 'normal' }}
            fill="#121212"
          >
            {formatText( value )}
          </text>
          <rect x={x} y={y} width="98" height="23" rx="1.5" stroke="#009681" />
        </g>
      )}
    </>
  ),
);
