import React from 'react';

import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';
import HoverTitle from 'components/custom_v2/HoverTitle';
import ExcellPL from 'components/custom_v2/ExcellPL';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import PLInfoModal from 'routes/App/routes/V2/User/Session/Information/Company/PLInfoModal';

const CompPAndL = ( { data, exportFileName, mkmode = {} } ) => (
  <>
    {!!data
      && data.length > 0
      && mkmode.information
      && mkmode.information.company && (
      <Grid item xs={12}>
        <CardBox
          styleName="px-2 h-100"
          header={(
            <KotlerCardHeader
              title="ProfitLossStatement"
              besideChildren={<PLInfoModal />}
              rightComponent={(
                <div className="d-flex">
                  <ExcellPL data={data} name={exportFileName} />
                </div>
              )}
              minHeight="0px"
            />
          )}
        >
          <div
            className="jr-link"
            id="PLossCompdiv"
            style={{ overflowX: 'auto' }}
          >
            <Table className="kotler-table-info-details  kotler-table-info-sm kotler-table-profit-and-lost">
              <TableHead />

              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell align="right">
                    {data[0].workgroup.name}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {data[1].workgroup.name}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {data[2].workgroup.name}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {data[3].workgroup.name}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {data[4].workgroup.name}
                  </TableCell>
                  <TableCell />
                </TableRow>

                <TableRow className="subtitle">
                  <TableCell colSpan={11}>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <IntlMessages id="Revenues" />
                    </HoverTitle>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="RevenuesFromGoodSold" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].revenuesFromGoodsSold )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].revenuesFromGoodsSold )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].revenuesFromGoodsSold )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].revenuesFromGoodsSold )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].revenuesFromGoodsSold )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                {mkmode.information.company.profitAndLoss
                  .revenuesFromServ && (
                  <TableRow>
                    <TableCell>
                      <HoverTitle
                        id="ProfitLossStatement.hover"
                        placement="right"
                      >
                        <span>
                          <IntlMessages id="RevenuesFromServ" />
                        </span>
                      </HoverTitle>
                    </TableCell>

                    <TableCell align="right">
                      {Util.formatNumber( data[0].revenuesFromServicesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[1].revenuesFromServicesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[2].revenuesFromServicesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[3].revenuesFromServicesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[4].revenuesFromServicesSold )}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}
                {mkmode.information.company.profitAndLoss
                  .revenuesFromBundlesSales && (
                  <TableRow>
                    <TableCell>
                      <HoverTitle
                        id="ProfitLossStatement.hover"
                        placement="right"
                      >
                        <span>
                          <IntlMessages id="RevenuesFromBundlesSales" />
                        </span>
                      </HoverTitle>
                    </TableCell>

                    <TableCell align="right">
                      {Util.formatNumber( data[0].revenuesFromBundlesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[1].revenuesFromBundlesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[2].revenuesFromBundlesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[3].revenuesFromBundlesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[4].revenuesFromBundlesSold )}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}
                <TableRow>
                  <TableCell className="bg-gray-3">
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="TotalRevenuesFromSales" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[0].totalRevenuesFromSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[1].totalRevenuesFromSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[2].totalRevenuesFromSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[3].totalRevenuesFromSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[4].totalRevenuesFromSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />
                </TableRow>
                <TableRow className="subtitle">
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <IntlMessages id="DirectExpenses" />
                    </HoverTitle>
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="CostOfGoodsSold" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].costOfGoodsSold )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].costOfGoodsSold )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].costOfGoodsSold )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].costOfGoodsSold )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].costOfGoodsSold )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                {mkmode.information.company.profitAndLoss
                  .costOfServicesSold && (
                  <TableRow>
                    <TableCell>
                      <HoverTitle
                        id="ProfitLossStatement.hover"
                        placement="right"
                      >
                        <span>
                          <IntlMessages id="CostOfServicesSold" />
                        </span>
                      </HoverTitle>
                    </TableCell>

                    <TableCell align="right">
                      {Util.formatNumber( data[0].costOfServicesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[1].costOfServicesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[2].costOfServicesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[3].costOfServicesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[4].costOfServicesSold )}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}
                {mkmode.information.company.profitAndLoss
                  .costOfBundlesSold && (
                  <TableRow>
                    <TableCell>
                      <HoverTitle
                        id="ProfitLossStatement.hover"
                        placement="right"
                      >
                        <span>
                          <IntlMessages id="CostOfBundlesSold" />
                        </span>
                      </HoverTitle>
                    </TableCell>

                    <TableCell align="right">
                      {Util.formatNumber( data[0].costOfBundlesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[1].costOfBundlesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[2].costOfBundlesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[3].costOfBundlesSold )}
                    </TableCell>
                    <TableCell />

                    <TableCell align="right">
                      {Util.formatNumber( data[4].costOfBundlesSold )}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}
                <TableRow>
                  <TableCell className="bg-gray-3">
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="TotalCostFromSales" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[0].totalCostsFormSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[1].totalCostsFormSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[2].totalCostsFormSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[3].totalCostsFormSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[4].totalCostsFormSales )}
                  </TableCell>
                  <TableCell className="bg-gray-3" />
                </TableRow>
                <TableRow>
                  <TableCell className="bg-gray-3 bg-gray-3">
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <IntlMessages id="Grossprofit" />
                    </HoverTitle>
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[0].grossProfit )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[0].totalRevenuesFromSales > 0
                      ? Util.formatNumberFixedDec(
                        ( data[0].grossProfit
                              / data[0].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[1].grossProfit )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[1].totalRevenuesFromSales > 0
                      ? Util.formatNumber(
                        ( data[1].grossProfit
                              / data[1].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[2].grossProfit )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[2].totalRevenuesFromSales > 0
                      ? Util.formatNumberFixedDec(
                        ( data[2].grossProfit
                              / data[2].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[3].grossProfit )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[3].totalRevenuesFromSales > 0
                      ? Util.formatNumberFixedDec(
                        ( data[3].grossProfit
                              / data[3].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[4].grossProfit )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[4].totalRevenuesFromSales > 0
                      ? Util.formatNumberFixedDec(
                        ( data[4].grossProfit
                              / data[4].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>
                </TableRow>
                <TableRow className="subtitle">
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <IntlMessages id="IndirectExpenses" />
                    </HoverTitle>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="GeneralAdministrative" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].generalAdministrative )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].generalAdministrative )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].generalAdministrative )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].generalAdministrative )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].generalAdministrative )}
                  </TableCell>
                  <TableCell />
                </TableRow>

                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="MarketingCosts" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].marketingCost )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].marketingCost )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].marketingCost )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].marketingCost )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].marketingCost )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="DirectSalesCosts" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].directSalesCost )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].directSalesCost )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].directSalesCost )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].directSalesCost )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].directSalesCost )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="StockHandlingCosts" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].stockHandlingCosts )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].stockHandlingCosts )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].stockHandlingCosts )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].stockHandlingCosts )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].stockHandlingCosts )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="RandD" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].rd )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].rd )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].rd )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].rd )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].rd )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="InterestsFromLoans" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].interestsFromLoans )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].interestsFromLoans )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].interestsFromLoans )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].interestsFromLoans )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].interestsFromLoans )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="TotalIndirectsExpenses" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].totalIndirectExpenses )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].totalIndirectExpenses )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].totalIndirectExpenses )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].totalIndirectExpenses )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].totalIndirectExpenses )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <IntlMessages id="ExtraordinaryIncomeOrLosses" />
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber(
                      data[0].settlementCost - data[0].liquidatedProductsCost,
                    )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber(
                      data[1].settlementCost - data[1].liquidatedProductsCost,
                    )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber(
                      data[2].settlementCost - data[2].liquidatedProductsCost,
                    )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber(
                      data[3].settlementCost - data[3].liquidatedProductsCost,
                    )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber(
                      data[4].settlementCost - data[4].liquidatedProductsCost,
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                {/*

                <TableCell  className="bg-gray-3"><IntlMessages id="TotalExpenses" /></TableCell>
                <TableCell  className="bg-gray-3">
                {Util.formatNumber( data[0].totalExpenses )}</TableCell>
                <TableCell  className="bg-gray-3">
                {Util.formatNumber( data[1].totalExpenses )}</TableCell>
                <TableCell  className="bg-gray-3">
                {Util.formatNumber( data[2].totalExpenses )}</TableCell>
                <TableCell  className="bg-gray-3">
                {Util.formatNumber( data[3].totalExpenses )}</TableCell>
                <TableCell  className="bg-gray-3">
                {Util.formatNumber( data[4].totalExpenses )}</TableCell>
              </Row>
            */}
                <TableRow>
                  <TableCell className="bg-gray-3">
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <IntlMessages id="EarningsBeforeTax" />
                    </HoverTitle>
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[0].earningBeforeTaxes )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[0].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[0].earningBeforeTaxes
                              / data[0].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[1].earningBeforeTaxes )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[1].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[1].earningBeforeTaxes
                              / data[1].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[2].earningBeforeTaxes )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[2].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[2].earningBeforeTaxes
                              / data[2].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[3].earningBeforeTaxes )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[3].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[3].earningBeforeTaxes
                              / data[3].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[4].earningBeforeTaxes )}
                  </TableCell>
                  <TableCell className="bg-gray-3" align="right">
                    {data[4].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[4].earningBeforeTaxes
                              / data[4].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <span>
                        <IntlMessages id="Incometaxes" />
                      </span>
                    </HoverTitle>
                  </TableCell>

                  <TableCell align="right">
                    {Util.formatNumber( data[0].incomeTaxes )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[1].incomeTaxes )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[2].incomeTaxes )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[3].incomeTaxes )}
                  </TableCell>
                  <TableCell />

                  <TableCell align="right">
                    {Util.formatNumber( data[4].incomeTaxes )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell className="bg-gray-3">
                    <HoverTitle
                      id="ProfitLossStatement.hover"
                      placement="right"
                    >
                      <IntlMessages id="Netearnings" />
                    </HoverTitle>
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[0].netEarnings )}
                  </TableCell>
                  <TableCell className="bg-gray-3">
                    {data[0].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[0].netEarnings
                              / data[0].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[1].netEarnings )}
                  </TableCell>
                  <TableCell className="bg-gray-3">
                    {data[1].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[1].netEarnings
                              / data[1].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[2].netEarnings )}
                  </TableCell>
                  <TableCell className="bg-gray-3">
                    {data[2].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[2].netEarnings
                              / data[2].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[3].netEarnings )}
                  </TableCell>
                  <TableCell className="bg-gray-3">
                    {data[3].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[3].netEarnings
                              / data[3].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>

                  <TableCell className="bg-gray-3" align="right">
                    {Util.formatNumber( data[4].netEarnings )}
                  </TableCell>
                  <TableCell className="bg-gray-3">
                    {data[4].totalRevenuesFromSales
                      ? Util.formatNumberFixedDec(
                        ( data[4].netEarnings
                              / data[4].totalRevenuesFromSales )
                              * 100,
                      )
                      : 0}
                      %
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardBox>
      </Grid>
    )}
  </>
);

export default CompPAndL;
