import { Typography } from '@material-ui/core';
import IconCard from 'components/custom_v2/IconCard';
import React, { useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import IntlMessages from 'utils/IntlMessages';
import NavigationOptions from 'constants/NavigationOptions';

export const SystemOptions = ( {  activeStrategy, mkmode } ) => {
    const user = useSelector( state => state.user );
  const [options, setOptions] = useState( null );
  useEffect( () => {
    if ( user && user.activeSession ) {
      setOptions(
        NavigationOptions( {
          studio: user.activeSession.distributionType === 'studio',
          now: user.activeSession.distributionType === 'now',
          activeStrategy:
            mkmode
            && mkmode.decision
            && mkmode.decision.strategy
            && activeStrategy,
        } ),
      );
    }
  }, [user, user.activeSession, activeStrategy, mkmode] );
  if ( !user || !user.activeSession || user.activeSession.noSessionType ) return <div />;
  return options ? (
    <>
      <div className="information-cont ">
        <Typography
          variant="h6"
          color="textSecondary"
          className="text-center icon-card-section-title"
        >
          <IntlMessages id="informationDashboard" />
        </Typography>
        <ul
          className={`list-icon-cards list-information ${
            user && user.activeSession && user.activeSession.isNow ? 'now' : ''
          }`}
        >
          {options.information.map( option => (
            <li key={option.title}>
              <IconCard {...option} />
            </li>
          ) )}
        </ul>
      </div>
      <div className="decision-cont xl">
        <Typography
          variant="h6"
          color="textSecondary"
          className="text-center  icon-card-section-title"
        >
          <IntlMessages id="decisionDashboard" />
        </Typography>
        <ul
          className={`list-icon-cards list-decision ${
            user && user.activeSession && user.activeSession.isNow ? 'now' : ''
          }`}
        >
          {options.desicion.map( option => (
            <li key={option.title}>
              <IconCard {...option} />
            </li>
          ) )}
        </ul>
      </div>
    </>
  ) : null;
};
