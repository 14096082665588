/* eslint-disable max-len */
import React from 'react';
import CardBox from 'components/custom_v2/CardBox';

import { connect } from 'react-redux';
import HeaderImage from 'assets/images/kotler/sectionHeaders/new-session.jpeg';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import async from 'async';
// import CategoryService from 'services/Category';
import DashboardStudentService from 'services/DashboardStudent';
import DashboardProfessorService from 'services/DashboardProfessor';
import { injectIntl } from 'react-intl';

// import SessionInfoService from 'services/SessionInfo'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartModal from 'routes/App/components/User/Chart';
// import Util from 'utils/Util';
import { Grid } from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import FeatherIcon from 'components/custom/FeatherIcon';
import KotlerTabs from 'components/custom/KotlerTabs';


class AnalitycsCharts extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      dataTabReport: [
        { id: 1, name: 'analytics.general' },
        { id: 2, name: 'analytics.distribution' },
        { id: 3, name: 'analytics.pricing' },
        { id: 4, name: 'analytics.marketing.activity' },
        { id: 5, name: 'analytics.customer.activity' },
      ],
      activeTabIndex: 0,
      chartModal: false,
      seriesStockValues1: [],

      seriesTitleLaptopMarketShareU: [],
      seriesTitleCellPhoneMarketShareU: [],
      seriesTitleTabletMarketShareU: [],
      seriesTitleLaptopMarketShareDol: [],
      seriesTitleCellPhoneMarketShareDol: [],
      seriesTitleTabletMarketShareDol: [],
      seriesTitleLaptopPercentDirectSalesByBrand: [],
      seriesTitleCellPhonePercentDirectSalesByBrand: [],
      seriesTitleTabletPercentDirectSalesByBrand: [],
      seriesTitleServicesSalesPercent: [],
      seriesTitleRecurringRevenue: [],
      seriesTitleLaptopPriceByProduct: [],
      seriesTitleCellPhonePriceByProduct: [],
      seriesTitleTabletPriceByProduct: [],
      seriesTitleLaptopPriceVsSalesByProduct: [],
      seriesTitleCellPhonePriceVsSalesByProduct: [],
      seriesTitleTabletPriceVsSalesByProduct: [],
      seriesTitleROS: [],
      seriesTitleMarketingInvestment: [],
      seriesTitleMarketingROI: [],
      seriesTitleActiveCustomers: [],
      seriesTitleActiveCustomersPercent: [],
      seriesTitleCustomerRetention: [],
      seriesTitleCustomerSatisfaction: [],
      seriesTitleCustomerNps: [],
      seriesTitleCustomerActivity: [],
      // seriesTitleCustomerRetentionAndSatisfaction: [],
      // seriesTitleCustomerAcquisitionCost: [],
      seriesTitleCustomerAcquisitionCostBAR: [],
      seriesTitleShareOfVoice: [],
      seriesTitleDirectMktInvestment: [],
      seriesTitleLaptopSOW: [],
      seriesTitleCellPhoneSOW: [],
      seriesTitleTabletSOW: [],
      seriesTitleLifetimeValue: [],
      seriesTitleClvcac: [],

    };
    this.showCellPhone = false;
    this.showTablet = false;
    this.showLaptop = false;
  }

  componentDidMount() {
    const { user } = this.props;
    this.showCellPhone = true;
    if ( user.activeSession.distributionType !== 'studio' ) {
      this.showLaptop = true;
      this.showTablet = true;
    }
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    this.loadHistoricalStockValues( user.activeSession.id );
    this.loadHistoricalEbit( user.activeSession.id );
    this.loadMarketProducts( user.activeSession.id );
    this.loadHistoricalBundleSales( user.activeSession.id );
    this.loadPricesProducts( user.activeSession.id );
    // await this.loadHistoricalBundle( user.activeSession.id, swActive );
    // await this.loadHistoricalProducts( user.activeSession.id, swActive );
  }

  componentDidUpdate( prevProps, prevState ) {
    const { activeTabIndex } = this.state;
    const { user, locale } = this.props;
    if ( activeTabIndex !== prevState.activeTabIndex || locale !== prevProps.locale ) {
      am4core.disposeAllCharts();
      this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
      if ( activeTabIndex === 0 ) {
        this.loadHistoricalStockValues( user.activeSession.id );
        this.loadHistoricalEbit( user.activeSession.id );
        this.loadHistoricalBundleSales( user.activeSession.id );
        this.loadMarketProducts( user.activeSession.id );
      }
      if ( activeTabIndex === 1 ) {
        this.loadMarketProducts( user.activeSession.id );
      }
      if ( activeTabIndex === 2 ) {
        this.loadPricesProducts( user.activeSession.id );
      }

      if ( activeTabIndex === 3 ) {
        this.loadActivityEffectiveness( user.activeSession.id );
        this.loadShareOfVoice( user.activeSession.id );
        this.loadShareOfVoiceMarket( user.activeSession.id );
        this.loadDirectMarketingInvestment( user.activeSession.id );
      }
      if ( activeTabIndex === 4 ) {
        this.loadCustomerActivity( user.activeSession.id );
        // this.loadCustomerRetentionSatisfaction( user.activeSession.id );
        this.loadLifetimeValue( user.activeSession.id );
        this.loadCustomerAcquisitionCost( user.activeSession.id );
      }

      // this.loadHistoricalBundle( user.activeSession.id, swActive );
      // this.loadHistoricalProducts( user.activeSession.id, swActive );
    }
  }


  componentWillUnmount() {
    am4core.disposeAllCharts();
  }

  getDataSerie=( categories, roundsCat = [], seriesTitle, param ) => {
    _.map( categories, ( item ) => {
      _.map( item.historical, ( round ) => {
        const exist = _.findIndex( roundsCat, { id: round.round.id } );
        if ( exist === -1 ) {
          const dataToPush = { attribute: round.round.shortName, id: round.round.id };
          dataToPush[item.id] = round[param];
          roundsCat.push( dataToPush );
        } else {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][item.id] = round[param];
        }
      } );
      seriesTitle.push( item.name );
    } );
  };

  getDataSerieCLVCAC=( categories, roundsCat = [], seriesTitle, param, param2 ) => {
    _.map( categories, ( item ) => {
      const varArr = _.last( item.historical );
      const dataToPush = { attribute: item.name, id: item.id };
      dataToPush[param] = varArr[param];
      dataToPush[param2] = varArr[param2];
      roundsCat.push( dataToPush );
    } );
    seriesTitle.push( param );
    seriesTitle.push( param2 );
  };
  createCharSerie=( key, roundsCat, categories, param, param1, isPorcent, xAxesTitle, yAxesTitle ) => {
    let config = {};
    const { intl } = this.props;
    if ( isPorcent ) {
      const roundsCatTraduce = roundsCat.map( item => ( {
        ...item,
        attribute: intl.formatMessage( { id: item.attribute.split( ' ' )[0] }, { value: item.attribute.split( ' ' )[1] } ),
      } ) );
      config = {
        data: roundsCatTraduce,
        type: am4charts.XYChart,
        xAxes: [{
          type: 'CategoryAxis',
          dataFields: { category: 'attribute' },
          title: { text: xAxesTitle ? intl.formatMessage( { id: xAxesTitle } ) : '' },
        }],
        yAxes: [{
          type: 'ValueAxis',
          min: 0,
          max: 100,
          title: { text: yAxesTitle ? intl.formatMessage( { id: yAxesTitle } ) : '' },
        }],
        series: _.map( categories, itemX => ( {
          type: 'LineSeries',
          dataFields: {
            valueY: itemX.id,
            categoryX: 'attribute',
          },
          name: itemX.name,
          strokeWidth: 2,
        } ) ),
      };
    } else {
      const roundsCatTraduce = roundsCat.map( item => ( {
        ...item,
        attribute: intl.formatMessage( { id: item.attribute.split( ' ' )[0] }, { value: item.attribute.split( ' ' )[1] } ),
      } ) );
      // eslint-disable-next-line no-const-assign
      config = {
        data: roundsCatTraduce,
        type: am4charts.XYChart,
        xAxes: [{
          type: 'CategoryAxis',
          dataFields: { category: 'attribute' },
          title: { text: xAxesTitle ? intl.formatMessage( { id: xAxesTitle } ) : '' },
        }],
        yAxes: [{
          type: 'ValueAxis',
          title: { text: yAxesTitle ? intl.formatMessage( { id: yAxesTitle } ) : '' },
        }],
        series: _.map( categories, itemX => ( {
          type: 'LineSeries',
          dataFields: {
            valueY: itemX.id,
            categoryX: 'attribute',
          },
          name: itemX.name,
          strokeWidth: 2,
        } ) ),
      };
    }
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[param],
      lastsUnitSales: item[param1],
    } ) );
    return { config, chart, table };
  };
  createCharSerieBar=( key, roundsCat, categories, param, param1, xAxesTitle, yAxesTitle ) => {
    const { intl } = this.props;
    const serie1 = {
      type: 'ColumnSeries',
      dataFields: {
        valueY: param,
        categoryX: 'attribute',
      },
      name: param,
      strokeWidth: 2,
    };
    const serie2 = {
      type: 'ColumnSeries',
      dataFields: {
        valueY: param1,
        categoryX: 'attribute',
      },
      name: param1,
      strokeWidth: 2,
    };
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
        title: { text: xAxesTitle ? intl.formatMessage( { id: xAxesTitle } ) : '' },
      }],
      yAxes: [{
        min: 0,
        type: 'ValueAxis',
        title: { text: yAxesTitle ? intl.formatMessage( { id: yAxesTitle } ) : '' },
      }],
      series: _.concat( serie1, serie2 ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[param],
      lastsUnitSales: item[param1],
    } ) );
    return { config, chart, table };
  };
  getDataSerieProducts=( categories, roundsCat = [], seriesTitle ) => {
    _.map( categories, ( item ) => {
      _.map( item.historical, ( round ) => {
        _.map( round.products, ( prod ) => {
          const exist = _.findIndex( roundsCat, { id: round.round.id } );
          if ( exist === -1 ) {
            const dataToPush = { attribute: round.round.shortName, id: round.round.id };
            dataToPush[prod.id] = prod.price;
            roundsCat.push( dataToPush );
          } else {
            // eslint-disable-next-line no-param-reassign
            roundsCat[exist][prod.id] = prod.price;
          }
          const exist2 = _.indexOf( seriesTitle, prod.name );
          if ( exist2 === -1 ) {
            seriesTitle.push( prod.name );
          }
        } );
      } );
    } );
  };

  createCharSerieProducts=( key, roundsCat, categories, param, param1, xAxesTitle, yAxesTitle ) => {
    const { intl } = this.props;
    const rawSerie = [];
    // eslint-disable-next-line max-len
    _.flatMap( categories, cat => ( _.flatMap( cat.historical, hist => ( _.map( hist.products, ( itemX ) => {
      const exist = _.findIndex( rawSerie, { id: itemX.id } );
      if ( exist === -1 ) {
        const dataToPush = { name: itemX.name, id: itemX.id };
        rawSerie.push( dataToPush );
      }
    } )
    ) )
    ) );
    const roundsCatTraduce = roundsCat.map( item => ( {
      ...item,
      attribute: intl.formatMessage( { id: item.attribute.split( ' ' )[0] }, { value: item.attribute.split( ' ' )[1] } ),
    } ) );
    const config = {
      data: roundsCatTraduce,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
        title: { text: xAxesTitle ? intl.formatMessage( { id: xAxesTitle } ) : '' },
      }],
      yAxes: [{
        type: 'ValueAxis',
        title: { text: yAxesTitle ? intl.formatMessage( { id: yAxesTitle } ) : '' },
      }],
      series: _.map( rawSerie, itemX => ( {
        type: 'LineSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[param],
      lastsUnitSales: item[param1],
    } ) );
    return { config, chart, table };
  };

  getDataSerieBubbleCustomer=( categories, roundsCat = [], seriesTitle, xField, yField, xField2 = '' ) => {
    _.map( categories, ( round ) => {
      const exist = _.findIndex( roundsCat, { id: round.id } );
      if ( exist === -1 ) {
        // eslint-disable-next-line max-len
        const dataToPush = { attribute: round.name, id: round.id, xx: round[xField], yy: round[yField] };
        dataToPush[`${round.id}yy`] = round[yField];
        if ( xField2 !== '' ) {
          dataToPush[`${round.id}xx`] = round[xField] + round[xField2];
        } else {
          dataToPush[`${round.id}xx`] = round[xField];
        }

        dataToPush[`${round.id}value`] = 20;
        roundsCat.push( dataToPush );
      } else {
        // eslint-disable-next-line no-param-reassign
        roundsCat[exist][`${round.id}yy`] = round[yField];
        if ( xField2 !== '' ) {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][`${round.id}xx`] = round[xField] + round[xField2];
        } else {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][`${round.id}xx`] = round[xField];
        }
        // eslint-disable-next-line no-param-reassign
        roundsCat[exist][`${round.id}value`] = 20;
      }
      seriesTitle.push( round.name );
    } );
  };

  createCharSerieBubbleCustomer=( key, roundsCat, categories, xField, yField, xTitle_ = '', xAxesTitle, yAxesTitle ) => {
    const xTitle = xTitle_ === '' ? xField : xTitle_;
    const { intl } = this.props;
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'ValueAxis',
        title: { text: xAxesTitle ? intl.formatMessage( { id: xAxesTitle } ) : '' },
      }],
      yAxes: [{
        id: 'v1',
        type: 'ValueAxis',
        title: { text: yAxesTitle ? intl.formatMessage( { id: yAxesTitle } ) : '' },
      }],
      series: _.map( categories, itemX => ( {
        type: 'LineSeries',
        sequencedInterpolation: true,
        dataFields: {
          valueY: `${itemX.id}yy`,
          valueX: `${itemX.id}xx`,

          value: `${itemX.id}value`,
        },
        heatRules: [
          {
            target: 'bubbleBullet',
            dataFields: { radius: `${itemX.id}value` },
            min: 10,
            max: 40,
            property: 'radius',
          },
        ],
        bullets: [
          {
            type: 'CircleBullet',
            stroke: '#ffffff',
            strokeOpacity: 1,
            fillOpacity: 0.7,
            propertyFields: { fill: 'color', showTooltipOn: 'always' },
            states: {
              hover: {
                properties: {
                  scale: 1.7,
                  fillOpacity: 1,
                  strokeOpacity: 1,
                },
              },
            },
            adapter: {
              tooltipY: ( _tooltipY, target ) => -target.radius,
              // eslint-disable-next-line no-unused-vars
              tooltipText: _val => `[bold]{attribute}:[/]\n${xTitle}: {valueX}\n${yField}: {valueY}`
              ,
            },
          },
        ],
        name: itemX.name,
        strokeWidth: 0,
      } ) ),
    };

    const configFixedTooltip = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{ type: 'ValueAxis' }],
      yAxes: [{
        id: 'v1',
        type: 'ValueAxis',
      }],
      series: _.map( categories, itemX => ( {
        type: 'LineSeries',
        sequencedInterpolation: true,
        dataFields: {
          valueY: `${itemX.id}yy`,
          valueX: `${itemX.id}xx`,

          value: `${itemX.id}value`,
        },
        heatRules: [
          {
            target: 'bubbleBullet',
            dataFields: { radius: `${itemX.id}value` },
            min: 10,
            max: 40,
            property: 'radius',
          },
        ],
        bullets: [
          {
            type: 'CircleBullet',
            stroke: '#ffffff',
            strokeOpacity: 1,
            fillOpacity: 0.7,
            showTooltipOn: 'always',
            propertyFields: { fill: 'color', showTooltipOn: 'showTooltip' },
            states: {
              hover: {
                properties: {
                  scale: 1.7,
                  fillOpacity: 1,
                  strokeOpacity: 1,
                },
              },
            },
            adapter: {
              tooltipY: ( _tooltipY, target ) => -target.radius,
              // eslint-disable-next-line no-unused-vars
              tooltipText: _val => `[bold]{attribute}:[/]\n${xTitle}: {valueX}\n${yField}: {valueY}`
              ,
            },
          },
        ],
        name: itemX.name,
        strokeWidth: 0,
      } ) ),
    };

    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[xField],
      lastsUnitSales: item[yField],
    } ) );
    return { config, chart, table, configFixedTooltip };
  };

  getDataSerieBubbleCustomerBAR=( categories, roundsCat = [], seriesTitle, xField, yField, xField2 = '' ) => {
    _.map( categories, ( round ) => {
      const exist = _.findIndex( roundsCat, { id: round.id } );
      if ( exist === -1 ) {
        // eslint-disable-next-line max-len
        const dataToPush = { attribute: round.name, id: round.id, xx: round[xField], yy: round[yField] };
        dataToPush[`${round.id}yy`] = round[yField];
        if ( xField2 !== '' ) {
          dataToPush[`${round.id}xx`] = round[yField] !== 0.0 ? ( ( round[xField] + round[xField2] ) ) / round[yField] : 0;
        } else {
          dataToPush[`${round.id}xx`] = round[xField];
        }

        dataToPush[`${round.id}value`] = 20;
        roundsCat.push( dataToPush );
      } else {
        // eslint-disable-next-line no-param-reassign
        roundsCat[exist][`${round.id}yy`] = round[yField];
        if ( xField2 !== '' ) {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][`${round.id}xx`] = round[yField] !== 0.0 ? ( ( round[xField] + round[xField2] ) / round[yField] ) : 0;
        } else {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][`${round.id}xx`] = round[xField];
        }
        // eslint-disable-next-line no-param-reassign
        roundsCat[exist][`${round.id}value`] = 20;
      }
      seriesTitle.push( 'CAC' );
    } );
  };
  createCharSerieBubbleCustomerBAR=( key, roundsCat, categories, xField, yField, xAxesTitle, yAxesTitle ) => {
    const { intl } = this.props;
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
        title: { text: xAxesTitle ? intl.formatMessage( { id: xAxesTitle } ) : '' },
      }],
      yAxes: [{
        type: 'ValueAxis',
        min: 0,
        title: { text: yAxesTitle ? intl.formatMessage( { id: yAxesTitle } ) : '' },
      }],
      series: _.map( categories, itemX => ( {
        type: 'ColumnSeries',
        clustered: false,
        dataFields: {
          valueY: `${itemX.id}xx`,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };

    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[xField],
      lastsUnitSales: item[yField],
    } ) );
    return { config, chart, table };
  };


  getDataSerieBubble=( categories, roundsCat = [], seriesTitle ) => {
    _.map( categories, ( item ) => {
      _.map( item.products, ( round ) => {
        const exist = _.findIndex( roundsCat, { id: round.id } );
        if ( exist === -1 ) {
          // eslint-disable-next-line max-len
          const dataToPush = { attribute: round.name, id: round.id, xx: round.price, yy: round.sales };
          dataToPush[`${round.id}yy`] = round.sales; // como es el
          dataToPush[`${round.id}xx`] = round.price; // como es el
          dataToPush[`${round.id}value`] = 50;
          roundsCat.push( dataToPush );
        } else {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][`${round.id}yy`] = round.sales;
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][`${round.id}xx`] = round.prices;
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][`${round.id}value`] = 20;
        }
      } );
      seriesTitle.push( item.name );
    } );
  };

  createCharSerieBubble=( key, roundsCat, categories, param, param1 ) => {
    const { intl } = this.props;
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'ValueAxis',
        title: { text: intl.formatMessage( { id: 'price' } ) },
      }],
      yAxes: [{
        id: 'v1',
        type: 'ValueAxis',
        title: { text: intl.formatMessage( { id: 'sales' } ) },
      }],
      series: _.flatMap( categories, cat => ( _.map( cat.products, itemX => ( {
        type: 'LineSeries',
        sequencedInterpolation: true,
        dataFields: {
          valueY: `${itemX.id}yy`,
          valueX: `${itemX.id}xx`,
          value: `${itemX.id}value`,
          showTooltip: 'always',
        },
        heatRules: [
          {
            target: 'bubbleBullet',
            dataFields: { radius: `${itemX.id}value`, showTooltip: 'always' },
            min: 20,
            max: 100,
            property: 'radius',
          },
        ],
        tooltips: {
          label: { textAlign: 'middle' },
          pointerOrientation: 'down',
          dy: -5,
          propertyFields: { pointerOrientation: 'orientation', dy: 'offset' },
        },
        bullets: [
          {
            type: 'CircleBullet',
            stroke: '#ffffff',
            strokeOpacity: 1,
            fillOpacity: 0.7,
            // alwaysShowTooltip: true,
            propertyFields: { fill: 'color', showTooltipOn: 'showTooltip' },
            label: { text: '{valueX}', dy: -20 },
            // showTooltipOn: 'always',
            states: {
              hover: {
                properties: {
                  scale: 1.7,
                  fillOpacity: 1,
                  strokeOpacity: 1,
                },
              },
            },
            adapter: {
              // showTooltipOn: ( val_, val2 ) => { console.log(val_, val2); return 'always'; },
              tooltipY: ( _tooltipY, target ) => -target.radius,
              // eslint-disable-next-line no-unused-vars
              tooltipText: _val => '[bold]{attribute}:[/]\nPrice: {valueX}\nSales: {valueY}',
            },
          },
        ],
        name: itemX.name,
        strokeWidth: 0,
      } ) )
      ) ),
    };

    const configFixedTooltip = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'ValueAxis',
        title: { text: intl.formatMessage( { id: 'price' } ) },
      }],
      yAxes: [{
        id: 'v1',
        type: 'ValueAxis',
        title: { text: intl.formatMessage( { id: 'sales' } ) },
      }],
      series: _.flatMap( categories, cat => ( _.map( cat.products, itemX => ( {
        type: 'LineSeries',
        sequencedInterpolation: true,
        dataFields: {
          valueY: `${itemX.id}yy`,
          valueX: `${itemX.id}xx`,
          value: `${itemX.id}value`,
          showTooltip: 'always',
        },
        heatRules: [
          {
            target: 'bubbleBullet',
            dataFields: { radius: `${itemX.id}value`, showTooltip: 'always' },
            min: 20,
            max: 100,
            property: 'radius',
          },
        ],
        tooltips: {
          label: { textAlign: 'middle' },
          pointerOrientation: 'down',
          dy: -5,
          propertyFields: { pointerOrientation: 'orientation', dy: 'offset' },
        },
        bullets: [
          {
            type: 'CircleBullet',
            stroke: '#ffffff',
            strokeOpacity: 1,
            fillOpacity: 0.7,
            // alwaysShowTooltip: true,
            showTooltipOn: 'always',
            propertyFields: { fill: 'color', showTooltipOn: 'showTooltip' },
            label: { text: '{valueX}', dy: -20 },
            states: {
              hover: {
                properties: {
                  scale: 1.7,
                  fillOpacity: 1,
                  strokeOpacity: 1,
                },
              },
            },
            adapter: {
              // showTooltipOn: ( val_, val2 ) => { console.log(val_, val2); return 'always'; },
              tooltipY: ( _tooltipY, target ) => -target.radius,
              // eslint-disable-next-line no-unused-vars
              tooltipText: _val => '[bold]{attribute}:[/]\nPrice: {valueX}\nSales: {valueY}',
            },
          },
        ],
        name: itemX.name,
        strokeWidth: 0,
      } ) )
      ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[param],
      lastsUnitSales: item[param1],
    } ) );
    return { config, chart, table, configFixedTooltip };
  };

  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  toggleTab2 = ( _event, tab ) => {
    this.setState( { activeTabIndex: tab } );
  };


  loadPricesProducts = ( userSessionID ) => {
    const { toggleLoading } = this.props;
    const { activeTabIndex } = this.state;
    const { showCellPhone, showLaptop, showTablet } = this;
    toggleLoading( true );

    async.waterfall( [
      ( cb ) => {
        DashboardProfessorService.getCategories( { 'filters[session]': userSessionID } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      ( category, cb ) => {
        async.parallel( [
          ( cb1 ) => {
            DashboardProfessorService.getPriceProductAllWorkGroup(
              userSessionID, { category: category[0].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getPriceProductAllWorkGroup(
              userSessionID, { category: category[1].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getPriceProductAllWorkGroup(
              userSessionID, { category: category[2].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getPriceSalesProductAllWorkGroup(
              userSessionID, { category: category[0].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getPriceSalesProductAllWorkGroup(
              userSessionID, { category: category[1].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getPriceSalesProductAllWorkGroup(
              userSessionID, { category: category[2].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response );
            } );
          },
        ], ( error, results ) => {
          cb( null, results );
        } );
      },
    ], ( error, results ) => {
      toggleLoading( false );
      if ( !error ) {
        const allData = _.cloneDeep( results[0] ? results[0].data : [] );
        const allData1 = _.cloneDeep( results[1] ? results[1].data : [] );
        const allData2 = _.cloneDeep( results[2] ? results[2].data : [] );
        const allData3 = _.cloneDeep( results[3] ? results[3].data : [] );
        const allData4 = _.cloneDeep( results[4] ? results[4].data : [] );
        const allData5 = _.cloneDeep( results[5] ? results[5].data : [] );

        const seriesTitleLaptopPriceByProduct = [];
        const seriesTitleTabletPriceByProduct = [];
        const seriesTitleCellPhonePriceByProduct = [];
        const seriesTitleLaptopPriceVsSalesByProduct = [];
        const seriesTitleTabletPriceVsSalesByProduct = [];
        const seriesTitleCellPhonePriceVsSalesByProduct = [];
        if ( activeTabIndex === 2 ) {
          if ( showLaptop ) {
            const roundsLaptopPriceByProduct = [];
            // eslint-disable-next-line max-len
            this.getDataSerieProducts( allData, roundsLaptopPriceByProduct, seriesTitleLaptopPriceByProduct );
            const resultPriceByProduct = this.createCharSerieProducts( 'laptopPriceByProductDiv', roundsLaptopPriceByProduct, allData, 'unitSales', 'lastsUnitSales', 'Rounds', 'Price' );
            this.configLaptopPriceByProduct = _.cloneDeep( resultPriceByProduct.config );
            this.chartLaptopPriceByProduct = resultPriceByProduct.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showTablet ) {
            const roundsTabletPriceByProduct = [];
            // eslint-disable-next-line max-len
            this.getDataSerieProducts( allData1, roundsTabletPriceByProduct, seriesTitleTabletPriceByProduct );
            const resultTabletPriceByProduct = this.createCharSerieProducts( 'tabletPriceByProductDiv', roundsTabletPriceByProduct, allData1, 'unitSales', 'lastsUnitSales', 'Rounds', 'Price' );
            this.configTabletPriceByProduct = _.cloneDeep( resultTabletPriceByProduct.config );
            this.chartTabletPriceByProduct = resultTabletPriceByProduct.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showCellPhone ) {
            const roundsCellPhonePriceByProduct = [];
            // eslint-disable-next-line max-len
            this.getDataSerieProducts( allData2, roundsCellPhonePriceByProduct, seriesTitleCellPhonePriceByProduct );
            const resultCellPhonePriceByProduct = this.createCharSerieProducts( 'cellPhonePriceByProductDiv', roundsCellPhonePriceByProduct, allData2, 'unitSales', 'lastsUnitSales', 'Rounds', 'Price' );
            this.configCellPhonePriceByProduct = _.cloneDeep( resultCellPhonePriceByProduct.config );
            this.chartCellPhonePriceByProduct = resultCellPhonePriceByProduct.chart;
            // const tableLaptopMarketShare = result1.table;
          }


          if ( showLaptop ) {
            const roundsLaptopPriceVsSalesByProduct = [];
            // eslint-disable-next-line max-len
            this.getDataSerieBubble( allData3, roundsLaptopPriceVsSalesByProduct, seriesTitleLaptopPriceVsSalesByProduct );
            const resultLaptoPriceVsSalesByProduct = this.createCharSerieBubble( 'laptopPriceVsSalesByProductDiv', roundsLaptopPriceVsSalesByProduct, allData3, 'revenues', 'lastsUnitSales' );
            // eslint-disable-next-line max-len
            this.configLaptopPriceVsSalesByProduct = _.cloneDeep( resultLaptoPriceVsSalesByProduct.configFixedTooltip );
            this.chartLaptopPriceVsSalesByProduct = resultLaptoPriceVsSalesByProduct.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showTablet ) {
            const roundsTabletPriceVsSalesByProduct = [];
            // const allData4 = _.cloneDeep( results[1].data );
            // eslint-disable-next-line max-len
            this.getDataSerieBubble( allData4, roundsTabletPriceVsSalesByProduct, seriesTitleTabletPriceVsSalesByProduct );
            const resultTabletPriceVsSalesByProduct = this.createCharSerieBubble( 'tabletPriceVsSalesByProductDiv', roundsTabletPriceVsSalesByProduct, allData4, 'revenues', 'lastsUnitSales' );

            // eslint-disable-next-line max-len
            this.configTabletPriceVsSalesByProduct = _.cloneDeep( resultTabletPriceVsSalesByProduct.configFixedTooltip );
            this.chartTabletPriceVsSalesByProduct = resultTabletPriceVsSalesByProduct.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showCellPhone ) {
            const roundsCellPhonePriceVsSalesByProduct = [];
            // const allData5 = _.cloneDeep( results[2].data );
            // eslint-disable-next-line max-len
            this.getDataSerieBubble( allData5, roundsCellPhonePriceVsSalesByProduct, seriesTitleCellPhonePriceVsSalesByProduct );
            const resultCellPhonePriceVsSalesByProduct = this.createCharSerieBubble( 'cellPhonePriceVsSalesByProductDiv', roundsCellPhonePriceVsSalesByProduct, allData5, 'revenues', 'lastsUnitSales' );
            // eslint-disable-next-line max-len
            this.configCellPhonePriceVsSalesByProduct = _.cloneDeep( resultCellPhonePriceVsSalesByProduct.configFixedTooltip );
            this.chartCellPhonePriceVsSalesByProduct = resultCellPhonePriceVsSalesByProduct.chart;
            // const tableLaptopMarketShare = result1.table;
          }
          this.setState( {
            seriesTitleLaptopPriceByProduct,
            seriesTitleTabletPriceByProduct,
            seriesTitleCellPhonePriceByProduct,
            seriesTitleLaptopPriceVsSalesByProduct: null,
            seriesTitleTabletPriceVsSalesByProduct: null,
            seriesTitleCellPhonePriceVsSalesByProduct: null,
          } );
        }
      }
    } );
  };

  loadMarketProducts = ( userSessionID ) => {
    const { toggleLoading } = this.props;
    const { activeTabIndex } = this.state;
    const { showCellPhone, showLaptop, showTablet } = this;
    toggleLoading( true );

    async.waterfall( [
      ( cb ) => {
        DashboardProfessorService.getCategories( { 'filters[session]': userSessionID } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      ( category, cb ) => {
        async.parallel( [
          ( cb1 ) => {
            DashboardProfessorService.getMarketProductsAllWorkGroup(
              userSessionID, { category: category[0].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getMarketProductsAllWorkGroup(
              userSessionID, { category: category[1].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getMarketProductsAllWorkGroup(
              userSessionID, { category: category[2].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          }], ( error, results ) => {
          cb( null, results );
        } );
      },
    ], ( error, results ) => {
      toggleLoading( false );
      if ( !error ) {
        const allData = _.cloneDeep( results[0] ? results[0].data : [] );
        const allData1 = _.cloneDeep( results[1] ? results[1].data : [] );
        const allData2 = _.cloneDeep( results[2] ? results[2].data : [] );

        const seriesTitleLaptopMarketShareU = [];
        const seriesTitleTabletMarketShareU = [];
        const seriesTitleCellPhoneMarketShareU = [];
        const seriesTitleLaptopMarketShareDol = [];
        const seriesTitleTabletMarketShareDol = [];
        const seriesTitleCellPhoneMarketShareDol = [];
        if ( activeTabIndex === 0 ) {
          if ( showLaptop ) {
            const roundsLaptopMarketShare = [];
            this.getDataSerie( allData, roundsLaptopMarketShare, seriesTitleLaptopMarketShareU, 'unitSales' );
            const result1 = this.createCharSerie( 'laptopMarketShareUDiv', roundsLaptopMarketShare, allData, 'unitSales', 'lastsUnitSales', false, 'Rounds', 'Units' );
            this.configLaptopMarketShare = _.cloneDeep( result1.config );
            this.chartLaptopMarketShare = result1.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showTablet ) {
            const roundsTabletMarketShare = [];
            this.getDataSerie( allData1, roundsTabletMarketShare, seriesTitleTabletMarketShareU, 'unitSales' );
            const resultTablet = this.createCharSerie( 'tabletMarketShareUDiv', roundsTabletMarketShare, allData1, 'unitSales', 'lastsUnitSales', false, 'Rounds', 'Units' );
            this.configTabletMarketShare = _.cloneDeep( resultTablet.config );
            this.chartTabletMarketShare = resultTablet.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showCellPhone ) {
            const roundsCellPhoneMarketShare = [];
            this.getDataSerie( allData2, roundsCellPhoneMarketShare, seriesTitleCellPhoneMarketShareU, 'unitSales' );
            const resultCellPhone = this.createCharSerie( 'cellPhoneMarketShareUDiv', roundsCellPhoneMarketShare, allData2, 'unitSales', 'lastsUnitSales', false, 'Rounds', 'Units' );
            this.configCellPhoneMarketShare = _.cloneDeep( resultCellPhone.config );
            this.chartCellPhoneMarketShare = resultCellPhone.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showLaptop ) {
            const roundsLaptopMarketShareDol = [];
            this.getDataSerie( allData, roundsLaptopMarketShareDol, seriesTitleLaptopMarketShareDol, 'revenues' );
            const resultLaptoDol = this.createCharSerie( 'laptopMarketShareDolDiv', roundsLaptopMarketShareDol, allData, 'revenues', 'lastsUnitSales', false, 'Rounds', 'value' );
            this.configLaptopMarketShareDol = _.cloneDeep( resultLaptoDol.config );
            this.chartLaptopMarketShare = resultLaptoDol.chart;
            // const tableLaptopMarketShare = result1.table;
          }
          // const allData4 = _.cloneDeep( results[1].data );

          if ( showTablet ) {
            const roundsTabletMarketShareDol = [];
            this.getDataSerie( allData1, roundsTabletMarketShareDol, seriesTitleTabletMarketShareDol, 'revenues' );
            const resultTabletDol = this.createCharSerie( 'tabletMarketShareDolDiv', roundsTabletMarketShareDol, allData1, 'revenues', 'lastsUnitSales', false, 'Rounds', 'value' );
            this.configTabletMarketShareDol = _.cloneDeep( resultTabletDol.config );
            this.chartTabletMarketShareDol = resultTabletDol.chart;
            // const tableLaptopMarketShare = result1.table;
          }
          // const allData5 = _.cloneDeep( results[2].data );


          if ( showCellPhone ) {
            const roundsCellPhoneMarketShareDol = [];
            this.getDataSerie( allData2, roundsCellPhoneMarketShareDol, seriesTitleCellPhoneMarketShareDol, 'revenues' );
            const resultCellPhoneDol = this.createCharSerie( 'cellPhoneMarketShareDolDiv', roundsCellPhoneMarketShareDol, allData2, 'revenues', 'lastsUnitSales', false, 'Rounds', 'value' );
            this.configCellPhoneMarketShareDol = _.cloneDeep( resultCellPhoneDol.config );
            this.chartCellPhoneMarketShareDol = resultCellPhoneDol.chart;
            // const tableLaptopMarketShare = result1.table;
          }
        }

        const seriesTitleCellPhonePercentDirectSalesByBrand = [];
        const seriesTitleTabletPercentDirectSalesByBrand = [];
        const seriesTitleLaptopPercentDirectSalesByBrand = [];
        if ( activeTabIndex === 1 ) {
          if ( showLaptop ) {
            const roundsLaptopPercentDirectSalesByBrand = [];
            this.getDataSerie( allData, roundsLaptopPercentDirectSalesByBrand, seriesTitleLaptopPercentDirectSalesByBrand, 'direcSalesPercentage' );
            const resultLaptoPercentDirectSalesByBrand = this.createCharSerie( 'laptopPercentDirectSalesByBrandDiv', roundsLaptopPercentDirectSalesByBrand, allData, 'direcSalesPercentage', 'lastsUnitSales', true, 'Rounds', 'Percent' );
            // eslint-disable-next-line max-len
            this.configLaptopPercentDirectSalesByBrand = _.cloneDeep( resultLaptoPercentDirectSalesByBrand.config );
            this.chartLaptopPercentDirectSalesByBrand = resultLaptoPercentDirectSalesByBrand.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showTablet ) {
            const roundsTabletPercentDirectSalesByBrand = [];
            // const allData4 = _.cloneDeep( results[1].data );
            this.getDataSerie( allData1, roundsTabletPercentDirectSalesByBrand, seriesTitleTabletPercentDirectSalesByBrand, 'direcSalesPercentage' );
            const resultTabletPercentDirectSalesByBrand = this.createCharSerie( 'tabletPercentDirectSalesByBrandDiv', roundsTabletPercentDirectSalesByBrand, allData1, 'direcSalesPercentage', 'lastsUnitSales', true, 'Rounds', 'Percent' );
            // eslint-disable-next-line max-len
            this.configTabletPercentDirectSalesByBrand = _.cloneDeep( resultTabletPercentDirectSalesByBrand.config );
            this.chartTabletPercentDirectSalesByBrand = resultTabletPercentDirectSalesByBrand.chart;
            // const tableLaptopMarketShare = result1.table;
          }

          if ( showCellPhone ) {
            const roundsCellPhonePercentDirectSalesByBrand = [];
            // const allData5 = _.cloneDeep( results[2].data );
            // console.log(allData2,"ALLDAT2")
            this.getDataSerie( allData2, roundsCellPhonePercentDirectSalesByBrand, seriesTitleCellPhonePercentDirectSalesByBrand, 'direcSalesPercentage' );
            const resultCellPhonePercentDirectSalesByBrand = this.createCharSerie( 'cellPhonePercentDirectSalesByBrandDiv', roundsCellPhonePercentDirectSalesByBrand, allData2, 'direcSalesPercentage', 'lastsUnitSales', true, 'Rounds', 'Percent' );
            // eslint-disable-next-line max-len
            this.configCellPhonePercentDirectSalesByBrand = _.cloneDeep( resultCellPhonePercentDirectSalesByBrand.config );
            // eslint-disable-next-line max-len
            this.chartCellPhonePercentDirectSalesByBrand = resultCellPhonePercentDirectSalesByBrand.chart;
            // const tableLaptopMarketShare = result1.table;
          }
        }
        this.setState( {
          seriesTitleLaptopMarketShareU,
          seriesTitleTabletMarketShareU,
          seriesTitleCellPhoneMarketShareU,
          seriesTitleLaptopMarketShareDol,
          seriesTitleTabletMarketShareDol,
          seriesTitleCellPhoneMarketShareDol,
          seriesTitleLaptopPercentDirectSalesByBrand,
          seriesTitleTabletPercentDirectSalesByBrand,
          seriesTitleCellPhonePercentDirectSalesByBrand,
        } );
      }
    } );
  };

  loadShareOfVoiceMarket = ( userSessionID ) => {
    const { toggleLoading } = this.props;
    const { showCellPhone, showLaptop, showTablet } = this;
    // const { activeTabIndex } = this.state;
    toggleLoading( true );

    async.waterfall( [
      ( cb ) => {
        DashboardProfessorService.getCategories( { 'filters[session]': userSessionID } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      ( category, cb ) => {
        async.parallel( [
          ( cb1 ) => {
            DashboardProfessorService.getShareOfVoiceMarketAllWorkGroup(
              userSessionID, { category: category[0].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getShareOfVoiceMarketAllWorkGroup(
              userSessionID, { category: category[1].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          },
          ( cb1 ) => {
            DashboardProfessorService.getShareOfVoiceMarketAllWorkGroup(
              userSessionID, { category: category[2].id },
            ).then( ( response ) => {
              if ( !response.ok ) return cb1( response.errors );
              cb1( null, response.data );
            } );
          }], ( error, results ) => {
          cb( null, results );
        } );
      },
    ], ( error, results ) => {
      toggleLoading( false );
      if ( error === null ) {
        const allData = _.cloneDeep( results[0] ? results[0] : [] );
        const allData1 = _.cloneDeep( results[1] ? results[1] : [] );
        const allData2 = _.cloneDeep( results[2] ? results[2] : [] );
        if ( showLaptop ) {
          const roundsLaptopSOW = [];
          const seriesTitleLaptopSOW = [];
          this.getDataSerieBubbleCustomer( allData, roundsLaptopSOW, seriesTitleLaptopSOW, 'shareOfMarket', 'shareOfVoice', '' );
          const resultLaptopSOW = this.createCharSerieBubbleCustomer( 'laptopSOWDiv', roundsLaptopSOW, allData, 'shareOfMarket', 'shareOfVoice', '', 'SOM', 'SOV' );
          this.configLaptopSOW = _.cloneDeep( resultLaptopSOW.configFixedTooltip );
          this.chartLaptopSOW = resultLaptopSOW.chart;
          this.setState( { seriesTitleLaptopSOW: null } );
        }
        if ( showTablet ) {
          const roundsTabletSOW = [];
          const seriesTitleTabletSOW = [];
          this.getDataSerieBubbleCustomer( allData1, roundsTabletSOW, seriesTitleTabletSOW, 'shareOfMarket', 'shareOfVoice', '' );
          const resultTabletSOW = this.createCharSerieBubbleCustomer( 'tabletSOWDiv', roundsTabletSOW, allData1, 'shareOfMarket', 'shareOfVoice', '', 'SOM', 'SOV' );
          this.configTabletSOW = _.cloneDeep( resultTabletSOW.configFixedTooltip );
          this.chartTabletSOW = resultTabletSOW.chart;
          // const tableLaptopSOW = result1.table;
          this.setState( { seriesTitleTabletSOW: null } );
        }
        if ( showCellPhone ) {
          const roundsCellPhoneSOW = [];
          const seriesTitleCellPhoneSOW = [];
          this.getDataSerieBubbleCustomer( allData2, roundsCellPhoneSOW, seriesTitleCellPhoneSOW, 'shareOfMarket', 'shareOfVoice', '' );
          const resultCellPhoneSOW = this.createCharSerieBubbleCustomer( 'cellPhoneSOWDiv', roundsCellPhoneSOW, allData2, 'shareOfMarket', 'shareOfVoice', '', 'SOM', 'SOV' );
          this.configCellPhoneSOW = _.cloneDeep( resultCellPhoneSOW.configFixedTooltip );
          this.chartCellPhoneSOW = resultCellPhoneSOW.chart;
          // const tableLaptopSOW = result1.table;
          this.setState( { seriesTitleCellPhoneSOW: null } );
        }
      }
    } );
  };

  loadShareOfVoice( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardProfessorService.getShareOfVoiceAllWorkGroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          // seriesTitleMarketingInvestment
          // seriesTitleMarketingROI
          const allData = _.cloneDeep( response.data );
          const roundsShareOfVoice = [];
          const seriesTitleShareOfVoice = [];
          this.getDataSerie( allData, roundsShareOfVoice, seriesTitleShareOfVoice, 'shareOfVoice' );
          const resultShareOfVoice = this.createCharSerie( 'shareOfVoiceDiv', roundsShareOfVoice, allData, 'shareOfVoice', 'lastsUnitSales', true, 'Rounds' );
          this.configShareOfVoice = _.cloneDeep( resultShareOfVoice.config );
          this.chartShareOfVoice = resultShareOfVoice.chart;
          this.setState( { seriesTitleShareOfVoice } );
        }
      } );
  }


  loadLifetimeValue( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardProfessorService.getLifetimeValueAllWorkGroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          // seriesTitleMarketingInvestment
          // seriesTitleMarketingROI
          const allData = _.cloneDeep( response.data );
          const roundsLifetimeValue = [];
          const seriesTitleLifetimeValue = [];
          this.getDataSerie( allData, roundsLifetimeValue, seriesTitleLifetimeValue, 'lifetimeValue' );
          const resultLifetimeValue = this.createCharSerie( 'lifetimeValueDiv', roundsLifetimeValue, allData, 'lifetimeValue', 'lastsUnitSales', false, 'Rounds', 'value' );
          this.configLifetimeValue = _.cloneDeep( resultLifetimeValue.config );
          // console.log(resultLifetimeValue.config);

          this.chartLifetimeValue = resultLifetimeValue.chart;
          this.setState( { seriesTitleLifetimeValue } );

          const roundsClvcac = [];
          const seriesTitleClvcac = [];
          this.getDataSerieCLVCAC( allData, roundsClvcac, seriesTitleClvcac, 'lifetimeValue', 'acquisitionCost' );
          const resultClvcac = this.createCharSerieBar( 'clvcacDiv', roundsClvcac, allData, 'lifetimeValue', 'adquisitionCost', 'Groups(Lifetime value/Adquisition cost)', 'value' );
          this.configClvcac = _.cloneDeep( resultClvcac.config );
          // console.log(resultClvcac.config);
          this.chartClvcac = resultClvcac.chart;
          this.setState( { seriesTitleClvcac } );
        }
      } );
  }

  loadHistoricalBundle( userSessionID, activeWorkGroupID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardStudentService.getHistoricalBundle( userSessionID,
      { workgroup: activeWorkGroupID, limit: -1, 'sortby[createdAt]': 'ASC' } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          const allData = _.cloneDeep( response.data.data );
          const roundsBundle1 = [];
          const seriesTitleBundle1 = [];

          this.getDataSerie( allData, roundsBundle1, seriesTitleBundle1, 'unitSales' );
          const result1 = this.createCharSerie( 'bundleunitsale', roundsBundle1, allData, 'unitSales', 'lastsUnitSales', false );
          this.configBundle1 = _.cloneDeep( result1.config );
          this.chartBundle1 = result1.chart;
          const tableBundle1 = result1.table;

          this.getDataSerie( allData, roundsBundle1, seriesTitleBundle1, 'revenues' );
          const result2 = this.createCharSerie( 'bundlerevenuesale', roundsBundle1, allData, 'revenues', 'lastsRevenues', false );
          this.configBundle2 = _.cloneDeep( result2.config );
          this.chartBundle2 = result2.chart;
          const tableBundle2 = result2.table;

          this.setState( ( {
            tableBundle1,
            tableBundle2,
          } ) );
        }
      } );
  }

  loadCustomerActivity( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardProfessorService.getCustomerActivityAllWorkGroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );

          const allData = _.cloneDeep( response.data );
          const roundsActiveCustomers = [];
          const seriesTitleActiveCustomers = [];
          this.getDataSerie( allData, roundsActiveCustomers, seriesTitleActiveCustomers, 'activeCustomer' );
          const resultActiveCustomers = this.createCharSerie( 'activeCustomersDiv', roundsActiveCustomers, allData, 'activeCustomer', 'lastsUnitSales', false, 'Rounds', 'ActiveCustomers' );
          this.configActiveCustomers = _.cloneDeep( resultActiveCustomers.config );
          this.chartActiveCustomers = resultActiveCustomers.chart;


          const roundsActiveCustomersPercent = [];
          const seriesTitleActiveCustomersPercent = [];
          this.getDataSerie( allData, roundsActiveCustomersPercent, seriesTitleActiveCustomersPercent, 'activeCustomerPercentage' );
          const resultActiveCustomersPercent = this.createCharSerie( 'activeCustomersPercentDiv', roundsActiveCustomersPercent, allData, 'activeCustomerPercentage', 'lastsUnitSales', true, 'Rounds', 'Percent' );
          this.configActiveCustomersPercent = _.cloneDeep( resultActiveCustomersPercent.config );
          this.chartActiveCustomersPercent = resultActiveCustomersPercent.chart;

          const roundsCustomerRetention = [];
          const seriesTitleCustomerRetention = [];
          this.getDataSerie( allData, roundsCustomerRetention, seriesTitleCustomerRetention, 'retentionRate' );
          const resultCustomerRetention = this.createCharSerie( 'customerRetentionDiv', roundsCustomerRetention, allData, 'retentionRate', 'lastsUnitSales', false, 'Rounds', 'value' );
          this.configCustomerRetention = _.cloneDeep( resultCustomerRetention.config );
          this.chartCustomerRetention = resultCustomerRetention.chart;

          const roundsCustomerSatisfaction = [];
          const seriesTitleCustomerSatisfaction = [];
          this.getDataSerie( allData, roundsCustomerSatisfaction, seriesTitleCustomerSatisfaction, 'satisfaction' );
          const resultCustomerSatisfaction = this.createCharSerie( 'customerSatisfactionDiv', roundsCustomerSatisfaction, allData, 'satisfaction', 'lastsUnitSales', false, 'Rounds', 'value' );
          this.configCustomerSatisfaction = _.cloneDeep( resultCustomerSatisfaction.config );
          this.chartCustomerSatisfaction = resultCustomerSatisfaction.chart;

          const roundsCustomerActivity = [];
          const seriesTitleCustomerActivity = [];
          this.getDataSerie( allData, roundsCustomerActivity, seriesTitleCustomerActivity, 'customerActivity' );
          const resultCustomerActivity = this.createCharSerie( 'customerActivityDiv', roundsCustomerActivity, allData, 'customerActivity', 'lastsUnitSales', false, 'Rounds', 'Average' );
          this.configCustomerActivity = _.cloneDeep( resultCustomerActivity.config );
          this.chartCustomerActivity = resultCustomerActivity.chart;

          const roundsCustomerNps = [];
          const seriesTitleCustomerNps = [];
          this.getDataSerie( allData, roundsCustomerNps, seriesTitleCustomerNps, 'nps' );
          const resultCustomerNps = this.createCharSerie( 'customerNpsDiv', roundsCustomerNps, allData, 'nps', 'lastsUnitSales', false, 'Rounds', 'analytics.title.customerNps' );
          this.configCustomerNps = _.cloneDeep( resultCustomerNps.config );
          this.chartCustomerNps = resultCustomerNps.chart;

          this.setState( {
            seriesTitleActiveCustomersPercent,
            seriesTitleActiveCustomers,
            seriesTitleCustomerRetention,
            seriesTitleCustomerSatisfaction,
            seriesTitleCustomerNps,
            seriesTitleCustomerActivity,
          } );
        }
      } );
  }
  // DIrect marketing investment
  loadDirectMarketingInvestment( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardProfessorService.getDirectMarketingInvestmentWorkGroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          // seriesTitleMarketingInvestment
          // seriesTitleMarketingROI
          const allData = _.cloneDeep( response.data );
          const roundsDirectMktInvestment = [];
          const seriesTitleDirectMktInvestment = [];
          // console.log(allData,"%")
          this.getDataSerie( allData, roundsDirectMktInvestment, seriesTitleDirectMktInvestment, 'directMarketingExpensesPercent' );
          const resultDirectMktInvestment = this.createCharSerie( 'directMktInvestmentDiv', roundsDirectMktInvestment, allData, 'directMarketingExpensesPercent', 'lastsUnitSales', true, 'Rounds', 'Percent' );
          this.configDirectMktInvestment = _.cloneDeep( resultDirectMktInvestment.config );
          this.chartDirectMktInvestment = resultDirectMktInvestment.chart;
          this.setState( { seriesTitleDirectMktInvestment } );
        }
      } );
  }

  loadActivityEffectiveness( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardProfessorService.getActivityEffectivenessAllWorkGroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          // seriesTitleMarketingInvestment
          // seriesTitleMarketingROI
          const allData = _.cloneDeep( response.data );
          const roundsMarketingInvestment = [];
          const seriesTitleMarketingInvestment = [];
          this.getDataSerie( allData, roundsMarketingInvestment, seriesTitleMarketingInvestment, 'investment' );
          const resultMarketingInvestment = this.createCharSerie( 'marketingInvestmentDiv', roundsMarketingInvestment, allData, 'investment', 'lastsUnitSales', false, 'Rounds', 'investment' );
          this.configMarketingInvestment = _.cloneDeep( resultMarketingInvestment.config );
          this.chartMarketingInvestment = resultMarketingInvestment.chart;


          const roundsMarketingROI = [];
          const seriesTitleMarketingROI = [];
          this.getDataSerie( allData, roundsMarketingROI, seriesTitleMarketingROI, 'roi' );
          const resultMarketingROI = this.createCharSerie( 'marketingROIDiv', roundsMarketingROI, allData, 'roi', 'lastsUnitSales', false, 'Rounds', 'roi' );
          this.configMarketingROI = _.cloneDeep( resultMarketingROI.config );
          this.chartMarketingInvestment = resultMarketingROI.chart;

          this.setState( { seriesTitleMarketingInvestment, seriesTitleMarketingROI } );
        }
      } );
  }
  loadHistoricalStockValues( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardProfessorService.getStockValueAllWorkgroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          const allData = _.cloneDeep( response.data );
          const roundsBundle1 = [];
          const seriesStockValues1 = [];
          this.getDataSerie( allData, roundsBundle1, seriesStockValues1, 'stockValue' );
          const result1 = this.createCharSerie( 'stockValueDiv', roundsBundle1, allData, 'stockValue', 'lastsUnitSales', false, 'Rounds', 'Price' );
          this.configStockValue1 = _.cloneDeep( result1.config );
          this.chartStockValue1 = result1.chart;
          // const tableStockValue1 = result1.table;

          this.setState( { seriesStockValues1 } );
        }
      } );
  }

  loadCustomerAcquisitionCost( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardProfessorService.getCustomerAcquisitionCostAllWorkGroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          const allData = _.cloneDeep( response.data );
          /* const roundsCustomerAcquisitionCost = [];
          const seriesTitleCustomerAcquisitionCost = [];
          this.getDataSerieBubbleCustomer( allData, roundsCustomerAcquisitionCost, seriesTitleCustomerAcquisitionCost, 'advertising', 'newCustomers', 'pop' );
          // eslint-disable-next-line max-len
          const resultCustomerAcquisitionCost = this.createCharSerieBubbleCustomer( 'customerAcquisitionCostDiv', roundsCustomerAcquisitionCost, allData, 'advertising', 'newCustomers', 'advertising+pop' );
          // eslint-disable-next-line max-len
          this.configCustomerAcquisitionCost = _.cloneDeep( resultCustomerAcquisitionCost.configFixedTooltip );
          this.chartCustomerAcquisitionCost = resultCustomerAcquisitionCost.chart;
          */

          const roundsCustomerAcquisitionCostBAR = [];
          const seriesTitleCustomerAcquisitionCostBAR = [];
          this.getDataSerieBubbleCustomerBAR( allData, roundsCustomerAcquisitionCostBAR, seriesTitleCustomerAcquisitionCostBAR, 'advertising', 'newCustomers', 'pop' );
          const resultCustomerAcquisitionCostBAR = this.createCharSerieBubbleCustomerBAR( 'customerAcquisitionCostBARDiv', roundsCustomerAcquisitionCostBAR, allData, 'advertising', 'newCustomers', 'Groups', 'cost' );
          // eslint-disable-next-line max-len
          this.configCustomerAcquisitionCostBAR = _.cloneDeep( resultCustomerAcquisitionCostBAR.config );
          this.chartCustomerAcquisitionCostBAR = resultCustomerAcquisitionCostBAR.chart;

          // eslint-disable-next-line max-len
          this.setState( { seriesTitleCustomerAcquisitionCostBAR } );
        }
      } );
  }

  /* loadCustomerRetentionSatisfaction( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    // eslint-disable-next-line max-len
    DashboardProfessorService.getCustomerRetentionSatisfactionAllWorkGroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          const allData = _.cloneDeep( response.data );
          const roundsCustomerRetentionAndSatisfaction = [];
          const seriesTitleCustomerRetentionAndSatisfaction = [];
          this.getDataSerieBubbleCustomer( allData, roundsCustomerRetentionAndSatisfaction, seriesTitleCustomerRetentionAndSatisfaction, 'retention', 'satisfaction', '' );
          const resultCustomerRetentionAndSatisfaction = this.createCharSerieBubbleCustomer( 'customerRetentionAndSatisfactionDiv', roundsCustomerRetentionAndSatisfaction, allData, 'retention', 'satisfaction', '' );
          // eslint-disable-next-line max-len
          this.configCustomerRetentionAndSatisfaction = _.cloneDeep( resultCustomerRetentionAndSatisfaction.config );
          this.chartCustomerRetentionAndSatisfaction = resultCustomerRetentionAndSatisfaction.chart;
          this.setState( { seriesTitleCustomerRetentionAndSatisfaction: null } );
        }
      } );
  } */

  loadHistoricalBundleSales( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    // seriesTitleServicesSalesPercent
    // seriesTitleRecurringRevenue
    DashboardProfessorService.getServiceBundleSalesAllWorkgroup( userSessionID, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          const allData = _.cloneDeep( response.data );
          const roundsServicesSalesPercent = [];
          const seriesTitleServicesSalesPercent = [];
          this.getDataSerie( allData, roundsServicesSalesPercent, seriesTitleServicesSalesPercent, 'serviceSalesPercentage' );
          const resultServicesSalesPercent = this.createCharSerie( 'servicesSalesPercentDiv', roundsServicesSalesPercent, allData, 'serviceSalesPercentage', 'lastsUnitSales', true, 'Rounds', 'Percent' );
          this.configServicesSalesPercent = _.cloneDeep( resultServicesSalesPercent.config );
          this.chartServicesSalesPercent = resultServicesSalesPercent.chart;

          const roundsRecurringRevenue = [];
          const seriesTitleRecurringRevenue = [];
          this.getDataSerie( allData, roundsRecurringRevenue, seriesTitleRecurringRevenue, 'bundleSalesPercentage' );
          const resultRecurringRevenue = this.createCharSerie( 'recurringRevenueDiv', roundsRecurringRevenue, allData, 'bundleSalesPercentage', 'lastsUnitSales', true, 'Rounds', 'Percent' );
          this.configRecurringRevenue = _.cloneDeep( resultRecurringRevenue.config );
          this.chartRecurringRevenue = resultRecurringRevenue.chart;
          // const tableStockValue1 = result1.table;

          this.setState( {
            seriesTitleServicesSalesPercent,
            seriesTitleRecurringRevenue,
          } );
        }
      } );
  }

  loadHistoricalEbit( userSessionID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardProfessorService.getEarningBeforeIncomingTaxAllWorkgroup(
      userSessionID, { limit: -1 },
    )
      .then( ( response ) => {
        if ( response.ok ) {
          const allData = _.cloneDeep( response.data );
          const roundsBundle1 = [];
          const seriesEbit1 = [];
          this.getDataSerie( allData, roundsBundle1, seriesEbit1, 'ebit' );
          const result1 = this.createCharSerie( 'ebitDiv', roundsBundle1, allData, 'ebit', 'lastsUnitSales', false, 'Rounds', 'value' );
          this.configEbit1 = _.cloneDeep( result1.config );
          this.chartEbit1 = result1.chart;

          const roundsROS = [];
          const seriesTitleROS = [];
          this.getDataSerie( allData, roundsROS, seriesTitleROS, 'ros' );
          const resultROS = this.createCharSerie( 'rosDiv', roundsROS, allData, 'ros', 'lastsUnitSales', true, 'Rounds', 'Percent' );
          this.configROS = _.cloneDeep( resultROS.config );
          this.chartROS = resultROS.chart;

          this.setState( { seriesEbit1, seriesTitleROS } );
          toggleLoading( false );
        }
      } );
  }

  loadHistoricalProducts( userSessionID, activeWorkGroupID ) {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    DashboardStudentService.getHistoricalProducts( userSessionID,
      activeWorkGroupID, { limit: -1, 'sortby[createdAt]': 'ASC' } )
      .then( ( response ) => {
        if ( response.ok ) {
          const allData = _.cloneDeep( response.data.data );
          const categories1 = _.filter( allData, { category: { label: 'CATEGORY_1' } } );
          const categories2 = _.filter( allData, { category: { label: 'CATEGORY_2' } } );
          // const categories3 = _.filter( allData, { category: { label: 'CATEGORY_3' } } );
          const roundsCat1 = [];
          const roundsCat2 = [];
          // const roundsCat3 = [];
          const seriesTitle1 = [];
          const seriesTitle2 = [];

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'unitSales' );
          const result1 = this.createCharSerie( 'laptopsalesbybrandu', roundsCat1, categories1, 'unitSales', 'lastsUnitSales', false );
          this.config1 = _.cloneDeep( result1.config );
          this.chart = result1.chart;
          const table1 = result1.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'revenues' );
          const result11 = this.createCharSerie( 'laptopsalesbybrandrevenuew', roundsCat1, categories1, 'revenues', 'lastsRevenues', false );
          this.config11 = _.cloneDeep( result11.config );
          this.chart11 = result11.chart;
          const table11 = result11.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'unitSales' );
          let result2 = this.createCharSerie( 'tabletsalesbybrandu', roundsCat2, categories2, 'unitSales', 'lastsUnitSales', false );
          this.config2 = _.cloneDeep( result2.config );
          this.chart2 = result2.chart;
          const table2 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'revenues' );
          result2 = this.createCharSerie( 'tabletsalesbybrandrevenue', roundsCat2, categories2, 'revenues', 'lastsRevenues', false );
          this.config21 = _.cloneDeep( result2.config );
          this.chart21 = result2.chart;
          const table21 = result2.table;

          this.setState( ( {
            table1,
            table2,
            table11,
            table21,

          } ) );
          toggleLoading( false );
        }
      } );
  }


  render() {
    const { activeTabIndex, dataTabReport } = this.state;

    const {
      chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
      // eslint-disable-next-line no-unused-vars
      table1,
      // eslint-disable-next-line no-unused-vars
      table2,
      // eslint-disable-next-line no-unused-vars
      table3,
      // eslint-disable-next-line no-unused-vars
      table11,
      // eslint-disable-next-line no-unused-vars
      table21,
      // eslint-disable-next-line no-unused-vars
      table31,

      // eslint-disable-next-line no-unused-vars
      tableBundle1,
      // eslint-disable-next-line no-unused-vars
      tableBundle2,
      seriesStockValues1,
      seriesEbit1,
      seriesTitleLaptopMarketShareU,
      seriesTitleCellPhoneMarketShareU,
      seriesTitleTabletMarketShareU,
      seriesTitleLaptopMarketShareDol,
      seriesTitleCellPhoneMarketShareDol,
      seriesTitleTabletMarketShareDol,
      seriesTitleLaptopPercentDirectSalesByBrand,
      seriesTitleCellPhonePercentDirectSalesByBrand,
      seriesTitleTabletPercentDirectSalesByBrand,
      seriesTitleServicesSalesPercent,
      seriesTitleRecurringRevenue,
      seriesTitleLaptopPriceByProduct,
      seriesTitleCellPhonePriceByProduct,
      seriesTitleTabletPriceByProduct,
      seriesTitleLaptopPriceVsSalesByProduct,
      seriesTitleCellPhonePriceVsSalesByProduct,
      seriesTitleTabletPriceVsSalesByProduct,
      seriesTitleROS,
      seriesTitleMarketingInvestment,
      seriesTitleMarketingROI,
      seriesTitleActiveCustomers,
      seriesTitleActiveCustomersPercent,
      seriesTitleCustomerRetention,
      seriesTitleCustomerSatisfaction,
      seriesTitleCustomerNps,
      seriesTitleCustomerActivity,
      // seriesTitleCustomerRetentionAndSatisfaction,
      // seriesTitleCustomerAcquisitionCost,
      seriesTitleCustomerAcquisitionCostBAR,
      seriesTitleShareOfVoice,
      seriesTitleDirectMktInvestment,
      seriesTitleLaptopSOW,
      seriesTitleCellPhoneSOW,
      seriesTitleTabletSOW,
      seriesTitleLifetimeValue,
      seriesTitleClvcac,
    } = this.state;
    const { showCellPhone, showLaptop, showTablet } = this;


    return (
      <div className="mb-3">

        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'breadcrumb.info.analytics' },
          ]}
        />
        <KotlerSectionHeader title="info.analytics" image={HeaderImage} />
        <Tabs
          value={activeTabIndex !== -1 ? activeTabIndex : 0}
          onChange={this.toggleTab2}
          className="kotler-tab-pills my-3"
          variant="scrollable"
        >
          { _.map( dataTabReport, item => (
            <Tab label={( <IntlMessages id={item.name} /> )} className="tab" key={item.id} />
          ) )}
        </Tabs>
        {activeTabIndex === 0 && (
          <>
            <Grid container style={{ rowGap: '15px' }}>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.stockPrices"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configStockValue1, 'analytics.title.stockPrices', seriesStockValues1 )}
                >
                  <div className="jr-link mt-3" id="stockValueDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.ebit"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configEbit1, 'analytics.title.ebit', seriesEbit1 )}
                >
                  <div className="jr-link mt-3" id="ebitDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardBox
                  heading="analytics.title.servicesSalesPercent"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configServicesSalesPercent, 'analytics.title.servicesSalesPercent', seriesTitleServicesSalesPercent )}
                >
                  <div className="jr-link mt-3" id="servicesSalesPercentDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardBox
                  heading="analytics.title.recurringRevenue"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configRecurringRevenue, 'analytics.title.recurringRevenue', seriesTitleRecurringRevenue )}
                >
                  <div className="jr-link mt-3" id="recurringRevenueDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardBox
                  heading="analytics.title.ROS"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configROS, 'analytics.title.ROS', seriesTitleROS )}
                >
                  <div className="jr-link mt-3" id="rosDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
            </Grid>
            <KotlerTabs
              tabClassName="kotler-tabs mt-3"
              titles={[

                ...( showLaptop
                  ? [
                    {
                      title: 'laptops',
                      icon: <FeatherIcon size="20px" icon="monitor" />,
                    },
                  ]
                  : [] ),
                ...( showTablet
                  ? [
                    {
                      title: 'tablets',
                      icon: <FeatherIcon size="20px" icon="tablet" />,
                    },
                  ]
                  : [] ),
                ...( showCellPhone
                  ? [
                    {
                      title: 'cellPhones',
                      icon: (
                        <FeatherIcon
                          size="20px"
                          color="primary.main"
                          icon="smartphone"
                        />
                      ),
                    },
                  ]
                  : [] ),


              ]}
              pages={[
                ...( showLaptop
                  ? [
                    <Grid container style={{ rowGap: '15px' }}>
                      <Grid item xs={12} md={6}>
                        <CardBox
                          heading="analytics.LaptopMarketShareU"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configLaptopMarketShare, 'analytics.LaptopMarketShareU', seriesTitleLaptopMarketShareU )}
                        >
                          <div className="jr-link mt-3" id="laptopMarketShareUDiv" style={{ width: '100%', height: '250px' }} />
                        </CardBox>

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CardBox
                          heading="analytics.LaptopMarketShareDol"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configLaptopMarketShareDol, 'analytics.LaptopMarketShareDol', seriesTitleLaptopMarketShareDol )}
                        >
                          <div className="jr-link mt-3" id="laptopMarketShareDolDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>
                      </Grid>

                    </Grid>,
                  ]
                  : [] ),
                ...( showTablet
                  ? [
                    <Grid container style={{ rowGap: '15px' }}>
                      <Grid item xs={12} md={6}>
                        <CardBox
                          heading="analytics.TabletMarketShareU"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configTabletMarketShare, 'analytics.TabletMarketShareU', seriesTitleTabletMarketShareU )}
                        >
                          <div className="jr-link mt-3" id="tabletMarketShareUDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CardBox
                          heading="analytics.TabletMarketShareDol"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configTabletMarketShareDol, 'analytics.TabletMarketShareDol', seriesTitleTabletMarketShareDol )}
                        >
                          <div className="jr-link mt-3" id="tabletMarketShareDolDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>
                      </Grid>

                    </Grid>,
                  ]
                  : [] ),
                ...( showCellPhone
                  ? [
                    <Grid container style={{ rowGap: '15px' }}>
                      <Grid item xs={12} md={6}>

                        <CardBox
                          heading="analytics.CellPhoneMarketShareU"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configCellPhoneMarketShare, 'analytics.CellPhoneMarketShareU', seriesTitleCellPhoneMarketShareU )}
                        >
                          <div className="jr-link mt-3" id="cellPhoneMarketShareUDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CardBox
                          heading="analytics.CellPhoneMarketShareDol"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configCellPhoneMarketShareDol, 'analytics.CellPhoneMarketShareDol', seriesTitleCellPhoneMarketShareDol )}
                        >
                          <div className="jr-link mt-3" id="cellPhoneMarketShareDolDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>

                      </Grid>
                    </Grid>,
                  ]
                  : [] ),

              ]}
            />


          </>
        )}

        {activeTabIndex === 1 && (
          <>
            <KotlerTabs
              tabClassName="kotler-tabs mt-3"
              titles={[

                ...( showLaptop
                  ? [
                    {
                      title: 'laptops',
                      icon: <FeatherIcon size="20px" icon="monitor" />,
                    },
                  ]
                  : [] ),
                ...( showTablet
                  ? [
                    {
                      title: 'tablets',
                      icon: <FeatherIcon size="20px" icon="tablet" />,
                    },
                  ]
                  : [] ),
                ...( showCellPhone
                  ? [
                    {
                      title: 'cellPhones',
                      icon: (
                        <FeatherIcon
                          size="20px"
                          color="primary.main"
                          icon="smartphone"
                        />
                      ),
                    },
                  ]
                  : [] ),


              ]}
              pages={[
                ...( showLaptop
                  ? [
                    <CardBox
                      heading="analytics.LaptopPercentDirectSalesByBrand"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal( this.configLaptopPercentDirectSalesByBrand, 'analytics.LaptopPercentDirectSalesByBrand', seriesTitleLaptopPercentDirectSalesByBrand )}
                    >
                      <div className="jr-link mt-3" id="laptopPercentDirectSalesByBrandDiv" style={{ width: '100%', height: '250px' }} />

                    </CardBox>,
                  ]
                  : [] ),
                ...( showTablet
                  ? [
                    <CardBox
                      heading="analytics.TabletPercentDirectSalesByBrand"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal( this.configTabletPercentDirectSalesByBrand, 'analytics.TabletPercentDirectSalesByBrand', seriesTitleTabletPercentDirectSalesByBrand )}
                    >
                      <div className="jr-link mt-3" id="tabletPercentDirectSalesByBrandDiv" style={{ width: '100%', height: '250px' }} />

                    </CardBox>,
                  ]
                  : [] ),
                ...( showCellPhone
                  ? [
                    <CardBox
                      heading="analytics.CellPhonePercentDirectSalesByBrand"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal( this.configCellPhonePercentDirectSalesByBrand, 'analytics.CellPhonePercentDirectSalesByBrand', seriesTitleCellPhonePercentDirectSalesByBrand )}
                    >
                      <div className="jr-link mt-3" id="cellPhonePercentDirectSalesByBrandDiv" style={{ width: '100%', height: '250px' }} />

                    </CardBox>,
                  ]
                  : [] ),

              ]}
            />


          </>
        )}

        {activeTabIndex === 2 && (
          <>
            <KotlerTabs
              tabClassName="kotler-tabs mt-3"
              titles={[

                ...( showLaptop
                  ? [
                    {
                      title: 'laptops',
                      icon: <FeatherIcon size="20px" icon="monitor" />,
                    },
                  ]
                  : [] ),
                ...( showTablet
                  ? [
                    {
                      title: 'tablets',
                      icon: <FeatherIcon size="20px" icon="tablet" />,
                    },
                  ]
                  : [] ),
                ...( showCellPhone
                  ? [
                    {
                      title: 'cellPhones',
                      icon: (
                        <FeatherIcon
                          size="20px"
                          color="primary.main"
                          icon="smartphone"
                        />
                      ),
                    },
                  ]
                  : [] ),


              ]}
              pages={[
                ...( showLaptop
                  ? [
                    <Grid container style={{ rowGap: '15px' }}>
                      <Grid item xs={12} md={6}>

                        <CardBox
                          heading="analytics.LaptopPriceByProduct"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configLaptopPriceByProduct, 'analytics.LaptopPriceByProduct', seriesTitleLaptopPriceByProduct )}
                        >
                          <div className="jr-link mt-3" id="laptopPriceByProductDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CardBox
                          heading="analytics.LaptopPriceVsSalesByProduct"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configLaptopPriceVsSalesByProduct, 'analytics.LaptopPriceVsSalesByProduct', seriesTitleLaptopPriceVsSalesByProduct )}
                        >
                          <div className="jr-link mt-3" id="laptopPriceVsSalesByProductDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>
                      </Grid>
                    </Grid>,
                  ]
                  : [] ),
                ...( showTablet
                  ? [
                    <Grid container style={{ rowGap: '15px' }}>
                      <Grid item xs={12} md={6}>

                        <CardBox
                          heading="analytics.TabletPriceByProduct"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configTabletPriceByProduct, 'analytics.TabletPriceByProduct', seriesTitleTabletPriceByProduct )}
                        >
                          <div className="jr-link mt-3" id="tabletPriceByProductDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CardBox
                          heading="analytics.TabletPriceVsSalesByProduct"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configTabletPriceVsSalesByProduct, 'analytics.TabletPriceVsSalesByProduct', seriesTitleTabletPriceVsSalesByProduct )}
                        >
                          <div className="jr-link mt-3" id="tabletPriceVsSalesByProductDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>
                      </Grid>

                    </Grid>,
                  ]
                  : [] ),
                ...( showCellPhone
                  ? [
                    <Grid container style={{ rowGap: '15px' }}>
                      <Grid item xs={12} md={6}>

                        <CardBox
                          heading="analytics.CellPhonePriceByProduct"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configCellPhonePriceByProduct, 'analytics.CellPhonePriceByProduct', seriesTitleCellPhonePriceByProduct )}
                        >
                          <div className="jr-link mt-3" id="cellPhonePriceByProductDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CardBox
                          heading="analytics.CellPhonePriceVsSalesByProduct"
                          styleName="px-2 h-100"
                          onClick={() => this.toggleChartModal( this.configCellPhonePriceVsSalesByProduct, 'analytics.CellPhonePriceVsSalesByProduct', seriesTitleCellPhonePriceVsSalesByProduct )}
                        >
                          <div className="jr-link mt-3" id="cellPhonePriceVsSalesByProductDiv" style={{ width: '100%', height: '250px' }} />

                        </CardBox>
                      </Grid>
                    </Grid>,
                  ]
                  : [] ),

              ]}
            />

          </>
        )}

        {activeTabIndex === 3 && (
          <>
            <Grid container style={{ rowGap: '15px' }}>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.marketingInvestment"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configMarketingInvestment, 'analytics.title.marketingInvestment', seriesTitleMarketingInvestment )}
                >
                  <div className="jr-link mt-3" id="marketingInvestmentDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.marketingROI"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configMarketingROI, 'analytics.title.marketingROI', seriesTitleMarketingROI )}
                >
                  <div className="jr-link mt-3" id="marketingROIDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.shareOfVoice"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configShareOfVoice, 'analytics.title.shareOfVoice', seriesTitleShareOfVoice )}
                >
                  <div className="jr-link mt-3" id="shareOfVoiceDiv" style={{ width: '100%', height: '250px' }} />
                </CardBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.directMktInvestment"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configDirectMktInvestment, 'analytics.title.directMktInvestment', seriesTitleDirectMktInvestment )}
                >
                  <div className="jr-link mt-3" id="directMktInvestmentDiv" style={{ width: '100%', height: '250px' }} />
                </CardBox>
              </Grid>
            </Grid>
            <KotlerTabs
              tabClassName="kotler-tabs mt-3"
              titles={[

                ...( showLaptop
                  ? [
                    {
                      title: 'laptops',
                      icon: <FeatherIcon size="20px" icon="monitor" />,
                    },
                  ]
                  : [] ),
                ...( showTablet
                  ? [
                    {
                      title: 'tablets',
                      icon: <FeatherIcon size="20px" icon="tablet" />,
                    },
                  ]
                  : [] ),
                ...( showCellPhone
                  ? [
                    {
                      title: 'cellPhones',
                      icon: (
                        <FeatherIcon
                          size="20px"
                          color="primary.main"
                          icon="smartphone"
                        />
                      ),
                    },
                  ]
                  : [] ),


              ]}
              pages={[
                ...( showLaptop
                  ? [
                    <CardBox
                      heading="analytics.LaptopSOW"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal( this.configLaptopSOW, 'analytics.LaptopSOW', seriesTitleLaptopSOW )}
                    >
                      <div className="jr-link mt-3" id="laptopSOWDiv" style={{ width: '100%', height: '250px' }} />

                    </CardBox>,
                  ]
                  : [] ),
                ...( showTablet
                  ? [
                    <CardBox
                      heading="analytics.TabletSOW"
                      styleName="px-2 h-100"
                      headerBg="bg-product-tablet"
                      icon="tablet-alt"
                      childrenStyle=""
                      onClick={() => this.toggleChartModal( this.configTabletSOW, 'analytics.TabletSOW', seriesTitleTabletSOW )}
                    >
                      <div className="jr-link mt-3" id="tabletSOWDiv" style={{ width: '100%', height: '250px' }} />

                    </CardBox>,
                  ]
                  : [] ),
                ...( showCellPhone
                  ? [
                    <CardBox
                      heading="analytics.CellPhoneSOW"
                      styleName="px-2 h-100"
                      onClick={() => this.toggleChartModal( this.configCellPhoneSOW, 'analytics.CellPhoneSOW', seriesTitleCellPhoneSOW )}
                    >
                      <div className="jr-link mt-3" id="cellPhoneSOWDiv" style={{ width: '100%', height: '250px' }} />

                    </CardBox>,
                  ]
                  : [] ),

              ]}
            />
          </>
        )}

        {activeTabIndex === 4 && (
          <>
            <Grid container style={{ rowGap: '15px' }}>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.activeCustomers"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configActiveCustomers, 'analytics.title.activeCustomers', seriesTitleActiveCustomers )}
                >
                  <div className="jr-link mt-3" id="activeCustomersDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.activeCustomersPercent"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configActiveCustomersPercent, 'analytics.title.activeCustomersPercent', seriesTitleActiveCustomersPercent )}
                >
                  <div className="jr-link mt-3" id="activeCustomersPercentDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} className="d-none">
                <CardBox
                  heading="analytics.title.customerSatisfaction"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configCustomerSatisfaction, 'analytics.title.customerSatisfaction', seriesTitleCustomerSatisfaction )}
                >
                  <div className="jr-link mt-3" id="customerSatisfactionDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.customerRetention"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configCustomerRetention, 'analytics.title.customerRetention', seriesTitleCustomerRetention )}
                >
                  <div className="jr-link mt-3" id="customerRetentionDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} className="d-none">
                <CardBox
                  heading="analytics.title.customerNps"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configCustomerNps, 'analytics.title.customerNps', seriesTitleCustomerNps )}
                >
                  <div className="jr-link mt-3" id="customerNpsDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardBox
                  heading="analytics.title.customerActivity"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configCustomerActivity, 'analytics.title.customerActivity', seriesTitleCustomerActivity )}
                >
                  <div className="jr-link mt-3" id="customerActivityDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardBox
                  heading="analytics.title.lifetimeValue"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configLifetimeValue, 'analytics.title.lifetimeValue', seriesTitleLifetimeValue )}
                >
                  <div className="jr-link mt-3" id="lifetimeValueDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardBox
                  heading="analytics.title.clvcac"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configClvcac, 'analytics.title.clvcac', seriesTitleClvcac )}
                >
                  <div className="jr-link mt-3" id="clvcacDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardBox
                  heading="analytics.title.customerAcquisitionCostBAR"
                  styleName="px-2 h-100"
                  onClick={() => this.toggleChartModal( this.configCustomerAcquisitionCostBAR, 'analytics.title.customerAcquisitionCostBAR', seriesTitleCustomerAcquisitionCostBAR )}
                >
                  <div className="jr-link mt-3" id="customerAcquisitionCostBARDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Grid>
            </Grid>
            {/* <CardBox
                  heading="analytics.title.customerRetentionAndSatisfaction"
                  childrenStyle=""
                  headerBg="bg-bundles"
                  onClick={() => this.toggleChartModal( this.configCustomerRetentionAndSatisfaction, 'analytics.title.customerRetentionAndSatisfaction', seriesTitleCustomerRetentionAndSatisfaction )}
                >
                  <div className="jr-link mt-3" id="customerRetentionAndSatisfactionDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox> */}
            { /* <Row>
              <Col lg={6}>
                <CardBox
                  heading="analytics.title.customerAcquisitionCost"
                  childrenStyle=""
                  headerBg="bg-bundles"
                  onClick={() => this.toggleChartModal( this.configCustomerAcquisitionCost, 'analytics.title.customerAcquisitionCost', seriesTitleCustomerAcquisitionCost )}
                >
                  <div className="jr-link mt-3" id="customerAcquisitionCostDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Col>
              <Col lg={6}>
                <CardBox
                  heading="analytics.title.customerAcquisitionCostBAR"
                  childrenStyle=""
                  headerBg="bg-bundles"
                  onClick={() => this.toggleChartModal( this.configCustomerAcquisitionCostBAR, 'analytics.title.customerAcquisitionCostBAR', seriesTitleCustomerAcquisitionCostBAR )}
                >
                  <div className="jr-link mt-3" id="customerAcquisitionCostBARDiv" style={{ width: '100%', height: '250px' }} />

                </CardBox>
              </Col>
           </Row> */}

          </>
        )}
        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />

      </div>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};
const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect( mapStateToProps, mapDispatchToProps )( injectIntl( AnalitycsCharts ) );
