import _ from 'lodash';
import { formatMessage, formatRoundName } from './IntlHelpers';

/**
 * Formats the input data into a format suitable for a line graph.
 * @param {Array<Object>} data - The input data.
 * @param {string} [historicalValue='totalNumberCustomer'] - The key to determine historical
 * @returns {{
 *   data: Array<{
 *     attribute: string,
 *     id: string,
 *     [key: string]: string | number
 *   }>,
 *   seriesData: Array<{
 *     valueField: string,
 *     name: string
 *   }>
 * }} An object containing two properties: 'data' and 'seriesData'.
 * - 'data': The formatted data for the line graph.
 * - 'seriesData': The series data for the graph.
 */
export const formatDataToLineGraph = (
  data,
  historicalValue = 'totalNumberCustomer',
) => {
  if ( !data || !data.length ) return {};

  const roundsFormated = [];

  _.map( data, ( item ) => {
    _.map( item.historical, ( round ) => {
      const exist = _.findIndex( roundsFormated, { id: round.round.id } );
      if ( exist === -1 ) {
        // Inserta el valor del arary principal de este grupo en el objeto de ronda
        roundsFormated.push( {
          attribute: formatRoundName( round.round.shortName ),
          id: round.round.id,
          [item.name]: round[historicalValue],
        } );
      } else {
        roundsFormated[exist][item.name] = round[historicalValue];
      }
    } );
  } );

  const seriesData = _.map( data, item => ( {
    valueField: item.name,
    name: item.name,
  } ) );

  return { data: roundsFormated, seriesData };
};

/**
 * Formatea los datos de entrada en un formato adecuado para un gráfico circular (gráfico de tarta).
 * @param {Array} data - Datos de entrada que contienen información de grupos.
 * @param {string} key - El nombre de la clave de datos a utilizar.
 * @returns {Object} - Objeto formateado para gráfico circular.
 */
export const formatDataToPieGraphPerGroup = (
  data,
  key = 'totalNumberCustomer',
) => {
  if ( !data || !data.length ) return {};

  const groupsFormated = _.map( data, item => ( {
    id: item.id,
    group: item.name,
    value: item[key],
  } ) );

  return { data: groupsFormated };
};
/**
 * Formatea los datos de entrada en un formato adecuado para un gráfico de radar (gráfico de radar).
 * @param {Array} data - Datos de entrada que contienen información de grupos.
 * @param {string} key - El nombre de la clave de datos a utilizar.
 * @returns {Object} - Objeto formateado para gráfico circular.
 */
export const formatDataToRadioGraph = ( data ) => {
  if ( !data || !data.length ) return {};

  const attributesFormated = [];

  _.map( data, ( product ) => {
    _.map( product.attributes, ( attribute ) => {
      const exist = _.findIndex( attributesFormated, { id: attribute.id } );
      if ( exist === -1 ) {
        attributesFormated.push( {
          attribute: formatMessage( { id: attribute.label } ),
          id: attribute.id,
          [product.name]: attribute.value,
        } );
      } else {
        attributesFormated[exist][product.name] = attribute.value;
      }
    } );
  } );

  const seriesData = _.map( data, product => ( {
    valueField: product.name,
    name: product.name,
  } ) );

  return { data: attributesFormated, seriesData };
};

export default { formatDataToLineGraph, formatDataToPieGraphPerGroup };
