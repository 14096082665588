import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import async from 'async';
import _ from 'lodash';

import { connect } from 'react-redux';

import IntlMessages from 'utils/IntlMessages';
import { TextField, ButtonsSpyLogin } from 'components/custom/FormElements';
import { required } from 'config/InputErrors';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import AuthService from 'services/Auth';
import UserService from 'services/User';


class SpyLoginForm extends React.Component {
  // eslint-disable-next-line no-unused-vars
  constructor( props ) {
    super();
  }
  getUser = ( data, cb ) => {
    UserService.getCurrentUserSpy( data )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        return cb( null, { ...data, ...response.data } );
      } );
  };
  getNotificationsUser = ( data, cb ) => {
    UserService.getNotificationsUser( data )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        return cb( null, { ...data, notifications: response.data } );
      } );
  };
  setReturnToken = ( data, cb ) => {
    const { accessToken, sessionId } = this.props;
    UserService.setReturnToken( sessionId, { returnToken: accessToken } )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        return cb( null, { ...data } );
      } );
  };
  loginUser = ( data, cb ) => {
    AuthService.loginUser( data )
      .then( response => cb( response.errors, response.data ) );
  };
  submitForm = ( dataForm ) => {
    const {
      toggleAlert,
      toggleLoading,
      changeActiveSession,
      history,
      user,
      data,
      loginUser,
      setSpyMode,
    } = this.props;
    toggleLoading( true );
    const dataToSend = {};
    dataToSend.password = dataForm.password;
    dataToSend.username = user.email;
    dataToSend.workgroup = data.workgroup;
    async.waterfall( [
      this.setReturnToken.bind( this, dataToSend ),
      this.loginUser,
      this.getUser,
      this.getNotificationsUser,
    ], ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        if ( error === 'invalid_grant' ) {
          return toggleAlert( 'wrongUserPass', 'danger', true );
        }
        return toggleAlert( error );
      }
      if ( _.get( results, 'licenses[0].session' ) ) {
        changeActiveSession( results.licenses[0].session );
        setSpyMode( true );
        loginUser( results );
        history.push( '/' );
      } else {
        loginUser( results );
        history.push( '/sessions/add' );
      }
    } );
  };
  render() {
    const {
      open,
      onClose,
      data,
    } = this.props;

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <Form
          initialValues={data}
          onSubmit={this.submitForm}
          validate={( values ) => {
            const errors = {};
            if ( ( !data || !data.id ) && !values.userEmail && !values.user ) {
              errors.enterValue = 'Required';
            }
            return errors;
          }}
          // eslint-disable-next-line no-unused-vars
          render={( { handleSubmit } ) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle className="bg-dark text-white">
                <IntlMessages id="spy.title.form" />
              </DialogTitle>
              <DialogContent className="p-5 overflow-visible">

                <div className="row">
                  <div className="col-md-9">
                    <TextField
                      field="password"
                      type="password"
                      label="password"
                      validate={required}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <ButtonsSpyLogin onClickCancel={onClose} />
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => ( {
  accessToken: user.access_token,
  isLoading: settings.isLoading,
  user,
} );

const mapDispatchToProps = ( {
  loginUser: UserActions.loginUser,
  logoutUser: UserActions.logoutUser,
  setSpyMode: UserActions.setSpyMode,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  toggleVideo: SettingsActions.toggleVideo,
  notificationsUser: UserActions.notificationsUser,
  changeActiveSession: UserActions.changeActiveSession,
} );

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( SpyLoginForm ) );
