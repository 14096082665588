import React, { useEffect, useState } from 'react';
import { withWidth } from '@material-ui/core';
import GraphDecorator from '../decorators/GraphDecorator';
import PieGraph from '../PieGraph';

/**
 * Componente PieGraphFull
 *
 * Este componente se utiliza para mostrar un gráfico de torta (PieGraph) con la capacidad de mostrarlo en un modal emergente cuando se hace clic.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.title - El título del gráfico de torta.
 * @param {string} props.hoverTitle - El título que se muestra al pasar el cursor sobre el gráfico de torta.
 * @param {string} props.htmlId - El ID del elemento HTML donde se renderizará el gráfico de torta.
 * @param {Array} props.data - Un arreglo de objetos que contiene los datos que se mostrarán en el gráfico de torta.
 * @param {string} props.height - La altura del gráfico de torta (por ejemplo, '315px').
 * @param {string} props.valueField - El nombre del campo en los datos que se utilizará para determinar el valor de cada parte de la torta.
 * @param {string} props.categoryField - El nombre del campo en los datos que se utilizará para categorizar las partes de la torta.
 * @param {string} props.width - El ancho del componente, que se obtiene de Material-UI.
 * @param {boolean} props.loading - Un booleano que indica si los datos se están cargando.
 * @param {boolean} props.error - Un booleano que indica si se ha producido un error al cargar los datos.
 * @returns {JSX.Element} - El componente PieGraphFull.
 */
const PieGraphFull = ( {
  title,
  hoverTitle,
  htmlId,
  data,
  height = '315px',
  valueField = 'value',
  categoryField = 'group',
  width,
  children,
  loading,
  error,
  decoratorProps = {},
} ) => {
  const [dataToGraph, setDataToGraph] = useState();

  useEffect( () => {
    if ( JSON.stringify( data ) !== JSON.stringify( dataToGraph ) ) {
      setDataToGraph( data );
    }
  }, [data, dataToGraph] );

  return (
    <GraphDecorator
      {...decoratorProps}
      title={title}
      hoverTitle={hoverTitle}
      extraChilds={children}
      modalGraph={(
        <PieGraph
          loading={loading}
          error={error}
          height={width === 'xs' || width === 'sm' ? '90vh' : '70vh'}
          data={dataToGraph}
          interactive
          categoryField={categoryField}
          valueField={valueField}
          htmlId={`${htmlId}-modal`}
          delay={100}
        />
      )}
    >
      <PieGraph
        height={height}
        data={dataToGraph}
        loading={loading}
        error={error}
        categoryField={categoryField}
        valueField={valueField}
        interactive={false}
        htmlId={htmlId}
      />
    </GraphDecorator>
  );
};

export default withWidth()( PieGraphFull );
