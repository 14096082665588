/* eslint-disable react/jsx-indent */
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import async from 'async';
import { Row, Col } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { Link, Prompt } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import ProductService from 'services/Product';
import CategoryService from 'services/Category';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import {
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
// import service from 'assets/images/kotler/icons/icon-service.png';
import CategoryProductBtn from 'components/custom_v2/CategoryProductBtn';
import { TextField, Switch } from 'components/custom/FormElements';
import PropertyIcon from 'components/custom_v2/PropertyIcon';
// import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';

import ProductionService from 'services/Production';
import {
  composeValidators,
  greaterOrEqualValue,
  lessOrEqualThanValue,
  required,
} from 'config/InputErrors';
import HoverTitle from 'components/custom_v2/HoverTitle';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/products.jpeg';
import SectionInfoText from 'components/custom/SectionInfoText';
import FeatherIcon from 'components/custom/FeatherIcon';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import ConfirmCancelButtons from 'components/custom/ConfirmCancelButtons';
import KotlerProductIcon from 'components/custom/KotlerProductIcon';
import { STATUS } from 'modules/sessions';
// import mkmode from 'constants/mkmode';

class Products extends React.Component {
  state = {
    categories: [],
    openedProducts: [],
    activeFilter: true,
    confirmSaveModal: false,
    confirmDeactivateModal: false,
    confirmActivateModal: false,
    confirmExitModal: false,
  };
  isEscapeKey = false;
  fieldModified = false;
  showActiveMessage = false;
  canExit = false;
  goTo = '/';
  mkmode = {};
  componentWillMount() {
    document.removeEventListener( 'keydown', this.handleKeyPress );
  }
  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;

    this.getData();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }

  componentWillUnmount() {
    this.fieldModified = false;
    document.removeEventListener( 'keydown', this.handleKeyPress );
  }

  toggleConfirmExitModal = () => {
    const { confirmExitModal } = this.state;
    this.setState( { confirmExitModal: !confirmExitModal } );
  };

  setConfirmExitModal = () => {
    const { history } = this.props;
    this.canExit = true;
    if ( this.goTo === '' ) {
      this.fieldModified = false;
    } else {
      history.push( this.goTo );
    }
  };

  setChangeStatus = () => {
    this.fieldModified = true;
  };
  handleKeyPress = ( event ) => {
    const { user } = this.props;
    if ( event.key === 'Enter' ) {
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        if (
          user
          && user.activeSession
          && user.activeSession.status !== STATUS.READY
        ) return;
        event.preventDefault();
        const { confirmExitModal } = this.state;
        if ( !confirmExitModal ) {
          this.setState( { confirmSaveModal: true } );
        }
      }
    }

    if ( event.key === 'Escape' ) {
      this.isEscapeKey = true;
    }
  };

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { activeFilter } = this.state;

    toggleLoading( true );

    async.parallel(
      {
        categories: ( cb ) => {
          CategoryService.getCategories( { 'filters[session]': this.activeSession.id } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        products: ( cb ) => {
          ProductService.getProducts( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            'filters[inStatus]': activeFilter ? ['READY', 'RD'] : 'CANCELED',
            limit: -1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) toggleAlert( error );
        let categories = _.map( results.categories, category => ( {
          ...category,
          products: activeFilter
            ? [
              ..._.filter( results.products, {
                category: { id: category.id },
                status: 'ACTIVE',
              } ),
              ..._.filter( results.products, {
                category: { id: category.id },
                status: 'READY',
              } ),
              ..._.filter( results.products, {
                category: { id: category.id },
                status: 'RD',
              } ),
            ]
            : _.filter( results.products, {
              category: { id: category.id },
              status: 'CANCELED',
            } ),
        } ) );
        if ( !this.mkmode.decision.products.laptop ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Lapto' ) === -1,
          );
        }
        if ( !this.mkmode.decision.products.tablet ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Tablet' ) === -1,
          );
        }
        if ( !this.mkmode.decision.products.celular ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Celular' ) === -1,
          );
        }
        this.setState( { categories } );
      },
    );
  };

  submitForm = ( formData, { change } ) => {
    const { toggleAlert, toggleLoading, intl } = this.props;
    const productions = [];
    _.map( formData.categories, ( category ) => {
      _.map( category.products, ( product ) => {
        if ( product.status === 'RD' && !product.active ) return;
        const data = {
          product: product.id,
          active: product.active,
          production: product.active ? product.production : 0,
        };
        productions.push( data );
      } );
    } );

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      productions,
    };
    ProductionService.updateProductionV2(
      this.activeSession.id,
      dataToSend,
    ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        if ( response.errors.productions !== undefined ) {
          return toggleAlert(
            <IntlMessages id={response.data.errors.productions[0].production} />,
          );
        }
        if ( this.productToDeactivate ) {
          change( `${this.productToDeactivate}.active`, true );
          this.productToDeactivate = null;
        }
        if ( this.productToActivate ) {
          change( `${this.productToActivate}.active`, false );
          this.productToActivate = null;
        }
        const messageErr = response.errors
          === 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET' ? (
            intl.formatMessage( {
              id:
                'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
            } )
          ) : (
            <IntlMessages id={response.errors} />
          );
        return toggleAlert( messageErr );
      }
      this.fieldModified = false;
      toggleAlert( 'dataSaved', 'info' );

      if ( this.showActiveMessage ) {
        this.showActiveMessage = false;
        this.productToActivate = null;
        this.setState( { confirmActivateModal: true } );
      }
      this.getData();
    } );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  toggleConfirmDeactivateModal = () => {
    const { confirmDeactivateModal } = this.state;
    this.setState( { confirmDeactivateModal: !confirmDeactivateModal } );
  };

  getStatus = ( product ) => {
    let txtLabel = '';
    if ( product.status === 'READY' && product.active ) txtLabel = 'lblActive';
    if ( product.status === 'READY' && !product.active ) txtLabel = 'ready';
    if ( product.status === 'CANCELED' ) txtLabel = 'CANCELLED';
    if ( product.status === 'RD' && !product.active ) txtLabel = 'R+D';
    return txtLabel;
  };

  toggleConfirmActivateModal = () => {
    const { confirmActivateModal } = this.state;
    this.setState( { confirmActivateModal: !confirmActivateModal } );
  };

  toggleProduct = ( id ) => {
    const { openedProducts } = this.state;
    const newOpenedProducts = [...openedProducts];
    const index = newOpenedProducts.indexOf( id );
    if ( index !== -1 ) {
      newOpenedProducts.splice( index, 1 );
    } else {
      newOpenedProducts.push( id );
    }
    this.setState( { openedProducts: newOpenedProducts } );
  };

  render() {
    const { history, user, intl } = this.props;
    const {
      categories,
      activeFilter,
      confirmSaveModal,
      confirmActivateModal,
      openedProducts,
      confirmDeactivateModal,
      confirmExitModal,
    } = this.state;
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'DecisionDashboard-products' },
          ]}
        />
        <KotlerSectionHeader
          title="products"
          image={HeaderImage}
          titleSubmit="save"
          titleCancel="cancelBtn"
          cancelTo="/"
          onSubmit={() => this.toggleConfirmSaveModal()}
        />
        <Grid
          container
          alignItems="center"
          className=" justify-content-between flex-lg-nowrap"
          style={{ columnGap: '24px' }}
        >
          <Grid item>
            <SectionInfoText title="help.product.snackbar.head" />
          </Grid>
          <Grid item lg="auto" xs={12}>
            <div
              className="d-flex justify-content-sm-between justify-content-center flex-wrap-reverse flex-md-nowrap align-items-center "
              style={{ rowGap: 20 }}
            >
              <div className="d-flex" style={{ minWidth: '250px' }}>
                <Switch
                  label="Showactive"
                  labelPlacement="start"
                  containerClass="d-flex justify-content-end text-right "
                  checked={activeFilter}
                  onChange={checked => this.setState( { activeFilter: checked }, this.getData )
                  }
                  marginContainer={false}
                  hover={{
                    id: activeFilter
                      ? 'product.showActiveToggle.hover'
                      : 'product.showDeactiveToggle.hover',
                  }}
                />
              </div>
              {user
                && user.activeSession
                && user.activeSession.status === STATUS.READY && (
                <HoverTitle id="product.realaunchProduct.hover">
                  <Button
                    style={{ minWidth: '190px' }}
                    variant="outlined"
                    type="button"
                    color="primary"
                    className="kotler-button-md my-3"
                    component={Link}
                    to="/sessions/general/products/copy"
                    disabled={activeFilter}
                  >
                    <IntlMessages id="relaunchProductBtn" />
                  </Button>
                </HoverTitle>
              )}
            </div>
          </Grid>
        </Grid>

        <Form
          initialValues={{ categories }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, values, dirty, form } ) => (
            <form onSubmit={handleSubmit}>
              <Prompt
                when={!this.canExit}
                message={( location ) => {
                  const { canExit } = this;
                  if ( dirty && !canExit ) {
                    this.goTo = location.pathname;
                    this.toggleConfirmExitModal();
                    return false;
                  }
                  return true;
                }}
              />
              <FieldArray name="categories">
                {data => data.fields.map( ( itemField, itemIndex ) => {
                  const item = data.fields.value[itemIndex];
                  return (
                    <div key={item.id}>
                      <Grid
                        container
                        wrap="nowrap"
                        className="kotler-table-container"
                      >
                        <Grid item>
                          <KotlerProductIcon
                            minHeight="123px"
                            img={
                              item.name === 'Laptops' ? 'Laptop' : item.name
                            }
                          />
                        </Grid>
                        <Grid item className="flex-grow-1">
                          <Grid container justify="center">
                            <Grid item xs={9} className="text-center ">
                              <KotlerTableTitle
                                disableIcon
                                titleClassName="ml-3"
                                /* category={item} */
                                title={
                                  item.name === 'Laptops'
                                    ? 'Laptop'
                                    : item.name
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className="d-flex  justify-content-end mb-2"
                            >
                              {user
                                  && user.activeSession
                                  && user.activeSession.status
                                    === STATUS.READY && (
                                <CategoryProductBtn
                                  category={item}
                                  onClick={() => history.push(
                                    `/sessions/general/products/${item.id}/new`,
                                  )
                                  }
                                />
                              )}
                            </Grid>

                            <Grid item xs={12}>
                              <Table size="small" className="kotler-table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ width: '12%' }}>
                                      <IntlMessages id="model" />
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: '9%' }}
                                    >
                                      <HoverTitle id="product.inventoryValue.hover">
                                        <span>
                                          <IntlMessages id="stock" />
                                        </span>
                                      </HoverTitle>
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: '8%' }}
                                    >
                                      <HoverTitle id="product.unitCostValue.hover">
                                        <span>
                                          <IntlMessages id="unitCost" />
                                        </span>
                                      </HoverTitle>
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: '9%' }}
                                    >
                                      <HoverTitle id="product.priceValue.hover">
                                        <span>
                                          <IntlMessages id="price" />
                                        </span>
                                      </HoverTitle>
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: '13%' }}
                                    >
                                      <span>
                                        <IntlMessages id="lastMonthSalesDollar" />
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: '13%' }}
                                    >
                                      <span>
                                        <IntlMessages id="lastMonthSalesUnits" />
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ width: '11%' }}
                                    >
                                      <span>
                                        <IntlMessages id="status" />
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ minWidth: '150px' }}
                                    >
                                      <HoverTitle id="product.productionValue.hover">
                                        <span>
                                          <IntlMessages id="production" />
                                        </span>
                                      </HoverTitle>
                                    </TableCell>
                                    <TableCell align="right">
                                      <span>
                                        <IntlMessages
                                          id="productionCost"
                                          style={{ width: '10%' }}
                                        />
                                      </span>
                                    </TableCell>
                                    <TableCell style={{ width: '4%' }}>
                                      {/*  <span><IntlMessages id="ProductDetails" /></span> */}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <FieldArray name={`${itemField}.products`}>
                                    {productsData => productsData.fields.map(
                                      ( productField, productIndex ) => {
                                        const product = categories[itemIndex].products[
                                          productIndex
                                        ];
                                        if ( !product ) return null;
                                        product.attributes = _.orderBy(
                                          product.attributes,
                                          ['order'],
                                          ['asc'],
                                        );
                                        const openedProduct = openedProducts.indexOf(
                                          product.id,
                                        ) !== -1;
                                        return [
                                          <TableRow key={product.id}>
                                            <TableCell className="border-0">
                                              {product.name}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className="border-0"
                                            >
                                              <HoverTitle id="product.inventoryValue.hover">
                                                <span>
                                                  {Util.formatNumber(
                                                    product.stock,
                                                  )}
                                                </span>
                                              </HoverTitle>
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className="border-0"
                                            >
                                              <HoverTitle id="product.unitCostValue.hover">
                                                <span>
                                                  {Util.formatCurrency(
                                                    product.unitCost,
                                                  )}
                                                </span>
                                              </HoverTitle>
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className="border-0"
                                            >
                                              <HoverTitle id="product.priceValue.hover">
                                                <span>
                                                  {Util.formatCurrency(
                                                    product.price,
                                                  )}
                                                </span>
                                              </HoverTitle>
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className="border-0"
                                            >
                                              {Util.formatNumber(
                                                product.lastRoundMoneySales,
                                              )}
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className="border-0"
                                            >
                                              {Util.formatNumber(
                                                product.lastRoundUnitSales,
                                              )}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              className="border-0"
                                            >
                                              <Switch
                                                label={this.getStatus(
                                                  product,
                                                )}
                                                hover={{
                                                  id: !product.active
                                                    ? 'product.activateDeactiveToggle.deactivate.hover'
                                                    : 'product.activateDeactiveToggle.hover',
                                                }}
                                                field={`${productField}.active`}
                                                marginContainer={false}
                                                disabled={
                                                  product.status
                                                        === 'CANCELED'
                                                      || ( product.status
                                                        === 'RD'
                                                        && !product.active )
                                                      || ( user
                                                        && user.activeSession
                                                        && user.activeSession
                                                          .status
                                                          !== STATUS.READY )
                                                }
                                                onChange={( checked ) => {
                                                  if ( !checked ) {
                                                    this.productToDeactivate = productField;
                                                    this.toggleConfirmDeactivateModal();
                                                  } else {
                                                    this.productToActivate = productField;
                                                    this.showActiveMessage = true;

                                                    this.setState( { confirmSaveModal: true } );
                                                  }
                                                }}
                                              />
                                              {/* eslint-disable */}
                                                </TableCell>
                                                <TableCell
                                                  align="center"
                                                  className="border-0"
                                                >
                                                  {product.status ===
                                                    "CANCELED" ||
                                                  (product.status === "RD" &&
                                                    !product.active) ? (
                                                    product.status === "RD" &&
                                                    !product.active ? (
                                                      <IntlMessages
                                                        id="availableOn"
                                                        values={{
                                                          round:
                                                            product
                                                              .availableInRound
                                                              .shortName
                                                        }}
                                                      />
                                                    ) : (
                                                      Util.formatNumber(
                                                        product.production
                                                      )
                                                    )
                                                  ) : (
                                                    <HoverTitle id="product.productionValue.hover">
                                                      <TextField
                                                        field={`${productField}.production`}
                                                        marginContainer={false}
                                                        type="number"
                                                        allowZero
                                                        disabled={
                                                          (user &&
                                                            user.activeSession &&
                                                            user.activeSession
                                                              .status !==
                                                              STATUS.READY) ||
                                                          !product.active
                                                        }
                                                        inputProps={{
                                                          className:
                                                            "text-right"
                                                        }}
                                                        validate={composeValidators(
                                                          required,
                                                          greaterOrEqualValue(
                                                            0
                                                          ),
                                                          lessOrEqualThanValue(
                                                            product.category
                                                              .name ===
                                                              "Cellular"
                                                              ? 2000000
                                                              : 1000000
                                                          )
                                                        )}
                                                        translateValues={{
                                                          value: 0,
                                                          value2:
                                                            product.category
                                                              .name ===
                                                            "Cellular"
                                                              ? 2000000
                                                              : 1000000
                                                        }}
                                                        onChange={value => {
                                                          const previousValue = _.get(
                                                            productsData.fields,
                                                            `value[${productIndex}].production`
                                                          );
                                                          if (
                                                            previousValue !==
                                                            value
                                                          ) {
                                                            this.setChangeStatus();
                                                          }
                                                        }}
                                                      />
                                                    </HoverTitle>
                                                  )}
                                                </TableCell>
                                                <TableCell
                                                  align="right"
                                                  className="border-0"
                                                >
                                                  {Util.formatCurrency(
                                                    _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].production`,
                                                      0
                                                    ) * product.unitCost
                                                  )}
                                                </TableCell>
                                                <TableCell
                                                  rowSpan={2}
                                                  style={{
                                                    verticalAlign: openedProduct
                                                      ? "bottom"
                                                      : "middle"
                                                  }}
                                                >
                                                  <div className="d-flex justify-content-end ">
                                                    <div>
                                                      <FeatherIcon
                                                        className={
                                                          openedProduct
                                                            ? "fa-rotate-180 "
                                                            : ""
                                                        }
                                                        style={{
                                                          border:
                                                            "2px solid #999FA3",
                                                          borderRadius: "2px",
                                                          cursor: "pointer"
                                                        }}
                                                        onClick={() =>
                                                          this.toggleProduct(
                                                            product.id
                                                          )
                                                        }
                                                        icon="chevronDown"
                                                        color="#999FA3"
                                                        size="1.5rem"
                                                      />
                                                    </div>
                                                  </div>
                                                </TableCell>
                                              </TableRow>,

                                              <TableRow
                                                key={`${product.id}-detail`}
                                                className={`bg-white  ${
                                                  openedProduct ? "show" : ""
                                                }`}
                                              >
                                                <TableCell padding="none" />
                                                <TableCell
                                                  padding="none"
                                                  colSpan={8}
                                                >
                                                  <Collapse in={openedProduct}>
                                                    <Row className="d-flex justify-content-around">
                                                      {/* eslint-disable-next-line max-len */}
                                                      {_.map(
                                                        product.attributes,
                                                        (attribute, index) => (
                                                          <Col
                                                            xs="auto"
                                                            key={`${attribute.label}${index}`}
                                                          >
                                                            <Row>
                                                              {/* eslint-disable-next-line max-len */}
                                                              <Col
                                                                style={{
                                                                  fontSize:
                                                                    "12px",
                                                                  fontWeight: 600,
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center"
                                                                }}
                                                                className="text-dark"
                                                              >
                                                                {/* eslint-disable-next-line max-len */}
                                                                <PropertyIcon
                                                                  propertyName={
                                                                    attribute.label
                                                                  }
                                                                />
                                                                <IntlMessages
                                                                  id={
                                                                    attribute.label
                                                                  }
                                                                />
                                                              </Col>
                                                            </Row>
                                                            <Row>
                                                              <Col
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                  fontWeight: 500
                                                                }}
                                                              >
                                                                {intl.formatMessage(
                                                                  {
                                                                    id: `${
                                                                      attribute.valueTextDesc
                                                                        .trim()
                                                                        .split(
                                                                          " ("
                                                                        )[0]
                                                                    } value`
                                                                  },
                                                                  {
                                                                    value: `(${
                                                                      attribute.valueTextDesc
                                                                        .trim()
                                                                        .split(
                                                                          " ("
                                                                        )[1]
                                                                    }`
                                                                  }
                                                                )}
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        )
                                                      )}
                                                    </Row>
                                                  </Collapse>
                                                </TableCell>
                                              </TableRow>
                                            ];
                                          }
                                        )
                                      }
                                    </FieldArray>
                                  </TableBody>
                                </Table>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {itemIndex === categories.length - 1 && (
                          <Grid container justify="flex-end">
                            <Grid item>
                              {" "}
                              <span
                                style={{ fontSize: 14 }}
                                className=" text-dark font-weight-bold border-none  mr-md-5"
                              >
                                Total:{" "}
                                {Util.formatCurrency(
                                  _.chain(values.categories)
                                    .map("products")
                                    .flatten()
                                    .sumBy(
                                      prod =>
                                        prod.unitCost * (prod.production || 0)
                                    )
                                    .value()
                                )}
                              </span>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    );
                  })
                }
              </FieldArray>
              {
                // <HelpSnackBar message="help.product.snackbar" />
              }

              <ConfirmCancelButtons
                containerClass="mt-3"
                titleSubmit="save"
                titleCancel="cancelBtn"
                cancelTo="/"
                onSubmit={() => this.toggleConfirmSaveModal()}
              />

              <ConfirmationDialog
                open={confirmSaveModal && !confirmDeactivateModal}
                title="confirmSaveProductProduction"
                showIcon
                type="save"
                confirmBtnText="save"
                closeBtnText="cancelBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  handleSubmit();
                }}
                onClose={() => {
                  this.showActiveMessage = false;
                  form.change(`${this.productToActivate}.active`, false);
                  form.change(`${this.productToDeactivate}.active`, true);
                  this.productToActivate = null;
                  this.toggleConfirmSaveModal();
                }}
              />

              <ConfirmationDialog
                open={confirmDeactivateModal}
                subTitle="confirmDeactivateProduct"
                type="warning"
                confirmBtnText="ok"
                closeBtnText="cancelBtn"
                closeOnClickOutside={false}
                onSubmit={() => {
                  this.toggleConfirmDeactivateModal();
                  this.toggleConfirmSaveModal();
                }}
                onClose={() => {
                  form.change(`${this.productToDeactivate}.active`, true);
                  this.toggleConfirmDeactivateModal();
                }}
              />

              <ConfirmationDialog
                open={confirmActivateModal}
                subTitle="messageAfterActivatingProduct"
                confirmBtnText="ok"
                showCancel={false}
                closeOnClickOutside
                onSubmit={this.toggleConfirmActivateModal}
                onClose={() => {
                  // form.change( `${this.productToActivate}.active`, false );
                  this.toggleConfirmActivateModal();
                }}
              />

              <ConfirmationDialog
                open={confirmExitModal}
                title="confirmExitWithOutSavingProducts"
                subTitle="subtitleExitWithoutSaving"
                confirmBtnText="yesModalBtn"
                closeBtnText="cancelBtn"
                closeOnClickOutside={false}
                allowEscape={false}
                onSubmit={() => {
                  this.toggleConfirmExitModal();
                  if (!this.isEscapeKey) {
                    this.setConfirmExitModal();
                  }
                  this.isEscapeKey = false;
                }}
                onClose={this.toggleConfirmExitModal}
              />
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Products));
