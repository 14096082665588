import api from '../config/Api';

const endpoint = 'v1/global-variable';
const endpoint2 = 'v2/global-variable';

export default {

  getVariables( params ) {
    return api.get( endpoint, params );
  },

  getMaxStudents() {
    return api.get( `${endpoint2}/max-students` );
  },

  getMarketingBudget() {
    return api.get( `${endpoint2}/marketing-budget` );
  },

  getMaxGroups() {
    return api.get( `${endpoint2}/max-groups` );
  },

  updateVariables( data ) {
    return api.put( endpoint, data );
  },
};
