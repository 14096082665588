import React from 'react';
import { InputAi, useManualAiStore } from '..';

export const ManualAi = () => {
  const { show } = useManualAiStore();

  return (
    <div
      className="d-flex flex-column  pb-3 border-grey w-100 border-bottom "
      style={{
        gap: '10px',
        position: show ? 'sticky' : undefined,
        top: 0,
        zIndex: 4,
      }}
    >
      <InputAi />
    </div>
  );
};

export default ManualAi;
