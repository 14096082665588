import React from 'react';
import { connect } from 'react-redux';

import {
  Dialog,
  Slide,
  DialogContent,
  TableBody,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core';
import CardBox from 'components/custom_v2/CardBox';
import IntlMessages from 'utils/IntlMessages';
import _ from 'lodash';
import Util from 'utils/Util';
import LogService from 'services/Log';
import SettingsActions from 'store/reducers/Settings';
import KotlerHeaderFullWidthModal from 'components/custom/KotlerHeaderFullWidthModal';

class CompModal extends React.Component {
  state = { data: [] };
  componentDidMount() {
    // this.loadData();
  }
  componentDidUpdate( prevProps ) {
    const { workgroupId } = this.props;
    if ( workgroupId !== prevProps.workgroupId ) {
      this.loadData();
    }
  }
  loadData = () => {
    const { user, toggleLoading, workgroupId } = this.props;
    toggleLoading( true );
    LogService.getActionsByStudents( user.activeSession.id, workgroupId ).then(
      ( response ) => {
        if ( response.ok ) {
          this.setState( { data: response.data.data || [] } );
        }
        toggleLoading( false );
      },
    );
  };

  render() {
    const { open, onClose } = this.props;
    const { data } = this.state;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
      >
        <KotlerHeaderFullWidthModal {...{ title: 'detail', onClose }} />
        <DialogContent className="px-2">
          <CardBox heading="group" styleName="px-2 ">
            <div style={{ overflowX: 'auto' }}>
              <Table className="kotler-table">
                {data.length > 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ width: '15%' }}>&nbsp;</TableCell>
                      {_.map( data, item => (
                        <TableCell
                          className="text-center text-primary"
                          key={item.id}
                        >
                          <b>
                            {item.name}
                            &nbsp;
                            {item.lastName}
                          </b>
                        </TableCell>
                      ) )}
                      <TableCell className="text-center text-primary">
                        <b>
                          <IntlMessages id="TOTAL" />
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-primary"
                        colSpan={_.size( data ) + 2}
                      >
                        <b>
                          <IntlMessages id="activity.product" />
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PRODUCT_CREATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PRODUCT_CREATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PRODUCT_CREATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_CREATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'PRODUCT_CREATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PRODUCT_DUPLICATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PRODUCT_DUPLICATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PRODUCT_DUPLICATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_DUPLICATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'PRODUCT_DUPLICATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PRODUCT_DEACTIVATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PRODUCT_DEACTIVATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PRODUCT_DEACTIVATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_DEACTIVATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'PRODUCT_DEACTIVATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PRODUCT_ACTIVATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PRODUCT_ACTIVATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PRODUCT_ACTIVATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_ACTIVATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'PRODUCT_ACTIVATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PRODUCT_PRODUCTION_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PRODUCT_PRODUCTION_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PRODUCT_PRODUCTION_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_PRODUCTION_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'PRODUCT_PRODUCTION_CHANGED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PRODUCT_PRICE_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PRODUCT_PRICE_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PRODUCT_PRICE_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_PRICE_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'PRODUCT_PRICE_CHANGED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PRODUCT_TRADE_SALE_PRICE_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PRODUCT_TRADE_SALE_PRICE_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PRODUCT_TRADE_SALE_PRICE_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_TRADE_SALE_PRICE_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, {
                                    action:
                                          'PRODUCT_TRADE_SALE_PRICE_CHANGED',
                                  } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-primary"
                        colSpan={_.size( data ) + 2}
                      >
                        <b>
                          <IntlMessages id="activity.serviceAndBundle" />
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="SERVICE_ACTIVATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'SERVICE_ACTIVATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'SERVICE_ACTIVATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_ACTIVATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'SERVICE_ACTIVATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="SERVICE_DEACTIVATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'SERVICE_DEACTIVATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'SERVICE_DEACTIVATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_DEACTIVATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'SERVICE_DEACTIVATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="SERVICE_PRICE_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'SERVICE_PRICE_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'SERVICE_PRICE_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_PRICE_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'SERVICE_PRICE_CHANGED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="SERVICE_TRADE_SALE_PRICE_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'SERVICE_TRADE_SALE_PRICE_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'SERVICE_TRADE_SALE_PRICE_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_TRADE_SALE_PRICE_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, {
                                    action:
                                          'SERVICE_TRADE_SALE_PRICE_CHANGED',
                                  } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="BUNDLE_CREATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'BUNDLE_CREATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'BUNDLE_CREATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_CREATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'BUNDLE_CREATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="BUNDLE_ACTIVATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'BUNDLE_ACTIVATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'BUNDLE_ACTIVATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_ACTIVATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'BUNDLE_ACTIVATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="BUNDLE_DEACTIVATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'BUNDLE_DEACTIVATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'BUNDLE_DEACTIVATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_DEACTIVATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'BUNDLE_DEACTIVATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="BUNDLE_PRICE_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'BUNDLE_PRICE_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'BUNDLE_PRICE_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_PRICE_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'BUNDLE_PRICE_CHANGED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        className="text-primary"
                        colSpan={_.size( data ) + 2}
                      >
                        <b>
                          <IntlMessages id="activity.distribution" />
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PRODUCT_POP_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PRODUCT_POP_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PRODUCT_POP_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PRODUCT_POP_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'PRODUCT_POP_CHANGED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="SERVICE_POP_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'SERVICE_POP_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'SERVICE_POP_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'SERVICE_POP_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'SERVICE_POP_CHANGED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="BUNDLE_POP_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'BUNDLE_POP_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'BUNDLE_POP_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_POP_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'BUNDLE_POP_CHANGED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="BUNDLE_TRADE_SALE_PRICE_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'BUNDLE_TRADE_SALE_PRICE_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'BUNDLE_TRADE_SALE_PRICE_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'BUNDLE_TRADE_SALE_PRICE_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, {
                                    action:
                                          'BUNDLE_TRADE_SALE_PRICE_CHANGED',
                                  } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    {
                      // <TableRow >
                      //   <TableCell style={{ width: '10%' }}>
                      //     <IntlMessages id="BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED" />
                      //   </TableCell>
                      //   {_.map( data, item => (
                      //     <TableCell className="text-center">
                      //       {Util.formatNumber( _.findIndex( item.actions,
                      // { action: 'BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED' } ) !== -1
                      // ? item.actions[_.findIndex( item.actions,
                      // { action: 'BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED' } )].quantity : 0 ) }
                      //     </TableCell>
                      //   ) )}
                      //   <TableCell className="text-center">
                      //     {Util.formatNumber( _.sum( _.map( data, item =>
                      // ( _.findIndex( item.actions,
                      // { action: 'BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED'
                      // } ) !== -1 ? Number( item.actions[_.findIndex( item.actions,
                      // { action: 'BUNDLE_MONTHLY_TRADE_SALE_FEE_CHANGED' } )
                      // ].quantity ) : 0 ) ) ) ) }
                      //   </TableCell>
                      // </TableRow>
                    }
                    <TableRow>
                      <TableCell
                        className="text-primary"
                        colSpan={_.size( data ) + 2}
                      >
                        <b>
                          <IntlMessages id="activity.cx" />
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="CEM_CHANGED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'CEM_CHANGED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'CEM_CHANGED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'CEM_CHANGED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'CEM_CHANGED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-primary"
                        colSpan={_.size( data ) + 2}
                      >
                        <b>
                          <IntlMessages id="activity.communications" />
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="CAMPAIGN_COMMUNICATION_CREATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'CAMPAIGN_COMMUNICATION_CREATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'CAMPAIGN_COMMUNICATION_CREATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'CAMPAIGN_COMMUNICATION_CREATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'CAMPAIGN_COMMUNICATION_CREATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-primary"
                        colSpan={_.size( data ) + 2}
                      >
                        <b>
                          <IntlMessages id="activity.marketing1to1" />
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="CAMPAIGN_CRM_CREATED" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'CAMPAIGN_CRM_CREATED' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'CAMPAIGN_CRM_CREATED' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'CAMPAIGN_CRM_CREATED' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'CAMPAIGN_CRM_CREATED' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-primary"
                        colSpan={_.size( data ) + 2}
                      >
                        <b>
                          <IntlMessages id="activity.research" />
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>
                        <IntlMessages id="PURCHASED_REPORTS" />
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell className="text-center" key={item.id}>
                          {Util.formatNumber(
                            _.findIndex( item.actions, { action: 'PURCHASED_REPORT' } ) !== -1
                              ? item.actions[
                                _.findIndex( item.actions, { action: 'PURCHASED_REPORT' } )
                              ].quantity
                              : 0,
                          )}
                        </TableCell>
                      ) )}
                      <TableCell className="text-center">
                        {Util.formatNumber(
                          _.sum(
                            _.map( data, item => ( _.findIndex( item.actions, { action: 'PURCHASED_REPORT' } ) !== -1
                              ? Number(
                                item.actions[
                                  _.findIndex( item.actions, { action: 'PURCHASED_REPORT' } )
                                ].quantity,
                              )
                              : 0 ) ),
                          ),
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ height: '30px' }}>
                      {/*  <TableCell className="text-primary" colSpan={_.size( data ) + 2}>
                        <b>&nbsp;</b>
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-primary"
                        style={{ width: '10%' }}
                      >
                        <b>
                          <IntlMessages id="activity.totalActivityPerGroup" />
                        </b>
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell
                          className="text-center text-primary"
                          key={item.id}
                        >
                          <b>{Util.formatNumber( item.total )}</b>
                        </TableCell>
                      ) )}
                      <TableCell className="text-center text-primary">
                        <b>
                          {Util.formatNumber(
                            _.sum( _.map( data, item => item.total ) ),
                          )}
                        </b>
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ height: '30px' }}>
                      {/*    <TableCell style={{ height: '30px' }}>
                        &nbsp;
                      </TableCell> */}
                    </TableRow>

                    <TableRow>
                      <TableCell
                        className="text-primary"
                        style={{ width: '10%' }}
                      >
                        <b>
                          <IntlMessages id="activity.totalLogin" />
                        </b>
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell
                          className="text-center text-primary"
                          key={item.id}
                        >
                          <b>{Util.formatNumber( item.incomeSystems )}</b>
                        </TableCell>
                      ) )}
                      <TableCell className="text-center text-primary">
                        <b>
                          {Util.formatNumber(
                            _.sum( _.map( data, item => item.incomeSystems ) ),
                          )}
                        </b>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        className="text-primary"
                        style={{ width: '10%' }}
                      >
                        <b>
                          <IntlMessages id="activity.screen_toured" />
                        </b>
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell
                          className="text-center text-primary"
                          key={item.id}
                        >
                          <b>{Util.formatNumber( item.screenToured )}</b>
                        </TableCell>
                      ) )}
                      <TableCell className="text-center text-primary">
                        <b>
                          {Util.formatNumber(
                            _.sum( _.map( data, item => item.screenToured ) ),
                          )}
                        </b>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        className="text-primary"
                        style={{ width: '10%' }}
                      >
                        <b>
                          <IntlMessages id="activity.time_in_system" />
                        </b>
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell
                          className="text-center text-primary"
                          key={item.id}
                        >
                          <b>{Util.formatNumber( item.timeInSystem )}</b>
                        </TableCell>
                      ) )}
                      <TableCell className="text-center text-primary">
                        <b>
                          {Util.formatNumber(
                            _.sum( _.map( data, item => item.timeInSystem ) ),
                          )}
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-primary"
                        style={{ width: '10%' }}
                      >
                        <b>
                          <IntlMessages id="activity.percMemoIndDelivered" />
                        </b>
                      </TableCell>
                      {_.map( data, item => (
                        <TableCell
                          className="text-center text-primary"
                          key={item.id}
                        >
                          <b>{Util.formatNumber( item.percMemoIndDelivered )}</b>
                        </TableCell>
                      ) )}
                      <TableCell className="text-center text-primary">
                        <b>
                          {Util.formatNumber(
                            _.sum(
                              _.map( data, item => item.percMemoIndDelivered ),
                            ),
                          )}
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>
          </CardBox>
        </DialogContent>
      </Dialog>
    );
  }
}
const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};
const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( CompModal );
