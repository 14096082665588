import { Box, Typography } from "@material-ui/core";
import CategoryAvatar from "components/custom_v2/CategoryAvatar";
import React from "react";
import IntlMessages from "utils/IntlMessages";
import FeatherIcon from "../FeatherIcon";
import service from "assets/images/kotler/icons/icon-service.png";
import bag from "assets/images/kotler/icons/icon-bag.png";
const KotlerTableTitle = ({ disableIcon=false,category, icon,titleClassName, title,titleValues, iconClass, fontSize,fontColor = "textPrimary", noTranslate ,className}) => {
  return !category ? (
    <Box className={`kotler-table-title-container ${className}`}>
     {!disableIcon && <> {icon !== "services" && icon !== "bag" ? (
        <div className={`kotler-table-title-icon ${iconClass}`}>
          <FeatherIcon icon={icon} />
        </div>
      ) : (
        <img
          className={`kotler-table-title-icon-img ${iconClass}`}
          alt="service-icon"
          src={icon === "services" ? service : bag}
        />
      )}</>}
      <Typography color={fontColor} style={{ fontSize: fontSize || "21px" }} className={`kotler-table-title ${titleClassName || ""}`}>
        {noTranslate ? title : <IntlMessages id={title} values={titleValues}/>}
      </Typography>
    </Box>
  ) : (
    <Box className="kotler-table-title-container">
      {!disableIcon && <CategoryAvatar category={category} />}
      <Typography color={fontColor} style={{ fontSize: fontSize || "21px" }} className={`kotler-table-title ${titleClassName || ""}`}>
        {noTranslate ? title : <IntlMessages id={title} />}
      </Typography>
    </Box>
  );
};
export default KotlerTableTitle;
