import React from 'react';
import { Col, Row } from 'reactstrap';
import IntlMessages from 'utils/IntlMessages';


const DetailReport = ( { data, type } ) => (
  ( type === 'initialStrategicPlan' ? (
    <div>
      <Row>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="generalStrategy" /></b>
          <br />
          {data[1] ? data[1] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="products" /></b>
          <br />
          {data[2] ? data[2] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="segments" /></b>
          <br />
          {data[3] ? data[3] : ' - '}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="services" /></b>
          <br />
          {data[4] ? data[4] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="catchment" /></b>
          <br />
          {data[5] ? data[5] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="prices" /></b>
          <br />
          {data[6] ? data[6] : ' - '}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="retention" /></b>
          <br />
          {data[7] ? data[7] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="distribution" /></b>
          <br />
          {data[8] ? data[8] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="profitability" /></b>
          <br />
          {data[9] ? data[9] : ' - '}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="cx" /></b>
          <br />
          {data[10] ? data[10] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="referencing" /></b>
          <br />
          {data[11] ? data[11] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="communications" /></b>
          <br />
          {data[12] ? data[12] : ' - '}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="reactivation" /></b>
          <br />
          {data[13] ? data[13] : ' - '}
        </Col>
      </Row>
    </div>
  ) : ( type === 'individualSemesterMemo' ? (
    <div>
      <Row>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="understanding" /></b>
          <br />
          {data[1] ? data[1] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="productStrategy" /></b>
          <br />
          {data[2] ? data[2] : ' - '}
        </Col>
        <Col md={4} lg={4} className="text-left">
          <b><IntlMessages id="customerStrategy" /></b>
          <br />
          {data[3] ? data[3] : ' - '}
        </Col>
      </Row>
    </div>
  ) : ( type === 'finalStrategicReport'
    ? (
      <div>
        <Row>
          <Col md={4} lg={4} className="text-left">
            <b><IntlMessages id="understanding" /></b>
            <br />
            {data[1] ? data[1] : ' - '}
          </Col>
          <Col md={4} lg={4} className="text-left">
            <b><IntlMessages id="productHits" /></b>
            <br />
            {data[2] ? data[2] : ' - '}
          </Col>
          <Col md={4} lg={4} className="text-left">
            <b><IntlMessages id="customerHits" /></b>
            <br />
            {data[3] ? data[3] : ' - '}
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4} className="text-left">
            <b><IntlMessages id="productErrors" /></b>
            <br />
            {data[4] ? data[4] : ' - '}
          </Col>
          <Col md={4} lg={4} className="text-left">
            <b><IntlMessages id="customerErrors" /></b>
            <br />
            {data[5] ? data[5] : ' - '}
          </Col>
          <Col md={4} lg={4} className="text-left">
            <b><IntlMessages id="learningPersonalActivity" /></b>
            <br />
            {data[6] ? data[6] : ' - '}
          </Col>
        </Row>
      </div>
    ) : null ) ) ) );

export default DetailReport;
