import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import CardBox from 'components/custom_v2/CardBox';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';

const CrossTable = ( { headerTitle, subtitleId, data, header, widthColum } ) => (
  <Grid item xs={12}>
    <CardBox
      styleName="px-2 pb-0 mb-0"
      header={<KotlerCardHeader minHeight="0px" title={headerTitle} />}
    >
      <h5>
        &nbsp;&nbsp;
        <IntlMessages id={subtitleId} />
      </h5>
      <div className="table-responsive">
        {header && data && data.length > 0 && (
          <Table className="kotler-table">
            <TableHead>
              <TableRow>
                <TableCell
                  scope="col"
                  style={{
                    fontWeight: 'bolder',
                    width: `${widthColum}%`,
                  }}
                >
                  &nbsp;
                </TableCell>
                {_.map( header, ( item, index ) => (
                  <TableCell align="right" key={index}>
                    {['music', 'news', 'movies'].includes( item ) ? (
                      <IntlMessages id={item} />
                    ) : (
                      item
                    )}
                  </TableCell>
                ) )}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map( data, ( prod, index ) => (
                <TableRow key={index}>
                  <TableCell>
                    {['music', 'news', 'movies'].includes( prod.product ) ? (
                      <IntlMessages id={prod.product} />
                    ) : (
                      prod.product
                    )}
                  </TableCell>
                  {_.map( prod.values, ( item, index2 ) => (
                    <TableCell
                      align="right"
                      key={index2}
                      style={{ color: item.product === prod.product ? '#003594' : null }}
                    >
                      {Util.formatNumber( item.value )}
                    </TableCell>
                  ) )}
                </TableRow>
              ) )}
            </TableBody>
          </Table>
        )}
      </div>
    </CardBox>
  </Grid>
);

CrossTable.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  subtitleId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape( {
      product: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.shape( {
          product: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        } ),
      ).isRequired,
    } ),
  ).isRequired,
  header: PropTypes.arrayOf( PropTypes.string ).isRequired,
  widthColum: PropTypes.number.isRequired,
};

export default CrossTable;
