// Importamos las funciones necesarias desde los módulos correspondientes
import { formatMessage, formatRoundName } from 'helpers/IntlHelpers';
import { filter, flatMap, isObject } from 'lodash';

// Función para formatear los datos de un paquete (bundle)
const formatBundleData = ( bundle ) => {
  // Usamos flatMap para crear un array de valores de venta unitaria y total,
  // con '-' en lugar de 0 si el valor es 0
  const arr = bundle.bundleData.flatMap( itemD => [
    itemD.unitSales === 0 ? '-' : itemD.unitSales,
    itemD.totalSales === 0 ? '-' : itemD.totalSales,
  ] );
  // Devolvemos un array que contiene el nombre del paquete y los datos formateados
  return [bundle.name, ...arr];
};

// Función para formatear las etiquetas de las rondas
const formatRoundsLabels = bundlesData => flatMap( bundlesData, ( { round } ) => [
  {
    // Formateamos el nombre corto de la ronda
    value: formatRoundName( round.shortName ),
    style: { alignment: { horizontal: 'right' }, font: { bold: true } },
  },
] );

// Función para formatear las etiquetas de las rondas con títulos
export const formatRoundLabelsTitle = ( roundlabels = [], title = '' ) => [
  {
    // Agregamos el título
    value: title,
    style: { font: { bold: true } },
  },
  ...flatMap( roundlabels, ( cell ) => {
    if ( isObject( cell ) ) {
      // Si es un objeto, agregamos '(u)' y '($)' a los valores
      return [
        { ...cell, value: `${cell.value} (u)` },
        { ...cell, value: `${cell.value} ($)` },
      ];
    }
    // Si es una cadena, agregamos '(u)' y '($)' a la cadena
    return [`${cell} (u)`, `${cell} ($)`];
  } ),
];

// Función para filtrar grupos que no tienen paquetes (bundles)
const filterGroupsNoBundles = group => filter( group, ( { bundles } ) => !!bundles.length );

// Función para formatear los valores de las rondas
const formatRoundsValues = ( data ) => {
  // Filtramos los grupos que tienen paquetes
  const groups = filterGroupsNoBundles( data );
  return flatMap( groups, ( { bundles, workGroupName } ) => {
    const bundle1 = bundles[0].bundleData;
    const roundsLabel = formatRoundsLabels( bundle1 );
    const labelRow = formatRoundLabelsTitle( roundsLabel, workGroupName );
    const dataRows = bundles.map( formatBundleData );
    // Devolvemos un array que contiene las etiquetas de la ronda, los datos formateados
    // y una fila vacía
    return [labelRow, ...dataRows, []];
  } );
};

// Función para formatear todos los paquetes de rondas en formato Excel
export const formatAllRoundsBundlesToExcel = (
  data,
  roundlabels,
  title = 'market.bundles_information',
) => {
  const sheetData = {
    xSteps: 0,
    ySteps: 1,
    columns: [formatMessage( { id: title } )],
    data: [],
  };

  // Verificamos si hay datos, si no, devolvemos el array vacío
  if ( !data || !data.length ) return [sheetData];

  // Formateamos los valores de las rondas
  const dataToExcel = formatRoundsValues( data, roundlabels );

  // Agregamos los datos formateados al objeto de la hoja de Excel y lo devolvemos
  sheetData.data = dataToExcel;
  return [sheetData];
};
