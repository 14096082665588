import {
  FETCH_LAST_ROUND_BUNDLES_REQUEST,
  FETCH_LAST_ROUND_BUNDLES_SUCCESS,
  FETCH_LAST_ROUND_BUNDLES_FAILURE,
  CLEAR_LAST_ROUND_BUNDLES,
} from '../actions/LastRoundBundlesActions';

const initialState = { data: null, loading: false, error: null };

const lastRoundBundlesReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_LAST_ROUND_BUNDLES_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case FETCH_LAST_ROUND_BUNDLES_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: null,
    };
  case FETCH_LAST_ROUND_BUNDLES_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case CLEAR_LAST_ROUND_BUNDLES:
    return {
      ...state,
      ...initialState,
    };
  default:
    return state;
  }
};

export default lastRoundBundlesReducer;
