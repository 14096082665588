import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import classNames from 'classnames';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

import IntlMessages from 'utils/IntlMessages';

function DatePickerComp( {
  adornRequired=false,append, dateFormat, timeFormat, formatDate, field, prefix, suffix, label,disabled=false,
                           marginContainer = true, onChange, placeholder, intl, validate, translateValues, ...rest
                         } ) {
  function renderInput( props, meta ) {
    function clear() {
      props.onChange( { target: { value: '' } } );
    }

    return (
      <div>
       <TextField {...props}
        className="kotler-text-field-primary2"
        variant="outlined"
        fullWidth
                            error={meta.touched && !!meta.error}
                            label={null}/>
        {props.value ?
          <div className="text-muted pointer">
            <i onClick={clear} className="fa fa-times"
               style={{
                 position: 'absolute',
                 bottom: 5,
                 right: 5,
               }}/>
          </div>
          : null}
      </div>
    );
  }

  return (
    <Field name={field} validate={validate}>
      {( { meta, input } ) => {
        return (
          <FormControl error={meta.touched && !!meta.error}
                       className={classNames( 'w-100 mt-custom kotler-text-field-form-control', { 'mb-4': marginContainer } )}>
            {label ? (
            <label /* className={labelClass} */>
              <IntlMessages id={label} />{adornRequired && <span className="text-danger">*</span>}
            </label>
          ) : (
            ''
          )}
            {append ?
              <div className="input-group" id={field}>
                {prefix ? <span className="input-group-addon"
                                dangerouslySetInnerHTML={{ __html: prefix }}/> : null}
                <DatePicker
                className='kotler-date-picker'
                  {...input}
                  {...rest} 
                  closeOnSelect
                  error={meta.touched && !!meta.error}
                  renderInput={( props ) => renderInput( props, meta )}
                  value={input.value ? moment( Number( input.value ) )
                    .format( dateFormat || formatDate || 'DD/MM/YYYY' ) : ''}
                  onChange={( value ) => {
                    input.onChange( value );
                    if (onChange) onChange( value );
                  }}
                  timeFormat={timeFormat ? timeFormat : false}
                  dateFormat={dateFormat ? dateFormat : 'DD/MM/YYYY'}
                  inputProps={{ placeholder: placeholder ? intl.formatMessage( { id: placeholder } ) : "",disabled}
                
                }
                />
                {suffix ? <span className="input-group-addon"
                                dangerouslySetInnerHTML={{ __html: suffix }}/> : null}
              </div> :
              <DatePicker
              className='kotler-date-picker'
                {...input}
                {...rest}
                closeOnSelect
                renderInput={( props ) => renderInput( props, meta )}
                value={input.value ? moment( Number( input.value ) )
                  .format( dateFormat || formatDate || 'DD/MM/YYYY' ) : ''}
                onChange={( value ) => {
                  input.onChange( value );
                  if (onChange) onChange( value );
                }}
                timeFormat={timeFormat ? timeFormat : false}
                dateFormat={dateFormat ? dateFormat : 'DD/MM/YYYY'}
                inputProps={{ placeholder: placeholder ? intl.formatMessage( { id: placeholder } ) : "",disabled}}
              />
            }
            {meta.touched && meta.error
            && (
              <FormHelperText>
                <IntlMessages id={meta.error} values={translateValues}/>
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
}

export default DatePickerComp;
