import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import CardBox from 'components/custom_v2/CardBox';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';

export default ( { summary, mkmode, data } ) => {
  const mediaAttributes = _.filter( data.mediaAttributes, { selected: true } );
  const { massiveResponse } = data;
  const internetPlans = _.filter(
    data.internetPlans,
    item => ( item.regions && item.regions.length )
      || item.gender
      || item.ageFrom
      || item.ageTo
      || ( item.body && item.body.length )
      || item.monthlyInvestment,
  );

  const promotePurchasing = (
    <div style={{ display: 'flex' }}>
      {data.promotePurchasing ? (
        <FeatherIcon
          icon="check"
          color="#2CD5C4"
          size="30px"
          style={{
            backgroundColor: '#D5F7F3',
            borderRadius: '2px',
            padding: '4px',
          }}
        />
      ) : (
        <FeatherIcon
          size="30px"
          icon="x"
          color="#F56954"
          style={{
            backgroundColor: '#FFC7BE',
            borderRadius: '2px',
            padding: '4px',
          }}
        />
      )}
    </div>
  );

  const getPropertyRender = ( label, key, value ) => (
    <TableRow>
      <TableCell>
        <IntlMessages id={label} />
      </TableCell>
      <TableCell>
        {value || ( _.get( data, key ) ? _.get( data, key ) : '-' )}
      </TableCell>
    </TableRow>
  );

  const getTranslatePropertyRender = ( label, key, value ) => (
    <TableRow>
      <TableCell>
        <IntlMessages id={label} />
      </TableCell>

      <TableCell>
        {value ? (
          <IntlMessages id={value} />
        ) : _.get( data, key ) ? (
          <IntlMessages id={_.get( data, key )} />
        ) : (
          '-'
        )}
        {' '}
      </TableCell>
    </TableRow>
  );

  const getTranslateArrayRender = ( label, value ) => (
    <TableRow>
      <TableCell>
        <IntlMessages id={label} />
      </TableCell>
      <TableCell>
        {_.map( value, ( item, index ) => (
          <span key={item.id}>
            <IntlMessages id={item.id} />
            {index < value.length - 1 ? ', ' : ''}
          </span>
        ) )}
      </TableCell>
    </TableRow>
  );

  return summary ? (
    <CarDetail>
      <TableDetail title="campaignSummary">
        <>
          {getPropertyRender( 'name', 'name' )}
          {getPropertyRender( 'startDate', 'fromRound' )}
          {getPropertyRender( 'endDate', 'toRound' )}
          {getTranslatePropertyRender( 'mainObjective', 'contentType' )}
          {data.contentType === 'PRODUCT' && (
            <>
              {getPropertyRender( 'product', 'product' )}
              {getPropertyRender(
                'attributes',
                null,
                _.map( data.productFeature, 'id' ).join( ', ' ),
              )}
              {getPropertyRender(
                'promotePurchasingDirectChannel',
                null,
                promotePurchasing,
              )}
            </>
          )}
          {data.contentType === 'SERVICE' && (
            <>
              {getPropertyRender( 'service', 'service' )}
              {getPropertyRender(
                'attributes',
                null,
                _.map( data.serviceFeature, 'id' ).join( ', ' ),
              )}
              {getPropertyRender(
                'promotePurchasingDirectChannel',
                null,
                promotePurchasing,
              )}
            </>
          )}
          {data.contentType === 'BUNDLE' && (
            <>
              {getPropertyRender( 'bundle', 'bundle' )}
              {getPropertyRender(
                'promotePurchasingDirectChannel',
                null,
                promotePurchasing,
              )}
            </>
          )}
        </>
      </TableDetail>
    </CarDetail>
  ) : (
    <Grid
      container
      justify="space-between"
      style={{ rowGap: '20px', paddingBottom: '20px', margin: '0px 5px' }}
    >
      <Grid item xs={12} sm={6} lg={3}>
        <CarDetail>
          <TableDetail title="campaign">
            <>
              {getPropertyRender( 'name', 'name' )}
              {getPropertyRender( 'startDate', 'fromRound' )}
              {getPropertyRender( 'endDate', 'toRound' )}
              {getTranslatePropertyRender( 'mainObjective', 'contentType' )}
              {data.contentType === 'PRODUCT' && (
                <>
                  {getPropertyRender( 'product', 'product' )}
                  {getPropertyRender(
                    'attributes',
                    null,
                    _.map( data.productFeature, 'id' ).join( ', ' ),
                  )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}
              {data.contentType === 'SERVICE' && (
                <>
                  {getPropertyRender( 'service', 'service' )}
                  {getPropertyRender(
                    'attributes',
                    null,
                    _.map( data.serviceFeature, 'id' ).join( ', ' ),
                  )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}
              {data.contentType === 'BUNDLE' && (
                <>
                  {getPropertyRender( 'bundle', 'bundle' )}
                  {getPropertyRender(
                    'promotePurchasingDirectChannel',
                    null,
                    promotePurchasing,
                  )}
                </>
              )}
            </>
          </TableDetail>
        </CarDetail>
      </Grid>

      {mkmode.decision.communications.mediaStep && (
        <Grid item xs={12} sm={6} lg={3}>
          <CarDetail>
            <Grid container justify="space-between">
              <Grid item xs={12}>
                <TableDetail title="media">
                  {_.map( mediaAttributes, item => (
                    <>
                      {getPropertyRender( 'channel', null, item.mediaChannel )}

                      {getPropertyRender( 'intensity', null, item.intensity )}
                    </>
                  ) )}
                </TableDetail>
              </Grid>
              <Grid item xs={12}>
                <TableDetail title="massiveResponse">
                  <>
                    {_.map( massiveResponse, ( item, key ) => (
                      <TableRow key={key}>
                        <TableCell>
                          <IntlMessages id={key} />
                        </TableCell>
                        <TableCell>
                          :&nbsp;
                          {Util.formatNumber( item )}
                        </TableCell>
                      </TableRow>
                    ) )}
                  </>
                </TableDetail>
              </Grid>
            </Grid>
          </CarDetail>
        </Grid>
      )}
      <Grid item xs={12} sm={6} lg={4}>
        <CarDetail>
          <TableDetail title="internet">
            <>
              {_.map( internetPlans, item => (
                <>
                  <TableRow>
                    <TableCell colSpan={2} className="subtitle">
                      <span>
                        <IntlMessages id={item.name} />
                      </span>
                    </TableCell>
                  </TableRow>

                  {getTranslatePropertyRender( 'gender', null, item.gender )}

                  {getPropertyRender(
                    'age',
                    null,
                    item.ageFrom || item.ageTo
                      ? `${item.ageFrom || ''} ${
                        item.ageTo && item.ageFrom ? 'to' : ''
                      } ${item.ageTo || ''}`
                      : false,
                  )}

                  {item.id === 'social_network_display' && (
                    <>
                      {getTranslateArrayRender(
                        'education',
                        _.filter( item.body, { type: 'education' } ),
                      )}
                      {getTranslateArrayRender(
                        'occupation',
                        _.filter( item.body, { type: 'occupation' } ),
                      )}
                    </>
                  )}

                  {item.id !== 'social_network_display'
                    && item.id !== 'artificial_intelligence'
                    && getTranslateArrayRender( item.bodyLabel, item.body )}

                  {item.id === 'artificial_intelligence'
                    && getTranslatePropertyRender( item.bodyLabel, null, item.body )}

                  {getPropertyRender(
                    'monthlyInvestment',
                    null,
                    Util.formatCurrency( item.monthlyInvestment ),
                  )}
                </>
              ) )}
            </>
          </TableDetail>
        </CarDetail>
      </Grid>
    </Grid>
  );
};

const TableDetail = ( { title, children } ) => (
  <Table className={classnames( 'px-2 kotler-table kotler-table-info-details' )}>
    <TableHead>
      {title && (
        <TableRow>
          <TableCell colSpan={2} className="pt-0">
            <IntlMessages id={title} />
          </TableCell>
        </TableRow>
      )}
    </TableHead>
    <TableBody>{children}</TableBody>
  </Table>
);
const CarDetail = ( { children, styleName } ) => (
  <CardBox styleName={styleName} header={<div />}>
    {children}
  </CardBox>
);
