import api from 'config/Api';

const endpoint = 'v1/entity';
const endpointV2 = 'v2/university';

export default {
  getUniversities( params ) {
    return api.get( endpoint, params );
  },

  getUniversity( universityId ) {
    return api.get( `${endpoint}/${universityId}` );
  },

  getWorkingDays( universityId, params ) {
    return api.get( `${endpoint}/${universityId}/workingday`, params );
  },

  updateEntity( id, data ) {
    return api.put( `${endpoint}/${id}`, data );
  },
  saveEntity( data ) {
    return api.post( endpoint, data );
  },
  saveUniversity( data ) {
    return api.post( endpointV2, data );
  },
  saveImage( universityId, image ) {
    return api.put( `${endpointV2}/${universityId}/setting`, { uploadAvatar: image } );
  },
  deleteImage( universityId ) {
    return api.delete( `${endpointV2}/${universityId}/delete-image` );
  },
};
