import api from '../config/Api';

const endpoint = 'v2/session';

export default {

  getIndividualSemesterMemo( sessionId, workGroupId, params ) {
    return api.get( `${endpoint}/${sessionId}/workgroup/${workGroupId}/individual-memos`, params );
  },

  saveIndividualSemesterMemo( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/individualsemestermemo`, data );
  },

  updateIndividualSemesterMemo( sessionId, individualSemesterMemoId, frmData ) {
    const data = frmData;
    delete data.id;
    delete data.createdAt;
    delete data.week;
    return api.put( `${endpoint}/${sessionId}/individualsemestermemo/${individualSemesterMemoId}`, data );
  },
};
