import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ManualAiActions } from '../redux/ManualAiReducer';

// eslint-disable-next-line import/prefer-default-export
export const useManualAiStore = () => {
  const dispatch = useDispatch();
  const { loading, question: lastQuestion, ask, show, message } = useSelector(
    ( { manualAi } ) => manualAi,
  );
  const sessionId = useSelector(
    ( { user } ) => user && user.activeSession && user.activeSession.id,
  );
  const language = useSelector(
    ( { settings: { locale } } ) => locale && locale.locale,
  );
  const fetchAsk = useCallback(
    question => dispatch( ManualAiActions.fetchAskRequest( sessionId, question, language ) ),
    [sessionId, language, dispatch],
  );
  const setShow = useCallback(
    value => dispatch( ManualAiActions.setShow( value ) ),
    [dispatch],
  );
  const clearAsk = useCallback( () => dispatch( ManualAiActions.clearAsk() ), [
    dispatch,
  ] );
  return {
    loading,
    lastQuestion,
    message,
    ask,
    show,
    fetchAsk,
    setShow,
    clearAsk,
  };
};
