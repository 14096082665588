import React from 'react';

import { connect } from 'react-redux';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-customer.jpeg';
import { Col, Row } from 'reactstrap';
import {
  Button,
  Grid,
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import DashboardStudentService from 'services/DashboardStudent';
import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';

import CardBox from 'components/custom_v2/CardBox';
import ChartModal from 'routes/App/components/User/Chart';
import CustomerDiagram from 'routes/App/components/User/CustomerDiagram';

import SettingsActions from 'store/reducers/Settings';
import parallel from 'async/parallel';
import Util from 'utils/Util';
// import CostumerDemograficModal from './DemograficModal';

import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import CostumerOwnerShipModal from 'components/layouts/CustomerOwnerShipModal';
import CrossTable from './CrossTable';

class CustomerCharts extends React.Component {
  state = {
    dataWorkGroup: [],
    activeTab: '',
    activeTabIndex: 0,
    customerReport: null,
    CrossTableHeader: null,
    CrossTableWidthColum: 1,
    customerReport2: null,
    chartModal: false,
    // DemograficModal: false,
    OwnerShipModal: false,
    // DemograficData: null,
    OwnerShipData: null,
  };
  mkmode = {};
  isEnterprise = false;
  async componentDidMount() {
    const { user } = this.props;
    const { activeTab } = this.state;
    let swActive = activeTab;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    const nuRound = user.activeSession.currentRound.number;
    const monthsLabel = nuRound > 11 ? 12 : nuRound - 1;
    this.setState( { monthsLabel } );
    await DashboardStudentService.getStockValueAllWorkgroup(
      user.activeSession.id,
      { limit: -1 },
    ).then( ( response ) => {
      if ( response.ok ) {
        this.workgroups = response.data;

        if ( activeTab === '' ) {
          swActive = this.workgroups[0].id;
        }
        this.setState( { dataWorkGroup: response.data, activeTab: swActive } );
      }
    } );

    this.loadAllChart( user.activeSession.id, swActive );
  }

  async componentDidUpdate( prevProps ) {
    const { locale, user } = this.props;
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;

    if ( locale !== prevProps.locale ) {
      const { dataWorkGroup } = this.state;
      const { activeTabIndex } = this.state;
      am4core.disposeAllCharts();
      await this.loadAllChart(
        user.activeSession.id,
        dataWorkGroup[activeTabIndex].id,
      );
    }
  }

  componentWillUnmount() {
    am4core.disposeAllCharts();
  }

  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  toggleOwnerShipModal = () => {
    this.setState( previousState => ( { OwnerShipModal: !previousState.OwnerShipModal } ) );
  };

  /* toggleDemograficModal = () => {
    this.setState( previousState => ( { DemograficModal: !previousState.DemograficModal } ) );
  }; */

  toggleTab2 = async ( event, tab ) => {
    const { dataWorkGroup } = this.state;
    const { user } = this.props;
    const { activeTab } = this.state;
    am4core.disposeAllCharts();
    if ( activeTab !== tab ) {
      this.setState( { activeTabIndex: tab } );
    }
    await this.loadAllChart( user.activeSession.id, dataWorkGroup[tab].id );
  };
  createLineCharSerie = ( key, roundsCat, datas ) => {
    const { intl } = this.props;
    const roundsCatTraduce = roundsCat.map( item => ( {
      ...item,
      attribute: intl.formatMessage(
        { id: item.attribute.split( ' ' )[0] },
        { value: item.attribute.split( ' ' )[1] },
      ),
    } ) );
    const config = {
      data: roundsCatTraduce,
      type: am4charts.XYChart,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: { category: 'attribute' },
          title: { text: intl.formatMessage( { id: 'Rounds' } ) },
        },
      ],
      yAxes: [{ type: 'ValueAxis', min: 0 }],
      series: _.map( datas, itemX => ( {
        type: 'LineSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    return { config, chart };
  };
  createCharSerie = ( key, roundsCat, datas ) => {
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: { category: 'attribute' },
          renderer: { minGridDistance: 30 },
        },
      ],
      yAxes: [{ type: 'ValueAxis', min: 0 }],
      series: _.map( datas, itemX => ( {
        type: 'ColumnSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    return { config, chart };
  };

  loadAllChart( userSessionID, activeWorkGroupID ) {
    const { toggleLoading, intl } = this.props;
    toggleLoading( true );
    let result1 = null;
    parallel(
      [
        ( cb ) => {
          // eslint-disable-next-line max-len
          DashboardStudentService.getCustomerInformation( userSessionID, { workgroup: activeWorkGroupID } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
        ( cb ) => {
          DashboardStudentService.getCustomerEvolution( userSessionID, {
            workgroup: activeWorkGroupID,
            limit: -1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
        ( cb ) => {
          // eslint-disable-next-line max-len
          DashboardStudentService.getCustomerRetention( userSessionID, { workgroup: activeWorkGroupID } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
        ( cb ) => {
          // eslint-disable-next-line max-len
          DashboardStudentService.getCustomerAvgRevenue( userSessionID, { workgroup: activeWorkGroupID } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
        },
      ],
      ( error, results ) => {
        if ( !error ) {
          if ( results ) {
            const CrossTableHeader = _.map(
              results[0].crossSellingNmbr,
              item => item.product,
            );
            // eslint-disable-next-line max-len
            const CrossTableWidthColum = CrossTableHeader.length > 0
              ? 100 / ( CrossTableHeader.length + 2 )
              : 1;
            // console.log(CrossTableWidthColum);
            this.setState( {
              customerReport: results[0],
              CrossTableHeader,
              CrossTableWidthColum,
            } );
            // eslint-disable-next-line max-len
            if (
              !!results[0].customerDemographics.demographics
              && !!results[0].customerProductOwnership.productOwnership
            ) {
              // this.setState( { DemograficData: results[0].customerDemographics } );
              this.setState( { OwnerShipData: results[0].customerProductOwnership } );
            }
            const dataList12month = [
              {
                attribute: intl.formatMessage( { id: 'retainedCustomers' } ),
                value: results[0].evolutionCustomerBase
                  ? results[0].evolutionCustomerBase.retainedCustomers
                  : 0,
              },
              {
                attribute: intl.formatMessage( { id: 'lostCustomers' } ),
                value: results[0].evolutionCustomerBase
                  ? results[0].evolutionCustomerBase.lostCustomers
                  : 0,
              },
              {
                attribute: intl.formatMessage( { id: 'lastReactivatedCustomers' } ),
                value: results[0].evolutionCustomerBase
                  ? results[0].evolutionCustomerBase.reactivatedCustomers
                  : 0,
              },
              {
                attribute: intl.formatMessage( { id: 'newCustomers' } ),
                value: results[0].evolutionCustomerBase
                  ? results[0].evolutionCustomerBase.newCustomers
                  : 0,
              },
              {
                attribute: intl.formatMessage( { id: 'activeCustomers' } ),
                value: results[0].evolutionCustomerBase
                  ? results[0].evolutionCustomerBase.activeCustomers
                  : 0,
              },
            ];

            result1 = this.createCharSerie(
              'chartcustomeractivity12m',
              dataList12month,
              [{ id: 'value', name: intl.formatMessage( { id: 'customers' } ) }],
            );
            this.config12m = _.cloneDeep( result1.config );
            this.chart = result1.chart;

            const dataList = _.map( results[1].data, item => ( {
              attribute: item.round.shortName,
              activeCustomers: item.activeCustomers,
              inactiveCustomers: item.inactiveCustomers,
            } ) );

            result1 = this.createLineCharSerie(
              'chartcustomeractivity',
              dataList,
              [
                {
                  id: 'activeCustomers',
                  name: intl.formatMessage( { id: 'activeCustomers' } ),
                },
                {
                  id: 'inactiveCustomers',
                  name: intl.formatMessage( { id: 'inactiveCustomers' } ),
                },
              ],
            );
            this.config = _.cloneDeep( result1.config );
            this.chart = result1.chart;

            // const dataList0 = _.map( results[2].data, item => ( {
            //  attribute: item.product,
            //  valueWithService: item.valueWithService,
            // } ) );

            // / 1 -> 1 0

            /* result1 = this.createCharSerie( 'chartevolution', dataList0, [
            { id: 'valueWithService', name: intl.formatMessage( { id: 'products' } ) },
          ] );
          this.config1 = _.cloneDeep( result1.config );
          this.chart1 = result1.chart;
          */
            /*
          const dataList2 = _.map( results[2].data, item => ( {
            attribute: item.product,
            customerLifetime: item.customerLifetime,
          } ) );
          // / 1 -> 1 0
          /*
          result1 = this.createCharSerie( 'chartretention', dataList2, [
            { id: 'customerLifetime', name: 'Productos' },
          ] );
          this.config2 = _.cloneDeep( result1.config );
          this.chart2 = result1.chart;
          */
            // / 2 -> 0 1
            /* const dataList3 = _.map( results[3].data, item => ( {
            attribute: item.product,
            valueWithoutService: item.valueWithoutService,
          } ) );

          result1 = this.createCharSerie( 'charaverage2', dataList3, [
            { id: 'valueWithoutService', name: 'Without Customers' },
          ] );
          this.config3 = _.cloneDeep( result1.config );
          this.chart3 = result1.chart; */

            // / 2 -> 1 0
            /* const dataList4 = _.map( results[2].data, item => ( {
            attribute: item.product,
            averageYearlyRevenue: item.averageYearlyRevenue,
          } ) );

          result1 = this.createCharSerie( 'charaverage1', dataList4, [
            { id: 'averageYearlyRevenue', name: intl.formatMessage( { id: 'products' } ) },
          ] );
          this.config4 = _.cloneDeep( result1.config );
          this.chart4 = result1.chart;
          */

            // Product per Costumer
            const dataListPxC = _.map( results[1].data, item => ( {
              attribute: item.round.shortName,
              productsPerCustomers: item.productsPerCustomers,
            } ) );
            result1 = this.createLineCharSerie(
              'chartProductsPerCustomers',
              dataListPxC,
              [
                {
                  id: 'productsPerCustomers',
                  name: intl.formatMessage( { id: 'products' } ),
                },
              ],
            );
            this.configPxC = _.cloneDeep( result1.config );
            this.chartPxC = result1.chart;

            // Product per Costumer
            const dataListRxC = _.map( results[1].data, item => ( {
              attribute: item.round.shortName,

              revenuesPerCustomers: item.revenuesPerCustomers,
            } ) );
            result1 = this.createLineCharSerie(
              'chartRevenuesPerCustomers',
              dataListRxC,
              [
                {
                  id: 'revenuesPerCustomers',
                  name: intl.formatMessage( { id: 'revenues' } ),
                },
              ],
            );
            this.configRxC = _.cloneDeep( result1.config );
            this.dataListRxC = result1.chart;

            // retentionRate
            const dataListRR = _.map( results[1].data, item => ( {
              attribute: item.round.shortName,

              retentionRate: item.retentionRate,
            } ) );
            result1 = this.createLineCharSerie(
              'chartRetentionRate',
              dataListRR,
              [
                {
                  id: 'retentionRate',
                  name: intl.formatMessage( { id: 'rate' } ),
                },
              ],
            );
            this.configRR = _.cloneDeep( result1.config );
            this.dataListRR = result1.chart;

            // lostCustomers
            const dataListLC = _.map( results[1].data, item => ( {
              attribute: item.round.shortName,

              lostCustomers: item.lostCustomers,
            } ) );
            result1 = this.createLineCharSerie(
              'chartLostCustomers',
              dataListLC,
              [
                {
                  id: 'lostCustomers',
                  name: intl.formatMessage( { id: 'customers' } ),
                },
              ],
            );
            this.configLC = _.cloneDeep( result1.config );
            this.dataListLC = result1.chart;
          }
        }
        toggleLoading( false );
      },
    );
  }
  render() {
    const {
      activeTabIndex,
      dataWorkGroup,
      chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
      customerReport,
      CrossTableHeader,
      CrossTableWidthColum,
      customerReport2,
      monthsLabel,
      OwnerShipModal,
      // DemograficModal,
      OwnerShipData,
      // DemograficData,
    } = this.state;
    const { intl, user } = this.props;
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'InformationDashboard-customers' },
          ]}
        />
        <KotlerSectionHeader title="customers" image={HeaderImage} />

        <div>
          <Tabs
            value={activeTabIndex !== -1 ? activeTabIndex : 0}
            onChange={this.toggleTab2}
            aria-label="simple tabs example"
            className="kotler-tab-pills mt-3"
            variant="scrollable"
          >
            {_.map( dataWorkGroup, item => (
              <Tab label={item.name} className="tab" key={item.id} />
            ) )}
          </Tabs>
        </div>

        {!!customerReport && (
          <Grid
            container
            className="my-3"
            justify="space-between"
            alignItems="flex-end"
          >
            <Grid item xs={12} sm={8}>
              <div className="d-flex align-items-end h-100">
                <KotlerCardHeader
                  minHeight="0px"
                  title="RegisteredCustomerBase"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm="auto">
              {user.activeSession.distributionType !== 'studio' && (
                <div className="d-flex justify-content-center h-100">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="kotler-button-md"
                    type="button"
                    onClick={this.toggleOwnerShipModal}
                  >
                    <IntlMessages id="ShowProductOwnerShip" />
                  </Button>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justify="space-around"
                alignItems="center"
                className="py-3"
                spacing={2}
              >
                <Grid item style={{ overflowX: 'auto' }}>
                  <CustomerDiagram
                    isEnterprise={this.isEnterprise}
                    customerReport={customerReport}
                    month={monthsLabel}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={3}>
          {!!customerReport2 && (
            <Grid item xs={12} md={4}>
              <CardBox
                styleName="px-2 h-100"
                heading="RegisteredCustomerBase"
                headerBg="bg-customer"
              >
                <Row>
                  <Col lg={4}>
                    <b>
                      <IntlMessages id="NumberOfCustomers" />
                    </b>
                  </Col>
                  <Col lg={2} className="text-right">
                    {Util.formatNumber( customerReport.numberOfCustomers )}
                  </Col>
                  <Col lg={4}>
                    <b>
                      <IntlMessages id="ProductsPerCustomer" />
                    </b>
                  </Col>
                  <Col lg={2} className="text-right">
                    {Util.formatNumber( customerReport.productsPerCustomer )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    {'\u00A0'.repeat( 4 )}
                    <IntlMessages id="ActiveCustomers" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.activeCustomers )}
                  </Col>
                  <Col lg={4}>
                    {'\u00A0'.repeat( 4 )}
                    <IntlMessages id="Devicespercustomer" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.devicesPerCustomer )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={4}>
                    {'\u00A0'.repeat( 4 )}
                    <IntlMessages id="InactiveCustomers" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.inactiveCustomers )}
                  </Col>
                  <Col lg={4}>
                    {'\u00A0'.repeat( 4 )}
                    <IntlMessages id="ServicesPerCustomer" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.servicesPerCustomer )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <IntlMessages id="NewCustomer (current month)" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.newCustomers )}
                  </Col>
                  <Col lg={4}>
                    <IntlMessages
                      id="RevenuesPerCustomer (n month)"
                      values={{ month: monthsLabel }}
                    />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.revenuesPerCustomer )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <IntlMessages
                      id="NewCustomer (n month)"
                      values={{ month: monthsLabel }}
                    />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.lastNewCustomers )}
                  </Col>
                  <Col lg={4}>&nbsp;</Col>
                  <Col className="text-right">&nbsp;</Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <IntlMessages id="Lost customers (current month)" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.lostCustomers )}
                  </Col>
                  <Col lg={4} />
                  <Col />
                </Row>
                <Row>
                  <Col lg={4}>
                    <IntlMessages
                      id="Lost customers (n month)"
                      values={{ month: monthsLabel }}
                    />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.lastLostCustomers )}
                  </Col>
                  <Col lg={4}>
                    <IntlMessages id="EstimatedCustomerLifetime" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber(
                      customerReport.estimatedCustomerLifetime,
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <IntlMessages id="RetentionRate" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber( customerReport.retentionRate )}
                  </Col>
                  <Col lg={4} />
                  <Col />
                </Row>
                <Row>
                  <Col lg={4}>
                    <IntlMessages id="estimatedCustomerDuration" />
                  </Col>
                  <Col className="text-right">
                    {Util.formatNumber(
                      customerReport.estimatedCustomerDuration,
                    )}
                  </Col>
                  <Col lg={4} />
                  <Col />
                </Row>
              </CardBox>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <CardBox
              heading="Evolution_of_Customer_Base"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal(
                this.config,
                'Evolution_of_Customer_Base',
                [
                  intl.formatMessage( { id: 'activeCustomers' } ),
                  intl.formatMessage( { id: 'inactiveCustomers' } ),
                ],
              )
              }
            >
              <h5>
                &nbsp;&nbsp;
                <IntlMessages id="Total_Active_and_Inactive_Customers" />
              </h5>
              <div
                className="jr-link"
                id="chartcustomeractivity"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <CardBox
              heading="Evolution_of_Customer_Base"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal(
                this.config12m,
                'Evolution_of_Customer_Base',
                [intl.formatMessage( { id: 'customers' } )],
              )
              }
            >
              <h5>
                &nbsp;&nbsp;
                <IntlMessages id="Total_Active_and_Inactive_Customers12m" />
              </h5>
              <div
                className="jr-link"
                id="chartcustomeractivity12m"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <CardBox
              heading="Evolution_of_Customer_Activity_Products"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal(
                this.configPxC,
                'Evolution_of_Customer_Activity_Products',
                [intl.formatMessage( { id: 'products' } )],
              )
              }
            >
              <h5>
                &nbsp;&nbsp;
                <IntlMessages id="subEvolution_of_Customer_Activity_Products" />
              </h5>
              <div
                className="jr-link"
                id="chartProductsPerCustomers"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardBox
              heading="Evolution_of_Customer_Activity_Revenues"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal(
                this.configRxC,
                'Evolution_of_Customer_Activity_Revenues',
                [intl.formatMessage( { id: 'revenues' } )],
              )
              }
            >
              <h5>
                &nbsp;&nbsp;
                <IntlMessages id="subEvolution_of_Customer_Activity_Revenues" />
              </h5>
              <div
                className="jr-link"
                id="chartRevenuesPerCustomers"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardBox
              heading="Evolution_of_Customer_Retention_lostCustomers"
              onClick={() => this.toggleChartModal(
                this.configLC,
                'Evolution_of_Customer_Retention_lostCustomers',
                [intl.formatMessage( { id: 'lostCustomers' } )],
              )
              }
            >
              <h5>
                &nbsp;&nbsp;
                <IntlMessages id="subEvolution_of_Customer_Retention_lostCustomers" />
              </h5>
              <div
                className="jr-link"
                id="chartLostCustomers"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardBox
              styleName="px-2 h-100"
              heading="Evolution_of_Customer_Retention_Rate"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal(
                this.configRR,
                'Evolution_of_Customer_Retention_Rate',
                [intl.formatMessage( { id: 'rate' } )],
              )
              }
            >
              <h5>
                &nbsp;&nbsp;
                <IntlMessages id="subEvolution_of_Customer_Retention_Rate" />
              </h5>
              <div
                className="jr-link"
                id="chartRetentionRate"
                style={{ width: '100%', height: '250px' }}
              />
            </CardBox>
          </Grid>
          {user.activeSession
            && user.activeSession.distributionType === 'enterprise'
            && CrossTableHeader && (
              <>
                {customerReport
                  && customerReport.crossSellingNmbr
                  && customerReport.crossSellingNmbr.length > 0 && (
                  <CrossTable
                    headerTitle="customer.title.crosstable.num"
                    subtitleId="customer.subtitle.crosstable.num"
                    data={customerReport.crossSellingNmbr}
                    header={CrossTableHeader}
                    widthColum={CrossTableWidthColum}
                  />
                )}
                {customerReport
                  && customerReport.crossSellingPerc
                  && customerReport.crossSellingPerc.length > 0 && (
                  <CrossTable
                    headerTitle="customer.title.crosstable.percent"
                    subtitleId="customer.subtitle.crosstable.percent"
                    data={customerReport.crossSellingPerc}
                    header={CrossTableHeader}
                    widthColum={CrossTableWidthColum}
                  />
                )}
              </>
          )}

          {
            // <Row>
            //   <Col lg={6}>
            //     <CardBox
            // styleName="px-2 h-100"
            //       heading="retention_rate_by_number_of_products_customers"
            //       childrenStyle=""
            //       headerBg="bg-customer"
            //       onClick={() => this.toggleChartModal( this.config1,
            // <IntlMessages id="retention_rate_by_number_of_products_customers" />,
            //         [intl.formatMessage( { id: 'groupBy' } )] )}
            //     >
            //       <div className="jr-link" id="chartevolution"
            // style={{ width: '100%', height: '250px' }} />
            //     </CardBox>
            //   </Col>
            //   <Col lg={6}>
            //     <CardBox
            // styleName="px-2 h-100"
            //       heading="Average_yearly_revenues_per_customer_by_number_of_products_customer"
            //       childrenStyle=""
            //       headerBg="bg-customer"
            //       onClick={() => this.toggleChartModal( this.config4,
            //  <IntlMessages id="Average_yearly_revenues_per_customer
            //  _by_number_of_products_customer" />,
            //  [intl.formatMessage( { id: 'groupBy' } )] )}
            //     >
            //       <div className="jr-link" id="charaverage1"
            // style={{ width: '100%', height: '250px' }} />
            //     </CardBox>
            //   </Col>
            // </Row>
            // <Row>
            //  <Col lg={3} />
            //  <Col lg={6}>
            //    <CardBox
            // styleName="px-2 h-100"
            //      heading="Customer_Lifetime_Value_by_number_of_products_customer"
            //      childrenStyle=""
            //      headerBg="bg-customer"
            // eslint-disable-next-line max-len
            //      onClick={() => this.toggleChartModal( this.config2, <IntlMessages id="Customer_Lifetime_Value_by_number_of_products_customer" />, seriesTitle1 )}
            //    >
            // eslint-disable-next-line max-len
            //      <div className="jr-link" id="chartretention" style={{ width: '100%', height: '250px' }} />
            //    </CardBox>
            //  </Col>
            //  <Col lg={3} />
            // </Row>
          }
        </Grid>

        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />

        <CostumerOwnerShipModal
          open={OwnerShipModal}
          title="ProductOwnerShip"
          onClose={this.toggleOwnerShipModal}
          data={OwnerShipData}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};

const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( CustomerCharts ) );
