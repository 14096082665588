import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { formatDataToPieGraphPerGroup } from 'helpers/GraphHelpers';

import PieGraphFull from 'components/StatisticalGraphs/containers/PieGraphFull';

const PieGraphLastRoundBundles = ( {
  gId = 1,
  graphHeight = '315px',
  title = 'market.bundles_information',
  hoverTitle,
  data,
  loading,
  error,
  children,
} ) => {
  const [dataToGraph, setDataToGraph] = useState( {} );

  useEffect( () => {
    const formatData = formatDataToPieGraphPerGroup( data, 'totalUnitSales' );
    if ( JSON.stringify( dataToGraph ) !== JSON.stringify( formatData ) ) {
      setDataToGraph( formatData );
    }
  }, [data, dataToGraph] );

  return (
    <PieGraphFull
      {...{
        title,
        loading,
        error,
        hoverTitle,
        children,
      }}
      height={graphHeight}
      htmlId={`pie_chart_lastround_bundles_${gId}`}
      data={dataToGraph.data}
    />
  );
};

const mapStateToProps = ( { bundles: { lastRoundBundles: { data, loading, error } } } ) => ( {
  data,
  loading,
  error,
} );

export default connect( mapStateToProps )( PieGraphLastRoundBundles );
