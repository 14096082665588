import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import classNames from 'classnames';

import IntlMessages from 'utils/IntlMessages';
import HoverTitle from 'components/custom_v2/HoverTitle';

export default ({ field, containerClass, fullWidth = false, hover = {}, label,labelPlacement="end", onChange, marginContainer = true, ...rest }) => (
  field ?
    <Field name={field} type="checkbox">
      {({ meta, input }) => (
        <FormControl
          error={meta.touched && !!meta.error}
          className={classNames("kotler-switch-form-control",containerClass, { 'mb-4': marginContainer })}
          fullWidth={fullWidth}
         
        >
       
            <HoverTitle {...hover} >
              <FormControlLabel
               labelPlacement={labelPlacement}
                label={label ? <IntlMessages id={label} /> : ''}
                className={classNames({
                  'm-0': !label
                })}

                control={(
                  <Switch
                  className='kotler-switch'
                    {...input}
                    color="primary"
                    classes={{
                      checked: 'text-primary',
                    }}
                    {...rest}
                    checked={!!input.checked || false}
                    onChange={(e, checked) => {
                      input.onChange(checked);
                      if (onChange) onChange(checked, e);
                    }}
                  />
                )}
              />
            </HoverTitle>
         
          {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field> :
    <FormControl
      className={classNames("kotler-switch-form-control",containerClass, { 'mb-4': marginContainer })}
      fullWidth={fullWidth}
    >
      <HoverTitle {...hover}>
        <FormControlLabel
        labelPlacement={labelPlacement}
          label={label ? 
            <HoverTitle id="product.showActiveToggle.hover"><IntlMessages id={label} /></HoverTitle> : ''}
          control={(
            <Switch
            className='kotler-switch'
              color="primary"
              classes={{
                checked: 'text-primary',
              }}
              {...rest}
              checked={!!rest.checked || false}
              onChange={(e, checked) => {
                if (onChange) onChange(checked, e);
              }}
            />
          )}
        />
      </HoverTitle>

    </FormControl>
);
