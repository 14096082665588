import React, { Children, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Download } from 'react-feather';
import { injectIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import CircularProgress from 'components/theme/CircularProgress';

function TabPanel( props ) {
  const { children, value, index, renderOneView, ...other } = props;
  const [render, setRender] = useState( false );
  useEffect( () => {
    if ( !render && value === index ) setRender( true );
  }, [render, value, index] );
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {( !renderOneView || render ) && <Box className="py-3">{children}</Box>}
    </div>
  );
}

const KotlerTabs = ( {
  noTranslateTitles = false,
  intl,
  disableUnderLine = false,
  endHeadComponent,
  variant = 'scrollable',
  tabClassName = 'kotler-tabs',
  titles = [
    {
      icon: <Download size="20px" color="primary.main" />,
      title: 'item dios',
    },
  ],
  pages,
  direction = 'left',
  onChange,
  children,
  renderOneView = false,
} ) => {
  const [value, setValue] = React.useState( 0 );

  const handleChange = ( event, newValue ) => {
    setValue( newValue );
  };

  const handleChangeIndex = ( index ) => {
    setValue( index );
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid
        wrap="wrap-reverse"
        style={{ borderBottom: !disableUnderLine ? '1px solid #999FA3' : 'none' }}
        container
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} md="auto">
          <Tabs
            variant={variant}
            className={tabClassName}
            value={value}
            onChange={onChange || handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {titles
              && titles.map( ( { title, icon, loading, disabled, key } ) => (
                <Tab
                  icon={
                    loading ? (
                      <CircularProgress
                        style={{
                          width: '18px',
                          height: '18px',
                          display: 'inline-block',

                          margin: '0  15px -5px -10px',
                        }}
                        svgClassName="ma-0 pa-0"
                      />
                    ) : (
                      icon
                    )
                  }
                  key={key || title}
                  label={
                    !noTranslateTitles
                      ? intl.formatMessage( { id: title } )
                      : title
                  }
                  disabled={disabled}
                />
              ) )}
          </Tabs>
        </Grid>
        {!!endHeadComponent && <Grid item>{endHeadComponent}</Grid>}
      </Grid>

      <SwipeableViews
        axis={direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {children
          ? Children.toArray( children )
            .filter( child => !!child )
            .map( ( child, index ) => ( child ? (
              <TabPanel
                key={index}
                value={value}
                index={index}
                dir={direction}
                renderOneView={renderOneView}
              >
                {child}
              </TabPanel>
            ) : (
              undefined
            ) ) )
          : !!pages
            && pages.map( ( render, index ) => (
              <TabPanel
                key={index}
                value={index}
                index={index}
                renderOneView={renderOneView}
                dir={direction}
              >
                {typeof render === 'function' ? render( index ) : render}
              </TabPanel>
            ) )}
      </SwipeableViews>
    </div>
  );
};
export default injectIntl( KotlerTabs );
