import { IconButton } from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';
import HoverTitle from 'components/custom_v2/HoverTitle';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Help from 'routes/App/components/User/Help';

const TutorialButton = ( { history, user } ) => {
  if (
    !history
    || !history.location
    || !history.location.pathname
    || !user
    || !user.activeSession
  ) return <div />;
  const { activeSession } = user;
  const indexOf = name => history.location.pathname.indexOf( name );
  const suffix = activeSession && activeSession.distributionType === 'studio'
    ? '.studio'
    : '';
  if ( indexOf( '/info/company' ) !== -1 ) {
    return (
      <Help
        title="help.info.company.title"
        content={`help.info.company.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/info/customer' ) !== -1 ) {
    return (
      <Help
        title="help.info.customer.title"
        content={`help.info.customer.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/info/product' ) !== -1 ) {
    return (
      <Help
        title="help.info.product.title"
        content={`help.info.product.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/info/activity' ) !== -1 ) {
    return (
      <Help
        title="help.info.activity.title"
        content={`help.info.activity.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/info/research' ) !== -1 ) {
    return (
      <Help
        title="help.info.research.title"
        content={`help.info.research.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/info/market' ) !== -1 ) {
    return (
      <Help
        title="help.info.market.title"
        content={`help.info.market.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/general/product' ) !== -1 && indexOf( 'new' ) === -1 ) {
    return (
      <Help
        title="help.product.title"
        content={`help.product.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/general/product' ) !== -1 && indexOf( 'new' ) !== -1 ) {
    return (
      <Help
        title="help.new.product.title"
        content={`help.new.product.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/general/services' ) !== -1 && indexOf( 'new-bundle' ) === -1 ) {
    return (
      <Help
        title="help.service.title"
        content={`help.service.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/general/services/new-bundle' ) !== -1 ) {
    return (
      <Help
        title="help.service.newbundle.title"
        content={`help.service.newbundle.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/general/price' ) !== -1 ) {
    return (
      <Help title="help.price.title" content={`help.price.content${suffix}`} />
    );
  }
  if ( indexOf( '/general/distribution' ) !== -1 ) {
    return (
      <Help
        title="help.distribution.title"
        content={`help.distribution.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/general/cem' ) !== -1 ) {
    return (
      <Help title="help.cem.title" content={`help.cem.content${suffix}`} />
    );
  }
  if ( indexOf( '/general/crm' ) !== -1 ) {
    return (
      <Help title="help.crm.title" content={`help.crm.content${suffix}`} />
    );
  }
  if ( indexOf( '/general/one-to-one' ) !== -1 ) {
    return (
      <Help title="help.1to1.title" content={`help.1to1.content${suffix}`} />
    );
  }
  if ( indexOf( '/general/strategy' ) !== -1 ) {
    return (
      <Help
        title="help.strategy.title"
        content={`help.strategy.content${suffix}`}
      />
    );
  }
  if ( indexOf( '/general' ) === -1 && indexOf( '/info' ) === -1 ) {
    return (
      <HoverTitle id="appBar.infobtn.hover" placement="left">
        <IconButton
          className="info-icon-button square-icon-buttom"
          component={Link}
          to="/tutorial"
        >
          <FeatherIcon icon="info" color="secondary.dark" size="16px" />
        </IconButton>
      </HoverTitle>
    );
  }
  return null;
};

export default connect( ( { user } ) => ( { user } ) )( TutorialButton );
