import React, { useState } from 'react';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import FeatherIcon from 'components/custom/FeatherIcon';
import { Link } from 'react-router-dom';
import { useFetch } from 'hooks';
import {
  deleteFeature,
  updateFeature,
} from 'modules/changelog/services/ChangelogService';
import { FeatureRowForm } from './FeatureRowForm';
import { FeatureBadge } from '../FeatureBadge/FeatureBadge';

export const FeatureRow = ( {
  feature,
  changelogId,
  onFetchData = async () => {},
  disabled,
} ) => {
  const [isEditing, setIsEditing] = useState( false );

  const { fetchData: onDeleteEntity, isLoading: isDeleting } = useFetch(
    async () => deleteFeature( changelogId, feature.id ),
    {
      initialData: [],
      onSuccess: () => {
        onFetchData( feature );
      },
    },
  );
  const { fetchData: onEdit } = useFetch(
    async values => updateFeature( changelogId, feature.id, values ),
    {
      initialData: [],
      onSuccess: async () => {
        await onFetchData( feature );
        setIsEditing( false );
      },
    },
  );
  const isDisabled = isEditing || isDeleting || disabled;
  return isEditing ? (
    <FeatureRowForm
      initialData={feature}
      onCancel={() => setIsEditing( false )}
      onSubmit={onEdit}
    />
  ) : (
    <>
      <TableRow>
        <TableCell className="text-primary">
          <FeatureBadge type={feature.type} />
        </TableCell>
        <TableCell
          className="text-primary text-wrap"
          style={{ maxWidth: '200px', wordWrap: 'break-word' }}
        >
          {feature.enDescription}
        </TableCell>
        <TableCell
          className="text-primary"
          style={{ maxWidth: '30vw', wordWrap: 'break-word' }}
        >
          {feature.esDescription}
        </TableCell>
        <TableCell
          colSpan={2}
          className="text-primary"
          style={{ width: '70px' }}
        >
          <div className="d-flex justify-content-end">
            <IconButton
              component={Link}
              to={`/changelogs/${feature.id}`}
              className="square-icon-button-primary2 "
              disabled={isDisabled}
            >
              <FeatherIcon icon="eye" color="primary2.light" size="18px" />
            </IconButton>
            <div className="mx-1" />
            <IconButton
              className="square-icon-button-primary "
              disabled={isDisabled}
              onClick={() => {
                setIsEditing( true );
              }}
            >
              <FeatherIcon icon="edit" color="primary.main" size="18px" />
            </IconButton>
            <div className="mx-1" />
            <IconButton
              disabled={isDisabled}
              onClick={onDeleteEntity}
              className="square-icon-button-danger"
            >
              <FeatherIcon icon="trash" color="error.main" size="18px" />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FeatureRow;
