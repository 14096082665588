import React from 'react';
import CardBox from 'components/custom_v2/CardBox';
import HoverTitle from 'components/custom_v2/HoverTitle';
import { connect } from 'react-redux';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';

const CompBalance = ( {
  inventoryValue,
  cash,
  patrimony,
  loans,
  serviceFeesReceivable,
  serviceFeesPayable,
  bundleFeesReceivable,
  user,
} ) => (
  <CardBox
    styleName="px-2 h-100"
    header={<KotlerCardHeader title="company.balance" minHeight="0px" />}
  >
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Table className="kotler-table-info-details kotler-table-info-sm">
          <TableHead />
          <TableBody>
            <TableRow>
              <TableCell style={{ fontWeight: 'bolder' }}>
                <strong>
                  <IntlMessages id="balance.assets" />
                </strong>
              </TableCell>
              <TableCell style={{ fontWeight: 'bolder' }}>
              &nbsp;
              </TableCell>


            </TableRow>
            <TableRow>
              <TableCell>
                <IntlMessages id="balance.cash" />
              </TableCell>
              <TableCell align="right">{Util.formatNumber( cash )}</TableCell>

            </TableRow>
            <TableRow>
              <TableCell>
                <HoverTitle id="balance.inventoryValue.hover" placement="right">
                  <span>
                    <IntlMessages id="balance.inventoryValue" />
                  </span>
                </HoverTitle>
              </TableCell>
              <TableCell align="right">{Util.formatNumber( inventoryValue )}</TableCell>

            </TableRow>
            {user.activeSession.distributionType !== 'studio' && (
              <TableRow>
                <TableCell>
                  <HoverTitle id="balance.receivables.hover" placement="right">
                    <span>
                      <IntlMessages id="balance.receivables" />
                    </span>
                  </HoverTitle>
                </TableCell>
                <TableCell align="right">
                  {Util.formatNumber( serviceFeesReceivable + bundleFeesReceivable )}
                </TableCell>

              </TableRow>
            )}


            <TableRow>
              <TableCell className="m-3">
                <strong>
                  <IntlMessages id="balance.totalAssets" />
                </strong>
              </TableCell>
              <TableCell align="right">
                {/* eslint-disable-next-line max-len */}
                <strong>{Util.formatNumber( cash + inventoryValue + serviceFeesReceivable + bundleFeesReceivable )}</strong>
              </TableCell>

            </TableRow>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>

            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Table className="kotler-table-info-details kotler-table-info-sm">
          <TableHead />
          <TableBody>
            <TableRow>


              <TableCell>
                <strong>
                  <IntlMessages id="balance.patrimony" />
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>{Util.formatNumber( patrimony )}</strong>
              </TableCell>
            </TableRow>
            <TableRow>

              <TableCell style={{ fontWeight: 'bolder' }}>
                <strong>
                  <IntlMessages id="balance.liabilities" />
                </strong>
              </TableCell>
              <TableCell style={{ fontWeight: 'bolder' }}>
                <strong>&nbsp;</strong>
              </TableCell>
            </TableRow>
            <TableRow>

              <TableCell>
                <HoverTitle id="outstandingDebt.hover" placement="right">
                  <span>
                    <IntlMessages id="balance.loans" />
                  </span>
                </HoverTitle>
              </TableCell>
              <TableCell align="right">{Util.formatNumber( loans )}</TableCell>
            </TableRow>
            {user.activeSession.distributionType !== 'studio' && (
              <TableRow>

                <TableCell>
                  <HoverTitle id="balance.serviceFeesPayable.hover" placement="right">
                    <span>
                      <IntlMessages id="balance.serviceFeesPayable" />
                    </span>
                  </HoverTitle>
                </TableCell>
                <TableCell align="right">{Util.formatNumber( serviceFeesPayable )}</TableCell>
              </TableRow>
            )}

            <TableRow>

              <TableCell>
                <strong>
                  <IntlMessages id="balance.totalLiabilities" />
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>{Util.formatNumber( loans + serviceFeesPayable )}</strong>
              </TableCell>
            </TableRow>

            <TableRow>

              <TableCell>
                <strong>
                  <IntlMessages id="balance.totalLiabilitiesAndPatrimony" />
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>{Util.formatNumber( loans + serviceFeesPayable + patrimony )}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  </CardBox>
);

const mapStateToProps = ( { user } ) => ( { user } );
export default connect( mapStateToProps, null )( CompBalance );
