import React from 'react';


const LanguageItem = ({active, language, switchLanguage, handleRequestClose }) => {
  const {  name } = language;
  return (
    <span className={`py-0 d-flex jr-link dropdown-item ${active?"active-option-dark":""}`}  onClick={() => {
      if(handleRequestClose)handleRequestClose();
      switchLanguage(language)}} >
      <span className="mb-0 ml-1 pl-1">{name}</span>
    </span>
  );
}

export default LanguageItem;
