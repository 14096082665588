import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import React from 'react';
import Image from 'assets/images/kotler/sectionHeaders/register.jpeg';
import { ChangelogTable } from 'modules/changelog';

const ChangelogDashboard = () => (
  <>
    <KotlerSectionHeader title="changelog" image={Image} />
    <ChangelogTable />
  </>
);

export default ChangelogDashboard;
