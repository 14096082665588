import {
  FETCH_LAST_ROUND_PRODUCTS_REQUEST,
  FETCH_LAST_ROUND_PRODUCTS_SUCCESS,
  FETCH_LAST_ROUND_PRODUCTS_FAILURE,
  CLEAR_LAST_ROUND_PRODUCTS,
} from '../actions/lastRoundProductsActions';

const initialState = { data: null, loading: false, error: null };

const lastRoundProductsReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case FETCH_LAST_ROUND_PRODUCTS_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case FETCH_LAST_ROUND_PRODUCTS_SUCCESS:
    return {
      ...state,
      data: action.payload,
      loading: false,
      error: null,
    };
  case FETCH_LAST_ROUND_PRODUCTS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  case CLEAR_LAST_ROUND_PRODUCTS:
    return {
      ...state,
      ...initialState,
    };
  default:
    return state;
  }
};

export default lastRoundProductsReducer;
