import React from 'react';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';
import { connect } from 'react-redux';
import async from 'async';
import _ from 'lodash';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';
import UserService from 'services/User';
class UserInfoPopup extends React.Component {
  getUser = ( data, cb ) => {
    UserService.getCurrentUserSpy( data )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        return cb( null, { ...data, ...response.data } );
      } );
  };
  getNotificationsUser = ( data, cb ) => {
    UserService.getNotificationsUser( data )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        return cb( null, { ...data, notifications: response.data } );
      } );
  };
  setReturnToken = ( data, cb ) => {
    const { user,setSpyMode } = this.props;
    UserService.setReturnTokenCloseSpy( user.activeSession.id, { returnToken: '' }, user.activeSession.returnToken )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        setSpyMode(false)
        return cb( null, { ...data } );
      } );
  };


  closeSpySession = ( ) => {
    const {
      toggleAlert,
      toggleLoading,
      history,
      user,
      loginUser,
    } = this.props;
    // return;
    toggleLoading( true );
    const dataToSend = {};
    dataToSend.access_token = user.activeSession.returnToken
    
    async.waterfall( [
      this.setReturnToken.bind( this, dataToSend ),
      this.getUser,
      this.getNotificationsUser,
    ], ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        if ( error === 'invalid_grant' ) {
          return toggleAlert( 'wrongUserPass', 'danger', true );
        }
        return toggleAlert( error );
      }
      if ( _.get( results, 'licenses[0].session' ) ) {
        //changeActiveSession( results.licenses[0].session );
        loginUser( results );
        history.push( '/' );
      } else {
        loginUser( results );
        history.push( '/sessions/add' );
      }
    } );
  };
  hasPermissions( role, license ) {
    const { user } = this.props;
    return user.roles && user.roles.indexOf( role ) !== -1
      && user.licenses && _.find( user.licenses, { owner: license } );
  }

  render() {
    // const { user } = this.props;

    return (
      <div>
        <div className="user-profile">
          <div className="user-detail ml-2">
            <h4><IntlMessages id="popup.titleSpy"/></h4>
          </div>
        </div>
       
        <span className="jr-link dropdown-item text-muted" onClick={() => {
          //window.location.replace( '/login' );
          this.closeSpySession();
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.logoutSpy"/>
        </span>
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = ( {
  loginUser: UserActions.loginUser,
  logoutUser: UserActions.logoutUser,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  toggleVideo: SettingsActions.toggleVideo,
  setSpyMode:UserActions.setSpyMode,
  notificationsUser: UserActions.notificationsUser,
  changeActiveSession: UserActions.changeActiveSession,
} );

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( UserInfoPopup ) );

