import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import async from 'async';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import SettingsActions from 'store/reducers/Settings';
import { TextField, Select } from 'components/custom/FormElements';
import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';
import IntlMessages from 'utils/IntlMessages';

import { required } from 'config/InputErrors';
import ProductService from 'services/Product';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/products.jpeg';
import HeadConfig from 'components/custom/HeadConfig';
import { Grid } from '@material-ui/core';

class NewProduct extends React.Component {
  state = {
    category: {},
    attributes: [],
  };
  backLabel = 'products';

  componentDidMount() {
    const { toggleAlert, user, history } = this.props;

    if ( history.location.pathname.includes( 'price' ) ) {
      this.backLabel = 'price';
    } else if ( history.location.pathname.includes( 'distribution' ) ) {
      this.backLabel = 'distribution';
    }

    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel( {
      products: ( cb ) => {
        ProductService.getProducts( this.activeSession.id, {
          workgroup: this.license.workGroup.id,
          'filters[inStatus]': ['CANCELED'],
        } ).then( ( response ) => {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } );
      },

    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );
      this.setState( { products: results.products } );

      this.attributesChart = [];
      _.map( results.products[0].attributes, ( attribute ) => {
        const item = { attribute: attribute.label, id: attribute.id };
        item[results.products[0].id] = 0;
        item.NEW = 0;
        this.attributesChart.push( item );
      } );

      this.chartData = [];
      this.chartData.push( { id: 'NEW', name: 'NEW' } );
      // this.renderGraph( this.attributesChart, this.chartData );
    } );
  };

  renderGraph = ( attributes, chartData ) => {
    if ( this.chart ) {
      this.chart.dispose();
    }
    const config = {
      data: attributes,
      type: am4charts.RadarChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
      }],
      cursor: { type: 'RadarCursor' },
      legend: { type: 'Legend' },
      yAxes: [{ type: 'ValueAxis' }],
      series: _.map( chartData, product => ( {
        type: 'RadarSeries',
        dataFields: {
          valueY: product.id,
          categoryX: 'attribute',
        },
        strokeWidth: 3,
        tooltipText: '{valueY}',
        name: product.name,
        fill: product.id !== 'NEW' ? am4core.color( '#A8A6A6' ) : am4core.color( '#00a89e' ),
        stroke: product.id !== 'NEW' ? am4core.color( '#A8A6A6' ) : am4core.color( '#00a89e' ),
        bullets: [{ type: 'CircleBullet' }],
      } ) ),
    };
    this.chart = am4core.createFromConfig( config, 'chartdiv' );
  };

  calcAttribute = ( id, value, previousValue, index, formData, sizeValue ) => {
    const { attributes } = this.state;
    const { toggleLoading } = this.props;
    toggleLoading( true );

    async.waterfall( [
      cb => this.getAttributeCost( id, value, previousValue, index, formData, cb, sizeValue ),
      ( cb ) => {
        const current = _.find( attributes, { id } );
        const weight = _.find( attributes, { label: 'Weight' } );
        const Size = _.find( attributes, { label: 'Size' } );
        const { newWeight } = this.state;
        if ( weight ) {
          let weightValue;
          if ( newWeight !== '' ) {
            weightValue = newWeight;
          } else {
            weightValue = weight.value;
          }
          const weightIndex = _.findIndex( attributes, { id: weight.id } );

          let currentSizeValue = sizeValue;
          if ( Size.value ) {
            currentSizeValue = Size.value;
          }
          this.getAttributeCost(
            weight.id, weightValue, weight.value, weightIndex, formData, cb, currentSizeValue,
          );
          const findIndexWeight = _.findIndex( this.attributesChart, { attribute: 'Weight' } );
          if ( findIndexWeight !== -1 ) this.attributesChart[findIndexWeight].NEW = weightValue;
        }
        const findIndex = _.findIndex( this.attributesChart, { attribute: current.label } );
        if ( findIndex !== -1 ) {
          this.attributesChart[findIndex].NEW = value;
          this.chartData = [];
          this.chartData.push( { id: 'NEW', name: 'NEW' } );
          this.renderGraph( this.attributesChart, this.chartData );
        }
      },
    ], () => {
      toggleLoading( false );
    } );
  };

  handleChangeProduct = ( value ) => {
    const { products } = this.state;
    const product = _.find( products, { id: value } );

    this.attributesChart = [];
    _.map( product.attributes, ( attribute ) => {
      const item = { attribute: attribute.label, id: attribute.id };
      item[product.id] = attribute.value;

      item.NEW = 0;
      this.attributesChart.push( item );
    } );
    this.chartData = [];
    this.chartData.push( { id: product.id, name: 'NEW' } );
    // this.renderGraph( this.attributesChart, this.chartData );
    // const prodName = `${product.name}.`;
    // eslint-disable-next-line max-len
    const formData = { product: product.id };
    this.setState( { formData } );
  }
  submitForm = ( data ) => {
    const { toggleAlert, toggleLoading, history } = this.props;

    // if ( _.find( data.variables, item => !item.value ) ) {
    //  return toggleAlert( 'fillAttributesValue', 'danger', true );
    // }
    toggleLoading( true );
    const dataToSend = {
      product: data.formData.product,
      name: data.formData.name.normalize( 'NFD' ).replace( /[\u0300-\u036f]/g, '' ).replace( /[^a-zA-Z0-9 ]/g, '' ),
      description: data.formData.description.normalize( 'NFD' ).replace( /[\u0300-\u036f]/g, '' ).replace( /[^a-zA-Z0-9 ]/g, '' ),

    };

    ProductService.duplicateProduct( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        toggleAlert( 'dataSaved', 'info' );
        history.push( `/sessions/general/${this.backLabel}` );
      } );
  };

  render() {
    const { category, attributes, formData, products } = this.state;
    return (
      <>
        <HeadConfig breadcrumbsLinks={[
          {
            url: '/',
            name: 'HomeLink',
          },
          {
            name: 'DecisionDashboard-products',
            url: '/sessions/general/products',
          },

          { name: 'RelaunchProduct' },
        ]}
        />
        <Form
          initialValues={{
            formData,
            attributes,
          }}
          validate={( values ) => {
            const errors = {};
            _.map( values.attributes, ( attribute ) => {
              if ( !errors.attributes ) errors.attributes = {};
              if ( !attribute.value ) errors.attributes[attribute.id] = required( attribute.value );
            } );
            return errors;
          }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit } ) => (
            <form onSubmit={handleSubmit}>
              <KotlerSectionHeader title={`titleRelaunchProduct${category.name || ''}`} image={HeaderImage} titleCancel="cancelBtn" titleSubmit="save" cancelTo={`/sessions/general/${this.backLabel}`} onSubmit={handleSubmit} />
              <Grid container spacing={2}>

                <Grid item xs={12} className="mt-4 mb-1 font-weight-bold">
                  <IntlMessages id="newProductTitleGeneral" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Select
                    adornRequired
                    field="formData.product"
                    label="productToRelaunch"
                    options={products}
                    onChange={this.handleChangeProduct}
                    translateOptions={false}
                    validate={required}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    adornRequired
                    field="formData.name"
                    label="newProductName"
                    validate={required}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    field="formData.description"
                    label="newProductDescription"
                    validate={required}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>


              {/*   <Row>
                <Col>
                  <div className="text-right mt-4">
                    <ButtonsCopyProd cancelTo={`/sessions/general/${this.backLabel}`} />
                  </div>
                </Col>
              </Row> */}
            </form>
          )}

        />
        <HelpSnackBar message="help.new.product.snackbar" />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( NewProduct );
