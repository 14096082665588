import { takeEvery, put, call } from 'redux-saga/effects';
import { getLastRoundProductAllCategories } from 'modules/Products/ProductServices';
import {
  FETCH_LAST_ROUND_PRODUCTS_REQUEST,
  fetchLastRoundProductsFailure,
  fetchLastRoundProductsSuccess,
} from '../actions/lastRoundProductsActions';

function* fetchLastRoundProductsSaga( action ) {
  const {
    sessionId,
    workgroupId,
    params = { limit: -1 },
  } = action.payload;
  try {
    const lastroundProductsData = yield call(
      getLastRoundProductAllCategories,
      sessionId,
      workgroupId,
      params,
    );
    yield put(
      fetchLastRoundProductsSuccess( lastroundProductsData ),
    );
  } catch ( error ) {
    yield put( fetchLastRoundProductsFailure( error ) );
  }
}

export default function* lastroundProductsRootSaga() {
  yield takeEvery( FETCH_LAST_ROUND_PRODUCTS_REQUEST, fetchLastRoundProductsSaga );
}
