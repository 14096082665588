import React from 'react';
import { connect } from 'react-redux';
import { Grid, Container, Typography } from '@material-ui/core';

import HeaderImage from 'assets/images/kotler/sectionHeaders/register.jpeg';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { Form } from 'react-final-form';
import { TextField } from 'components/custom/FormElements';
import { composeValidators, equalTo, required } from 'config/InputErrors';
import UserService from 'services/User';
import HeadConfig from 'components/custom/HeadConfig';

import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import CardBox from 'components/custom_v2/CardBox';
import KotlerCardHeader from 'components/custom/KotlerCardHeader';
import { parallel } from 'async';
import PictureProfile from '../../PictureProfile';

const AccountProfile = ( { toggleLoading, toggleAlert, onEdit, readOnly, user, profileProps } ) => {
  const submitForm = ( data, form ) => {
    const newData = {
      name: data.name,
      lastName: data.lastName,
    };
    toggleLoading( true );
    parallel( {
      userProcess: ( cb ) => {
        UserService.updateUserProfile( newData ).then( ( response ) => {
          if ( !response.ok ) {
            toggleAlert( response.errors );
            return cb( response.errors );
          }

          toggleAlert( 'profileUpdated', 'info' );
          cb( null );
        } );
      },
      passwordProcess: ( cb ) => {
        if ( data.newPassword && data.oldPassword && data.confirmNewPassword ) {
          UserService.changePassword( {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
          } )
            .then( ( response ) => {
              if ( !response.ok ) {
                toggleAlert( response.errors );
                return cb( response.errors );
              }
              toggleAlert( 'passwordChanged', 'info' );
              form.change( 'oldPassword' );
              form.change( 'newPassword' );
              form.change( 'confirmNewPassword' );
              cb( null );
            } );
        } else {
          return cb( null );
        }
      },

    }, ( errors /* results */ ) => {
      toggleLoading( false );
      if ( !errors ) {
        onEdit();
      }
    } );
  };
  return (
    <>
      <Form
        initialValues={{ ...user }}
        onSubmit={submitForm}
        validate={( { newPassword, oldPassword, confirmNewPassword } ) => {
          const errors = {};
          if ( ( newPassword || oldPassword ) && !confirmNewPassword ) errors.confirmNewPassword = 'fieldError.required';
          if ( ( confirmNewPassword || oldPassword ) && !newPassword ) errors.newPassword = 'fieldError.required';
          if ( ( newPassword || confirmNewPassword ) && !oldPassword ) errors.oldPassword = 'fieldError.required';
          return errors;
        }}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit} noValidate>
            <KotlerSectionHeader
              title="profile-account-title"
              image={HeaderImage}
              titleCancel={readOnly ? 'exit' : 'cancelBtn'}
              onCancel={!readOnly ? () => {
                form.reset();
                onEdit();
              } : null}
              cancelTo={readOnly ? '/' : null}
              titleSubmit={readOnly ? 'profile-edit' : 'save'}
              onSubmit={readOnly ? onEdit : handleSubmit}
            />
            <Container maxWidth="lg" className="mt-3">
              <HeadConfig breadcrumbsLinks={[
                {
                  url: '/',
                  name: 'HomeLink',
                },
                { name: 'profile-account-title' },

              ]}
              />


              <Grid container style={{ rowGap: '20px' }}>
                <Grid item xs={12} sm={6} md={4}>
                  <CardBox styleName="px-2 h-100" disableHeader>
                    <Typography
                      variant="h4"
                      style={{
                        fontSize: '21px',
                        fontWeight: 700,
                        lineHeight: '32px',
                        color: '#09082C',
                      }}
                    >
                      {user.name && user.lastName && ( `${user.name} ${user.lastName}` ) }
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '21px',
                        color: '#09082C',
                      }}
                      className="mt-2"
                    >
                      {user.email && user.email}

                    </Typography>
                    <PictureProfile {...profileProps} />
                  </CardBox>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CardBox styleName="px-2 h-100" header={<KotlerCardHeader title="NewSessionStep1" minHeight="30px" />}>


                    <div>


                      <TextField
                        containerClass={readOnly ? 'kotler-text-field-form-control' : null}
                        className={readOnly ? 'kotler-text-field-gray' : null}
                        field="name"
                        label="name"
                        disabled={readOnly}
                        validate={required}
                      />
                      <TextField
                        containerClass={readOnly ? 'kotler-text-field-form-control' : null}
                        className={readOnly ? 'kotler-text-field-gray' : null}
                        field="lastName"
                        label="lastName"
                        disabled={readOnly}
                        validate={required}
                      />


                    </div>
                  </CardBox>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CardBox styleName="px-2 h-100" header={<KotlerCardHeader title="changePassword" minHeight="30px" />}>
                    <TextField

                      field="oldPassword"
                      type="password"
                      label="currentPassword"
                      containerClass={readOnly ? 'kotler-text-field-form-control' : null}
                      className={readOnly ? 'kotler-text-field-gray' : null}
                      disabled={readOnly}
                    />
                    <TextField
                      field="newPassword"
                      type="password"
                      label="newPassword"
                      containerClass={readOnly ? 'kotler-text-field-form-control' : null}
                      className={readOnly ? 'kotler-text-field-gray' : null}
                      disabled={readOnly}
                    />
                    <TextField
                      field="confirmNewPassword"
                      type="password"
                      label="confirmNewPassword"
                      validate={composeValidators( equalTo( 'newPassword' ) )}
                      translateValues={{ label: <IntlMessages id="newPassword" /> }}
                      containerClass={readOnly ? 'kotler-text-field-form-control' : null}
                      className={readOnly ? 'kotler-text-field-gray' : null}
                      disabled={readOnly}
                    />
                    {/*    <ChangePassword />  */}
                  </CardBox>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      />
    </>

  );
};
const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( AccountProfile );
