export const FETCH_MARKET_PRODUCTS_REQUEST = 'FETCH_MARKET_PRODUCTS_REQUEST';
export const FETCH_MARKET_PRODUCTS_SUCCESS = 'FETCH_MARKET_PRODUCTS_SUCCESS';
export const FETCH_MARKET_PRODUCTS_FAILURE = 'FETCH_MARKET_PRODUCTS_FAILURE';
export const CLEAR_MARKET_PRODUCTS = 'CLEAR_MARKET_PRODUCTS';

export const fetchMarketProductsRequest = (
  sessionId,
  workgroupId,
  categoryId,
  params,
) => ( {
  type: FETCH_MARKET_PRODUCTS_REQUEST,
  payload: { sessionId, workgroupId, categoryId, params },
} );

export const fetchMarketProductsSuccess = ( { categoryId, data } ) => ( {
  type: FETCH_MARKET_PRODUCTS_SUCCESS,
  payload: { categoryId, data },
} );

export const fetchMarketProductsFailure = ( { categoryId, error } ) => ( {
  type: FETCH_MARKET_PRODUCTS_FAILURE,
  payload: { categoryId, error },
} );

export const clearMarketProducts = () => ( { type: CLEAR_MARKET_PRODUCTS } );
